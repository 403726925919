// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-quill .list-media {
  display: flex;
  flex-wrap: wrap; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/editor-quill/editorHtml.scss"],"names":[],"mappings":"AAAA;EAEI,aAAe;EACf,eAAe,EAAA","sourcesContent":[".survey-managment-quill {\n  .list-media {\n    display  : flex;\n    flex-wrap: wrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
