import { CaretUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { get } from "lodash";
import React, { useState } from "react";
import "./overview-chart-tab.scss";
import StatisticalReportCard from "../../StatisticalReportCard";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import { barChartCountData, barChartData, barStackedChartLecturerData, barStackedChartStudentData, barStackedChartUniversityData, comboDualAxesChartData, donutChartData } from "../../constants/fakeData";

type Props = {
  overviewDataStatistical: any,
};

const OverviewChartTab = (props: Props) => {
  const {overviewDataStatistical} = props;

  const renderStatistical = () => {
    return (
      <>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Lượt truy cập"
                value={get(overviewDataStatistical,'dataHits.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataHits.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Trường liên kết"
                value={get(overviewDataStatistical,'dataAffiliatedUniversity.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataAffiliatedUniversity.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Giảng viên"
                value={get(overviewDataStatistical,'dataLecturer.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataLecturer.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Học viên"
                value={get(overviewDataStatistical,'dataStudent.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataStudent.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Khóa học"
                value={get(overviewDataStatistical,'dataCourse.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataCourse.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Tài nguyên"
                value={get(overviewDataStatistical,'dataResources.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(overviewDataStatistical,'dataResources.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
      </>
    )
  }
  return (
    <>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        {renderStatistical()}
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượt truy cập và số người truy cập"  
            content={
              <ComboDualAxesChart 
                data={comboDualAxesChartData}
                columnName={`Số lượt truy cập (567)`}
                columnColor="#69C0FF"
                lineName={`Số người truy cập (567)`}
                lineColor="#FF7875"
                hasTotal={false}
              />
            }
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Đối tượng truy cập"
            content={
              <BarChart 
                data={barChartData}
                colors={["#69C0FF", "#FFC069"]}
                showLegend={true}
                hasTotal={false}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng trường liên kết"  
            content={
              <BarChart 
                data={barChartCountData}
                colors={["#69C0FF"]}
                showLegend={false}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng giảng viên"  
            content={
              <BarStackedChart 
                data={barStackedChartLecturerData}
                colors={["#FFC069", "#5CDBD3", "#69C0FF"]}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng học viên"  
            content={
              <BarStackedChart 
                data={barStackedChartStudentData}
                colors={["#5CDBD3", "#69C0FF"]}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng khóa học theo trường"  
            content={
              <BarStackedChart 
                data={barStackedChartUniversityData}
                colors={["#5CDBD3", "#69C0FF"]}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard 
            chartName="Số lượng tài nguyên"  
            content={
              <DonutChart 
                data={donutChartData}
                title=""
                height={300}
                innerSize="70%"
                size="80%"
                colors={["#69C0FF","#5CDBD3","#FFC069","#FF7875"]}
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default OverviewChartTab;
