import { Form, Layout } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./shared-categories-management-detail.scss";

const { Content } = Layout;
const imgPath = "/images/";

const SharedCategoriesManagementDetail: FunctionComponent = () => {
  const location = useLocation();
  const [parentCate, setParentCate] = useState<string>("");
  const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);
  const listBreadcrumb = [
    {
      title: "Quản lý danh mục",
    },
    {
      title: (
        <SafeLink
          to={`/${routesConfig.categoryManagement}/${routesConfig.sharedCategoriesManagement}`}
        >
          <span>Quản lý danh mục dùng chung</span>
        </SafeLink>
      ),
    },
    {
      title: location.state.name,
    },
  ];

  useEffect(() => {
    if (location.state.record.parentId) {
      location.state.dataPortfolio.map((item: any) => {
        if (item.id === location.state.record.parentId) {
          setParentCate(item.name);
        }
      });
    }
  }, [location.state]);

  return (
    <>
      <Content>
        <PageHeader
          title={location.state?.name ? location.state?.name : ""}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={isShowSubmitButton}
        />
        <div className="wrapper-settings">
          <section className="setting-category">
            <CustomCard className="category-card" title="Thông tin danh mục dùng chung" >
              <div>
                <Form.Item className="setting-item" name="cateName" label="Tên danh mục" >
                  <p className="category-content">{location.state?.name}</p>
                </Form.Item>
                <Form.Item className="setting-item" name="cateCode" label="Mã danh mục" >
                  <p className="category-content">
                    {location.state.record.code}
                  </p>
                </Form.Item>
                <Form.Item className="setting-item" name="cateDad" label="Danh mục cha" >
                  <p className="category-content">{parentCate}</p>
                </Form.Item>
                <Form.Item className="setting-item" name="status" label="Trạng thái" >
                  <p>
                    {location.state.record.status ? (
                      <>Hoạt động</>
                    ) : (
                      <>Không hoạt động</>
                    )}
                  </p>
                </Form.Item>
                <Form.Item className="setting-item" name="createdDate" label="Ngày tạo" >
                  <p className="created-date"> {location.state.record.createdDate} </p>
                </Form.Item>
              </div>
            </CustomCard>
          </section>
        </div>
      </Content>
    </>
  );
};

export default SharedCategoriesManagementDetail;
