import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Row,
  Typography,
  Tree,
  Space,
  Divider,
  Card,
  Select,
  Input,
} from "antd";
import type { TreeDataNode } from "antd";
import {
  BookOutlined,
  CaretRightOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./detail.scss";

import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
  TypeCoursePlanAssignment,
  TypeCourseConstructionPlan,
  DataTreeNodePlanAssignment,
} from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { getAllTeachers, getDataTeachers } from "../../../../../../service/list-account-teachers/listAccountTeachersApi";

interface DetailCoursePlanAssignV2Props {
  dataCoursePlan: TypeCourseConstructionPlan[];
  dataListCourseStructure: TypeChapterList[];
  dataListChapter: TypeChapterList[];
  dataCourseAssignment: TypeCoursePlanAssignment[];
  dataTreeNode: DataTreeNodePlanAssignment[];
  setDataCoursePlanAssignment: (data: TypeCoursePlanAssignment[]) => void;
}

interface Teacher {
  label: string;
  value: string;
}

const DetailCoursePlanAssignV2: React.FC<DetailCoursePlanAssignV2Props> = ({
  dataCoursePlan,
  dataListCourseStructure,
  dataListChapter,
  dataCourseAssignment,
  dataTreeNode,
  setDataCoursePlanAssignment,
}) => {
  //SearchNameTeacherFromId

  //GetListAllTeacher
  const [dataOptions, setDataOptions] = useState({
    dataTeachers: [] as Teacher[],
  });

  const getListDataTeachers = async () => {
    try {
      await getAllTeachers().then((res) => {
        const { data } = res.data;
        setDataOptions({
          dataTeachers: data.map((member: any) => ({
            value: member.id,
            label: member.fullName,
          })),
        });
      });
    } catch (error) { }
  };

  useEffect(() => {
    getListDataTeachers();
  }, []);

  // console.log(`dataTeacher:::`, dataOptions.dataTeachers);
  // console.log(`dataCourseAssignment:::`, dataCourseAssignment);
  const [keywordSearchPlanAssignment, setKeywordSearchPlanAssignment] =
    useState("");

  const handleChangeCourseAssignment = (
    coursePlanId: number,
    assigneeId?: number,
    courseSturctureItemId?: number
  ) => {
    //2. Nếu không có courseSturctureItemId (cập nhật chương)
    if (!courseSturctureItemId) {
      const index = dataCourseAssignment.findIndex(
        (item) =>
          item.courseConstructionPlanId === coursePlanId &&
          !item.courseSturctureItemId
      );

      if (index !== -1) {
        // Nếu đã tồn tại bản ghi với coursePlanId này, thực hiện cập nhật

        let newDataCourseAssignment = [...dataCourseAssignment];

        newDataCourseAssignment[index] = {
          ...newDataCourseAssignment[index],
          assigneeId: assigneeId,
        };

        setDataCoursePlanAssignment(newDataCourseAssignment);
      } else {
        // Nếu không tìm thấy bản ghi, thêm mới vào dataCourseAssignment
        let newDataCourseAssignment = [...dataCourseAssignment];
        const neww = {
          courseConstructionPlanId: coursePlanId,
          assigneeId: assigneeId,
        };
        newDataCourseAssignment.push(neww);
        setDataCoursePlanAssignment(newDataCourseAssignment);
      }
    }
  };

  const handleChangeChapterAssignment = (
    coursePlanId: number,
    assigneeId?: number[],
    courseSturctureItemId?: number
  ) => {
    // console.log(`coursePlanId:::`, coursePlanId);
    // console.log(`courseSturctureItemId:::`, courseSturctureItemId);
    // console.log(`assigneeId:::`, assigneeId);

    if (courseSturctureItemId) {
      //nếu không có mảng id thì exit
      if (!assigneeId) return;

      //clone
      let newDataCourseAssignment = [...dataCourseAssignment];

      // console.log(`dataCourseAssignment::`, dataCourseAssignment);

      // lọc các bản ghi có cùng courseSturctureItemId
      let filteredData = newDataCourseAssignment.filter(
        (item) => item.courseSturctureItemId === courseSturctureItemId
      );

      let noAccessData = newDataCourseAssignment.filter(
        (item) =>
          item.courseSturctureItemId !== courseSturctureItemId ||
          item.courseSturctureItemId === null
      );

      // Lọc các phần tử trong mảng cũ, cái nào trong có trong mảng mới thì delete
      for (let i = filteredData.length - 1; i >= 0; i--) {
        const foundIndex = assigneeId.findIndex(
          (id) => id === filteredData[i].assigneeId
        );

        // If the item is not found in the assigneeId array, remove it from filteredData
        if (foundIndex === -1) {
          filteredData.splice(i, 1);
        }
      }

      //Lọc các phần tử trong mảng mới, cái nào trong mảng mới không có trong mảng cũ thì thêm
      for (const id of assigneeId) {
        const foundIndex = filteredData.findIndex(
          (data) => data.assigneeId === id
        );

        //không có id đó trong filterData ===> thêm
        if (foundIndex === -1) {
          const neww = {
            courseConstructionPlanId: coursePlanId,
            courseSturctureItemId: courseSturctureItemId,
            assigneeId: Number(id),
          };

          filteredData.push(neww);
        }
      }

      setDataCoursePlanAssignment([...filteredData, ...noAccessData]);
    }
  };

  const convertCourseToTreeData = (
    course: DataTreeNodePlanAssignment,
    index: number
  ): TreeDataNode => {
    const assigneeIdCourse = dataCourseAssignment.find(
      (item) =>
        item.courseConstructionPlanId === course.plan.id &&
        !item.courseSturctureItemId
    )?.assigneeId;
    const courseNode: TreeDataNode = {
      title: (
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Col>
            <span>{course.plan.registrationName}</span>
          </Col>
          <Col>
            <Select
              showSearch
              allowClear={false}
              placeholder="GV chủ trì"
              value={assigneeIdCourse ? (
                assigneeIdCourse &&
                dataOptions.dataTeachers.find(
                  (item) => Number(item.value) === assigneeIdCourse
                )?.label) :  dataOptions.dataTeachers[0]
              }
              options={dataOptions.dataTeachers.map((teacher, index) => ({
                label: teacher.label,
                value: teacher.value,
              }))}
              filterOption={(input, option) =>
                (option?.label?.toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              filterSort={(optionA, optionB) =>
                (optionA?.label?.toLowerCase() ?? "").localeCompare(
                  optionB?.label?.toLowerCase() ?? ""
                )
              }
              onChange={(value) =>
                handleChangeCourseAssignment(
                  Number(course.plan.id),
                  Number(value)
                )
              }
              style={{ minWidth: "200px" }}
            // style={
            //   assigneeIdCourse
            //     ? { minWidth: "200px" }
            //     : { minWidth: "200px", border: "none", outline: "none" }
            // }
            />
          </Col>
        </Row>
      ),
      key: `${course.plan.id}`,
      children: [],
      style:
        index > 0
          ? { borderBottom: "1px solid #5e87ff", marginTop: "35px" } //#fff6cc
          : { borderBottom: "1px solid #5e87ff", marginTop: "5px" }, //f4f4f4
    };

    // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
    course.courseStructure.forEach((chapter: TypeChapterList) => {
      const chapterAssignList = dataCourseAssignment.filter(
        (item) => item.courseSturctureItemId === chapter.id
      );
      // console.log(`chapterAssignList:::`, chapterAssignList);

      const assigneeIdChapterList = chapterAssignList.map((item) => ({
        value: item.assigneeId,
        label: dataOptions.dataTeachers.find(
          (teacher) => Number(teacher.value) === Number(item.assigneeId)
        )?.label,
      }));

      // console.log(`assigneeIdChapterList::`, assigneeIdChapterList)
      const chapterNode: TreeDataNode = {
        title: (
          <Row
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Col>
              <span>{chapter.structureItemName}</span>
            </Col>
            <Col>
              <Select
                showSearch
                mode="multiple"
                style={{ width: "100%", minWidth: "300px" }}
                maxTagCount="responsive"
                placeholder="Chờ phân công"
                options={dataOptions.dataTeachers.map((teacher, index) => ({
                  label: teacher.label,
                  value: teacher.value,
                }))}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label?.toLowerCase() ?? "").localeCompare(
                    optionB?.label?.toLowerCase() ?? ""
                  )
                }
                onChange={(values, record) => {
                  const numericValues = values.map((item) =>
                    Number(item.value)
                  );
                  handleChangeChapterAssignment(
                    Number(course.plan.id),
                    numericValues,
                    Number(chapter.id)
                  );
                }}
                value={assigneeIdChapterList}
                labelInValue
              />
            </Col>
          </Row>
        ),
        key: `${chapter.id}`,
        children: [],
        style: {
          backgroundColor: "#e6f4ff", //#fafafa
          marginTop: "0",
          paddingTop: "5px",
        },
      };

      // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
      chapter.subjectList.forEach((subject: TypeSubjectList) => {
        const subjectNode: TreeDataNode = {
          title: (
            <Space direction="vertical">
                        <Row>
                            <BookOutlined /> <span style={{ marginLeft: "5px" }}>{subject.structureItemName}</span>
                        </Row>
                    </Space>
          ),
          key: `${subject.id}`,
          switcherIcon: <CaretRightOutlined />,
          style: {
            backgroundColor: "#e6f4ff", //#fafafa
            marginTop: "0",
            paddingTop: "5px",
          },
          children: [],
        };

        // Duyệt qua từng bài học của môn học và chuyển đổi thành TreeDataNode
        subject.lessonList.forEach((lesson: TypeCourseStructure) => {
          const lessonNode: TreeDataNode = {
            title: (
              <Space direction="vertical">
              <Row>
                  <BookOutlined /> <span style={{ marginLeft: "5px" }}>{lesson.structureItemName}</span>
              </Row>
          </Space>
            ),
            switcherIcon: <MinusOutlined />,
            key: `${lesson.id}`,
            isLeaf: true,
            style: { backgroundColor: "#e6f4ff", paddingTop: "5px" }, //#fafafa
          };

          // Thêm node bài học vào node môn học
          if (subjectNode.children) {
            subjectNode.children.push(lessonNode);
          }
        });

        // Thêm node môn học vào node chapter
        if (chapterNode.children) {
          chapterNode.children.push(subjectNode);
        }
      });

      // Thêm node môn học vào node chapter
      if (courseNode.children) {
        courseNode.children.push(chapterNode);
      }
    });

    return courseNode;
  };

  const treeData: TreeDataNode[] = dataTreeNode.map((course, index) =>
    convertCourseToTreeData(course, index)
  );

  const [isFilterSearch, setIsFilterSearch] = useState(false);

  const filteredTreeDataNode: TreeDataNode[] = useMemo(() => {
    const dataNode = dataTreeNode.filter((item) => JSON.stringify(item).toLowerCase().includes(keywordSearchPlanAssignment.toLowerCase()))
    return dataNode.map((course, index) =>
      convertCourseToTreeData(course, index)
    );
  }, [treeData, isFilterSearch])


  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);

  useEffect(() => {
    if (expandAll) {
      // Tạo mảng chứa tất cả các khóa của nút trong cây
      const allNodeKeys = getAllNodeKeys(treeData);
      setExpandedKeys(allNodeKeys);
    } else {
      setExpandedKeys([]);
    }
  }, [expandAll]);

  // Hàm đệ quy để lấy tất cả các khóa của nút trong cây
  const getAllNodeKeys = (nodes: TreeDataNode[]): string[] => {
    let keys: string[] = [];
    nodes.forEach((node) => {
      keys.push(node.key as string);
      if (node.children) {
        keys = keys.concat(getAllNodeKeys(node.children));
      }
    });
    return keys;
  };

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    // console.log("selected", selectedKeys, info);
  };



  return (
    <div>
      <Input.Search
        placeholder="Nhập từ khóa cần tìm"
        // value={keywordSearchPlanAssignment}
        // onChange={(e) => {setKeywordSearchPlanAssignment(e.target.value); setIsFilterSearch(false);}}
        onSearch={(content) => { setKeywordSearchPlanAssignment(content); setIsFilterSearch(true) }}
      />
      <Divider />
      <Tree
        showIcon={true}
        style={{ fontSize: 15 }}
        defaultExpandedKeys={["0-0-0-0"]}
        expandedKeys={expandedKeys}
        onExpand={(e) => setExpandedKeys(e)}
        onSelect={onSelect}
        treeData={filteredTreeDataNode}
        blockNode
      />
    </div>
  );
};
export default DetailCoursePlanAssignV2;





































// import React, { useEffect, useMemo, useState } from "react";
// import {
//   Button,
//   Col,
//   Row,
//   Typography,
//   Tree,
//   Space,
//   Divider,
//   Card,
//   Select,
//   Input,
// } from "antd";
// import type { TreeDataNode } from "antd";
// import {
//   BookOutlined,
//   CaretRightOutlined,
//   MinusOutlined,
//   SearchOutlined,
// } from "@ant-design/icons";
// import "./detail.scss";

// import {
//   TypeCourseStructure,
//   TypeChapterList,
//   TypeSubjectList,
//   TypeCoursePlanAssignment,
//   TypeCourseConstructionPlan,
//   DataTreeNodePlanAssignment,
// } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
// import { getDataTeachers } from "../../../../../../service/list-account-teachers/listAccountTeachersApi";

// interface DetailCoursePlanAssignV2Props {
//   dataCoursePlan: TypeCourseConstructionPlan[];
//   dataListCourseStructure: TypeChapterList[];
//   dataListChapter: TypeChapterList[];
//   dataCourseAssignment: TypeCoursePlanAssignment[];
//   dataTreeNode: DataTreeNodePlanAssignment[];
//   setDataCoursePlanAssignment: (data: TypeCoursePlanAssignment[]) => void;
// }

// interface Teacher {
//   label: string;
//   value: string;
// }

// const DetailCoursePlanAssignV2: React.FC<DetailCoursePlanAssignV2Props> = ({
//   dataCoursePlan,
//   dataListCourseStructure,
//   dataListChapter,
//   dataCourseAssignment,
//   dataTreeNode,
//   setDataCoursePlanAssignment,
// }) => {
//   //SearchNameTeacherFromId

//   //GetListAllTeacher
//   const [dataOptions, setDataOptions] = useState({
//     dataTeachers: [] as Teacher[],
//   });

//   const getListDataTeachers = async () => {
//     try {
//       await getDataTeachers({}).then((res) => {
//         const { data } = res.data.data;
//         setDataOptions({
//           dataTeachers: data.map((member: any) => ({
//             value: member.id,
//             label: member.fullName,
//           })),
//         });
//       });
//     } catch (error) {}
//   };

//   useEffect(() => {
//     getListDataTeachers();
//   }, []);

//   // console.log(`dataTeacher:::`, dataOptions.dataTeachers);
//   // console.log(`dataCourseAssignment:::`, dataCourseAssignment);
//   const [keywordSearchPlanAssignment, setKeywordSearchPlanAssignment] =
//     useState("");

//   const handleChangeCourseAssignment = (
//     coursePlanId: number,
//     assigneeId?: number,
//     courseSturctureItemId?: number
//   ) => {
//     //2. Nếu không có courseSturctureItemId (cập nhật chương)
//     if (!courseSturctureItemId) {
//       const index = dataCourseAssignment.findIndex(
//         (item) =>
//           item.courseConstructionPlanId === coursePlanId &&
//           !item.courseSturctureItemId
//       );

//       if (index !== -1) {
//         // Nếu đã tồn tại bản ghi với coursePlanId này, thực hiện cập nhật

//         let newDataCourseAssignment = [...dataCourseAssignment];

//         newDataCourseAssignment[index] = {
//           ...newDataCourseAssignment[index],
//           assigneeId: assigneeId,
//         };

//         setDataCoursePlanAssignment(newDataCourseAssignment);
//       } else {
//         // Nếu không tìm thấy bản ghi, thêm mới vào dataCourseAssignment
//         let newDataCourseAssignment = [...dataCourseAssignment];
//         const neww = {
//           courseConstructionPlanId: coursePlanId,
//           assigneeId: assigneeId,
//         };
//         newDataCourseAssignment.push(neww);
//         setDataCoursePlanAssignment(newDataCourseAssignment);
//       }
//     }
//   };

//   const handleChangeChapterAssignment = (
//     coursePlanId: number,
//     assigneeId?: number[],
//     courseSturctureItemId?: number
//   ) => {
//     // console.log(`coursePlanId:::`, coursePlanId);
//     // console.log(`courseSturctureItemId:::`, courseSturctureItemId);
//     // console.log(`assigneeId:::`, assigneeId);

//     if (courseSturctureItemId) {
//       //nếu không có mảng id thì exit
//       if (!assigneeId) return;

//       //clone
//       let newDataCourseAssignment = [...dataCourseAssignment];

//       // console.log(`dataCourseAssignment::`, dataCourseAssignment);

//       // lọc các bản ghi có cùng courseSturctureItemId
//       let filteredData = newDataCourseAssignment.filter(
//         (item) => item.courseSturctureItemId === courseSturctureItemId
//       );

//       let noAccessData = newDataCourseAssignment.filter(
//         (item) =>
//           item.courseSturctureItemId !== courseSturctureItemId ||
//           item.courseSturctureItemId === null
//       );

//       // Lọc các phần tử trong mảng cũ, cái nào trong có trong mảng mới thì delete
//       for (let i = filteredData.length - 1; i >= 0; i--) {
//         const foundIndex = assigneeId.findIndex(
//           (id) => id === filteredData[i].assigneeId
//         );

//         // If the item is not found in the assigneeId array, remove it from filteredData
//         if (foundIndex === -1) {
//           filteredData.splice(i, 1);
//         }
//       }

//       //Lọc các phần tử trong mảng mới, cái nào trong mảng mới không có trong mảng cũ thì thêm
//       for (const id of assigneeId) {
//         const foundIndex = filteredData.findIndex(
//           (data) => data.assigneeId === id
//         );

//         //không có id đó trong filterData ===> thêm
//         if (foundIndex === -1) {
//           const neww = {
//             courseConstructionPlanId: coursePlanId,
//             courseSturctureItemId: courseSturctureItemId,
//             assigneeId: Number(id),
//           };

//           filteredData.push(neww);
//         }
//       }

//       setDataCoursePlanAssignment([...filteredData, ...noAccessData]);
//     }
//   };

//   const convertCourseToTreeData = (
//     course: DataTreeNodePlanAssignment,
//     index: number
//   ): TreeDataNode => {
//     const assigneeIdCourse = dataCourseAssignment.find(
//       (item) =>
//         item.courseConstructionPlanId === course.plan.id &&
//         !item.courseSturctureItemId
//     )?.assigneeId;
//     const courseNode: TreeDataNode = {
//       title: (
//         <Row
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             width: "100%",
//           }}
//         >
//           <Col>
//             <span>{course.plan.registrationName}</span>
//           </Col>
//           <Col>
//             <Select
//               showSearch
//               placeholder="Chờ phân công"
//               value={
//                 assigneeIdCourse &&
//                 dataOptions.dataTeachers.find(
//                   (item) => Number(item.value) === assigneeIdCourse
//                 )?.label
//               }
//               options={dataOptions.dataTeachers.map((teacher, index) => ({
//                 label: teacher.label,
//                 value: teacher.value,
//               }))}
//               filterOption={(input, option) =>
//                 (option?.label?.toLowerCase() ?? "").includes(
//                   input.toLowerCase()
//                 )
//               }
//               filterSort={(optionA, optionB) =>
//                 (optionA?.label?.toLowerCase() ?? "").localeCompare(
//                   optionB?.label?.toLowerCase() ?? ""
//                 )
//               }
//               onChange={(value) =>
//                 handleChangeCourseAssignment(
//                   Number(course.plan.id),
//                   Number(value)
//                 )
//               }
//               style={{ minWidth: "200px" }}
//               // style={
//               //   assigneeIdCourse
//               //     ? { minWidth: "200px" }
//               //     : { minWidth: "200px", border: "none", outline: "none" }
//               // }
//             />
//           </Col>
//         </Row>
//       ),
//       key: `${course.plan.id}`,
//       children: [],
//       style:
//         index > 0
//           ? { borderBottom: "1px solid #5e87ff", marginTop: "35px" } //#fff6cc
//           : { borderBottom: "1px solid #5e87ff", marginTop: "5px" }, //f4f4f4
//     };

//     // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
//     course.courseStructure.forEach((chapter: TypeChapterList) => {
//       const chapterAssignList = dataCourseAssignment.filter(
//         (item) => item.courseSturctureItemId === chapter.id
//       );
//       // console.log(`chapterAssignList:::`, chapterAssignList);

//       const assigneeIdChapterList = chapterAssignList.map((item) => ({
//         value: item.assigneeId,
//         label: dataOptions.dataTeachers.find(
//           (teacher) => Number(teacher.value) === Number(item.assigneeId)
//         )?.label,
//       }));

//       // console.log(`assigneeIdChapterList::`, assigneeIdChapterList)
//       const chapterNode: TreeDataNode = {
//         title: (
//           <Row
//             style={{
//               display: "flex",
//               justifyContent: "space-between",
//               width: "100%",
//             }}
//           >
//             <Col>
//               <span>{chapter.structureItemName}</span>
//             </Col>
//             <Col>
//               <Select
//                 showSearch
//                 mode="multiple"
//                 style={{ width: "100%", minWidth: "300px" }}
//                 maxTagCount="responsive"
//                 placeholder="Chờ phân công"
//                 options={dataOptions.dataTeachers.map((teacher, index) => ({
//                   label: teacher.label,
//                   value: teacher.value,
//                 }))}
//                 filterOption={(input, option) =>
//                   (option?.label?.toLowerCase() ?? "").includes(
//                     input.toLowerCase()
//                   )
//                 }
//                 filterSort={(optionA, optionB) =>
//                   (optionA?.label?.toLowerCase() ?? "").localeCompare(
//                     optionB?.label?.toLowerCase() ?? ""
//                   )
//                 }
//                 onChange={(values, record) => {
//                   const numericValues = values.map((item) =>
//                     Number(item.value)
//                   );
//                   handleChangeChapterAssignment(
//                     Number(course.plan.id),
//                     numericValues,
//                     Number(chapter.id)
//                   );
//                 }}
//                 value={assigneeIdChapterList}
//                 labelInValue
//               />
//             </Col>
//           </Row>
//         ),
//         key: `${chapter.id}`,
//         children: [],
//         style: {
//           backgroundColor: "#e6f4ff", //#fafafa
//           marginTop: "0",
//           paddingTop: "5px",
//         },
//       };

//       // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
//       chapter.subjectList.forEach((subject: TypeSubjectList) => {
//         const subjectNode: TreeDataNode = {
//           title: (
//             <Row>
//               <Space direction="horizontal" size={4}>
//                 <BookOutlined /> <span>{subject.structureItemName}</span>
//               </Space>
//             </Row>
//           ),
//           key: `${subject.id}`,
//           switcherIcon: <CaretRightOutlined />,
//           style: {
//             backgroundColor: "#e6f4ff", //#fafafa
//             marginTop: "0",
//             paddingTop: "5px",
//           },
//           children: [],
//         };

//         // Duyệt qua từng bài học của môn học và chuyển đổi thành TreeDataNode
//         subject.lessonList.forEach((lesson: TypeCourseStructure) => {
//           const lessonNode: TreeDataNode = {
//             title: (
//               <Row>
//                 <Space direction="horizontal" size={4}>
//                   <BookOutlined /> <span>{lesson.structureItemName}</span>
//                 </Space>
//               </Row>
//             ),
//             switcherIcon: <MinusOutlined />,
//             key: `${lesson.id}`,
//             isLeaf: true,
//             style: { backgroundColor: "#e6f4ff", paddingTop: "5px" }, //#fafafa
//           };

//           // Thêm node bài học vào node môn học
//           if (subjectNode.children) {
//             subjectNode.children.push(lessonNode);
//           }
//         });

//         // Thêm node môn học vào node chapter
//         if (chapterNode.children) {
//           chapterNode.children.push(subjectNode);
//         }
//       });

//       // Thêm node môn học vào node chapter
//       if (courseNode.children) {
//         courseNode.children.push(chapterNode);
//       }
//     });

//     return courseNode;
//   };

//   const treeData: TreeDataNode[] = dataTreeNode.map((course, index) =>
//     convertCourseToTreeData(course, index)
//   );

//   const [expandAll, setExpandAll] = useState(false);
//   const [expandedKeys, setExpandedKeys] = useState<any[]>([]);

//   useEffect(() => {
//     if (expandAll) {
//       // Tạo mảng chứa tất cả các khóa của nút trong cây
//       const allNodeKeys = getAllNodeKeys(treeData);
//       setExpandedKeys(allNodeKeys);
//     } else {
//       setExpandedKeys([]);
//     }
//   }, [expandAll]);

//   // Hàm đệ quy để lấy tất cả các khóa của nút trong cây
//   const getAllNodeKeys = (nodes: TreeDataNode[]): string[] => {
//     let keys: string[] = [];
//     nodes.forEach((node) => {
//       keys.push(node.key as string);
//       if (node.children) {
//         keys = keys.concat(getAllNodeKeys(node.children));
//       }
//     });
//     return keys;
//   };

//   const onSelect = (selectedKeys: React.Key[], info: any) => {
//     // console.log("selected", selectedKeys, info);
//   };

//   // const filteredTreeData: TreeDataNode[] = dataTreeNode.map((course, index) =>
//   //   convertCourseToTreeData(course, index)
//   // );

//   const dataList: { key: React.Key; title: string }[] = [];
//   const generateList = (data: TreeDataNode[]) => {
//     for (let i = 0; i < data.length; i++) {
//       const node = data[i];
//       const { key } = node;
//       dataList.push({ key, title: key as string });
//       if (node.children) {
//         generateList(node.children);
//       }
//     }
//   };

//   // const filteredTreeData = useMemo(() => {
//   //   const loop = (data: TreeDataNode[]): TreeDataNode[] =>
//   //     data.map((item) => {
//   //       const strTitle = item.title as string;
//   //       console.log(strTitle)
//   //       const index = strTitle.indexOf(keywordSearchPlanAssignment);
//   //       const beforeStr = strTitle.substring(0, index);
//   //       const afterStr = strTitle.slice(index + keywordSearchPlanAssignment.length);
//   //       const title =
//   //         index > -1 ? (
//   //           <span>
//   //             {beforeStr}
//   //             <span className="site-tree-search-value">{keywordSearchPlanAssignment}</span>
//   //             {afterStr}
//   //           </span>
//   //         ) : (
//   //           <span>{strTitle}</span>
//   //         );
//   //       if (item.children) {
//   //         return { title, key: item.key, children: loop(item.children) };
//   //       }

//   //       return {
//   //         title,
//   //         key: item.key,
//   //       };
//   //     });

//   //   return loop(treeData);
//   // }, [keywordSearchPlanAssignment]);

//   const [isFilterSearch, setIsFilterSearch] = useState(false);

//   const customStringify = (obj: any): string => {
//     const seen = new WeakSet();
//     return JSON.stringify(obj, (key, value) => {
//       if (typeof value === "object" && value !== null) {
//         if (seen.has(value)) {
//           return;
//         }
//         seen.add(value);
//       }
//       return value;
//     });
//   };

//   const loop = (data: TreeDataNode[]): TreeDataNode[] =>
//     data.map((item) => {
//       const strTitle = customStringify(item);
//       // console.log(item);
//       if (item.children && strTitle.includes(keywordSearchPlanAssignment)) {
//         return {
//           title: item.title,
//           key: item.key,
//           style: item.style,
//           children: loop(item.children),
//         };
//       }

//       return {
//         title: null,
//         key: item.key,
//         style: item.style,
//       };
//     });

//   const filteredTreeData = loop(treeData);

//   // console.log(`filteredTreeData:::`, filteredTreeData);
//   // console.log(`treeData:::`, treeData);
//   console.log(`keywordSearchPlanAssignment:::`, keywordSearchPlanAssignment);

//   const handleSearchByWord = () => {
//     setIsFilterSearch(true);
//   };

//   return (
//     <div>
//       <Input.Search
//         placeholder="Nhập từ khóa cần tìm"
//         value={keywordSearchPlanAssignment}
//         onChange={(e) => setKeywordSearchPlanAssignment(e.target.value)}
//         onSearch={handleSearchByWord}
//       />
//       <Divider />
//       <Tree
//         showIcon={true}
//         style={{ fontSize: 15 }}
//         defaultExpandedKeys={["0-0-0-0"]}
//         expandedKeys={expandedKeys}
//         onExpand={(e) => setExpandedKeys(e)}
//         onSelect={onSelect}
//         // treeData={treeData}
//         treeData={isFilterSearch ? filteredTreeData : treeData}
//         blockNode
//       />
//     </div>
//   );
// };
// export default DetailCoursePlanAssignV2;
