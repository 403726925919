import { Button, Col, Collapse, CollapseProps, Dropdown, Input, MenuProps, Modal, Row, Space, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CopyOutlined,
  DeleteOutlined,
  MoreOutlined,
  SaveOutlined,
  SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import "./survey-list-core.scss";
import FormInput from '../../../../../../components/form-input/FormInput';
import { QuestionProgressModeClick } from '../../../../../../constants';
import { deleteCourseUnit, getAllUnitCourse, getUnitDetail } from '../../../../../../service/course-construct';
import { useAddLesson } from '../../../../../../stores/stores';
import { SortableList } from '../../../../../../components/SortableList';
import { HelpService } from '../../../../../../service/helper.service';
import { addUnitBank, updateOrderInsideUnit } from '../../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz';
import { duplicateUnit } from '../../../../../../service/content-course/contenCourseApi';

type Props = {
  setTab: (value: string) => void;
  tab: string;
  setDataLessonId: (value: number) => void;
  setIsShowModal: (value: boolean) => void
}

export default function SurveyListCore(props: Props) {
  const params = useParams();
  const helpService = new HelpService();

  const setReviewList = useAddLesson((state) => state.setReviewList);
  const setModeClick = useAddLesson((state) => state.setModeClick);

  const [lessonDataList, setLessonDataList] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [isDuplicateModalOpen, setDuplicateIsModalOpen] = useState<boolean>(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [idQuizToAdd, setIdQuizToAdd] = useState<number>(0);
  const [bankCodeValue, setBankCodeValue] = useState<string>('');

  const [exerciseList, setExerciseList] = useState<any>([]);
  const [testList, setTestList] = useState<any>([]);
  const [compositionList, setCompositionList] = useState<any>([]);
  const [sectionId, setSectionId] = useState<number>(0);
  const [sequenceId, setSequenceId] = useState<number>(0);
  const [unitName, setUnitName] = useState<string>('');
  const [loadingQuizBlock, setLoadingQuizBlock] = useState<boolean>(false);
  const [loadingAddUnitToBank, setLoadingAddUnitToBank] = useState<boolean>(false);
  const [loadingCloneUnit, setLoadingCloneUnit] = useState<boolean>(false);

  useEffect(() => {
    if (props.tab === '1') {
      getDetailCourseData();
      setModeClick(QuestionProgressModeClick.START);
    }
  }, [props.tab])

  const getDetailCourseData = async () => {
    setLoadingQuizBlock(true);
    try {
      await getAllUnitCourse(params.id).then((res) => {
        const { data } = res;
        setLessonDataList(data);
        setExerciseList(data.filter((item: any) => item.type === 1).sort((a: any, b: any) => a.orderNumberInside && b.orderNumberInside ? a.orderNumberInside - b.orderNumberInside : 0));
        setTestList(data.filter((item: any) => item.type === 2).sort((a: any, b: any) => a.orderNumberInside && b.orderNumberInside ? a.orderNumberInside - b.orderNumberInside : 0));
        setCompositionList(data.filter((item: any) => item.type === 3).sort((a: any, b: any) => a.orderNumberInside && b.orderNumberInside ? a.orderNumberInside - b.orderNumberInside : 0));
        setLoadingQuizBlock(false);
      });
    } catch (error) {
    }
  };

  const getLesson = (item: any) => {
    setReviewList(item)
    setActiveTab(item.id)
    props.setDataLessonId(item.id)
    props.setTab("2")
    setModeClick(QuestionProgressModeClick.INPROGRESS);
  }

  const optionsDetails: MenuProps["items"] = [
    {
      label: "Lưu vào ngân hàng đề",
      key: "1",
      icon: <SaveOutlined />,
    },
    {
      key: "2",
      label: "Nhân bản",
      icon: <CopyOutlined />,
    },
    {
      key: "3",
      label: "Xoá",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const handleChildDropDown = (key: string, item: any) => {
    if (key === "1") {
      showSaveUnitToBankModal(item);
    }
    if (key === "2") {
      handleGetUnitDetail(item);
    }
    if (key === "3") {
      Modal.confirm({
        centered: false,
        title: `Xóa bộ câu hỏi`,
        content: `Dữ liệu đã xóa sẽ không thể khôi phục. Bạn chắc chắn muốn xóa câu hỏi ?`,
        onOk: async () => {
          try {
            await deleteCourseUnit(item.id).then((res) => {
              const { data } = res;
              helpService.successMessage(data.data);
              getDetailCourseData();
            });
          } catch (err) {
            console.error(err);
          }

        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        okButtonProps: {
          className: "ant-btn-dangerous",
        },
        icon: (
          <WarningOutlined style={{ color: "#FF4D4F" }} />
        ),
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    }
  }

  const genExtraChild = (item: any) => (
    <Space className="dropdown-course-browse">
      <Dropdown
        trigger={["click"]}
        className="dropdown-course-browse"
        menu={{
          items: optionsDetails,
          onClick: ({ key }) => {
            handleChildDropDown(key, item)
          },
        }}
        placement="bottomRight"
      >
        <MoreOutlined />
      </Dropdown>
    </Space>
  );

  const handleDragChange = async (type: number, values: any) => {
    helpService.loadingProcess();
    setLoadingQuizBlock(true);
    const newValues = values.map((item: any, index: number) => {
      return {
        ...item,
        orderNumberInside: index + 1
      };
    });
    if (type === 1) {
      setExerciseList(values);
    }

    if (type === 2) {
      setTestList(values);
    }

    if (type === 3) {
      setCompositionList(values);
    }

    const newOrderNumber = newValues.map((item: any) => {
      return {
        unitId: item.id,
        orderNumberInside: item.orderNumberInside,
      };
    });
    handleDragApi(newOrderNumber);
  };

  const handleDragApi = async (values: any) => {
    try {
      await updateOrderInsideUnit(values).then((res) => {
        const { data } = res;
        helpService.closeProcess();
        helpService.successMessage(data.message);
        setLoadingQuizBlock(false);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const showSaveUnitToBankModal = (item: any) => {
    setIsSaveModalOpen(true);
    setIdQuizToAdd(item.id);
    setBankCodeValue(transformString(item.name));
  };

  const removeVietnameseAccents = (str: string) => {
    let lowerStr = str.toLowerCase();
    const accentsMap = [
      { base: 'a', letters: /[áàảãạâấầẩẫậăắằẳẵặ]/g },
      { base: 'e', letters: /[éèẻẽẹêếềểễệ]/g },
      { base: 'i', letters: /[íìỉĩị]/g },
      { base: 'o', letters: /[óòỏõọôốồổỗộơớờởỡợ]/g },
      { base: 'u', letters: /[úùủũụưứừửữự]/g },
      { base: 'y', letters: /[ýỳỷỹỵ]/g },
      { base: 'd', letters: /[đ]/g },
    ];
    accentsMap.forEach(function (map) {
      lowerStr = lowerStr.replace(map.letters, map.base);
    });
    return lowerStr;
  }

  const transformString = (str: string) => {
    str = removeVietnameseAccents(str);
    str = str.replace(/\s+/g, '_');
    return str;
  }

  const handleChangeText = (e: any) => {
    setBankCodeValue(e.target.value);
  };

  const handleSaveQuizToBankOk = async () => {
    setLoadingAddUnitToBank(true);
    if (bankCodeValue) {
      try {
        await addUnitBank({
          unitId: idQuizToAdd,
          code: bankCodeValue,
        }).then((res) => {
          const { data } = res;
          helpService.successMessage(data.message);
          setIsSaveModalOpen(false);
          setLoadingAddUnitToBank(false);
        });

      } catch (err) {
        console.error(err);
      }
    } else {
      helpService.errorMessage("Vui lòng nhập mã câu hỏi!");
      return Promise.reject(); // Trả về Promise.reject để ngăn không cho hộp thoại đóng
    }
  };

  const handleGetUnitDetail = async (item: any) => {
    setDuplicateIsModalOpen(true);
    try {
      await getUnitDetail(item.id).then((res) => {
        const { data } = res;
        setUnitName(item.name);
        setIdQuizToAdd(item.id);
        setSectionId(data.data.courseSectionId);
        setSequenceId(data.data.courseSequenceId);
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Modal duplicate question
  const handleDuplicateOk = async () => {
    setLoadingCloneUnit(true);
    try {
      await duplicateUnit({
        unitId: idQuizToAdd,
        sectionId: sectionId,
        sequenceId: sequenceId,
        name: unitName + ' - bản sao',
        description: '',
      }).then((res) => {
        setLoadingCloneUnit(false);
        setDuplicateIsModalOpen(false);
        const { data } = res;
        helpService.successMessage(data.body.message);
        getDetailCourseData();
      });

      setIsSaveModalOpen(false);
    } catch (err) {
      console.error(err);
    }
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label:
        <span>
          Bài tập <span className='number-record'>{exerciseList.length}</span>
        </span>,
      children:
        <SortableList
          items={exerciseList}
          onChange={(values: any) => {
            handleDragChange(1, values);
          }}
          renderItem={(record: any, index: number) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <div key={record.id} className={`section-record cursor-pointer ${record.id === activeTab ? 'section-record-active' : ''} flex justify-between items-center`}>
                <span onClick={() => { getLesson(record) }}>{record.name} </span>
                {genExtraChild(record)}
              </div>
            </SortableList.Item>
          )}
        />
    },
    {
      key: '2',
      label:
        <span>
          Bài kiểm tra <span className='number-record'>{testList.length}</span>
        </span>,
      children:
        <SortableList
          items={testList}
          onChange={(values: any) => {
            handleDragChange(2, values);
          }}
          renderItem={(record: any, index: number) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <div key={record.id} className={`section-record cursor-pointer ${record.id === activeTab ? 'section-record-active' : ''} flex justify-between items-center`}>
                <span onClick={() => { getLesson(record) }}>{record.name} </span>
                {genExtraChild(record)}
              </div>
            </SortableList.Item>
          )}
        />
    },
    {
      key: '3',
      label:
        <span>
          Bài thi <span className='number-record'>{compositionList.length}</span>
        </span>,
      children:
        <SortableList
          items={compositionList}
          onChange={(values: any) => {
            handleDragChange(3, values);
          }}
          renderItem={(record: any, index: number) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <div key={record.id} className={`section-record cursor-pointer ${record.id === activeTab ? 'section-record-active' : ''} flex justify-between items-center`}>
                <span onClick={() => { getLesson(record) }}>{record.name} </span>
                {genExtraChild(record)}
              </div>
            </SortableList.Item>
          )}
        />
    },
  ];

  return (
    <div className='editor-course p-2'>
      <Spin spinning={loadingQuizBlock} >
        <Row gutter={[{ xs: 8, xl: 8, md: 8 }, 8]}>
          <Col xl={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <FormInput
              placeholder="Nhập từ khoá cần tìm"
              value={searchValue}
              onChange={() => {
              }}
              afterPrefixIcon={<SearchOutlined onClick={() => {
              }} />}
            />
          </Col>
        </Row>
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 0]} className='mt-1'>
          <Col xl={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Collapse items={items} defaultActiveKey={['1']} />
          </Col>
        </Row>
      </Spin>

      {/* Modal save unit to bank*/}
      <Modal
        title="Lưu vào ngân hàng câu hỏi"
        className="save-modal"
        open={isSaveModalOpen}
        okText="Xác nhận"
        onOk={() => { handleSaveQuizToBankOk() }}
        cancelText="Hủy"
        onCancel={() => { setIsSaveModalOpen(false) }}
        confirmLoading={loadingAddUnitToBank}
      >
        <div className="save-modal-input">
          <label>Mã câu hỏi: </label>
          <Input
            onChange={handleChangeText}
            value={bankCodeValue}
            placeholder="Mã câu hỏi"
          />
        </div>
      </Modal>

      {/* Modal duplicate unit*/}
      <Modal
        title={
          <span>
            <CopyOutlined style={{ marginRight: 15, fontSize: 24, color: "#1677FF" }} />
            Tạo bản sao câu hỏi
          </span>
        }
        className="duplicate-modal"
        open={isDuplicateModalOpen}
        okText="Tạo bản sao"
        onOk={() => { handleDuplicateOk() }}
        cancelText="Hủy"
        onCancel={() => { setDuplicateIsModalOpen(false) }}
        confirmLoading={loadingCloneUnit}
      >
        <p>Bạn chắc chắn muốn tạo bản sao cho câu hỏi ?</p>
      </Modal>
    </div>
  )
}
