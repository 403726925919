import { memo, useEffect, useState } from "react";
import FormSwitch from "../../../../../../components/form-switch/FormSwitch";
import FormInputNumber from "../../../../../../components/form-input-number/FormInputNumber";
import FormSelect from "../../../../../../components/form-select/FormSelect";
import { QuestionTypeEnum, TypeCreateQuestion } from "../../../../../../constants";
import { useAddLesson } from "../../../../../../stores/stores";
import { GeneralValueProps } from "../../../../../../types/course-construction";
import { UpdateSettingsProps } from "../setting-question-course";
import { Button, Form, InputNumber } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

function GeneralSetting(props: UpdateSettingsProps) {
  const { handelChangeForm, handelUpdateSettings } = props;
  const question = useAddLesson((state) => state.question);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);
  const { settingPopupQuestion, updateTimeToPlayFromOutside } = useAddLesson(state => ({
    settingPopupQuestion: state.settingPopupQuestion,
    updateTimeToPlayFromOutside: state.updateTimeToPlayFromOutside, 
  }));

  const [timeToPlay, setTimeToPlay] = useState<number | undefined>(0);
  const [scoreOfCorrect, setScoreOfCorrect] = useState<number | undefined>(0);

  useEffect(() => {
    setTimeToPlay(settingQuestion.timeToPlay);
    setScoreOfCorrect(settingQuestion.scoreOfCorrect);

    // Nếu settingPopupQuestion.defaultQuestion là true, cập nhật timeToPlay từ store
    if (settingPopupQuestion.defaultQuestion) {
      setTimeToPlay(settingQuestion.timeToPlay); 
    }
  }, [settingQuestion, settingPopupQuestion.defaultQuestion]); 

  const renderCanEnterAnswer = () => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (question.type !== QuestionTypeEnum.SHORT_TEXT && question.type !== QuestionTypeEnum.FILE_UPLOAD && question.type !== QuestionTypeEnum.VIDEO && question.type !== QuestionTypeEnum.MP3) {
        return (<FormSwitch name="canEnterAnswer" label="Chấm điểm tự động" onChange={handelUpdateSettings} defaultValue={settingQuestion.canEnterAnswer} />);
      } else {
        return (<FormSwitch name="displayScoreCriteria" label="Nhập tiêu chí tính điểm" onChange={handelUpdateSettings} defaultValue={settingQuestion.displayScoreCriteria} />);
      }
    }
  }

  const renderIsRandomOrder = () => {
    if (question.type !== QuestionTypeEnum.SHORT_TEXT && question.type !== QuestionTypeEnum.FILE_UPLOAD && question.type !== QuestionTypeEnum.VIDEO && question.type !== QuestionTypeEnum.MP3) {
      return (<FormSwitch name="isRandomOrder" label="Thứ tự câu trả lời hiển thị ngẫu nhiên" onChange={handelUpdateSettings} defaultValue={settingQuestion.isRandomOrder} />);
    } else {
      return(<></>)
    }
  }

  const renderRandomOrder = () => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      return (
        <>
          {renderIsRandomOrder()}
          <div className="setting-question-row">
            <div className="row-item">
              <span className="row-item">Thời gian thực hiện</span>
              <Form.Item >
                <InputNumber
                  disabled={!settingPopupQuestion.defaultQuestion}
                  min={0}
                  name="timeToPlay"
                  value={timeToPlay}
                  onChange={(e: any) => {
                    handelUpdateSettings(e, 'timeToPlay');
                  }}
                  addonAfter="giây" />
              </Form.Item>
              <Form.Item>
                <Button className="btn-reset-time" onClick={() => {
                  handelUpdateSettings(0, 'timeToPlay');
                }}>
                  <ReloadOutlined className="icon-reset-time" />
                </Button>
              </Form.Item>
            </div>
          </div>
        </>
      )
    }
  }

  const renderMarkNumber = () => {
    if (question.type === QuestionTypeEnum.CONNECT) {
      if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
        return (
          <>
            <FormSelect
              name="markNumber"
              label="Đánh số 1"
              defaultValue={settingQuestion.markNumber}
              onChange={handelUpdateSettings}
              options={[
                { value: 0, label: "Không có" },
                { value: 1, label: "A, B, C" },
                { value: 2, label: "a, b, c" },
                { value: 3, label: "1, 2, 3" },
                { value: 4, label: "I, II, III" },
              ]}
            />
            <FormSelect
              name="markNumber2"
              label="Đánh số 2"
              defaultValue={settingQuestion.markNumber2}
              onChange={handelUpdateSettings}
              options={[
                { value: 0, label: "Không có" },
                { value: 1, label: "A, B, C" },
                { value: 2, label: "a, b, c" },
                { value: 3, label: "1, 2, 3" },
                { value: 4, label: "I, II, III" },
              ]}
            />
          </>)
      }
    } else {
      if (question.type !== QuestionTypeEnum.FILE_UPLOAD && question.type !== QuestionTypeEnum.VIDEO && question.type !== QuestionTypeEnum.MP3) {
        return (
          <FormSelect
            name="markNumber"
            label="Đánh số"
            defaultValue={settingQuestion.markNumber}
            onChange={handelUpdateSettings}
            options={[
              { value: 0, label: "Không có" },
              { value: 1, label: "A, B, C" },
              { value: 2, label: "a, b, c" },
              { value: 3, label: "1, 2, 3" },
              { value: 4, label: "I, II, III" },
            ]}
          />
        );
      }
    }
  }

  const renderFileQuantity = () => {
    if (question.type === QuestionTypeEnum.FILE_UPLOAD || question.type === QuestionTypeEnum.VIDEO || question.type === QuestionTypeEnum.MP3) {
      return (
        <>
          {/* <FormInputNumber
            min={0}
            label="Số lượng file"
            name="fileQuantity"
            onChange={(e: any) => {
              handelUpdateSettings(e, 'fileQuantity');
            }}
          /> */}
          <FormInputNumber
            min={0}
            max={500}
            label="Dung lượng tối đa"
            addonAfter={"MB"}
            controls={false}
            name="fileCapacity"
            onChange={(e: any) => {
              handelUpdateSettings(e, 'fileCapacity');
            }}
          />
          <div className="center cm-em">Dung lượng tối đa là 500mb.</div>
        </>
      );
    }
  }

  return (
    <>
      <FormSwitch name="isVisibleToViewers" label="Hiển thị với người xem" onChange={handelUpdateSettings} defaultValue={settingQuestion.isVisibleToViewers} />
      <FormSwitch name="isRequiredAnswer" label="Bắt buộc trả lời câu hỏi" onChange={handelUpdateSettings} defaultValue={settingQuestion.isRequiredAnswer} />
      {renderCanEnterAnswer()}
      {renderRandomOrder()}
      {renderMarkNumber()}
      {renderFileQuantity()}
    </>
  );
}

export default memo(GeneralSetting);
