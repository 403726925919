import React from "react";
import { DetailTypeMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import CustomCard from "../../../../../../components/custom-card/CustomCard";
import { Badge, Typography } from "antd";

interface DetailInfoProps {
  detailData: any;
  ltsIndustry: any;
  ltsIndustryGroup: any;
}

const DetailInfo: React.FC<DetailInfoProps> = ({
  detailData,
  ltsIndustry,
  ltsIndustryGroup,
}) => {
  const handleString = (e: any[]) => {
    // console.log("object")
    // console.log(detailData);

    let title: string = "";
    if (e.length > 0) {
      e.forEach((m) => {
        title = title + m.name + ", ";
      });
      title = title.slice(0, -2);
    }

    return title;
  };

  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      default:
        return "";
    }
  };

  const handleReturnStatus = (statusCode: number) => {
    switch (statusCode) {
      case 1:
        return "Bản nháp";
      case 2:
        return "Chờ duyệt";
      case 3:
        return "Đã duyệt";
      case 4:
        return "Từ chối";
      default:
        return "";
    }
  };

  return (
    <div>
      <CustomCard
        title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              Thông tin đăng ký kế hoạch
            </Typography.Title>
            <Typography.Text className="sub-title">
              Thông tin chung về đăng ký kế hoạch xây dựng khóa học
            </Typography.Text>
          </div>
        }
        className="card-info--wrapper"
      >
        <div className="list-wrapper">
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Tên đăng ký
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                {detailData.registrationName}
              </Typography.Text>
            </div>
          </div>
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Mã đăng ký
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                {detailData.registrationCode}
              </Typography.Text>
            </div>
          </div>
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Trạng thái
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                <Badge
                  key={detailData.constructionPlanStatus}
                  color={
                    parseInt(detailData.constructionPlanStatus) === 1
                      ? "#D9D9D9"
                      : parseInt(detailData.constructionPlanStatus) === 2
                      ? "#FAAD14"
                      : parseInt(detailData.constructionPlanStatus) === 3
                      ? "#1677FF"
                      : parseInt(detailData.constructionPlanStatus) === 4
                      ? "#52C41A"
                      : "white"
                  }
                  style={{ marginRight: 5 }}
                />
                {handleReturnStatus(
                  parseInt(detailData.constructionPlanStatus)
                )}
              </Typography.Text>
            </div>
          </div>
          {ltsIndustry !== undefined && ltsIndustry.length > 0 ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Khoa
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {handleString(ltsIndustry)}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {ltsIndustryGroup !== undefined && ltsIndustryGroup.length > 0 ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Chuyên ngành
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {handleString(ltsIndustryGroup)}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {detailData.moocUniversity !== undefined &&
          detailData.moocUniversity !== null ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Trường chủ trì
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {detailData.moocUniversity.name}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Loại khóa học
            </Typography.Title>

            <div className="list-item--description">
              <div className="table-course-type">
                {parseInt(detailData.courseType) === 2 ? (
                  <div className="table-course-type-2">
                    {handleReturnCourseType(parseInt(detailData.courseType))}
                  </div>
                ) : parseInt(detailData.courseType) === 1 ? (
                  <div className="table-course-type-1">
                    {handleReturnCourseType(parseInt(detailData.courseType))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default DetailInfo;
