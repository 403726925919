import { message, Modal, Table, TableColumnsType, TableProps } from "antd";
import { useAuthStore } from "../../../../../stores/stores";
import { ChangeEvent, useEffect, useState } from "react";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateV2 } from "../../../../../utils/converDate";
import "./ManyApprovals.scss";
import TableData from "../../../../../components/table-data/TableData";
import { DataType } from "../../../../../types/listUsers";
import { getListAssign } from "../../../../../service/assign-course-construction";

import { ApprovalType } from "../../../../../types/survey-browse";

import { get, isEmpty } from "lodash";
import { HelpService } from "../../../../../service/helper.service";
import {
  approvalStatus,
  approvalStatusEum,
  courseStatusEum,
} from "../../constants/data";
import {
  approveManySurvey,
  postCoursePlanSearchAllApprove,
  postCoursePlanSearchApprove,
  postSurveySearchApprove,
} from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { FilterDateCoursePlan } from "../../../../../types/course-plan-approval";
import Item from "antd/es/list/Item";
import {
  CreareCoursePlanArroval,
  CrearteManyCoursePlanArroval,
  ManyCoursePlanArroval,
} from "../../../../../types/course-plan-managment/mooc-approval";

interface RowKeyType {
  id: number;
}
type Props = {
  open: boolean;
  handleCancel: () => void;
  listCourse: ManyCoursePlanArroval[];
};
export default function ManyApprovalsSurvey(props: Props) {
  const { open, handleCancel, listCourse } = props;
  const userId = useAuthStore((states) => states.user?.id);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [listSurveyMany, setListSurveyMany] = useState<ManyCoursePlanArroval[]>(
    []
  );
  const helpService = new HelpService();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [filterData, setFilterData] = useState<FilterDateCoursePlan>({
    submissionDate: null,
    approvalDate: null,
  });
  const handleReturnStatus = (record: any) => {
    switch (record.constructionPlanStatus) {
      // case approvalStatusEum.SAVE_DRAFT:
      //   return "Lưu bản nháp";
      case approvalStatusEum.WAIT_APPROVE:
        return "Chờ duyệt";
      case approvalStatusEum.APPROVED:
        return "Đã duyệt";
      case approvalStatusEum.REFUSE:
        return "Từ chối";
      // case approvalStatusEum.PUBLISHED:
      //   return "Đã xuất bản";
      // case approvalStatusEum.END:
      //   return "Kết thúc";
      default:
        return "";
    }
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      width: 10,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên kế hoạch xây dựng khóa học",
      sorter: (a, b) => a.registrationName.localeCompare(b.registrationName),
      width: 60,
      dataIndex: "registrationName",
    },
    {
      title: "Ngày gửi",
      width: 20,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 30,
      dataIndex: "constructionPlanStatus",
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor: approvalStatus.filter(
                  (x) => x.value == record.constructionPlanStatus
                )[0]?.color,
              }}
            ></div>
            {
              approvalStatus.filter(
                (x) => x.value == record.constructionPlanStatus
              )[0]?.text
            }
          </div>
        );
      },
    },
  ];
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
  };
  useEffect(() => {
    if (open) {
      setListSurveyMany(listCourse);
      setLoading(false);
    }
  }, [open]);
  const onOk = () => {
    if (selectedRowKeys.length > 0) {
      const dataSend: CrearteManyCoursePlanArroval[] =
        // organzationId: listSurveyMany[0]?.organzationId ?? undefined,
        (selectedRowKeys as unknown as RowKeyType[]).map((i) => {
          let data = listSurveyMany.filter((x) => x.id == i.id);
          return {
            courseIds: data[0].courseConstructionPlanId,
            individualId: data[0]?.individualId ?? undefined,
            organzationId: data[0]?.organzationId ?? undefined,
            courseStatus: approvalStatusEum.APPROVED,
            submitterId: data[0]?.submitterId ?? undefined,
          };
        });
      approveManySurvey(dataSend)
        .then((res) => {
          const { data, statusCode, message } = res.data;
          if (statusCode === 200) {
            helpService.successMessage(get(data, "message", message));
            setTimeout(() => handleCancel(), 500);
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } else {
      handleCancel();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const handleSubmitSearch = () => {
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: null,
      submitedDateTo: null,
      approvalDateFrom: null,
      approvalDateTo: null,
      type: false,
    };
    postCoursePlanSearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        setListSurveyMany(data)
      })
      .catch((err) => {
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };
  const disableButton = selectedRowKeys.length === 0 ? true : false;

  return (
    <>
      <Modal
        title="Phê duyệt nhiều Lập kế hoạch xây dựng khóa học"
        centered
        open={open}
        okText="Xác nhận phê duyệt"
        cancelText="Huỷ"
        onOk={onOk}
        onCancel={handleCancel}
        width={1000}
        okButtonProps={{ disabled: disableButton }}
      >
        <FormItemInput
          placeholder="Nhập từ khoá cần tìm"
          value={searchValue}
          onChange={handleChangeInput}
          afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
        />
        <div className="title-custom-many-approval">
          <h1 className="title-custom-many-approval-h1">Danh sách kế hoạch </h1>
          <span className="title-custom-many-approval-span">
            (Đã chọn {selectedRowKeys.length}/{listSurveyMany.length} khảo sát)
          </span>
        </div>
        <TableData
          dataTable={listSurveyMany}
          dataColumns={dataColumns}
          hideSelection={true}
          loadingTable={loading}
          setSelectedRowKeysProps={setSelectedRowKeys}
          handleTableChangeProps={handleTableChange}
        />
      </Modal>
    </>
  );
}
