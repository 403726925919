import React, {useState} from 'react';
import {Checkbox, Col, DatePicker, Flex, Form, FormInstance, InputNumber, Row, Typography} from "antd";
import FormInput from "../../../../form-input/FormInput";
import './index.scss'
import vi from "antd/es/date-picker/locale/vi_VN"
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import {get} from "lodash";


type CreateClassProps = {
  form: FormInstance,
  isEdit: boolean,
  dataOptions?: any,
  dataDetail: any,
}

const validations = {
  name: {
    required: true,
    message: 'Vui lòng nhập tên lớp'
  },
  startTime: {
    required: true,
    message: "Vui lòng chọn giờ ghi danh"
  },
  startOpenTime: {
    required: true,
    message: "Vui lòng chọn giờ khai giảng"
  }
}

const locale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ"
  },
  timePickerLocale: {
    placeholder: "Chọn giờ"
  },
}

const optionTime = [
  {
    id: 10,
    name: "Ngày",
  },
  {
    id: 20,
    name: "Tuần",
  },
  {
    id: 30,
    name: "Tháng"
  }
];

const optionsTeacher = [
  {
    id: 1,
    name: "Giảng Viên 1"
  },
  {
    id: 2,
    name: "Giảng Viên 2"
  },
  {
    id: 3,
    name: "Giảng Viên 3"
  }
]

const CreateClass = ({form, dataOptions, isEdit, dataDetail}: CreateClassProps) => {
  const [selectStartedDate, setStartedDate] = useState(get(dataDetail, 'enrolledDate', ''))
  const [selectEndDate, setSelectEndDate] = useState()
  const [selectStartedTime, setStartedTime] = useState(dayjs(get(dataDetail, 'enrolledDate', '')))
  const [selectEndTime, setEndTime] = useState(dayjs(get(dataDetail, 'enrolledDate', '')))

  dayjs.extend(customParseFormat);


  const handleChangeStartedDate = (date: any) => {
    setStartedDate(date)
  }

  const handleChangeStartedTime = (time: any) => {
    setStartedTime(time)
  }

  const handleChangeEndedTime = (time: any) => {
    setEndTime(time)
  }

  const handleChangeEndedDate = (date: any) => {

    setSelectEndDate(date)
  }
  const disabledHours = () => {
    if (!selectStartedDate) return []
    // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(selectStartedDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past hours
      // @ts-ignore
      return [...Array(currentDate.hour()).keys()];
    }
    return []
  };

  const disabledMinutes = () => {
    if (!selectStartedDate) return []
    const currentDate = dayjs();
    const selectedDay = dayjs(selectStartedDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past minutes
      // @ts-ignore
      return [...Array(currentDate.minute()).keys()];
    }
    return []
  }

  const disableHoursEndDate = () => {
    if (!selectEndDate) return []
    // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(selectEndDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past hours
      // @ts-ignore
      return [...Array(currentDate.hour()).keys()];
    }
    return []
  };

  const disabledMinutesEndDate = () => {
    if (!selectEndDate) return []
    const currentDate = dayjs();
    const selectedDay = dayjs(selectEndDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past minutes
      // @ts-ignore
      return [...Array(currentDate.minute()).keys()];
    }
    return []
  }

  const disableDays = (currentDate: any) => {
    if (!selectStartedDate) return currentDate && currentDate.isBefore(dayjs().subtract(1, "day"))
    if (selectStartedDate) {
      return currentDate && currentDate.isBefore(dayjs(selectStartedDate))
    }
  }


  return (
    <>
      <Form
        form={form}
        name="createEnrolledClass"
        className="create-enrolled--class"
      >
        <Typography.Title level={5}>Thông tin chung</Typography.Title>
        <Row gutter={16}>
          <Col className="col-table-create" span={24}>
            <Form.Item
              name="name"
              rules={[validations.name]}
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign="left"
              label="Tên lớp"
            >
              <FormInput disabled={isEdit} placeholder="Nhập tên lớp"/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={24}>
            <Row>
              <Col span={6}>
                <label>Hình thức</label>
              </Col>
              <Col span={18}>
                <Form.Item
                  name="form"
                >
                  <Checkbox.Group>
                    <Flex gap="small" align="baseline">
                      <Checkbox value={"10"} disabled={isEdit}/>
                      <Flex vertical={true}>
                        <span className="title-checkbox">
                          Trực tuyến
                        </span>
                        <span className="sub-title">
                          Người học tham gia đánh giá trên nền tảng MOOC
                        </span>
                      </Flex>
                    </Flex>
                    <Flex gap="small" align="baseline">
                      <Checkbox value={"20"} disabled={isEdit}/>
                      <Flex vertical={true}>
                        <span className="title-checkbox">
                          Trực tiếp
                        </span>
                        <span className="sub-title">
                          Người học tham gia đánh giá trực tiếp tại Cơ sở đào tạo
                        </span>
                      </Flex>
                    </Flex>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="col-table-create" span={12}>
            <Form.Item
              name="numberStudent"
              labelCol={{span: 12}}
              wrapperCol={{span: 9}}
              labelAlign="left"
              label="Số lượng học viên tối đa"
            >
              <InputNumber min={0} disabled={isEdit}/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={12}>
            <Form.Item
              name="numberOfSession"
              labelCol={{span: 15}}
              wrapperCol={{span: 9}}
              labelAlign="left"
              label="Số buổi"
            >
              <InputNumber min={0} disabled={isEdit}/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={24}>
            <Form.Item
              name="instructorTeachers"
              label="Giảng viên"
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign="left"
            >
              <FormItemDropdown options={optionsTeacher}
                                modeDropdown="multiple"
                                disabled={isEdit}
                                placeholder="Chọn giảng viên"/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={10}>
            <Form.Item
              name="expectedTime"
              label="Nỗ lực mong chờ"
              labelCol={{span: 15}}
              wrapperCol={{span: 9}} labelAlign="left"
            >
              <InputNumber min={0} disabled={isEdit}/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={4}>
            <Form.Item name="expectedTimeType">
              <FormItemDropdown options={optionTime} disabled={isEdit}/>
            </Form.Item>
          </Col>
        </Row>
        <Typography.Title level={5}>Thời gian ghi danh</Typography.Title>
        <Row className="form-time--wrapper" gutter={16}>
          <Col span={6}>
            <label htmlFor="enrolledDate" className="label-required"
            >Ngày mở ghi danh:
            </label>
          </Col>
          <Col span={18}>
            <Row gutter={16}>
              <Col className="col-table-create" span={16}>
                <Form.Item name="enrolledDate"
                           className="item-time"
                           getValueProps={(i) => ({value: i ? dayjs(i) : ""})}
                           dependencies={["closedDate"]}
                           rules={[{
                             required: true,
                             message: "Vui lòng chọn mở ghi danh"
                           },
                             ({getFieldValue}) => ({
                               validator(_, value) {
                                 const futureDate = getFieldValue('closedDate')
                                 if (dayjs(value).isAfter(futureDate)) {
                                   return Promise.reject(new Error("Ngày mở ghi danh không được sau ngày đóng ghi danh"))
                                 }
                                 return Promise.resolve()
                               }
                             })
                           ]}
                >
                  <DatePicker format="DD/MM/YYYY"
                              disabledDate={(current) => current.isBefore(dayjs().subtract(1, "day"))} locale={locale}
                              disabled={isEdit}
                              onChange={handleChangeStartedDate}/>
                </Form.Item>
              </Col>
              <Col className="col-table-create" span={8}>
                <Form.Item
                  name="enrolledTime"
                  className="item-time"
                  rules={[validations.startTime]}
                >
                  <DatePicker.TimePicker disabled={isEdit}
                                         locale={locale} disabledHours={disabledHours} disabledMinutes={disabledMinutes}
                                         value={selectStartedDate && dayjs(selectStartedDate).set('hour', 8).set('minute', 0).toDate()}
                                         onChange={handleChangeStartedTime}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <label className="label">Ngày đóng ghi danh:
            </label>
          </Col>
          <Col span={18}>
            <Row gutter={16}>
              <Col className="col-table-create" span={16}>
                <Form.Item name="closedDate"
                           className="item-time"
                >
                  <DatePicker format="DD/MM/YYYY"
                              disabledDate={(current) => disableDays(current)}
                              onChange={handleChangeEndedDate}
                              locale={locale} disabled={isEdit}/>
                </Form.Item>
              </Col>
              <Col className="col-table-create" span={8}>
                <Form.Item
                  className="item-time"
                  name="closedTime"
                >
                  <DatePicker.TimePicker locale={locale}
                                         disabledHours={disableHoursEndDate}
                                         disabledMinutes={disabledMinutesEndDate}
                                         onChange={handleChangeEndedTime}
                                         disabled={isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

        </Row>

        <Typography.Title level={5}>Thời gian khai giảng</Typography.Title>
        <Row className="form-time--wrapper" gutter={16}>
          <Col span={6}>
            <label className="label-required" htmlFor="openingDate">Ngày khai giảng:</label>
          </Col>
          <Col span={18}>
            <Row gutter={16}>
              <Col className="col-table-create" span={16}>
                <Form.Item name="openingDate"
                           className="item-time"
                           dependencies={["closingDate"]}
                           getValueProps={(i) => ({value: i ? dayjs(i) : ""})}
                           rules={[{
                             required: true,
                             message: "Vui lòng chọn ngày khai giảng"
                           },
                             ({getFieldValue}) => ({
                               validator(_, value) {
                                 const futureDate = getFieldValue('closingDate')
                                 if (!futureDate) {
                                   return Promise.resolve();
                                 }
                                 if (dayjs(value).isAfter(futureDate)) {
                                   return Promise.reject(new Error("Ngày khai giảng không được sau ngày kết thúc"))
                                 }
                                 return Promise.resolve()
                               }
                             })
                           ]}
                >
                  <DatePicker format="DD/MM/YYYY" locale={locale} disabledDate={disableDays}/>
                </Form.Item>
              </Col>
              <Col className="col-table-create" span={8}>
                <Form.Item
                  name="openingTime"
                  className="item-time"
                  rules={[validations.startOpenTime]}

                >
                  <DatePicker.TimePicker locale={locale}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <label className="label">Ngày kết thúc dự kiến:
            </label>
          </Col>
          <Col span={18}>
            <Row gutter={16}>
              <Col className="col-table-create" span={16}>
                <Form.Item name="closingDate"
                           className="item-time"
                >
                  <DatePicker format="DD/MM/YYYY" locale={locale} disabledDate={disableDays}/>
                </Form.Item>
              </Col>
              <Col span={8} className="col-table-create">
                <Form.Item
                  name="closingTime"
                  className="item-time"
                >
                  <DatePicker.TimePicker locale={locale} disabledHours={disableHoursEndDate}
                                         disabledMinutes={disabledMinutesEndDate}

                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateClass;
