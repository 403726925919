import DOMPurify from "dompurify";
import { LESSON_TYPE } from "../constants";
import { isEmpty } from "lodash";

export function convertMinutesToTimeString(
  minutes?: number,
  format?: "hh:mm:ss" | "mm:ss"
) {
  if (!minutes) return "0 giờ 00 phút";
  if (format) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const seconds = 0;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = mins.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    if (format === "mm:ss") return `${formattedMinutes}:${formattedSeconds}`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours.toString().padStart(2, "0")} giờ ${remainingMinutes
    .toString()
    .padStart(2, "0")} phút`;
}

export function bytesToMegabytesBinary(bytes: number) {
  return (bytes / 1048576).toFixed(3);
}

export function formatSecondTimeToString(seconds: number | undefined | null) {
  if (!seconds) return "00:00:00";
  // Tính số giờ, phút và giây
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = seconds % 60;

  // Chuyển đổi mỗi giá trị thành chuỗi và thêm số '0' nếu cần
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSeconds = secondsLeft.toString().padStart(2, "0");

  // Kết hợp thành chuỗi định dạng "hh:mm:ss"
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}

export const mapCourseInfoBeToFe = (course: any) => {
  if (isEmpty(course)) return {};

  return {
    id: course.id,
    image: "",
    school: course.universityName,
    hours: course.completedTime || 0,
    title: course.name,
    price: course.cost || 0,
    teachers: course.teachers.map(
      (teacher: any) => `${teacher.firstName} ${teacher.lastName}`
    ),
    slug: course.slug,
    isPopular: true,
    star: course.avgStar || 0,
    students: course.totalStudents || 0,
    skills: [],
    description: course?.reviewDescription.description,
    isSaved: course?.isSaved,
    isRegistered: course?.isRegistered,
    completedPercentage: course?.completedPercentage,
  };
};

export const renderLessonIconByType = (type?: number) => {
  switch (type) {
    case LESSON_TYPE.DONE:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_1_34135)">
        <path d="M18.3334 9.23745V10.0041C18.3324 11.8011 17.7505 13.5497 16.6745 14.989C15.5986 16.4283 14.0862 17.4812 12.3629 17.9907C10.6396 18.5002 8.7978 18.439 7.11214 17.8163C5.42648 17.1935 3.98729 16.0425 3.00922 14.535C2.03114 13.0275 1.56657 11.2442 1.68481 9.45105C1.80305 7.65792 2.49775 5.95106 3.66531 4.58501C4.83288 3.21897 6.41074 2.26694 8.16357 1.87091C9.91641 1.47488 11.7503 1.65607 13.3918 2.38745M18.3334 3.33268L10.0001 11.6743L7.50008 9.17435" stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_1_34135">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>`;
    case LESSON_TYPE.VIDEO:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_498_20117)">
        <path d="M10.0001 18.3327C14.6025 18.3327 18.3334 14.6017 18.3334 9.99935C18.3334 5.39698 14.6025 1.66602 10.0001 1.66602C5.39771 1.66602 1.66675 5.39698 1.66675 9.99935C1.66675 14.6017 5.39771 18.3327 10.0001 18.3327Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.91675 7.47046C7.91675 7.07272 7.91675 6.87385 7.99987 6.76283C8.0723 6.66608 8.18318 6.60555 8.30373 6.59694C8.44207 6.58705 8.60935 6.69459 8.94392 6.90967L12.8777 9.43856C13.1681 9.6252 13.3132 9.71852 13.3634 9.83717C13.4072 9.94085 13.4072 10.0578 13.3634 10.1615C13.3132 10.2802 13.1681 10.3735 12.8777 10.5601L8.94392 13.089C8.60935 13.3041 8.44207 13.4116 8.30373 13.4018C8.18318 13.3932 8.0723 13.3326 7.99987 13.2359C7.91675 13.1248 7.91675 12.926 7.91675 12.5282V7.47046Z" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_498_20117">
        <rect width="20" height="20" fill="white"/>
        </clipPath>
        </defs>
        </svg>`;
    case LESSON_TYPE.READ:
      return `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M10.0001 17.5L9.9167 17.3749C9.33783 16.5066 9.0484 16.0725 8.666 15.7582C8.32746 15.4799 7.93739 15.2712 7.51809 15.1438C7.04446 15 6.52267 15 5.4791 15H4.33341C3.39999 15 2.93328 15 2.57676 14.8183C2.26316 14.6586 2.00819 14.4036 1.8484 14.09C1.66675 13.7335 1.66675 13.2668 1.66675 12.3333V5.16667C1.66675 4.23325 1.66675 3.76654 1.8484 3.41002C2.00819 3.09641 2.26316 2.84144 2.57676 2.68166C2.93328 2.5 3.39999 2.5 4.33341 2.5H4.66675C6.53359 2.5 7.46701 2.5 8.18005 2.86331C8.80726 3.18289 9.31719 3.69282 9.63677 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333M10.0001 17.5V7.83333M10.0001 17.5L10.0835 17.3749C10.6623 16.5066 10.9518 16.0725 11.3342 15.7582C11.6727 15.4799 12.0628 15.2712 12.4821 15.1438C12.9557 15 13.4775 15 14.5211 15H15.6667C16.6002 15 17.0669 15 17.4234 14.8183C17.737 14.6586 17.992 14.4036 18.1518 14.09C18.3334 13.7335 18.3334 13.2668 18.3334 12.3333V5.16667C18.3334 4.23325 18.3334 3.76654 18.1518 3.41002C17.992 3.09641 17.737 2.84144 17.4234 2.68166C17.0669 2.5 16.6002 2.5 15.6667 2.5H15.3334C13.4666 2.5 12.5332 2.5 11.8201 2.86331C11.1929 3.18289 10.683 3.69282 10.3634 4.32003C10.0001 5.03307 10.0001 5.96649 10.0001 7.83333" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`;
    default:
      return "";
  }
};

export function formatCurrency(value: any) {
  // Sử dụng hàm toLocaleString() để định dạng giá trị tiền theo locale của người dùng
  // Ví dụ: 'en-US' cho tiền tệ Mỹ, 'vi-VN' cho tiền tệ Việt Nam
  // Cần chú ý rằng một số quốc gia có phần phụ định dạng khác nhau, như việc sử dụng dấu phẩy thay vì dấu chấm để phân tách hàng nghìn
  // Nên cần kiểm tra locale của ứng dụng hoặc truyền vào tham số cho hàm formatCurrency()
  return value?.toLocaleString("vi-VN", {
    style: "currency",
    currency: "VND", // Thay đổi thành mã tiền tệ mong muốn (ví dụ: 'EUR' cho euro, 'GBP' cho bảng Anh, ...)
  });
}

export const convertSomeObjKeyToArray = (obj: any, key: any) => {
  const distArrWithKey: any = [];
  const distArrWithoutKey: any = [];

  for (const objKey in obj) {
    if (key && objKey.includes(key)) {
      distArrWithKey.push(obj[objKey]);
    }
    distArrWithoutKey.push(obj[objKey]);
  }

  if (key) return distArrWithKey;
  return distArrWithoutKey;
};

export const getCleanText = (value: string) => {
  return DOMPurify.sanitize(value);
};

export function convertNumberingTypeToText(number: number, formatType: number) {
  const upperAlphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowerAlphabet = "abcdefghijklmnopqrstuvwxyz";
  const romanNumerals = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
    "XVI",
    "XVII",
    "XVIII",
    "XIX",
    "XX",
  ];

  switch (formatType) {
    case 1:
      return upperAlphabet[number - 1] || "";
    case 2:
      return lowerAlphabet[number - 1] || "";
    case 3:
      return number.toString();
    case 4:
      return romanNumerals[number - 1] || "";
    default:
      return "";
  }
}

export const renderFileType = (type: string) => {
  switch (type) {
    case "xlsx":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "xlxs-icon.svg";
    case "xls":
    case "application/vnd.ms-excel":
      return "xls-icon.svg";
    case "ppt":
    case "application/vnd.ms-powerpoint":
      return "ppt-icon.svg";
    case "pptx":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return "pptx-icon.svg";
    case "doc":
    case "application/msword":
      return "doc-icon.svg";
    case "docx":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docx-icon.svg";
    case "application/pdf":
    case "pdf":
      return "pdf-icon.svg";
    default:
      break;
  }
};
