import { MenuUnfoldOutlined, SettingOutlined } from "@ant-design/icons";
import { Collapse, Form } from "antd";
import { get, isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { initialQuestionSettingValue, ModeQuestion, TypeCreateQuestion } from "../../../../../constants";
import { useAddLesson } from "../../../../../stores/stores";
import { QuestionData, SettingQuestionProps } from "../../../../../types/course-construction";
import FeedbackSetting from "./feedback-setting/FeedbackSetting";
import GeneralSetting from "./genaral-setting/GeneralSetting";
import HintSetting from "./hint-setting/HintSetting";
import ScoreSetting from "./score-setting/ScoreSetting";
import './setting-question-course.scss';
import { useDirty } from "../../../../../contexts/DirtyProvider";
const { Panel } = Collapse;

export type UpdateSettingsProps = {
  handelChangeForm: (val: any, name: string, cb?: any) => void;
  handelUpdateSettings: (val: any, name: string, cb?: any) => void;
};

const SettingQuestionCourse = () => {
  const { flag, toggleFlag } = useAddLesson((state) => state);
  const setSettingQuestion = useAddLesson((state) => state.setSettingQuestion);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const questionSettingBe = useAddLesson((state) => state.questionSettingsBe);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const question = useAddLesson((state: any) => state.question);
  const [questionData, setQuestionData] = useState<QuestionData>({} as QuestionData);

  const [formSettings] = Form.useForm();
  const [formDisable, setFormDisable] = useState<boolean>(true);
  const [titleSetting, setTitleSetting] = useState<string>('');
  const [nameGeneralSetting, setNameGeneralSetting] = useState<string>('');
  const { setDirty } = useDirty();

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      setTitleSetting('Thiết lập');
      setNameGeneralSetting('Thiết lập chung');
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setTitleSetting('Cài đặt');
      setNameGeneralSetting('Cài đặt hoạt động');
    }
  }, [typeCreateQuestion]);

  useEffect(() => {
    setFormDisable(true);
    if (modeQuestion === ModeQuestion.CREATE) {
      setFormDisable(false);
      const newInitialQuestionSettingValue = initialQuestionSettingValue;
      if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
        newInitialQuestionSettingValue.displayScoreCriteria = false;
      }
      setSettingQuestion(newInitialQuestionSettingValue);
      formSettings.resetFields();
    }

    if (modeQuestion === ModeQuestion.UPDATE) {
      setFormDisable(false);
      const initValues = mapSettingBeToFe(get(questionSettingBe, "body", {}));
      setSettingQuestion(initValues);
      formSettings.setFieldsValue(initValues);
    }
  }, [questionSettingBe]);

  const handelChangeForm = (newValue: any, name: string) => {
    formSettings.setFieldValue(name, newValue);
  };

  const handelUpdateSettings = (newValue: any, name: string, cb?: any) => {
    setSettingQuestion({
      [name]: newValue,
    });
    cb && cb(newValue);
  }

  const mapSettingBeToFe = (beSetting: QuestionData) => {
    if (!isEmpty(beSetting)) {
      const {
        settingGeneral,
        settingHint,
        settingPoint,
        settingResponse,
      } = beSetting;
      return {
        isVisibleToViewers: settingGeneral?.showForViewer,
        isRequiredAnswer: settingGeneral?.requiredAnswer,
        canEnterAnswer: settingGeneral?.haveCorrectAnswer,
        isRandomOrder: settingGeneral?.shuffleQuestion,
        markNumber: settingGeneral?.numberingType,
        markNumber2: settingGeneral?.numberingType2,
        fileCapacity: settingGeneral?.fileCapacity,
        fileQuantity: settingGeneral?.fileQuantity,
        displayScoreCriteria: settingGeneral?.displayScoreCriteria,
        timeToPlay: settingGeneral?.timeToPlay,

        scoreCriteria: settingPoint?.scoreCriteria,
        isSetScore4EachAns: settingPoint?.isPerQuiz,
        totalScore: settingPoint?.totalScore,
        scoreOfCorrect: settingPoint?.correct,
        scoreOfInCorrect: settingPoint?.incorrect,

        isShowGuide: settingHint?.isDisplayInstruction,
        hintContent: settingHint?.content,
        uri: settingHint?.uri,
        schema: settingHint?.schema,

        isFeedback4EachAns: settingResponse?.isDisplayPerResponse,
        correctAns: settingResponse?.correct,
        incorrectAns: settingResponse?.incorrect,
        notAnswer: settingResponse?.notYet,
      };
    }
    return {};
  };

  const arrSection = [
    {
      title: (<span>{nameGeneralSetting}</span>),
      content: <GeneralSetting handelChangeForm={handelChangeForm} handelUpdateSettings={handelUpdateSettings} />,
      mode: [TypeCreateQuestion.COURSEWARE, TypeCreateQuestion.SURVEY],
    },
    {
      title: "Thiết lập điểm",
      content: <ScoreSetting handelChangeForm={handelChangeForm} handelUpdateSettings={handelUpdateSettings} />,
      mode: [TypeCreateQuestion.COURSEWARE],
    },
    {
      title: "Thiết lập hướng dẫn",
      content: <HintSetting handelChangeForm={handelChangeForm} handelUpdateSettings={handelUpdateSettings} />,
      mode: [TypeCreateQuestion.COURSEWARE],
    },
    {
      title: "Thiết lập phản hồi",
      content: <FeedbackSetting handelChangeForm={handelChangeForm} handelUpdateSettings={handelUpdateSettings} />,
      mode: [TypeCreateQuestion.COURSEWARE],
    },
  ];

  return (
    <div className={`setting-question-course ${flag ? "" : "tab-closed w-50px"}`}>
      <div className="setting-question-course__header flex">
        <MenuUnfoldOutlined
          className="setting-question-course__header__first"
          rotate={flag ? 0 : 180}
          onClick={toggleFlag}
        />
        <SettingOutlined className="setting-question-course__header__second" />
        <div className="setting-question-course__header__title">{titleSetting}</div>
      </div>
      <div
        className="setting-question-course__content"
        style={{ visibility: flag ? "visible" : "hidden" }}
      >
        <Form
          form={formSettings}
          initialValues={initialQuestionSettingValue}
          disabled={formDisable}
          onValuesChange={() => {
            setDirty(true);
          }}
        >
          <Collapse defaultActiveKey={[0]} >
            {arrSection.map((el, index) => {
              if (el.mode.find((x) => { return x === typeCreateQuestion })) {
                return (
                  <Panel collapsible={formDisable ? 'disabled' : 'header'} key={index} header={el?.title}>
                    <div className="sub-content">{el?.content}</div>
                  </Panel>
                );
              }
            })}
          </Collapse>
        </Form>
      </div>
    </div>
  );
};

export default SettingQuestionCourse;
