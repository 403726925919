import {
  Badge,
  Button,
  Col,
  Collapse,
  Form,
  Layout,
  Modal,
  Radio,
  Row,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSurveyInfo,
  getSurveyObjectsFilterById,
} from "../../../../../service/survey-managment/list-survey";
import { useEffect, useState } from "react";
import message from "antd/lib/message";
import { DetailContent } from "../../../../survey-managment/mooc-survey/components/detail-content/DetailContent";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import "./index.scss";
import { CaretDownOutlined, FileDoneOutlined } from "@ant-design/icons";
import {
  getdataIndustryGroup,
  getIndustry,
  getUniversity,
} from "../../../../../service/common";
import moment from "moment";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { get } from "lodash";
import toolbarOptions from "../../../common/editor-quill/toolbarOptions";
import ReactQuill from "react-quill";
import {
  CreareCoursePlanArroval,
  InfoMoocCoursePlanArroval,
  InfoResultArroval,
  InfoSendArroval,
} from "../../../../../types/course-plan-managment/mooc-approval";
import {
  approveCoursePlan,
  getInfoPlanApproval,
} from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { approvalStatus, approvalStatusEum } from "../../constants/data";
import { routesConfig } from "../../../../../config/routes";
type InforUser = {
  userName: string;
  approvalDate: string;
};
type Props = {
  title: string;
  contentConfirm: string;
  messageSuccess: string;
  navigationUrl: string;
  listInfor: InforUser;
};
const CourseApproval = (props: Props) => {
  const { title, contentConfirm, messageSuccess, navigationUrl, listInfor } =
    props;
  const params = useParams();
  const { Text } = Typography;
  const { Panel } = Collapse;
  console.log(listInfor.userName);
  const navigation = useNavigate();
  const inForApproval = props.listInfor;
  const [listInfoSurvey, setListInfoSurvey] = useState<any[]>();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataFilter: {},
  });
  const [infoResultApproval, setInfoResultApproval] =
    useState<InfoResultArroval>({
      approvalStatus: 1,
      userArrovel: undefined,
      approvalDate: undefined,
      approvalRemark: undefined,
    });
  const [infoSendApproval, setInfoSendApproval] = useState<InfoSendArroval>({
    unniversityName: undefined,
    submissionDate: undefined,
    submitterName: undefined,
  });
  const [dataSendInfo, setDataSendInfo] = useState<CreareCoursePlanArroval>({});
  const [listInfoResultApproval, setListInfoResultApproval] = useState<any>();
  const [listInfoSendApproval, setListInfoSendApproval] = useState<any[]>();
  const [form] = Form.useForm();
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const [isRequiredRemark, setIsRequiredRemark] = useState(false);
  const renderSwitchApproval = (value: string) => {
    switch (value) {
      case "unniversityName":
        return "Trường chủ trì: ";
      case "submitterName":
        return "Người gửi: ";
      case "submissionDate":
        return "Ngày gửi: ";
      case "submissionDate":
        return "Ngày gửi: ";
      case "userArrovel":
        return "Người duyệt: ";
      case "approvalDate":
        return "Ngày duyệt: ";
      case "approvalRemark":
        return "Mô tả: ";
      default:
        return "";
    }
  };

  const fetchDataOptions = async () => {
    const response = await Promise.all([
      getUniversity(),
      getIndustry(),
      getdataIndustryGroup(),
    ]);
    const dataUniversity = response[0].data.data;
    const dataIndustry = response[1].data.data;
    const dataIndustryGroup = response[2].data.data;
    setDataOptions({
      ...dataOptions,
      dataIndustry: dataIndustry,
      dataUniversity: dataUniversity,
      dataIndustryGroup: dataIndustryGroup,
    });
  };
  const getInfoApprovalById = async (id: any) => {
    await getInfoPlanApproval(id)
      .then((res) => {
        const resData = res.data.data;
        setInfoSendApproval({
          unniversityName: resData?.unniversityName ?? "",
          submissionDate: moment(resData?.submissionDate ?? undefined).format(
            "DD/MM/yyyy"
          ),
          submitterName: resData?.submitterName ?? "",
        });
        setInfoResultApproval({
          approvalStatus: resData.approvalStatus ?? "",
          userArrovel: resData.userArrovel ?? "",
          approvalDate: moment(resData?.approvalDate ?? undefined).format(
            "DD/MM/yyyy"
          ),
          approvalRemark: resData.approvalRemark ?? "",
        });
        setDataSendInfo({
          individualId: resData.individualId ?? undefined,
          organzationId: resData.organzationId ?? undefined,
          submitterId: resData.submitterId ?? undefined,
        });
      })
      .catch((err) => {
        message.error("Có lỗi chưa xác định");
      });
  };

  useEffect(() => {
    fetchDataOptions();
    getInfoApprovalById(params.id);
  }, []);

  useEffect(() => {
    // Object.entries. chuyển 1 đôi tượng với các thuộc tính thình mảng các thuộc tijnshn theo dạng key value
    // vdL {id:1, name:2}  => [[id,1],[name,2]]
    const convertObjectDataInfoSendApproval = Object.entries(infoSendApproval);
    const listInfoSendApproval = convertObjectDataInfoSendApproval.map(
      (item: any, index) => {
        return (
          <div className="info-row-approval" key={index}>
            <label className="label-row">
              {renderSwitchApproval(item[0])}{" "}
            </label>{" "}
            <span className="sub-title-approval-custom-card">{item[1]}</span>
          </div>
        );
      }
    );

    const listInfoReSultApproval = (
      <>
        <div className="info-row-approval">
          <label className="label-row">{infoResultApproval.userArrovel} </label>{" "}
        </div>
        <div className="info-row-approval">
          <span className="sub-title-approval-custom-card">
            {infoResultApproval.approvalDate}
          </span>{" "}
          <Badge
            color={
              approvalStatus.filter(
                (x) => x.value == infoResultApproval.approvalStatus
              )[0].color ?? null
            }
          />
          <span className="sub-title-approval-custom-card">
            {approvalStatus.filter(
              (x) => x.value == infoResultApproval.approvalStatus
            )[0].text ?? null}
          </span>
        </div>
        <div className="info-row-approval">
          <label className="label-row">{inForApproval.userName} </label>{" "}
        </div>
        <div className="info-row-approval">
          <span className="sub-title-approval-custom-card">
            {inForApproval.approvalDate}
          </span>{" "}
          <Badge
            color={
              approvalStatus.filter(
                (x) => x.value == infoResultApproval.approvalStatus
              )[0].color ?? null
            }
          />
          <span className="sub-title-approval-custom-card">
            {approvalStatus.filter(
              (x) => x.value == infoResultApproval.approvalStatus
            )[0].text ?? null}
          </span>
        </div>
        <div className="info-row-approval">
          <label className="label-row">Mô tả </label>{" "}
          <span
            style={{ fontWeight: 400, fontFamily: "roboto", fontSize: "14px" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: ` ${infoResultApproval.approvalRemark ?? ""}`,
              }}
            />
          </span>
        </div>
      </>
    );

    setListInfoSendApproval(listInfoSendApproval);
    setListInfoResultApproval(listInfoReSultApproval);
  }, [infoSendApproval, infoResultApproval, inForApproval]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin kế hoạch khóa học
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về kế hoạch xây dựng khóa học đã được duyệt
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfoSurvey}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khóa học",
      children: (
        <DetailContent>
          <h1>Cấu trúc khóa học</h1>
        </DetailContent>
      ),
    },
    {
      key: "3",
      label: "Tài liệu mô tả",
      children: (
        <DetailContent>
          <h1>Cấu trúc khóa học</h1>
        </DetailContent>
      ),
    },
  ];

  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        Modal.confirm({
          icon: <FileDoneOutlined />,
          title: `Xác nhận ${title} kế hoạch khóa học?`,
          content: (
            <>
              <p>
                Đảm bảo rằng tất cả các
                <Text type="warning">
                  {" "}
                  thông tin liên quan đã được kiểm tra và cặp nhật đầy đủ{" "}
                </Text>
                trước khi Xác nhận kết quả {contentConfirm} kế hoạch khóa học
              </p>
            </>
          ),
          onOk: () => form.submit(),
          centered: true,
          okText: "Xác nhận",
          cancelText: "Đóng",
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      })
      .catch((error) => {});
  };

  const modules = {
    toolbar: toolbarOptions,
  };
  return (
    <>
      <div className="approval-custom-card">
        <div className="approval-space"></div>
        <div className="approval-card-container">
          <CustomCard
            title={
              <div>
                <Typography.Title level={4}>Thông tin duyệt</Typography.Title>
                <Collapse defaultActiveKey={["1", "2", "3"]}>
                  <Panel header="Thông tin gửi" key="1">
                    <div style={{ margin: "8px" }}>{listInfoSendApproval}</div>
                  </Panel>
                  <Panel header="Kết quả duyệt" key="2">
                    <div style={{ margin: "8px" }}>
                      {listInfoResultApproval}
                    </div>
                  </Panel>
                  <Panel header="Kết quả phê duyệt" key="3">
                    <div style={{ margin: "8px" }}>
                      <Form.Provider
                        onFormFinish={(name, { values, forms }) => {
                          if (name === "approval") {
                            const dataSend: CreareCoursePlanArroval = {
                              userId: Number(userId),
                              courseId: Number(params.id),
                              submitterId:
                                dataSendInfo.submitterId ?? undefined,
                              organzationId:
                                dataSendInfo.organzationId ?? undefined,
                              approvalRemark: values.approvalRemark,
                              individualId:
                                dataSendInfo.individualId ?? undefined,
                              courseStatus: values.surveyStatus
                                ? approvalStatusEum.APPROVED
                                : approvalStatusEum.REFUSE,
                            };

                            approveCoursePlan(dataSend).then((res) => {
                              const { statusCode } = res.data;
                              if (statusCode === 200) {
                                message.success(messageSuccess);
                                navigation(navigationUrl);
                              }
                            });
                          }
                        }}
                      >
                        <Form
                          form={form}
                          layout="vertical"
                          initialValues={{
                            surveyStatus: true,
                          }}
                          name="approval"
                        >
                          <Row gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                name="surveyStatus"
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      if (value === true) {
                                        form.setFields([
                                          {
                                            name: "approvalRemark",
                                            errors: [],
                                          },
                                        ]);
                                        setIsRequiredRemark(false);
                                        return Promise.resolve();
                                      } else {
                                        setIsRequiredRemark(true);
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                validateTrigger={["onChange", "onBlur"]}
                              >
                                <Radio.Group>
                                  <Radio value={true}>Đồng ý</Radio>
                                  <Radio value={false}>Không đồng ý</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                name="approvalRemark"
                                label="Mô tả phê duyệt"
                                rules={[
                                  { required: isRequiredRemark, message: "" },
                                  () => ({
                                    validator(_, value) {
                                      if (
                                        isRequiredRemark &&
                                        (!value ||
                                          value
                                            .replace(/<[^>]+>/g, "")
                                            .trim() === "")
                                      ) {
                                        return Promise.reject(
                                          "Vui lòng nhập mô tả phê duyệt!"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                                validateTrigger={["onChange", "onBlur"]}
                              >
                                <ReactQuill
                                  modules={modules}
                                  theme="snow"
                                  style={{
                                    height: "200px",
                                    marginBottom: "48px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={24}>
                              <div className="btn-approval">
                                <Button
                                  onClick={() => {
                                    navigation(
                                      `/${routesConfig.coursePlan}/${routesConfig.approval}`
                                    );
                                  }}
                                >
                                  Hủy
                                </Button>
                                <Button onClick={handleOk} type="primary">
                                  Lưu
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Form.Provider>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            }
          ></CustomCard>
        </div>
      </div>
    </>
  );
};

export default CourseApproval;
