import React, {ChangeEvent, KeyboardEvent, useEffect, useMemo, useState} from 'react';
import {Button, Dropdown, Form, Layout, message, Popconfirm, Space, Switch, TableColumnsType, TableProps} from "antd";
import PageHeader from "../../components/page-header/PageHeader";
import FilterTable from "../list-ministries/components/filter-table/FilterTable";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DataType} from "../../types/listUsers";
import {get, isEmpty} from "lodash";
import {
  changeStatusAccount,
  getOffices,
  getRoles,
  getUnits
} from "../../service/list-account-users/listAccountUsersApi";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, KeyOutlined, MoreOutlined} from "@ant-design/icons";
import TableData from "../../components/table-data/TableData";
import {createAccountMinistry, getDataMinistry} from "../../service/list-acoount-ministry/listAccountMinistry";
import {validateFormCreate} from "./constants/data";
import FormCreateMinistry from "./components/form-create-ministry/FormCreateMinistry";
import FormFilter from "./components/filter-group/FormFilter";
import {HelpService} from '../../service/helper.service';
import {getCurrentPageFromLink} from '../../utils/getPageFromLink';
import {combineArray} from "../../utils/arrays";
import {useAppStore} from "../../stores/stores";
import {formatOriginTime} from '../../utils/converDate';
import DetailDepartmentalUserAccountDrawer from '../../components/drawer/departmental-account';
import ChangePassDepartmentDrawer from '../../components/drawer/departmental-account/change-password';
import CommonModal from '../../components/modal/common';


type ListMinistriesProps = {
  title?: string
}


const ListMinistries = ({title}: ListMinistriesProps) => {
  const helpService = new HelpService();
  const [params, setParams] = useSearchParams()
  const {setDataMinistries} = useAppStore()

  const [isOpenedCreateMinistries, setIsCreateMinistries] = useState<boolean>(false)
  const [accountMinistries, setAccountMinistries] = useState<DataType[]>([])
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [isOpenLecturerAccountAdmin, setIsOpenLecturerAccountAdmin] = useState<boolean>(false)
  const [isOpenChangePass, setIsOpenChangePass] = useState<boolean>(false)

  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: []
  })
  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    status: null,
    approvedDate: null,
  });
  const [loading, setLoading] = useState<boolean>(true)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [isOpenBasicAccountAdmin, setIsOpenBasicAccountAdmin] = useState<boolean>(false)
  const [isOpenedConfirmDelete, setIsOpenedConfirmDelete] = useState<boolean>(false)

  const [detailData, setDetailData] = useState<any>()

  const options = [
    {
      key: "1",
      label: "Đổi mật khẩu",
      icon: <KeyOutlined/>,
    },
    {
      key: "2",
      label: "Xoá tài khoản",
      icon: <DeleteOutlined/>,
      className: "dropdown-delete",
      danger: true,
    },
  ]

  const fetchData = () => getDataMinistry({
    keySearch: searchValue,
    pageSize: pageSize,
    pageNumber: pageNumber
  }).then(res => {
    const {data, totalRecords} = res.data.data;
    setAccountMinistries(data)
    setDataMinistries(data)
    setTotalPage(totalRecords)
    setLoading(false)
  })

  useEffect(() => {
    fetchData()
  }, [pageSize, pageNumber]);


  const dataColumnsMinistries: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
      width: 30
    },
    {
      title: 'Họ và tên người dùng',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 30
    },
    {
      title: 'Đơn vị công tác',
      dataIndex: 'phone',
      key: 'phone',
      width: 20
    },
    {
      title: 'Vai trò',
      dataIndex: 'vaiTros',
      key: 'vaiTros',
      render: (_: any, record: { vaiTros: string[] }) => {
        const length = record.vaiTros?.length
        return (
          record.vaiTros.map((item: string, index: number) => (
            <p key={index} className={`border rounded-md w-fit p-1 bg-gray-100 ${index !== 0 ? 'mt-1' : ''}`}>{item}</p>
          ))
        )
      },
      width: 30
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>{record?.createdDate ? formatOriginTime(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      render: (_: any, record: { trangThai: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          // record.trangThai = checked;
        }

        const confirm = (id: number) => {
          const data = {
            usedId: record?.id,
            isActive: !record?.trangThai
          }
          changeStatusAccount(data).then((res) => {
            const {statusCode} = res.data

            if (statusCode === 200) {
              helpService.successMessage(`${record?.trangThai ? 'Khóa' : 'Mở khóa'} tài khoản thành công`)
              // fetchData()
              record.trangThai = !record?.trangThai;

              // setPageNumber(1)
            }

          }).catch(err => {
          })
        }

        const cancel = () => {
        }

        return (
          <Popconfirm
            description={`Bạn có chắc chắn muốn ${record.trangThai ? 'khóa' : 'mở khóa'} tài khoản?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.trangThai}
                    checked={record.trangThai}
                    onChange={handleChange}/>
          </Popconfirm>
        )
      },
      width: 20
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record) => {
        const location = {
          id: `${record.id}`
        }

        return (
          (
            <Space className="flex justify-center">
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenLecturerAccountAdmin(true)
                  setDetailData(record)
                }}
              >
                <EyeOutlined/>
              </a>
              <Dropdown
                menu={{
                  items: options,
                  onClick: ({key}) => {
                    if (key === '1') {
                      setIsOpenChangePass(true)
                      setDetailData(record)
                    }
                    if (key === '2') {
                      setIsOpenedConfirmDelete(true)
                      setDetailData(record)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined/>
                </Button>
              </Dropdown>
            </Space>
          )
        )
      },
      fixed: 'right',
      width: 15,
    }
  ]

  const defaultCheckedList = dataColumnsMinistries.map((item) => item.key as string)

  const [checkedList, setCheckedList] = useState(defaultCheckedList)

  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search)
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink))
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([getRoles(), getUnits(), getOffices()])
        const dataRoles = response[0].data.data
        const dataUnits = response[1].data.data
        const dataOffices = response[2].data.data
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnits,
          dataRoles: dataRoles
        })

      }
      fetchDataOptions()
    }
  }, []);


  const columnChecked = useMemo(() => {
    return dataColumnsMinistries.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string)
    }))
  }, [checkedList])


  const handleOpenCreate = () => {
    setIsOpened(true);
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter)
  }


  const handleClose = () => {
    setIsOpened(false)
    form.resetFields()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target
    setParams({[name]: value})
    setSearchValue(value)
    if (value.length === 0) {
      setSearchValue("")
      setParams()
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      getDataMinistry({
        keyword: searchValue
      }).then(users => {
        const {data, totalRecords} = users.data.data
        setAccountMinistries(data)
        setTotalPage(totalRecords)
      }).catch(err => console.error(err))
    }

  }

  const handleSubmitSearch = () => {
    getDataMinistry({
      keyword: searchValue
    }).then(users => {
      const {data, totalRecords} = users.data.data
      setAccountMinistries(data)
      setTotalPage(totalRecords)
    }).catch(err => console.error(err))

    if (searchValue === '') {
      getDataMinistry({}).then(users => {
        const {data} = users.data.data
        setAccountMinistries(data)
      }).catch(err => console.error(err))
    }

  }

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      universityId: null,
      status: null,
      approvedDate: null,
    });
  }

  const handleFinish = (values: any) => {
    setLoading(true)
    getDataMinistry({
      vaiTros: get(values, 'vaiTros', []),
      donVis: combineArray(dataOptions.dataUnits, get(values, 'donVis', [])),
      trangThai: get(values, 'trangThai', []),
    }).then(res => {
      const {data, totalRecords} = res.data.data
      setLoading(false)
      setTotalPage(totalRecords)
      setAccountMinistries(data)
    })
  }

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({...filterData, [name]: value});
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const onFinishCreate = (values: any) => {
    createAccountMinistry(values).then(res => {
      const {data, statusCode} = res.data
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", "Tạo tài khoản thành công"))
        form.resetFields()
        fetchData()
        handleClose()
      }
    }).catch(err => {
    })
  }

  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
  };

  const deleteAccount = () => {
    setIsOpenedConfirmDelete(false)
    message.success("Xóa tài khoản thành công")
  }

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, {values, forms}) => {
        if (name === 'createMinistry') {
          const {createMinistry} = forms
          const data = createMinistry.getFieldsValue(validateFormCreate)
          onFinishCreate(data)
        }
        if (name === "filterForm") {
          fetchData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title}/>
            </div>
            <FilterTable handleOpen={handleOpenCreate} searchValue={searchValue}
                         dataColumns={dataColumnsMinistries}
                         handleOpenFilter={handleOpenFilter}
                         handleSubmitSearch={handleSubmitSearch}
                         handleSearch={handleSearch}
                         checkedList={checkedList}
                         setCheckedList={setCheckedList}
                         handleChangeInput={handleChangeInput}/>
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <TableData dataTable={accountMinistries} dataColumns={columnChecked}
                   setPageNumber={setPageNumber}
                   setPageSize={setPageSize}
                   pageNumber={pageNumber}
                   pageSize={pageSize}
                   pagination={true}
                   loadingTable={loading}
                   scroll={{x: 1500, y: 600}}
                   totalRecords={totalPage}
                   handleTableChangeProps={handleTableChange}/>

        <FormCreateMinistry open={isOpened} handleClose={handleClose} fetchData={fetchData}/>
        <DetailDepartmentalUserAccountDrawer
          open={isOpenLecturerAccountAdmin}
          onClose={() => setIsOpenLecturerAccountAdmin(false)}
          detailData={detailData}
        />

        <ChangePassDepartmentDrawer
          open={isOpenChangePass}
          onClose={() => setIsOpenChangePass(false)}
          detailData={detailData}
          fetchData={fetchData}
        />

        <CommonModal
          open={isOpenedConfirmDelete}
          title={'Xác nhận xóa tài khoản'}
          desc={`Vui lòng xác nhận xóa tài khoản Người dùng cấp bộ`}
          closeIcon={true}
          okText={'Xóa tài khoản'}
          cancelText={"Huỷ"}
          icon={<ExclamationCircleOutlined style={{color: "orange"}}/>}
          confirm={deleteAccount}
          closeModal={() => setIsOpenedConfirmDelete(false)}
          className="modal-delete-common"
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListMinistries;
