import { Button, Dropdown, Form, Layout, MenuProps, Modal, Popconfirm, Space, Switch, TableColumnsType, TableProps, Tag, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { HelpService } from "../../../service/helper.service";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { routesConfig } from "../../../config/routes";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import FormCreateUser from "./components/form-create-user/FormCreateUser";
import { formatDateV2 } from "../../../utils/converDate";
import { get, isEmpty } from "lodash";
import "./ministry-users-management.scss";
import { addMoocMinistryUser, changeStatusMinistryUser, deleteMoocMinistryUser, downloadMinistryUserTemplateFile, exportExcelMoocMinistryUser, getAcademicLevelMinistryUser, getAddressProvinces, getAllMinistryUserByFilter, getMoocMinistryUserById, getOrganizationByName, getPositionsMinistryUser, importExcelMoocMinistryUser, updateMoocMinistryUser } from "../../../service/category-management/ministry-users-management";

type MinistryUsersManagementProps = {
  title?: string;
};

const MinistryUsersManagement = ({ title }: MinistryUsersManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataUser, setDataUser] = useState<any>([]);
  const [dataOptions, setDataOptions] = useState({
    dataOrganization: [],
    dataRegency: [],
    dataProvince: [],
    dataAcademicLevel: [],
  });

  const [filterData, setFilterData] = useState<any>({
    keyword: null,
    status: null,
    organization: null,
    regency: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const usersOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa người dùng",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa người dùng",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên người dùng",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 50,
      render: (_: any, record) => {
        return (
          <div className="table-user cursor-pointer">
            <div>
              <a
                className="user-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.ministryUsersManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Đơn vị công tác",
      dataIndex: "organizationName",
      key: "organizationName",
      width: 50,
    },
    {
      title: "Chức vụ",
      dataIndex: "authPositions",
      key: "authPositions",
      width: 50,
      render: (authPositions) => {
        return (
          <div>
            {authPositions.map((position: any) => (
              <Tag style={{marginBottom: 5}} key={position.name}>{position.name}</Tag>
            ))}
          </div>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 40,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 30,
      render: (_: any, record: any, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: { status: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          record.status = checked;
        };

        const confirm = (id: number) => {
          setLoading(true);
          changeStatusMinistryUser({
            usedId: id, 
            isActive: record?.status,
          }).then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                setLoading(false);
              }, 333);
              helpService.successMessage("Thay đổi trạng thái thành công!");
              getAllUser();
            }
          }).catch(err => {
            setLoading(false);
            console.error(err);
          })
        };


        const cancel = () => {
          helpService.errorMessage("Hủy thao tác thành công!");
        };

        return (
          <Popconfirm
            rootClassName="popconfirm-change-status"
            description={`Xác nhận đổi trạng thái?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.status}
                    checked={record.status}
                    onChange={handleChange}/>
          </Popconfirm>
        );
      },
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.ministryUsersManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: usersOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      // setIdDetail(record.id);
                      getUserUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá người dùng cấp bộ`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deleteUser(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

   // Get data User
   const getAllUser = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      organizationId: filterData.organization,
      positionId: filterData.regency,
      status: filterData.status,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date,desc'] : [`${fieldSortOrder},${statusOrder}`]
    };
    getAllMinistryUserByFilter(dataReq)
      .then((res) => {
        setDataUser(res.data.data.ministerialUserData);
        setTotalRecords(res.data.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      organization: null,
      regency: null,
      createdDateFrom: null,
      createdDateTo: null,
      status: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllUser();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllUser();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      status: values?.status,
      organization: values?.organization,
      regency: values?.regency,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };

   // Get user to edit
   const getUserUpdate = async (id: number) => {
    setLoadingForm(true);
    await getMoocMinistryUserById(id).then((res) => {
      const { data } = res.data;
      setDataDetail(data);
      setTimeout(() => {
        setLoadingForm(false);
      }, 333);
    })
    .catch((err) => {
      setLoadingForm(false);
      console.error(err);
    });
  };
  
  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };


  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          name: values.name,
          organizationId: values.organization,
          gender: values.gender,
          provinceCode: values.domicile,
          positionIds: values.regency,
          educationLevels: values.academicLevel,
          phoneNumber: values.phone,
          email: values.email,
          status: values.status,
        };
        setIsSaving(true);
        try {
          await updateMoocMinistryUser(editId, dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật người dùng thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllUser();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          name: values.name,
          organizationId: values.organization,
          gender: values.gender,
          provinceCode: values.domicile,
          positionIds: values.regency,
          educationLevels: values.academicLevel,
          phoneNumber: values.phone,
          email: values.email,
          status: values.status,
        };
        try {
          await addMoocMinistryUser(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới người dùng thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllUser();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

   // Delete user
  const deleteUser = async (user: any) => {
    try {
      await deleteMoocMinistryUser(user.id).then((res) => {
        if (res.status === 200) {
          message.success("Xóa người dùng thành công!");
          getAllUser();
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Import file Excel to add new
  const importExcel = async (file: any) => {
    setLoading(true);
    await importExcelMoocMinistryUser(file).then((res) => {
      if (res.status === 200) {
        message.success("Thêm mới người dùng cấp bộ thành công!");
        setLoading(false);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        getAllUser();
      }
    }).catch((err) => {{
      setLoading(false);
      console.error(err);
    }})
  }

  // Export file Excel
  const exportExcel = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      organizationId: filterData.organization,
      positionId: filterData.regency,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
      status: filterData.status,
    };
    setIsRendering(true);
    try {
      await exportExcelMoocMinistryUser(dataReq).then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Danh sách người dùng cấp bộ.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!");
        setIsRendering(false);
      })
    } catch (err) {
      setIsRendering(false);
      console.error("Error downloading Excel file:", err);
    }
  }

  // Download file template excel
  const handleDownloadFile = async () => {
    try {
      await downloadMinistryUserTemplateFile().then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Mẫu thêm mới người dùng cấp bộ.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!")
      })
    } catch (err) {
      console.error("Error downloading Excel file:", err);
    }
  }

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      const positionIds = dataDetail.authPositions.map((item: any) => {
        return(item.id)
      })

      const educationLevels = dataDetail.moocTeacherProfiles.map((item: any) => {
        return(item.id)
      })

      form.setFieldsValue({
        name: dataDetail.name,
        organization: dataDetail.organizationId,
        regency: positionIds,
        academicLevel: educationLevels,
        gender: dataDetail.gender ? dataDetail.gender : null,
        domicile: dataDetail.provinceCode ? dataDetail.provinceCode : null,
        phone: dataDetail.phoneNumber ? dataDetail.phoneNumber : null,
        email: dataDetail.email ? dataDetail.email : null,
        status: dataDetail.status,
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllUser();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  useEffect(() => {
    // setLoading(true);
    Promise.all([getOrganizationByName(), getPositionsMinistryUser(), getAcademicLevelMinistryUser(), getAddressProvinces()])
    .then((res) => {
      const dataOrganization = res[0].data.data;
      const dataPositions = res[1].data.data;
      const dataAcademicLevel = res[2].data.data.map((item: any) => {
        return ({
          id : item.id,
          name: item.levelName,
        })
      })
      const dataProvince = res[3].data.data;
      setDataOptions({
        ...dataOptions,
        dataOrganization: dataOrganization,
        dataRegency: dataPositions,
        dataAcademicLevel: dataAcademicLevel,
        dataProvince: dataProvince,
      })
      setTimeout(() => {
        setLoading(false);
      }, 300);
    })
  },[]);

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUser") {
            const { createUser } = forms;
            const data = createUser.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${ showFilter ? "open-filter" : "" }`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              exportExcel={exportExcel}
              handleDownloadFile={handleDownloadFile}
              importExcel={importExcel}
              isRendering={isRendering}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <TableData
          dataTable={dataUser}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          handleTableChangeProps={handleTableChange}
        />

        <DrawerCustom title={`${ isOpenedEdit ? "Chỉnh sửa người dùng" : "Thêm mới người dùng" }`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={() => form.submit()} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateUser form={form} dataOptions={dataOptions} loadingForm={loadingForm}/>
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default MinistryUsersManagement;
