import React, { useEffect, useState } from "react";
import ChooseText from "./choose-text/ChooseText";
import { Button, Form, Select, Spin } from "antd";

import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import ScoreDetailModal from "../components/ScoreDetailModal";
import ReportModal from "../components/ReportModal";
import {
  AutoResultOptions,
  LayoutMode,
  QuestionTypeEnum,
} from "../../../../../../constants/course";
import { ArrowLeftIcon, WarningIcon } from "../../../../../../components/icons";
import {
  ExamConfigs,
  GroupsExam,
  QuizProps,
  ResultExam,
  SubmitExamHistory,
} from "../../../../../../types/course";
import EmptyComponent from "../../../../../../components/empty";
import useKeyword from "../../../../../../hooks/useKeyword";
import { submitHistoryDate } from "../../../../../../service/learning-course";
import { toNumber } from "lodash";
import moment from "moment";
import { formatSecondTimeToString } from "../../../../../../utils/format";

interface ResultQuizLayoutProps {
  data?: any;
  groups?: GroupsExam[];
  sessionId?: string;
  loading?: boolean;
  isPausing?: boolean;
  disableNextBtn?: boolean;
  disablePrevBtn?: boolean;
  quizzes: QuizProps[];
  isTrainingQuizHasTime?: boolean;
  timeOfCurrentQuiz?: number;
  disabledQuiz?: boolean;
  results?: ResultExam;
  layout?: LayoutMode.FLAT | LayoutMode.GROUP | LayoutMode.SINGLE;
  configs?: ExamConfigs;
  currentGroup?: GroupsExam;
  onBack?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onChangeHistory?: (ssId: string) => void;
}

function ResultQuizLayout({
  data,
  results,
  loading = false,
  sessionId = "",
  quizzes = [],
  groups = [],
  layout = LayoutMode.FLAT,
  configs,
  onChangeHistory = () => {},
  onBack = () => {},
}: ResultQuizLayoutProps) {
  const [form] = Form.useForm();
  const showResultsCompletionOption =
    configs?.evaluateConfig?.showResultsCompletionOptions?.map((item: string) =>
      toNumber(item)
    );

  const blockId = toNumber(useKeyword("blockId"));

  const [histories, setHistories] = useState<SubmitExamHistory[]>([]);
  const [isViewDetailComment, setIsViewDetailComment] =
    useState<boolean>(false);

  const renderQuestionByType = (currentQuiz: QuizProps) => {
    const type = currentQuiz?.type;
    const currentIndexOfQuiz = quizzes?.findIndex(
      (quiz: QuizProps) => quiz.id === currentQuiz?.id
    );

    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return (
          <ChooseText
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return (
          <ChooseImage
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return (
          <ChooseVideo
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return (
          <TrueOrFalse
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.DROP_DOWN:
        return (
          <DropDown
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return (
          <ChooseMultiText
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return (
          <ChooseMultiImage
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return (
          <ChooseMultiVideo
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.CONNECT:
        return (
          <Connect
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
            form={form}
          />
        );

      case QuestionTypeEnum.SHORT_TEXT:
        return (
          <ShortText
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.LONG_TEXT:
        return (
          <LongText
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.FILE_UPLOAD:
        return (
          <FileUpload
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            form={form}
            configs={configs}
          />
        );

      case QuestionTypeEnum.VIDEO:
        return (
          <Video
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      case QuestionTypeEnum.MP3:
        return (
          <Mp3
            data={{ ...currentQuiz, index: currentIndexOfQuiz }}
            configs={configs}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    const getResultHistory = async () => {
      if (blockId) {
        const res = await submitHistoryDate(blockId);
        setHistories(res.data?.data);
      }
    };
    getResultHistory();
  }, [blockId]);

  const handleChangeHistory = (ssId: any) => {
    if (ssId) {
      onChangeHistory(ssId);
    }
  };

  const handleBack = () => {
    if (isViewDetailComment) {
      setIsViewDetailComment(false);
    } else onBack();
  };

  const renderQuizzesByLayout = () => {
    switch (layout) {
      case LayoutMode.FLAT:
        return quizzes?.map((quiz: QuizProps, index: number) => (
          <div
            id={`quest-${quiz.id}`}
            key={`quest-${quiz.id}-${index}`}
            className="mb-4"
          >
            {renderQuestionByType(quiz)}
          </div>
        ));
      case LayoutMode.GROUP:
        return (
          groups?.length > 0 &&
          groups?.map((group: GroupsExam, index) => (
            <div>
              <div className="exam-group-header mb-2">
                <div className="group-index">
                  Phần {index + 1}/{groups?.length}
                </div>
                <p className="text-primary font-size-14 font-weight-6 mt-1">
                  {group?.title}
                </p>
              </div>
              {group?.quiz?.length > 0 ? (
                group?.quiz?.map((quiz: QuizProps, index: number) => (
                  <div
                    id={`quest-${quiz.id}`}
                    key={`quest-${quiz.id}-${index}`}
                    className="mb-4"
                  >
                    {renderQuestionByType(quiz)}
                  </div>
                ))
              ) : (
                <EmptyComponent description="Không có câu hỏi" />
              )}
            </div>
          ))
        );
      case LayoutMode.SINGLE:
        return renderQuestionByType(data as QuizProps);
      default:
        return <></>;
    }
  };

  const renderFooter = () => {
    return (
      <div className="report-course flex flex-column">
        <div className="report-table mt-2">
          <div className="flex justify-space-between">
            <div className="flex gap-8 align-center">
              <h3 className="font-size-16 line-height-24 font-weight-6">
                Nhận xét bài kiểm tra
              </h3>

              <Button
                className="btn-view"
                onClick={() => setIsViewDetailComment(true)}
              >
                <span className=" text-secondary font-size-16">Xem</span>
              </Button>
            </div>
            <div className="report-history flex gap-8 align-center">
              <ReportModal sessionId={sessionId}>
                <div className="text-primary font-weight-6 font-size-14 pointer">
                  Báo cáo
                </div>
              </ReportModal>

              <Select
                style={{ width: 150 }}
                placeholder="Lịch sử trả lời"
                className="h-40 history-select font-weight-6"
                options={histories?.map((history: SubmitExamHistory) => ({
                  value: history?.sessionId,
                  label: moment(history?.date).format("DD/MM/YYYY"),
                }))}
                onChange={handleChangeHistory}
              />
            </div>
          </div>

          <div className="flex flex-wrap mt-3">
            {showResultsCompletionOption?.includes(
              AutoResultOptions.SHOW_TOTAL_CORRECT
            ) && (
              <div className="table-item font-size-14 flex-1">
                Câu trả lời đúng:
                <p className="font-size-12 font-weight-6">
                  {results?.trueQuestions || 0}/{results?.totalQuestions || 0}
                </p>
              </div>
            )}

            {showResultsCompletionOption?.includes(
              AutoResultOptions.SHOW_TOTAL_POINT
            ) && (
              <div className="table-item font-size-14 flex-1 border-left">
                <span className="flex align-center gap-8">
                  Điểm số:{" "}
                  {showResultsCompletionOption?.includes(
                    AutoResultOptions.SHOW_POINT_BY_GROUP
                  ) && (
                    <ScoreDetailModal data={groups}>
                      <span className="pointer">
                        <WarningIcon />
                      </span>
                    </ScoreDetailModal>
                  )}
                </span>
                <p className="font-size-12 font-weight-6">
                  {results?.totalPoints || 0}/{results?.maxPoints || 0}
                </p>
              </div>
            )}
            {showResultsCompletionOption?.includes(
              AutoResultOptions.SHOW_TIME_TRAIN
            ) && (
              <div className="table-item font-size-14 flex-1 border-left">
                Thời gian làm bài:
                <p className="font-size-12 font-weight-6">
                  {formatSecondTimeToString(results?.totalTimeComplete)}
                </p>
              </div>
            )}
            {showResultsCompletionOption?.includes(
              AutoResultOptions.SHOW_TIME_FINISH
            ) && (
              <div className="table-item font-size-14 flex-1 border-left">
                Thời gian nộp:
                <p className="font-size-12 font-weight-6">
                  {moment(results?.createdTime).format("HH:mm:ss - DD/MM/YYYY")}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="quiz-layout flex flex-column quiz-view-layout">
      {quizzes?.length > 0 ? (
        <div className="question-content flex flex-column justify-space-between">
          <div
            className="btn-back flex align-center gap-8 pointer mt-2 ml-3"
            onClick={handleBack}
          >
            <ArrowLeftIcon />{" "}
            <span className="font-size-14 text-primary font-weight-6">
              Quay lại
            </span>
          </div>

          {isViewDetailComment ? (
            <div className="comment-by-teacher">
              <p className="font-size-24 font-weight-6 line-height-32">
                Nhận xét tổng quan của giảng viên:
              </p>
              <pre className="font-size-16 text-secondary mt-1">
                {results?.commentByTeacher || "Không có nhận xét"}
              </pre>
            </div>
          ) : (
            <div className="view-quiz-content pt-2 flex-1">
              <Spin spinning={loading}>
                <Form
                  form={form}
                  onValuesChange={(v, value) => console.log(value)}
                >
                  {renderQuizzesByLayout()}
                </Form>
              </Spin>
            </div>
          )}

          {renderFooter()}
        </div>
      ) : (
        <EmptyComponent description="Bài kiểm tra chưa có kết quả" />
      )}
    </div>
  );
}

export default ResultQuizLayout;
