import { Button, Col, ColorPicker, ColorPickerProps, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import FormRadio from '../../form-radio/FormRadio'
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, HeatMapOutlined, InfoCircleOutlined, LoadingOutlined, PicCenterOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import "./index.scss"
import CommonModal from '../../modal/common'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FileType } from '../../../utils/uploadImage'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import { DragSortTable, ProColumns } from '@ant-design/pro-components';
import { DragHandle } from '../../SortableList/components/SoftableItem';
import ElementSort from './element';
import { getIndustry, getIndustryGroup } from '../../../service/course-construct';
import { Enterprises } from '../../../types/assign-course-construction';
import { createListProgram, getAllCourse, updateListProgram } from '../../../service/portal-administration';
import { cloneDeep, isEmpty } from 'lodash';
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  refreshData: (value: boolean) => void
  dataUpdate?: any
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

export default function AddListProgramDrawer(props: Props) {
  const { open, handleClose, refreshData, dataUpdate } = props
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = React.useState<string>('#1677ff');
  const [industryGroup, setIndustryGroup] = useState<Enterprises[]>([]);
  const [industry, setIndustry] = useState<Enterprises[]>([]);
  const [courses, setCourses] = useState<Enterprises[]>([]);

  const params = useParams()

  const [listCourse, setListCourse] = React.useState<any>([
    {
      id: 1,
      key: 1,
      display: true,
      value: ''
    },
    {
      id: 2,
      key: 2,
      display: true,
      value: ''
    }
  ]);

  const handleChange = (value: string, id: string) => {
    const arr = [...listCourse]
    const newArr = arr.filter((item) => item.id === id)
    newArr[0].value = value
    setListCourse(arr)
  }

  const removeRow = (id: string) => {
    const arr = [...listCourse]
    const newArr = arr.filter((item) => item.id === id)
    newArr[0].display = false
    setListCourse(arr)
  }

  const columns: ProColumns[] = [
    {
      title: '',
      dataIndex: 'sort',
      width: 60,
      className: 'drag-visible',
    },
    {
      title: '',
      dataIndex: 'value',
      className: 'drag-visible',
      render: (_: any, record, index: number) => {
        return (
          <Form.Item name={`${index}`} rules={[{ required: true, message: "Vui lòng chọn danh sách khoá học" }]}>
            <FormItemDropdown placeholder="Chọn khoá học" className='w-100' value={record.value} onChange={(e: any) => handleChange(e, record.id)}
              options={courses} />
          </Form.Item>
        );
      },
    },
    {
      title: '',
      dataIndex: 'address',
      render: (_: any, record, index: number) => {
        return (
          <Button disabled={listCourse.filter((item: any) => item.display).length === 1} onClick={() => removeRow(record.id)}>
            <DeleteOutlined className='cursor-pointer' />
          </Button>
        );
      },
    },
  ];

  const validation = {
    programName: {
      required: true,
      message: "Vui lòng nhập tên chương trình học"
    },
    codeProgram: {
      required: true,
      message: "Vui lòng nhập mã chương trình học"
    },
    avatarProgram: {
      required: dataUpdate ? false : true,
      message: "Vui lòng tải ảnh chương trình học"
    },
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const beforeUpload = () => {
    return false
  }

  function array_move(arr: any, old_index: any, new_index: any) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const sortUp = (id: number) => {
    const arr = listCourse?.filter((item: any) => item.display)
    const sortArr = array_move(arr, id, id - 1)
    setListCourse(sortArr)
  }

  const addCourse = () => {
    const arr = [...listCourse]
    arr.push({
      id: listCourse.length + 1,
      display: true,
      key: listCourse.length + 1,
    })
    setListCourse(arr)
  }

  const getListIndustryGroup = async () => {
    try {
      await getIndustryGroup().then((res) => {
        const { data } = res.data;
        setIndustryGroup(data);
      });
    } catch (error) { }
  };

  const getListIndustry = async () => {
    try {
      await getIndustry().then((res) => {
        const { data } = res.data;
        setIndustry(data);
      });
    } catch (error) { }
  };

  const getListCourse = async () => {
    try {
      await getAllCourse(params?.id,"").then((res) => {
        setCourses(res.data.data.courseQueryData)
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (open) {
      form.setFieldValue("typeStatus", 1)
      getListIndustryGroup()
      getListIndustry()
      getListCourse()
    }
  }, [open])

  useEffect(() => {
    const industryGroup = dataUpdate?.industryGroupInfos.map((item: any) => { return item.id })
    const industry = dataUpdate?.industryInfos.map((item: any) => { return item.id })
    if (dataUpdate) {
      form.setFieldsValue({
        programName: dataUpdate?.name || "",
        codeProgram: dataUpdate?.code || "",
        industryGroup: industryGroup,
        industry: industry,
      });

      setImageUrl(dataUpdate?.imagePath)

      dataUpdate?.courses?.forEach((item: any, index: number) => {
        form.setFieldValue(index, item.id)
      })

    }
  }, [dataUpdate])

  const handleDragSortEnd = (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: any
  ) => {
    setListCourse(newDataSource);
  };

  const handleChangeAvatar: UploadProps['onChange'] = (info) => {
    setLoading(true);
    getBase64(info.fileList?.[0]?.originFileObj as FileType, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const AddListProgram = async (values: any) => {
    const obj = cloneDeep(values)
    delete obj.avatarProgram
    delete obj.codeProgram
    delete obj.industry
    delete obj.industryGroup
    delete obj.programName

    const newObj = Object.keys(obj).forEach(function (key) {
      const property = key.replace("courses", "")
      return {
        property: obj[key]
      }
    });

    const listCourse = Object.values(obj)

    const formData = new FormData();
    const uuid: any = params?.id
    formData.append("code", values.codeProgram);
    formData.append("name", values.programName);
    formData.append("uuid", uuid);

    if (!isEmpty(values.avatarProgram?.fileList)) {
      formData.append("imagePath", values.avatarProgram?.fileList[0].originFileObj ?? null);
    }
    if (!isEmpty(values.industry)) {
      formData.append("industryIds", values.industry.toString());
    }
    if (!isEmpty(values.industry)) {
      formData.append("industryGroupIds", values.industry.toString());
    }
    if (!isEmpty(listCourse)) {
      formData.append("courseIds", listCourse.toString());
    }
    if (!dataUpdate) {

      await createListProgram(formData).then((res) => {
        if (res.status === 200) {
          message.success("Tạo chương trình học thành công")
          handleClose()
          refreshData(true)
          form.resetFields()
          refreshData(true)
        }
      })
    } else {
      formData.append("id", dataUpdate?.id);
      console.log('formData', formData)
      await updateListProgram(formData).then((res) => {

        if (res.status === 200) {
          message.success("Sửa chương trình học thành công")
          handleClose()
          refreshData(true)
          form.resetFields()
          refreshData(true)
        }
      })
    }

  }

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-add-list-program') {
        Modal.confirm({
          title: `Xác nhận việc ${dataUpdate ? 'sửa' : 'thêm'} chương trình học`,
          content: <div>
            <p>Đảm bảo rằng tất cả các thông tin liên quan đã</p>
            <p>được kiểm tra và cập nhật đầy đủ trước khi thêm</p>
            <p>khoá học.</p>
          </div>,
          onOk: () => {
            AddListProgram(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "orange" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title={`${dataUpdate ? 'Sửa' : 'Thêm'} chương trình học`}
        placement={"right"}
        className='add-list-program-drawer'
        width={600}
        onClose={handleClose}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary"
              onClick={() => form.submit()}>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-add-list-program">
            <Row gutter={24} className='flex justify-center'>
              <Col span={24}>
                <Form.Item name="programName" rules={[validation.programName]} label="Tên chương trình học">
                  <FormInput placeholder="Nhập tên chương trình học" />
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex' >
                <Form.Item name="codeProgram" rules={[validation.codeProgram]} label="Mã chương trình học" hasFeedback className='w-100'>
                  <FormInput placeholder="Nhập tên chương trình học" />
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex'>
                <Form.Item name="avatarProgram" rules={[validation.avatarProgram]} label="Ảnh" hasFeedback>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleChangeAvatar}
                    maxCount={1}
                    accept='.png,.svg,.jpg,.jpeg'
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex'>
                <Form.Item name="industryGroup" label="Khoa" hasFeedback className='w-100'>
                  <FormItemDropdown placeholder="Chọn khoa" className='w-100' modeDropdown="multiple" value={[1, 2]}
                    options={industryGroup} />
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex'>
                <Form.Item name="industry" label="Ngành" hasFeedback className='w-100'>
                  <FormItemDropdown placeholder="Chọn ngành" className='w-100' modeDropdown="multiple"
                    options={industry} />
                </Form.Item>
              </Col>
              <Col span={24} className='flex'>
                <Typography.Title level={5} style={{ marginTop: '10px' }}>Danh sách khoá học</Typography.Title>
              </Col>
              {/* {renderListCourse} */}
              <Col span={24} className='w-full'>
                <div className='flex flex-col w-full justify-center items-center'>
                  <DragSortTable
                    className='dragandsort'
                    rowKey="key"
                    columns={columns}
                    search={false}
                    loading={loading}
                    pagination={false}
                    dataSource={listCourse.filter((item: any) => item.display)}
                    dragSortKey="sort"
                    onDragSortEnd={handleDragSortEnd}
                  />
                  <Button className='margin-auto mt-2 max-w-40' onClick={addCourse}><PlusOutlined /> Thêm khoá học</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}
