import { Button, Dropdown, Form, Layout, MenuProps, message, Modal, Space, TableColumnsType, TableProps, } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState, } from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
  BookOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SendOutlined,
  SettingOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import TableData from "../../../components/table-data/TableData";

import "./index.scss";
import { isEmpty } from "lodash";
import PublishCourseProgramModal from "../../../components/modal/publish-course-program";
import { deleteListProgram, getAllCurriculum, getDetailCurriculum } from "../../../service/portal-administration";
import CommonModal from "../../../components/modal/common";
import AddListProgramDrawer from "../../../components/drawer/add-list-program";
import { routesConfig } from "../../../config/routes";


type ContentConstructionProps = {
  title?: string;
  refreshData: boolean;
};

const ListProgramPage = (props: ContentConstructionProps) => {
  const [tab, setTab] = useState(1);
  const { title, refreshData } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listCurriculum, setListCurriculum] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [idDetail, setIdDetail] = useState(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenEditCurriculum, setIsOpenEditCurriculum] = useState<boolean>(false);
  const [openPublishCourseProgram, setOpenPublishCourseProgram] = useState<boolean>(false);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [dataUpdate, setDataUpdate] = useState<any>();

  const params = useParams()

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const getListData = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: !isEmpty(statusOrder) ? [`name,${statusOrder}`] : [],
      keyword: "",
      enterpriseId: params?.id ?? ''
    }
    await getAllCurriculum(data).then((res) => {
      setListCurriculum(res.data?.data?.curriculumsData)
      setTotalRecords(res.data?.data?.count)
    })
  };

  const detailCurriculum = async (id: string) => {
    await getDetailCurriculum(id).then((res) => {
      setDataUpdate(res.data.data)
    })
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
  };

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  useEffect(() => {
    if (refreshData) {
      getListData();
    }
  }, [refreshData]);

  const options1: MenuProps["items"] = [
    {
      key: "0",
      label: "Xem trước khóa học",
      icon: <BookOutlined />,
    },
    {
      key: "1",
      label: "Sửa khóa học",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Tạo bản sao",
      icon: <CopyOutlined />,
    },
    {
      key: "3",
      label: "Đồng bộ khóa học",
      icon: <CloudSyncOutlined />,
    },
  ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "Số thứ tự",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder ?? 1}</span>
        );
      },
    },
    {
      title: "Chương trình học",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 80,
      render: (_: any, record) => {
        return (
          <div
            className="table-order-course-name cursor-pointer"
          >
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={
                record.imagePath
                  ? record.imagePath
                  : "/images/ic_school_placeholder.svg"
              }
            />
            <div>
              <span className="course-title">{record.name}</span>
              <span>{record.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space className="dropdown-course-browse">
              {/* <a
                className="action-table"
              // onClick={() =>

              // }
              >
                <EyeOutlined />
              </a> */}
              <Dropdown
                className="dropdown-course-browse"
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Xem chương trình học",
                      icon: <EyeOutlined />,
                    },
                    {
                      key: "2",
                      label: "Sửa chương trình học",
                      icon: <EditOutlined />,
                    },
                    {
                      key: "3",
                      label: "Xoá chương trình học",
                      icon: <DeleteOutlined />,
                      danger: true,
                    },
                    {
                      key: "4",
                      label: "Cài đặt giao diện giới thiệu",
                      icon: <SettingOutlined />,
                    },
                    {
                      key: "5",
                      label: "Xuất bản chương trình học",
                      icon: <SendOutlined />,
                    },
                  ],
                  onClick: ({ key }) => {
                    if (key === '2') {
                      setIsOpenEditCurriculum(true)
                      detailCurriculum(record?.id)
                    }
                    if (key === '3') {
                      setIsOpenedConfirm(true)
                      setDeleteId(record?.id)
                    }
                    if (key === '4') {
                      navigate(`/${routesConfig.settingIntroCourse}/${record.id}`)
                    }

                    if (key === '5') {
                      setOpenPublishCourseProgram(true)
                      setDeleteId(record?.id)
                    }


                  }
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị vai trò',
    },
  ]

  const deleteProgram = async () => {
    const data = {
      ids: [deleteId]
    }
    await deleteListProgram(data).then((res) => {
      if (res.status === 200) {
        message.success("Xoá chương trình học thành công")
        setIsOpenedConfirm(false)
        getListData()
      }
    })
  };

  return (
    <Layout className="page-header-group portal-administration-list-program">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {

        }}
      >
        <div className="tabs-heading">
          <TableData
            dataTable={listCurriculum}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      </Form.Provider>

      <PublishCourseProgramModal open={openPublishCourseProgram} handleClose={() => setOpenPublishCourseProgram(false)} publicId={deleteId} />

      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá chương trình học'}
        desc={`Bạn có chắc muốn xoá chương trình học này?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deleteProgram}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-program-common"
      />
      <AddListProgramDrawer open={isOpenEditCurriculum} handleClose={() => setIsOpenEditCurriculum(false)} refreshData={getListData} dataUpdate={dataUpdate} />

    </Layout>
  );
};

export default ListProgramPage;
