import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface Value {
  count: number;
  percentage: number | null;
  criteria: string;
}

interface DataType {
  type: number;
  criteria: string;
  values: Value[];
}

interface ColumnStackedPercentChartProps {
  data: DataType[];
  colors?: string[];
  showDataLabels?: boolean;
  yAxisTitle?: string;
  title?: string;
  hasTotal?: boolean;
}

const ColumnStackedPercentChart = forwardRef(
  (
    {
      data,
      colors = ["#FF6384", "#FF9F40", "#4BC0C0", "#36A2EB", "#9966FF"],
      showDataLabels = false,
      yAxisTitle,
      title,
      hasTotal = true,
    }: ColumnStackedPercentChartProps,
    ref
  ) => {
    const uniqueCriteria = Array.from(
      new Set(
        data.flatMap((item) => item.values.map((value) => value.criteria))
      )
    );

    const totalCounts = data.reduce((acc, item) => {
      const total = item?.values.reduce((sum, value) => sum + value.count, 0);
      return acc + total;
    }, 0);

    const categories = data.map((item) => item.criteria);
    const seriesData = uniqueCriteria.map((criteria, index) => ({
      name: `${criteria}`,
      data: data.map((item) => {
        const foundValue = item.values.find(
          (value) => value.criteria === criteria
        );
        return foundValue ? foundValue.count : 0;
      }),
      color: colors[index % colors.length],
    }));

    const options: Highcharts.Options = {
      chart: {
        type: "bar",
        scrollablePlotArea: {
          minHeight: data?.length * 54,
          scrollPositionX: 1,
        },
      },
      title: {
        text: hasTotal
          ? `<div class="static-chart-total">Tổng số: <span class="static-chart-count">${totalCounts}</span></div>`
          : title || "",
        useHTML: true,
      },
      xAxis: {
        categories: categories,
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: yAxisTitle,
        },
        labels: {
          format: "{value}%",
          style: {
            fontSize: "12px",
            maxWidth: "120px",
            textWrap: "wrap",
            wordBreak: "break-word",
            paddingLeft: "10px",
            textAlign: "left",
          },
          useHTML: true,
        },
      },
      tooltip: {
        pointFormat:
          "{series.name}: <b>{point.y}</b> ({point.percentage:.f}%)<br/>",
        shared: true,
      },
      plotOptions: {
        series: {
          stacking: "percent",
          dataLabels: {
            enabled: showDataLabels,
            format: "{point.percentage:.1f}%",
          },
        },
        bar: {
          pointWidth: 20,
        },
      },
      series: seriesData.reverse() as Highcharts.SeriesOptionsType[],
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        reversed: true,
        itemStyle: { fontSize: "12px" },
      },
      exporting: {
        enabled: true,
        allowHTML: true,
        chartOptions: {
          credits: {
            enabled: false,
          },
        },
      },
    };

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    useImperativeHandle(ref, () => ({
      getChart: () => chartRef.current?.chart,
    }));

    return (
      <div className="mt-2">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    );
  }
);

export default ColumnStackedPercentChart;
