import { DatePicker, DatePickerProps, Form, Modal } from 'antd'
import React from 'react'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'

type Props = {
  title: string
  open: boolean
  handleClose: () => void
  handleOk: () => void
}

const ExportAccountModal = (props: Props) => {
  const { title, open, handleClose, handleOk } = props
  const [form] = Form.useForm()
  const { RangePicker } = DatePicker;

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  const options = [
    {
      id: 1,
      name: 'Khóa'
    },
    {
      id: 2,
      name: 'Mở khóa'
    }
  ]

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "form-export-account") {

      }
    }}>
      <Modal
        title={title}
        open={open}
        centered
        onOk={handleOk}
        cancelText="Hủy"
        okText="Kết xuất"
        onCancel={handleClose}>
        <Form
          form={form}
          name="form-export-account"
          layout="vertical"
        >
          <Form.Item name="status" label="Trạng thái">
            <FormItemDropdown placeholder="Chọn trạng thái"
              options={options} />
          </Form.Item>
          <Form.Item name="status" label="Thời gian kết nối" className='w-full'>
            <RangePicker
              placeholder={["Từ ngày", "Đến ngày"]}
              format="YYYY-MM-DD"
              className='w-full'
            />
          </Form.Item>
        </Form>
      </Modal>
    </Form.Provider>
  )
}

export default ExportAccountModal;
