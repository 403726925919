import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  MoreOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormFilter from "../../../../components/course-browse/course-evaluation/filter-group/FormFilter";
import FilterDataCourseBrowse from "../../../../components/course-browse/course-evaluation/filter-table/FilterTable";
import { FilterData } from "../../../../components/course-construction/assign-course-construction/filter-group/filter";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import {
  assignMultiCourse,
  revokeAssign,
} from "../../../../service/assign-course-construction";
import { getCourseReviewList } from "../../../../service/course-construct";
import { DataType } from "../../../../types/assign-course-construction";
import { formatDate, formatOriginTime } from "../../../../utils/converDate";
import "./index.scss";
import ReviewMultipleContent from "../../../../components/modal/review-multiple-content";
import { PageSize } from "docx";

type Props = {
  title?: string;
};

const CourseEvaluation = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [courseReviewList, setCourseReviewList] = useState([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [quickReview, setQuickReview] = useState(false)
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [quickReviewId, setQuickReviewId] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [filterData, setFilterData] = useState<any>({
    status: null,
    courseType: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const handleOpenCreate = () => {
    if (isEmpty(selectedRowKeys)) {
      message.error("Vui lòng chọn khoá học phân công");
      return;
    }
    setIsOpened(true);
  };

  const getListData = () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      apprisedSendFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      apprisedSendTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      courseType: filterData.courseType ? filterData.courseType : [],
      status: filterData.status === 1 || tab === '2' ? true : false,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
    };

    getCourseReviewList(data)
      .then((res) => {
        const { data } = res;
        setCourseReviewList(data?.page?.content);
        setTotalRecords(data.totalElements);
        setCountReviewed(data?.countTrueRecords);
        setCountAwaitingReview(data?.countFalseRecords);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, statusOrder, pageSize]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const revocationAssignment = async (record: any) => {
    await revokeAssign(record.id).then((res) => {
      if (res.status === 200) {
        message.success("Lưu thông tin thành công");
        setTab("1");
      }
    });
  };

  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      default:
        return "";
    }
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const optionsMenu: MenuProps["items"] = [
    {
      key: "1",
      label: "Thẩm định khoá học",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Thẩm định nhanh nội dung",
      icon: <ThunderboltOutlined />,
    },
    // {
    //   key: "3",
    //   label: "Lịch sử thẩm định",
    //   icon: <HistoryOutlined />,
    // },
    // {
    //   key: "4",
    //   label: "Tải nội dung khoá học",
    //   icon: <DownloadOutlined />,
    // },
    {
      key: "5",
      label: "Kết quả duyệt",
      icon: <ThunderboltOutlined />,
    },
  ];

  const optionsAppraisedMenu: MenuProps["items"] = [
    {
      key: "5",
      label: "Lịch sử thẩm định",
      icon: <HistoryOutlined />,
    },
    {
      key: "6",
      label: "Tải nội dung khoá học",
      icon: <DownloadOutlined />,
    },
  ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      sorter: true,
      width: 20,
    },
    {
      title: "Tên khoá học",
      dataIndex: "courseName",
      key: "courseName",
      sorter: true,
      width: 30,
      render: (_: any, record: any) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record?.nameCourse}</span>
              <span>{record?.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      sorter: true,
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            {tab === "1" ? "Chờ hội đồng thẩm định duyệt" : "Chờ tổ chuyên môn duyệt"}
          </div>
        );
      },
    },
    {
      title: "Loại khoá học",
      dataIndex: "courseType",
      key: "courseType",
      sorter: true,
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : null}
          </div>
        );
      },
    },

    {
      title: "Ngày gửi thẩm định",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return <>{!isEmpty(record?.createdDate) ? formatOriginTime(record?.createdDate) : null}</>;
      },
    },
    {
      title: "Ngày thẩm định",
      dataIndex: "updateDate",
      key: "updateDate",
      width: 20,
      render: (_: any, record: any) => {
        console.log('record?.updateDate', record?.updateDate)
        return <>{!isEmpty(record?.updateDate) ? formatOriginTime(record?.updateDate) : null}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            {
              tab === "1" ?
                <Space>
                  <a
                    className="action-table"
                    onClick={() =>
                      navigate(
                        `${routesConfig.detailCourseEvaluation}/${location.id}`,
                        {
                          state: {
                            ...record,
                            isCourseEvaluation: true,
                            mode: 'view'
                          },
                        }
                      )
                    }
                  >
                    <EyeOutlined />
                  </a>

                  <Dropdown
                    menu={{
                      items: tab === "1" ? optionsMenu : optionsAppraisedMenu,
                      onClick: ({ key }) => {
                        if (key === "3" || key === "5") {
                          navigate(
                            `${routesConfig.detailCourseEvaluation}/${location.id}`,
                            {
                              state: {
                                ...record,
                                isCourseEvaluation: true,
                                tab: 3
                              },
                            }
                          );
                        }
                        if (key === "1") {
                          navigate(
                            `${routesConfig.detailCourseEvaluation}/${location.id}`,
                            {
                              state: {
                                ...record,
                                isCourseEvaluation: true,
                                tab: 2,
                                mode: 'edit'
                              }
                            }
                          );
                        }
                        if (key === "2") {
                          setIsOpened(true)
                          setQuickReview(true)
                          setQuickReviewId(record?.id)
                        }
                      },
                    }}
                    placement="bottomRight"
                  >
                    <Button>
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space> : <>
                  <Space>
                    <a
                      className="action-table"
                      onClick={() =>
                        navigate(
                          `${routesConfig.detailCourseEvaluation}/${location.id}`,
                          {
                            state: {
                              ...record,
                              isCourseEvaluation: true,
                              mode: 'view'
                            },
                          }
                        )
                      }
                    >
                      <EyeOutlined />
                    </a>
                  </Space>
                </>}
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Chờ thẩm định
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={courseReviewList}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={countAwaitingReview}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={true}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
            clearRowkey={clearRowkey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Đã thẩm định
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={courseReviewList}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={countReviewed}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={true}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const saveMultiAssign = async (data: object) => {
    await assignMultiCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Phân công thành công khoá học");
        setIsOpened(!isOpened);
        getListData();
      }
    });
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-evaluation">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "create-course-plan-modal") {
            const listRowKey = selectedRowKeys.map((item: any) => {
              return item.id;
            });
            const data = {
              courseIds: listRowKey,
              assigners: values.lecturerName,
            };
            saveMultiAssign(data);
            getListData();
            setClearForm(true);
          }
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterDataCourseBrowse
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
        <ReviewMultipleContent
          open={isOpened}
          type="thamdinh"
          title={`${quickReview ? 'Thẩm định nhanh' : 'Thẩm định nhiều'} `}
          handleClose={() => setIsOpened(false)}
          quickReview={quickReview}
          quickReviewId={quickReviewId}
          selectedRowKeys={selectedRowKeys}
          getListDataProps={getListData}
          setSelectedRowKeys={() => setSelectedRowKeys([])}
          setClearRowkey={setClearRowkey}
        />
      </Form.Provider>
    </Layout>
  );
};

export default CourseEvaluation;