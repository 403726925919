import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, Select } from "antd";
import { MenuProps } from "antd/lib";
import React, { ReactNode } from "react";

type Props = {
  chartName: string;
  content: ReactNode;
  showSelect? : boolean;
};
const {Option} = Select;
const StatisticalReportCard = (props: Props) => {
  const { chartName, content, showSelect = false } = props;
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: "Xuất file PNG",
    },
    {
      key: '2',
      label: "Xuất file PDF",
    },
    {
      key: '3',
      label: "Xuất file Excel",
    },
  ];
  return (
    <div className="statistical-report-chart-card">
      <div className="card-header">
        <div className="chart-name">{chartName}</div>
        {showSelect && (
          <Select defaultValue={"Tài liệu tham khảo"}>
          <Option>Bài kiểm tra</Option>
          <Option>Tài liệu tham khảo</Option>
          <Option>Đa phương tiện</Option>
          <Option>Bài giảng</Option>
          <Option>Khoá học</Option>
        </Select>
        )}
          <Dropdown menu={{ items }} placement="bottomRight">
            <Button className="chart-action">
              <DownloadOutlined />
            </Button>
          </Dropdown>
        </div>
      <div className="hr-tag-item"></div>
      <div className="chart-content">{content}</div>
    </div>
  );
};

export default StatisticalReportCard;
