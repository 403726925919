import { Input, Layout } from "antd";
import { SearchProps } from "antd/es/input";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import ManagementTableList from "./ManagementTableList";
const imgPath = "/images/";

const { Content } = Layout;
const { Search } = Input;

const SearchFilter = ({onSearch}: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  return (
    <div className="heading-search">
      <Search
        placeholder="Nhập tên hoặc mã bảng điểm cần tìm"
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
      />
    </div>
  );
};

const ManagementListScore = () => {
  const navigate = useNavigate();

  const params = useParams();
  const [searchValue, setSearchValue] = useState<string>("");

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  return (
    <Content>
      <PageHeaderCommon
        title={"Danh sách bảng điểm"}
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate(-1)}
        headerGroupButton
        childrenGroupButton={<SearchFilter onSearch={onSearch} />}
      />
      <ManagementTableList searchValue={searchValue} />
    </Content>
  );
};

export default ManagementListScore;
