import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";
import React, { ReactNode } from "react";

type ExportOption = {
  label: string;
  action: () => void;
};

type Props = {
  chartName: string;
  content: ReactNode;
  moreOption?: ReactNode;
  exportOptions?: ExportOption[];
};

const StatisticalReportCard = (props: Props) => {
  const { chartName, content, moreOption = false, exportOptions = [] } = props;

  const items: MenuProps["items"] = exportOptions.map((option, index) => ({
    key: index.toString(),
    label: <div onClick={option.action}>{option.label}</div>,
  }));

  return (
    <div className="statistical-report-chart-card">
      <div className="card-header">
        <div className="chart-name">{chartName}</div>
        <div className="flex align-center gap-8">
          {moreOption && moreOption}
          {exportOptions.length === 1 ? (
            <Button
              onClick={exportOptions[0].action}
              className="chart-action"
              icon={<DownloadOutlined />}
            >
              {exportOptions[0].label}
            </Button>
          ) : exportOptions.length > 1 ? (
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button
                className="chart-action"
                icon={<DownloadOutlined />}
              ></Button>
            </Dropdown>
          ) : null}
        </div>
      </div>
      <div className="hr-tag-item"></div>
      <div className="chart-content">{content}</div>
    </div>
  );
};

export default StatisticalReportCard;
