import { Badge, Flex, Form, Layout, TableColumnsType, TableProps, Typography } from "antd";
import { KeyboardEvent, useMemo, useState } from "react";
import TableData from "../../../../components/table-data/TableData";
import { formatDate } from "../../../../utils/converDate";

type TableRateScoreProps = {
  qualityAutoScore: any;
  setPageNumber: (item: number) => void;
  setPageSize: (item: number) => void;
  pageNumber: number;
  pageSize: number;
  loading: boolean;
  totalPage: number;
};

const TableRateScore = ({
  qualityAutoScore,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  loading,
  totalPage,
}: TableRateScoreProps) => {

  const dataColumnsQualityAutoScore: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    {
      title: "Nhãn thẻ tag theo sở thich",
      dataIndex: "tag",
      key: "tag",
      width: 20,
      render: (_: any, record) => {
        return (
          <Flex align="center" wrap="wrap">
            <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
              Lập trình web
            </div>
            <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
              Lập trình web
            </div>
            <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
              Lập trình web
            </div>
            <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">+2</div>
            {/* <div>Chưa có dữ liệu</div> */}
          </Flex>
        );
      },
    },
    {
      title: "Ngày gắn tag",
      dataIndex: "created_date",
      key: "created_date",
      width: 15,
      render: (_: any, record) => formatDate(record?.created_date),
    },
    {
      title: "Trạng thái",
      dataIndex: "courseType",
      key: "courseType",
      width: 10,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record?.status ? <Badge status="success" text="Đã tương tác" /> : <Badge status="default" text="Chưa tương tác" />}
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsQualityAutoScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsQualityAutoScore.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={qualityAutoScore}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableRateScore;
