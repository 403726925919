import React, { useState } from 'react';
import { Button, Col, Collapse, CollapseProps, Drawer, Form, Input, message, Modal, Row, Space, Typography } from 'antd'

import FormInput from "../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import { FormInstance } from "antd/lib";
// import "./index.scss"
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { regexEmail, regexPassword } from '../../../../utils/regex';
import { isEmpty } from 'lodash';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { rolesSlices } from '../../../../stores/list-roles/slices';

type Props = {
  open: boolean
  handleClose: () => void
  fetchData: () => void
}

const FormCreateMinistry = (props: Props) => {
  const { open, handleClose, fetchData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;
  const [status, setStatus] = useState<number>(1)
  const validations = {
    phone: {
      required: true,
      message: "Vui lòng nhập số điện thoại"
    },
    enterprise: {
      required: true,
      message: "Vui lòng chọn giảng viên"
    },
    emailContact: {
      validator: (_: unknown, value: any) => {
        if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
          return Promise.reject(
            new Error("Định dạng email không đúng, vui lòng nhâp lại")
          );
        }
        if (isEmpty(value)) {
          return Promise.reject(new Error("Vui lòng nhập địa chỉ email"));
        }
        return Promise.resolve();
      },
    },
    trainingFacility: {
      required: true,
      message: "Vui lòng chọn cơ sở đào tạo"
    },
    emailLogin: {
      validator: (_: unknown, value: any) => {
        if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
          return Promise.reject(
            new Error("Định dạng email không đúng, vui lòng nhâp lại")
          );
        }
        if (isEmpty(value)) {
          return Promise.reject(new Error("Vui lòng nhập địa chỉ email"));
        }
        return Promise.resolve();
      },
    },
    roleLogin: {
      required: true,
      message: "Vui lòng chọn vai trò"
    },
    passwordLogin: [
      {
        required: true,
        message: "Vui lòng nhập mật khẩu",
      },
      {
        min: 8,
        max: 25,
        message: "Độ dài mật khẩu từ 8-25 ký tự.",
      },
      {
        pattern: regexPassword,
        message:
          "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt, và có độ dài từ 8-25 ký tự.",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (value && getFieldValue("oldPassword") === value) {
            return Promise.reject(
              new Error("Mật khẩu mới phải khác mật khẩu hiện tại.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    passwordLoginConfirm: [
      {
        required: true,
        message: "Vui lòng xác nhập mật khẩu mới",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (!isEmpty(value)) {
            if (getFieldValue("passwordLogin") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Mật khẩu không khớp.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
  }

  const option = [
    {
      id: 1,
      name: '123'
    }
  ]

  const blockOne = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="trainingFacility" label="Đơn vị" rules={[validations.trainingFacility]}>
            <FormItemDropdown
              options={option}
              allowClear={true}
              placeholder="Chọn cơ sở đào tạo"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="enterprise" label="Người dùng" rules={[validations.enterprise]}>
            <FormItemDropdown
              options={option}
              allowClear={true}
              placeholder="Chọn giảng viên"
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const blockTwo = () => (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="emailLogin" label="Email đăng nhập" rules={[validations.emailLogin]}>
            <FormInput
              placeholder='Nhập email đăng nhập'
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="roleLogin" label="Vai trò" rules={[validations.roleLogin]}>
            <FormItemDropdown
              options={option}
              allowClear={true}
              placeholder="Chọn vai trò"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="">
        <Col span={12}>
          <Form.Item name="passwordLogin" label="Mật khẩu" rules={validations.passwordLogin}>
            <FormInput
              placeholder="Nhập mật khẩu cho tài khoản"
              passwordInput={true}
              style={{ height: '32px' }}
              autoComplete="new-password"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="passwordLoginConfirm" label="Xác nhận mật khẩu" rules={validations.passwordLoginConfirm}>
            <FormInput
              placeholder="Xác nhận mật khẩu cho tài khoản"
              passwordInput={true}
              style={{ height: '32px' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} className="">
        <Col span={24}>
          <Form.Item name="status" label="Trạng thái">
            <Space.Compact block>
              <Button className={status === 1 ? 'active' : ''} onClick={() => setStatus(1)}>Mở khóa</Button>
              <Button className={status === 2 ? 'active' : ''} onClick={() => setStatus(2)}>Khóa</Button>
            </Space.Compact>
          </Form.Item>
        </Col>
      </Row>
    </>
  )

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: <Typography.Title level={5}><strong>Thông tin người dùng</strong></Typography.Title>,
      children: blockOne(),
    },
    {
      key: '2',
      label: <Typography.Title level={5}><strong>Thông tin tài khoản</strong></Typography.Title>,
      children: blockTwo(),
    },
  ];

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "add-chapter") {
          Modal.confirm({
            title: `Xác nhận tạo tài khoản`,
            content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra trước khi xác nhận.`,
            onOk: () => {
              console.log("values", values)
              handleClose()
              message.success("Tạo tài khoản thành công")
              fetchData()
            },
            okText: "Xác nhận",
            cancelText: "Huỷ",
            centered: true,
            icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
            footer: (_, { OkBtn, CancelBtn }) => (
              <>
                <CancelBtn />
                <OkBtn />
              </>
            ),
          });
        }
      }}>
        <Drawer
          title="Tạo tài khoản người dùng cấp bộ"
          open={open}
          onClose={handleClose}
          width={736}
          extra={
            <Space>
              <Button onClick={() => {
                form.resetFields()
                handleClose()
              }} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>
                  Hủy
                </Typography.Text>
              </Button>
              <Button className="btn btn-primary"
                onClick={() => form.submit()
                }>
                <SaveOutlined />
                <Typography.Text>
                  Lưu
                </Typography.Text>
              </Button>
            </Space>
          }
        >
          <Form
            className="create-form-facility-management"
            layout={"vertical"}
            form={form}
            name="add-chapter"
            autoComplete='off'
            initialValues={{ emailLogin: '', passwordLogin: '' }}
          >
            <Collapse defaultActiveKey={['1', '2']} ghost items={items} />
          </Form>
        </Drawer>
      </Form.Provider>
    </>
  );
};

export default FormCreateMinistry;
