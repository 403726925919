import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthGuard } from "../../components/authentication/auth-guard";
import { Button, Flex, Layout, Menu } from "antd";
import { useViewport } from "../../hooks/useViewport";
import { menuItems } from "../sidebar/MenuItem";
import { useEffect, useState } from "react";
import LoadingFullScreen from "../../components/loading-full-screen/LoadingFullScreen";
import { MenuItem } from "../../types/sidebar";
import { getPermissions } from "../../utils/checkRole";
import HeaderLayout from "../header/Header";
import SiderLayout from "../sidebar/Sidebar";
import { routesConfig } from "../../config/routes";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
const { Header, Sider, Content } = Layout;

export default function DashboardRootPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const pathName = location.pathname;
  const { width } = useViewport();
  const isMobile = width <= 768;
  const [loading, setLoading] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState<MenuItem[]>([]);
  const [collapsedContent, setCollapsedContent] = useState(false);
  const [pageAddQuestion, setPageAddQuestion] = useState<boolean>(false);

  let layoutStyle = {
    width: '100vw',
    height: '100vh',
    paddingTop: '48px',
    backgroundColor: '#f5f5f5',
    transition: 'all 333ms ease-in-out',
  };

  let headerStyle: React.CSSProperties = {
    width: '100%',
    height: 48,
    backgroundColor: '#fff',
    position: 'fixed',
    top: '0px',
    left: '0px',
    padding: 0,
    transition: 'all 333ms ease-in-out',
  };

  let siderStyle: React.CSSProperties = {
    width: 260,
    height: 'calc(100% - 48px)',
    backgroundColor: '#fff',
    position: 'fixed',
    top: 48,
    left: 0,
    transition: 'all 333ms ease-in-out',
  };

  let contentStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all 333ms ease-in-out',
  };

  const hasChildren = (
    item: MenuItem
  ): item is MenuItem & { children: MenuItem[] } => {
    return item != null && "children" in item;
  };

  const hasPermission = (
    item: MenuItem
  ): item is MenuItem & { permission: string[] } => {
    return item != null && "permission" in item;
  };

  const filterMenuItems = (
    items: MenuItem[],
    permissions: string[]
  ): MenuItem[] => {
    return items
      .filter((item) => {
        if (!item) return false;

        // If item doesn't have permissions, include it by default
        if (!item.permission) return true;

        // If item has permissions, check if user has any of those permissions
        if (
          item.permission.some((permission) => permissions.includes(permission))
        ) {
          if (hasChildren(item) && item.children) {
            item.children = filterMenuItems(item.children, permissions);
          }
          return true;
        }

        return false;
      })
      .map((item) => ({ ...item }));
  };

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    const filteredMenu = filterMenuItems(
      menuItems as MenuItem[],
      listPermissionConvert
    );
    setSelectedMenu(filteredMenu);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getRoles();
    const filteredMenu = filterMenuItems(
      menuItems as MenuItem[],
      listPermission
    );
    setSelectedMenu(filteredMenu);
    setLoading(false);
  }, []);

  useEffect(() => {
    const filteredMenu = filterMenuItems(
      menuItems as MenuItem[],
      listPermission
    );
    setSelectedMenu(filteredMenu);
  }, [listPermission]);

  useEffect(() => {
    if (
      pathName.includes(routesConfig.addLesson) ||
      pathName.includes(routesConfig.addSurvey)
    ) {
      setPageAddQuestion(true);
    } else {
      setPageAddQuestion(false);
    }
  }, [navigate, pathName]);

  return (
    <AuthGuard>
      <Flex gap="middle">
        <Layout style={layoutStyle}>
          <Header style={headerStyle}>
            <HeaderLayout />
            {isMobile && (
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[location.pathname]}
                items={selectedMenu}
                className="menu-mobile"
              />
            )}
          </Header>
          <Sider style={{ ...siderStyle, transform: pageAddQuestion ? 'translateX(-100%)' : 'translateX(0%)' }} width={collapsedContent ? 80 : 260}>
            <SiderLayout
              selectedMenu={selectedMenu}
              collapsedContent={collapsedContent}
              isHideSider={pageAddQuestion}
            />
          </Sider>
          <Content style={{ ...contentStyle, paddingLeft: pageAddQuestion ? 0 : collapsedContent ? 80 : 260 }} className={collapsedContent ? 'content-stretch' : ''}>
            {loading && <LoadingFullScreen spinning={loading} />}
            <Outlet />
          </Content>
          <Button
            className="btn-layout-sider-trigger"
            onClick={() => {
              setCollapsedContent(!collapsedContent);
            }}
            style={{
              width: !collapsedContent ? 260 : 80,
              transform: pageAddQuestion ? 'translateX(-100%)' : 'translateX(0%)'
            }}
          >
            {!collapsedContent ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        </Layout>
      </Flex>
      {/* <main>
          <Layout>
            <HeaderLayout />
            {isMobile && (
              <Menu
                mode="horizontal"
                defaultSelectedKeys={[location.pathname]}
                items={selectedMenu}
                className="menu-mobile"
              />
            )}
            <Layout style={{ minHeight: "calc(100vh - 48px)" }}>
              <Sidebar selectedMenu={selectedMenu} />
              {loading && <LoadingFullScreen spinning={loading} />}
              <Outlet />
            </Layout>
          </Layout>
        </main> */}
    </AuthGuard>
  );
}
