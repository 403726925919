import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Flex, Form, Row, Select } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { TeacherBaseInfo } from "../../../types/scoring-course";
import { searchTeachers } from "../../../service/scoring";
import { toNumber } from "lodash";
import { useParams } from "react-router-dom";
import { ScoringStatusExam } from "../../../constants/scoring";
import { viLocale } from "../../../utils/pickerType";

const { RangePicker } = DatePicker;

export interface FilterSearchValues {
  status?: number;
  teacher?: number;
  date?: any;
}

interface FilterScoringSearch {
  onChange?: (value: FilterSearchValues) => void;
  onSearch?: (value: FilterSearchValues) => void;
  onClear?: (value: null) => void;
}

function FilterSearch({
  onSearch = () => {},
  onClear = () => {},
}: FilterScoringSearch) {
  const [form] = Form.useForm();
  const params = useParams();
  const courseId = toNumber(params?.id);
  const [teachers, setTeachers] = useState<TeacherBaseInfo[]>([]);

  useEffect(() => {
    const getTeachers = async () => {
      const res = await searchTeachers(courseId);
      setTeachers(res.data.data);
    };
    getTeachers();
  }, []);

  const handleFinish = (values: FilterSearchValues) => {
    onSearch(values);
  };

  const handleClear = () => {
    form.resetFields();
    onClear(null);
  };
  return (
    <div className="mt-2">
      <Form form={form} onFinish={handleFinish}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item name="status">
              <Select
                className="w-full"
                placeholder="Trạng thái"
                options={[
                  { label: "Đợi chấm", value: ScoringStatusExam.WAITING },
                  { label: "Đang chấm chấm", value: ScoringStatusExam.SCORING },
                  { label: "Hoàn thành", value: ScoringStatusExam.COMPLETED },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="teacher">
              <Select
                className="w-full"
                placeholder="Giảng viên chấm"
                options={teachers?.map((teacher: TeacherBaseInfo) => ({
                  label: teacher?.fullname,
                  value: teacher?.userId,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date">
              <RangePicker
                className="w-full"
                placeholder={["Ngày nộp", "Ngày nộp"]}
                format="YYYY-MM-DD"
                locale={viLocale}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Flex gap={8}>
              <Button
                htmlType="submit"
                icon={<FilterOutlined />}
                className="btn-primary"
              >
                Lọc
              </Button>
              <Button
                onClick={handleClear}
                icon={<DeleteOutlined />}
                className="btn-outline"
              >
                Xoá tất cả
              </Button>
            </Flex>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FilterSearch;
