import React, { useEffect, useRef, useState } from "react";
import { Button, Layout, Tabs, TabsProps, Typography } from "antd";
import CourseStructure from "./structure";
import "./style.scss";
import MoocBreadcrumb from "../../components/breadcrumb";
import PageHeader from "../../components/page-header/PageHeader";
import { routesConfig } from "../../config/routes";
import {
  PlayCircleOutlined,
  SaveOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

const indexDB = require("idbcache").default;

function PreviewQuiz() {
  const location = useLocation();
  const backAnchorRef = useRef<HTMLAnchorElement>(null);
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get("id");
  const [title, setTitle] = useState("");
  const [backUrl, setBackUrl] = useState("");
  const [backBreadCrumb, setBackBreadCrumb] = useState("");

  const navigate = useNavigate();
  const navigateBack = () => {
    // Navigate back to the previous page
    // window.history.back();
    navigate(backUrl, {
      state: {
        id: unitId
      }
    });
  };

  const { Content } = Layout;
  const imgPath = "/images/";

  const listBreadcrumb = [
    {
      title: (
        <a ref={backAnchorRef} onClick={navigateBack} href={`${backUrl}`}>
          {backBreadCrumb}
        </a>
      ),
    },
    {
      title: title || "",
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Cấu trúc khoá học",
      children: <CourseStructure />,
    },
    {
      key: "2",
      label: "Bài tập/bài kiểm tra/bài thi",
      children: "Content of Tab Pane 2",
    },
    {
      key: "3",
      label: "Tài liệu tải về",
      children: "Content of Tab Pane 3",
    },
    {
      key: "4",
      label: "Ghi chú",
      children: "Content of Tab Pane 3",
    },
    {
      key: "5",
      label: "Thảo luận",
      children: "Content of Tab Pane 3",
    },
  ];

  useEffect(() => {
    indexDB.get("current-course").then((val: any) => {
      setTitle(JSON.parse(val).name);
      setBackUrl(JSON.parse(val).backUrl);
      setBackBreadCrumb(JSON.parse(val).backBreadCrumb);
      
    });
  }, []);

  return (
    <Content>
      <PageHeader
        title={title || ""}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        headerGroupButton={true}
      // childrenGroupButton={
      //   <div className="button-container">
      //     <Button onClick={() => {}} className="btn">
      //       <SettingOutlined />
      //       <Typography.Text>Cài đặt</Typography.Text>
      //     </Button>
      //     <Button onClick={() => {}} className="btn btn-primary">
      //       <SaveOutlined />
      //       <Typography.Text>Lưu</Typography.Text>
      //     </Button>
      //   </div>
      // }
      />

      <div className="content-layout">
        {/* <MoocBreadcrumb className="pt-1 pb-1" />
        <h3 className="font-size-24 line-height-38">Khoá học figma cơ bản</h3> */}
        <div className="practice-layout">
          {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}
          <CourseStructure />
        </div>
      </div>
    </Content>
  );
}

export default PreviewQuiz;
