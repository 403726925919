import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Flex,
  Form,
  Layout,
  Modal,
  Radio,
  Row,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../../components/page-header/PageHeader";
import {
  getSurveyDetailAll,
  getSurveyInfo,
  getSurveyObjectsFilterById,
} from "../../../service/survey-managment/list-survey";
import { ChangeEvent, useEffect, useState } from "react";
import message from "antd/lib/message";
import { DetailContent } from "../mooc-survey/components/detail-content/DetailContent";
import CustomCard from "../../../components/custom-card/CustomCard";
import "./index.scss";
import { FileDoneOutlined } from "@ant-design/icons";
import { surveyStatus } from "../survey-construction/detail-survey/constants/data";
import SettingSurvey from "../survey-construction/detail-survey/component/setting-survey";
import {
  getdataIndustryGroup,
  getIndustry,
  getUniversity,
} from "../../../service/common";
import {
  approveSurvey,
  getInfoApproval,
} from "../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import moment from "moment";
import { useAuthStore, useSurveyDetail, useSurveyInfo } from "../../../stores/stores";
import { HelpService } from "../../../service/helper.service";
import { get } from "lodash";
import { ListSurveySection } from "../../../types/survey-managment/survey";
import toolbarOptions from "../survey-construction/detail-survey/component/editor-quill/toolbarOptions";
import ReactQuill from "react-quill";
import { MoocSurveyContentSection } from "../../../types/survey-managment/mooc-survey-content";
import DetailSection from "../survey-construction/detail-survey/component/detail-section/DetailSection";
import { initialValueListSection } from "../survey-construction/detail-survey/component/constant/data";

const SurveyApproval = () => {
  const params = useParams();
  const { Content } = Layout;
  const { Text } = Typography;
  const { Panel } = Collapse;
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigate();
  const imgPath = "/images/";
  const [status, setStatus] = useState();
  const [detailDataSurvey, setDetailDataSurvey] = useState<any>({});
  const [listInfoSurvey, setListInfoSurvey] = useState<any[]>();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataFilter: {},
  });
  const { surveyInfo, setSurveyInfo } = useSurveyInfo((state) => {
    return {
      surveyInfo: state?.surveyInfo,
      setSurveyInfo: state?.setSurveyInfo,
    };
  });
  const [infoApproval, setInfoApproval] = useState<any>({});
  const [listInfoApproval, setListInfoApproval] = useState<any[]>();
  const [form] = Form.useForm();
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const [isRequiredRemark, setIsRequiredRemark] = useState(false);

  const renderSwitchSurvey = (value: string) => {
    switch (value) {
      case "surveyName":
        return <div>Tên đợt khảo sát</div>;
      case "surveyStatusName":
        return <div>Trạng thái</div>;
      case "industry":
        return <div>Khoa/nhóm ngành</div>;
      case "industryGroup":
        return <div>Chuyên ngành</div>;
      case "courseTypeName":
        return <div>Loại khóa học</div>;
      case "universityHost":
        return <div>Trường chủ trì</div>;
      case "collaborator":
        return <div>Trường hợp tác</div>;
      default:
        return "";
    }
  };
  const renderSwitchApproval = (value: string) => {
    switch (value) {
      case "university":
        return "Trường chủ trì: ";
      case "submitter":
        return "Người gửi: ";
      case "submissionDate":
        return "Ngày gửi: ";
      default:
        return "";
    }
  };
  const getSurveyDetailInfo = () => {
    try {
      getSurveyDetailAll(get(params, "id", "")).then((res) => {
        const data = res.data.data;
        // console.log(`sssdata:::`, data)
        setSurveyInfo(data);
        if (
          data?.moocSurveySection?.length &&
          data?.moocSurveySection?.length > 0
        ) {
          setListSurveySection(sortDataFunction(data?.moocSurveySection));
          setListSurveySectionInput(sortDataFunction(data?.moocSurveySection));
          setDataSection(sortDataFunction(data?.moocSurveySection));
        }
        if (data?.moocSurveySection?.length === 0) {
          setListSurveySection(initialValueListSection);
          setListSurveySectionInput(initialValueListSection);
          setDataSection(initialValueListSection);
        }
        setIsLoading(false);
      });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }

    // setListSurveySection(sortDataFunction(listSurveySection3));
    // setDataSection(sortDataFunction(listSurveySection3));
    // setIsLoading(false);
  };
  const getDetailMoocSurveyData = async (id: any) => {
    try {
      await getSurveyInfo(id)
        .then((res) => {
          const detailData = res.data.data;
          setStatus(res.data.data.surveyStatus);
          setDetailDataSurvey({
            surveyName: detailData?.surveyName || "",
            surveyStatusName: detailData?.surveyStatusName || "",
            industry: detailData?.industry || "",
            industryGroup: detailData?.industryGroup || "",
            courseTypeName: detailData?.courseTypeName || "",
            universityHost: detailData?.universityHost || "",
            collaborator: detailData?.collaborator || "",
          });
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };
  const fetchDataOptions = async () => {
    const response = await Promise.all([
      getUniversity(),
      getIndustry(),
      getdataIndustryGroup(),
      getSurveyObjectsFilterById(Number(params.id)),
    ]);
    const dataUniversity = response[0].data.data;
    const dataIndustry = response[1].data.data;
    const dataIndustryGroup = response[2].data.data;
    const datafilterOject = response[3].data.data.data;
    setDataOptions({
      ...dataOptions,
      dataIndustry: dataIndustry,
      dataUniversity: dataUniversity,
      dataIndustryGroup: dataIndustryGroup,
      dataFilter: datafilterOject,
    });
  };
  const getInfoApprovalById = async (id: any) => {
    await getInfoApproval(id)
      .then((res) => {
        const resData = res.data.data;
        setInfoApproval({
          university: resData?.university,
          submissionDate: moment(resData?.submissionDate).format("DD/MM/yyyy"),
          submitter: resData?.submitter,
        });
      })
      .catch((err) => {
        message.error("Có lỗi chưa xác định");
      });
  };
  const {
    listSurveySection,
    setListSurveySection,
    updateSurveySection,
    setListSurveySectionInput,
    saveDraftSurveySection,
  } = useSurveyDetail((state) => {
    return {
      listSurveySection: state?.listSurveySections,
      setListSurveySection: state?.setListSurveySections,
      updateSurveySection: state?.updateSurveySection,
      setListSurveySectionInput: state?.setListSurveySectionsInput,
      saveDraftSurveySection: state?.saveDraftSurveySections,
    };
  });
  const [dataSection, setDataSection] = useState<any>(listSurveySection);

  const filterDeleteData = (data: ListSurveySection[]) => {
    const filteredData = data
      .map((section) => {
        const filteredQuestions = section.moocSurveyQuestion
          ?.map((question) => {
            const filteredAnswers = question.moocSurveyQuestionAnswer?.filter(
              (answer) => !answer.isDeleted
            );
            return {
              ...question,
              moocSurveyQuestionAnswer: filteredAnswers,
            };
          })
          .filter((question) => !question.isDeleted);

        return {
          ...section,
          moocSurveyQuestion: filteredQuestions,
        };
      })
      .filter((section) => !section.isDeleted);

    return filteredData;
  };

  const sortDataFunction = (data: ListSurveySection[]) => {
    const filteredData = data.filter((item) => !item.isDeleted);

    let sortedData = filteredData.sort((a, b) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      }
      // Xử lý trường hợp orderNumber có thể undefined
      // Ví dụ: Trả về 0, không thay đổi thứ tự
      return 0;
    });

    // Sắp xếp moocSurveyQuestion của mỗi section
    sortedData.forEach((section) => {
      if (section.moocSurveyQuestion) {
        section.moocSurveyQuestion.sort((a, b) => {
          if (a.orderNumber !== undefined && b.orderNumber !== undefined) {
            return a.orderNumber - b.orderNumber;
          }
          return 0;
        });

        // Sắp xếp moocSurveyQuestionAnswer của mỗi câu hỏi
        section.moocSurveyQuestion.forEach((question) => {
          if (question.moocSurveyQuestionAnswer) {
            question.moocSurveyQuestionAnswer.sort((a, b) => {
              if (
                a.answerOrderNumber !== undefined &&
                b.answerOrderNumber !== undefined
              ) {
                return a.answerOrderNumber - b.answerOrderNumber;
              }
              return 0;
            });
          }
        });
      }
    });

    return sortedData;
  };
  const updateDataSection = (newDataSection: ListSurveySection[]) => {
    setListSurveySection(sortDataFunction(newDataSection));
    setDataSection(sortDataFunction(newDataSection));
  };
  useEffect(() => {
    getSurveyDetailInfo();
    getDetailMoocSurveyData(params.id);
    fetchDataOptions();
    getInfoApprovalById(params.id);
  }, []);
  useEffect(() => {
    const convertObjectDataSurvey = Object.entries(detailDataSurvey);
    const listInfoSurvey = convertObjectDataSurvey.map((item: any, index) => (
      <div className="list-item" key={index}>
        <Typography.Title level={5} className="list-item--title">
          {renderSwitchSurvey(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {item[0] === "surveyStatusName" ? (
              <Badge
                key={surveyStatus.filter((x) => x.value == status)[0].color}
                color={surveyStatus.filter((x) => x.value == status)[0].color}
              />
            ) : null}{" "}
            {item[1]}
          </Typography.Text>
        </div>
      </div>
    ));

    setListInfoSurvey(listInfoSurvey);
  }, [detailDataSurvey]);
  useEffect(() => {
    const convertObjectDataApproval = Object.entries(infoApproval);
    const listInfoApproval = convertObjectDataApproval.map(
      (item: any, index) => {
        return (
          <div className="info-row-approval" key={index}>
            <label>{renderSwitchApproval(item[0])} </label>{" "}
            <span className="sub-title-approval-custom-card">{item[1]}</span>
          </div>
        );
      }
    );
    setListInfoApproval(listInfoApproval);
  }, [infoApproval]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin khảo sát
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về đợt khảo sát
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfoSurvey}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Nội dung khảo sát",
      children: (
        <DetailContent>
          <DetailSection
            dataSection={filterDeleteData(dataSection)}
            setDataSection={setDataSection}
            setUpdateDataSection={updateDataSection}
            surveyInfo={surveyInfo}
          />
        </DetailContent>
      ),
    },
    {
      key: "3",
      label: "Cài đặt",
      children: (
        <SettingSurvey dataOptions={dataOptions} isVisibleButton={true} />
      ),
    },
  ];
  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        Modal.confirm({
          icon: <FileDoneOutlined />,
          title: `Xác nhận phê duyệt đợt khảo sát ${detailDataSurvey?.surveyName}?`,
          content: (
            <>
              <p>
                Đảm bảo rằng tất cả các
                <Text type="warning">
                  {" "}
                  thông tin liên quan đã được kiểm tra và cặp nhật đầy đủ{" "}
                </Text>
                trước khi Xác nhận kết quả phê duyệt khảo sát
              </p>
            </>
          ),
          onOk: () => form.submit(),
          centered: true,
          okText: "Xác nhận",
          cancelText: "Đóng",
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      })
      .catch((error) => { });
  };

  const modules = {
    toolbar: toolbarOptions,
  };

  return (
    <>
      <Content>
        <PageHeader
          title={detailDataSurvey?.surveyName}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
        />
        <div className="tabs-heading approval-tabs-heading">
          <div className="approval-tabs-container">
            <Tabs defaultActiveKey="1" items={items} />
          </div>
          <div className="approval-custom-card">
            <div className="approval-space"></div>
            <div className="approval-card-container">
              <CustomCard
                title={
                  <div className="card-heading--info">
                    <Typography.Title level={5}>
                      Thông tin duyệt
                    </Typography.Title>
                    <Collapse defaultActiveKey={['1','2']}>
                      <Panel header="Thông tin gửi" key="1">
                        <div style={{ margin: '8px' }}>
                          {listInfoApproval}
                        </div>
                      </Panel>
                      <Panel header="Kết quả phê duyệt" key="2">
                        <div style={{ margin: '8px' }}>
                          <Form.Provider
                            onFormFinish={(name, { values, forms }) => {
                              if (name === "approval") {
                                const dataSend = {
                                  surveyId: params.id ? parseInt(params.id) : null,
                                  individualId: userId,
                                  approvalRemark: values.approvalRemark,
                                  surveyStatus: values.surveyStatus,
                                };
                                approveSurvey(dataSend).then((res) => {
                                  const { data, statusCode, message } = res.data;
                                  if (statusCode === 200) {
                                    helpService.successMessage(
                                      get(data, "message", message)
                                    );
                                  }
                                  navigation(`/survey-training/browse-survey`);
                                });
                              }
                            }}
                          >
                            <Form
                              form={form}
                              layout="vertical"
                              initialValues={{
                                surveyStatus: true,
                              }}
                              name="approval"
                            >
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item
                                    name="surveyStatus"
                                    rules={[
                                      () => ({
                                        validator(_, value) {
                                          if (value === true) {
                                            form.setFields([
                                              {
                                                name: "approvalRemark",
                                                errors: [],
                                              },
                                            ]);
                                            setIsRequiredRemark(false);
                                            return Promise.resolve();
                                          } else {
                                            setIsRequiredRemark(true);
                                            return Promise.resolve();
                                          }
                                        },
                                      }),
                                    ]}
                                    validateTrigger={["onChange", "onBlur"]}
                                  >
                                    <Radio.Group>
                                      <Radio value={true}>Đồng ý</Radio>
                                      <Radio value={false}>Không đồng ý</Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <Form.Item
                                    name="approvalRemark"
                                    label="Mô tả phê duyệt"
                                    rules={[
                                      { required: isRequiredRemark, message: "" },
                                      () => ({
                                        validator(_, value) {
                                          if (
                                            isRequiredRemark &&
                                            (!value ||
                                              value.replace(/<[^>]+>/g, "").trim() ===
                                              "")
                                          ) {
                                            return Promise.reject(
                                              "Vui lòng nhập mô tả phê duyệt!"
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }),
                                    ]}
                                    validateTrigger={["onChange", "onBlur"]}
                                  >
                                    <ReactQuill
                                      modules={modules}
                                      theme="snow"
                                      style={{
                                        height: "200px",
                                        marginBottom: "48px",
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row gutter={16}>
                                <Col span={24}>
                                  <div className="btn-approval">
                                    <Button
                                      onClick={() => {
                                        navigation(`/survey-training/browse-survey`);
                                      }}
                                    >
                                      Hủy
                                    </Button>
                                    <Button onClick={handleOk} type="primary">
                                      Lưu
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                          </Form.Provider>
                        </div>
                      </Panel>
                    </Collapse>

                  </div>
                }
              ></CustomCard>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default SurveyApproval;
