import React, {FunctionComponent, useEffect, useState} from "react";
import {Button, Col, DatePicker, Form, Row, Typography} from "antd";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import "./filter-group.scss";
import {FormInstance} from "antd/lib";
// import { dataStatus } from '../../../pages/list-user/constants/data';
import {FilterData} from "./filter";
import {getLecturerAssign} from "../../../../service/assign-course-construction";
import {
  getAllSectionByCourseId,
  getAllSequenceByCourseId
} from "../../../../service/content-list-course/contentListCourseApi";
import {useParams} from "react-router-dom";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  tab: string;
  filterData: FilterData;
};

const statusList = [
  {
    value: 0,
    name: "Bản nháp",
    describe: "Phần này sẽ không được người dùng nhìn thấy.",
  },
  {
    value: 1,
    name: "Riêng tư",
    describe:
      "Phần này sẽ được người dùng nhìn thấy nhưng các hoạt động học tập sẽ không thể truy cập được",
  },
  {
    value: 2,
    name: "Công khai",
    describe: "phần này sẽ được người dùng nhìn thấy và truy cập miễn phí.",
  },
];

const statusListQuiz = [
  {
    value: true,
    name: "Hoạt động",
  },
  {
    value: false,
    name: "Không hoạt động",
  },
];

const optionShow = [
  {
    value: true,
    label: 'Hiển thị'
  },
  {
    value: false,
    label: 'Ẩn'
  }
]

const FormFilter: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  filterData,
  setFilterData,
  tab,
}) => {
  const params = useParams()
  const {RangePicker} = DatePicker;
  const [dataOptionsFilter, setDataOptionsFilter] = useState<any>([]);
  const [sectionList, setSectionList] = useState<any>([])
  const [sequenceList, setSequenceList] = useState<any>([])

  const getListLecturerFilter = async () => {
    await getLecturerAssign().then((res) => {
      setDataOptionsFilter(res.data.data);
    });
  };

  const getAllSection = async () => {
    await getAllSectionByCourseId(params?.id).then((res) => {
      setSectionList(res.data)
    })
  }

  const getAllSequence = async () => {
    await getAllSequenceByCourseId(params?.id).then((res) => {
      setSequenceList(res.data)
    })
  }

  useEffect(() => {
    getAllSection()
    getAllSequence()
    getListLecturerFilter();
  }, [tab]);

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
            {
              tab === '2' || tab === '3' ?
                <> {tab === '2' ? <></> : null}
                  <Col
                    xl={{span: 3}}
                    md={{span: 3}}
                    sm={{span: 3}}
                    xs={{span: 3}}
                  >
                    <Typography.Title level={5}>Chương</Typography.Title>
                    <Form.Item name="sectionId">
                      <FormItemDropdown
                        placeholder="Chọn chương"
                        onChange={(option) => setFilterData(option, "sectionId")}
                        options={sectionList}/>
                    </Form.Item>
                  </Col></>
                : <></>}

            {
              tab === '3' ?
                <Col
                  xl={{span: 3}}
                  md={{span: 3}}
                  sm={{span: 3}}
                  xs={{span: 3}}
                >
                  <Typography.Title level={5}>Bài giảng</Typography.Title>
                  <Form.Item name="sequenceId">
                    <FormItemDropdown
                      placeholder="Chọn bài giảng"
                      onChange={(option) => setFilterData(option, "sequenceId")}
                      options={sequenceList}
                    />
                  </Form.Item>
                </Col>
                : null
            }
            {
              tab !== '6' ?
                <>
                  <Col
                    xl={{span: 3}}
                    md={{span: 3}}
                    sm={{span: 3}}
                    xs={{span: 3}}
                  >
                    <Typography.Title level={5}>Trạng thái</Typography.Title>
                    <Form.Item name="availableStatus">
                      <FormItemDropdown
                        placeholder="Chọn trạng thái"
                        onChange={(option) => setFilterData(option, "availableStatus")}
                        options={tab === '6' ? statusListQuiz : statusList}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{span: 4}}
                    md={{span: 5}}
                    sm={{span: 5}}
                    xs={{span: 24}}
                  >
                    <Typography.Title level={5}>Ngày xuất bản</Typography.Title>
                    <Form.Item name="planApprovalDate">
                      <RangePicker
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                        format="YYYY-MM-DD"
                        value={filterData.planCreationDate}
                        onChange={(_value, dateString: any) =>
                          setFilterData(dateString, "planApprovalDate")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{span: 4}}
                    md={{span: 5}}
                    sm={{span: 5}}
                    xs={{span: 24}}
                  >
                    <Typography.Title level={5}>
                      Ngày bắt đầu
                    </Typography.Title>
                    <Form.Item name="planCreationDate">
                      <RangePicker
                        placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                        format="YYYY-MM-DD"
                        value={filterData.planApprovalDate}
                        onChange={(_value, dateString: any) =>
                          setFilterData(dateString, "planCreationDate")
                        }
                      />
                    </Form.Item>
                  </Col>
                </> : null
            }
            {
              tab === '6' ? (
                <>
                  <Col
                    xl={{span: 3}}
                    md={{span: 3}}
                    sm={{span: 3}}
                    xs={{span: 3}}
                  >
                    <Typography.Title level={5}>Đánh giá</Typography.Title>
                    <Form.Item name="unitIds">
                      <FormItemDropdown
                        placeholder="Chọn đánh giá"
                        modeDropdown={"multiple"}
                        onChange={(option) => setFilterData(option, "unitIds")}
                        options={tab === '6' ? dataOptions.optionUnitName : []}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xl={{span: 4}}
                    md={{span: 5}}
                    sm={{span: 5}}
                    xs={{span: 24}}
                  >
                    <Typography.Title level={5}>Hiển thị</Typography.Title>
                    <Form.Item name="isShow">
                      <FormItemDropdown
                        placeholder="Chọn trạng thái"
                        onChange={(option) => setFilterData(option, "isShow")}
                        options={optionShow}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null
            }
            <Col
              xl={{span: 3}}
              md={{span: 9}}
              sm={{span: 9}}
              xs={{span: 24}}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined/>
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined/>
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
