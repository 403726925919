import { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import PageHeader from "../../components/page-header/PageHeader";
import "./list-user.scss";
import { Button, Dropdown, Form, Layout, message, Modal, Popconfirm, Space, Switch, TableColumnsType, TableProps, Typography } from 'antd'
import FilterTable from "./components/filter-table/FilterTable";
import DrawerCustom from "../../components/drawer/Drawer";
import FormCreateUser from "./components/form-create-user/FormCreateUser";
import { DataType } from "../../types/listUsers";
import { CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, KeyOutlined, MoreOutlined, SaveOutlined, UploadOutlined, WarningOutlined } from "@ant-design/icons";
import FormFilter from "./components/filter-group/FormFilter";
import { Link, useSearchParams } from "react-router-dom";
import TableData from "../../components/table-data/TableData";
import { get, isEmpty } from "lodash"
import { validateFieldCreate } from "./constants/data";
import {
  changeStatusAccount,
  createUser,
  getAccountUsers,
  getAllEnterpriseCustomer,
  getOffices,
  getRoles,
  getUnits
} from "../../service/list-account-users/listAccountUsersApi";
import { HelpService } from "../../service/helper.service";
import { routesConfig } from "../../config/routes";
import { getCurrentPageFromLink } from "../../utils/getPageFromLink"
import { FormInstance } from "antd/lib";
import { combineArray } from "../../utils/arrays";
import { formatOriginTime } from "../../utils/converDate";
import DetailParticipateDrawer from "../../components/drawer/management-participate-platform/detail-participant";
import DetailBasicAccountAdminDrawer from "../../components/drawer/basic-admin-account";
import ChangePassUserDrawer from "../../components/drawer/basic-admin-account/change-password";
import CommonModal from "../../components/modal/common";
const { confirm } = Modal;

type ListUserProps = {
  title?: string
}


const ListUser: FunctionComponent<ListUserProps> = ({ title }) => {
  const helpService = new HelpService();

  const [params, setParams] = useSearchParams()

  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [isOpenBasicAccountAdmin, setIsOpenBasicAccountAdmin] = useState<boolean>(false)
  const [isOpenChangePass, setIsOpenChangePass] = useState<boolean>(false)
  const [isOpenedConfirmDelete, setIsOpenedConfirmDelete] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);

  const [detailData, setDetailData] = useState<any>()
  const [accountUsers, setAccountUsers] = useState<DataType[]>([])
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: []
  })

  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    status: null,
    approvedDate: null,
  });

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const [form] = Form.useForm()

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
      enterpriseId: filterData.universityId ?? null,
      organizationId: undefined,
      createdFromDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdToDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      status: filterData.status === 1 ? false : true,
    };

    await getAllEnterpriseCustomer(data)
      .then((res) => {
        console.log('res', res)
        const { data } = res.data
        setAccountUsers(data.data)
        setTotalRecords(data.total);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search)
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink))
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([getRoles(), getUnits(), getOffices()])
        const dataRoles = response[0].data.data
        const dataUnits = response[1].data.data
        const dataOffices = response[2].data.data
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnits,
          dataRoles: dataRoles
        })
      }
      fetchDataOptions()
    }

  }, []);

  useEffect(() => {
    getListData()
  }, [pageSize, pageNumber, statusOrder])

  const options = [
    {
      key: "1",
      label: "Đổi mật khẩu",
      icon: <KeyOutlined />,
    },
    {
      key: "2",
      label: "Xoá tài khoản",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ]

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      width: 30
    },
    {
      title: 'Họ và tên người dùng',
      dataIndex: 'name',
      key: 'name',
      width: 30
    },
    {
      title: 'Trường đại học',
      dataIndex: 'unitName',
      key: 'unitName',
      width: 20
    },
    {
      title: 'Vai trò',
      dataIndex: 'vaiTros',
      key: 'vaiTros',
      render: (_: any, record: any) => {
        return (
          record?.roles?.map((item: any, index: number) => (
            <p key={index} className={`border rounded-md w-fit p-1 mt-1 bg-gray-100 break-all`}>{item?.name}</p>
          ))
        )
      },
      width: 30
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>{record?.dateJoined ? formatOriginTime(record?.dateJoined) : null}</>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      // shouldCellUpdate(record, prevRecord) {
      //   return true
      // },
      render: (_: any, record: { trangThai: boolean; id: number }) => {
        const handleChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
          record.trangThai = !checked
          event.preventDefault()
        }

        const confirm = (id: number) => {
          const data = {
            usedId: record?.id,
            isActive: !record?.trangThai
          }
          changeStatusAccount(data).then((res) => {
            if (res.status === 200) {
              helpService.successMessage(`${record?.trangThai ? 'Khóa' : 'Mở khóa'} tài khoản thành công`)
              record.trangThai = !record?.trangThai;
            }
          })
        }

        const cancel = () => {
        }

        return (
          <Popconfirm
            description={`Bạn có chắc chắn muốn ${record.trangThai ? 'khóa' : 'mở khóa'} tài khoản?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.trangThai}
              checked={record.trangThai}
              onChange={handleChange} />
          </Popconfirm>
        )
      },
      width: 20
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record) => {
        const location = {
          id: `${record.id}`
        }

        return (
          (
            <Space className="flex justify-center">
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenBasicAccountAdmin(true)
                  setDetailData(record)
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: options,
                  onClick: ({ key }) => {
                    if (key === '1') {
                      setIsOpenChangePass(true)
                      setDetailData(record)
                    }
                    if (key === '2') {
                      setIsOpenedConfirmDelete(true)
                      setDetailData(record)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          )
        )
      },
      fixed: 'right',
      width: 15,
    }
  ]

  const defaultCheckedList = dataColumns.map((item) => item.key as string)
  const [checkedList, setCheckedList] = useState(defaultCheckedList)

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string)
    }))
  }, [checkedList])


  const handleOpenCreate = () => {
    setIsOpened(true)
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter)
  }


  const handleClose = () => {
    setIsOpened(false)
    form.resetFields()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setParams({ [name]: value })
    setSearchValue(value)
    if (value.length === 0) {
      setSearchValue("")
      setParams()
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      getAccountUsers({
        keyword: searchValue
      }).then(users => {
        const { data, totalRecords } = users.data.data
        setAccountUsers(data)
        setTotalRecords(totalRecords)
      }).catch(err => console.error(err))
    }
  }

  const handleSubmitSearch = () => {
    getAccountUsers({
      keyword: searchValue
    }).then(users => {
      const { data, totalRecords } = users.data.data
      setAccountUsers(data)
      setTotalRecords(totalRecords)
    }).catch(err => console.error(err))

    if (searchValue === '') {
      setPageNumber(1)
    }
  }

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      universityId: null,
      status: null,
      approvedDate: null,
    });
  }

  const handleFinish = (values: any) => {
    setLoading(true)
    getAccountUsers({
      vaiTros: get(values, 'vaiTros', []),
      donVis: combineArray(dataOptions.dataUnits, get(values, 'donVis', [])),
      trangThai: get(values, 'trangThai', []),
    }).then(res => {
      const { data, totalRecords } = res.data.data
      setLoading(false)
      setAccountUsers(data)
      setTotalRecords(totalRecords)
    })
  }

  const onFinishCreate = (values: any) => {
    createUser(values).then(res => {
      const { data, statusCode } = res.data
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", "Tạo tài khoản thành công"))
        form.resetFields()
        getListData()
        handleClose()
      }
    }).catch(err => {
    })
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị cơ sở',
    },
  ]

  const deleteAccount = () => {
    setIsOpenedConfirmDelete(false)
    message.success("Xóa tài khoản thành công")
  }

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === 'createUser') {
          const { createUser } = forms
          const data = createUser.getFieldsValue(validateFieldCreate)
          onFinishCreate(data)
        }
        if (name === "filterForm") {
          getListData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable handleOpen={handleOpenCreate} searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput} />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>

        <TableData dataTable={accountUsers} dataColumns={newColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange}
        />

        <FormCreateUser open={isOpened} handleClose={handleClose} fetchData={getListData} />

        <DetailBasicAccountAdminDrawer
          open={isOpenBasicAccountAdmin}
          onClose={() => setIsOpenBasicAccountAdmin(false)}
          detailData={detailData}
        />

        <ChangePassUserDrawer
          open={isOpenChangePass}
          onClose={() => setIsOpenChangePass(false)}
          detailData={detailData}
          fetchData={getListData}
        />

        <CommonModal
          open={isOpenedConfirmDelete}
          title={'Xác nhận xóa tài khoản'}
          desc={`Vui lòng xác nhận xóa tài khoản Quản trị cơ sở`}
          closeIcon={true}
          okText={'Xóa tài khoản'}
          cancelText={"Huỷ"}
          icon={<ExclamationCircleOutlined style={{ color: "orange" }} />}
          confirm={deleteAccount}
          closeModal={() => setIsOpenedConfirmDelete(false)}
          className="modal-delete-common"
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListUser;
