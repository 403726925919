import { Col, Row, Empty, Spin, Select } from "antd";
import TableWithTotals from "../../../../../../../components/table-with-totals/TableWithTotals";
import {
  BarStackedChartProps,
  ClassProps,
  DualChart,
} from "../../../../../../../types/static-report";
import StatisticalReportCard from "../../../StatisticalReportCard";
import { createTableData, rateTransformData } from "../../tableConvertData";
import dayjs from "dayjs";
import { staticReportStore } from "../../../../../../../stores/stores";
import {
  exportRateClassAction,
  exportRateSequenceAction,
  exportRateUnitByClass,
  exportRateUnitByCourse,
  exportRateUnitByModule,
  exportRateUnitBySequence,
  exportUnitDiscuss,
  exportUnitReviewAndAccess,
} from "../../../../../../../service/statistical-report";
import { chartContentTypes } from "../../../../../../../utils/staticReport";

type Props = {
  loading: Record<string, boolean>;
  rateUnitByCourse: BarStackedChartProps[];
  rateUnitByModule: BarStackedChartProps[];
  rateUnitBySequence: BarStackedChartProps[];
  unitReviewAndAccess: DualChart[];
  rateSequenceAction: BarStackedChartProps[];
  rateClassAction: BarStackedChartProps[];
  unitDiscuss: BarStackedChartProps[];
  rateUnitByClass: BarStackedChartProps[];
  classList: ClassProps[];
};
const FeedbackAndEvaluationTableTab = (props: Props) => {
  const {
    loading,
    rateUnitByCourse,
    rateUnitByModule,
    rateUnitBySequence,
    unitReviewAndAccess,
    rateSequenceAction,
    rateClassAction,
    unitDiscuss,
    rateUnitByClass,
    classList,
  } = props;
  const { filterValues, setFilterValues } = staticReportStore();

  interface DataType {
    key: string;
    hinhThuc: string;
    soLuong: number;
  }

  const data: DataType[] = [
    { key: "1", hinhThuc: "Thẻ ghi danh", soLuong: 15100 },
    { key: "2", hinhThuc: "Riêng tư", soLuong: 15100 },
    { key: "3", hinhThuc: "Tự do", soLuong: 15100 },
  ];

  const columns = [
    { title: "Hình thức", dataIndex: "hinhThuc", key: "hinhThuc" },
    { title: "Số lượng", dataIndex: "soLuong", key: "soLuong" },
  ];

  const rateColumns = [
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "1 sao",
      dataIndex: "1 sao",
      key: "1 sao",
    },
    {
      title: "2 sao",
      dataIndex: "2 sao",
      key: "2 sao",
    },
    {
      title: "3 sao",
      dataIndex: "3 sao",
      key: "3 sao",
    },
    {
      title: "4 sao",
      dataIndex: "4 sao",
      key: "4 sao",
    },
    {
      title: "5 sao",
      dataIndex: "5 sao",
      key: "5 sao",
    },
  ];
  const handleExport = async (
    apiEndpoint: (data: any) => Promise<any>,
    fileName: string,
    payloadOverride?: any
  ) => {
    const defaultPayload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
    };

    const payloads = { ...defaultPayload, ...payloadOverride };

    try {
      const res = await apiEndpoint(payloads);

      const fileData = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(fileData);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  const handleContentTypesChange = (value: string) => {
    let courseStructureType = "";
    let moduleGroup = null;
    switch (value) {
      case "5":
        courseStructureType = "sequence";
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = "course";
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = "unit";
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      courseStructureType: courseStructureType,
      moduleGroup: moduleGroup,
    });
  };

  return (
    <div>
      <>
        <div className="chart-title">Thống kê số lượng phản hồi</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê số lượng phản hồi theo thời gian"
              content={
                <Spin spinning={loading.unitDiscuss}>
                  {unitDiscuss.length > 0 ? (
                    <TableWithTotals
                      data={createTableData(unitDiscuss).dataSource}
                      columns={[
                        {
                          title: "Thời gian tạo",
                          dataIndex: "name",
                          key: "name",
                        },
                        ...createTableData(unitDiscuss).columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                      hasTotal={true}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportUnitDiscuss,
                      "Thống kê số lượng phản hồi theo thời gian.xlsx",
                      {
                        timeUnit: filterValues.timeUnit,
                      }
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.rateSequenceAction}>
                {rateSequenceAction.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(rateSequenceAction).dataSource}
                    columns={[
                      {
                        title: "Lớp",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateSequenceAction).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "",
                action: () =>
                  handleExport(exportRateSequenceAction, "unit_by_course.xlsx"),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo lớp"
            content={
              <Spin spinning={loading.rateClassAction}>
                {rateClassAction.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(rateClassAction).dataSource}
                    columns={[
                      {
                        title: "Lớp",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateClassAction).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "",
                action: () =>
                  handleExport(exportRateClassAction, "unit_by_course.xlsx"),
              },
            ]}
          />
        </Col>
      </Row>

      <>
        <div className="chart-title">Thống kê số lượng đánh giá</div>
        <Col span={24}>
          <StatisticalReportCard
            chartName="Số lượng đánh giá theo thời gian"
            content={
              <Spin spinning={loading.unitReviewAndAccess}>
                {unitReviewAndAccess.length > 0 ? (
                  <TableWithTotals
                    data={unitReviewAndAccess?.map((it) => ({
                      ...it,
                      total: it?.columnValue + it?.lineValue,
                    }))}
                    columns={[
                      {
                        title: "Ngày làm đánh giá",
                        dataIndex: "criteria",
                        key: "criteria",
                      },
                      {
                        title: "Lượng truy cập",
                        dataIndex: "lineValue",
                        key: "lineValue",
                      },
                      {
                        title: "Lượng làm đánh giá",
                        dataIndex: "columnValue",
                        key: "columnValue",
                      },
                      {
                        title: "Tổng",
                        dataIndex: "total",
                        key: "total",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  defaultValue="3"
                  style={{ width: 170 }}
                  options={chartContentTypes.filter(
                    (item) => item.value !== "5" && item.value !== "6"
                  )}
                  onSelect={(value) => {
                    handleContentTypesChange(value);
                  }}
                  allowClear
                />
              </div>
            }
            exportOptions={[
              {
                label: "",
                action: () =>
                  handleExport(
                    exportUnitReviewAndAccess,
                    "Số lượng đánh giá theo thời gian.xlsx",
                    {
                      timeUnit: filterValues.timeUnit,
                      courseStructureType: filterValues?.courseStructureType,
                      moduleGroup: filterValues?.moduleGroup,
                    }
                  ),
              },
            ]}
          />
        </Col>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Tỷ lệ kết quả làm đánh giá"
              content={
                <Spin spinning={loading.rateUnitByModule}>
                  {rateUnitByModule.length > 0 ? (
                    <TableWithTotals
                      data={rateTransformData(rateUnitByModule)}
                      columns={rateColumns}
                      keyColumn="content"
                      height={275}
                      hasTotal={false}
                      calculatePercentage={true}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportRateUnitByModule,
                      "Tỷ lệ kết quả làm đánh giá.xlsx"
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Tỷ lệ kết quả đánh giá khoá học"
              content={
                <Spin spinning={loading.rateUnitByCourse}>
                  {rateUnitByCourse.length > 0 ? (
                    <TableWithTotals
                      data={rateTransformData(rateUnitByCourse)}
                      columns={rateColumns}
                      keyColumn="content"
                      height={275}
                      hasTotal={true}
                      calculatePercentage={true}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportRateUnitByCourse,
                      "Tỷ lệ kết quả đánh giá khoá học.xlsx"
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>

      <>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Tỷ lệ kết quả đánh giá theo bài giảng"
              content={
                <Spin spinning={loading.rateUnitBySequence}>
                  {rateUnitBySequence.length > 0 ? (
                    <TableWithTotals
                      data={rateTransformData(rateUnitBySequence)}
                      columns={rateColumns}
                      keyColumn="content"
                      height={275}
                      hasTotal={true}
                      calculatePercentage={true}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportRateUnitBySequence,
                      "Tỷ lệ kết quả đánh giá theo bài giảng.xlsx"
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Tỷ lệ kết quả đánh giá học liệu theo lớp"
              content={
                <Spin spinning={loading.rateUnitByClass}>
                  {data.length > 0 ? (
                    <TableWithTotals
                      data={rateTransformData(rateUnitByClass)}
                      columns={rateColumns}
                      keyColumn="content"
                      hasTotal={true}
                      calculatePercentage={true}
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    showSearch
                    placeholder="Chọn lớp"
                    style={{ width: 170 }}
                    onSelect={(value) => {
                      setFilterValues({ classId: value });
                    }}
                    options={classList}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportRateUnitByClass,
                      "Tỷ lệ kết quả đánh giá học liệu theo lớp.xlsx",
                      {
                        classId: filterValues?.classId,
                      }
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default FeedbackAndEvaluationTableTab;
