import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Switch,
  TimePickerProps,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import CommonModal from "../../../components/modal/common";
import TimePickerCustom from "../../../components/timepicker";
import { updateSettingExamSupervision } from "../../../service/exam-supervision";

const { Title, Text } = Typography;

interface IProps {
  paramFeature: any;
  setParamFeature: (param: any) => void;
}
const SettingFeatured = ({ paramFeature, setParamFeature }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isEditSetting, setIsEditSetting] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  console.log(paramFeature);
  
  const onChangeActive = async (checked: boolean, name: string) => {
    if (!checked) {
      setIsOpen(true);
    } else {
      try {
        await updateSettingExamSupervision(paramFeature?.id, {
          is_active: checked,
        });
        messageApi.open({
          type: "success",
          content: "Thay đổi trạng thái thành công",
        });
        setParamFeature({
          ...paramFeature,
          is_active: checked,
        });
      } catch (error: any) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: error?.response?.data?.message,
        });
      }
    }
  };
  const onChangeSelect = (e: any) => {
    setParamFeature({
      ...paramFeature,
      [e.target.name]: e.target.checked,
    });
  };

  const handleChangeTimeFocus: TimePickerProps["onChange"] = (
    time,
    timeString
  ) => {
    console.log(time, timeString);
    setParamFeature({
      ...paramFeature,
      timeFollow: timeString,
    });
  };
  const handleChangeTimeError: TimePickerProps["onChange"] = (
    time,
    timeString
  ) => {
    console.log(time, timeString);
    setParamFeature({
      ...paramFeature,
      timeDetect: timeString,
    });
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };
  const handleConfirmNotice = async () => {
    try {
      await updateSettingExamSupervision(paramFeature?.id, {
        is_active: false,
        is_notification: false,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
      setParamFeature({
        ...paramFeature,
        is_active: false,
        is_notification: false,
      });
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const paramExamSupervision = {
      is_active: true,
      is_concentration: paramFeature?.is_follow,
      is_location: paramFeature?.is_detect,
      concentration: paramFeature?.timeFollow,
      location: paramFeature?.timeDetect,
    };
    try {
      await updateSettingExamSupervision(
        paramFeature?.id,
        paramExamSupervision
      );
      setParamFeature({
        ...paramFeature,
        is_active: true,
      });
      messageApi.open({
        type: "success",
        content: "Lưu thông tin thành công",
      });

      setIsEditSetting(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {contextHolder}
      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Cài đặt tính chất giám sát thi cử
            </Title>
            <Text className="text-[#00000073]">
              Chọn bật/tắt tính năng giám sát sinh viên trong quá trình thi cử
            </Text>
          </div>
        </Flex>
        <Flex className="py-4">
          <span className="!font-semibold pr-8">Trạng thái</span>
          <div className="px-4">
            <Switch
              defaultChecked
              onChange={(checked) => onChangeActive(checked, "is_active")}
              checked={paramFeature?.is_active}
            />
            <Text className="px-4">
              {paramFeature?.is_active ? "Đang hoạt động" : "Không hoạt động"}
            </Text>
          </div>
        </Flex>
      </Card>
      {paramFeature?.is_active && (
        <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
          <Flex
            justify="space-between"
            align="center"
            className="border-b pb-3"
          >
            <div>
              <Title level={4} className="!m-0">
                Chi tiết cài đặt tính chất giám sát thi cử
              </Title>
              <Text className="text-[#00000073]">
                Chọn tính chất video bắt buộc áp dụng giám sát sinh viên trong
                quá trình thi cử
              </Text>
            </div>
            <Button
              className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
              onClick={() => setIsEditSetting(true)}
            >
              <EditOutlined />
            </Button>
          </Flex>
          <div className="p-4 bg-[#F5F5F5] flex my-4 rounded-lg">
            <span className="flex items-center font-bold">
              Thời gian hoàn thành bài thi
            </span>
            <Flex className="border rounded-lg mx-4" align="center">
              <span className="border-r p-2 pr-4 text-[#00000073]">{paramFeature?.completion_time}</span>
              <p className="text-[#00000073] px-4 !mb-0">Phút</p>
            </Flex>
          </div>
          <Flex justify="space-between" align="center" className="py-2">
            <div>
              <Title level={4} className="!m-0">
                Xác thực khuôn mặt
              </Title>
              <Text className="text-[#00000073]">
                So sánh khuôn mặt sinh viên trong thời gian thi cử với thông tin
                đã định danh
              </Text>
            </div>
            <Checkbox
              className="m-0 !w-fit"
              disabled={true}
              checked={paramFeature?.face}
              onChange={onChangeSelect}
              name="face"
            />
          </Flex>
          <Flex justify="space-between" align="center" className="py-4">
            <div>
              <Title level={4} className="!m-0">
                Theo dõi mức độ tập trung
              </Title>
              <Text className="text-[#00000073]">
                Theo dõi chuyển động khuôn mặt để đánh giá mức độ tập trung của
                sinh viên
              </Text>
            </div>
            <Checkbox
              className="m-0 !w-fit"
              disabled={!isEditSetting}
              checked={paramFeature?.is_follow}
              onChange={onChangeSelect}
              name="is_follow"
            />
          </Flex>
          <Flex align="center" className="">
            <Text className="pr-2">Thời gian cho phép vi phạm</Text>
            <TimePickerCustom
              onChange={handleChangeTimeFocus}
              value={
                paramFeature.timeFollow === "" || !paramFeature.timeFollow
                  ? "00:00:00"
                  : paramFeature.timeFollow
              }
              format="HH:mm:ss"
              disabled={!isEditSetting}
            />
            <Tooltip
              placement="rightTop"
              title={"Khuyến khích cài đặt thời gian vi phạm không quá 10 giây"}
              className="px-2 flex justify-center"
            >
              <InfoCircleOutlined style={{ color: "#00000073" }} />
            </Tooltip>
          </Flex>
          <Flex justify="space-between" align="center" className="py-4">
            <div>
              <Title level={4} className="!m-0">
                Phát hiện rời vị trí
              </Title>
              <Text className="text-[#00000073]">
                Theo dõi sinh viên rời khỏi khung hình camera được quy định
              </Text>
            </div>
            <Checkbox
              className="m-0 !w-fit"
              disabled={!isEditSetting}
              checked={paramFeature?.is_detect}
              onChange={onChangeSelect}
              name="is_detect"
            />
          </Flex>
          <Flex align="center" className="">
            <Text className="pr-2">Thời gian cho phép vi phạm</Text>
            <TimePickerCustom
              onChange={handleChangeTimeError}
              value={
                paramFeature.timeDetect === "" || !paramFeature.timeDetect
                  ? "00:00:00"
                  : paramFeature.timeDetect
              }
              format="HH:mm:ss"
              disabled={!isEditSetting}
            />
            <Tooltip
              placement="rightTop"
              title={"Khuyến khích cài đặt thời gian vi phạm không quá 10 giây"}
              className="px-2 flex justify-center"
            >
              <InfoCircleOutlined style={{ color: "#00000073" }} />
            </Tooltip>
          </Flex>
          {isEditSetting && (
            <Flex justify="end" className="mt-2">
              <Button type="primary" onClick={handleSubmit}>
                Lưu
              </Button>
            </Flex>
          )}
        </Card>
      )}
      <CommonModal
        open={isOpen}
        title={"Xác nhận tắt cài đặt thông báo?"}
        desc={
          "Hệ thống sẽ gỡ toàn bộ các cài đặt. Bạn có chắc chắn muốn tắt cài đặt giám sát thi cử?"
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmNotice}
        closeModal={onCloseModal}
      />
    </div>
  );
};

export default SettingFeatured;
