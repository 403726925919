import { DatePicker, Dropdown, Form, Layout, Modal, Select, Space, Tabs } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import FeedbackAndEvaluationChartTab
  from "./components/chart/feedback-and-evaluation-tab/FeedbackAndEvaluationChartTab";
import UserBehaviorChartTab from "./components/chart/user-behavior-tab/UserBehaviorChartTab";
import {FormInstance, MenuProps} from "antd/lib";
import React, {useState} from "react";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import OverviewChartTab from "./components/chart/overview-tab/OverviewChartTab";
import ResourceChartTab from "./components/chart/resource-tab/ResourceChartTab";
import "./index.scss";
import {BarChartOutlined, BarsOutlined, DownOutlined} from "@ant-design/icons";
import OverviewTable from "./components/table/overview-table";
import ResourceTable from "./components/table/resource-table";
import FeedbackEvaluationTable from "./components/table/feedback-evaluation";
import BehaviorTable from "./components/table/behavior-table";

type Props = {
  title: string;
};
const { Option } = Select;
const StatisticalReportForMinistry = (props: Props) => {
  const {title} = props;
  const [form] = Form.useForm();
  const [filter, setFilter] = useState();
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fromDate,setFromDate] = useState(null);
  const [toDate,setToDate] = useState(null);
  const [dataType, setDataType] = useState<string>("1");
  const [dataOptions, setDataOptions] = useState({
    dataUniversity: [],
    dataLevel: [],
    dataunit: [],
  });
const handlFromDateChange = (date:any) => {
    setFromDate(date);
    if(toDate && date > toDate){
      setToDate(date);
    }
};
const handleToDateChange = (date:any) => {
  setToDate(date);
};

  const [overviewDataStatistical, setOverviewDataStatistical] = useState<any>({
    dataHits: {value: 85, percent: 15.3},
    dataAffiliatedUniversity: {value: 120, percent: 15.3},
    dataLecturer: {value: 250, percent: 15.3},
    dataStudent: {value: 5462, percent: 15.3},
    dataCourse: {value: 76, percent: 15.3},
    dataResources: {value: 1.979, percent: 15.3},
  });

  const [resourceDataStatistical, setResourceDataStatistical] = useState<any>({
    dataCourses: {value: 85, percent: 15.3},
    dataLesson: {value: 120, percent: 15.3},
    dataTest: {value: 250, percent: 15.3},
    dataScormxAPi: {value: 5462, percent: 15.3},
    dataMultimedia: {value: 76, percent: 15.3},
    dataReferences: {value: 1.979, percent: 15.3},
  });

  const items: MenuProps["items"] = [
    {
      label: "Tuần này",
      key: "Tuần này",
    },
    {
      label: "Tháng này",
      key: "Tháng này",
    },
    {
      label: "3 tháng gần đây",
      key: "3 tháng gần đây",
    },
    {
      label: "Tùy chọn",
      key: "Tùy chọn",
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    console.log("Selected key:", e);

    if (e.key === "Tùy chọn") {
      setIsModalVisible(true);
    }
    setSelectedTimeFilter(e.keyPath);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  }
  const handleOk = () => {
    setIsModalVisible(false);
  }

  const tabItems = [
    {
      label: "Tổng quan",
      key: "1",
      children: (dataType === "1") ? <OverviewChartTab overviewDataStatistical={overviewDataStatistical}/> : <OverviewTable/>,
    },
    {
      label: "Tài nguyên",
      key: "2",
      children: (dataType === "1") ? <ResourceChartTab resourceDataStatistical={resourceDataStatistical}/> : <ResourceTable/>,
    },
    {
      label: "Phản hồi & đánh giá",
      key: "3",
      children: (dataType === "1") ? <FeedbackAndEvaluationChartTab/> : <FeedbackEvaluationTable/>,
    },
    {
      label: "Hành vi người dùng",
      key: "4",
      children: (dataType === "1") ? <UserBehaviorChartTab/> : <BehaviorTable/>,
    },
  ];

  const tabItemsTimeFilter = [
    {
      label: <BarChartOutlined/>,
      key: "1",
    },
    {
      label: <BarsOutlined/>,
      key: "2",
    },
  ];

  const handleChangeType = (e: any) => {
    setDataType(e);
  }

  const dropdownMenu = (
    <>
        <Dropdown menu={{ items, onClick: handleMenuClick }} trigger={["click"]}>
            <Space>
                {selectedTimeFilter ? selectedTimeFilter : "Thời gian"}<DownOutlined style={{width: 10}}/>
            </Space>
        </Dropdown>
        <Tabs
            className="tabs-time-filter"
            defaultActiveKey="1"
            items={tabItemsTimeFilter}
            onChange={handleChangeType}
        />
    </>
  );

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => { };

  const handleFinish = (values: any) => { };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title}/>
            </div>
            <FilterTable
              handleOpenFilter={handleOpenFilter}
              isRendering={isRendering}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>
        <div className="chart-table-content-group">
          <Tabs
            defaultActiveKey="1"
            items={tabItems}
            tabBarExtraContent={
              <div className="filter-button-group">
                {dropdownMenu}
              </div>
            }
          />
        </div>
        <Modal
          title="Tùy chọn"
          open={isModalVisible}
          onCancel={handleCancel}
          okText="Xác nhận"
          cancelText="Huỷ"
          onOk={handleOk}
          okButtonProps={{
            disabled: !fromDate || !toDate,
          }}
        >
          <Form labelCol={{span:6}}>
              <Form.Item label="Đơn vị"> 
                  <Select defaultValue="Biểu đồ ngày" style={{ width: '100%' }}>
                    <Option value="Biểu đồ ngày">Biểu đồ ngày</Option>
                    <Option value="Biểu đồ tuần">Biểu đồ tuần</Option>
                    <Option value="Biểu đồ tháng">Biểu đồ tháng</Option>
                    <Option value="Biểu đồ năm">Biểu đồ năm</Option>
                  </Select>
              </Form.Item>
              <Form.Item label="Từ ngày">
                <DatePicker style={{width:'100%'}} format="DD/MM/YYYY" placeholder="Chọn ngày" value={fromDate} onChange={handlFromDateChange}/>
              </Form.Item>
              <Form.Item label="Đến ngày">
              <DatePicker style={{width:'100%'}} format="DD/MM/YYYY" placeholder="Chọn ngày" value={toDate} onChange={handleToDateChange}  disabledDate={(current:any) => current && fromDate && current < fromDate}/>
              </Form.Item>
          </Form>
        </Modal>
      </Form.Provider>
    </Layout>
  );
};

export default StatisticalReportForMinistry;
