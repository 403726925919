// @ts-nocheck
import { FunctionComponent } from "react";
import { Layout, Row, message } from "antd";
import { DataType } from "../../types/listUsers";
import { postCourseUserContactAdvice } from "../../service/page-builder/page-builder";

const { Content } = Layout;
type ProfileInfoProps = {
  dataUser?: DataType;
};

const ContactComponent: FunctionComponent<ProfileInfoProps> = (
  props,
  context
) => {
  const isRegistered = true;
  const displayFor = props["display-for"];
  let isHidden = displayFor == "hidden";
  if (displayFor == "all") {
    isHidden = false;
  }
  if (displayFor == "registered_only") {
    isHidden = !isRegistered;
  }

  const handleSend = async(data:any) =>{
    try {
        await postCourseUserContactAdvice(data).then((result) => {
            message.success('Gửi góp ý thành công!')
        })
    } catch (error) {
        
    }
  }


  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
        name: document.getElementById('name').value,
        email: document.getElementById('email').value,
        message: document.getElementById('message').value
    }
    handleSend(data)

  };

  return (
    <>
      <section id="iytfu" class="text-gray-600 body-font relative">
        <div
          id="ilvri"
          class="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap"
        >
          <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              frameborder="0"
              width="100%"
              height="100%"
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?&q=6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội&z=14&t=q&output=embed"
              id="i6sat"
              class="absolute inset-0"
            ></iframe>
            <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
              <div class="lg:w-1/2 px-6">
                <h2
                  id="it1gc"
                  class="title-font font-semibold text-gray-900 tracking-widest text-xs"
                >
                  ĐỊA CHỈ
                </h2>
                <p id="i3vt2" class="mt-1">
                  6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội
                </p>
              </div>
              <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
                  EMAIL
                </h2>
                <a class="text-indigo-500 leading-relaxed">example@email.com</a>
                <h2
                  id="i81dy"
                  class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4"
                >
                  SỐ ĐIỆN THOẠI
                </h2>
                <p id="i8gj4" class="leading-relaxed">
                  123-456-7890
                </p>
              </div>
            </div>
          </div>
          <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
            <form onSubmitCapture={handleSubmit} id="iol3fl">
              <h2
                id="iyr5fi"
                class="text-gray-900 text-lg mb-1 font-medium title-font"
              >
                Góp ý
              </h2>
              <p id="id5szv" class="leading-relaxed mb-5 text-gray-600">
                Mọi góp ý của bạn giúp chúng tôi hoàn thiện khoá học hơn
              </p>
              <div id="ipuizh" class="relative mb-4">
                <label
                  for="Name"
                  id="iohrm3"
                  title="Tên"
                  class="leading-7 text-sm text-gray-600"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <label for="email" class="leading-7 text-sm text-gray-600">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                />
              </div>
              <div class="relative mb-4">
                <label for="message" class="leading-7 text-sm text-gray-600">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  required
                  class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                ></textarea>
              </div>
              <button
                type="submit"
                id="ioca6c"
                class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
              >
                Gửi
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactComponent;
