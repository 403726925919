import {
  Avatar,
  Flex,
  Form,
  Layout,
  Space,
  Switch,
  TableColumnsType,
  TableProps,
  Typography,
  message,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { EyeOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/lib";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { routesConfig } from "../../../config/routes";
import {
  getListCourseAttendance,
  updateStatusSettingAttendance,
} from "../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../stores/stores";
import { formatDate } from "../../../utils/converDate";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { CheckRole, getPermissions } from "../../../utils/checkRole";
import { Permission } from "../../../layout/sidebar/permission.enum";

type SettingRemindCheckInProps = {
  title?: string;
};

const SettingRemindCheckIn = ({ title }: SettingRemindCheckInProps) => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { setTabAttendance } = useStudyManagementStore((state) => state);

  const [accountReportCheckIn, setAccountReportCheckIn] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [displayRoleToggleAttendance, setDisplayRoleToggleAttendance] =
    useState(false);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleToggleAttendance = [Permission.ToggleSwitchAttendanceModeUpdate];
    const checkRoleToggleAttendance = CheckRole(
      roleToggleAttendance,
      listPermission
    );
    if (checkRoleToggleAttendance.includes(true)) {
      setDisplayRoleToggleAttendance(true);
    }
  }, [listPermission]);

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getListCourseAttendance(searchParam);
      const { data } = response.data;
      setAccountReportCheckIn(data.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      course_name: searchValue,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber, isRefresh]);

  const handleNotice = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
              {/* <span>{record?.diaChi}</span> */}
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    // {
    //   title: "Người tạo",
    //   dataIndex: "fullName",
    //   key: "fullName",
    //   sorter: {
    //     compare: (a, b) =>
    //       a.mooc_course_plan?.auth_user?.first_name.length -
    //       b.mooc_course_plan?.auth_user?.last_name.length,
    //   },
    //   width: 20,
    //   render: (_, data: any) => {
    //     return (
    //       <Flex align="center">
    //         <Avatar style={{ backgroundColor: "#f56a00" }}>
    //           {data?.mooc_course_plan?.auth_user?.first_name.charAt(0)}
    //         </Avatar>
    //         <Typography.Text className="pl-2">
    //           {data?.mooc_course_plan?.auth_user?.first_name +
    //             " " +
    //             data?.mooc_course_plan?.auth_user?.last_name}
    //         </Typography.Text>
    //       </Flex>
    //     );
    //   },
    // },
    {
      title: "Trạng thái",
      dataIndex: "trangThai",
      key: "trangThai",
      render: (_: any, record: any) => {
        const handleChange = (checked: boolean) => {
          record.trangThai = checked;
          handleChangeStatus(record?.id, record.trangThai);
        };

        return (
          <div>
            <Switch
              disabled={!displayRoleToggleAttendance}
              checked={record?.mooc_setting_attendance[0]?.is_active}
              onChange={handleChange}
            />
          </div>
        );
      },
      width: 10,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        const onLink = () => {
          setTabAttendance("1");
          navigate(
            `/management-study/${routesConfig.settingRemindCheckInDetail}/${location?.id}`
          );
        };
        return (
          <Space>
            {record?.mooc_setting_attendance[0]?.is_active && (
              <span className="action-table cursor-pointer" onClick={onLink}>
                <EyeOutlined />
              </span>
            )}
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList, displayRoleToggleAttendance, isRefresh]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        take: 10,
        skip: 0,
        course_name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      take: 10,
      skip: 0,
      course_name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const clearFilter = async (formInstance: FormInstance) => {
    setLoading(true);

    setSearchValue("");
    const searchParam = {
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      course_name: searchValue,
    };
    fetchData(searchParam);
    formInstance.resetFields();
  };

  const handleChangeStatus = async (
    id: number | string,
    is_active: boolean
  ) => {
    try {
      await updateStatusSettingAttendance(id, { is_active });
      handleNotice(
        !is_active ? "Đã tắt cài đặt điểm danh" : "Đã bật cài đặt điểm danh"
      );
      setIsRefresh(!isRefresh)
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Lỗi khi thay đổi trạng thái!",
      });
    }
  };

  const handleFinish = (values: any) => {
    setLoading(true);

    const searchParam = {
      ...values,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      course_name: searchValue,
    };
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      {contextHolder}
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsReportCheckIn}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>
        <TableData
          dataTable={accountReportCheckIn}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1200, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default SettingRemindCheckIn;
