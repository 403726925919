import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Layout,
  Row,
  Typography,
} from "antd";
import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  KeyboardEvent,
} from "react";
import PageHeader from "../../../../../components/page-header/PageHeader";
import {
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { FilterDateCoursePlan } from "../../../../../types/course-plan-approval";
import "./FilterApprovalPlan.scss";

type PageHeaderType = {
  title?: string | null;
  form: FormInstance;
  activeTabKey: string;
  clearFilter: () => void;
  searchValue: string;
  filterData: FilterDateCoursePlan;
  handleSubmitSearch: () => void;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  handleSearchEnter: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleOpenManyApprovalsSurvey: () => void;
  multipleApprove: () => void;
};
const FilterApprovalPlan: FunctionComponent<PageHeaderType> = ({
  title,
  form,
  activeTabKey,
  clearFilter,
  searchValue,
  filterData,
  setFilterData,
  handleSubmitSearch,
  handleChangeInput,
  handleOpenManyApprovalsSurvey,
  handleSearchEnter,
  multipleApprove,
}) => {
  const { RangePicker } = DatePicker;
  const [showFilter, setShowFilter] = useState(false);
  const openFilter = () => {
    setShowFilter(!showFilter);
  };
  return (
    <Layout className="page-header-group">
      <div
        className={`page-header-group--layout ${
          showFilter ? "open-filter" : ""
        }`}
      >
        <div className="table-header--wrapper">
          <div className="table-header--left">
            <PageHeader title={title} />
          </div>
          <div className="heading-extra">
            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
              <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 5 }}
                xs={{ span: 7 }}
              >
                <div className="heading-filter">
                  <Button className="filter-button" onClick={openFilter}>
                    <Typography.Text>Bộ lọc</Typography.Text>
                    <DownOutlined />
                  </Button>
                </div>
              </Col>
              <Col
                md={{ span: 10 }}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
                xs={{ span: 17 }}
              >
                <div className="heading-search">
                  <FormItemInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearchEnter}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch} />
                    }
                  />
                </div>
              </Col>
              <Col
                md={{ span: 9 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xs={{ span: 21 }}
              >
                <div className="heading-resigter heading-resigter-survey">
                  <Button
                    onClick={handleOpenManyApprovalsSurvey}
                    className="btn btn-primary"
                  >
                    <Typography.Text onClick={multipleApprove}>
                      Phê duyệt nhiều kế hoạch
                    </Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
          <Form form={form} name="filterForm">
            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} justify="end">
              <Col
                xl={{ span: 7 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="createDate" className="">
                  <>
                    {/* <Typography.Title level={5} className='label-range-picker'>Ngày gửi phê duyệt</Typography.Title> */}
                    <RangePicker
                      format="DD-MM-YYYY"
                      placeholder={["Gửi từ ngày", "đến ngày"]}
                      value={filterData.submissionDate}
                      onChange={(_value, dateString: any) => {
                        const convertedDates = dateString.map(
                          (date: String) => {
                            const [day, month, year] = date.split("-");
                            return `${year}-${month}-${day}`;
                          }
                        );
                        setFilterData(convertedDates, "submissionDate");
                      }}
                    />
                  </>
                </Form.Item>
              </Col>
              {activeTabKey === "1" ? null : (
                <Col
                  xl={{ span: 7 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item name="publicDate" className="">
                    <>
                      {/* <Typography.Title level={5} className='label-range-picker'>Ngày duyệt</Typography.Title> */}
                      <RangePicker
                        placeholder={["Duyệt từ ngày", "đến ngày"]}
                        format="DD-MM-YYYY"
                        value={filterData.approvalDate}
                        onChange={(_value, dateString: any) => {
                          const convertedDates = dateString.map(
                            (date: String) => {
                              const [day, month, year] = date.split("-");
                              return `${year}-${month}-${day}`;
                            }
                          );
                          setFilterData(convertedDates, "approvalDate");
                        }}
                      />
                    </>
                  </Form.Item>
                </Col>
              )}
              <Col
                xl={{ span: 6 }}
                md={{ span: 9 }}
                sm={{ span: 9 }}
                xs={{ span: 24 }}
              >
                <div className="group-btn--filter">
                  <Button
                    className="btn btn-primary filter-btn"
                    onClick={handleSubmitSearch}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    onClick={clearFilter}
                    className="btn btn-outlined clear-filter-btn"
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa tất cả</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default FilterApprovalPlan;
