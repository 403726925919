import dayjs, { Dayjs } from "dayjs";
import create from "zustand";
import { StateCreator } from "zustand";

export interface StaticReportState {
  staticReportType: string;
  searchQuery: string;
  filterValues: {
    industryGroupIds: string[];
    courseLevelIds: string[];
    courseIds: string[];
    timeUnit: string;
    timeType: string;
    dateFrom: Dayjs | string | null;
    dateTo: Dayjs | string | null;
    classId: number | null;
    searchKeywordModuleGroup: number | null;
    searchKeywordCourseStructureType: string;
    downloadModuleGroup: number | null;
    downloadCourseStructureType: string;
    viewModuleGroup: number | null;
    viewCourseStructureType: string;
    shareModuleGroup: number | null;
    shareCourseStructureType: string;
    moduleGroup: number | null;
    courseStructureType: string;
  };
  isLoading: boolean;
  isZipLoading: boolean;
  tab: string;
  handleExportAllImagesAsZip: (() => void) | null;
  setHandleExportAllImagesAsZip: (fn: () => void) => void;
  callExportAllImages: () => void;
  setFilterValues: (values: Partial<StaticReportState["filterValues"]>) => void;
  setStaticReportType: (type: string) => void;
  setIsLoading: (type: boolean) => void;
  setIsZipLoading: (loading: boolean) => void;
  setTab: (tab: string) => void;
}

export const staticReportSlice: StateCreator<StaticReportState> = (
  set,
  get
) => ({
  staticReportType: "chart",
  searchQuery: "",
  filterValues: {
    industryGroupIds: [],
    courseLevelIds: [],
    courseIds: [],
    timeUnit: "day",
    timeType: "day",
    dateFrom: dayjs().subtract(7, "day"),
    dateTo: dayjs(),
    classId: null,
    searchKeywordModuleGroup: null,
    searchKeywordCourseStructureType: "course",
    downloadModuleGroup: null,
    downloadCourseStructureType: "course",
    viewModuleGroup: null,
    viewCourseStructureType: "course",
    shareModuleGroup: null,
    shareCourseStructureType: "course",
    moduleGroup: 3,
    courseStructureType: "course",
  },
  isLoading: false,
  isZipLoading: false,
  tab: "1",
  handleExportAllImagesAsZip: null,

  setFilterValues: (values) => {
    set((state) => ({
      filterValues: {
        ...state.filterValues,
        ...values,
      },
    }));
  },

  setStaticReportType: (type: string) => {
    set({ staticReportType: type });
  },
  setIsLoading: (type: boolean) => {
    set({ isLoading: type });
  },
  setIsZipLoading: (loading: boolean) => {
    set({ isZipLoading: loading });
  },
  setTab: (tab: string) => {
    set({ tab: tab });
  },

  setHandleExportAllImagesAsZip: (fn: () => void) => {
    set({ handleExportAllImagesAsZip: fn });
  },

  callExportAllImages: async () => {
    const { handleExportAllImagesAsZip, setIsZipLoading } = get();

    if (handleExportAllImagesAsZip) {
      setIsZipLoading(true);
      await handleExportAllImagesAsZip();
      setIsZipLoading(false);
    } else {
      console.warn("handleExportAllImagesAsZip chưa được khởi tạo");
    }
  },
});
