import dayjs from "dayjs";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import {
  getCourseAssignTeacher,
  getCoursesByFormat,
  getMultimaterialsByType,
  getNumberSCORMxAPI,
  getReferenceSourceByType,
  getSequenceByAvailableStatus,
  getSequenceByDepartment,
  getTestByType,
  getUnitByCourse,
} from "../../../../../service/statistical-report";
import { staticReportStore } from "../../../../../stores/stores";
import {
  BarStackedChartProps,
  PieChart,
} from "../../../../../types/static-report";
import ResourcesChartTab from "../chart/resources-tab/ResourcesChartTab";
import ResourcesTableTab from "../table/report-list-tab/resource-table-tab/ResourceTableTab";

type Props = {
};

const ResourceTab = (props: Props) => {
  const { staticReportType, filterValues, tab } = staticReportStore();

  const [chartData, setChartData] = useState({
    coursesByFormatList: [] as PieChart[],
    sequenceByAvailableStatus: [] as PieChart[],
    sequenceByDepartment: [] as PieChart[],
    scormAndXAPI: [] as PieChart[],
    multiMaterialsByType: [] as PieChart[],
    testByType: [] as PieChart[],
    referenceSourceByType: [] as PieChart[],
    unitByCourse: [] as BarStackedChartProps[],
    courseAssignTeacher: [] as BarStackedChartProps[],
  });

  const [loading, setLoading] = useState({
    coursesByFormatList: false,
    sequenceByAvailableStatus: false,
    sequenceByDepartment: false,
    scormAndXAPI: false,
    multiMaterialsByType: false,
    testByType: false,
    referenceSourceByType: false,
    unitByCourse: false,
    courseAssignTeacher: false,
  });

  const fetchData = async () => {
    const payload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
    };

    try {
      const apiCalls = [
        {
          key: "coursesByFormatList",
          api: getCoursesByFormat,
          params: payload,
        },
        {
          key: "sequenceByAvailableStatus",
          api: getSequenceByAvailableStatus,
          params: payload,
        },
        {
          key: "sequenceByDepartment",
          api: getSequenceByDepartment,
          params: payload,
        },
        {
          key: "scormAndXAPI",
          api: getNumberSCORMxAPI,
          params: payload,
        },
        {
          key: "multiMaterialsByType",
          api: getMultimaterialsByType,
          params: payload,
        },
        {
          key: "testByType",
          api: getTestByType,
          params: payload,
        },
        {
          key: "referenceSourceByType",
          api: getReferenceSourceByType,
          params: payload,
        },
        {
          key: "unitByCourse",
          api: getUnitByCourse,
          params: payload,
        },
        {
          key: "courseAssignTeacher",
          api: getCourseAssignTeacher,
          params: {
            ...payload,
            timeUnit: filterValues.timeUnit,
          },
        },
      ];

      await Promise.allSettled(
        apiCalls.map(async ({ key, api, params }) => {
          setLoading((prev) => ({ ...prev, [key]: true }));
          try {
            const response = await api(params);
            setChartData((prevState) => ({
              ...prevState,
              [key]: response?.data?.data?.statistic || response?.data?.data,
            }));
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
          } finally {
            setLoading((prev) => ({ ...prev, [key]: false }));
          }
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const debouncedFetchData = useMemo(() => debounce(fetchData, 300), [filterValues]);

  useEffect(() => {
    if (tab === "1") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    return {
      coursesByFormatList: chartData.coursesByFormatList,
      sequenceByAvailableStatus: chartData.sequenceByAvailableStatus,
      sequenceByDepartment: chartData.sequenceByDepartment,
      scormAndXAPI: chartData.scormAndXAPI,
      multiMaterialsByType: chartData.multiMaterialsByType,
      testByType: chartData.testByType,
      referenceSourceByType: chartData.referenceSourceByType,
      unitByCourse: chartData.unitByCourse,
      courseAssignTeacher: chartData.courseAssignTeacher,
    };
  }, [chartData]);

  return (
    <div>
      {staticReportType === "chart" ? (
        <ResourcesChartTab
          loading={loading}
          {...memoizedChartData}
        />
      ) : (
        <ResourcesTableTab
          loading={loading}
          {...memoizedChartData}
        />
      )}
    </div>
  );
};

export default ResourceTab;
