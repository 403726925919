import {
  DownOutlined,
  FileWordOutlined,
  FundProjectionScreenOutlined,
  HolderOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  Card,
  Dropdown,
  Flex,
  Layout,
  MenuProps,
  Tree,
  TreeDataNode,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../config/routes";
import { getCourseAutoScore } from "../../service/auto-score";
import './automatic-mark.scss'
const imgPath = "/images/";

const { Title, Text } = Typography;
const { Content } = Layout;

const AutomaticMarkDetail = () => {
  const navigation = useNavigate();
  const [dataCourse, setDataCourse] = useState([]);
  const [title, setTitle] = useState("");

  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: <Link to={`/setting-high-level/${routesConfig.managementAutoScoring}`}>Quản lý chấm điểm tự động</Link>,
    },
    {
      title: title,
    },
  ];
  const CommonSession = (props: any) => {
    const { name, mooc_course_sequence } = props.value;
    const treeData: TreeDataNode[] = mooc_course_sequence?.map(
      (value: any) => ({
        title: value?.name,
        key: value?.id,
        icon: <FundProjectionScreenOutlined />,
        children:
          value?.mooc_course_unit
            ?.map((item: any) => {
              if (item?.mooc_course_block[0]?.module === "quiz") {
                const items: MenuProps["items"] = [
                  {
                    label: (
                      <Link
                        to={`/${routesConfig.autoMarkPermissionDetail}/${item?.mooc_course_block[0]?.id}`}
                      >
                        <Flex>
                          <Typography.Text className="pl-2">
                            Phân quyền
                          </Typography.Text>
                        </Flex>
                      </Link>
                    ),
                    key: "0",
                  },
                  {
                    label: (
                      <Link to={`/${routesConfig.autoMarkActionDetail}/${item?.mooc_course_block[0]?.id}`}>
                        <Flex>
                          <Typography.Text className="pl-2">
                            Chấm điểm tự động
                          </Typography.Text>
                        </Flex>
                      </Link>
                    ),
                    key: "1",
                  },
                  {
                    label: (
                      <Link
                        to={`/${routesConfig.managementListScore}/${item?.mooc_course_block[0]?.id}`}
                      >
                        <Flex>
                          <Typography.Text className="pl-2">
                            Quản lý bảng điểm
                          </Typography.Text>
                        </Flex>
                      </Link>
                    ),
                    key: "2",
                  },
                ];
                return {
                  title: (
                    <Flex
                      justify="space-between"
                      align="center"
                      wrap="wrap"
                      className="border-b pl-2 py-2"
                    >
                      <div className="py-2">
                        <Flex>
                          <Typography.Text className="pl-2">
                            {item?.name}
                          </Typography.Text>
                        </Flex>
                      </div>
                      <Dropdown
                        menu={{ items }}
                        trigger={["click"]}
                        placement="bottomRight"
                        className="action-table"
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <MoreOutlined />
                        </a>
                      </Dropdown>
                    </Flex>
                  ),
                  key: item?.mooc_course_block[0]?.id,
                  icon: <FileWordOutlined />,
                };
              }
            })
            .filter((item: any) => item) || [],
      })
    );
    return (
      <Card className="mt-4">
        <Flex className="border-b pb-2">
          <HolderOutlined style={{ fontSize: "40px", color: "#91D5FF" }} />
          <Title level={4} className="">
            {name}
          </Title>
        </Flex>
        <Tree
          showIcon
          className="tree-course"
          defaultExpandAll
          switcherIcon={<DownOutlined />}
          treeData={treeData}
        />
      </Card>
    );
  };

  const fetchDataReport = async () => {
    try {
      const response = await getCourseAutoScore(params?.id as string);
      const { data } = response.data;
      setDataCourse(data?.mooc_course_section || []);
      setTitle(data?.name)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataReport();
  }, []);

  return (
    <Content>
      <PageHeaderCommon
        title={title}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() =>
          navigation(`/setting-high-level/${routesConfig.managementAutoScoring}`)
        }
      />
      <div className="w-full lg:w-2/3 xl:w-1/2 m-auto my-8">
        {contextHolder}
        <Card className="mt-4">
          <Flex justify="space-between">
            <div>
              <Title level={3} className="!leading-3">
                Cấu trúc của khoá học
              </Title>
              <Text className="text-[#00000073]">
                Chi tiết học liệu đánh giá theo cấu trúc
              </Text>
            </div>
          </Flex>
        </Card>
        {dataCourse?.map((courseData) => (
          <CommonSession value={courseData} />
        ))}
      </div>
    </Content>
  );
};

export default AutomaticMarkDetail;
