import {
  Card,
  Flex,
  Form,
  Layout,
  Select,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import TableData from "../../../../../components/table-data/TableData";
import { routesConfig } from "../../../../../config/routes";
import {
  getSettingAttendanceReportByDocumentID,
  getSettingAttendanceReportByID,
} from "../../../../../service/setting-attendance";
import { DataType } from "../../../../../types/listUsers";
import { formatDate } from "../../../../../utils/converDate";
import { useStudyManagementStore } from "../../../../../stores/stores";
import moment from "moment";

type TableReportCheckInDetailProps = {
  title?: string;
  idDocument?: string;
  setReport: any;
  paramStatus: any;
  setParamStatus: any;
  setIdCourse: any;
};
interface SearchType {
  search: string;
  course_document_id?: number | string;
  skip: number;
  take: number;
}

const TableReportCheckInDetail = ({
  title,
  idDocument,
  setReport,
  paramStatus,
  setParamStatus,
  setIdCourse,
}: TableReportCheckInDetailProps) => {
  const location = useLocation();

  const [accountReportCheckInDetail, setAccountReportCheckInDetail] = useState<
    DataType[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [headValue, setHeadValue] = useState<any>([]);
  const { setTitleAttendance } = useStudyManagementStore((state) => state);
  // const [paramStatus, setParamStatus] = useState<any>([]);

  const pathName = location.pathname;

  const fetchData = async (idDocument: any) => {
    try {
      const response = await getSettingAttendanceReportByDocumentID(idDocument);
      const { data } = response.data;
      setAccountReportCheckInDetail(data?.mooc_attendance || []);
      setTotalPage(data?.total || 0);

      setParamStatus(
        data?.mooc_attendance?.map((item: any) => ({
          id: item?.id,
          result: item?.result,
        }))
      );
      setLoading(false);
      setReport({
        title: data?.name || "",
        idReport: data?.id,
      });
      setIdCourse(data?.mooc_course.id);
      setTitleAttendance(data?.mooc_course.name);
      console.log(data);

      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.mooc_course?.name,
        },
        {
          label: "Chương",
          value:
            data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
              ?.mooc_course_section?.name || "",
        },
        {
          label: "Bài giảng",
          value:
            data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
              ?.name || "",
        },
        {
          label: "Học liệu",
          value: data?.mooc_course_block?.mooc_course_unit?.name || "",
        },
        {
          label: "Ngày học",
          value: data?.mooc_course_unit?.published_date
            ? formatDate(data?.mooc_course_unit?.published_date)
            : "Không có ngày học",
        },
        {
          label: "Thời gian học",
          value: data?.mooc_course_block?.mooc_course_unit?.schedule_start_date
            ? formatDate(
                data?.mooc_course_block?.mooc_course_unit?.schedule_start_date
              )
            : "Không có thời gian học",
        },
        {
          label: "Sĩ số",
          value: `${data?.attendance}/${data?.total}`,
        },
        {
          label: "Tính chất điểm danh",
          value: `${
            data?.mooc_course?.mooc_setting_attendance[0]?.lesson
              ? "Tiết học"
              : ""
          }${
            data?.mooc_course?.mooc_setting_attendance[0]?.discussion
              ? ", Thảo luận"
              : ""
          }${
            data?.mooc_course?.mooc_setting_attendance[0]?.seminar
              ? ", Khảo sát"
              : ""
          }`,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(idDocument);
  }, [pageSize, pageNumber]);

  const handleChangeStatus = (value: any, id: number | string) => {
    setParamStatus((prevData: any[]) =>
      prevData.map((item: { id: string | number; result: any }) =>
        item.id === id ? { ...item, result: value } : item
      )
    );
  };

  const dataColumnsReportCheckInDetail: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {
              record?.auth_user_mooc_attendance_student_idToauth_user
                ?.auth_userprofile.name
            }
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.auth_userprofile?.date_of_birth &&
              moment(
                record?.auth_user_mooc_attendance_student_idToauth_user
                  ?.auth_userprofile?.date_of_birth
              ).format("DD/MM/yyyy")}
          </Typography.Text>
        );
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {
              record?.auth_user_mooc_attendance_student_idToauth_user
                ?.auth_userprofile.phone_number
            }
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user?.email}
          </Typography.Text>
        );
      },
    },
    {
      title: "Điểm danh",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            <div
              className={`${
                record?.result ? "table-checkin-1" : "table-checkin-2"
              }`}
            >
              {record?.result ? "Có mặt" : "Vắng mặt"}
            </div>
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckInDetail.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckInDetail.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="px-6 pt-6 w-full">
          <Card>
            <Flex className="w-full 2xl:justify-between" wrap="wrap">
              {headValue?.map((dataReport: any, index: number) => (
                <div
                  className={`${
                    index + 1 === headValue.length ? "" : "sm:border-r"
                  } px-4 w-full sm:w-1/2 md:w-1/4 mb-2 xl:mb-0 xl:w-fit`}
                  key={index}
                >
                  <p className="w-full">{dataReport.label}</p>
                  <p className="font-medium text-lg">{dataReport?.value}</p>
                </div>
              ))}
            </Flex>
          </Card>
        </div>
        <TableData
          dataTable={accountReportCheckInDetail}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableReportCheckInDetail;
