import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSystemSuggestCourseAll: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/recommendation/get-all`, dataReq);
};

export const getAllTagSystemSuggestCourse: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.post(`/recommendation-tag/get-all-tag`);
};

export const getAllStudentFavorite: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/student-favorite/get-all`, dataReq);
};

export const getListTagFavorite: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/student-favorite/search-student-same-favorite`, dataReq);
};

export const getStudentFavoriteById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/student-favorite/get/${id}`);
};

export const getSystemSuggestCourseById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/recommendation/get/${id}`);
};

export const updateSystemSuggestCourse: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/recommendation/update-by-course/${id}`, dataReq);
};
