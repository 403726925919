import React, { useEffect, useState } from 'react'
import { Flex, Modal, Progress, message } from 'antd';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import './detail.scss'
import { TypeCourseDescriptionDocument } from '../../../../types/course-plan-managment/mooc-course-construction-plan';
import axios from 'axios';

interface ModalProgressBarProps {
  isOpen?: boolean;
  setIsOpen?: any;
  isOpenModalFather?: any;
  setIsOpenModalFather?: any;
  refFilePdf?: React.RefObject<HTMLInputElement>;
  link?: any;
  refConstructPlanPdf?: React.RefObject<HTMLInputElement>;
  typeDownload?: number[];
  setExpandAll?: any;
  expandAll?: boolean;
  dataDescriptionDocument?: TypeCourseDescriptionDocument[];
}
const ModalProgressBar = (props: ModalProgressBarProps) => {
  const {
    isOpen,
    setIsOpen,
    isOpenModalFather,
    setIsOpenModalFather,
    refFilePdf,
    link,
    refConstructPlanPdf,
    typeDownload,
    dataDescriptionDocument
  } = props

  // console.log({ link })
  const [percent1, setPercent1] = useState(0);
  const [percent2, setPercent2] = useState(0);
  const [percent3, setPercent3] = useState(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  // console.log({ link })
  useEffect(() => {
    const interval = 50;
    const increment = 2;
    const intervalId = setInterval(() => {
      if (percent1 < 100) {
        setIsDisabled(true)
        setPercent1(percent1 + increment);
      } else if (percent2 < 100) {
        setIsDisabled(true)
        setPercent2(percent2 + increment);
      } else if (percent3 < 100) {
        setIsDisabled(true)
        setPercent3(percent3 + increment);
      } else {
        setIsDisabled(false)
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [percent1, percent2, percent3]);


  const handleCancel = () => { setIsOpen(false) }

  const handleOk = async () => {

    let input: any
    let detailInforDiv: any
    let courseConstructDiv: any
    const folderName = "Documents";
    const zip = new JSZip();
    const folder = zip.folder(folderName);
    if (!link) {
      setIsLoading(true)
      console.log('khong co link')
      if (typeDownload?.includes(0) && !typeDownload?.includes(1) && !typeDownload?.includes(2)) {
        input = refFilePdf?.current
      } else if (!typeDownload?.includes(0) && typeDownload?.includes(1) && !typeDownload?.includes(2)) {
        input = refConstructPlanPdf?.current
      } else if (!typeDownload?.includes(0) && !typeDownload?.includes(1) && typeDownload?.includes(2)) {
        detailInforDiv = refFilePdf?.current
        courseConstructDiv = refConstructPlanPdf?.current
      }
      else if (typeDownload?.includes(0) && !typeDownload?.includes(1) && typeDownload?.includes(2)) {
        detailInforDiv = refFilePdf?.current
        courseConstructDiv = refConstructPlanPdf?.current
      }
      else if (!typeDownload?.includes(0) && typeDownload?.includes(1) && typeDownload?.includes(2)) {
        detailInforDiv = refFilePdf?.current
        courseConstructDiv = refConstructPlanPdf?.current
      }
      else if (typeDownload?.includes(0) && typeDownload?.includes(1) && !typeDownload?.includes(2)) {
        detailInforDiv = refFilePdf?.current
        courseConstructDiv = refConstructPlanPdf?.current
      }
      else if (typeDownload?.includes(0) && typeDownload?.includes(1) && typeDownload?.includes(2)) {
        detailInforDiv = refFilePdf?.current
        courseConstructDiv = refConstructPlanPdf?.current
      }




      try {
        const processDiv = async (div: any, fileName: any) => {
          // console.log({ div })
          await new Promise(resolve => setTimeout(resolve, 500));

          const canvas = await html2canvas(div, { logging: true, useCORS: true, removeContainer: true, scale: 10 });
          const imageData = canvas.toDataURL("image/jpeg");

          const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4'
          });


          // const width = 200;
          // const height = canvas.height * width / canvas.width;
          const a4Width = 210;
          const a4Height = 297;
          const imgWidth = a4Width - 4;
          const imgHeight = (canvas.height * imgWidth / canvas.width) - 6.5;


          // pdf.addImage(imageData, "JPEG", 0, 0, width, height);
          let heightLeft = imgHeight;
          let position = 0;
          const bottomPadding = 10;
          pdf.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= (a4Height - bottomPadding);

          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= (a4Height - bottomPadding);
          }
          if (folder) {
            folder.file(fileName, pdf.output('blob'));
          }
        };
        if (input) {
          await Promise.all([
            processDiv(input, 'file.pdf'),
          ]);
        } else {
          await Promise.all([
            processDiv(detailInforDiv, 'chi-tiet-cau-truc.pdf'),
            processDiv(courseConstructDiv, 'cau-truc-khoa-hoc.pdf')
          ]);
        }

        if (typeDownload?.includes(2)) {
          if (dataDescriptionDocument && dataDescriptionDocument.length > 0) {
            for (const document of dataDescriptionDocument) {
              if (document.filePath && document.filePath !== "") {
                try {
                  const response = await axios.get(document.filePath, {
                    responseType: "arraybuffer",
                  });
                  zip.file(`${folderName}/${document.fileName}`, response.data);
                } catch (error) {
                  console.error(error);
                }
              } else {
                console.log('khong co file');
              }
            }
          }
        }
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(zipBlob);
        setIsLoading(false)
        link.download = 'ThongTinDangKyKeHoach.zip';
        link.click();

        message.success("Tải xuống kế hoạch khoá học thành công");
        setIsOpen(false);
        setIsOpenModalFather(false);


      } catch (error) {
        console.error('loi', error);
        message.error("Tải xuống kế hoạch khoá học thất bại");

      }
    } else {
      console.log('co link')
      console.log('cho nay can tim')

      link.download = 'ThongTinDangKyKeHoach.zip';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsOpen(false);
      setIsOpenModalFather(false);
    }
  };

  return (
    <Modal open={isOpen}
      title="Tải xuống đăng ký Kế hoạch 01"
      width={600}
      confirmLoading={isLoading}
      centered={true}
      onOk={handleOk}
      okText='Tải xuống'
      onCancel={handleCancel}
      cancelText="Huỷ"
      okButtonProps={{ disabled: isDisabled }}
    >
      <Flex gap="small" wrap="wrap">
        <div className='container'>
          <div className='item item-progress item-progress-border-1'>
            <Progress
              style={{ marginTop: 15, marginRight: 14 }}
              size={36}
              type="circle"
              percent={percent1}
            />
            <div className='progress-content'>
              <span className="item-title-progress">Chuẩn bị </span>
              <p className='item-title-progress-content'>{` (Chuẩn bị để bắt đầu kết xuất)`}</p>
            </div>
          </div>
          <div className='item item-progress item-progress-border-2'>
            <Progress
              style={{ marginTop: 15, marginRight: 14 }}
              size={36}
              type="circle"
              percent={percent2}
            />
            <div className='progress-content'>
              <span className="item-title-progress">Xuất dữ liệu</span>
              <p className='item-title-progress-content'>{`   (Tạo cái tệp dữ liệu xuất)`}</p>
            </div>
          </div>
          <div className='item item-progress'>
            <Progress
              style={{ marginTop: 15, marginRight: 14 }}
              size={36}
              type="circle"
              percent={percent3} />
            <div className='progress-content'>
              <span className="item-title-progress">Nén tệp </span>
              <p className='item-title-progress-content'>{` (Nén dữ liệu đã được xuất)`}</p>

            </div>
          </div>

        </div>



      </Flex>
    </Modal>

  )
}

export default ModalProgressBar