import { MenuFoldOutlined, SortAscendingOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Input, Radio, Row, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  useAddLesson,
  useAutomaticScoreStore,
} from "../../../../../stores/stores";
import "./index.scss";

import { SearchProps } from "antd/es/input";
import { useEffect, useRef, useState } from "react";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import ResultExam from "./ResultExam";
import { getListCourseAttendancePage } from "../../../../../service/setting-attendance";
import {
  getListAnswerByStudent,
  getListStudentExam,
} from "../../../../../service/auto-score";

const { Search } = Input;

interface IProps {
  idReport: number;
}
const ExamStudentContent = ({ idReport }: IProps) => {
  const navigate = useNavigate();
  const ulRef = useRef(null);

  const { flagCourseEditing, toggleFlagCourseEditing } = useAddLesson(
    (state) => state
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [dataListStudent, setDataListStudent] = useState<any>([]);

  const [dataExam, setDataExam] = useState<any>([]);
  const [dataStaticExam, setDataStaticExam] = useState({
    correct: 0,
    point: 0,
    total: 0,
    questionNumber: 0,
  });
  const [infoExam, setInfoExam] = useState({
    title: "",
    code: "",
  });

  const [idStudent, setIdStudent] = useState(NaN);
  const { setScoreQuestion, scoreQuestion } = useAutomaticScoreStore(
    (state) => state
  );
  const [pageSize, setPageSize] = useState<number>(20);
  const [isStopScroll, setIsStopScroll] = useState(false);
  const [sort, setSort] = useState<string>("desc");

  const handleScroll = () => {
    const ulElement: any = ulRef.current;
    if (
      ulElement?.scrollTop + ulElement?.clientHeight ===
        ulElement?.scrollHeight &&
      !isStopScroll
    ) {
      setPageSize((prevItems) => prevItems + 10);
    }
  };

  // Gắn sự kiện scroll vào window
  useEffect(() => {
    const ulElement: any = ulRef.current;
    ulElement.addEventListener("scroll", handleScroll);
    return () => ulElement.removeEventListener("scroll", handleScroll);
  }, [pageSize]);

  const getListDataFilter = async (idReport: number) => {
    const param = {
      search: searchValue,
      skip: 0,
      take: pageSize,
      sort: sort,
    };
    if (idReport) {
      try {
        const res = await getListStudentExam(param, idReport);
        const { data } = res.data;
        setInfoExam({
          code: data?.code,
          title: data?.name
        })
        setDataListStudent(data?.mooc_score);
        if (!idStudent) {
          setIdStudent(data?.mooc_score[0]?.id);
        }
        if (pageSize >= data?.mooc_score?.length) {
          setIsStopScroll(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getListDataExamByStudent = async (idStudent: number) => {
    if (idStudent) {
      try {
        const res = await getListAnswerByStudent(`${idStudent}`);
        const { data } = res.data;
        setDataStaticExam({
          correct: data[0]?.correct,
          point: data[0]?.point,
          total: data[0]?.total,
          questionNumber: data[0]?.mooc_score_detail?.length,
        });
        setDataExam(data[0]?.mooc_score_detail);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getListDataFilter(idReport);
    getListDataExamByStudent(idStudent);
  }, [pageSize, searchValue, sort, idReport, idStudent]);

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  const handleViewExam = (listExam: any) => {
    setIdStudent(listExam?.id);
  };
  const handleSort = () => {
    if (sort === "desc") {
      setSort("asc");
    } else {
      setSort("desc");
    }
  };

  const CommonListExam = ({ listQuestion, index }: any) => {
    let valueDefault = 0;
    let checkAnswer = listQuestion?.mooc_course_quiz_session?.answer?.answer[0];

    listQuestion?.mooc_course_block_quiz?.answers?.map(
      (items: any, indexItem: number) => {
        valueDefault = items?.uuid === checkAnswer ? indexItem : 0;
      }
    );
    return (
      <div key={index} className="mb-3">
        <Typography.Title level={5} className="bg-[#F9FAFB] p-3 rounded-lg">
          {listQuestion?.mooc_course_block_quiz?.question}
        </Typography.Title>
        <Radio.Group value={valueDefault} className="py-2">
          <Space direction="vertical" className="flex flex-wrap">
            {listQuestion?.mooc_course_block_quiz?.answers?.map(
              (listAnswer: any, index: number) => (
                <Radio
                  className={`w-full ${listQuestion?.is_true ? "" : "answer-false"}`}
                  key={index}
                  value={listAnswer?.uuid === checkAnswer && index}
                >
                  {listAnswer.content}
                </Radio>
              )
            )}
          </Space>
        </Radio.Group>
        <Typography.Title level={5} className="bg-[#DCFAE6] p-3 rounded-lg">
          Điểm: {listQuestion?.point}
        </Typography.Title>
      </div>
    );
  };
  return (
    <div className="exam-student">
      <Row className="flex" style={{ height: "100%" }}>
        <div
          className={`tabs-heading exam-student-editing ${
            flagCourseEditing ? "" : "tab-closed w-50px"
          }`}
        >
          <div className="border-b flex">
            <div
              className="setting-question-course__header__title justify-center"
              style={{ visibility: flagCourseEditing ? "visible" : "hidden" }}
            >
              {infoExam?.title}
            </div>
            <MenuFoldOutlined
              className="exam-student-editing-move-btn"
              rotate={flagCourseEditing ? 0 : 180}
              onClick={toggleFlagCourseEditing}
            />
          </div>
          <div
            className={`border-b p-4 text-center ${
              flagCourseEditing ? "visible" : "invisible"
            }`}
          >
            <Typography.Text>Mã bảng điểm: {infoExam?.code}</Typography.Text>
          </div>
          <div
            className={`p-4 text-center ${
              flagCourseEditing ? "visible" : "invisible"
            }`}
          >
            <Typography.Text>Danh sách sinh viên</Typography.Text>
            <Flex className="pt-3">
              <Search
                placeholder="Tìm tên sinh viên"
                onSearch={onSearch}
                value={searchText}
                onChange={handleChangeSearch}
              />
              <Button className="ml-2" onClick={handleSort}>
                <SortAscendingOutlined />
              </Button>
            </Flex>
          </div>
          <div
            id="scrollList"
            ref={ulRef}
            className={`${
              flagCourseEditing ? "visible" : "invisible"
            }  overflow-auto max-h-[615px]`}
          >
            {dataListStudent?.map((listStudent: any, index: number) => (
              <Flex
                key={index}
                className={`hover:bg-[#E6F7FF] p-4 ${
                  listStudent?.id === idStudent
                    ? "bg-[#E6F7FF] border-r-4 border-r-[#1890FF]"
                    : ""
                }`}
                onClick={() => handleViewExam(listStudent)}
              >
                <Typography.Text className="pr-3 min-w-[45px]">
                  {Number(index + 1)}
                </Typography.Text>
                <Typography.Text>
                  {listStudent?.auth_user?.auth_userprofile?.name}
                </Typography.Text>
              </Flex>
            ))}
          </div>
        </div>
        <CustomCard className="card-info--wrapper flex-1">
          <div className="list-wrapper">
            <Card>
              <div className="border-b p-4">
                <Typography.Title level={4} className="!mb-0">
                  Bài làm của sinh viên
                </Typography.Title>
                <Typography.Text>
                  Chi tiết câu hỏi và đáp án trả lời của sinh viên
                </Typography.Text>
              </div>
              <div className="p-4">
                {dataExam.map((listQuestion: any, index: number) => (
                  <CommonListExam listQuestion={listQuestion} index={index} />
                ))}
              </div>
            </Card>
          </div>
        </CustomCard>
        <ResultExam dataExam={dataExam} setDataExam={setDataExam} dataStaticExam={dataStaticExam} />
      </Row>
    </div>
  );
};

export default ExamStudentContent;
