import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HeartOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SettingOutlined,
  StopOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateV2 } from "../../../utils/converDate";
import { routesConfig } from "../../../config/routes";


import FilterHistoryManagementData from "../../../components/management-data/history-management-data/filter-table/FilterTable";
import FormFilter from "../../../components/management-data/history-management-data/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import PageHeader from "../../../components/page-header/PageHeader";
import { deleteHistoryManagement, getHistoryManagementData } from "../../../service/management-data";
import { optionAccuracyData, optionShareData, optionSyncData, typeOptionManagementData } from "../../../constants/management-data";
import HistoryManagementDataModal from "../../../components/modal/management-data/history-management-data";
import CommonModal from "../../../components/modal/common";
import DownloadHistoryManagementDataModal from "../../../components/modal/management-data/download-doc-management-data";

type Props = {
  title?: string;
};

const HistoryManagementPage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDownloadDoc, setIsOpenedDownloadDoc] = useState<boolean>(false);
  const [listAdminInterface, setListAdminInterface] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [modeSave, setModeSave] = useState<string>("");

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const handleOpenCreate = () => {
    setIsOpened(true);
    setDataUpdate({})
  };

  const optionsContentForum: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa lịch sử",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Cập nhật thông tin",
      icon: <RedoOutlined />,
    },
    {
      key: "3",
      label: "Kết xuất",
      icon: <DownloadOutlined />,
    },
    {
      key: "4",
      label: "Xóa",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      connectedDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      connectedDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      status: filterData.status ?? null,
      isLearningResult: tab === "1" ? true : false,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
    };

    await getHistoryManagementData(data)
      .then((res) => {
        const { data } = res.data
        setListAdminInterface(data.histories)
        setTotalRecords(data.total);
        setCountAwaitingReview(data?.allLearning)
        setCountReviewed(data?.allStudy)

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, lastPreview]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p>Không thành công</p>
        break;
      case 1:
        return <p>Thành công</p>
        break;
      default:
        break;
    }
  }

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Hệ thống",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Loại kết nối",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        const data = typeOptionManagementData.filter((item: any) => item.id === record?.type)
        return <div>{data?.[0]?.name}</div>;
      },
    },
    {
      title: "Thông tin",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        let data: any = []
        if (record.type === 1) {
          data = optionSyncData.filter((item: any) => item.id === record?.typeInformation)
        } else if (record.type === 2) {
          data = optionShareData.filter((item: any) => item.id === record?.typeInformation)
        } else {
          data = optionAccuracyData.filter((item: any) => item.id === record?.typeInformation)
        }
        return <div>{data?.[0]?.name}</div>;
      },
    },
    {
      title: "Ngày kết nối",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.connectedDate ? formatDateV2(record?.connectedDate) : null}</>
        );
      },
    },
    {
      title: "Kết quả",
      dataIndex: "status",
      key: "status",
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="flex items-center gap-2">
            <div className="table-status">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    record.status === 0
                      ? "#ffae49"
                      : record.status === 1
                        ? "#24b524" : "white"
                }}
              ></div></div>
            {renderStatus(record?.status)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpened(true)
                  setDataUpdate(record)
                  setMode("view")
                  setModeSave('')
                }
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsContentForum,
                  onClick: ({ key }) => {
                    if (key === '1') {
                      setModeSave('1')
                      setIsOpened(true)
                      setDataUpdate(record)
                    }

                    if (key === '2') {
                      setIsOpened(true)
                      setDataUpdate(record)
                      setMode("edit")
                      setModeSave('')
                    }

                    if (key === '3') {
                      setIsOpenedDownloadDoc(true)
                      setDataUpdate(record)
                    }

                    if (key === "4") {
                      setIsOpenedConfirm(true)
                      setDeleteId(record?.id)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space >
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Hồ sơ học tập
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Kết quả học tập
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const deleteHistory = async () => {
    await deleteHistoryManagement(deleteId).then((res) => {
      if (res.status === 200) {
        message.success("Xoá lịch sử kết nối, chia sẻ dữ liệu thành công")
        setIsOpenedConfirm(false)
        getListData()
      }
    })
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-evaluation">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData()
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterHistoryManagementData
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
      </Form.Provider>

      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá giao diện quản trị'}
        desc={`Chủ đề đã xoá sẽ không thể khôi phục và giao diện sẽ chuyển về mặc đinh. Bạn có chắc muốn xóa giao diện quản trị?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deleteHistory}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      />

      <HistoryManagementDataModal open={isOpened} dataUpdate={dataUpdate} mode={mode} onCancel={() => setIsOpened(false)} getListData={getListData} modeSave={modeSave} />
      <DownloadHistoryManagementDataModal open={isOpenedDownloadDoc} dataUpdate={dataUpdate} onCancel={() => setIsOpenedDownloadDoc(false)} getListData={getListData} />
    </Layout>
  );
};

export default HistoryManagementPage;
