import React from 'react'
import { Badge, Button, Form, message, Modal, Typography } from "antd";
import CustomCard from '../../../custom-card/CustomCard';
import { Appraisers, BrowsingResuls, DetailCourseData, PlanInfo } from '../../../../types/assign-course-construction';

import "./index.scss"
import { formatDateToIsoString, formatOriginTime } from '../../../../utils/converDate';
import { isEmpty } from 'lodash';

type Props = {
  planInfo: PlanInfo
  detailData: any
}

const BrowsingResults = (props: Props) => {
  const { planInfo, detailData } = props
  const [form] = Form.useForm()
  let infoData = {}
  if (isEmpty(detailData?.approvedTime)) {
    infoData = {
      approvedSlideTime: isEmpty(detailData?.approvedSlideTime) ? "" : formatOriginTime(detailData?.approvedSlideTime),
      approvedSlide: detailData?.approvedSlide,
      slideDescription: detailData?.slideDescription,
    }
  } else {
    infoData = {
      approvedSlideTime: isEmpty(detailData?.approvedSlideTime) ? "" : formatOriginTime(detailData?.approvedSlideTime),
      approvedSlide: detailData?.approvedSlide,
      slideDescription: detailData?.slideDescription,
      approvedTime: isEmpty(detailData?.approvedTime) ? "" : formatOriginTime(detailData?.approvedTime),
      approvedContentUsername: detailData?.approvedContentUsername,
      contentDescription: detailData?.contentDescription
    }
  }

  const convertObjectData = Object.entries(infoData);

  const renderSwitch = (value: string) => {
    switch (value) {
      case 'approvedSlideTime':
        return <div>Ngày duyệt trang trình bày</div>;
      case 'approvedSlide':
        return <div>Người duyệt trang</div>;
      case 'slideDescription':
        return <div>Nội dung duyệt trang</div>;
      case 'approvedTime':
        return <div>Ngày duyệt nội dung</div>;
      case 'approvedContentUsername':
        return <div>Người duyệt nội dung</div>;
      case 'contentDescription':
        return <div>Duyệt nội dung</div>;
      default:
        return '';
    }
  }

  const listInfo = convertObjectData.map((item: any, index) => {
    let name = ''
    if (item[0] === 'slideDescription') {
      name = 'approveSLideStatus'
    }
    if (item[0] === 'contentDescription') {
      name = 'approveContentStatus'
    }
    return (
      <div className="list-item" key={index}>
        <Typography.Title level={5} className="list-item--title">
          {renderSwitch(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {['approvedSlide', 'approvedContentUsername'].includes(item[0]) ?
              item[1] :
              (['slideDescription', 'contentDescription'].includes(item[0]) ? <div contentEditable='true'
                dangerouslySetInnerHTML={{ __html: Number(detailData?.[name]) === 1 ? `Duyệt - <${item[1]}>` : (Number(detailData?.[name]) === 2 ? `Không duyệt - <${item[1]}>` : '') }}></div>
                : item[1])}
          </Typography.Text>
        </div>
      </div>
    )
  })

  const infoDataAppraise: any = {
    councilApprisedUsernames: detailData?.councilApprisedUsernames,
    councilName: detailData?.councilApprisedUsernames,
    councilApprisedTime: isEmpty(detailData?.councilApprisedTime) ? "" : formatOriginTime(detailData?.councilApprisedTime),
    councilDescription: detailData?.councilDescription,
  }

  const convertObjectDataAppraise = Object.entries(infoDataAppraise);

  const renderSwitchAppraise = (value: string) => {
    switch (value) {
      case 'councilApprisedUsernames':
        return <div>Người thẩm định</div>;
      case 'councilName':
        return <div>Hội đồng thẩm định</div>;
      case 'councilApprisedTime':
        return <div>Ngày thẩm định</div>;
      case 'councilDescription':
        return <div>Nội dung thẩm định</div>;
      default:
        return '';
    }
  }

  const listAppraised = convertObjectDataAppraise.map((item: any, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitchAppraise(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        <Typography.Text className="content">
          {['councilApprisedUsernames', 'councilName'].includes(item[0]) ?
            item[1] :
            (['councilDescription'].includes(item[0]) ?
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: Number(detailData?.councilApprisedStatus) === 1 ? `Duyệt - <${item[1]}>` : (Number(detailData?.councilApprisedStatus) === 2 ? `Không duyệt - <${item[1]}>` : '') }}></div>
              : item[1])}
        </Typography.Text>
      </div>
    </div>
  ))

  const infoDataApproved: any = {
    proApprisedUsernames: detailData?.proApprisedUsernames,
    proName: detailData?.senderCouncilUsername,
    proApprisedTime: isEmpty(detailData?.councilApprisedTime) ? "" : formatOriginTime(detailData?.councilApprisedTime),
    proDescription: detailData?.councilDescription,
  }

  const convertObjectDataApproved = Object.entries(infoDataApproved);

  const renderSwitchApproved = (value: string) => {
    switch (value) {
      case 'proApprisedUsernames':
        return <div>Người phê duyệt</div>;
      case 'proName':
        return <div>Tổ chuyên môn</div>;
      case 'proApprisedTime':
        return <div>Ngày phê duyệt</div>;
      case 'proDescription':
        return <div>Nội dung phê duyệt</div>;
      default:
        return '';
    }
  }

  const listApproved = convertObjectDataApproved.map((item: any, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitchApproved(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        <Typography.Text className="content">
          {['proApprisedUsernames', 'proName'].includes(item[0]) ?
            item[1] :
            (['proDescription'].includes(item[0]) ?
              <div contentEditable='true' dangerouslySetInnerHTML={{ __html: Number(detailData?.councilApprisedStatus) === 1 ? `Duyệt - <${item[1]}>` : (Number(detailData?.councilApprisedStatus) === 2 ? `Không duyệt - <${item[1]}>` : '') }}></div>
              : item[1])}
        </Typography.Text>
      </div>
    </div>
  ))

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
      }}>
        <CustomCard title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              Kết quả duyệt
            </Typography.Title>
            <Typography.Text className="sub-title">
              Chi tiết kết quả duyệt của kế hoạch
            </Typography.Text>
          </div>
        } className="card-info--wrapper">
          <div className="list-wrapper">
            {listInfo}
          </div>
        </CustomCard>
        {detailData?.councilApprisedStatus ?
          <CustomCard title={
            <div className="card-heading--info">
              <Typography.Title level={5}>
                Kết quả thẩm định
              </Typography.Title>
              <Typography.Text className="sub-title">
                Chi tiết kết quả thẩm định của kế hoạch
              </Typography.Text>
            </div>
          } className="card-info--wrapper mt-2">
            <div className="list-wrapper">
              {listAppraised}
            </div>
          </CustomCard> : null}

        {
          !isEmpty(detailData?.proApprisedTime) ?
            <CustomCard title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Kết quả phê duyệt
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Chi tiết kết quả phê duyệt của kế hoạch
                </Typography.Text>
              </div>
            } className="card-info--wrapper mt-2">
              <div className="list-wrapper">
                {listApproved}
              </div>
            </CustomCard> : null
        }
      </Form.Provider>
    </>
  )
}

export default BrowsingResults


