import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Tabs, TabsProps, message } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { routesConfig } from "../../../config/routes";
import { useLocation, useParams } from "react-router-dom";
import IsLoading from "../mooc-course-construction-plan/detail-course-plan/component/is-loading/IsLoading";
import { useAppStore } from "../../../stores/stores";
import DetailInfoApprovalCourse from "./components/detail-infor/DetailInfor";
import { getMoocApprovedCourseById } from "../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import { DetailContent } from "./components/detail-content/DetailContent";
import { log } from "util";
import CourseStructureApprovalCourseRegister from "./components/course-structure/CourseStructure";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../types/course-plan-managment/mooc-course-construction-plan";
import {
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById
} from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import PreviewCourseStructure from "./components/preview-course-structure/PreviewCourseStructure";
import PreviewDescriptionDocument from "./components/preview-description-document/PreviewDescriptionDocument";
import DescriptionDocument from "./components/description-document/DescriptionDocument";
import DetailConfirm from "./components/detail-confirm/DetailConfirm";
import ApprovalCourseRegister from "./components/approval-course-register/ApprovalCourseRegister";
import { title } from "process";
const { Content } = Layout;
const imgPath = "/images/";

const MoocCourseApprovalDetail: FunctionComponent = () => {
  const { id } = useParams();
  const params = useParams();
  const location = useLocation();
  const { state } = location;
  const mode = state && state.mode ? state.mode : null;

  const [detailData, setDetailData] = useState<any>({}); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustry, setLtsIndustry] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustryGroup, setLtsIndustryGroup] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan

  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);

  const [listChapter, setListChapter] = useState<TypeCourseStructure[]>([]);
  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);

  const [activeTab, setActiveTab] = useState<string>("1");
  const [isLoading, setIsLoading] = useState(false);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <DetailContent>
          <DetailInfoApprovalCourse
            detailData={detailData}
            ltsIndustry={ltsIndustry}
            ltsIndustryGroup={ltsIndustryGroup}
          />
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khóa học",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode !== "view" && (
            <DetailContent>
              <CourseStructureApprovalCourseRegister
                dataCourseStructure={dataListCourseStructure}
                setDataCourseStructure={setDataListCourseStructure}
              />
            </DetailContent>
          )}
          {mode === "view" && (
            <DetailContent>
              <PreviewCourseStructure
                dataCourseStructure={dataListCourseStructure}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Mô tả tài liệu",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode === "view" && (
            <DetailContent>
              <PreviewDescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
              />
            </DetailContent>
          )}
          {mode !== "view" && (
            <DetailContent isReadOnly={mode === "view" ? true : false}>
              <DescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
                setDataDescriptionDocument={setDataDescriptionDocument}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Kết quả duyệt",
      children: <DetailConfirm />,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: (
        <a href={`/course-plan/${routesConfig.browseSubscriptionPlans}`}>Duyệt khóa học</a>
      ),
    },
    {
      title: `${detailData?.moocCourseConstructionPlan?.registrationName ?? ""}`,
    },
  ];


  const getDetailMoocCourseRegisterData = async (id: any) => {
    try {
      await getMoocApprovedCourseById(id)
        .then((res) => {
          const dataRes = res.data.data;
          setDetailData(dataRes);
          const allIndustries =
            dataRes.moocCourseConstructionPlan?.moocConstructionPlanIndustry?.flatMap(
              (e: any) => e.moocIndustry
            );
          setLtsIndustry(allIndustries);
          const allIndustryGroups =
            dataRes.moocCourseConstructionPlan?.moocConstructionPlanIndustryGroup?.flatMap(
              (e: any) => e.moocIndustryGroup
            );
          setLtsIndustryGroup(allIndustryGroups);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });

    return chapterList;
  };

  const getListMoocCourseStructureChapterByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureChapterById(id)
        .then((res) => {
          const data = res.data.data;
          setListChapter(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getMoocApprovedCourseById(id)
        .then(async (res) => {
          const dataRes = res.data.data;
          await getListMoocCourseStructureById(
            dataRes?.moocCourseConstructionPlan?.id
          )
            .then((resp) => {
              const data = resp.data.data;
              setDataListCourseStructure(changeToChapterList(data));
            })
            .catch((err) => {
              console.log(err);
              message.error("Có lỗi chưa xác định");
            });
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseDescriptionDocumentByIData = async (id: any) => {
    {
      try {
        await getMoocApprovedCourseById(id)
          .then(async (res) => {
            const dataRes = res.data.data;
            await getListMoocCourseDescriptionDocumentById(
              dataRes?.moocCourseConstructionPlan?.id
            )
              .then((res) => {
                const data = res.data.data;

                setDataDescriptionDocument(data);
              })
              .catch((err) => {
                console.log(err);
                message.error("Có lỗi chưa xác định");
              });
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    }
  };

  useEffect(() => {
    const tab = location.search.slice(5);
    if (tab) {
      setActiveTab(tab);
    }
    getDetailMoocCourseRegisterData(id);
    getListMoocCourseStructureByIdData(id);
    getListMoocCourseDescriptionDocumentByIData(id);
    getListMoocCourseStructureChapterByIdData(id);
  }, [id]);

  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={`Xem chi tiết ${detailData?.moocCourseConstructionPlan?.registrationName ?? ""}`}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
            items={items}
          />
        </div>
      </Content>
    </>
  );
};

export default MoocCourseApprovalDetail;
