interface Option {
  value: string;
  label: string;
}

export const chartContentTypes: Option[] = [
  { value: "1", label: "Đa phương tiện" },
  { value: "2", label: "Tài liệu tham khảo" },
  { value: "3", label: "Bài kiểm tra đánh giá" },
  { value: "4", label: "SCORM & xAPI" },
  { value: "5", label: "Bài giảng" },
  { value: "6", label: "Khoá học" },
];