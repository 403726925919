import { useEffect, useState } from "react";
import { Button, Checkbox, CheckboxProps, Col, Row, Typography } from "antd";
import FormInputText from "../../../form-input-text/FormInputText";
import { DeleteOutlined } from "@ant-design/icons";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { initialQuestionSettingValue } from "../../../../constants";
import { useAddLesson } from "../../../../stores/stores";

interface QuestionShortTextProps {
  index: number;
  value?: string;
  checked?: any;
  onChange?: (val: { id: number; value: string }) => void;
  onDelete?: (id: number) => void;
  onChangeChecked?: (checked: boolean) => void;
}

function QuestionShortTextItem({
  index,
  value,
  checked = false,
  onChange = () => {
  },
  onDelete = () => {
  },
  onChangeChecked = () => {
  },
}: QuestionShortTextProps) {
  const [textValue, setTextValue] = useState<string>("");
  const [currentChecked, setCurrentChecked] = useState<boolean>(false);
  const { Text, Link } = Typography;
  const settingQuestion = useAddLesson((state) => state.settingQuestion);


  const handleChangeText = (changeValue: string) => {
    setTextValue(changeValue);
    onChange({ id: index, value: changeValue });
  };


  const handleChange: CheckboxProps["onChange"] = (e) => {
    onChangeChecked(e.target.checked);
    setCurrentChecked(e.target.checked);
  };

  const handleDeleteSection = (index: number) => {
    confirmMessage(
      "Xóa lựa chọn",
      0,
      "Bạn có chắc muốn xóa lựa chọn này?",
      () => {
        onDelete(index);
      }
    );
  };

  useEffect(() => {
    if (value) setTextValue(value);
  }, [value]);

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);


  return (
    <section className='answer'>
      <div className='ml-2 form-value w-full'>
        <Row>
          <Col span={24} className='form-value__header'>
            <Text>Câu trả lời {index}</Text>
          </Col>
          <Col span={24}>
            <FormInputText
              value={textValue}
              onChange={handleChangeText}
              placeholder="Nhập câu trả lời"
              autoSize={{ minRows: 1, maxRows: 6 }}
              containerClassName="w-full"
              label=""
              type="textarea"
            />
          </Col>
        </Row>
      </div>
      <Button
        className="btn-delete-item ml-2"
        onClick={() => handleDeleteSection(index)}
        icon={<DeleteOutlined />}
      ></Button>
    </section>
  );
}

export default QuestionShortTextItem;
