import { Col, Row, Empty, Spin, Button } from "antd";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ColumnsChart from "../../../../../../components/types-of-charts/ColumnsChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import {
  BarStackedChartProps,
  PieChart,
} from "../../../../../../types/static-report";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  coursesByFormatList: PieChart[];
  sequenceByAvailableStatus: PieChart[];
  sequenceByDepartment: PieChart[];
  scormAndXAPI: PieChart[];
  multiMaterialsByType: PieChart[];
  unitByCourse: BarStackedChartProps[];
  testByType: PieChart[];
  referenceSourceByType: PieChart[];
  courseAssignTeacher: BarStackedChartProps[];
};

const ResourcesChartTab = React.memo((props: Props) => {
  const {
    loading,
    coursesByFormatList,
    sequenceByAvailableStatus,
    sequenceByDepartment,
    scormAndXAPI,
    multiMaterialsByType,
    unitByCourse,
    testByType,
    referenceSourceByType,
    courseAssignTeacher,
  } = props;
  const { tab, setHandleExportAllImagesAsZip } = staticReportStore();

  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();
  useEffect(() => {
    setHandleExportAllImagesAsZip(handleExportAllImagesAsZip);
  }, [tab]);
  return (
    <div>
      <>
        <div className="chart-title">Thống kê số lượng khoá học</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng khoá học theo phân công"
              content={
                <Spin spinning={loading.courseAssignTeacher}>
                  {courseAssignTeacher.length > 0 ? (
                    <ColumnsChart
                      data={courseAssignTeacher}
                      colors={["#69C0FF", "#FFC069"]}
                      ref={createChartRef("courseAssignTeacher")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("courseAssignTeacher", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("courseAssignTeacher", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng khoá học theo hình thức xuất bản"
              content={
                <Spin spinning={loading.coursesByFormatList}>
                  {coursesByFormatList.length > 0 ? (
                    <DonutChart
                      data={coursesByFormatList}
                      title=""
                      colors={["#5CDBD3", "#FF7875", "#FFC069"]}
                      height={280}

                      ref={createChartRef("coursesByFormatList")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("coursesByFormatList", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("coursesByFormatList", "application/pdf"),
                },
              ]}
            />
          </Col>
        </Row>
      </>
      <>
        <div className="chart-title">Thống kê số lượng bài giảng</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng bài giảng theo khoa"
              content={
                <Spin spinning={loading.sequenceByDepartment}>
                  {sequenceByDepartment.length > 0 ? (
                    <BarChart
                      data={sequenceByDepartment}
                      colors={["#69C0FF"]}
                      ref={createChartRef("sequenceByDepartment")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("sequenceByDepartment", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage(
                      "sequenceByDepartment",
                      "application/pdf"
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng bài giảng theo trạng thái xuất bản"
              content={
                <Spin spinning={loading.sequenceByAvailableStatus}>
                  {sequenceByAvailableStatus.length > 0 ? (
                    <DonutChart
                      data={sequenceByAvailableStatus}
                      title=""
                      colors={["#FFC069", "#FF7875", "#5CDBD3"]}
                      height={280}

                      ref={createChartRef("sequenceByAvailableStatus")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("sequenceByAvailableStatus", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage(
                      "sequenceByAvailableStatus",
                      "application/pdf"
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>
      <>
        <div className="chart-title">Thống kê số lượng học liệu</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng bài kiểm tra theo loại"
              content={
                <Spin spinning={loading.testByType}>
                  {testByType.length > 0 ? (
                    <DonutChart
                      data={testByType}
                      title=""
                      colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                      height={280}

                      ref={createChartRef("testByType")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () => handleExportImage("testByType", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("testByType", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tài liệu tham khảo theo loại"
              content={
                <Spin spinning={loading.referenceSourceByType}>
                  {referenceSourceByType.length > 0 ? (
                    <DonutChart
                      data={referenceSourceByType}
                      title=""
                      colors={["#FFC069", "#FF7875", "#5CDBD3"]}
                      height={280}

                      ref={createChartRef("referenceSourceByType")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("referenceSourceByType", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage(
                      "referenceSourceByType",
                      "application/pdf"
                    ),
                },
              ]}
            />
          </Col>
          <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng Scorm & xAPI"
              content={
                <Spin spinning={loading.scormAndXAPI}>
                  {scormAndXAPI.length > 0 ? (
                    <DonutChart
                      data={scormAndXAPI}
                      title=""
                      colors={["#5CDBD3", "#FFC069"]}
                      height={280}

                      ref={createChartRef("scormAndXAPI")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () => handleExportImage("scormAndXAPI", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("scormAndXAPI", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng học liệu đa phương tiện theo loại"
              content={
                <Spin spinning={loading.multiMaterialsByType}>
                  {multiMaterialsByType.length > 0 ? (
                    <DonutChart
                      data={multiMaterialsByType}
                      title=""
                      colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                      height={280}

                      ref={createChartRef("multiMaterialsByType")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("multiMaterialsByType", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage(
                      "multiMaterialsByType",
                      "application/pdf"
                    ),
                },
              ]}
            />
          </Col>
        </Row>
        <div className="mt-2">
          <StatisticalReportCard
            chartName="Số lượng học liệu theo khoá học"
            content={
              <Spin spinning={loading.unitByCourse}>
                {unitByCourse.length > 0 ? (
                  <BarStackedChart
                    data={unitByCourse}
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    ref={createChartRef("unitByCourse")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () => handleExportImage("unitByCourse", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("unitByCourse", "application/pdf"),
              },
            ]}
          />
        </div>
      </>
    </div>
  );
});

export default ResourcesChartTab;
