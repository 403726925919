export enum IdentityMethod {
  FACE = "face",
  PASSWORD = "password",
  BOTH = "both",
  NONE = "none",
}

export const DEFAULT_GROUP: any = {
   "is_qtcs": "Quản trị cơ sở",
   "is_sv": "Sinh viên",
   "is_giang_vien": "Giảng viên",
   "is_cap_bo": "Quản trị hệ thống",
}

export const DEFAULT_ROLE: any = {
  attendance: "Quyền điểm danh",
  export_report: "Quyền xuất báo cáo",
  share_report: "Quyền chia sẻ báo cáo",
  get_report: "Quyền nhận báo cáo",
  notification: "Quyền nhận thông báo",
  mode: "Quyền cài đặt chế độ"
};

export const DEFAULT_EXPORT_REPORT: any = {
  nameSv: "Họ và tên sinh viên",
  date_birthday: "Ngày tháng năm sinh",
  phone: "Số điện thoại",
  email: "Email",
  status: "Điểm danh",
};
