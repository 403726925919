import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../form-input/FormInput";
import {
  CloseOutlined,
  CopyOutlined,
  HeatMapOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PicCenterOutlined,
  PlayCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import "./index.scss";
import CommonModal from "../../modal/common";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { createReviewCourse, getQuestionBank } from "../../../service/course-construct";
import TextArea from "antd/es/input/TextArea";

var indexDB = require("idbcache").default;

type Props = {
  open: boolean;
  onClose: () => void;
  id: string | undefined;
  sequenceId: number;
  parentName: string | undefined;
};

export default function AddLessionDrawer(props: Props) {
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const [listQuestionBank, setListQuestionBank] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState<any>({
    page: 1,
    size: 20,
    keyword: "",
    types: ["unit_quiz"],
    status: [
      true
    ]
  })
  const validation = {
    title: {
      required: true,
      message: "Vui lòng nhập tiêu đề.",
    },
  };

  const dataStatus = [
    {
      value: true,
      label: "Hoạt động",
    },
    {
      value: false,
      label: "Không hoạt động",
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const OPTIONS = [
    {
      value: 1,
      label: <PlayCircleOutlined />,
    },
    {
      value: 2,
      label: <PicCenterOutlined />,
    },
    {
      value: 3,
      label: <CopyOutlined />,
    },
    {
      value: 4,
      label: <HeatMapOutlined />,
    },
  ];

  // 1 = 10
  // 2 = 20
  // 1 + 2 = 9999
  const listAssessments = [
    {
      label: "Trực tuyến",
      value: 1,
      description: "Người học tham gia đánh giá trên nền tảng MOOC",
    },
    {
      label: "Trực tiếp",
      value: 2,
      description: "Người học tham gia đánh giá trực tiếp tại Cơ sở đào tạo",
    },
  ];

  const renderAssessmentsRadioGroup = (option: any) => {
    return (
      <Radio.Group>
        <Row>
          {option.map((op: any) => (
            <Col span={12} key={op.value} style={{ marginBottom: "16px" }}>
              <Radio value={op.value}>{op.label}</Radio>
              <div
                style={{
                  marginLeft: "24px",
                  paddingRight: "10px",
                  color: "#888",
                  fontSize: "12px",
                }}
              >
                {op.description}
              </div>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    );
  };

  const optionTime = [
    {
      id: 10,
      name: "Phút",
    },
    {
      id: 20,
      name: "Giờ",
    },
  ];

  const gotoAddLessonPage = () => {
    form.submit();
  };

  // Get list evaluation form
  const listVideo = listQuestionBank.map((item: any) => ({
    id: item.id,
    name: item.code,
  }));

  const getEvaluationForm = async (data: any) => {
    try {
      await getQuestionBank(data).then((res) => {
        setLoading(false);
        if (res.status === 200 && res.data.data) {
          setListQuestionBank(res.data.data.content);
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (props.open) {
      setLoading(true)
      getEvaluationForm(dataSearch);
    }
  }, [props.open])

  useEffect(() => {
    form.setFieldsValue({
      typeStatus: 1,
      assessments: 1,
      expectedTime: 4,
      expectedTimeType: 10,
    });
  }, []);

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === "form-create-review-course") {
          const data = {
            title: values.title,
            description: values.description,
            courseSequenceId: props.sequenceId,
            iconType: values.icon,
            reviewType: values.typeStatus,
            formOfReview: values.assessments ,
            expectedTime: values.expectedTime,
            expectedTimeType: values.expectedTimeType,
          };
          await createReviewCourse(data).then((res) => {
            if (res.status === 200) {
              message.success("Tạo đánh giá thành công");
              setOpenCorfirmModal(false);
              setTimeout(() => {
                form.resetFields();
                indexDB.set("current-course", JSON.stringify({ name: data.title, parent: props.parentName, tabCurrent: '2' }), 1440);
                navigate(`${routesConfig.addLesson}?id=${res.data.body.message}`, { state: { id: res.data.body.message ?? "" } });
              }, 1000);
            }
          });
        }
      }}
    >
      <Drawer
        title="Thêm đánh giá"
        placement={"right"}
        className="add-lession-drawer"
        width={500}
        onClose={props.onClose}
        open={props.open}
      >
        <>
          {loading ?
            (
              <div className="loading-content">
                <LoadingOutlined />
              </div>
            )
            :
            (
              <Form
                layout={"vertical"}
                form={form}
                name="form-create-review-course"
              >
                <Row gutter={[24, 12]}>
                  <Typography.Title level={5}>Thông tin chung</Typography.Title>

                  <Col span={24}>
                    <Form.Item
                      name="title"
                      rules={[validation.title]}
                      label="Tiêu đề"
                    >
                      <FormInput placeholder="Tiêu đề" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label="Mô tả"
                    >
                      <TextArea showCount maxLength={512} placeholder="Mô tả" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="icon" label="Icon">
                      <Select
                        placeholder="Chọn icon"
                        value={selectedItems}
                        onChange={setSelectedItems}
                        // style={{ width: '100%' }}
                        options={OPTIONS.map((item, index) => ({
                          ...item,
                          label: <div>{item.label}</div>,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={18}>
                    <Form.Item
                      name="videoTemplate"
                      label="Chọn mẫu"
                      className="last-form-item"
                    >
                      <FormItemDropdown
                        options={listVideo}
                        allowClear={true}
                        placeholder="Chọn mẫu"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="typeStatus" label="Loại đánh giá">
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Bài tập</Radio>
                        <Radio value={2}>Bài kiểm tra</Radio>
                        <Radio value={3}>Bài thi</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="assessments" label="Hình thức đánh giá">
                      {renderAssessmentsRadioGroup(listAssessments)}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="expectedTime" label="Nỗ lực mong chờ">
                      <InputNumber style={{ width: "100%" }} min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="expectedTimeType" label=" ">
                      <FormItemDropdown options={optionTime} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-3 float-right">
                  <Space>
                    <Button onClick={props.onClose} className="btn btn-outlined">
                      <CloseOutlined />
                      <Typography.Text>Hủy</Typography.Text>
                    </Button>
                    <Button
                      className="btn btn-primary"
                      onClick={() => setOpenCorfirmModal(true)}
                    >
                      <SaveOutlined />
                      <Typography.Text>Lưu</Typography.Text>
                    </Button>
                  </Space>
                </Row>

                <CommonModal
                  open={openCorfirmModal}
                  title={"Thông báo xác nhận lưu học liệu"}
                  // desc={`Vui lòng xác nhận lưu học liệu trong khoá học`}
                  desc={
                    <><p>Xin vui lòng xác nhận việc lưu học liệu trong khoá học</p><div>
                      Đảm bảo rằng tất cả các{" "}
                      <span className="text-browse">
                        thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                      </span>
                      trước khi lưu khoá học
                    </div></>
                  }
                  closeIcon={true}
                  okText={"Xác nhận"}
                  cancelText={"Huỷ"}
                  icon={
                    <InfoCircleOutlined
                      style={{ color: "#FFC53D", marginRight: "10px" }}
                    />
                  }
                  confirm={gotoAddLessonPage}
                  closeModal={() => setOpenCorfirmModal(false)}
                />
              </Form>
            )
          }
        </>
      </Drawer>
    </Form.Provider>
  );
}
