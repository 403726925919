import {
  Button,
  Checkbox,
  CheckboxProps,
  DatePicker,
  DatePickerProps,
  Form,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Switch,
  TimePicker,
  Typography,
} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import "./active-setting.scss";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import {dataOptionTime, dataTimeLimited, dataTimeSettings,} from "./data";
import dayjs, {Dayjs} from "dayjs";
import {TimePickerProps} from "antd/lib";
import vi from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
import {
  configSurveyActive,
  getDataDetailConfig,
  getDataLayoutSurvey,
} from "../../../../../service/course-construct/survey-plan";
import {get} from "lodash";

type ActiveSettingProps = {
  data?: any;
};

const localeSchedule: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ",
  },
  timePickerLocale: {
    placeholder: "Chọn giờ",
  },
};

const ActiveSetting = ({data}: ActiveSettingProps) => {
  const [form] = Form.useForm();
  const displayTimeValue = Form.useWatch('displayTime', form)
  const displayResultValue = Form.useWatch('displayResult', form)
  const [isFormUpdate, setIsFormUpdate] = useState(false);
  const [dateLimit, setDateLimit] = useState<Dayjs | null>(null);
  const [timeLimit, setTimeLimit] = useState<Dayjs | null>(null);
  const [limit, setLimit] = useState({
    limitTimeFinish: '',
    limitTimeProgress: '',
    limitPersonProgress: '',
    limitTurnProgress: ''
  });
  const [timeSetting, setTimeSetting] = useState("");
  const [valueNumber, setValueNumber] = useState(0);
  const [isDisplayDate, setIsDisplayDate] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [typeLayout, setTypeLayout] = useState<string>("");
  const [dataLayout, setDataLayout] = useState([]);
  const [initialValues, setInitialValues] = useState({
    showExecutionTime: false,
    showCompletetionTime: false,
    executionTime: false,
    displayTime: [],
    executionTimeValue: "",
    dateLimit: "",
    timeLimit: "",
    timeAllQuiz: 0,
    timeEachQuiz: 0,
    numberOfPeople: 0,
    executionTurn: 0,
    layout: "default",
    typeLayout: "in_turn",
  });


  const getDataConfig = async (id: number) => {
    try {
      await getDataDetailConfig(id).then((r) => {
        const {data} = r.data;
        setInitialValues(data);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getDataLayout = async () => {
    try {
      await getDataLayoutSurvey().then((r) => {
        const {data} = r.data;
        setDataLayout(data);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const newDataLayout = useMemo(() => {
    return dataLayout.map((item: any) => {
      return {
        label: item.name,
        code: item.id,
        description: item.id === 'default' ? 'Hiển thị tất cả câu hỏi trên một trang' :
          item.id === 'by_group' ? 'Hiển thị mỗi trang một phần' : 'Hiển thị mỗi trang một câu hỏi',
        displayDropdown: item.id === 'by_group' || item.id === 'by_quiz',
        dataDropdown: (item.id === 'by_group' || item.id === 'by_quiz') && [{
          id: 'in_turn',
          label: 'Lần lượt'
        }, {id: 'optional', label: 'Tùy chọn'}]
      }
    })
  }, [dataLayout])


  const onFinish = (values: any) => {
    const payloadData = {
      surveyPlanId: data.id ? data.id : 0,
      layout: values.layout,
      typeLayout: values.layout === "default" ? "" : values.layout === "by_group" ? values.typeLayoutByGroup : values.typeLayoutByQuiz,
      showExecutionTime:
        values.displayTime &&
        values.displayTime.some((item: string) => item === "progress"),
      showCompletetionTime:
        values.displayTime &&
        values.displayTime.some((item: string) => item === "finish"),
      timeAllQuiz: values.settingLimit && values.settingLimit.includes("limitTimeFinish") ? values.timeAllQuiz : 0,
      timeEachQuiz: values.settingLimit && values.settingLimit.includes("limitTimeProgress") ? values.timeEachQuiz : 0,
      numberOfPeople: values.settingLimit && values.settingLimit.includes('limitPersonProgress') ? values.numberOfPeople : 0,
      executionTurn: values.settingLimit && values.settingLimit.includes('limitTurnProgress') ? values.executionTurn : 0,
      executionTimeValue:
        values.dateLimit && values.timeLimit
          ? dayjs(values.dateLimit)
            .set("hour", values.timeLimit.hour())
            .set("minute", values.timeLimit.minute())
          : null,
      executionTime: values.executionTime,
    };

    if (!isFormUpdate) {
      createSurvey(payloadData);
    } else {
      updateSurvey(payloadData);
    }
  };

  const onReset = () => {
    if (isFormUpdate && get(initialValues, 'surveyPlanId', null)) {
      getDataConfig(data.id)
    } else {
      form.resetFields()
      setInitialValues({
        ...initialValues,
        showExecutionTime: false,
        showCompletetionTime: false,
        executionTime: false,
        displayTime: [],
        executionTimeValue: "",
        dateLimit: "",
        timeLimit: "",
        timeAllQuiz: 0,
        timeEachQuiz: 0,
        numberOfPeople: 0,
        executionTurn: 0,
        layout: "default",
        typeLayout: "in_turn",
      })
      setLimit({
        ...limit,
        limitTimeFinish: '',
        limitTimeProgress: '',
        limitPersonProgress: '',
        limitTurnProgress: ''
      })
      setIsDisplayDate(false)
    }
  }


  useEffect(() => {
    if (data.id) {
      getDataConfig(data.id);
    }

  }, [data.id]);


  useEffect(() => {
    if (initialValues !== null && get(initialValues, 'surveyPlanId', 0) > 0 && Object.values(initialValues).some(value => value > 0 || true)) {
      setIsFormUpdate(true)
      form.setFieldsValue({
        layout: initialValues.layout,
        typeLayoutByGroup: initialValues.layout === 'by_group' ? initialValues.typeLayout : 'in_turn',
        typeLayoutByQuiz: initialValues.layout === 'by_quiz' ? initialValues.typeLayout : 'in_turn',
        displayTime: initialValues.showCompletetionTime && !initialValues.showExecutionTime ? ["finish"] : !initialValues.showCompletetionTime && initialValues.showExecutionTime ? ["progress"] :
          initialValues.showExecutionTime && initialValues.showCompletetionTime ? ["finish", "progress"] : [""],
        displayResult: initialValues.showExecutionTime || initialValues.showCompletetionTime,
        timeAllQuiz: initialValues.timeAllQuiz,
        timeEachQuiz: initialValues.timeEachQuiz,
        numberOfPeople: initialValues.numberOfPeople,
        executionTurn: initialValues.executionTurn,
        timeLimit: initialValues.executionTime ? dayjs(initialValues.executionTimeValue) : null,
        dateLimit: initialValues.executionTime ? dayjs(initialValues.executionTimeValue) : null,
        executionTime: initialValues.executionTime,
        settingLimit: [initialValues.timeAllQuiz !== 0 ? 'limitTimeFinish' : '',
          initialValues.timeEachQuiz !== 0 ? 'limitTimeProgress' : '',
          initialValues.numberOfPeople !== 0 ? 'limitPersonProgress' : '',
          initialValues.executionTurn !== 0 ? 'limitTurnProgress' : ''
        ]
      });
      setLimit({
        ...limit,
        limitTimeFinish: initialValues.timeAllQuiz !== 0 ? 'limitTimeFinish' : '',
        limitTimeProgress: initialValues.timeEachQuiz !== 0 ? 'limitTimeProgress' : '',
        limitPersonProgress: initialValues.numberOfPeople !== 0 ? 'limitPersonProgress' : '',
        limitTurnProgress: initialValues.executionTurn !== 0 ? 'limitTurnProgress' : ''
      })
      if (initialValues.executionTime) {
        setIsDisplayDate(true)
      }
      if (initialValues.layout) {
        setTypeLayout(initialValues.layout)
      }

    } else {
      setIsFormUpdate(false);
    }
    if (initialValues === null) {
      setInitialValues(initialValues)
    }

  }, [initialValues]);

  useEffect(() => {
    getDataLayout();
  }, []);

  const disabledHours = () => {
    if (!dateLimit) return []; // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(dateLimit);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past hours
      // @ts-ignore
      return [...Array(currentDate.hour()).keys()];
    }
    return []; // Otherwise, all hours are available
  };

  const disabledMinutes = () => {
    if (!dateLimit) return []; // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(dateLimit);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past minutes
      // @ts-ignore
      return [...Array(currentDate.minute()).keys()];
    }
    return []; // Otherwise, all hours are available
  };


  const disableDays = (currentDate: any) => {
    if (!dateLimit) return currentDate && currentDate.isBefore(dayjs().subtract(1, "day"))
    if (dateLimit || initialValues.dateLimit) {
      dayjs(dateLimit).isAfter(currentDate.subtract(1, "day"))
      return currentDate && currentDate.isBefore(dayjs(dateLimit))
    }
  }

  const handleChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    setDateLimit(date);
  };

  const handleChangeTime: TimePickerProps["onChange"] = (time, timeString) => {
    setTimeLimit(time);
  };

  const createSurvey = async (values: any) => {
    try {
      await configSurveyActive(values).then((res) => {
        const {success} = res.data;
        if (success) {
          message.success("Thiết lập khảo sát thành công");
        }
        if (!success) {
          message.success(res.data.message);
        }
      })
    } catch (err) {
      console.error(err);
    }
  };

  const updateSurvey = async (values: any) => {
    try {
      await configSurveyActive(values).then((res) => {
        const {success} = res.data;
        if (success) {
          message.success("Cập nhật thiết lập khảo sát thành công");
        }
        if (!success) {
          message.success(res.data.message);
        }
      })
    } catch (err) {
      console.error(err);
    }
  };

  const handleChooseTimeSetting = ({target: {value}}: RadioChangeEvent) => {
    if (!value) {
      setIsDisplayDate(false);
    } else {
      setIsDisplayDate(true);
    }
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setLimit({
        ...limit,
        [e.target.value]: e.target.value
      })
    } else {
      setLimit({
        ...limit,
        [e.target.value]: ''
      });
    }
  };


  const onChangeSetting: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      setTimeSetting(e.target.value)
    } else {
      setTimeSetting("");
    }
  };

  const handleChangeLayout = (e: RadioChangeEvent) => {
    setTypeLayout(e.target.value);
  };


  useEffect(() => {
    if (timeSetting) {
      if (form.getFieldValue(['displayTime']).some((value: string) => value === 'finish' || value === 'progress') && form.getFieldValue(['displayTime']).length > 0) {
        form.setFieldsValue({
          displayResult: true
        })
      }
    }
  }, [timeSetting]);


  useEffect(() => {
    if (displayTimeValue && displayTimeValue.length === 0) {
      form.setFieldsValue({
        displayResult: false
      })
    }
  }, [displayTimeValue]);

  return (
    <>
      <div className="wrapper-active">
        <div className="active-settings">
          <Form form={form} className="form-settings" onFinish={onFinish} initialValues={initialValues}>
            <CustomCard
              title="Kết quả khảo sát"
              className="wrapper-settings--card form-setting--common"
              children={
                <>
                  <div className="toggle-display">
                    <span>Hiển thị kết quả sau khi hoàn tất:</span>
                    <Form.Item name="displayResult">
                      <Switch/>
                    </Form.Item>
                  </div>
                  <Form.Item className="form-item-setting" name="displayTime">
                    <Checkbox.Group>
                      <div className="wrapper-radio">
                        {dataOptionTime.map((opt) => (
                          <>
                            <Checkbox
                              key={opt.value}
                              value={opt.value}
                              disabled={!displayResultValue}
                              onChange={onChangeSetting}
                            >
                              {opt.label}
                            </Checkbox>
                          </>
                        ))}
                      </div>
                    </Checkbox.Group>
                  </Form.Item>
                </>
              }
            />
            <CustomCard
              title="Thực hiện khảo sát"
              className="wrapper-settings--card form-setting--common"
              children={
                <>
                  <div className="survey-implement">
                    <div className="survey-implement--title">
                      <Typography.Text>Thiết lập thời hạn</Typography.Text>
                    </div>
                    <div className="survey-implement--item">
                      <Form.Item name="executionTime">
                        <Radio.Group>
                          {dataTimeLimited.map((time) => (
                            <>
                              <div className="wrapper-radio" key={time.label}>
                                <Radio
                                  value={time.value}
                                  onChange={handleChooseTimeSetting}
                                >
                                  {time.label}
                                </Radio>
                                <div className="message">
                                  <Typography.Text className="sub-title">
                                    {time.description}
                                  </Typography.Text>
                                </div>
                              </div>
                            </>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                      <div className="item-time">
                        <Form.Item name="dateLimit">
                          <DatePicker
                            format="DD/MM/YYYY"

                            disabledDate={(current) => disableDays(current)}
                            onChange={handleChangeDate}
                            disabled={!isDisplayDate}
                            locale={localeSchedule}
                          />
                        </Form.Item>
                        <Form.Item name="timeLimit">
                          <TimePicker
                            disabledHours={disabledHours}
                            disabledMinutes={disabledMinutes}
                            onChange={handleChangeTime}
                            disabled={!isDisplayDate}
                            locale={localeSchedule}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="survey-implement">
                    <div className="survey-implement--title">
                      <Typography.Text>Thiết lập giới hạn</Typography.Text>
                    </div>
                    <div className="survey-implement--item">
                      <Form.Item name="settingLimit">

                        <Checkbox.Group>
                          {dataTimeSettings.map((time) => (
                            <>
                              <div
                                className="wrapper-radio--input"
                                key={time.id}
                              >
                                <Checkbox value={time.value}
                                          key={time.id} onChange={onChange}>
                                  {time.label}
                                </Checkbox>
                                {time.value === "limitTimeFinish" && (
                                  <Form.Item name="timeAllQuiz">
                                    <InputNumber
                                      addonAfter={time.inputValue.label}
                                      disabled={time.value !== limit.limitTimeFinish}
                                      value={valueNumber}
                                      min={0}
                                    />
                                  </Form.Item>
                                )}
                                {time.value === "limitTimeProgress" && (
                                  <Form.Item name="timeEachQuiz">
                                    <InputNumber
                                      addonAfter={time.inputValue.label}
                                      disabled={time.value !== limit.limitTimeProgress}
                                      min={0}
                                    />
                                  </Form.Item>
                                )}
                                {time.value === "limitPersonProgress" && (
                                  <Form.Item name="numberOfPeople">
                                    <InputNumber
                                      addonAfter={time.inputValue.label}
                                      disabled={time.value !== limit.limitPersonProgress}
                                      min={0}
                                    />
                                  </Form.Item>
                                )}
                                {time.value === "limitTurnProgress" && (
                                  <Form.Item name="executionTurn">
                                    <InputNumber
                                      addonAfter={time.inputValue.label}
                                      disabled={time.value !== limit.limitTurnProgress}
                                      min={0}
                                    />
                                  </Form.Item>
                                )}
                              </div>
                            </>
                          ))}
                        </Checkbox.Group>
                      </Form.Item>

                    </div>
                  </div>
                </>
              }
            />
            <CustomCard
              title="Bố cục"
              className="wrapper-settings--card form-setting--common"
              children={
                <>
                  <div className="survey-implement">
                    <div className="survey-implement--title">
                      <Typography.Text>Thiết lập bố cục</Typography.Text>
                    </div>
                    <div className="survey-implement--item">
                      <Form.Item name="layout">
                        <Radio.Group onChange={handleChangeLayout} value={typeLayout}>
                          {newDataLayout.map((item) => (
                            <>
                              {!item.displayDropdown ? (
                                <div className="wrapper-radio">
                                  <Radio
                                    value={item.code}
                                  >
                                    {item.label}
                                  </Radio>
                                  <div className="message">
                                    <Typography.Text className="sub-title">
                                      {item.description}
                                    </Typography.Text>
                                  </div>
                                </div>
                              ) : (
                                <div className="wrapper-radio--input">
                                  <div className="wrapper-radio">
                                    <Radio
                                      value={item.code}
                                    >
                                      {item.label}
                                    </Radio>
                                    <div className="message">
                                      <Typography.Text className="sub-title">
                                        {item.description}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                  {
                                    item.code === "by_group" && (
                                      <Form.Item name="typeLayoutByGroup" key={item.code}>
                                        <FormItemDropdown
                                          placeholder="Lần lượt"
                                          style={{width: 100}}
                                          disabled={item.code !== typeLayout}
                                          options={item.dataDropdown ? item.dataDropdown : []}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                  {
                                    item.code === "by_quiz" && (
                                      <Form.Item name="typeLayoutByQuiz" key={item.code}>
                                        <FormItemDropdown
                                          placeholder="Lần lượt"
                                          style={{width: 100}}
                                          disabled={item.code !== typeLayout}
                                          options={item.dataDropdown ? item.dataDropdown : []}
                                        />
                                      </Form.Item>
                                    )
                                  }
                                </div>
                              )}
                            </>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                </>
              }
            />
            <div className="group-button">
              <Button className="ant-btn-primary" htmlType="submit">
                <SaveOutlined/>
                <Typography.Text>Lưu</Typography.Text>
              </Button>
              <Button className="cancel-btn" onClick={onReset}>
                <CloseOutlined/>
                <Typography.Text>Hủy</Typography.Text>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ActiveSetting;
