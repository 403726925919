// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-section .form-input {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0; }

.survey-managment-section .contain-media {
  display: flex;
  flex-wrap: wrap; }
  .survey-managment-section .contain-media iframe {
    max-width: 100%;
    margin: 5px;
    border: 1px solid #cfcfcf;
    border-radius: 15px; }
  .survey-managment-section .contain-media p {
    display: flex;
    flex-wrap: wrap;
    flex: auto; }
    .survey-managment-section .contain-media p img {
      max-width: 100%;
      margin: 5px;
      object-fit: cover;
      border: 1px solid #cfcfcf;
      border-radius: 15px; }
  .survey-managment-section .contain-media .file-media {
    display: flex;
    flex-wrap: wrap; }
    .survey-managment-section .contain-media .file-media img {
      max-width: 100%;
      margin: 5px;
      object-fit: cover;
      border: 1px solid #cfcfcf;
      border-radius: 15px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/detail-section/detail.scss"],"names":[],"mappings":"AAAA;EAGI,aAAiB;EACjB,mBAAmB;EACnB,WAAiB;EACjB,SAAkB,EAAA;;AANtB;EAaI,aAAe;EACf,eAAe,EAAA;EAdnB;IAiBM,eAAmB;IACnB,WAAkB;IAClB,yBAAgC;IAChC,mBAAmB,EAAA;EApBzB;IA4BM,aAAa;IAEb,eAAe;IACf,UAAe,EAAA;IA/BrB;MAkCQ,eAAmB;MAEnB,WAAkB;MAClB,iBAAoB;MACpB,yBAAgC;MAChC,mBAAmB,EAAA;EAvC3B;IA6CM,aAAe;IACf,eAAe,EAAA;IA9CrB;MAiDQ,eAAe;MAGf,WAAkB;MAClB,iBAAoB;MACpB,yBAAgC;MAChC,mBAAmB,EAAA","sourcesContent":[".survey-managment-section {\n\n  .form-input {\n    display    : flex;\n    align-items: center;\n    width      : 100%;\n    flex       : 1 0 0;\n  }\n\n  // .input-header-title::placeholder{\n  //     color:black\n  // }\n  .contain-media {\n    display  : flex;\n    flex-wrap: wrap;\n\n    iframe {\n      max-width    : 100%;\n      margin       : 5px;\n      border       : 1px solid #cfcfcf;\n      border-radius: 15px;\n    }\n\n    // .ql-video {\n    //   width:200px;\n    //   height: 200px;\n    // }\n    p {\n      display: flex;\n\n      flex-wrap: wrap;\n      flex     : auto;\n\n      img {\n        max-width    : 100%;\n        // height: 200px;\n        margin       : 5px;\n        object-fit   : cover;\n        border       : 1px solid #cfcfcf;\n        border-radius: 15px;\n      }\n    }\n\n    .file-media {\n      // height: 1000px;\n      display  : flex;\n      flex-wrap: wrap;\n\n      img {\n        max-width: 100%;\n\n        // height: 200px;\n        margin       : 5px;\n        object-fit   : cover;\n        border       : 1px solid #cfcfcf;\n        border-radius: 15px;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
