import React, {useState} from 'react';
import {Col, Row, TableColumnsType, TableProps} from "antd";
import TableReportCard from "../../chart/table-report-card";
import TableData from "../../../../../../components/table-data/TableData";
import './index.scss'

const OverviewTable = () => {
  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")

  const tableAccessColumn: TableColumnsType<any> = [
    {
      title: "Ngày",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Vãng lai",
      dataIndex: "guest",
      key: "guest"
    },
    {
      title: "Người dùng tự do",
      dataIndex: "freeUsers",
      key: "freeUsers"
    },
    {
      title: "Trường đăng ký",
      dataIndex: "universityRegister",
      key: "universityRegister"
    },
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total"
    }
  ]

  const tableColumn: TableColumnsType<any> = [
    {
      title: "Tỉnh/TP",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Số lượng",
      dataIndex: "guest",
      key: "guest"
    },
  ]

  const tableTeachersAndStudentColumn: TableColumnsType<any> = [
    {
      title: "Trường",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Số lượng",
      dataIndex: "guest",
      key: "guest"
    },
  ]

  const tableCourseDataColumn: TableColumnsType<any> = [
    {
      title: "Trường",
      dataIndex: "date",
      key: "date",
      width: 40,
    },
    {
      title: "Bài kiểm tra",
      dataIndex: "guest",
      key: "guest",
      width: 20
    },
    {
      title: "Tài liệu tham khảo",
      width: 20
    },
    {
      title: "Đa phương tiện",
      width: 20
    },
    {
      title: "Scorm & xAPI",
      width: 20
    },
    {
      title: "Tổng",
      width: 20
    }
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê lượt truy cập
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượt truy cập"
                           content={<>
                             <TableData dataColumns={tableAccessColumn} handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Số người truy cập"
                           content={<>
                             <TableData dataColumns={tableAccessColumn} handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê trường - giảng viên - học viên - khóa học
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng trường liên kết" content={<>
            <TableData dataColumns={tableColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng giảng viên" content={<>
            <TableData dataColumns={tableTeachersAndStudentColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng học viên" content={<>
            <TableData dataColumns={tableTeachersAndStudentColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng khoá học" content={<>
            <TableData dataColumns={tableTeachersAndStudentColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng học liệu
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 24}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng học liệu theo khoá học"
                           content={<>
                             <TableData dataColumns={tableCourseDataColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default OverviewTable;
