import { Form, Layout } from "antd";
import { FunctionComponent, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./portfolio-value-management-detail.scss";

const { Content } = Layout;
const imgPath = "/images/";

const PortfolioValueManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);

    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.portfolioValueManagement}`}>
            <span>Quản lý giá trị danh mục</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
    ];

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={isShowSubmitButton}
                />
                <div className="wrapper-settings">
                    <section className="setting-portfolio">
                        <CustomCard
                            className="portfolio-card"
                            title="Thông tin danh mục dùng chung">
                            <div>
                                <Form.Item className="setting-item" name="cateName" label="Tên thuộc tính">
                                    <p className="portfolio-content">{location.state?.name}</p>
                                </Form.Item>
                                <Form.Item className="setting-item" name="cateCode" label="Mã thuộc tính">
                                    <p className="portfolio-content">{location.state.record.code}</p>
                                </Form.Item>
                                <Form.Item className="setting-item" name="cateDad" label="Thuộc tính cha">
                                    <p className="portfolio-content">{location.state.record.parentName}</p>
                                </Form.Item>
                                <Form.Item className="setting-item" name="status" label="Trạng thái">
                                    <p> {location.state.record.status ? <>Hoạt động</> : <>Không hoạt động</>} </p>
                                </Form.Item>
                                <Form.Item className="setting-item" name="createdDate" label="Ngày tạo">
                                    <p className="created-date">{location.state.record.createdDate}</p>
                                </Form.Item>
                            </div>
                        </CustomCard>
                    </section>
                </div>
            </Content>
        </>
    )
}

export default PortfolioValueManagementDetail;