import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  HolderOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Flex, Input, Tooltip, Typography } from "antd";
import { ChangeEvent, useEffect, useState } from "react";
import {
  createSettingGroupQualityAssessment,
  deleteQualityAssessmentGroup,
  updateSettingGroupQualityAssessment,
} from "../../../service/control-quality-course";
const { Title, Text } = Typography;

interface IProps {
  isOpen: boolean;
  handleNotice: (type: any, message: string) => void;
  isView: boolean;
  dataStandard: any;
  setDataStandard: (item: any) => void;
  handleRefresh: () => void;
}

const SettingStandard = ({
  isOpen,
  handleNotice,
  isView,
  dataStandard,
  setDataStandard,
  handleRefresh,
}: IProps) => {
  const [indexEdit, setIndexEdit] = useState<any>([]);

  const fetchSettingIdentity = async () => {};

  useEffect(() => {
    fetchSettingIdentity();
  }, []);

  const handleChange = (e: any, index: number) => {
    const { value } = e.target;
    setDataStandard((prevItems: any) => {
      prevItems[index] = {
        ...prevItems[index],
        title: value,
      };
      return prevItems;
    });
  };

  const handleChangeItem = (
    itemIndex: number,
    formIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;

    setDataStandard((prevItems: any) => {
      prevItems[itemIndex].mooc_setting_quality_assessment_field[
        formIndex
      ].title = value;
      return prevItems;
    });
  };

  const handleSave = async (index: number) => {
    const paramAssessment = {
      title: dataStandard[index].title,
      fields: dataStandard[index].mooc_setting_quality_assessment_field.map(
        (item: any) => item.title
      ),
    };
    const paramAssessmentUpdate = {
      title: dataStandard[index].title,
      fields: dataStandard[index].mooc_setting_quality_assessment_field.map(
        (item: any) => ({ id: item?.id, title: item.title })
      ),
    };

    if (
      dataStandard[index].mooc_setting_quality_assessment_field.some(
        (item: any) => item.title === ""
      ) ||
      dataStandard[index].title === ""
    ) {
      handleNotice("error", "Tiêu đề và tiêu chí không được để trống");
      return;
    }
    setIndexEdit(indexEdit.filter((item: number) => item !== index));

    if (!dataStandard[index]?.id) {
      // Create Group Assessment
      try {
        const res = await createSettingGroupQualityAssessment(paramAssessment);
        const { data } = res.data;
        setDataStandard((prevItems: any) => {
          prevItems[index] = {
            ...prevItems[index],
            id: data?.id,
            mooc_setting_quality_assessment_field: [
              ...data?.mooc_setting_quality_assessment_field,
            ],
          };
          return prevItems;
        });
        handleNotice("success", "Lưu thông tin thành công");
      } catch (error) {
        console.log(error);
      }
    } else {
      // Update Group Assessment
      try {
        const res = await updateSettingGroupQualityAssessment(
          dataStandard[index]?.id,
          paramAssessmentUpdate
        );
        const { data } = res.data;
        setDataStandard((prevItems: any) => {
          prevItems[index] = {
            ...prevItems[index],
            id: data?.id,
            mooc_setting_quality_assessment_field: [
              ...data?.mooc_setting_quality_assessment_field,
            ],
          };
          return prevItems;
        });
        handleNotice("success", "Lưu thông tin thành công");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleAddList = () => {
    setDataStandard([
      ...dataStandard,
      { title: "", mooc_setting_quality_assessment_field: [] },
    ]);
    setIndexEdit([...indexEdit, dataStandard?.length]);
  };
  const handleAddItemForm = (index: number) => {
    dataStandard[index].mooc_setting_quality_assessment_field.push({
      title: "",
    });
    setDataStandard([...dataStandard]);
  };
  const handleDelete = async (index: number, id: number) => {
    if (id) {
      try {
        await deleteQualityAssessmentGroup(`${dataStandard[index]?.id}`);
        dataStandard.splice(index, 1);
        setDataStandard([...dataStandard]);
        handleNotice("success", "Xóa tiêu chí thành công");
      } catch (error) {
        console.log(error);
      }
    } else {
      dataStandard.splice(index, 1);
      setDataStandard([...dataStandard]);
    }
  };

  const handleCancel = (index: number) => {
    setIndexEdit(indexEdit.filter((item: number) => item !== index));
    handleRefresh();
    if (!dataStandard[index]?.id) {
      dataStandard.splice(index, 1);
      setDataStandard([...dataStandard]);
    }
  };
  const CommonFormStandard = ({ value, index }: any) => {
    return (
      <Card className="w-full lg:w-2/3 m-auto mt-6" key={index}>
        <Flex className="border-b pb-2" justify="space-between">
          <Flex align="center" className="w-full">
            <HolderOutlined style={{ fontSize: "40px", color: "#91D5FF" }} />
            {indexEdit.includes(index) && !isView ? (
              <Input
                defaultValue={value?.title}
                placeholder="Nhập nội dung tiêu đề"
                onChange={(e) => handleChange(e, index)}
              />
            ) : (
              <Title level={5} className="px-2">
                {value?.title}
              </Title>
            )}
          </Flex>
          {!indexEdit.includes(index) && !isView && (
            <Flex>
              <Tooltip title="Xóa">
                <Button
                  className="mr-2"
                  onClick={() => handleDelete(index, value?.id)}
                >
                  <DeleteOutlined />
                </Button>{" "}
              </Tooltip>
              <Tooltip title="Chỉnh Sửa">
                <Button onClick={() => setIndexEdit([...indexEdit, index])}>
                  <EditOutlined />
                </Button>
              </Tooltip>
            </Flex>
          )}
        </Flex>
        <div className="pl-12 border-b">
          {value?.mooc_setting_quality_assessment_field?.map(
            (formItem: any, indexItem: number) => (
              <div className="p-4 pr-0 border-l border-b" key={indexItem}>
                {indexEdit.includes(index) && !isView ? (
                  <Input
                    defaultValue={formItem.title}
                    placeholder="Nhập nội dung tiêu chí"
                    onChange={(e) => handleChangeItem(index, indexItem, e)}
                  />
                ) : (
                  <Text>{formItem.title}</Text>
                )}
              </div>
            )
          )}

          {indexEdit.includes(index) && !isView && (
            <div
              className="text-[#1677FF] py-4 border-l pl-4 cursor-pointer"
              onClick={() => handleAddItemForm(index)}
            >
              <PlusOutlined
                style={{ color: "#1677FF", paddingRight: "12px" }}
              />
              Thêm tiêu chí
            </div>
          )}
        </div>
        {indexEdit.includes(index) && !isView && (
          <Flex className="pt-4" justify="end">
            <Button className="mr-2" onClick={() => handleCancel(index)}>
              <CloseOutlined className="pr-2" />
              Huỷ
            </Button>
            <Button type="primary" onClick={() => handleSave(index)}>
              <FilePdfOutlined className="pr-2" />
              Lưu
            </Button>
          </Flex>
        )}
      </Card>
    );
  };

  return (
    <Flex wrap="wrap">
      <Card className="w-full lg:w-2/3 m-auto mt-12">
        <div className="pb-3">
          <Flex align="center">
            <Title level={4} className="pr-4">
              Cài đặt kiểm soát chất lượng khoá học
            </Title>
            {isOpen ? (
              <Badge status="success" text="Đang bật" className="pb-2" />
            ) : (
              <Badge status="default" text="Đang tắt" className="pb-2" />
            )}
          </Flex>
          <Text className="text-[#00000073]">
            {isOpen
              ? "Chi tiết danh sách tiêu chí đánh giá theo danh mục"
              : `Vui lòng chọn “Bật chế độ" để sử dụng tính năng`}
          </Text>
        </div>
      </Card>
      {isOpen &&
        dataStandard?.map((value: any, index: number) => (
          <CommonFormStandard value={value} index={index} />
        ))}
      {isOpen && !isView && (
        <Button
          type="primary"
          className="my-4 w-full lg:w-2/3 m-auto"
          onClick={handleAddList}
        >
          <PlusOutlined /> Thêm danh mục
        </Button>
      )}
    </Flex>
  );
};

export default SettingStandard;
