import {
  BarChartOutlined,
  CopyOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Flex, Progress, Tooltip, Typography } from "antd";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

const { Title, Text } = Typography;

interface IProps {
  maxTimeData: any;
}
const MaxTimeVideo = ({ maxTimeData }: IProps) => {
  return (
    <div>
      <Flex className="mt-6 mb-[1rem]">
        <Text className="text-[1rem] pr-3">
          Tiêu chỉ đánh giá thời gian tối đa video buổi học
        </Text>
        <Tooltip
          title={
            <div>
              Tiêu chí được thiết lập tại{" "}
              <Link to={"/"} className="text-[#1890FF]">
                Cài đặt kiểm soát chất lượng tiết học video
              </Link>{" "}
            </div>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Flex>
      <Flex justify="space-between" className="flex-wrap lg:!flex-nowrap">
        <div className="w-full lg:w-1/3 lg:mr-2 mb-6 lg:mb-0 bg-white">
          <div className="p-[24px] text-[1rem] font-medium">
            Tiêu chí đánh giá
          </div>
          <Flex className="border-y">
            <div className="w-1/2 bg-[#FAFAFA] p-4 font-medium">
              Thời lượng tiêu chuẩn
            </div>
            <Flex className="w-1/2 p-4" justify="space-between" align="center">
              <Text>{maxTimeData?.setting}</Text>
              <Tooltip title="Độ dài tối đa của một video">
                <InfoCircleOutlined />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex className="pt-6" justify="center">
            <Progress
              strokeLinecap="butt"
              type="dashboard"
              percent={(maxTimeData?.wrong/maxTimeData?.total) * 100}
              className=""
              gapDegree={180}
              status="exception"
              format={(percent) => (
                <div>
                  <div className="leading-0">
                    <Text className="leading-0 text-[2rem] font-medium">
                      {maxTimeData?.wrong}
                    </Text>
                    <Text className="leading-0 text-[1rem] font-medium">
                      /{maxTimeData?.total}
                    </Text>
                  </div>
                  <div className="leading-0">
                    <Text className="leading-0 text-[1rem] font-normal">
                      Video chưa đạt
                    </Text>
                    <Text className="leading-0 text-[1rem] font-normal">
                      /Tổng
                    </Text>
                  </div>
                </div>
              )}
              size={[200, 10]}
            />
          </Flex>
          <Flex className="border-y">
            <div className="w-1/2 bg-[#FAFAFA] p-4 font-medium">
              Kết quả đánh giá
            </div>
            <Flex className="w-1/2 p-4" justify="space-between">
              {maxTimeData?.wrong > 0 ? (
                <Text className="border border-[#FFA39E] text-[#F5222D] bg-[#FFF1F0] px-2 rounded-md">
                  Không đạt
                </Text>
              ) : (
                <Text className="border border-[#91D5FF] text-[#1677FF] bg-[#E6F7FF] px-2 rounded-md">
                  Đạt
                </Text>
              )}
            </Flex>
          </Flex>
        </div>
        <div className="w-full lg:w-2/3 lg:ml-2 bg-white">
          <div className="p-[24px] text-[1rem] font-medium">
            Thống kê chi tiết ( Tổng: {maxTimeData?.total} video )
          </div>
          <div>
            <Flex className="bg-[#FAFAFA] py-4 border-t">
              <div className="px-4 font-medium w-2/5">
                Danh sách không đạt ({maxTimeData?.wrong}/{maxTimeData?.total})
              </div>
              <div className="px-4 font-medium w-1/5 border-x">
                Thời lượng video
              </div>
              <div className="px-4 font-medium w-2/5">Đường dẫn</div>
            </Flex>
            <div className="overflow-auto max-h-[265px]">
              {isEmpty(maxTimeData?.details) ? (
                <div className="text-center pt-8">
                  <BarChartOutlined className="text-[2rem] text-[#D9D9D9] p-4" />{" "}
                  <br />
                  <Text className="text-[#D9D9D9] text-[1rem] font-medium">
                    Không có video <br />
                    Các video đều đạt tiêu chí
                  </Text>
                </div>
              ) : (
                maxTimeData?.details?.map(
                  (value: { filename: string; time: any; file: string }) => (
                    <Flex className="py-4 border-t">
                      <div className="px-4 w-2/5">{value?.filename}</div>
                      <div className="px-4 w-1/5 text-[#FF4D4F]">
                        {value?.time ? value?.time : ""}
                      </div>
                      <Text
                        className="px-4 w-2/5 text-[#1890FF] cursor-pointer"
                        copyable={{
                          icon: [<CopyOutlined style={{ marginRight: 8 }} />],
                          text: value?.file,
                        }}
                      >
                        <span>Sao chép đường dẫn</span>
                      </Text>
                    </Flex>
                  )
                )
              )}
            </div>
          </div>
        </div>
      </Flex>
    </div>
  );
};

export default MaxTimeVideo;
