import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import "./index.scss";
import { Layout, Tabs, TabsProps, Form } from "antd";
import { useParams } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import ResultShot from "../result-shot/index";
import FilterChart from "../filter-chart/FilterTable";
import PageHeader from "../../../../../components/page-header/PageHeader";
import {
  getSurveyById,
  getResult,
} from "../../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { isEmpty } from "lodash";
import { DataResult } from "../../../../../types/survey-managment/listSurvey";
import HighCharts from "../high-chart/Highcharts";
import htmlToImage from "html-to-image";
import { toPng, toJpeg } from "html-to-image";
import { getLookupMoocSurveySection } from "../../../../../service/survey-managment/mooc-survey-question/mooc-survey-question-api";
import { FilterResultChart } from "../../../../../types/survey-managment/listSurvey";

const { Content } = Layout;
const imgPath = "/images/";
type ResultChartProps = {
  refChild?: any;
};
const ResultChart: FunctionComponent<ResultChartProps> = ({ refChild }) => {
  const location = useLocation();
  const [dataFilter, setDataFilter] = useState<FilterResultChart>({
    repDate: null,
    typeQuestion: null,
    nameQuestion: null,
  });
  const refParent = useRef<HTMLDivElement>(null);
  console.log(refChild);
  const [params, setParams] = useSearchParams();
  const paramsId = useParams();
  const [dataResult, setDataResult] = useState<DataResult[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [checkResult, setCheckResult] = useState<boolean>(false);
  const [form] = Form.useForm();
  const getResultSurvey = async () => {
    const dataFilterResult = {
      id: Number(paramsId.id),
      repDateFrom: !isEmpty(dataFilter.repDate?.[0])
        ? new Date(dataFilter.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(dataFilter.repDate?.[1])
        ? new Date(dataFilter.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(dataFilter.typeQuestion)
        ? dataFilter.typeQuestion
        : null,
      nameQuestion: !isEmpty(dataFilter.nameQuestion)
        ? dataFilter.nameQuestion
        : null,
    };
    await getResult(dataFilterResult).then((res) => {
      const { data } = res.data.data;
      const dataArray = Array.isArray(data) ? data : [data];
      const dataResultFormat = dataArray.map((item) => {
        // Ensure answerOther exists before accessing its length
        if (item.answerOther?.length > 0) {
          item.questionData = [
            ...item.questionData,
            ["Khác", item.answerOther.length],
          ];
        }
        // Return the item to make sure map creates a new array of items
        return item;
      });
      console.log(dataResult);
      setDataResult(dataResultFormat);
      if (data.length > 0) {
        setCheckResult(false);
      } else {
        setCheckResult(true);
      }
    });
  };

  useEffect(() => {
    getResultSurvey();
  }, []);
  const ref = useRef<HTMLDivElement>(null); // Sử dụng useRef với kiểu HTMLDivElement
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const onChartAdded = () => {
    const formDetail = document.getElementById("form-detail-chart");
    console.log(formDetail);
    if (formDetail && ref.current) {
      const chartHeight = ref.current.clientHeight; // Sử dụng kích thước của div chứa biểu đồ
      console.log(chartHeight);
      formDetail.style.minHeight = 1000 + "px";
    }
  };
  const handleSubmitSearch = () => {
    // if (searchValue === "") {
    //   setPageNumber(1);
    // }
    getResultSurvey();
  };
  const handleChangeFilter = (value: any, name: string) => {
    setDataFilter({ ...dataFilter, [name]: value });
  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };
  const handleOpenCreate = () => {
    setIsOpened(true);
  };
  const clearFilter = () => {
    form.resetFields();
    setDataFilter({
      repDate: null,
      typeQuestion: null,
      nameQuestion: null,
    });
  };
  return (
    <div className="survey-managment-synthesis">
      <Layout className="page-header-group">
        <Form.Provider>
          <div
            className={`page-header-group--layout ${
              showFilter ? "open-filter" : ""
            }`}
          >
            <div className="table-header--wrapper">
              <FilterChart
                refImage={refParent.current}
                handleOpen={handleOpenCreate}
                searchValue={searchValue}
                filterData={dataFilter}
                setFilterData={handleChangeFilter}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
                clearFilter={clearFilter}
                form={form}
                dataExport={dataResult}
              />
            </div>
          </div>
          <div className="list-chart" ref={refParent}>
            {dataResult &&
              dataResult.map((data, index) => {
                if (data?.type !== 8) {
                  const optionstest = {
                    chart: {
                      type: "pie", // Loại biểu đồ hình tròn
                    },
                    title: {
                      text: null,
                    },
                    // series: [{
                    //   data: [1, 2, 3, 4, 5]
                    // }]
                    series: [
                      {
                        name: "Dữ liệu", // Tên của loại dữ liệu
                        data: data?.questionData,
                      },
                    ],
                  };
                  return (
                    <div>
                      <HighCharts
                        key={index}
                        title={data?.question}
                        options={optionstest}
                        onChartAdded={onChartAdded}
                        numberQuestion={data?.ordernumber}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ResultShot
                      key={index}
                      numberQuestion={data?.ordernumber}
                      title={data?.question}
                      data={data?.answerText}
                    />
                  );
                }
              })}
          </div>
          {checkResult ? (
            <span>
              <p>
                <img
                  className="image-empty-data"
                  src="/images/empty-img-gray.png"
                  alt="empty-img"
                ></img>
              </p>
              <p className="nodata-text">Không thể tổng hợp dữ liệu</p>
            </span>
          ) : (
            <></>
          )}
        </Form.Provider>
      </Layout>
    </div>
  );
};

export default ResultChart;
