import { Col, Empty, Row, Select, Spin } from "antd";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import LineChart from "../../../../../../components/types-of-charts/LineChart";
import { staticReportStore } from "../../../../../../stores/stores";
import {
  BarStackedChartProps,
  LineChartProps,
  PieChart,
} from "../../../../../../types/static-report";
import { chartContentTypes } from "../../../../../../utils/staticReport";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";

type Props = {
  loading: Record<string, boolean>;
  staticDownloadUnit: LineChartProps[];
  staticViewUnit: LineChartProps[];
  staticShareUnit: LineChartProps[];
  searchHistory: BarStackedChartProps[];
  searchKeywordCount: PieChart[];
};

const UserBehaviorChartTab = React.memo((props: Props) => {
  const {
    loading,
    staticDownloadUnit,
    staticViewUnit,
    staticShareUnit,
    searchHistory,
    searchKeywordCount,
  } = props;
  const { setFilterValues } = staticReportStore();

  const { tab, setHandleExportAllImagesAsZip } = staticReportStore();
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();
  useEffect(() => {
    setHandleExportAllImagesAsZip(handleExportAllImagesAsZip);
  }, [tab]);
  const handleParamsChange = (
    value: string,
    setFilterValues: Function,
    courseTypeKey: string,
    moduleGroupKey: string
  ) => {
    let courseStructureType = "";
    let moduleGroup = null;

    switch (value) {
      case "5":
        courseStructureType = "sequence";
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = "course";
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = "unit";
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      [courseTypeKey]: courseStructureType,
      [moduleGroupKey]: moduleGroup,
    });
  };
  return (
    <div>
      <>
        <div className="chart-title">Thống kê số lượng tìm kiếm</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tìm kiếm theo từ khoá"
              content={
                <Spin spinning={loading.searchKeywordCount}>
                  {searchKeywordCount.length > 0 ? (
                    <BarChart
                      data={searchKeywordCount}
                      colors={["#69C0FF"]}
                      ref={createChartRef("searchKeywordCount")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "searchKeywordCourseStructureType",
                        "searchKeywordModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("searchKeywordCount", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("searchKeywordCount", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian"
              content={
                <Spin spinning={loading.searchHistory}>
                  {searchHistory.length > 0 ? (
                    <AreaStackedChart
                      data={searchHistory}
                      colors={[
                        "#69C0FF",
                        "#5CDBD3",
                        "#FFC069",
                        "#FF7875",
                        "#B37FEB",
                      ]}
                      yAxisTitle="Số lượng tìm kiếm"
                      ref={createChartRef("searchHistory")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () => handleExportImage("searchHistory", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("searchHistory", "application/pdf"),
                },
              ]}
            />
          </Col>
        </Row>
      </>

      <>
        <div className="chart-title">Thống kê số lượng tải về</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng tải về theo thời gian"
              content={
                <Spin spinning={loading.staticDownloadUnit}>
                  {staticDownloadUnit.length > 0 ? (
                    <LineChart
                      data={staticDownloadUnit}
                      lineColor="#FF7875"
                      lineName="Lượt tải về"
                      ref={createChartRef("staticDownloadUnit")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "downloadCourseStructureType",
                        "downloadModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("staticDownloadUnit", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("staticDownloadUnit", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng xem theo thời gian"
              content={
                <Spin spinning={loading.staticViewUnit}>
                  {staticViewUnit.length > 0 ? (
                    <LineChart
                      data={staticViewUnit}
                      lineColor="#69C0FF"
                      lineName="Lượt xem"
                      ref={createChartRef("staticViewUnit")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "viewCourseStructureType",
                        "viewModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("staticViewUnit", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("staticViewUnit", "application/pdf"),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng chia sẻ theo thời gian"
              content={
                <Spin spinning={loading.staticShareUnit}>
                  {staticShareUnit.length > 0 ? (
                    <LineChart
                      data={staticShareUnit}
                      lineColor="#5CDBD3"
                      lineName="Lượt chia sẻ"
                      ref={createChartRef("staticShareUnit")}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "shareCourseStructureType",
                        "shareModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "Hình ảnh (PNG)",
                  action: () =>
                    handleExportImage("staticShareUnit", "image/png"),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportImage("staticShareUnit", "application/pdf"),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    </div>
  );
});

export default UserBehaviorChartTab;
