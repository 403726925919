import { DownOutlined, SearchOutlined, PlusOutlined, FilterOutlined, DeleteOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Button, Typography, Form, DatePicker, FormInstance } from 'antd';
import React, { ChangeEvent, FunctionComponent, useState, KeyboardEvent } from 'react'
import FormItemDropdown from '../../../../../components/form-dropdown/FormItemDropdown';
import FormItemInput from '../../../../../components/form-input/FormInput';
import PageHeader from '../../../../../components/page-header/PageHeader';

import { FilterDateSubmitSurvey } from '../../../../../types/survey-browse';
type PageHeaderType = {
    title?: string | null,
    activeTabKey: string,
    filterData: FilterDateSubmitSurvey,
    searchValue: string,
    handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
    setFilterData: (dateString: string[], planCreationDate: string) => void,
    clearFilter: () => void,
    form: FormInstance,
    handleSubmitSearch: () => void,
    multipleApprove: () => void,
    handleOpenManyApprovalsSurvey: () => void,
    handleSearchEnter: (e: KeyboardEvent<HTMLSpanElement>) => void,
};

const FilterBrowseSurvey: FunctionComponent<PageHeaderType> =
    ({ title,
        activeTabKey,
        filterData,
        searchValue,
        handleChangeInput,
        setFilterData,
        clearFilter,
        form,
        handleSubmitSearch,
        multipleApprove,
        handleOpenManyApprovalsSurvey,
        handleSearchEnter,
    }) => {
        const { RangePicker } = DatePicker;
        const [showFilter, setShowFilter] = useState(false)
        const openFilter = () => {
            setShowFilter(!showFilter)
        }
        return (
            <Layout className="page-header-group">
                <div
                    className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}
                >
                    <div className="table-header--wrapper">
                        <div className="table-header--left">
                            <PageHeader title={title} />
                        </div>
                        <div className="heading-extra">
                            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
                                <Col
                                    md={{ span: 5 }}
                                    lg={{ span: 5 }}
                                    xl={{ span: 5 }}
                                    xs={{ span: 7 }}
                                >
                                    <div className="heading-filter">
                                        <Button className="filter-button" onClick={openFilter}>
                                            <Typography.Text>Bộ lọc</Typography.Text>
                                            <DownOutlined />
                                        </Button>
                                    </div>
                                </Col>
                                <Col
                                    md={{ span: 10 }}
                                    lg={{ span: 11 }}
                                    xl={{ span: 11 }}
                                    xs={{ span: 17 }}
                                >
                                    <div className="heading-search">
                                        <FormItemInput
                                            placeholder="Nhập từ khoá cần tìm"
                                            value={searchValue}
                                            onChange={handleChangeInput}
                                            onKeyPress={handleSearchEnter}
                                            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    md={{ span: 9 }}
                                    lg={{ span: 8 }}
                                    xl={{ span: 8 }}
                                    xs={{ span: 21 }}
                                >
                                    <div className="heading-resigter">
                                        <Button onClick={handleOpenManyApprovalsSurvey} className="btn btn-primary">
                                            <Typography.Text onClick={multipleApprove}>Phê duyệt nhiều khảo sát</Typography.Text>
                                        </Button>
                                    </div>
                                </Col>

                            </Row>
                        </div>
                    </div>

                    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
                        <Form form={form} name="filterForm" >
                            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} justify="end">
                                <Col
                                    xl={{ span: 7 }}
                                    md={{ span: 5 }}
                                    sm={{ span: 5 }}
                                    xs={{ span: 24 }}
                                >
                                    <Form.Item name="createDate" className="">
                                        <>
                                            {/* <Typography.Title level={5} className='label-range-picker'>Ngày gửi phê duyệt</Typography.Title> */}
                                            <RangePicker format="YYYY-MM-DD"
                                                placeholder={["Gửi từ ngày", "đến ngày"]}
                                                value={filterData.submissionDate}
                                                onChange={(_value, dateString: any) => {
                                                    setFilterData(dateString, "submissionDate");
                                                }}
                                            />
                                        </>
                                    </Form.Item>
                                </Col>
                                {
                                    activeTabKey === "1" ? null : (
                                        <Col
                                            xl={{ span: 7 }}
                                            md={{ span: 5 }}
                                            sm={{ span: 5 }}
                                            xs={{ span: 24 }}
                                        >
                                            <Form.Item name="publicDate" className="">
                                                <>
                                                    {/* <Typography.Title level={5} className='label-range-picker'>Ngày duyệt</Typography.Title> */}
                                                    <RangePicker placeholder={["Duyệt từ ngày", "đến ngày"]}
                                                        format="YYYY-MM-DD"
                                                        value={filterData.approvalDate}
                                                        onChange={(_value, dateString: any) => {
                                                            setFilterData(dateString, "approvalDate");
                                                        }}
                                                    />
                                                </>
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                                <Col
                                    xl={{ span: 6 }}
                                    md={{ span: 9 }}
                                    sm={{ span: 9 }}
                                    xs={{ span: 24 }}
                                >
                                    <div className="group-btn--filter">
                                        <Button
                                            className="btn btn-primary filter-btn"
                                            onClick={handleSubmitSearch}
                                        >
                                            <FilterOutlined />
                                            <Typography.Text>Lọc</Typography.Text>
                                        </Button>
                                        <Button className="btn btn-outlined clear-filter-btn" onClick={clearFilter}>
                                            <DeleteOutlined />
                                            <Typography.Text>Xóa tất cả</Typography.Text>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Layout>
        )
    }

export default FilterBrowseSurvey