import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Layout,
  Row,
  Typography,
} from "antd";
import {
  ChangeEvent,
  FunctionComponent,
  useState,
  KeyboardEvent,
  useEffect,
} from "react";
import PageHeader from "../../../../../components/page-header/PageHeader";
import {
  DeleteOutlined,
  DownOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { FormDataFilter } from "../../../../../types/course-plan-managment/mooc-approval";

type FilterPlanningApprovalProps = {
  form: FormInstance;
  searchValue: string;
  setSearchValue: (value: string) => void;
  handleSearch: () => void;
  handleSearchEnter: (e: KeyboardEvent<HTMLSpanElement>) => void;
  activeTabKey: string;
  clearFilter: () => void;
  dataFormFilter: FormDataFilter;
  setDataFormFilter: (dateString: string[], planCreationDate: string) => void;
  multipleApprove: () => void;
  handleOpenManyApprovalsSurvey: () => void;
};

const FilterPlanningApproval: FunctionComponent<
  FilterPlanningApprovalProps
> = ({
  form,
  searchValue,
  setSearchValue,
  handleSearch,
  handleSearchEnter,
  activeTabKey,
  clearFilter,
  dataFormFilter,
  setDataFormFilter,
  multipleApprove,
  handleOpenManyApprovalsSurvey,
}) => {
    const [showFilter, setShowFilter] = useState(false);
    const { RangePicker } = DatePicker;

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);
    };
    const openFilter = () => {
      setShowFilter(!showFilter);
    };

    return (
      <Layout className="page-header-group ">
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title="Danh sách Duyệt kế hoạch khóa học" />
            </div>
            <div className="heading-extra">
              <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
                <Col
                  md={{ span: 5 }}
                  lg={{ span: 5 }}
                  xl={{ span: 5 }}
                  xs={{ span: 7 }}
                >
                  <div className="heading-filter">
                    <Button className="filter-button" onClick={openFilter}>
                      <Typography.Text>Bộ lọc</Typography.Text>
                      <DownOutlined />
                    </Button>
                  </div>
                </Col>
                <Col
                  md={{ span: 10 }}
                  lg={{ span: 11 }}
                  xl={{ span: 11 }}
                  xs={{ span: 17 }}
                >
                  <div className="heading-search">
                    <FormItemInput
                      placeholder="Nhập từ khoá cần tìm"
                      value={searchValue}
                      onChange={handleChangeInput}
                      onKeyPress={handleSearchEnter}
                      afterPrefixIcon={<SearchOutlined onClick={handleSearch} />}
                    />
                  </div>
                </Col>
                <Col
                  md={{ span: 9 }}
                  lg={{ span: 8 }}
                  xl={{ span: 8 }}
                  xs={{ span: 21 }}
                >
                  <div className="heading-resigter">
                    <Button
                      className="btn btn-primary"
                      onClick={handleOpenManyApprovalsSurvey}
                    >
                      <Typography.Text onClick={multipleApprove}>
                        Duyệt nhiều kế hoạch
                      </Typography.Text>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
            <Form form={form} name="filterForm">
              <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} justify="end">
                <Col
                  xl={{ span: 7 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5} className="label-range-picker">
                    Ngày gửi duyệt
                  </Typography.Title>
                  <Form.Item name="submissionDate">
                    <RangePicker
                      format="DD-MM-YYYY"
                      value={dataFormFilter.submissionDate}
                      placeholder={["Gửi từ ngày", "đến ngày"]}
                      onChange={(_value, dateString: any) => {
                        setDataFormFilter(dateString, "submissionDate");
                      }}
                    />
                  </Form.Item>
                </Col>
                {activeTabKey === "2" && (
                  <Col
                    xl={{ span: 7 }}
                    md={{ span: 5 }}
                    sm={{ span: 5 }}
                    xs={{ span: 24 }}
                  >
                    <Typography.Title level={5} className="label-range-picker">
                      Ngày duyệt
                    </Typography.Title>
                    <Form.Item name="approvalDate">
                      <RangePicker
                        format="DD-MM-YYYY"
                        value={dataFormFilter.approvalDate}
                        placeholder={["Duyệt từ ngày", "đến ngày"]}
                        onChange={(_value, dateString: any) => {
                          setDataFormFilter(dateString, "approvalDate");
                        }}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col
                  xl={{ span: 6 }}
                  md={{ span: 9 }}
                  sm={{ span: 9 }}
                  xs={{ span: 24 }}
                  style={{ marginTop: "30px" }}
                >
                  <div className="group-btn--filter">
                    <Button
                      className="btn btn-primary filter-btn"
                      onClick={handleSearch}
                    >
                      <FilterOutlined />
                      <Typography.Text>Lọc</Typography.Text>
                    </Button>
                    <Button
                      className="btn btn-outlined clear-filter-btn"
                      onClick={clearFilter}
                    >
                      <DeleteOutlined />
                      <Typography.Text>Xóa tất cả</Typography.Text>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Layout>
    );
  };
export default FilterPlanningApproval;
