import {DownloadOutlined} from "@ant-design/icons";
import {Button} from "antd";
import React, {ReactNode} from "react";
import './index.scss'

type Props = {
  tableName: string;
  content?: ReactNode;
};

const TableReportCard = (props: Props) => {
  const {tableName, content} = props;
  return (
    <div className="statistical-report-table-card">
      <div className="card-header">
        <div className="chart-name">{tableName}</div>
        <Button className="chart-action">
          <DownloadOutlined/>
        </Button>
      </div>
      <div className="hr-tag-item"></div>
      <div className="chart-content">{content}</div>
    </div>
  );
};

export default TableReportCard;
