import React, { useEffect, useState } from "react";
import { Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAddLesson } from "../../../../stores/stores";
import { QuestionData, QuestionItemDetail, } from "../../../../types/course-construction";
import { initialChooseTextQuestionValue } from "../../../../constants";
import { SortableList } from "../../../SortableList";
import QuestionTextItem from "./QuestionMultiItem";
import TextEditor from "../../../text-editor/TextEditor";
import { convertObjectToArray, findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import { renderQuestionDescriptionByType, renderQuestionNameByType } from "../../../../utils/functionUltils";

function ChooseMultiText({ data = {} as QuestionData }: QuestionItemDetail) {
  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);

  const [answers, setAnswers] = useState(convertObjectToArray(currentQuestionInfo, "questionName") || []);
  const [questionName, setQuestionName] = useState<string>("");

  const resetAnswers = () => {
    const newAnswers = convertObjectToArray(currentQuestionInfo, "questionName");
    return newAnswers;
  }

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = resetAnswers();
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setAnswers(distAnswer);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = resetAnswers();
      setAnswers(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  };

  const handleAddMoreChoose = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        value: "",
        key: findMaxIndexOfArray(answers) + 1,
        isTrue: false,
        point: 0,
        response: '',
      };
      const newList = resetAnswers();
      setAnswers(newList.concat([newItem]));
    } else setAnswers(initialChooseTextQuestionValue);
  };

  useEffect(() => {
    setCurrentQuestionInfo({
      ...answers,
      questionName: questionName,
    });
  }, [answers, questionName]);

  useEffect(() => {
    setQuestionName(question.question);

    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);
    } else {
      setAnswers([]);
    }
  }, [question, data.answers]);

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  const handleChangeChecked = (checked: boolean, id: number) => {
    const newList = resetAnswers();
    newList.forEach((item) => {
      if (item.id === id) item.isTrue = checked;
    });
    setAnswers(newList);
  };

  return (
    <section className='question-wrapper'>
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Text className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Text>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <div className='tree-course-construct list-answers choose-text'>
        <SortableList
          items={answers}
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <QuestionTextItem
                index={record.id}
                value={record.value}
                checked={record.isTrue}
                onChangeChecked={(checked: boolean) =>
                  handleChangeChecked(checked, record.id)
                }
                onChange={handleChangeText}
                onDelete={handleRemoveOneChoose}
              />
            </SortableList.Item>
          )}
        />
        <Button
          icon={<PlusOutlined />}
          className='btn-add-more mt-2'
          onClick={handleAddMoreChoose}
        >
          Thêm lựa chọn
        </Button>
      </div>
    </section>
  );
}

export default ChooseMultiText;
