import React, {useState} from 'react';
import {Col, Row, TableColumnsType, TableProps} from "antd";
import TableReportCard from "../../chart/table-report-card";
import TableData from "../../../../../../components/table-data/TableData";

const ResourceTable = () => {
  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")

  const tableAccessColumn: TableColumnsType<any> = [
    {
      title: "Trường liên kết",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Chủ trì",
      dataIndex: "guest",
      key: "guest"
    },
    {
      title: "Phối hợp",
      dataIndex: "freeUsers",
      key: "freeUsers"
    },
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total"
    }
  ]

  const tableColumn: TableColumnsType<any> = [
    {
      title: "Loại đánh giá",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Số lượng",
      dataIndex: "guest",
      key: "guest"
    },
  ]

  const tableTeachersAndStudentColumn: TableColumnsType<any> = [
    {
      title: "Khoa",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity"
    },
  ]

  const tableQuantityDataColumn: TableColumnsType<any> = [
    {
      title: "Định dạng tài liệu",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity"
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê khoá học
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng khoá học theo phân công"
                           content={<>
                             <TableData dataColumns={tableAccessColumn} handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng khóa học theo khoa"
                           content={<>
                             <TableData dataColumns={tableTeachersAndStudentColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng bài giảng theo khoa"
                           content={<>
                             <TableData dataColumns={tableTeachersAndStudentColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng học liệu
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng bài kiểm tra" content={<>
            <TableData dataColumns={tableColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng tài liệu tham khảo" content={<>
            <TableData dataColumns={tableQuantityDataColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng Scorm & xAPI" content={<>
            <TableData dataColumns={tableQuantityDataColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng học liệu đa phương tiện" content={<>
            <TableData dataColumns={tableQuantityDataColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default ResourceTable;
