import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  Typography,
  Card,
  Row,
  Col,
  DatePicker,
  TableProps,
} from "antd";
import React, { ChangeEvent, useEffect, useState, useRef } from "react";
import FilterSurvey from "./components/filter-survey/FilterSurvey";
import moment from "moment";
import { SurveyType } from "../../../types/survey-managment/survey";
import {
  EditOutlined,
  CopyOutlined,
  SendOutlined,
  CloudSyncOutlined,
  HistoryOutlined,
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
  CloseOutlined,
  SaveOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { isEmpty, range } from "lodash";
import { FilterData } from "../../../types/course-construction";
import {
  createSurvey,
  postSurveySearch,
  surveyDelete,
} from "../../../service/survey-managment/list-survey";
import DrawerCustom from "../../../components/drawer/Drawer";
import { getCurrentPageFromLink } from "../../../utils/getPageFromLink";
import { getUniversity } from "../../../service/common";
import {
  getIndustry,
  getIndustryGroup,
} from "../../../service/course-construct";
import { getListSurvey } from "../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { HelpService } from "../../../service/helper.service";
import { get } from "lodash";
import { routesConfig } from "../../../config/routes";
import { DataType } from "../../../types/survey-managment/listSurvey";
import { useNavigate, Link } from "react-router-dom";
import ApproveSurvey from "./components/approve-survey/approveSurvey";
import { FilterResultChart } from "../../../types/survey-managment/listSurvey";
import { getQuestionById } from "../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { DataQuestion } from "../../../types/survey-managment/listSurvey";
import FormItemDropdown from "../../../components/form-dropdown/FormItemDropdown";
import * as FileSaver from "file-saver";
//import * as XLSX from "xlsx";
import { getResult } from "../../../service/survey-managment/survey-synthesis/survey-systhesis";
import ResultChartRender from "./components/result-chart-render";
import domToImage from "dom-to-image";
import { useAuthStore, useSurveyRespond } from "../../../stores/stores";
import XLSX from "xlsx-js-style";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import TableData from "./components/table-data/TableData";
type ContentConstructionProps = {
  title?: string;
};
type FilterImage = {
  id: number;
  repDateFrom: string;
  repDateTo: string;
  typeQuestion: number[] | null | undefined;
  nameQuestion: number[] | null | undefined;
};
const ListSurveyResult = (props: ContentConstructionProps) => {
  const helpService = new HelpService();
  const [loading, setLoading] = useState<boolean>(true);
  const { title } = props;
  const [listSurvey, setListSurvey] = useState<SurveyType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [form] = Form.useForm();
  const [browseData, setBrowseData] = useState<DataType>();
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const { RangePicker } = DatePicker;
  const [filterData, setFilterData] = useState<FilterData>({
    createDate: null,
    publicDate: null,
    courseType: null,
    status: null,
  });
  const [filterResult, setFilterResult] = useState<FilterResultChart>({
    repDate: null,
    typeQuestion: null,
    nameQuestion: null,
  });
  const navigate = useNavigate();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataCollaborator: [],

    dataTypes: [
      {
        id: 1,
        name: "Nhiều lựa chọn văn bản",
      },
      {
        id: 2,
        name: "Nhiều câu lựa chọn ảnh",
      },
      {
        id: 3,
        name: "Lựa chọn đúng sai",
      },
      {
        id: 4,
        name: "Lựa chọn dropdown",
      },
      {
        id: 5,
        name: "Nhiều trả lời văn bản",
      },
      {
        id: 6,
        name: "Nhiều trả lời ảnh",
      },
      {
        id: 7,
        name: "Câu hỏi kết nối",
      },
      {
        id: 8,
        name: "Câu hỏi trả lời ngắn",
      },
    ],
  });
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const [dataQuestions, setDataQuestions] = useState<DataQuestion[]>([]);
  const [dataResult, setDataResult] = useState([]);
  const [filterImage, setFilterImage] = useState<FilterImage>();
  const [isShowModalAddSurveyDescription, setIsShowModalAddSurveyDescription] =
    useState(false);
  const [idSurvey, setIdSurvey] = useState(0);
  const [repDateFrom, setRepDateFrom] = useState("");
  const [repDateTo, setRepDateTo] = useState("");
  const [typeQuestion, setTypeQuestion] = useState([]);
  const [nameQuestion, setNameQuestion] = useState([]);
  const childRef = useRef(null);
  const handleClose = () => {
    setIsOpened(false);
    form.resetFields();
  };
  const { setSurveyData } = useSurveyRespond();
  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search);
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      setLoading(true);
      const fetchDataOptions = async () => {
        const response = await Promise.all([
          getUniversity(),
          getIndustry(),
          getIndustryGroup(),
        ]);
        const dataUniversity = response[0].data.data;
        const dataIndustry = response[1].data.data;
        const dataIndustryGroup = response[2].data.data;
        setDataOptions({
          ...dataOptions,
          dataIndustry: dataIndustry,
          dataUniversity: dataUniversity,
          dataIndustryGroup: dataIndustryGroup,
          dataCollaborator: dataUniversity,
        });
      };
      fetchDataOptions();
      setLoading(false);
    }
  }, []);

  const getListData = () => {
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      createdDateFrom: !isEmpty(filterData.createDate?.[0])
        ? new Date(filterData.createDate?.[0]).toISOString()
        : "",
      createdDateTo: !isEmpty(filterData.createDate?.[1])
        ? new Date(filterData.createDate?.[1]).toISOString()
        : "",
      publicDateFrom: !isEmpty(filterData.publicDate?.[0])
        ? new Date(filterData.publicDate?.[0]).toISOString()
        : "",
      publicDateTo: !isEmpty(filterData.publicDate?.[1])
        ? new Date(filterData.publicDate?.[1]).toISOString()
        : "",
      status: !isEmpty(filterData.status) ? filterData.status : null,
      type: !isEmpty(filterData.courseType) ? filterData.courseType : null,
      // sortField: fieldSortOrder,
      // sortOrder: statusOrder,
    };
    setLoading(true);
    getListSurvey(data)
      .then((res) => {
        const { pageNumber, pageSize, data, totalRecords } = res.data.data;
        setTotalRecords(totalRecords);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
        const result = data.map((x: any) => {
          return {
            id: x.id,
            surveyName: x.surveyName,
            surveyStatus: x.surveyStatus,
            couresType: x.couresType,
            surveyOpen: x.surveyOpen,
            surveyClosed: x.surveyClosed,
            replies: x.replies,
            surveyTheme: x.surveyTheme,
            universityName: x.universityName,
          };
        });
        setListSurvey(result);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [pageNumber, pageSize]);

  const formatDateV2 = (date: string) => {
    const dateObj = date.split("T")[0];
    const arrDate = dateObj.split("-");

    // Lấy ra ngày, tháng và năm
    const ngay = arrDate[2];
    const thang = arrDate[1]; // Lưu ý: getMonth() trả về index của tháng (từ 0 đến 11)
    const nam = arrDate[0];

    // Định dạng lại chuỗi ngày tháng năm
    const ngayThangNam = ngay + "/" + thang + "/" + nam;

    return ngayThangNam;
    //return date.split("T")[0];
    //return moment(date).format("DD/MM/yyyy");
  };

  const handleSubmitSearch = () => {
    getListData();
  };
  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      createDate: null,
      publicDate: null,
      courseType: null,
      status: null,
    });
    const data = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10,
    };
    setLoading(true);
    getListSurvey(data)
      .then((res) => {
        const { pageNumber, pageSize, data } = res.data.data;
        setTotalRecords(pageSize);
        setPageNumber(pageNumber);
        setPageSize(pageSize);
        const result = data.map((x: any) => {
          return {
            id: x.id,
            surveyName: x.surveyName,
            surveyStatus: x.surveyStatus,
            couresType: x.couresType,
            surveyOpen: x.surveyOpen,
            surveyClosed: x.surveyClosed,
            replies: x.replies,
            surveyTheme: x.surveyTheme,
            universityName: x.universityName,
          };
        });
        setListSurvey(result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
    getListData();
  };
  const handleReturnStatus = (statusCode: number) => {
    switch (statusCode) {
      case 5:
        return "Đã xuất bản";
      case 6:
        return "Kết thúc";
      default:
        return "";
    }
  };

  const handleOk = () => {
    setIsShowModalAddSurveyDescription(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setFilterResult({
      repDate: null,
      typeQuestion: null,
      nameQuestion: null,
    });
    console.log(filterResult);
    setIsShowModalAddSurveyDescription(false);
  };

  const options1: MenuProps["items"] = [
    {
      key: "2",
      label: "Danh sách tham gia",
      icon: <CopyOutlined />,
    },
    {
      key: "3",
      label: "Xem kết quả",
      icon: <SendOutlined />,
    },
    {
      key: "4",
      label: "Tải kết quả",
      icon: <CloudSyncOutlined />,

      onClick: () => handleClick,
    },
  ];

  const handleClick = () => {
    setIsShowModalAddSurveyDescription(true);
  };

  const handleReturnType = (typeCode: number) => {
    switch (typeCode) {
      case 1:
        return "Nhiều lựa chọn văn bản";
      case 2:
        return "Nhiều lựa chọn ảnh ";
      case 3:
        return "Lựa chọn đúng, sai ";
      case 4:
        return "Lựa chọn dropdown";
      case 5:
        return "Nhiều trả lời văn bản";
      case 6:
        return "Nhiều trả lời ảnh";
      case 7:
        return "Câu hỏi kết nối";
      case 8:
        return "Câu trả lời ngắn";
      default:
        return "";
    }
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };
  const handleExportExcel = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    console.log(filterResult);
    const dataFilterResult = {
      id: Number(idSurvey),
      repDateFrom: !isEmpty(filterResult.repDate?.[0])
        ? new Date(filterResult.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(filterResult.repDate?.[1])
        ? new Date(filterResult.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(filterResult.typeQuestion)
        ? filterResult.typeQuestion
        : null,
      nameQuestion: !isEmpty(filterResult.nameQuestion)
        ? filterResult.nameQuestion
        : null,
    };
    var test = [];
    await getResult(dataFilterResult).then((res) => {
      console.log(res.data);
      const dataExport = res.data.data.data.map((item: any) => {
        // Thêm thuộc tính 'key' với giá trị là 'id' vào mỗi phần tử
        return { ...item, key: item.questionId };
      });
      setTotalRecords(res.data.totalRecords);
      let dataFormat: any[] = [];
      const dataCSV = dataExport.map((item: any) => {
        // Check if answerOther exists and has elements
        if (item.answerOther && item.answerOther.length > 0) {
          // Iterate over each element of answerOther
          item.answerOther.forEach((answer: string) => {
            // Add each answerOther element to questionData
            item.questionData.push(["Khác (" + answer + " )", 1]);
          });
        }
        return item;
      });
      dataCSV.forEach((item: any, key: any) => {
        let isFirstQuestionShown: boolean = false;
        if (item.questionData) {
          item.questionData.forEach((answer: any, index: any) => {
            if (index === 0 && !isFirstQuestionShown) {
              dataFormat.push({
                STT: key + 1,
                "Câu hỏi": item.question,
                "Loại câu hỏi": handleReturnType(item.type),
                "Tổng số câu trả lời": item.totalAnswers,
                "Ngày trả lời": item.dateReply
                  ? formatDate(item.dateReply)
                  : "",
                "Đáp án": answer[0],
                "Số câu trả lời": answer[1],
                "Tỉ lệ":
                  ((Number(answer[1]) / item.totalAnswers) * 100).toFixed(2) +
                  "%",
              });
              isFirstQuestionShown = true;
            } else {
              dataFormat.push({
                STT: "",
                "Câu hỏi": "",
                "Loại câu hỏi": "",
                "Tổng số câu trả lời": "",
                "Ngày trả lời": "",
                "Đáp án": answer[0],
                "Số câu trả lời": answer[1],
                "Tỉ lệ":
                  ((Number(answer[1]) / item.totalAnswers) * 100).toFixed(2) +
                  "%",
              });
            }
          });
        }
        if (item.answerText) {
          item.answerText.forEach((answer: any, index: any) => {
            if (index === 0 && !isFirstQuestionShown) {
              dataFormat.push({
                STT: key + 1,
                "Câu hỏi": item.question,
                "Loại câu hỏi": handleReturnType(item.type),
                "Tổng số câu trả lời": item.total,
                "Ngày trả lời": item.dateReply
                  ? formatDate(item.dateReply)
                  : "",
                "Đáp án": answer,
                "Số câu trả lời": "",
                "Tỉ lệ": "",
              });
              isFirstQuestionShown = true;
            } else {
              dataFormat.push({
                STT: "",
                "Câu hỏi": "",
                "Loại câu hỏi": "",
                "Tổng số câu trả lời": "",
                "Ngày trả lời": "",
                "Đáp án": answer,
                "Số câu trả lời": "",
                "Tỉ lệ": "",
              });
            }
          });
        }
      });
      const wscols = [
        { wch: 10 }, // Độ rộng cột cho "STT"
        { wch: 40 }, // Độ rộng cột cho "Câu hỏi"
        { wch: 30 }, // Độ rộng cột cho "Loại câu hỏi"
        { wch: 20 }, // Độ rộng cột cho "Tổng số câu trả lời"
        { wch: 30 }, // Độ rộng cột cho "Ngày trả lời"
        { wch: 40 }, //Độ rộng cột cho "Đáp án"
        { wch: 20 }, //Số câu trả lời
      ];
      const headerStyle = {
        fill: {
          patternType: "solid",
          fgColor: { rgb: "ADD8E6" }, // Màu nền màu xanh
        },
        font: {
          bold: true, // Chữ in đậm
        },
        alignment: {
          horizontal: "center", // Căn giữa
        },
        border: {
          // Định dạng border
          top: { style: "thin", color: { rgb: "000000" } }, // Border trên
          bottom: { style: "thin", color: { rgb: "000000" } }, // Border dưới
          left: { style: "thin", color: { rgb: "000000" } }, // Border trái
          right: { style: "thin", color: { rgb: "000000" } }, // Border phải
        },
      };

      const ws = XLSX.utils.json_to_sheet(dataFormat);
      // Định nghĩa phong cách căn giữa
      const centerAlignment = {
        horizontal: "center",
        vertical: "center",
      };

      // Áp dụng căn giữa cho cột thứ 5
      if (ws["!ref"]) {
        const range = XLSX.utils.decode_range(ws["!ref"]);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cell_ref = XLSX.utils.encode_cell({ c: 4, r: R }); // Cột E là cột thứ 5, đánh số từ 0
          if (!ws[cell_ref]) continue;
          ws[cell_ref].s = ws[cell_ref].s || {};
          ws[cell_ref].s.alignment = centerAlignment;
        }
      }

      if (ws["!ref"]) {
        const range = XLSX.utils.decode_range(ws["!ref"]); // lấy phạm vi của bảng
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_col(C) + "1"; // tạo địa chỉ A1, B1, C1, v.v...
          if (!ws[address]) continue; // nếu ô không tồn tại, bỏ qua
          ws[address].s = headerStyle; // áp dụng định dạng
          console.log(ws[address].s);
        }
      }
      const borderStyle = {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      };

      // Áp dụng viền cho tất cả các ô
      if (ws["!ref"]) {
        const range = XLSX.utils.decode_range(ws["!ref"]);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          for (let C = range.s.c; C <= range.e.c; ++C) {
            const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
            if (!ws[cell_ref]) continue;
            ws[cell_ref].s = ws[cell_ref].s || {};
            ws[cell_ref].s.border = borderStyle;
          }
        }
      }

      ws["!cols"] = wscols;

      // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "data");
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "export" + fileExtension);
      // const data = new Blob([excelBuffer], { type: fileType });
      // FileSaver.saveAs(data, "export" + fileExtension);
    });
  };

  const handleExportCsv = async () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const dataFilterResult = {
      id: Number(idSurvey),
      repDateFrom: !isEmpty(filterResult.repDate?.[0])
        ? new Date(filterResult.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(filterResult.repDate?.[1])
        ? new Date(filterResult.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(filterResult.typeQuestion)
        ? filterResult.typeQuestion
        : null,
      nameQuestion: !isEmpty(filterResult.nameQuestion)
        ? filterResult.nameQuestion
        : null,
    };
    await getResult(dataFilterResult).then((res) => {
      const dataFormat = res.data.data.data.map((item: any) => {
        return {
          STT: item.ordernumber,
          "Câu hỏi": item.question,
          "Loại câu hỏi": handleReturnType(item.type),
          "Tổng số câu trả lời": item.totalAnswers || item.total,
          "Ngày trả lời": item.dateReply,
        };
      });
      const ws = XLSX.utils.json_to_sheet(dataFormat);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, "export" + ".csv");
    });
  };
  const handleExportImage = async () => {
    setFilterImage({
      id: Number(idSurvey),
      repDateFrom: !isEmpty(filterResult.repDate?.[0])
        ? new Date(filterResult.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(filterResult.repDate?.[1])
        ? new Date(filterResult.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(filterResult.typeQuestion)
        ? filterResult.typeQuestion
        : null,
      nameQuestion: !isEmpty(filterResult.nameQuestion)
        ? filterResult.nameQuestion
        : null,
    });
    console.log(childRef);
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);
    const refImage = childRef.current as HTMLDivElement | null;
    if (!refImage) {
      return;
    }

    // Tạo bản sao sâu của refImage
    const clonedRefImage = refImage.cloneNode(true) as HTMLDivElement;
    tempDiv.appendChild(clonedRefImage);
    console.log(clonedRefImage);
    const targetElement = clonedRefImage.querySelector(
      "#target-element"
    ) as HTMLDivElement | null;
    const layoutElement = clonedRefImage.querySelector(
      "#layout-element"
    ) as HTMLDivElement | null;
    console.log(targetElement);
    if (targetElement) {
      targetElement.style.display = "block";
    }
    if (layoutElement) {
      layoutElement.style.display = "block";
    }

    domToImage
      .toPng(tempDiv)
      .then((dataUrl: any) => {
        // Tạo liên kết để tải ảnh
        const link = document.createElement("a");
        link.download = "image.png";
        link.href = dataUrl;
        link.click();

        // Xóa phần tử tạm thời sau khi chuyển đổi
        document.body.removeChild(tempDiv);
      })
      .catch((error: any) => {
        console.error("Could not convert the element to an image", error);
        // Xóa phần tử tạm thời nếu có lỗi
        document.body.removeChild(tempDiv);
      });
  };
  // const handleExportPdf = async () => {
  //   setFilterImage({
  //     id: Number(idSurvey),
  //     repDateFrom: !isEmpty(filterResult.repDate?.[0])
  //       ? new Date(filterResult.repDate?.[0]).toISOString()
  //       : "",
  //     repDateTo: !isEmpty(filterResult.repDate?.[1])
  //       ? new Date(filterResult.repDate?.[1]).toISOString()
  //       : "",
  //     typeQuestion: !isEmpty(filterResult.typeQuestion)
  //       ? filterResult.typeQuestion
  //       : null,
  //     nameQuestion: !isEmpty(filterResult.nameQuestion)
  //       ? filterResult.nameQuestion
  //       : null,
  //   });
  //   console.log(childRef);
  //   const tempDiv = document.createElement("div");
  //   document.body.appendChild(tempDiv);
  //   const refImage = childRef.current as HTMLDivElement | null;
  //   if (!refImage) {
  //     return;
  //   }

  //   // Tạo bản sao sâu của refImage
  //   const clonedRefImage = refImage.cloneNode(true) as HTMLDivElement;
  //   tempDiv.appendChild(clonedRefImage);
  //   console.log(clonedRefImage);
  //   const targetElement = clonedRefImage.querySelector(
  //     "#target-element"
  //   ) as HTMLDivElement | null;

  //   console.log(targetElement);
  //   if (targetElement) {
  //     targetElement.style.display = "block";
  //   }
  //   const pdf = new jsPDF({
  //     orientation: "p",
  //     unit: "mm",
  //     format: "a4",
  //   });
  //   html2canvas(tempDiv).then((canvas) => {
  //     // Thêm ảnh vào trang PDF
  //     const width = pdf.internal.pageSize.getWidth();
  //     const height = (canvas.height * width) / canvas.width;
  //     const imgData = canvas.toDataURL("image/png");
  //     pdf.addImage(imgData, "PNG", 0, 0, width, height);
  //     // Tải xuống file PDF
  //     pdf.save("download.pdf");
  //     document.body.removeChild(tempDiv);
  //   });
  // };
  const handleExportPdf = async () => {
    setFilterImage({
      id: Number(idSurvey),
      repDateFrom: !isEmpty(filterResult.repDate?.[0])
        ? new Date(filterResult.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(filterResult.repDate?.[1])
        ? new Date(filterResult.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(filterResult.typeQuestion)
        ? filterResult.typeQuestion
        : null,
      nameQuestion: !isEmpty(filterResult.nameQuestion)
        ? filterResult.nameQuestion
        : null,
    });

    const refImage = childRef.current as HTMLDivElement | null;
    if (!refImage) {
      return;
    }

    // Tạo bản sao sâu của refImage
    const clonedRefImage = refImage.cloneNode(true) as HTMLDivElement;
    document.body.appendChild(clonedRefImage);
    const targetElement = clonedRefImage.querySelector(
      "#target-element"
    ) as HTMLDivElement | null;
    if (targetElement) {
      targetElement.style.display = "block";
    }

    // Tạo PDF
    const pdf = new jsPDF({
      orientation: "p",
      unit: "mm",
      format: "a4",
    });

    // Sử dụng html2canvas để chụp ảnh của phần tử
    html2canvas(clonedRefImage, { scrollY: -window.scrollY }).then((canvas) => {
      const imgWidth = 210; // Chiều rộng của trang A4 theo mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgData = canvas.toDataURL("image/png");

      let heightLeft = imgHeight;
      let position = 0;

      // Thêm từng phần của ảnh vào các trang PDF
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pdf.internal.pageSize.getHeight();
      }

      // Tải xuống file PDF
      pdf.save("download.pdf");
      document.body.removeChild(clonedRefImage);
    });
  };

  const handleClickSurvey = (
    event: any,
    id: any,
    typeSurvey: any,
    theme: any
  ) => {
    event.preventDefault();
    setSurveyData([]);
    navigate(`survey-detail/${id}/${typeSurvey}/${theme}`, {
      state: { mode: "view" },
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đợt khảo sát",
      width: 10,
      dataIndex: "surveyName",
      sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
      render: (_: any, record, index: number) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.surveyName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 6,
      dataIndex: "surveyStatus",
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.surveyStatus === 5
                    ? "#52C41A"
                    : record.surveyStatus === 6
                    ? "#FAAD14"
                    : "white",
              }}
            ></div>
            {handleReturnStatus(record?.surveyStatus)}
          </div>
        );
      },
    },
    {
      title: "Loại Khoá học",
      width: 7,
      dataIndex: "couresType",
      render: (_: any, record) => {
        const courseTypeLabel = () => {
          console.log("Hello", record.couresType);
          switch (record.couresType) {
            case 2:
              return (
                <div className="table-course-type-2">Hợp tác xây dựng</div>
              );
            case 1:
              return <div className="table-course-type-1">Tự triển khai</div>;
            default:
              return <div className="table-course-type-common">Dùng chung</div>;
          }
        };
        return <div className="table-course-type">{courseTypeLabel()}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      width: 5,
      dataIndex: "surveyOpen",
      render: (_: any, record) => {
        return (
          <>{record.surveyOpen ? formatDateV2(record.surveyOpen) : null}</>
        );
      },
    },
    {
      title: "Ngày kết thúc",
      width: 5,
      dataIndex: "surveyClosed",
      render: (_: any, record) => {
        return (
          <>{record.surveyClosed ? formatDateV2(record.surveyClosed) : null}</>
        );
      },
    },
    {
      title: "Lượt trả lời",
      width: 5,
      dataIndex: "replies",
      render: (_: any, record) => {
        return <>{record.replies}</>;
      },
    },
    {
      title: "",
      width: 3,
      fixed: "right",
      render: (_, record) => {
        console.log(record);
        const location = {
          id: `${record.id}`,
          typeSurvey: 0,
          themeSurvey: `${record.surveyTheme}`,
        };

        return (
          <>
            <Space>
              <Link
                className="action-table"
                to={`survey-detail/${location.id}/0`}
                state={{ mode: "view" }}
                onClick={(e) =>
                  handleClickSurvey(
                    e,
                    location.id,
                    location.typeSurvey,
                    location.themeSurvey
                  )
                }
              >
                <EyeOutlined />
              </Link>

              <Dropdown
                menu={{
                  items: options1,
                  onClick: ({ key }) => {
                    if (key === "2") {
                      navigate(`${routesConfig.detailSurvey}/${location.id}`, {
                        state: { record, mode: "view", tab: "1" },
                      });
                    }
                    if (key === "3") {
                      navigate(`${routesConfig.detailSurvey}/${location.id}`, {
                        state: { record, mode: "view", tab: "2" },
                      });
                    }
                    if (key === "4") {
                      setIsShowModalAddSurveyDescription(true);
                      setIdSurvey(Number(location.id));
                      const getQuestion = async (id: any) => {
                        await getQuestionById(id).then((res) => {
                          const { data } = res.data;
                          const dataFormat = [...data].map((item: any) => {
                            return {
                              id: item.id,
                              name: item.question_name,
                            };
                          });
                          setDataQuestions(dataFormat);
                        });
                      };
                      getQuestion(record.id);
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Layout className="survey-managment course-construction">
      <Modal
        title="Kết xuất kết quả khảo sát"
        width="fit-content"
        open={isShowModalAddSurveyDescription}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Đóng
          </Button>,
          <Button key="excel" onClick={handleExportExcel}>
            Excel
          </Button>,
          <Button key="image" onClick={handleExportImage}>
            Ảnh
          </Button>,
          <Button key="pdf" onClick={handleExportPdf}>
            PDF
          </Button>,
          <Button key="csv" onClick={handleExportCsv}>
            CSV
          </Button>,
        ]}
      >
        <Card
          title="Chọn tham số kết xuất dữ liệu"
          style={{
            border: "none",
            outline: "none",
            fontWeight: "lighter",
          }}
          className="wrapper-settings--card form-setting--common"
        >
          <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
            <Col
              xl={{ span: 12 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="repDate" className="">
                <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={["Ngày trả lời", "Ngày trả lời"]}
                  value={filterResult.repDate}
                  onChange={(dates, dateStrings) => {
                    setRepDateFrom(
                      !isEmpty(dateStrings[0])
                        ? new Date(dateStrings[0]).toISOString()
                        : ""
                    );
                    setRepDateTo(
                      !isEmpty(dateStrings[1])
                        ? new Date(dateStrings[1]).toISOString()
                        : ""
                    );
                    setFilterResult((prevState) => ({
                      ...prevState,
                      repDate: dateStrings, // Gán giá trị mới cho repDate từ dateStrings
                    }));
                  }}
                />
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 12 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="nameQuestion">
                <FormItemDropdown
                  placeholder="Loại câu hỏi"
                  modeDropdown="multiple"
                  value={filterResult.typeQuestion}
                  options={dataOptions.dataTypes}
                  onChange={(option: any) => {
                    setTypeQuestion(option);
                    setFilterResult((prevState) => ({
                      ...prevState,
                      typeQuestion: option, // Gán giá trị mới cho repDate từ dateStrings
                    }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[{ md: 24, xl: 8, xs: 8 }, 8]}>
            <Col
              xl={{ span: 16 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="nameQuestion">
                <FormItemDropdown
                  placeholder="Câu hỏi khảo sát"
                  modeDropdown="multiple"
                  value={filterResult.nameQuestion}
                  options={dataQuestions}
                  onChange={(option: any) => {
                    setNameQuestion(option);
                    setFilterResult((prevState) => ({
                      ...prevState,
                      nameQuestion: option, // Gán giá trị mới cho repDate từ dateStrings
                    }));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <ResultChartRender
            id={idSurvey}
            repDateFrom={repDateFrom}
            repDateTo={repDateTo}
            refChild={childRef}
            typeQuestion={typeQuestion}
            nameQuestion={nameQuestion}
          />
        </Card>
      </Modal>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createSurvey") {
            const { createSurvey } = forms;
          }
        }}
      >
        <div className="table-header--wrapper">
          <FilterSurvey
            handleOpen={handleOpenCreate}
            title={title}
            searchValue={searchValue}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            handleChangeInput={handleChangeInput}
            handleSubmitSearch={handleSubmitSearch}
            clearFilter={clearFilter}
            form={form}
          />
        </div>
        <div className="tabs-heading">
          <div className="table-wrapper">
            <TableData
              dataTable={listSurvey}
              dataColumns={dataColumns}
              setPageNumber={setPageNumber}
              hideSelection={false}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              pageSize={pageSize}
              loadingTable={loading}
              scroll={{ x: 1500, y: 600 }}
              totalRecords={totalRecords}
              handleTableChangeProps={handleTableChange}
              pagination={true}
            />
          </div>
        </div>
        <ApproveSurvey
          open={isOpenModalApprove}
          handleCancel={() => {
            closeApproveModal();
          }}
          browseData={browseData}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListSurveyResult;
