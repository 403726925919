import { Layout } from "antd";
import { useLocation } from "react-router-dom";

import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";

const imgPath = "/images/";
const { Content } = Layout;

type Props = {
  isCourseApprove?: string;
};
export default function DetailAdminInterface(props: Props) {
  const location = useLocation();

  const listBreadcrumb = [
    {
      title: (
        <a
          href={`/${routesConfig.adminInterface}`}
        >
          Thiết lập giao diện quản trị
        </a>
      ),
    },
    {
      title: location.state.name
    },
    // {
    //   title: <div contentEditable='true' dangerouslySetInnerHTML={{ __html: location.state.name }}></div>,
    // },
  ];

  return (
    <Layout>
      <Content className="detail-topic-page">
        <PageHeader
          title={`Giao diện quản trị: ${location.state.name}`}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <div className="wrapper-course-construct">
            <section className="detail-topic-page">
              <div className="form-detail">
                {/* <DetailTopic detailData={location.state?.detailData}/> */}
              </div>
            </section>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
