import React, {useState} from 'react';
import {Col, Row, TableColumnsType, TableProps} from "antd";
import TableReportCard from "../../chart/table-report-card";
import TableData from "../../../../../../components/table-data/TableData";

const FeedbackEvaluationTable = () => {
  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")

  const tableAccessColumn: TableColumnsType<any> = [
    {
      title: "Thời gian tạo",
      dataIndex: "createDate",
      key: "createDate"
    },
    {
      title: "Học liệu",
      dataIndex: "section",
      key: "section"
    },
    {
      title: "Tài liệu tham khảo",
      dataIndex: "document",
      key: "document"
    },
    {
      title: "Scorm & xAPI",
      dataIndex: "scorm&xAPI",
      key: "scorm&xAPI"
    },
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total"
    }
  ]

  const tableColumn: TableColumnsType<any> = [
    {
      title: "Bài giảng",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Học liệu",
      dataIndex: "section",
      key: "section"
    },
    {
      title: "Tài liệu tham khảo",
      dataIndex: "document",
      key: "document"
    },
    {
      title: "Bài tập/Bài kiểm tra",
      dataIndex: "lesson",
      key: "lesson"
    },
    {
      title: "Scorm & xAPI",
      dataIndex: "scorm&xAPI",
      key: "scorm&xAPI"
    },
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total"
    },
  ]
  

  const tableQuantityFeedback: TableColumnsType<any> = [
    {
      title: "Ngày đánh giá",
      dataIndex: "dateFeedback",
      key: "dateFeedback",
    },
    {
      title: "Số lượt hoàn thành",
      dataIndex: "quantityFinish",
      key: "quantityFinish"
    },
    {
      title: "Số lượt đánh giá",
      dataIndex: "quantityFeedback",
      key: "quantityFeedback"
    },
  ]

  const tableRateFeedbackCourseColumn: TableColumnsType<any> = [
    {
      title: "Tài nguyên",
      dataIndex: "resource",
      key: "resource",
    },
    {
      title: "5 sao",
      dataIndex: "star",
      key: "star"
    },
    {
      title: "4 sao",
      dataIndex: "star",
      key: "star"
    },
    {
      title: "3 sao",
      dataIndex: "star",
      key: "star"
    },
    {
      title: "2 sao",
      dataIndex: "star",
      key: "star"
    },
    {
      title: "1 sao",
      dataIndex: "star",
      key: "star"
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng phản hồi
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Thống kê số lượng phản hồi theo thời gian"
                           content={<>
                             <TableData dataColumns={tableAccessColumn} handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Thống kê số lượng phản hồi theo bài giảng"
                           content={<>
                             <TableData dataColumns={tableColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng đánh giá
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượng đánh giá theo thời gian" content={<>
            <TableData dataColumns={tableQuantityFeedback} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Tỷ lệ đánh giá khóa học" content={<>
            <TableData dataColumns={tableRateFeedbackCourseColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Tỷ lệ đánh giá bài giảng" content={<>
            <TableData dataColumns={tableRateFeedbackCourseColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <TableReportCard tableName="Tỷ lệ đánh giá khóa học" content={<>
            <TableData dataColumns={tableRateFeedbackCourseColumn} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default FeedbackEvaluationTable;
