import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  FormOutlined,
  HeartOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SettingOutlined,
  StopOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "../../components/page-header/PageHeader";
import FilterAdminInterface from "../../components/admin-interface/filter-table/FilterTable";
import { formatDateV2, formatOriginTime } from "../../utils/converDate";
import { routesConfig } from "../../config/routes";
import TableData from "../../components/table-data/TableData";
import FormFilter from "../../components/admin-interface/filter-group/FormFilter";
import {
  deleteCertificateTemplate,
  getListCertificate,
  postCopyCertificateTemplate,
} from "../../service/certificate";

type Props = {
  title?: string;
};

const CertificatePage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [listContentForum, setListContentForum] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [position, setPosition] = useState<any[]>(["right"]);
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [mode, setMode] = useState<string>("");

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const handleOpenCreate = () => {
    setIsOpened(true);
    setDataUpdate({});
  };

  // const optionsContentForum: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: "Xem chi tiết",
  //     icon: <FileDoneOutlined />,
  //   },
  //   {
  //     key: "2",
  //     label: "Đăng bài",
  //     icon: <UploadOutlined />,
  //   },
  //   {
  //     key: "3",
  //     label: "Sửa chủ đề",
  //     icon: <EditOutlined />,
  //   },
  //   {
  //     key: "4",
  //     label: "Xóa",
  //     icon: <DeleteOutlined />,
  //     danger: true,
  //   },
  // ];

  const optionsContentForum1: MenuProps["items"] = [
    {
      key: "1",
      label: "Xem chi tiết",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Lưu mẫu",
      icon: <SaveOutlined />,
    },
    {
      key: "3",
      label: "Tạo bản nháp",
      icon: <FormOutlined />,
    },
    {
      key: "4",
      label: "Cài đặt hoạt động",
      icon: <SettingOutlined />,
    },
    {
      key: "5",
      label: "Xóa",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const followAllTopic = async () => {
    // const data = {
    //   isFollowAll: true
    // }
    // await followAll(data).then((res) => {
    //   if (res.status === 200) {
    //     message.success("Đã theo dõi tất cả các chủ đề")
    //     getListData()
    //   }
    // })
  };

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      createdDateFrom:
        !isEmpty(filterData.approvedDate) &&
        !isEmpty(filterData.approvedDate?.[0])
          ? `${filterData.approvedDate?.[0]}T00:00:00.000Z`
          : null,
      createdDateTo:
        !isEmpty(filterData.approvedDate) &&
        !isEmpty(filterData.approvedDate?.[1])
          ? `${filterData.approvedDate?.[1]}T00:00:00.000Z`
          : null,
      status: filterData.status ?? null,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      sortByView: lastPreview && lastPreview === 1 ? "desc" : null,
      sortByLastDiscussDate: lastPreview && lastPreview === 2 ? "desc" : null,
    };

    await getListCertificate(data)
      .then((res) => {
        const { data } = res.data;
        setListContentForum(data.templates);
        setTotalRecords(data.total);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, statusOrder, lastPreview]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleDuplicateTemplate = async (id: string) => {
    setLoading(true)
    await postCopyCertificateTemplate(id)
      .then((result:any) => {
        setLoading(false);
        message.success("Lưu thông tin thành công")
        getListData();
      })
      .catch((err:any) => {
        setLoading(false);
        console.error(err)
      });
  };

  const handleDeleteTemplate = async (id: string) => {
    setLoading(true)
    await deleteCertificateTemplate(id)
      .then((result:any) => {
        setLoading(false);
        message.success("Lưu thông tin thành công")
        getListData();
      })
      .catch((err:any) => {
        setLoading(false);
        console.error(err)
      });
  };


  // 

  const renderStatus = (value: number) => {
    switch (value) {
      case 1:
        return <p>Nháp</p>;
        break;
      case 2:
        return <p>Đã đăng</p>;
        break;
      case 3:
        return <p>Xoá</p>;
        break;
      default:
        break;
    }
  };

  const postContentBlog = async (id: string | undefined) => {
    // await publishTopic(id).then((res) => {
    //   if (res.status === 200) {
    //     message.success("Đăng bài thành công")
    //     getListData()
    //   }
    // })
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên mẫu",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.createdDate ? formatDateV2(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: "Mã mẫu",
      dataIndex: "code",
      key: "code",
      width: 20,
      render: (_: any, record) => {
        return (
          <span className="p-3 rounded-full px-1.5 py-0.5">{record?.code}</span>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="flex items-center gap-2">
            <div className="table-status">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    record.status === 1
                      ? "#1677FF"
                      : record.status === 2
                      ? "#24b524"
                      : record.status === 3
                      ? "gray"
                      : "white",
                }}
              ></div>
            </div>
            {renderStatus(record?.status)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(`${record?.id}`, {
                    state: {
                      ...record,
                      isCourseEvaluation: true,
                      mode: "view",
                      name: record?.name,
                      detailData: record,
                    },
                  })
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsContentForum1,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      navigate(`${record?.id}`, {
                        state: {
                          ...record,
                          isCourseEvaluation: true,
                          mode: "view",
                          name: record?.name,
                          detailData: record,
                        },
                      });
                    }

                    if (key === "2") {
                      Modal.confirm({
                        title: `Xác nhận đăng bài`,
                        content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi đăng bài`,
                        onOk: () => {
                          postContentBlog(record?.id);
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: (
                          <ExclamationCircleOutlined
                            style={{ color: "#1677FF" }}
                          />
                        ),
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    if (key === "3") {
                      Modal.confirm({
                        title: `Xác nhận tạo bản nháp`,
                        content: `Vui lòng xác nhận tạo bản nháp mẫu chứng nhận/chứng chỉ`,
                        onOk: () => {
                          handleDuplicateTemplate(record?.id)
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: (
                          <ExclamationCircleOutlined
                            style={{ color: "#1677FF" }}
                          />
                        ),
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    if (key === "5") {
                      Modal.confirm({
                        title: `Xác nhận xóa`,
                        content: `Mẫu chứng nhận/chứng chỉ đã xóa sẽ không thể khôi phục.
                        Bạn có chắc chắn muốn xoá chứng nhận/chứng chỉ này?`,
                        onOk: () => {
                          handleDeleteTemplate(record?.id)
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: (
                          <ExclamationCircleOutlined
                            style={{ color: "#1677FF" }}
                          />
                        ),
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const deletePortalAdmin = async () => {
    // await deleteTopic(deleteId).then((res) => {
    //   if (res.status === 200) {
    //     message.success("Xoá chủ đề thành công")
    //     setIsOpenedConfirm(false)
    //     getListData()
    //   }
    // })
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-evaluation">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterAdminInterface
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              setlastPreview={setlastPreview}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <div className="tabs-heading">
          <div className="waiting-assignment">
            <TableData
              dataColumns={dataColumns}
              loadingTable={loading}
              dataTable={listContentForum}
              pageSize={pageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              totalRecords={totalRecords}
              scroll={{ x: 1500, y: 800 }}
              pagination={true}
              hideSelection={false}
              handleTableChangeProps={handleTableChange}
              setSelectedRowKeysProps={setSelectedRowKeys}
              clearRowkey={clearRowkey}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default CertificatePage;
