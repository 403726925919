import React, {ChangeEvent, KeyboardEvent, MouseEvent, ReactElement, useEffect, useState, useTransition} from "react";
import {RegistrationClassTabProps} from "../registration-class";
import {useLocation} from "react-router-dom";
import {
  addStudentEnrolledClass,
  getListStudentCourse,
  getSampleTemplateUsersExcel
} from "../../../../service/course-construct";
import TableData from "../../../../components/table-data/TableData";
import {
  Button,
  Flex,
  Form,
  FormInstance,
  message,
  Modal,
  Progress,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
  UploadFile
} from "antd";
import {CloseOutlined, DeleteFilled, DeleteOutlined, FileExcelOutlined, SaveOutlined} from "@ant-design/icons";
import {formatDateV2} from "../../../../utils/converDate";
import {Page500} from "../../../index";
import FilterTable from "./filter-table";
import DrawerCustom from "../../../../components/drawer/Drawer";
import CreateStudent from "./create-student";
import FormTable from "./form-table";
import {get} from "lodash";
import ModalUploadFileExcel from "../../../../components/modal/modal-upload-file-excel";
import {ExcelFileIcon} from "../../../../components/icons/svg";
import {UploadListType, UploadProps} from "antd/es/upload/interface";
import {BASE_URL_V2} from "../../../../config/api/configApiv2";
import {useAuthStore} from "../../../../stores/stores";
import {findSchoolForStudent} from "../../../../service/notifications";
import {getPopularTopics} from "../../../../service/courses";
import {saveAs} from "file-saver";
import {FileType} from "../../../../utils/uploadImage";
import {convertErrors} from "../../../../utils/arrays";

function ListStudentTab(props: RegistrationClassTabProps) {
  const [form] = Form.useForm()
  const {confirm} = Modal;
  const location = useLocation();
  const {classId, enrolledDate, id} = location.state

  const {accessToken} = useAuthStore()
  const [isPending, startTransition] = useTransition()
  const [loadingContent, setLoadingContent] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [dataStudents, setDataStudents] = useState([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [checkUploadFile, setCheckUploadFile] = useState<boolean>(false)
  const [isHasStudent, setIsHasStudent] = useState<boolean>(false)

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [isOpenedImport, setIsOpenedImport] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isTitleEdit, setIsTitleEdit] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileList, setFileList] = useState<FileType[]>([]);

  const [dataOptions, setDataOptions] = useState({
    dataUniversity: [],
    dataIndustry: [],
  });

  const [optionsUsers, setOptionsUsers] = useState<any[]>([])

  const [optionsUniversity, setOptionsUniversity] = useState<any[]>([])


  const [dataDetail, setDataDetail] = useState<any>({})


  const [filterData, setFilterData] = useState<any>({
    enrolledClassId: null,
    enrolledDateStart: null,
    enrolledDateDate: null,
  });


  const getOptions = async () => {
    const response = await Promise.all([findSchoolForStudent(), getPopularTopics({
      page: 0,
      size: 0,
      keyword: ""
    })])

    setDataOptions({
      ...dataOptions,
      dataUniversity: response[0].data.data,
      dataIndustry: response[1].data.content
    })
  }


  useEffect(() => {
    if (props.currentTab === '3') {
      getStudents()
      getOptions()
    }
  }, [props.currentTab, id]);


  const getStudents = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      courseId: id,
    }

    try {
      const response = await getListStudentCourse(dataReq)
      const {data, status} = response
      if (status === 200) {
        startTransition(() => {
          setDataStudents(data.content);
          setHasError(false);
        });
      }

    } catch (error) {
      setHasError(true)
      console.error(error);
    }
  };

  const createNewStudentEnrollClass = async (dataReq: any) => {
    try {
      const response = await addStudentEnrolledClass(dataReq)
      const {success} = response.data
      if (success) {
        message.success('Thêm học viên thành công')
      }
      handleClose()
      getStudents()
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteStudent = async (id: number) => {
    setLoadingContent(true)
    /* try {
       const response = await deleteEnrolledClass(id)
       const {success} = response.data
       if (success) {
         message.success('Xóa học viên thành công')
       }
       getDataClass({})
       setLoading(false)
     } catch (error) {
       console.error(error)
     }*/
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setLoadingContent(true)
      getListStudentCourse({
        keyword: searchValue,
        size: pageSize,
        page: totalPage,
        courseId: id
      }).then(res => {
        const {data} = res
        setDataStudents(data.content)
        setLoadingContent(false)
      }).catch(err => console.error(err))
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("")
    }
  };


  const handleOpen = () => {
    setIsOpenDrawer(true)
    setIsEdit(false)
    setIsTitleEdit(false)
  }

  const handleOpenModal = () => {
    setIsOpenedImport(true)
  }

  const handleClose = () => {
    setIsOpenDrawer(false)
    form.resetFields()
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleSubmitSearch = () => {
    setLoadingContent(true);
    getListStudentCourse({
      keyword: searchValue,
      size: pageSize,
      page: totalPage,
      courseId: id
    }).then(res => {
      const {data} = res
      setDataStudents(data.content)
      setLoadingContent(false)
    }).catch(err => console.error(err))
    if (searchValue === '') {
      setPageNumber(1)
    }
  };

  const clearFilter = (formInstance: FormInstance) => {
    setLoadingContent(true)
    getListStudentCourse({
      keyword: searchValue,
      size: pageSize,
      page: pageNumber,
      courseId: id
    }).then(res => {
      const {data} = res
      setDataStudents(data.content)
      setLoadingContent(false)
      setFilterData({
        enrolledClassId: classId,
        enrolledDate: enrolledDate,
      });
      formInstance.resetFields()
    }).catch(err => console.error(err))
  };

  const showConfirm = (id: number) => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{color: "red", fontSize: "20px"}}>
            <DeleteFilled/>
          </div>
          <span>Bạn muốn xóa học viên?</span>
        </div>
      ),
      icon: null,
      okText: "Xác nhận",
      cancelText: "Hủy",
      okType: "danger",
      onOk() {
        handleDeleteStudent(id);
      },
      onCancel() {
      },
    });
  };

  const beforeUploadFile = (file: FileType) => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

    if (!isExcel) {
      message.error("Chỉ tải lên được file xlsx/csv");
    }
    setFileList([file])

    return isExcel;
  };

  const propsModal: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    action: BASE_URL_V2 + `/mooc-courses/enrolled-class/import-user/${classId}`,
    headers: {Authorization: 'Bearer ' + accessToken},
    beforeUpload: beforeUploadFile,
    fileList: fileList,
    onChange(info) {
      const {status, response} = info.file;
      if (status !== 'uploading') {
        setCheckUploadFile(true)
      }
      if (status === 'done') {
        message.success(`Tệp ${info.file.name} tải lên thành công.`);


        setCheckUploadFile(false)
      } else if (status === 'error') {
        setCheckUploadFile(true)
        message.error(`${info.file.name} file tải lên thất bại.`);
        if (response.codeStatus === 400) {
          setErrorMessage(response.message)
        }
      }
    },
    onDrop(e) {
      //console.log('Dropped files', e.dataTransfer.files);
    },
    iconRender: (file: UploadFile<any>, listType?: UploadListType | undefined) => (<FileExcelOutlined/>),
    itemRender: (originNode: ReactElement, file: UploadFile, fileList,
                 actions: { download: any, preview: any, remove: any }
    ) => {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const errorMessages = errorMessage && convertErrors(errorMessage)

      return (
        <>
          {
            <>
              <Flex justify={'space-between'} className="item-render">
                <p className="status-progress--upload">
                  <span>
                    <ExcelFileIcon/>
                  </span>
                  <span>
                    {file.name}
                  </span></p>
                <Progress percent={!checkUploadFile ? file.percent : 100}
                          status={checkUploadFile ? 'exception' : 'success'}
                          showInfo={true}
                          size={'default'}
                          style={{width: 200}}/>
              </Flex>
              {
                !isExcel ? (
                  <div className="errorUpload">
                    <span>Lỗi định dạng file !</span>
                  </div>
                ) : isExcel && errorMessage ? (
                  <div className="errorUpload">
                    <Flex vertical={true} gap={"small"}>
                      <span>
                      Lỗi dữ liệu file!
                    </span>
                      {errorMessages && errorMessages.map((mess: string, index: number) => (
                        <span key={index}>
                          {mess}
                        </span>
                      ))}
                    </Flex>
                  </div>
                ) : (
                  <></>
                )
              }
            </>
          }
        </>
      )
    }
  };

  const columns: TableColumnsType = [
    {
      key: 'numeric',
      title: 'STT',
      dataIndex: 'numeric',
      width: 10
    },
    {
      key: 'studentName',
      title: 'Học viên đăng ký',
      dataIndex: 'studentName',
      width: 30
    },
    {
      key: 'enrolledDate',
      dataIndex: 'enrolledDate',
      title: 'Ngày ghi danh',
      render: (_, record: any) => {
        return record.enrolledDate ? formatDateV2(record.enrolledDate) : null;
      },
      width: 40,
    },
    {
      key: 'enrolledUserName',
      dataIndex: 'enrolledUserName',
      title: 'Người ghi danh',
      width: 40
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record: any) => {

        return (
          <>

            <Space>
              <a
                className="action-table"
                onClick={() => showConfirm(record.id)}
              >
                <DeleteOutlined/>
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
  };


  if (hasError) return (<Page500/>)


  const onFinish = (values: any) => {
    const dataReq = {
      enrollClassID: Number(classId),
      userIds: values.listStudent
    }

    createNewStudentEnrollClass(dataReq)
    setIsOpenDrawer(false)
    form.resetFields()
  }

  const onFinishFilter = (values: any) => {
    setLoadingContent(true)
    setFilterData({
      ...filterData,
      enrolledClassId: get(values, 'enrolledClassId', 0),
      enrolledDateStart: get(values, 'enrolledDate', "").length > 0 ? get(values, 'enrolledDate', "")[0].format('YYYY-MM-DD') : "",
      enrolledDateEnd: get(values, 'enrolledDate', "").length > 0 ? get(values, 'enrolledDate', "")[1].format('YYYY-MM-DD') : "",
    })
    getListStudentCourse({
      courseId: id,
      enrolledDateStart: filterData.enrolledDateStart,
      enrolledDateEnd: filterData.enrolledDateEnd,
    }).then(res => {
      const {data} = res
      setLoadingContent(false)
      setDataStudents(data.content)
    })
  }
  const handleOK = () => {
    setIsOpenedImport(false)
    getStudents()
  }

  const handleCancel = () => {
    setIsOpenedImport(false)
    setFileList([])

  }

  const downloadSampleTemplate = async (e: MouseEvent) => {
    e.preventDefault()

    try {
      const response = await getSampleTemplateUsersExcel({
        responseType: 'arraybuffer',
        headers: {'Content-Type': 'blob'},
      })
      if (response.data) {
        const outputFilename = `sampleTemplate.xlsx`;
        let blobFile = new Blob([response.data], {type: "application/vnd.ms-excel"});
        saveAs(blobFile, outputFilename)
      }
    } catch (err) {
      console.error(err)
    }
  }


  return (
    <>
      <div className="wrapper-settings-history">
        <Form.Provider onFormFinish={(name, {values, forms}) => {
          if (name === 'createStudent') {
            onFinish(values)
          }
        }}>
          <section className="history-page">

            <div className="history-header">
              <Typography.Title className="history-title" style={{margin: 0}}>
                Danh sách lớp ghi danh
              </Typography.Title>
              <FilterTable
                handleOpenFilter={handleOpenFilter}
                showFilter={showFilter}
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleOpen={handleOpen}
                handleOpenModal={handleOpenModal}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
              />
            </div>
            <div className="filter-class--register">
              <FormTable
                handleCloseFilter={handleCloseFilter}
                clearFilter={clearFilter}
                showFilter={showFilter}
                filterData={filterData}
                // setFilterData={handleChangeFilter}
                onFinish={onFinishFilter}
              />
            </div>
            <div className="tabs-heading">
              <TableData
                dataTable={dataStudents}
                dataColumns={columns}
                pageNumber={pageNumber}
                totalRecords={totalRecords}
                pageSize={pageSize}
                pagination={true}
                loadingTable={isPending}
                scroll={{x: 1500, y: 600}}
                handleTableChangeProps={handleTableChange}
              />
            </div>
          </section>
          <DrawerCustom
            title={"Thêm học viên"} open={isOpenDrawer} onClose={handleClose}
            extraNode={
              <Space>
                <>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <CloseOutlined/>
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" htmlType="submit" onClick={() => form.submit()}>
                    <SaveOutlined/>
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </>
              </Space>
            }
            widthDrawer={730}>
            <CreateStudent formProps={form} isOpenDrawer={isOpenDrawer} dataOptions={dataOptions} classId={classId}/>
          </DrawerCustom>
          <ModalUploadFileExcel
            title={`Import danh sách học viên ghi danh`}
            open={isOpenedImport}
            isHasStudent={true}
            onOk={handleOK}
            onCancel={handleCancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            propsDragger={propsModal}
            checkError={checkUploadFile}
            downloadSample={downloadSampleTemplate}
          />
        </Form.Provider>
      </div>
    </>
  );
}

export default ListStudentTab;
