import React, { FunctionComponent, useEffect, useState } from "react";
import { message } from "antd/lib";
import { get, isEmpty, set } from "lodash";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { UrlPattern, regexEmail } from "../../../../../../utils/regex";
import type { DatePickerProps } from "antd";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { Input } from "antd";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import FormInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormRadio from "../../../../../../components/form-radio/FormRadio";

import {
  getMoocCourseConstructionPlanById,
  getLookupAuthUser,
  getLookupMoocUniversity,
  getLookupIndustryGroup,
  getLookupIndustry,
  checkExistCodeName,
  getListPlanningCourseConstruction,
} from "../../../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";
import { DetailTypeMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";

type FormCreateMoocCourseConstructionPlanProps = {
  onFinish?: any;
  dataOptions?: any;
  id?: any;
  detailData?: DetailTypeMoocCourseConstructionPlan;
  isOpened?: boolean;
  modeOpen: any;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData?: React.Dispatch<
    React.SetStateAction<DetailTypeMoocCourseConstructionPlan>
  >;
  setDataUpdate?: (data: any) => void;
  setDataSponsorUpdate?: (data: any) => void;
};

export const FormCreateMoocCourseConstructionPlan: FunctionComponent<
  FormCreateMoocCourseConstructionPlanProps
> = ({
  onFinish,
  dataOptions,
  id,
  detailData,
  isOpened,
  setIsOpened,
  modeOpen,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
  const originalRegistrationStageId = detailData?.registrationStageId;

  const validations = {
    id: {
      required: false,
      message: "Vui lòng chọn khoa Title of id áp dụng",
    },
    registrationCode: {
      required: true,
      validator: async (_: unknown, value: string) => {
        if (value && value.length > 25) {
          return Promise.reject(
            new Error("Mã kế hoạch không vượt quá 25 ký tự")
          );
        }
        if (!value) {
          return Promise.reject(new Error("Vui lòng nhập mã đăng ký"));
        } else {
          if (modeOpen === "edit") {
            if (value !== detailData?.registrationCode) {
              await checkExistCodeName(value).then((res) => {
                if (res.data.data === true) {
                  return Promise.reject(
                    new Error("Mã đăng ký khóa học đã tồn tại")
                  );
                }
              });
            }
          } else if (modeOpen === "create") {
            // Kiểm tra trong chế độ tạo mới
            await checkExistCodeName(value).then((res) => {
              if (res.data.data === true) {
                return Promise.reject(
                  new Error("Mã đăng ký khóa học đã tồn tại")
                );
              }
            });
          }
        }
        return Promise.resolve();
      },
    },
    registrationName: {
      required: true,
      validator: (_: unknown, value: string) => {
        if (value && value.length > 255) {
          return Promise.reject(
            new Error("Tên kế hoạch không vượt quá 255 ký tự")
          );
        }
        if (!value) {
          return Promise.reject(new Error("Vui lòng nhập tên kế hoạch"));
        }
        return Promise.resolve();
      },
    },
    courseIndustry: {
      required: true,
      message: "Vui lòng chọn khoa áp dụng",
    },
    courseType: {
      required: true,
      message: "Vui lòng chọn loại khóa học áp dụng",
      validator: (_: unknown, value: string) => {
        value = "x";
        if (!value) {
          return Promise.reject(
            new Error("Vui lòng chọn loại khóa học áp dụng")
          );
        }
        return Promise.resolve();
      },
    },
    registrationSubmitterId: {
      required: true,
      message: "Vui lòng chọn khoa áp dụng",
      validator: (_: unknown, value: string) => {
        value = "x";
        if (!value) {
          return Promise.reject(
            new Error("Vui lòng nhập tên Title of registration_submitter_id")
          );
        }
        return Promise.resolve();
      },
    },
    universityId: {
      required: true,
      message: "Vui lòng chọn trường chủ trì áp dụng",
    },
    courseIndustryGroup: {
      required: true,
      message: "Vui lòng chọn chuyên ngành áp dụng khóa học",
    },
    registrationStageId: {
      required: true,
      message: "Vui lòng chọn đăng kí kế hoạch muốn đồng bộ",
    },
  };
  const dateFormat = "DD/MM/YYYY";
  const { TextArea } = Input;
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
  };
  const [form] = Form.useForm();
  const [error, setError] = useState<any>({});
  const [detailDataEdit, setDetailDataEdit] =
    useState<DetailTypeMoocCourseConstructionPlan>(detailData!);

  useEffect(() => {
    // console.log("detailData", detailData);
    // console.log("modeOpen",modeOpen);
    if (
      detailData !== null &&
      detailData !== undefined &&
      modeOpen === "edit"
    ) {
      form.resetFields();
      // console.log(detailData?.moocConstructionPlanIndustryGroup.flatMap(
      //   (e: any) => e.mooc_industry_group
      // ));
      form.setFieldsValue({
        id: detailData?.id,
        registrationCode: detailData?.registrationCode,
        registrationName: detailData?.registrationName,
        courseStart: dayjs(detailData?.courseStart),
        courseDuration: detailData?.courseDuration,
        constructionPlanStatus: detailData?.constructionPlanStatus,
        courseType: detailData?.courseType,
        registrationSubmissionDate: dayjs(
          detailData?.registrationSubmissionDate
        ),
        registrationSubmitterId: detailData?.registrationSubmitterId,
        universityId: detailData?.universityId,
        registrationStageId: detailData?.registrationStageId,
        createdDate: dayjs(detailData?.createdDate),
        updatedDate: dayjs(detailData?.updatedDate),
        isDeleted: detailData?.isDeleted,
        moocApproval: detailData?.moocApproval,
        moocConstructionPlanCollaborator:
          detailData?.moocConstructionPlanCollaborator,

        lstIndustryIds:
          detailData?.moocConstructionPlanIndustry !== undefined &&
          detailData?.moocConstructionPlanIndustry.length > 0
            ? detailData?.moocConstructionPlanIndustry.flatMap(
                (e: any) => `${e.industry_id}`
              )
            : [],

        lstIndustryGroupIds:
          detailData?.moocConstructionPlanIndustryGroup !== undefined &&
          detailData?.moocConstructionPlanIndustryGroup.length > 0
            ? detailData?.moocConstructionPlanIndustryGroup.flatMap(
                (e: any) => `${e.industry_group_id}`
              )
            : [],
        lstCollaboratedUniversityIds:
          detailData?.moocConstructionPlanCollaborator !== undefined &&
          detailData?.moocConstructionPlanCollaborator.length > 0
            ? detailData?.moocConstructionPlanCollaborator.flatMap(
                (e: any) => e.collaborated_university_id
              )
            : [],
        moocCourseConstructionPlan: detailData?.moocCourseConstructionPlan,
        otherMoocCourseConstructionPlan:
          detailData?.otherMoocCourseConstructionPlan,
        moocCoursePlanAssignment: detailData?.moocCoursePlanAssignment,
        moocCourseStructure: detailData?.moocCourseStructure,
      });
    }
  });

  useEffect(() => {
    form.resetFields();
    // if (id && id != 0) getDetailMoocCourseConstructionPlanData();
  }, [id]);

  const navigate = useNavigate();
  const onStartInput = () => {
    if (false) {
      //setError({ ...error, [`lecturerName`]: true });
      return;
    } else {
      form.submit();
    }
  };
  const [loading, setLoading] = useState<boolean>(true);

  // forgein key function call apis
  const [lstRegistrationSubmitterId, setLstRegistrationSubmitterId] = useState<
    any[]
  >([]);
  const [lstUniversityId, setLstUniversityId] = useState<any[]>([]);
  const [dataIndustry, setDataIndustry] = useState<any[]>([]);
  const [dataIndustryGroup, setDataIndustryGroup] = useState<any[]>([]);
  const [dataCourseType, setDataCourseType] = useState<any[]>([]);
  const [dataListCourseConstruction, setDataListCourseConstruction] = useState<
    any[]
  >([]);
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);

  const getListCourseConstruction = async () => {
    try {
      await getListPlanningCourseConstruction()
        .then((res) => {
          // console.log(res);
          const data = res.data.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: e.id,
              label: e.registration_name,
            });
          });
          setDataListCourseConstruction(dataConvert);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstRegistrationSubmitterId = async () => {
    try {
      await getLookupAuthUser()
        .then((res) => {
          const { data } = res.data;
          setLstRegistrationSubmitterId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstUniversityId = async () => {
    try {
      await getLookupMoocUniversity()
        .then((res) => {
          const { data } = res.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: e.id,
              label: e.name,
            });
          });
          setLstUniversityId(dataConvert);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getDataIndustry = async () => {
    try {
      setLoading(true);
      await getLookupIndustry()
        .then((res) => {
          const { data } = res.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: `${e.id}`,
              label: e.name,
            });
          });

          setDataIndustry(dataConvert);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getDataIndustryGroup = async () => {
    try {
      setLoading(true);
      await getLookupIndustryGroup()
        .then((res) => {
          const { data } = res.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: `${e.id}`,
              label: e.name,
            });
          });

          setDataIndustryGroup(dataConvert);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const filterOption = (input: any, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    getListCourseConstruction();
    getListLstRegistrationSubmitterId();
    getListLstUniversityId();
    getDataIndustry();
    getDataIndustryGroup();
    setDataCourseType([
      {
        label: "Tự thực hiện",
        value: 1,
      },
      {
        label: "Hợp tác xây dựng",
        value: 2,
      },
      {
        label: "Dùng chung",
        value: 3,
      },
    ]);
  }, []);
  // console.log(`detailData:: `, detailData)
  const handleCourseSelection = async (selectedId: any) => {
    try {
      if (!detailData) {
        setIsFieldsDisabled(true);
      } else {
        if (selectedId !== originalRegistrationStageId) {
          setIsFieldsDisabled(true);
        } else {
          setIsFieldsDisabled(false);
        }
      }
      const response = await getMoocCourseConstructionPlanById(selectedId);
      const data = response.data.data;
      // console.log("Selected course data:", data);

      form.setFieldsValue({
        registrationStageId: selectedId,
        universityId: data.university_id,
        lstCollaboratedUniversityIds:
          data.mooc_construction_plan_collaborator.flatMap(
            (e: any) => e.collaborated_university_id
          ),
        courseType: data.course_type,
      });
    } catch (error) {
      console.error("Error fetching course data:", error);
      setLoading(false);
      message.error("Có lỗi khi lấy dữ liệu khóa học");
    }
  };

  return (
    <>
      <DrawerCustom
        title={
          id && id !== 0 ? "Chỉnh sửa kế hoạch" : "Thêm mới kế hoạch khóa học"
        }
        open={isOpened}
        onClose={() => {
          setIsOpened(false);
          setIsFieldsDisabled(false);
          form.resetFields();
        }}
        extraNode={
          <Space>
            <Button
              onClick={() => {
                setIsOpened(false);
                form.resetFields();
                setIsFieldsDisabled(false);
              }}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={onStartInput}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          className="form-create-edit"
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          name="create-edit-mooc-course-construction-plan-detail"
        >
          <Row gutter={24} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Form.Item
                name="registrationName"
                rules={[validations.registrationName]}
                label="Tên kế hoạch"
              >
                <FormInput placeholder="Nhập tên kế hoạch" />
              </Form.Item>
              <span className="label-down-text">
                Tên hiển thị công khai cho kế hoạch của bạn
              </span>
            </Col>
            <Col span={12}>
              <Form.Item
                name="registrationCode"
                rules={[validations.registrationCode]}
                label="Mã kế hoạch"
              >
                <FormInput placeholder="Nhập mã kế hoạch" />
              </Form.Item>
              <span className="label-down-text">
                Số định danh duy nhất xác định kế hoạch của bạn
              </span>
            </Col>
          </Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="lstIndustryIds"
              label="Khoa"
              rules={[validations.courseIndustry]}
            >
              <Select
                filterOption={filterOption}
                mode="multiple"
                options={dataIndustry}
                allowClear={true}
                placeholder="Chọn khoa đăng ký kế hoạch áp dụng"
                onChange={(e) => {
                  // console.log(dataIndustry);
                  // console.log(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="lstIndustryGroupIds"
              label="Chuyên ngành"
              rules={[validations.courseIndustryGroup]}
            >
              <Select
                filterOption={filterOption}
                mode="multiple"
                options={dataIndustryGroup}
                allowClear={true}
                placeholder="Chọn chuyên ngành đăng ký khóa học áp dụng"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="registrationStageId"
              label="Đăng ký kế hoạch"
              rules={[validations.registrationStageId]}
            >
              <Select
                filterOption={filterOption}
                showSearch
                optionFilterProp="children"
                options={dataListCourseConstruction}
                allowClear={true}
                placeholder="Chọn đăng ký kế hoạch muốn đồng bộ"
                onChange={handleCourseSelection}
              />
            </Form.Item>
          </Col>

          <div style={{ marginTop: 20 }}>
            Trường chủ trì lập kế hoạch khóa học
          </div>
          <Col span={24} style={{ marginTop: 10 }}>
            <Form.Item name="universityId" label="Trường chủ trì">
              <Select
                disabled={isFieldsDisabled}
                showSearch
                optionFilterProp="children"
                options={lstUniversityId}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn trường đại học chủ trì"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginTop: 10 }}>
            <Form.Item
              name="lstCollaboratedUniversityIds"
              label="Trường hợp tác"
            >
              <Select
                disabled={isFieldsDisabled}
                showSearch
                mode="multiple"
                optionFilterProp="children"
                options={lstUniversityId}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn trường đại học hợp tác"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item name="courseType" label="Loại khóa học">
              <Select
                disabled={isFieldsDisabled}
                showSearch
                optionFilterProp="children"
                options={dataCourseType}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn loại khóa học áp dụng"
              />
            </Form.Item>
          </Col>
        </Form>
      </DrawerCustom>
    </>
  );
};
