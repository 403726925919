import { Form, Layout, TableColumnsType, TableProps, Typography } from "antd";
import { KeyboardEvent, useMemo, useState } from "react";
import TableData from "../../../../components/table-data/TableData";
import { formatDate } from "../../../../utils/converDate";

type TableRateScoreProps = {
  qualityAutoScore: any;
  setPageNumber: (item: number) => void;
  setPageSize: (item: number) => void;
  pageNumber: number;
  pageSize: number;
  loading: boolean;
  totalPage: number;
};

const TableRateScore = ({
  qualityAutoScore,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  loading,
  totalPage,
}: TableRateScoreProps) => {

  const dataColumnsQualityAutoScore: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.date_of_birth &&
              formatDate(record?.auth_user?.auth_userprofile?.date_of_birth)}
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.auth_user?.email}</Typography.Text>;
      },
    },
    {
      title: "Điểm danh",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div>
            {record?.correct || 0}/{record?.total || 0}
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsQualityAutoScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsQualityAutoScore.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={qualityAutoScore}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableRateScore;
