import React, {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Input,
  MenuProps,
  Modal,
  Row,
  TableColumnType,
  Typography,
  Form,
  message,
} from "antd";

import FormInput from "../../../../../components/form-input/FormInput";
import "./filter-table.scss";
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { DataType } from "../../../../../types/listTrainingNeeds";
import { CheckboxOptionType } from "antd/lib";
import PlanAssignmentMany from "../plan-assignment-many/PlanAssignmentMany";
import { TypeCoursePlanAssignment } from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import IsLoading from "../is-loading/IsLoading";
import { createUpdateCoursePlanAssignmentMany } from "../../../../../service/course-plan-managment/mooc-course-plan-assignment/mooc-course-plan-assignment-api";

type FilterTableProps = {
  handleOpen: () => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<DataType>[];
  selectedRowKeys: Number[];
  setRefreshData: (data:boolean) => void;
};

const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
  setRefreshData
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox.Group
          options={options as CheckboxOptionType[]}
          value={checkedList}
          onChange={(value) => {
            setCheckedList(value as string[]);
          }}
        />
      ),
      key: "radio",
    },
  ];

  const menuProps = {
    items,
  };

  const [isOpenPlanAssignment, setIsOpenPlanAssignment] =
    useState<boolean>(false);
  
  const [dataCoursePlanAssignment, setDataCoursePlanAssignment] = useState<
    TypeCoursePlanAssignment[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveCourseAssign = async () => {
    try {
      setIsLoading(true);
      // console.log(`dataWillSave:::`, dataCoursePlanAssignment);
      const response = await createUpdateCoursePlanAssignmentMany(dataCoursePlanAssignment, selectedRowKeys)
      console.log(response)
      setIsLoading(false);
      if(response.status===200 || response.status===201){message.success(`Phân công giảng viên thành công`)}
      setDataCoursePlanAssignment([]);
      setIsOpenPlanAssignment(false);
      setRefreshData(true);
    } catch (error) {}
  };

  useEffect(()=>{
    if(!isOpenPlanAssignment){ setDataCoursePlanAssignment([])}
  }, [isOpenPlanAssignment])

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}
            xs={{ span: 7 }}
          >
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 13 }}
            lg={{ span: 13 }}
            xl={{ span: 11 }}
            xs={{ span: 17 }}
          >
            <div className="heading-search">
              <FormInput
                placeholder="Nhập từ khóa cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </Col>
          <Col
            md={{ span: 11, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xs={{ span: 21 }}
          >
            <div className="heading-resigter">
              <Button
                className="btn btn-primary"
                onClick={() => setIsOpenPlanAssignment(true)}
              >
                <UserAddOutlined />
                <Typography.Text>Phân công nhiều</Typography.Text>
              </Button>
            </div>
            <Modal
              open={isOpenPlanAssignment}
              title="Phân công lập kế hoạch"
              okText="Lưu"
              cancelText="Hủy"
              onCancel={() => {setIsOpenPlanAssignment(false)}}
              destroyOnClose
              onOk={handleSaveCourseAssign}
              width={800}
            >
              {isLoading && <IsLoading />}
              {!isLoading && (
                <>
                  <PlanAssignmentMany
                    listCoursePlan={selectedRowKeys}
                    dataCoursePlanAssignment={dataCoursePlanAssignment}
                    setDataCoursePlanAssignment={setDataCoursePlanAssignment}
                  />
                </>
              )}
            </Modal>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterTable;
