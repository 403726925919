import { Col, Form, Layout, Row, Tag } from "antd";
import { FunctionComponent, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./ministry-users-management-detail.scss"

const { Content } = Layout;
const imgPath = "/images/";

const MinistryUsersManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);

    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.ministryUsersManagement}`}>
            <span>Quản lý người dùng cấp bộ</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
      ];

    // Chức vụ
    const regency = location.state.record?.authPositions.map((item: any) => {
        return(item.name);
    })

    // Trình độ học vấn
    const academicLevel = location.state.record?.moocTeacherProfiles.map((item: any) => {
        return(item.levelName);
    });

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={isShowSubmitButton}
                />
                <div className="wrapper-settings">
                    <section className="setting-user">
                        <CustomCard
                            className="user-card"
                            title="Thông tin người dùng">
                            <Row gutter={[16,16]}>
                                <Col span={24}>
                                    <Form.Item className="setting-item" name="name" label="Tên người dùng">
                                        <p className="user-content">{location.state?.name}</p>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        className="setting-item" name="organization" label="Đơn vị">
                                        <p className="user-content">{location.state.record.organizationName}</p>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item className="setting-item" name="regency" label="Chức vụ">
                                        <div className="user-content">
                                            {regency?.map((item: any) => (
                                                <Tag style={{marginBottom: 5}} key={item}>{item}</Tag>
                                            ))}
                                        </div>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item className="setting-item" name="academicLevel" label="Trình độ học vấn">
                                        <div className="user-content">
                                            {academicLevel?.map((item: any) => (
                                                <Tag style={{marginBottom: 5}} key={item}>{item}</Tag>
                                            ))}
                                        </div>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item className="setting-item item-label" name="gender" label="Giới tính">
                                        <p className="user-content">{location.state.record.gender}</p>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item className="setting-item item-label" name="domicile" label="Nguyên quán">
                                        <p className="user-content">{location.state.record.provinceDto.name}</p>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item className="setting-item item-label" name="email" label="Email">
                                        <p className="user-content">{location.state.record.email}</p>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item className="setting-item item-label" name="phone" label="Điện thoại liên hệ">
                                        <p className="user-content">{location.state.record.phoneNumber}</p>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </CustomCard>
                    </section>
                </div>
            </Content>
        </>
    )
}

export default MinistryUsersManagementDetail;