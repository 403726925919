import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Flex, Form, Typography } from "antd";
import { useState } from "react";
import FormInputCommon from "../../../../../components/form-input-common/FormInputCommon";
import { initialQuestionSettingValue } from "../../../../../constants";
import {
  useAddLesson,
  useAutomaticScoreStore,
} from "../../../../../stores/stores";
import { SettingQuestionProps } from "../../../../../types/course-construction";
import "./index.scss";

interface ResultExamProps {
  dataExam?: any;
  setDataExam: (value: any) => void;
  dataStaticExam: any;
}

const ResultExam = ({
  dataExam,
  setDataExam,
  dataStaticExam,
}: ResultExamProps) => {
  const { flag, toggleFlag } = useAddLesson((state) => state);
  const [settingValue, setSettingValue] = useState<SettingQuestionProps>(
    initialQuestionSettingValue
  );
  const [form] = Form.useForm();
  const { setExamScore, setScoreQuestion, scoreQuestion } =
    useAutomaticScoreStore((state) => state);

  const handleChangeMark = (e: any, name: string) => {
    const { value } = e.target;
    if (/^\d*\.?\d*$/.test(value)) {
      const newData = dataExam.map((item: any) =>
        item.name === name ? { ...item, mark: value } : item
      );
      setExamScore(newData);
      setScoreQuestion({ ...scoreQuestion, [name]: value });
    }
  };
  return (
    <>
      <div
        className={`setting-question-course ${flag ? "" : "tab-closed w-50px"}`}
      >
        <div className="setting-question-course__header flex">
          <MenuUnfoldOutlined
            className="setting-question-course__header__first"
            rotate={flag ? 0 : 180}
            onClick={toggleFlag}
          />
          <div
            className={`${
              flag ? "visible" : "invisible"
            } setting-question-course__header__title`}
          >
            Kết quả điểm bài thi
          </div>
        </div>
        <div
          className="setting-question-course__content"
          style={{ visibility: flag ? "visible" : "hidden" }}
        >
          <div className="p-4">
            <div className="bg-[#FAFAFA] p-4 rounded-lg">
              <Typography.Title level={5}>
                Tổng điểm: {dataStaticExam?.point}/{dataStaticExam?.total}
              </Typography.Title>
              <Typography.Text>
                Số câu trả lời đúng: {dataStaticExam?.correct}/
                {dataStaticExam?.questionNumber} câu
              </Typography.Text>
            </div>
          </div>
          <Form
            form={form}
            className="px-4"
            onValuesChange={(_, values) => {
              setSettingValue(values);
            }}
            initialValues={{}}
          >
            {dataExam?.map((data: any, index: number) => (
              <Flex
                justify="space-between"
                align="center"
                className="pb-3"
                key={index}
              >
                <span>Câu hỏi {index + 1}</span>
                <FormInputCommon
                  name={data?.name}
                  type="number"
                  value={data?.point}
                  className="border border-[#52C41A] rounded-lg w-[100px]"
                  // onChange={(e) => handleChangeMark(e, question?.name)}
                />
              </Flex>
            ))}
          </Form>
        </div>
      </div>
    </>
  );
};

export default ResultExam;
