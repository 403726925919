import {
  LogoutOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Layout,
  MenuProps,
  Modal,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MyNotificationDrop from "../../components/my-notifycation-drop";
import { routesConfig } from "../../config/routes";
import { useAuthStore } from "../../stores/stores";
import "./header.scss";
import { postLogout } from "./services";
import SafeLink from "../../components/link/SafeLink";
import { getPermission } from "../../service/common";

const imgPath = "/images/";

const HeaderLayout = () => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const accessToken = useAuthStore((state) => state.accessToken);
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState<any>({});
  const [logoPersonal, setLogoPersonal] = useState<any>("");

  const location = useLocation();
  const pathname = location.pathname;

  const backgroundColor = location.state?.color?.backgroundColor;

  const logo = location.state?.logo ?? logoPersonal;

  const getColor = async () => {
    await getPermission().then((res) => {
      if (res.status === 200) {
        setColor(res.data?.data?.color);
        setLogoPersonal(res.data?.data?.logo);
      }
    });
  };

  useEffect(() => {
    getColor();
  }, []);

  const logoutFNC = async () => {
    await postLogout().then((res) => {
      if (res.status === 200) {
        logout();
        setTimeout(() => {
          navigate(routesConfig.login);
          setLoading(false);
        }, 1000);
      }
    });
  };

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const handleLogout = async () => {
    setLoading(true);
    if (accessToken) {
      try {
        logoutFNC();
      } catch (error) {
        logout();
        setLoading(false);
      }
    }
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{ color: "#1677FF", fontSize: "20px" }}>
            <LogoutOutlined />
          </div>
          <span>Xác nhận đăng xuất?</span>
        </div>
      ),
      //   centered: true,
      icon: null,
      okText: "Đăng xuất",
      cancelText: "Hủy",
      onOk() {
        handleLogout();
      },
      onCancel() { },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <div className="account account-drop">
          <div className="account__avatar">
            <div className="account__avatar__wrapper">
              <a onClick={(e) => e.preventDefault()}>
                <Avatar src={getUserInfo?.anhDaiDien} icon={<UserOutlined />} />{" "}
              </a>
            </div>
          </div>
          <div className="account__name">
            <a
              className="account__name__wrapper"
              onClick={(e) => e.preventDefault()}
            >
              <span>{getUserInfo?.hoVaTen}</span>
              <span className="email">{getUserInfo?.email}</span>
            </a>
          </div>
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <SafeLink
          className="account__menu__item"
          rel="account"
          to={routesConfig.userInfo}
        >
          <UserOutlined />
          <span>Thông tin cá nhân</span>
        </SafeLink>
      ),
    },
    {
      key: "3",
      label: (
        <a className="account__menu__item" rel="settings">
          <SettingOutlined />
          <span>Cài đặt</span>
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a className="account__menu__item border-bottom" rel="helper">
          <QuestionCircleOutlined />
          <span>Trợ giúp</span>
        </a>
      ),
    },
    {
      key: "5",
      label: (
        <a className="account__menu__item" rel="log-out" onClick={showConfirm}>
          <LogoutOutlined />
          <span>Đăng xuất</span>
        </a>
      ),
    },
  ];

  return (
    <>
      <div className="global-header">
        <div
          className="global-header1"
          style={{
            background: `${pathname.includes(routesConfig.detailAdminInterface)
                ? backgroundColor
                : color
                  ? color?.backgroundColor
                  : "#FFFFFF"
              }`,
          }}
        >
          <div
            className="logo-mooc2 cursor-pointer"
            onClick={() => navigate(`/${routesConfig?.accountSystem}/${routesConfig?.schoolManagementParticipates}`)}
          >
            {logo ? (
              <img
                src={logo}
                className="w-8 h-8 rounded-lg object-cover"
                alt="logo-mooc"
              />
            ) : (
              <div className="shape2" />
            )}
            <h2 className="mooc2">Mooc</h2>
          </div>
          <div className="menulegacy">
            <ul>
              <li>
                <Link to={"#"} className="active-link--menu">
                  <TableOutlined />
                  <Typography.Text>Quản lý</Typography.Text>
                </Link>
              </li>
            </ul>
          </div>
          <div className="toolbar">
            <div className="icon-wrapper">
              <a href="#">
                <img
                  className="search-icon"
                  loading="eager"
                  alt=""
                  src={imgPath + "search.svg"}
                />
              </a>
            </div>
            <div className="icon-wrapper">
              <a href="#">
                <img
                  className="questioncircle-icon"
                  loading="eager"
                  alt=""
                  src={imgPath + "questioncircle.svg"}
                />
              </a>
            </div>
            <MyNotificationDrop />
            <Dropdown
              overlayClassName="header-account"
              trigger={["click"]}
              menu={{ items }}
            >
              <div className="account">
                <div className="account__avatar">
                  <div className="account__avatar__wrapper">
                    <a onClick={(e) => e.preventDefault()}>
                      <Avatar
                        src={getUserInfo?.anhDaiDien}
                        icon={<UserOutlined />}
                      />
                    </a>
                  </div>
                </div>
                <div className="account__name">
                  <a
                    className="account__name__wrapper"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span>{getUserInfo?.hoVaTen}</span>
                  </a>
                </div>
              </div>
            </Dropdown>
          </div>
        </div>
        <Spin spinning={loading} fullscreen size="large" />
      </div>
    </>
  );
};

export default HeaderLayout;
