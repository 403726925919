import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, message, Switch } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import { getAllTagSystemSuggestCourse } from "../../service/system-suggest-course";
import "./setting-structure.scss";
import SettingStructureView from "./SettingStructureView";
import CommonModal from "../../components/modal/common";
import SettingStructureContent from "./SettingStructure";
const imgPath = "/images/";

const { Content } = Layout;

const SettingStructureCourse = () => {
  const navigate = useNavigate();

  const [optionSelectTag, setOptionSelectTag] = useState([]);
  const [defaultIndustryId, setDefaultIndustryId] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();

  const [isView, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [dataParam, setDataParam] = useState<any>({
    industry_ids: [],
    is_active: false,
    type: "video",
    length: 0,
    content: "Các mô hình phân tích chiến lược Marketing của doanh nghiệp",
    description: "",
  });
  const [contentCourse, setContentCourse] = useState("");

  const fetchListTag = async () => {
    try {
      const response = await getAllTagSystemSuggestCourse();
      const { data } = response.data;
      setOptionSelectTag(
        data?.data?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );

      setDefaultIndustryId(
        data?.data?.filter(
          (item: { name: string; id: any }) => item.name === "Marketing"
        )[0].id
        // .map((item: { name: string; id: any }) => item.id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  useEffect(() => {
    fetchListTag();
  }, [dataParam?.is_active]);

  const onChangeActive = async (checked: boolean) => {
    try {
      setDataParam({
        ...dataParam,
        is_active: checked,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleConfirmStatus = () => {
    setIsOpen(false);
    handleNotice("success", "Thay đổi trạng thái thành công");
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        title="Cài đặt cấu trúc bài giảng"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
        headerGroupButton
        childrenGroupButton={
          <Flex>
            <Button onClick={() => setIsView(!isView)} className="mr-4">
              <EyeOutlined className="pr-2" />
              {!isView ? "Chế độ xem" : "Thoát chế độ xem"}
            </Button>
            <Button onClick={() => setIsOpen(true)}>Trạng thái</Button>
          </Flex>
        }
      />
      <SettingStructureContent
          handleNotice={handleNotice}
          dataParam={dataParam}
          isView={isView}
        />
      <CommonModal
        open={isOpen}
        title={`Thay đổi trạng thái`}
        desc={
          <div>
            <p>Chọn trạng thái hoạt động của cấu trúc bài giảng mặc định</p>
            <Flex>
              <span className="!font-semibold pr-8">Trạng thái</span>
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={onChangeActive}
                defaultChecked
              />
            </Flex>
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmStatus}
        closeModal={() => setIsOpen(false)}
        className={""}
      />
    </Content>
  );
};

export default SettingStructureCourse;
