import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfig } from "../../config/api/configApi";

export const getListManagementParticipatePlatform: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-register/get-all-by-filter`, data);
};

export const getDetailManagementParticipatePlatform: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-university-register/${id}`);
};

export const createBasicAdminAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-facility-management/account`, data);
};

export const getRoleDefaultAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfig.post(`/accounts/get-role-by-condition`, data);
};