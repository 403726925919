import React, { useEffect, useState } from "react";
import { Button, Checkbox, CheckboxProps, Col, Row, Typography } from "antd";
import FormInputText from "../../../form-input-text/FormInputText";
import { DeleteOutlined } from "@ant-design/icons";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { useAddLesson } from "../../../../stores/stores";
import { TypeCreateQuestion, initialQuestionSettingValue } from "../../../../constants";

interface QuestionTextProps {
  index: number;
  value?: string;
  checked?: any;
  onChange?: (val: { id: number; value: string }) => void;
  onDelete?: (id: number) => void;
  onChangeChecked?: (checked: boolean) => void;
}

function QuestionTextItem({ index, value, checked = false, onChange = () => { }, onDelete = () => { }, onChangeChecked = () => { }, }: QuestionTextProps) {
  const { Text, Link } = Typography;
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const [textValue, setTextValue] = useState<string>('');
  const [currentChecked, setCurrentChecked] = useState<boolean>(false);
  const [disableChecked, setDisableChecked] = useState<boolean>(false);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (Object.keys(settingQuestion).length > 0) {
        setDisableChecked(!settingQuestion.canEnterAnswer);
      } else {
        setDisableChecked(!initialQuestionSettingValue.canEnterAnswer);
      }
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setDisableChecked(false);
    }
  }, [settingQuestion]);

  useEffect(() => {
    if (value) setTextValue(value);
  }, [value]);

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);

  const handleChangeText = (changeValue: string) => {
    setTextValue(changeValue);
    onChange({ id: index, value: changeValue });
  };

  const handleChange: CheckboxProps["onChange"] = (e) => {
    onChangeChecked(e.target.checked);
    setCurrentChecked(e.target.checked);
  };

  const handleDelete = (index: number) => {
    confirmMessage(
      'Xóa lựa chọn',
      0,
      'Bạn có chắc muốn xóa lựa chọn này?',
      () => {
        onDelete(index)
      }
    );
  }

  return (
    <section className='answer'>
      <Checkbox
        defaultChecked={false}
        disabled={disableChecked}
        checked={currentChecked}
        onChange={handleChange}
      />
      <div className='ml-2 form-value w-full'>
        <Row>
          <Col span={24} className='form-value__header'>
            <Text>Câu trả lời {index}</Text>
          </Col>
          <Col span={24}>
            <FormInputText
              value={textValue}
              onChange={handleChangeText}
              placeholder="Nhập câu trả lời"
              autoSize={{ minRows: 1, maxRows: 6 }}
              containerClassName="w-full"
              label=""
              type="textarea"
            />
          </Col>
        </Row>
      </div>
      <Button
        className="btn-delete-item ml-2"
        onClick={() => handleDelete(index)}
        icon={<DeleteOutlined />}
      ></Button>
    </section>
  );
}

export default QuestionTextItem;
