import { DatePicker, Typography } from "antd";
import { useEffect, useState } from "react";
import CommonModal from "../../../components/modal/common";
import {
  getLearningTrends,
  updateLearningTrends,
} from "../../../service/prediction-model";
import dayjs from "dayjs";

const { Text, Title } = Typography;
const { RangePicker } = DatePicker;

interface SearchProps {
  take: number;
  group_id?: number;
  skip: number;
  name?: string;
}
interface IProps {
  isOpen: boolean;
  clearModal: any;
  handleNotice: any;
  id: string;
}

const ModalTimeMatch = ({ isOpen, clearModal, handleNotice, id }: IProps) => {
  const [isError, setIsError] = useState(false);
  const [dateMatch, setDateMatch] = useState({
    started_date: "",
    ended_date: "",
  });

  const fetchData = async (searchParam: SearchProps) => {
    try {
      const response = await getLearningTrends(searchParam);
      const { data } = response.data;
      setDateMatch({
        started_date: dayjs(data?.learning_trends?.started_date).format(
          "DD/MM/YYYY"
        ),
        ended_date: dayjs(data?.learning_trends?.ended_date).format(
          "DD/MM/YYYY"
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      take: 10,
      group_id: Number(id),
      skip: 0,
    });
  }, [id]);

  const handleChangeTime = (dateString: any, name: string) => {
    setDateMatch({
      started_date: dateString[0],
      ended_date: dateString[1],
    });
    setIsError(false);
  };

  const handleCreate = async () => {
    if (dateMatch.started_date !== "" && dateMatch.ended_date !== "") {
      try {
        await updateLearningTrends(id, {
          started_date: dayjs(
            dateMatch.started_date,
            "DD/MM/YYYY"
          ).add(7, 'hour').toISOString(),
          ended_date: dayjs(dateMatch.ended_date, "DD/MM/YYYY").add(7, 'hour').toISOString(),
        });
        handleNotice("success", "Cài đặt lịch đồng bộ thành công");
        clearModal();
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsError(true);
    }
  };

 const dateDefault = dayjs().format('DD/MM/YYYY');

  return (
    <CommonModal
      open={isOpen}
      title={"Cài đặt lịch đồng bộ thông tin"}
      desc={
        <div className="w-full border-y pt-4 pb-8">
          <Title level={5}>Thời gian lấy thông tin đồng bộ</Title>
          <RangePicker
            className="w-full"
            // picker="year"
            format={"DD/MM/YYYY"}
            defaultValue={[
              dayjs(dateMatch?.started_date ? dateMatch?.started_date : dateDefault, "DD/MM/YYYY"),
              dayjs(dateMatch?.ended_date ? dateMatch?.ended_date : dateDefault, "DD/MM/YYYY"),
            ]}
            placeholder={["Từ năm", "Đến năm"]}
            onChange={(_value, dateString: any) =>
              handleChangeTime(dateString, "year")
            }
          />
          {isError && (
            <p className="text-[#FF4D4F]">
              Thời gian lấy thông tin đồng bộ là bắt buộc{" "}
            </p>
          )}
        </div>
      }
      closeIcon={true}
      okText={"Lưu"}
      cancelText={"Huỷ"}
      icon={""}
      confirm={handleCreate}
      closeModal={clearModal}
      className="!w-[400px]"
    />
  );
};

export default ModalTimeMatch;
