import {Button, Dropdown, message, Modal, Space,} from "antd";
import "../course-constructor/course-construct.scss";
import {DragSortTable, ProColumns} from "@ant-design/pro-components";
import {useEffect, useState} from "react";
import {isEmpty} from "lodash";
import {
  DeleteFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  SettingOutlined,
  UploadOutlined,
  WechatWorkOutlined,
} from "@ant-design/icons";
import {
  deleteCourseSequence,
  postCreateUnit,
  publishSequence,
  putCourseUpdateSequence,
} from "../../../../service/course-construct";
import UnitCard from "../unit-card";
import AddLessionModal from "../../../modal/add-lession";
import AddLessionDrawer from "../../../drawer/add-lesstion";
import {useNavigate, useParams} from "react-router-dom";
import AddChapter from "../../../drawer/add-chapter/AddChapter";
import AddLearningMaterials from "../../../drawer/add-learning-materials/AddLearningMaterials";
import ActiveSettingsChaptersDrawer from "../../active-settings-chapters-drawer/ActiveSettingsChaptersDrawer";
import AssignContentCreationLesson from "../../../modal/assign-content-creatation-lesson";
import SaveTemplateLessonModal from "../../../modal/save-template-lesson-modal";
import AddLearningMaterialsReferences
  from "../../../drawer/add-learning-materials-references/AddLearningMaterialsReferences";
import { routesConfig } from "../../../../config/routes";
import "./lesson-card.scss"

type IProp = {
  data?: any;
  itemDetailChapter?: any;
  handleGetDetail?: any;
  id?: any;
  name?: string;
  updateUnit: (idSection: number, idSequence: number, data: any) => void;
};

const imgPath = "/images/";

const LessonCard = (props: IProp) => {
  const {updateUnit} = props;
  const [isOpenAddLessionMoldal, setIsOpenAddLessionMoldal] =
    useState<boolean>(false);
  const [isOpenAddLessionDrawer, setIsOpenAddLessionDrawer] =
    useState<boolean>(false);
  const [dataSourceChildrenLesson, setDataSourceChildrenLesson] = useState(
    props.data.children
  );
  const [isOpenDrawerLesson, setIsOpenDrawerLesson] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLesson, setIsLesson] = useState<boolean>(false);
  const [multimediaType, setMultimediaType] = useState<string>("");
  const [isAddLearningMaterials, setIsAddLearningMaterials] =
    useState<boolean>(false);
  const [isAddLearningMaterialsReferences, setIsAddLearningMaterialsReferences] =
    useState<boolean>(false);
  const [isOpenSettingDraw, setIsOpenSettingDrawer] = useState(false);
  const [isOpenAssignContentCreation, setIsOpenAssignContentCreation] = useState(false);
  const [isOpenSaveTemplateModal, setIsOpenSaveTemplateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  const handleAddUnit = async () => {
    const data = {
      name: "Tên học liệu",
      sequenceId: Number(props.data.id),
    };
    try {
      const response = await postCreateUnit(data).then((result) => {
        props.handleGetDetail();
      });
    } catch (error) {
    }
  };
  const [cardHeader, setCardHeader] = useState<string>(props.data.name);
  const [oldName, setOldName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const columns: ProColumns[] = [
    {
      title: "",
      dataIndex: "sort",
      width: 1,
      className: "drag-visible",
    },
    {
      title: "",
      dataIndex: "name",
      className: "drag-visible",
      render: (_: any, record) => {
        return (
          <UnitCard
            data={record}
            lessonDetailData={props.data}
            name={props.name}
            updateUnit={props.updateUnit}
            setLoading={setLoading}
            id={props.data.id}
            sequenceId={Number(props.data.id)}
            handleGetDetail={props.handleGetDetail}
          />
        );
      },
    },
  ];
  const handleDragSortEnd = (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: any
  ) => {
    setDataSourceChildrenLesson(newDataSource);
    updateUnit(props.id, props.data.id, newDataSource);
  };

  const [active, setActive] = useState(false);
  const handleActive = () => {
    setActive(true);
  };

  const handleChangeNameSequence = async () => {
    const data = {
      name: cardHeader,
      sectionId: props.id,
      id: props.data.id
    };

    try {
      const response = await putCourseUpdateSequence(data).then(
        (result) => {
          props.handleGetDetail();
          message.success("Lưu cấu trúc thành công");
        }
      );
    } catch (error) {
    }
  };

  const handleDeleteSequence = async () => {
    try {
      const response = await deleteCourseSequence(Number(props.data.id)).then(
        (result) => {
          props.handleGetDetail();
          message.success("Xóa bài giảng thành công");
        }
      );
    } catch (error) {
    }
  };

  const {confirm} = Modal;

  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{color: "red", fontSize: "20px"}}>
            <DeleteFilled/>
          </div>
          <span>Bạn muốn xóa bài giảng?</span>
        </div>
      ),
      icon: null,
      okText: "Xác nhận",
      cancelText: "Hủy",
      okType: "danger",
      onOk() {
        handleDeleteSequence();
      },
      onCancel() {
      },
    });
  };

  const handleSave = () => {
    if (cardHeader) {
      Modal.confirm({
        title: `Xác nhận lưu cấu trúc`,
        content: `Bạn có chắc chắn muốn lưu cấu trúc ${cardHeader} của khoá học ${props.name}?`,
        onOk: () => {
          handleBlur();
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        icon: <ExclamationCircleOutlined style={{color: "#1677FF"}}/>,
        footer: (_, {OkBtn, CancelBtn}) => (
          <>
            <CancelBtn/>
            <OkBtn/>
          </>
        ),
      });
    } else {
      setErrorMessage("Vui lòng nhập tên bài giảng");
    }
  };

  const handleBlur = () => {
    setActive(false);
    handleChangeNameSequence();
  };

  const handleClick = () => {
    setActive(true);
    setOldName(cardHeader);
  };

  const handleCancel = () => {
    setCardHeader(oldName);
    setActive(false);
    setErrorMessage("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardHeader(event.target.value);
  };

  const confirmAddLession = () => {
    setIsOpenAddLessionMoldal(false);
    setIsOpenAddLessionDrawer(true);
  };

  useEffect(() => {
    if (multimediaType.includes("reference")) {
      setIsAddLearningMaterialsReferences(multimediaType !== "");

    } else {
      setIsAddLearningMaterials(multimediaType !== "");
    }
  }, [multimediaType]);

  const handlePublishSequence = async (sequenceId: number) => {
    try {
      await publishSequence(sequenceId);
      message.success("Xuất bản bài giảng thành công!")
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className="chapter-header">
        <div className="chapter-title">
          <img
            className=""
            loading="eager"
            alt=""
            src={imgPath + "ic_arrow_down.svg"}
          />
          <img
            className=""
            loading="eager"
            alt=""
            src={imgPath + "ic_projects.svg"}
          />
          <div className="lesson-name">
            {/* <Input
              placeholder="Xin vui lòng nhập"
              onClick={handleActive}
              onBlur={handleBlur}
              value={cardHeader}
              onChange={handleChange}
              className={
                active
                  ? "course-input active-input"
                  : "course-input inactive-input"
              }
            /> */}
            {cardHeader}

            {/* <span className="text-header-description">{` ${props.data.assignedName ? props.data.assignedName : ""
              } ${" "}
                ${props.data.assignedId ? `(${props.data.assignedId})` : ""
              }`}</span> */}
          </div>
        </div>

        <Space>
          <a className="action-course" onClick={() =>
            Modal.confirm({
              title: "Xuất bản bài giảng",
              content:
                "Bạn có muốn xuất bản bài giảng này?",
              okText: "Xác nhận",
              cancelText: "Huỷ",
              onOk: () => {
                handlePublishSequence(Number(props.data.id))

              },
              onCancel() {
                // Optional: Handle the cancel event
              },
            })
          }>
            <UploadOutlined/>
          </a>
          <Dropdown
            menu={{
              items: [
                {
                  key: "1",
                  label: "Sửa bài giảng",
                  icon: <EditOutlined/>,
                },
                {
                  key: "2",
                  label: "Xóa bài giảng",
                  icon: <DeleteOutlined/>,
                  danger: true,
                },
                {
                  key: "3",
                  label: "Cài đặt hoạt động",
                  icon: <SettingOutlined/>,
                },
                {
                  key: "4",
                  label: "Phân công xây dựng nội dung",
                  icon: <WechatWorkOutlined/>,
                },
                {
                  key: "5",
                  label: "Lưu mẫu",
                  icon: <DownloadOutlined/>,
                },
              ],
              onClick: ({key}) => {
                if (key === "1") {
                  setIsOpenDrawerLesson(true);
                  setIsLesson(true);
                  setIsEdit(true);
                }
                if (key === "2") {
                  showConfirm();
                }
                if (key === "3") {
                  setIsOpenSettingDrawer(true);
                  setIsLesson(true)
                }
                if (key === "4") {
                  setIsOpenAssignContentCreation(true);
                }
                if (key === '5') {
                  setIsOpenSaveTemplateModal(true)
                }
              },
            }}
            placement="bottomRight"
          >
            <Button className="action-course">
              <MoreOutlined/>
            </Button>
          </Dropdown>
        </Space>
      </div>
      {!isEmpty(props.data.children) && (
        <DragSortTable
          // headerTitle="拖拽排序(默认把手)"
          columns={columns}
          rowKey="key"
          search={false}
          loading={loading}
          pagination={false}
          dataSource={dataSourceChildrenLesson}
          dragSortKey="sort"
          onDragSortEnd={handleDragSortEnd}
        />
      )}
      <div
        onClick={() => setIsOpenAddLessionMoldal(true)}
        className="add-container"
      >
        <div>+</div>
        Thêm học liệu
      </div>

      <AddLessionModal
        open={isOpenAddLessionMoldal}
        title={"Chọn học liệu"}
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={null}
        confirm={confirmAddLession}
        multimediaType={setMultimediaType}
        closeModal={() => setIsOpenAddLessionMoldal(false)}

      />
      <AddLessionDrawer
        open={isOpenAddLessionDrawer}
        onClose={() => setIsOpenAddLessionDrawer(false)}
        id={params?.id}
        sequenceId={Number(props.data.id)}
        parentName={props.name}
      />
      <AddChapter
        id={props.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsOpenDrawerLesson}
        open={isOpenDrawerLesson}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isLesson={isLesson}
        isEdit={isEdit}
      />
      {/* thêm học liệu văn bản  */}
      <AddLearningMaterials
        id={props.id}
        sequenceId={props.data.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsAddLearningMaterials}
        open={isAddLearningMaterials}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isEdit={isEdit}
        multimediaType={multimediaType}
      />

      {/* thêm học liệu tài liệu tham khảo */}
      <AddLearningMaterialsReferences
        id={props.id}
        sequenceId={props.data.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsAddLearningMaterialsReferences}
        open={isAddLearningMaterialsReferences}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isEdit={isEdit}
        multimediaType={multimediaType}
      />
      <ActiveSettingsChaptersDrawer
        id={props.id}
        setIsOpened={setIsOpenSettingDrawer}
        open={isOpenSettingDraw}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        itemDetailChapter={props.itemDetailChapter}
        isLesson={isLesson}
      />

      <AssignContentCreationLesson
        open={isOpenAssignContentCreation}
        handleClose={() => setIsOpenAssignContentCreation(false)}
        sequenceId={props?.data?.id}
      />

      <SaveTemplateLessonModal
        open={isOpenSaveTemplateModal}
        handleClose={() => setIsOpenSaveTemplateModal(false)}
        codeLesson={params?.id}
        sequenceId={props?.data?.id}
      />
    </>
  );
};

export default LessonCard;
