import {
  Checkbox,
  Flex,
  Radio,
  RadioChangeEvent,
  Space,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import CommonModal from "../../../../../components/modal/common";
import SelectUiCard from "../../../../../components/select-ui";
import { DEFAULT_GROUP } from "../../../../../constants/setting-identity";
import { updateShareScore } from "../../../../../service/auto-score";
import {
  getListUserAttendance
} from "../../../../../service/setting-attendance";
import { getSettingAuthority } from "../../../../../service/setting-authority";
const { Title, Text } = Typography;

interface IProps {
  isOpenConfirmShare: boolean;
  onCloseModal: () => void;
  handleCloseModalShare: () => void;
  handleNotice: (mess: string) => void;
  idScore: string;
  usersShare: any;
  setUsersShare: any;
  title: string;
}
const ListScoreShare = ({
  onCloseModal,
  isOpenConfirmShare,
  handleCloseModalShare,
  handleNotice,
  idScore,
  usersShare,
  title,
  setUsersShare,
}: IProps) => {

  const [optionSelectPermission, setOptionSelectPermission] = useState([]);
  const [optionSelectUser, setOptionSelectUser] = useState([]);

  const onChangeShare = (e: RadioChangeEvent) => {
    setUsersShare({
      ...usersShare,
      notification: e.target.value,
    });
  };
  const handleConfirmShare = async () => {
    const paramShare = {
      is_view: usersShare?.is_view,
      is_edit: usersShare?.is_edit,
      is_remove: usersShare?.is_remove,
      notification: usersShare?.notification,
      permission_ids: usersShare?.permission_ids,
      user_ids: usersShare?.user_ids,
    };
    try {
      await updateShareScore(idScore, paramShare);
      handleNotice("Chia sẻ bảng điểm thành công");
      setUsersShare({
        permission_ids: [],
        user_ids: [],
      });
      onCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeShareReport = (val: string, name: string) => {
    setUsersShare({
      ...usersShare,
      [name]: val,
    });
  };
  const handleChangeRole = (e: any) => {
    setUsersShare({
      ...usersShare,
      [e.target.name]: e.target.checked,
    })
  };
  const fetchDataUser = async () => {
    try {
      const res = await getListUserAttendance({
        skip: 0,
        take: 10,
      });
      const { data } = res.data;
      setOptionSelectUser(
        data?.data?.map((option: any) => ({
          label: option?.auth_userprofile?.name,
          value: option.id,
          desc: option?.email,
        }))
      );
    } catch (error) {}
  };

  const fetchSettingAuthority = async () => {
    try {
      const response = await getSettingAuthority();
      const { data } = response.data;
      setOptionSelectPermission(
        data?.map((option: any) => ({
          label: DEFAULT_GROUP[option?.codename],
          value: option.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
    fetchDataUser();
  }, []);

  return (
    <div>
      <Title level={5} className="w-1/4 !mb-0">
        Nhóm người dùng <span className="text-[#FF3141]">*</span>
      </Title>
      <SelectUiCard
        className="font-medium"
        name="permission_ids"
        filterOption={(input: any, option: any) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        label=""
        options={optionSelectPermission}
        mode="multiple"
        required
        value={usersShare?.permission_ids}
        allowClear
        placeholder="Nhập tên nhóm người dùng"
        onChange={(val) => onChangeShareReport(val, "permission_ids")}
      />

      <Title level={5} className="w-1/4 !mb-0">
        Người dùng <span className="text-[#FF3141]">*</span>
      </Title>
      <SelectUiCard
        className="font-medium"
        name="user_ids"
        label=""
        filterOption={(input: any, option: any) =>
          (option?.label.toLowerCase() + option?.desc.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={optionSelectUser}
        mode="multiple"
        required
        value={usersShare?.user_ids}
        placeholder="Nhập tên hoặc email người dùng"
        allowClear
        onChange={(val) => onChangeShareReport(val, "user_ids")}
        optionRender={(option: any) => (
          <Space>
            <span role="img" aria-label={option.data.label}>
              {option.data.label}
            </span>
            {option.data.desc}
          </Space>
        )}
      />

      <Flex className="pt-6">
        <Title level={5} className="w-1/4">
          Quyền chia sẻ:
        </Title>
        <Flex>
          <Checkbox
            name="is_view"
            disabled
            checked
            className="flex items-center pr-2"
          >
            Xem
          </Checkbox>
          <Checkbox
            name="is_edit"
            onChange={(e) => handleChangeRole(e)}
            checked={usersShare.is_edit}
            className="flex items-center pr-2"
          >
            Sửa
          </Checkbox>
          <Checkbox
            name="is_remove"
            onChange={(e) => handleChangeRole(e)}
            checked={usersShare.is_remove}
            className="flex items-center"
          >
            Xóa
          </Checkbox>
        </Flex>
      </Flex>
      <Flex>
        <Title level={5} className="w-1/4">
          Chọn gửi báo cáo qua:
        </Title>
        <Radio.Group onChange={onChangeShare} value={usersShare?.notification}>
          <Radio value={"email"}>Email</Radio>
          <Radio value={"system"}>Hệ thống Mooc</Radio>
        </Radio.Group>
      </Flex>
      <CommonModal
        open={isOpenConfirmShare}
        title={`Chia sẻ bảng điểm`}
        desc={
          <div>
            Đảm bảo rằng tất cả các{" "}
            <span className="text-[#FAAD14]">
              thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi Xác nhận chia sẻ bảng điểm này
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmShare}
        closeModal={handleCloseModalShare}
      />
    </div>
  );
};

export default ListScoreShare;
