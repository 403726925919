import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  Typography
} from "antd";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { updateLearningTrendsReport } from "../../../../../service/prediction-model";
import FormCreateStatistical from "../../form-create-statistical";

const { Title, Text } = Typography;
interface IProps {
  handleNotice: (type: any, mess: string) => void;
  setTag: any;
  isView: any;
  dataDetail: any;
  handleRefresh: () => void;
  timeData: any;
  fieldsInput: any;
  fieldsAction: any;
  setFieldsInput: any;
  setFieldsAction: any;
}
const ElementSetting = ({
  handleNotice,
  setTag,
  isView,
  dataDetail,
  handleRefresh,
  timeData,
  fieldsInput,
  fieldsAction,
  setFieldsInput,
  setFieldsAction,
}: IProps) => {
  const params = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const [nameSetting, setNameSetting] = useState(dataDetail?.name);

  const [timeInput, setTimeInput] = useState([]);
  const [timeAction, setTimeAction] = useState([]);

  const handleChange = (e: any) => {
    setNameSetting(e.target.value);
  };
  const handleChangeTime = (dateString: any, name: string) => {
    if (name === "input") {
      setTimeInput(dateString);
    } else {
      setTimeAction(dateString);
    }
  };

  const onChangeSelectInfo = (e: any, name: string) => {
    if (name === "input") {
      setFieldsInput({
        ...fieldsInput,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFieldsAction({
        ...fieldsAction,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleSave = async () => {
    const fieldsActionConvert = [];
    for (const key in fieldsAction) {
      if (fieldsAction[key] === true) {
        fieldsActionConvert.push(Number(key));
      }
    }
    const fieldsInputConvert = [];
    for (const key in fieldsInput) {
      if (fieldsInput[key] === true) {
        fieldsInputConvert.push(Number(key));
      }
    }
    const paramsUpdate = {
      name: nameSetting,
      type: dataDetail?.type,
      data: [
        {
          fields: fieldsInputConvert,
          group_id: 1,
          ended_date: timeInput[1],
          started_date: timeInput[0],
        },
        {
          fields: fieldsActionConvert,
          group_id: 2,
          ended_date: timeAction[1],
          started_date: timeAction[0],
        },
      ],
    };
    try {
      if (isEmpty(fieldsInputConvert) && isEmpty(fieldsActionConvert)) {
        handleNotice("error", "Phải chọn ít nhất 1 yếu tố");
      } else {
        await updateLearningTrendsReport(params?.id || "", paramsUpdate);
        setIsDisable(true);
        handleNotice("success", "Lưu thông tin thành công");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleTraining = async () => {
    try {
      await updateLearningTrendsReport(params?.id || "", {
        is_training: true,
      });
      handleRefresh();
      handleNotice("success", "Huấn luyện mô hình thành công");
      setTag("2");
    } catch (error) {}
  };
  return (
    <Card className="w-full lg:w-2/3 m-auto my-6">
      <Flex className="border-b pb-3" justify="space-between">
        <div>
          <Title level={4}>Cài đặt yếu tố mô hình phân tích xu hướng</Title>
          <Text className="text-[#00000073]">
            Chọn các thông tin cài đặt yêu tố mô hình
          </Text>
        </div>
        {!isView && (
          <Flex>
            <Button
              type="primary"
              className="mr-2"
              disabled={!isDisable}
              onClick={handleTraining}
            >
              Huấn luyện
            </Button>
            <Button type="primary" disabled={isDisable} onClick={handleSave}>
              Lưu
            </Button>
          </Flex>
        )}
      </Flex>
      {dataDetail?.name && (
        <Form
          name="setting"
          layout="horizontal"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          className="pb-2"
        >
          <Form.Item
            label="Tên danh mục cài đặt"
            name="vertical"
            rules={[
              { required: true, message: "Bắt buộc nhập tên danh mục cài đặt" },
            ]}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              placeholder="Điền tên danh mục cài đặt"
              defaultValue={dataDetail?.name}
              onChange={handleChange}
              disabled={isView}
            />
          </Form.Item>
        </Form>
      )}

      <div className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
        <p className=" font-medium text-xl pb-2">
          Yếu tố thuộc nội dung thông tin đầu vào của sinh viên
        </p>
        <Flex align="center">
          <Text>Thời gian phân tích dữ liệu</Text>
          <Text className="pl-2">{timeData?.input}</Text>
        </Flex>
      </div>
      {dataDetail?.mooc_learning_trends && (
        <div className="pt-4">
          {dataDetail?.mooc_learning_trends[0]?.categories?.map(
            (data: any, index: number) => (
              <div className="py-4 border-b" key={index}>
                <Typography.Title level={5}>
                  {data?.category[0]?.name}
                </Typography.Title>
                <div className="max-h-[200px] overflow-auto">
                  {data?.category[0]?.fields?.map((value: any) => (
                    <Checkbox
                      className="w-full m-0"
                      checked={fieldsInput[value?.id]}
                      disabled={isView}
                      name={value?.id}
                      onChange={(e) => onChangeSelectInfo(e, "input")}
                    >
                      {value?.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      )}
      {/* ------- */}
      <div className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
        <p className=" font-medium text-xl pb-2">
          Yếu tố thuộc nội dung thông tin hành vi của sinh viên
        </p>
        <Flex align="center">
          <Text>Thời gian phân tích dữ liệu</Text>
          <Text className="pl-2">{timeData?.action}</Text>
        </Flex>
      </div>
      {dataDetail?.mooc_learning_trends && (
        <div className="pt-4">
          {dataDetail?.mooc_learning_trends[1]?.categories?.map(
            (data: any, index: number) => (
              <div className="py-4 border-b" key={index}>
                <Typography.Title level={5}>
                  {data?.category[0]?.name}
                </Typography.Title>
                <div className="max-h-[200px] overflow-auto">
                  {data?.category[0]?.fields?.map((value: any) => (
                    <Checkbox
                      className="w-full m-0"
                      checked={fieldsAction[value?.id]}
                      name={value?.id}
                      disabled={isView}
                      onChange={(e) => onChangeSelectInfo(e, "action")}
                    >
                      {value?.name}
                    </Checkbox>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      )}
      {!isView && (
        <div className="mt-2">
          <Button onClick={() => setIsOpenForm(true)}>
            <PlusOutlined />
            Thêm yếu tố
          </Button>
        </div>
      )}
      <FormCreateStatistical
        isOpenForm={isOpenForm}
        handleClose={() => setIsOpenForm(false)}
        handleNotice={handleNotice}
        handleRefresh={handleRefresh}
        isEdit={true}
        fieldsOptionInput={fieldsInput}
        fieldsOptionAction={fieldsAction}
      />
    </Card>
  );
};

export default ElementSetting;
