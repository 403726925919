import {EyeOutlined} from "@ant-design/icons";
import type {ProColumns} from "@ant-design/pro-components";
import {DragSortTable} from "@ant-design/pro-components";
import {Button, message} from "antd";
import {useState} from "react";
import {getCourseDetail, putUpdateCoursePosition,} from "../../../../service/course-construct";
import AddChapter from "../../../drawer/add-chapter/AddChapter";
import ChapterCard from "../chapter-card";
import "./TreeCourseConstruct.scss";
import { SortableList } from "../../../SortableList";
import { log } from "console";

export const TreeCourseConstruct = (props: any) => {
  const [dataSource, setDataSource] = useState(props.data);
  const [dataSourceSorted, setDataSourceSorted] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const handleChangePosition = async (data: any) => {
    const dataApi = {
      sections: data,
    };
    try {
      const response = await putUpdateCoursePosition(props.id, dataApi).then(
        (res) => {
          props.handleGetDetail();
        }
      );
    } catch (error) {
    }
  };

  const handleDragChange = (newDataSource: any) => {
    setDataSource(newDataSource);
    // console.log(value);
    const data = newDataSource.map((item: any, index: number) => {
      const units = item.children?.map((itemChild: any, index: number) => {
        const unitsLastChildren = itemChild?.children?.map(
          (itemLastChild: any, index: number) => {
            return {
              id: itemLastChild.id,
              orderNumber: index + 1,
            };
          }
        );
        return {
          id: itemChild.id,
          orderNumber: index + 1,
          units: unitsLastChildren,
        };
      });

      return {
        id: item.id,
        orderNumber: index + 1,
        sequences: units,
      };
    });
    setDataSource(newDataSource);
    setDataSourceSorted(data);
    //TODO: CALL API HERE
    handleChangePosition(data);
    message.success("Di chuyển vị trí thành công");
    
  };

  const handleDragSortEnd = (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: any
  ) => {
    const data = newDataSource.map((item: any, index: number) => {
      const units = item.children?.map((itemChild: any, index: number) => {
        const unitsLastChildren = itemChild?.children?.map(
          (itemLastChild: any, index: number) => {
            return {
              id: itemLastChild.id,
              orderNumber: index + 1,
            };
          }
        );
        return {
          id: itemChild.id,
          orderNumber: index + 1,
          units: unitsLastChildren,
        };
      });

      return {
        id: item.id,
        orderNumber: index + 1,
        sequences: units,
      };
    });
    setDataSource(newDataSource);
    setDataSourceSorted(data);
    //TODO: CALL API HERE
    handleChangePosition(data);
    message.success("Di chuyển vị trí thành công");
  };

  const updateSequence = (
    idSection: number,
    idSequence: number,
    updates: any
  ) => {
    const dataDraft = dataSource || props.data;
    // Find the index of the item with the given id
    const indexSection = dataDraft?.findIndex(
      (item: any) => item.id === idSequence
    );

    const newUpdate = {
      children: updates,
    };

    // If the item is found, update its properties
    if (indexSection !== -1) {
      // Update the properties of the item
      dataDraft[indexSection] = {
        ...dataDraft[indexSection],
        ...newUpdate,
      };

      handleChangeNewSortData(dataDraft);
    }
  };

  const updateUnit = (idSection: number, idSequence: number, updates: any) => {
    const dataDraft = dataSource || props.data;
    // Find the index of the item with the given id
    const indexSection = dataDraft?.findIndex(
      (item: any) => item.id === idSection
    );

    const newUpdate = {
      children: updates,
    };

    // If the item is found, update its properties
    if (indexSection !== -1) {
      const indexSequence = dataDraft[indexSection].children?.findIndex(
        (item: any) => item.id === idSequence
      );

      if (indexSequence !== -1) {
        dataDraft[indexSection].children[indexSequence] = {
          ...dataDraft[indexSection].children[indexSequence],
          ...newUpdate,
        };
        handleChangeNewSortData(dataDraft);
      }
      // Update the properties of the item
    }
  };

  const handleDetail = async () => {
    setLoading(true);
    try {
      const response = await getCourseDetail(props.paramId).then(
        (res) => {
          const {data} = res.data;
          setDataSource(data.sections);
          props.handleGetDetail();
        }
      );
    } catch (error) {
    }
  };

  const handleChangeNewSortData = (data: any) => {
    const newData = data.map((item: any, index: number) => {
      const units = item.children?.map((itemChild: any, index: number) => {
        const unitsLastChildren = itemChild?.children?.map(
          (itemLastChild: any, index: number) => {
            return {
              id: itemLastChild.id,
              orderNumber: index + 1,
            };
          }
        );
        return {
          id: itemChild.id,
          orderNumber: index + 1,
          units: unitsLastChildren,
        };
      });

      return {
        id: item.id,
        orderNumber: index + 1,
        sequences: units,
      };
    });

    handleChangePosition(newData);
  };

  let locale = {
    emptyText: "Không có dữ liệu",
  };

  // console.log(dataSource);
  

  const columns: ProColumns[] = [
    {
      title: "",
      dataIndex: "sort",
      width: 50,
      className: "drag-visible",
      render: (_: any, record) => {
        return (
          <div className="chapter-drag-icon">
            {/* <EyeOutlined/> */}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      className: "drag-visible",
      width: 700,
      render: (_: any, record) => {
        return (
          <ChapterCard
            data={record}
            handleGetDetail={handleDetail}
            id={props.id}
            name={props.name}
            updateSequence={updateSequence}
            updateUnit={updateUnit}
          />
        );
      },
    },
  ];
  return (
    <div className="tree-course-construct">
      {dataSource && props.data.length > 0 && (
        <>
          <SortableList
            items={dataSource ? dataSource : props.data}
            className="course-list"
            onChange={handleDragChange}
            renderItem={(record: any, index: number) =>(
              <SortableList.Item className="course-list--item" id={record.id}>
                <SortableList.DragHandle />
                <div className="sortableList-card" key={record.id} >
                  <ChapterCard
                    data={record}
                    handleGetDetail={handleDetail}
                    id={props.id}
                    name={props.name}
                    updateSequence={updateSequence}
                    updateUnit={updateUnit}
                  />
                </div>
              </SortableList.Item>

            )}
          />
        </>
      )}

      <Button
        onClick={() => setIsOpenDrawer(true)}
        className="button-add-chapter"
      >
        <div className="add-button">
          <div>+</div>
          Thêm chương
        </div>
      </Button>

      <AddChapter
        id={props.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsOpenDrawer}
        open={isOpenDrawer}
        handleDetail={handleDetail}
      />
    </div>
  );
};
