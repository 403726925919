import { Button, Dropdown, Form, Layout, MenuProps, message, Modal, Space, TableColumnsType, TableProps, } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState, } from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTableCourseConstruction
  from "../../../components/course-construction/list-course-construction/filter-table/FilterTable";

import { NavigateOptions, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import "../../../styles/_variables.scss";
import {
  BookOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SendOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

import TableData from "../../../components/table-data/TableData";

import "./index.scss";
import { isEmpty } from "lodash";
import { ConstructCourseDetailType, DataType, FilterData, } from "../../../types/course-construction";
import FormFilter from "../../../components/course-construction/list-course-construction/filter-group/FormFilter";
import {
  FormCreateCourse
} from "../../../components/course-construction/list-course-construction/form-create-course/FormCreateCourse";
import {
  browseSource,
  courseDelete,
  createSource,
  getCourseDetail,
  getIndustry,
  getIndustryGroup,
  postCoursePlan,
  postCourseSearch,
  postCreateCourse,
  postDuplicateCourse,
  putUpdateCourse,
  revokeSource,
  syncSource,
} from "../../../service/course-construct";
import { formatDateV2 } from "../../../utils/converDate";
import BrowseCourseModal from "../../../components/modal/browse-course";
import SyncCourseModal from "../../../components/modal/sync-course";
import { getEnterprises } from "../../../service/assign-course-construction";
import { Enterprises } from "../../../types/assign-course-construction";

var indexDB = require("idbcache").default;

type ContentConstructionProps = {
  title?: string;
};

const ContentConstruction = (props: ContentConstructionProps) => {
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [listPlan, setListPlan] = useState<DataType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [browseData, setBrowseData] = useState<DataType>();
  const [isOpenBrowseCourseModal, setIsOpenBrowseCourseModal] = useState<boolean>(false);
  const [isOpenSyncCourseModal, setIsOpenSyncCourseModal] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [syncCourseCheckbox, setSyncCourseCheckbox] = useState<boolean>(false);

  const [filterData, setFilterData] = useState<FilterData>({
    createDate: null,
    publicDate: null,
    courseType: null,
    status: null,
  });
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const [detailData, setDetailData] = useState<ConstructCourseDetailType>({});
  const [idDetail, setIdDetail] = useState(0);

  const navigate = useNavigate();

  const handleReturnStatus = (statusCode: number) => {
    switch (statusCode) {
      case 1:
        return "Bản nháp";
      case 2:
        return "Riêng tư";
      case 3:
        return "Chờ duyệt";
      case 4:
        return "Bị trả lại";
      case 5:
        return "Chờ thẩm định";
      case 6:
        return "Thẩm định trả lại";
      case 7:
        return "Đã thẩm định";
      case 8:
        return "Đã duyệt";
      case 9:
        return "Chờ phê duyệt";
      case 10:
        return "Đã phê duyệt";
      case 11:
        return "Phê duyệt trả lại";
      case 12:
        return "Chờ xuất bản";
      case 13:
        return "Đã xuất bản";
      default:
        return "";
    }
  };
  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      default:
        return "";
    }
  };

  const [dataOptions, setDataOptions] = useState({
    dataDepartment: [
      {
        id: 1,
        name: "Công nghệ thông tin",
      },
      {
        id: 2,
        name: "Quản trị kinh doanh",
      },
    ],
    dataField: [
      {
        id: 1,
        name: "Khoa học máy tính",
      },
      {
        id: 2,
        name: "Hệ thống quản trị",
      },
    ],
    dataPlan: [
      {
        id: 1,
        name: "Kế hoạch 01",
      },
      {
        id: 2,
        name: "Kế hoạch 02",
      },
    ],
  });

  const [form] = Form.useForm();

  const getListDataFilter = () => {
    setPageNumber(1);
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: 1,
      pageSize: pageSize,
      createdDateFrom: !isEmpty(filterData.createDate?.[0])
        ? new Date(filterData.createDate?.[0]).toISOString()
        : "",
      createdDateTo: !isEmpty(filterData.createDate?.[1])
        ? new Date(filterData.createDate?.[1]).toISOString()
        : "",
      publicDateFrom: !isEmpty(filterData.publicDate?.[0])
        ? new Date(filterData.publicDate?.[0]).toISOString()
        : "",
      publicDateTo: !isEmpty(filterData.publicDate?.[1])
        ? new Date(filterData.publicDate?.[1]).toISOString()
        : "",
      status: !isEmpty(filterData.status) ? filterData.status : null,
      courseType: !isEmpty(filterData.courseType)
        ? filterData.courseType
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };

    postCourseSearch(data)
      .then((res) => {
        const { data } = res.data;
        console.log('data',data)
        setListPlan(data.data);
        setTotalRecords(data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getListData = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      createdDateFrom: !isEmpty(filterData.createDate?.[0])
        ? new Date(filterData.createDate?.[0]).toISOString()
        : "",
      createdDateTo: !isEmpty(filterData.createDate?.[1])
        ? new Date(filterData.createDate?.[1]).toISOString()
        : "",
      publicDateFrom: !isEmpty(filterData.publicDate?.[0])
        ? new Date(filterData.publicDate?.[0]).toISOString()
        : "",
      publicDateTo: !isEmpty(filterData.publicDate?.[1])
        ? new Date(filterData.publicDate?.[1]).toISOString()
        : "",
      status: !isEmpty(filterData.status) ? filterData.status : null,
      courseType: !isEmpty(filterData.courseType)
        ? filterData.courseType
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };

    postCourseSearch(data)
      .then((res) => {
        const { data } = res.data;
        setListPlan(data.data);
        setTotalRecords(data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const getDetailCourseData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getCourseDetail(JSON.stringify(id) || "0").then(
        (res) => {
          const { data } = res.data;
          setDetailData(data);
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        }
      );
    } catch (error) {
    }
  };

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    getListDataFilter();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListDataFilter();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      createDate: null,
      publicDate: null,
      courseType: null,
      status: null,
    });

    // setDataFilter({ ...dataFilter, vaiTros: [], donVis: [], trangThais: [] })
    // getAccountUsers({}).then(users => {
    //   const { data, totalRecords } = users.data.data
    //   setAccountUsers(data)
    //   setLoading(false)
    //   setTotalRecords(totalRecords)
    // }).catch(err => console.error(err))
  };

  const callApi = () => {
    message.success("Thu hồi khoá học thành công");
  };

  const deleteCourse = async (record: any) => {
    if (record.status === 13) {
      message.error("Không thể xoá khoá học đã xuất bản");
    } else {
      await courseDelete(record.id).then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Khoá học đã được xoá thành công");
          getListData();
        }
      });
    }
  };

  const randomCode = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).replace("-", "");
      }
    );
  };

  const copyData = async (record: any) => {
    await getCourseDetail(record.id).then(async (res) => {
      if (res.status === 200) {
        const obj = res.data.data;
        const listDaculties = obj.faculties.map((item: any) => {
          return item.id;
        });
        const listIndustries = obj.industries.map((item: any) => {
          return item.id;
        });
        const listAssigners = obj.assigners.map((item: any) => {
          return {
            enterpriseUUID: item.enterpriseId,
            assigners: item.assigned.map((result: any) => {
              return {
                teacherId: Number(result.assignUserId),
                instruct: result.instruct,
                construct: result.construct,
              };
            }),
            isSponsor: item.isSponsor,
          };
        });
        obj.name = record.name.concat(" (Bản sao)");
        obj.code = randomCode().replaceAll("-", "");
        delete obj.id;
        const data = {
          name: obj.name,
          code: obj.code,
          faculties: listDaculties,
          industries: listIndustries,
          coursePlanId: obj.coursePlanId,
          isSynchronizeCourse: true,
          assigns: listAssigners,
        };
        await postDuplicateCourse(record.id)
          .then((res) => {
            message.success(`Tạo bản sao thành công với tên ${data.name}`);
            getListData();
          })
          .catch((err) =>
            message.error("Bản sao của khoá học này đã được tạo")
          );
      }
    });
  };

  const revokeCourse = async (record: any) => {
    await revokeSource(record.id).then((res) => {
      if (res.status === 200) {
        message.success(`Yêu cầu thu hồi duyệt khoá học thành công`);
        getListData();
      }
    });
  };

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Xem trước khóa học",
        icon: <BookOutlined />,
      },
      {
        key: "1",
        label: "Sửa khóa học",
        icon: <EditOutlined />,
      },
      {
        key: "2",
        label: "Tạo bản sao",
        icon: <CopyOutlined />,
      },
      {
        key: "3",
        label: "Đồng bộ khóa học",
        icon: <CloudSyncOutlined />,
      },
      {
        key: "4",
        label: "Gửi duyệt khóa học",
        icon: <SendOutlined />,
      },
      {
        key: "5",
        label: "Thu hồi gửi duyệt khóa học",
        icon: <RedoOutlined />,
      },
      ...((record.status === 12 || record.status === 13) ? [
        {
          key: "6",
          label: "Chia sẻ khóa học",
          icon: <ShareAltOutlined />,
        }
      ] : []),
      {
        key: "7",
        label: "Lịch sử hoạt động",
        icon: <HistoryOutlined />,
      },
      {
        key: "8",
        label: "Xóa khóa học",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    return options1;
  };

  const gotoDetail = (record: any) => {
    navigate(`${routesConfig.contentCourseDetail}/${record.id}`, { state: { mode: "edit", record: record, name: record.name, tab: '1' }, });
  };
  const gotoSetting = (record: any) => {
    navigate(`${routesConfig.contentCourseDetail}/${record.id}`, { state: { mode: "share", record: record, name: record.name, tab: '4' }, });
  };

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "Số thứ tự",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Tên khóa học",
      dataIndex: "name",
      key: "name",
      width: 13,
      sorter: true,
      render: (_: any, record) => {
        return (
          <div
            className="table-order-course-name cursor-pointer"
            onClick={() => gotoDetail(record)}
          >
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={
                record.imagePath
                  ? record.imagePath
                  : "/images/ic_school_placeholder.svg"
              }
            />
            <div>
              <span className="course-title">{record.name}</span>
              <span>{record.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 5,
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.status === 1
                    ? "#D9D9D9"
                    : record.status === 2
                      ? "#FAAD14"
                      : record.status === 3
                        ? "#1677FF"
                        : record.status === 4
                          ? "#52C41A"
                          : "white",
              }}
            ></div>
            {handleReturnStatus(record?.status)}
          </div>
        );
      },
    },
    {
      title: "Loại khóa học",
      dataIndex: "courseType",
      key: "courseType",
      width: 5,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      width: 5,
      render: (_: any, record) => {
        return (
          <>{record.createDate ? formatDateV2(record.createDate) : null}</>
        );
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publicDate",
      key: "publicDate",
      width: 5,
      render: (_: any, record) => {
        return (
          <>{record.publicDate ? formatDateV2(record.publicDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  // xem trước khóa học
                  navigate(`${routesConfig.contentCourseDetail}/${location.id}`, { state: { record, mode: "view", tab: '1' }, })
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({ key }) => {
                    if (key === "0") {
                      indexDB.set("course-id-preview", JSON.stringify({ courseId: location.id, }), 1440);
                      window.open(`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.coursePreview}/${location.id}`, "_blank");
                    }
                    if (key === "1") {
                      setIdDetail(record.id);
                      // setIsOpened(true);
                      getDetailCourseData(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Bạn có muốn tạo bản sao cho ${record.name}`,
                        content: `Tên của bản sao khoá học là "${record.name} (bản sao)"`,
                        onOk: () => copyData(record),
                        okText: "Lưu",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: <CopyOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "3") {
                      setIsOpenSyncCourseModal(true);
                      setBrowseData(record);
                    }
                    if (key === "4") {
                      if (record.status === 3) {
                        message.error(
                          "Khoá học đang trong quá trình chờ duyệt"
                        );
                      } else {
                        setIsOpenBrowseCourseModal(true);
                        setBrowseData(record);
                      }
                    }
                    if (key === "5") {
                      Modal.confirm({
                        title: `Thu hồi gửi duyệt khoá học ${record.name}`,
                        content: `Bạn có chắc chắn muốn thu hồi gửi duyệt khoá học ${record.name}?`,
                        onOk: () => revokeCourse(record),
                        centered: true,
                        okText: "Lưu",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "6") {
                      gotoSetting(record);
                    }
                    if (key === "7") {
                      navigate(`${routesConfig.historyCourseConstruction}`, {
                        state: record,
                      });
                    }
                    if (key === "8") {
                      if (record.status !== 13) {
                        Modal.confirm({
                          title: `Xoá khoá học ${record.name}`,
                          content: `Khoá học đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá khoá học?`,
                          onOk: () => deleteCourse(record),
                          centered: true,
                          okText: "Xác nhận",
                          cancelText: "Huỷ bỏ",
                          footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                              <CancelBtn />
                              <OkBtn />
                            </>
                          ),
                        });
                      } else {
                        message.warning("Không thể xóa khóa học đã xuất bản.");
                      }
                    }
                  },
                  // else if (key === '2')handleCloseTask
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 3,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [enterprises, setEnterprises] = useState<Enterprises[]>([]);
  const [industryGroup, setIndustryGroup] = useState<Enterprises[]>([]);
  const [industry, setIndustry] = useState<Enterprises[]>([]);
  const [coursePlan, setCoursePlan] = useState<Enterprises[]>([]);
  const [dataUpdate, setDataUpdate] = useState<any>([]);
  const [dataSponsorUpdate, setDataSponsorUpdate] = useState<any>([]);
  const getNumericalNumber = (index: number, page: number, size: number) => {
    return (page - 1) * size + index + 1;
  };

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const getListEnterprises = async () => {
    const data = {
      keyword: "",
      pageNumber: 1,
      pageSize: 50,

    };
    await getEnterprises(data).then((res) => {
      const { data } = res.data;
      setEnterprises(data.data);
    });
  };

  const getListIndustryGroup = async () => {
    try {
      const response = await getIndustryGroup().then((res) => {
        const { data } = res.data;
        setIndustryGroup(data);
      });
    } catch (error) {
    }
  };

  const getListIndustry = async () => {
    try {
      const response = await getIndustry().then((res) => {
        const { data } = res.data;
        setIndustry(data);
      });
    } catch (error) {
    }
  };

  const getListCoursePlan = async () => {
    const data = {
      pageNumber: 1,
      pageSize: 1000,
    };
    try {
      const response = await postCoursePlan(data).then((res) => {
        const { data } = res.data;
        setCoursePlan(data.data);
      });
    } catch (error) {
    }
  };

  useEffect(() => {
    getListEnterprises();
    getListIndustryGroup();
    getListIndustry();
    getListCoursePlan();
  }, []);

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const saveBrowseCourse = async (approveUserId: number) => {
    const data = {
      approveUserId: approveUserId,
    };
    await browseSource(browseData?.id, data).then((res) => {
      if (res.status === 200) {
        message.success("Yêu cầu duyệt khoá học được gửi thành công");
        setIsOpenBrowseCourseModal(false);
        setClearForm(true);
        getListData();
      }
    });
  };

  const saveSyncCourse = async (coursePlanId: number, isSync: boolean) => {
    const data = {
      courseId: browseData?.id ?? "",
      coursePlanId: coursePlanId,
      isSync: isSync,
    };
    await syncSource(data).then((res) => {
      if (res.status === 200) {
        message.success("Khoá học đã được đồng bộ thành công");
        setIsOpenSyncCourseModal(false);
        setClearForm(true);
      }
    });
  };
  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEditCourse(value);
    } else {
      handleCreateCourse(value);
      // handleEditCourse(value);
    }
  };

  const handleCreateCourse = async (value: any) => {
    try {
      const dataDraft = dataUpdate.filter((item: any) => item.display);
      const dataCoop = dataDraft.map((item: any) => {
        const params = item.assigned.map((item: any) => {
          return {
            teacherId: item.assignUserId,
            instruct:
              item.instruct && item.instruct !== undefined ? true : false,
            construct:
              item.construct && item.construct !== undefined ? true : false,
          };
        });
        return {
          enterpriseUUID: item.enterpriseId,
          assigners: params,
          isSponsor: false,
        };
      });

      const dataSponsor = dataSponsorUpdate.map((item: any) => {
        const params = item.assigned.map((item: any) => {
          return {
            teacherId: item.assignUserId,
            instruct:
              item.instruct && item.instruct !== undefined ? true : false,
            construct:
              item.construct && item.construct !== undefined ? true : false,
          };
        });
        return {
          enterpriseUUID: item.enterpriseId,
          assigners: params,
          isSponsor: true,
        };
      });
      const dataApi = [...dataCoop, ...dataSponsor];

      const data =
        value.coursePlanId !== null && value.coursePlanId
          ? {
            name: value.name,
            code: value.code,
            faculties: value.industryGroup.map((item: any) => Number(item)),
            industries: value.industry.map((item: any) => Number(item)),
            coursePlanId: value.coursePlanId,
            isSynchronizeCourse: value.isSyncPlan || false,
            assigns: dataApi,
          }
          : {
            name: value.name,
            code: value.code,
            faculties: value.industryGroup.map((item: any) => Number(item)),
            industries: value.industry.map((item: any) => Number(item)),
            assigns: dataApi,
          };
      const response = await postCreateCourse(data).then((result) => {
        navigate(`${routesConfig.contentCourseDetail}/${result.data.data.id}`, { state: { mode: "create", record: { status: 1 }, tab: '1' }, });
      });
    } catch (error) {
    }
  };

  const handleEditCourse = async (value: any) => {
    try {
      const dataDraft = dataUpdate.filter((item: any) => item.display);
      const dataCoop = dataDraft.map((item: any) => {
        const params = item.assigned.map((item: any) => {
          return {
            teacherId: item.assignUserId,
            instruct: item.instruct,
            construct: item.construct,
          };
        });
        return {
          enterpriseUUID: item.enterpriseId,
          assigners: params,
          isSponsor: false,
        };
      });

      const dataSponsor = dataSponsorUpdate.map((item: any) => {
        const params = item.assigned.map((item: any) => {
          return {
            teacherId: item.assignUserId,
            instruct: item.instruct,
            construct: item.construct,
          };
        });
        return {
          enterpriseUUID: item.enterpriseId,
          assigners: params,
          isSponsor: true,
        };
      });
      const dataApi = [...dataCoop, ...dataSponsor];

      const data =
        value.coursePlanId !== null && value.coursePlanId
          ? {
            name: value.name,
            code: value.code,
            faculties: value.industryGroup.map((item: any) => Number(item)),
            industries: value.industry.map((item: any) => Number(item)),
            coursePlanId: value.coursePlanId,
            isSynchronizeCourse: value.isSyncPlan || false,
            assigns: dataApi,
          }
          : {
            name: value.name,
            code: value.code,
            faculties: value.industryGroup.map((item: any) => Number(item)),
            industries: value.industry.map((item: any) => Number(item)),
            assigns: dataApi,
          };
      const response = await putUpdateCourse(idDetail, data).then((result) => {
        // navigate success
        message.success(`Sửa khóa học ${data.name} thành công`);
        navigate(`${routesConfig.contentCourseDetail}/${idDetail}`, { state: { mode: "edit", record: detailData, tab: '1' }, });
      });
    } catch (error) {
      //   state: { mode: "edit" },
      // });
    }
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "create-course-plan-modal") {
            confirmModal();
          }

          if (name === "create-browse-course-modal") {
            saveBrowseCourse(values.lecturerName);
          }

          if (name === "create-sync-course-modal") {
            Modal.confirm({
              title: `Xác nhận đồng bộ`,
              content: `Bạn có chắc chắn muốn đồng bộ khoá học này?`,
              onOk: () => {
                saveSyncCourse(values.lecturerName, syncCourseCheckbox);
              },
              okText: "Xác nhận",
              cancelText: "Huỷ",
              centered: true,
              icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            });
          }

          if (name === "create-course-detail") {
            handleSave(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTableCourseConstruction
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            onFinish={handleSubmitSearch}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
          />
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <FormCreateCourse
          enterprises={enterprises}
          dataOptions={dataOptions}
          industryGroup={industryGroup}
          industry={industry}
          coursePlan={coursePlan}
          id={idDetail}
          detailData={detailData}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          setDetailData={setDetailData}
          setDataUpdate={setDataUpdate}
          setDataSponsorUpdate={setDataSponsorUpdate}
        />
        <BrowseCourseModal
          clearForm={clearForm}
          title={`Gửi duyệt khoá học ${browseData?.name}`}
          open={isOpenBrowseCourseModal}
          handleOk={confirmModal}
          handleCancel={() => {
            setIsOpenBrowseCourseModal(!isOpenBrowseCourseModal);
          }}
          browseData={browseData}
        />
        <SyncCourseModal
          clearForm={clearForm}
          setSyncCourseCheckbox={setSyncCourseCheckbox}
          title={`Đồng bộ khoá học ${browseData?.name}`}
          open={isOpenSyncCourseModal}
          handleOk={confirmModal}
          handleCancel={() => {
            setIsOpenSyncCourseModal(!isOpenSyncCourseModal);
          }}
          browseData={browseData}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ContentConstruction;
