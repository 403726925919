import { Button, Col, Modal, Row, Tabs, TabsProps, Typography } from "antd";
import React, { ReactNode } from "react";
import "./index.scss";
import { FilePdfOutlined, FontColorsOutlined } from "@ant-design/icons";
import Multimedia from "./multimedia";
import References from "./references";

type Props = {
  open: boolean;
  closeIcon: boolean;
  title: string;
  okText: string;
  cancelText: string;
  closeModal: (value: boolean) => void;
  confirm: () => void;
  icon: ReactNode;
  multimediaType: React.Dispatch<React.SetStateAction<string>>;
};

export default function AddLessionModal(props: Props) {
  const {
    open,
    title,
    closeIcon,
    okText,
    cancelText,
    closeModal,
    icon,
    confirm,
    multimediaType,
  } = props;

  const onOk = () => {
    confirm();
  };

  const mockData = [
    {
      id: 1,
      title: 'Bài tập, bài kiểm tra, bài thi',
      desc: 'Cung cấp cơ hội củng cố kiến thức, kỹ năng và hiểu biết của học viên trong các bài tập, bài kiểm tra, bài thi cụ thể.',
    }
  ]

  const mockDataReferences = [
    {
      id: 1,
      title: 'Tài liệu tham khảo',
      desc: 'Tải lên và trình bày các tệp PDF trong khoá học',
    }
  ]

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: `Đa phương tiện`,
      children: (
        <div className="waiting-assignment">
          <Multimedia isCloseModal={closeModal} multimediaType={multimediaType} />
        </div>
      ),
    },
    {
      key: "2",
      label: `Đánh giá`,
      children: (
        <div className="assigned">
          <Row className='row-content'>
            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              {
                mockData.map((item, index) => (
                  <Row className='review-course-list p-3' onClick={confirm} key={index + 1}>
                    <>
                      <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
                        <Row>
                          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 4 }} className='svg-icon'>
                            <FontColorsOutlined />
                          </Col>

                          <Col md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 240 }} xs={{ span: 20 }}>
                            <Typography.Title level={5}>
                              {item.title}
                            </Typography.Title>
                            <Typography.Text className="sub-title">
                              {item.desc}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  </Row>
                ))
              }
            </Col>
            {/* <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }} className='col-button'>
              <Button type="primary" onClick={confirm}>Chuyển tiếp</Button>
            </Col> */}
          </Row>

        </div>
      ),
    },
    {
      key: "3",
      label: `Tài liệu tham khảo`,
      children: (
        <div className="waiting-assignment">
          <References isCloseModal={closeModal} multimediaType={multimediaType} />
        </div>
      ),
    },
  ];

  return (
    <Modal
      className="add-lession-modal"
      title={
        <>
          {icon} <span>{title}</span>
        </>
      }
      closeIcon={closeIcon}
      centered
      open={open}
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={() => closeModal(false)}
      footer={null}
    >
      <Row gutter={16}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Tabs tabPosition={"left"} items={itemsTab} />
        </Col>
      </Row>
    </Modal>
  );
}
