import {
  ScanOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import AutoScoring from "./auto-scoring";
import ManagementListScore from "./management-table-scores";

const { Content } = Layout;
const imgPath = "/images/";

const ManagementScoreAuto = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState('1')
  const [breadHead, setBreadHead] = useState({
    titleCourse: '',
    titleExam: ''
  })

  const listBreadcrumb = [
    {
      title: "Danh sách khoá học",
    },
    {
      title: breadHead.titleCourse,
    },
    {
      title: breadHead.titleExam,
    },
    {
      title: "Quản lý chấm bài thi tự động",
    },
  ];
  const handleChange = (key: string) => {
    setKey(key)
  }
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ScanOutlined />,
      label: "Chấm điểm tự động",
      children: <AutoScoring handleChange={handleChange} setBreadHead={setBreadHead} />,
    },
    {
      key: "2",
      icon: <UnorderedListOutlined />,
      label: "Quản lý bảng điểm",
      children: <ManagementListScore />,
    },
  ];
  
  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Quản lý chấm bài thi tự động"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} activeKey={key} onChange={handleChange}/>
      </div>
    </Content>
  );
};

export default ManagementScoreAuto;
