import { Form, Input, Select } from "antd";
import FormSwitch from "../../../../../../components/form-switch/FormSwitch";
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA } from "../../../../../../constants";
import { useEffect, useState } from "react";
import { useAddLesson } from "../../../../../../stores/stores";
import { UpdateSettingsProps } from "../setting-question-course";

const { TextArea } = Input;
const { Option } = Select;

function HintSetting(props: UpdateSettingsProps) {
  const { handelUpdateSettings, handelChangeForm } = props;
  const setSettingQuestion = useAddLesson((state) => state.setSettingQuestion);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const [uri, setUri] = useState<string | undefined>(settingQuestion.uri ? settingQuestion.uri : 'https://');
  const [schema, setSchema] = useState<string | undefined>(settingQuestion.schema);

  useEffect(() => {
    handelUpdateSettings(uri, 'uri');
  }, [uri]);

  useEffect(() => {
    handelUpdateSettings(schema, 'schema');
  }, [schema]);

  const selectBefore = (
    <Select value={uri} onChange={(val) => {
      setUri(val);
    }}>
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  const handleSeparateUriSchema = (text: string) => {
    setSchema(text);
    const withS = 'https://';
    const withOutS = 'http://';
    let body = '';

    if (text.includes(withS)) {
      body = text.replace(withS, '');
      setUri(withS);
      setSchema(body);
      handelChangeForm(withS, 'uri');
      handelChangeForm(body, 'schema');
    }

    if (text.includes(withOutS)) {
      body = text.replace(withOutS, '');
      setUri(withOutS);
      setSchema(body);
      handelChangeForm(withOutS, 'uri');
      handelChangeForm(body, 'schema');
    }
  }

  const handleChange = (e: any) => {
    handleSeparateUriSchema(e.target.value);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    handleSeparateUriSchema(pastedData);
  };

  return (
    <>
      <FormSwitch name="isShowGuide" label="Hiển thị hướng dẫn" defaultValue={settingQuestion.isShowGuide} onChange={handelUpdateSettings} />
      {settingQuestion.isShowGuide ? (
        <section>
          <Form.Item name="hintContent" style={{ marginBottom: '20px' }}>
            <TextArea
              className="setting-introduce"
              placeholder="Nhập nội dung hướng dẫn"
              autoSize={{ minRows: 2, maxRows: 6 }}
              showCount maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
              defaultValue={settingQuestion.hintContent}
              onChange={(e) => {
                handelUpdateSettings(e.target.value, 'hintContent');
              }}
            />
          </Form.Item>
          <div className="setting-question-row row-area">
            <span className="row-label">Đường link</span>
            <Form.Item name="schema" style={{ marginTop: '10px' }}>
              <Input
                addonBefore={selectBefore}
                value={schema}
                onPaste={handlePaste}
                onChange={handleChange}
                
              />
            </Form.Item>
          </div>
        </section>
      ) : (
        <div className="setting-question-row row-area">
        <span className="row-label">Đường link</span>
        <Form.Item name="schema" style={{ marginTop: '10px' }}>
          <Input
            addonBefore={selectBefore}
            value={schema}
            readOnly
          />
        </Form.Item>
      </div>
      )}
    </>
  );
}

export default HintSetting;
