import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";
import { axiosConfigV2 } from "../../config/api/configApiv2";

const UPLOAD_TIMEOUT = 600000;

export const postCourseSearch: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/courses/search`, data);
};

export const getCourseDetail: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/courses/get-by-id/${id}`);
};

export const postEnrolledClass: (dataReq?: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-courses/enrolled-class/search`, dataReq)
}

export const deleteEnrolledClass: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-courses/enrolled-class/${id}`)
}

export const getDetailEnrolledClass: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-courses/enrolled-class/${id}`)
}

export const getEnrolledClassStatus: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-courses/enrolled-class/status-lookup`)
}

export const getEnrolledClassForm: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-courses/enrolled-class/form-lookup`)
}

export const createEnrolledClass: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/enrolled-class`, data)
}

export const addStudentEnrolledClass: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/add-students-add-to-enroll-class`, data)
}

export const updateEnrolledClass: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.put(`/mooc-courses/enrolled-class/${id}`, data)
}

export const getEnrolledInstructorTeacherLookup: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-courses/instructor-teacher-lookup/${id}`)
}

export const getCourseRoadmap: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/search-course-roadmap`, data)
}

export const updateCourseRoadmap: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/course-roadmap-update `, data)
}

// Categories
export const getIndustryGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry-group`)
}

export const getIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry`)
}

export const postCoursePlan: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/course-plans/course-plan-by-user`, data)
}

export const getSectionDetail: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/course-section/get-config-course-resource/${id}`);
};

export const getSquenceDetail: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/course-sequence/get/${id}`);
};

export const getUnitDetailSetting: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-course-unit/${id}`);
};

// Add
export const postCreateSection: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/course-section/create-course-resource`, data)
}

export const postCreateSequence: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/course-sequence/create`, data)
}

export const postCreateUnit: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/courses/create-unit`, data)
}

//Edit
export const putCourseUpdateSection: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-section/update-course-resource`, data)
}

export const putCourseUpdateSequence: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-sequence/update`, data)
}

export const putCourseUpdateUnit: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/courses/update-unit/${id}`, data)
}

//setting Instructions
export const sectionsettingInstructions: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/course-section/update-config`, data)
}

export const lessonSettingInstructions: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/course-sequence/update-config`, data)
}

export const lessonUnitInstructions: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/mooc-course-unit/update-config`, data)
}

// Edit Course
export const putUpdateCourse: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/courses/update-course/${id}`, data)
}

export const publishCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-courses/publish`, data)
}

export const postCreateCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/courses/create-course`, data)
}

export const putUpdateCoursePosition: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/courses/update-course-position/${id}`, data)
}


// Delete
export const deleteCourseSection: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/course-section/delete-course-resource/${id}`)
}

export const deleteCourseSequence: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/course-sequence/delete-course-sequence/${id}`)
}

export const deleteCourseUnit: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/courses/delete-unit/${id}`)
}

export const downloadCourseUnit: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-block-quiz/export/excel/${id}`)
}

// Publish
export const publishSection: (sectionId: number) => Promise<AxiosResponse<any>> = (sectionId) => {
  return axiosConfigV2.put(`/course-section/publish-section/${sectionId}`)
}

export const publishSequence: (sequenceId: number) => Promise<AxiosResponse<any>> = (sequenceId) => {
  return axiosConfigV2.put(`/course-sequence/publish-sequence/${sequenceId}`)
}

export const publishUnit: (unitId: number) => Promise<AxiosResponse<any>> = (unitId) => {
  return axiosConfigV2.put(`/mooc-course-unit/publish-unit/${unitId}`)
}

//UC 288-293
export const courseDelete: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.put(`/courses/delete/${id}`);
};

export const createSource: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/courses/create-course`, data);
};

export const postDuplicateCourse: (id: any) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.post(`/mooc-courses/duplicate-course/${id}`);
};

export const revokeSource: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.put(`/courses/revoke-approve/${id}`);
};

export const browseSource: (
  id: number | undefined,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.put(`/v1/courses/approve-course/${id}`, data);
};

export const syncSource: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.put(`/courses/course-sync`, data);
};

export const getHistoryCourseConstruction: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/courses/history-active`, data);
};

export const getListLecturerByCourse: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/courses/lecturers-by-course/${id}`);
};

export const getCoursePlanByUser: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/course-plans/course-plan-by-user`, data);
};

export const getContentBrowseList: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/approve-search`, data);
};

export const getApproveInformationDetail: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-courses/get-approved-information/${id}`);
};


export const getCourseReviewList: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/search-apprise-by-user`, data);
};

export const getAppriseGroup: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-courses-apprise/get-apprise-group/${id}`);
};
export const getAppraisalBoardMemberList: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.get(`/mooc-courses-apprise/list-user-apprise-group?${data}`,);
};

export const getCourseApprovalList: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/search-after-apprise-by-user`, data);
};

export const sendApprove: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-courses/approve`, data)
}

export const approveApi: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/update-apprise-result-course`, data)
}

export const approveApiEnd: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/update-apprise-form-course`, data)
}

export const saveAndPublish: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/update-after-apprise-form-course`, data)
}

export const getCourseEvaluationDetail: (data: string) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.get(`/approve-form/get-approve-form-course?${data}`);
};

export const getCourseAllReview: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/course/structure/apprise-details/${id}`);
};

export const createCourseByScrom: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-scorm/create/v2`, data, { timeout: UPLOAD_TIMEOUT });
};

export const createCourseByVideo: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-video/create/v2`, data);
};

export const createCourseByXAPI: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-xapi/create/v2`, data, { timeout: UPLOAD_TIMEOUT });
};

export const createCourseByPDF: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-pdf/create/v2`, data);
};

export const createCourseByPDFReference: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-reference/create/v2`, data);
};

export const createCourseByAudio: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-mp3/create/v2`, data);
};

export const createCourseByText: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/course-unit-text/create/v2`, data);
};

export const createReviewCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/create-unit-quiz`, data);
};

export const editReviewCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-course-block-quiz/update-unit-quiz`, data)
}

export const getDetailQuiz: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-block-quiz/get-quiz-by-unitId/${id}`)
}

export const getAllUnitCourse: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-course-block-quiz/get-all-unit-by-course/${id}`);
};

export const getSectionList: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-courses/get-by-id/${id}`)
};


export const getUnitDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-unit/get-unit/${id}`)
};

export const getVideoDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-video/get/${id}`)
};

export const getXapiDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-xapi/get/${id}`)
};

export const getPdfDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-pdf/get/${id}`)
};

export const getMp3Detail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-mp3/get/${id}`)
};

export const getTextDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-text/get/${id}`)
};

export const getReferenceDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-reference/get/${id}`)
};

export const getScormDetail: (id: number | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-unit-scorm/get/${id}`)
};

export const editVideoUnit: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-video/update/v2`, data)
}

export const editXapiUnit: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-xapi/update/v2`, data, { timeout: UPLOAD_TIMEOUT })
}

export const editPdfUnit: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-pdf/update/v2`, data)
}

export const editMp3Detail: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-mp3/update/v2`, data)
}

export const editTextDetail: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-text/update/v2`, data)
}

export const editReferenceDetail: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-reference/update/v2`, data)
}

export const editScormUnit: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/course-unit-scorm/update/v2`, data, { timeout: UPLOAD_TIMEOUT })
}
export const updateGroupQuiz: (id: number | undefined, data: any) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.put(`/mooc-course-block-quiz/group/${id}`, data)
};

export const createCourseBlockQuiz: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz`, data)
};

export const updateCourseBlockQuiz: (id: string | undefined, data: any) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.put(`/mooc-course-block-quiz?id=${id}`, data)
};

export const getQuizById: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-block-quiz/get-quiz-by-id/${id}`)
};

export const deleteQuizById: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-course-block-quiz?id=${id}`)
};

export const uploadFileImageQuiz: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.post(`/mooc-course-block-quiz/upload-file`, data)
};

export const postCreateGroup: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/group`, data);
};


export const postCreateSurveyGroup: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-group-question/create`, data);
};

export const getQuestionBank: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-question-bank`, data);
};

export const getQuizBank: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-question-bank`, data);
};

export const getSampleTemplateExcel: (isEnterprise: any, config?: any) => Promise<AxiosResponse<any>> = (isEnterprise, config) => {
  return axiosConfigV2.get(`/courses/config/download-template-share-course/${isEnterprise}`, config)
}

export const getSampleTemplateUsersExcel: (config?: any) => Promise<AxiosResponse<any>> = (config) => {
  return axiosConfigV2.get(`/mooc-courses/enrolled-class/import-user/get-add-user-template`, config)
}


export const getListStudentCourse: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-courses/enrolled-class/search-student`, dataReq)
}
export const getListStudentAddEnrolled: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-courses/search-students-add-enroll-class`, dataReq)
}

export const getListVideoOption: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/file-manager/get-all-file-video`, data)
}

export const getListScormOption: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/file-manager/get-all-file-scorm`, data)
}

export const getListPdfOption: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/file-manager/get-all-file-pdf`, data)
}

export const getListMp3Option: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/file-manager/get-all-file-mp3`, data)
}

export const getListReferenceOption: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/file-manager/get-all-file-reference`, data)
}

export const deleteAllShareCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/courses/config/delete-all-share-course`, data)
}

