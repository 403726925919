import { isEmpty } from "lodash";
import { QuestionTypeEnum } from "../constants";

export const renderStatusCourse = (status: any) => {
  switch (status) {
    case null:
      return (
        <>
          <div className="dot color-wait" />
          Chờ quản trị khoá học duyệt
        </>
      );
    case 3:
      return (
        <>
          <div className="dot color-wait" />
          Chờ quản trị khoá học duyệt
        </>
      );
    case 4:
      return (
        <>
          <div className="dot color-refuse" />
          Quản trị khoá học trả lại
        </>
      );
    case 5:
      return (
        <>
          <div className="dot color-wait" />
          Chờ hội đồng thẩm định duyệt
        </>
      );
    case 6:
      return (
        <>
          <div className="dot color-refuse" />
          Hội đồng thẩm định không duyệt
        </>
      );
    case 7:
      return (
        <>
          <div className="dot color-done" />
          Chờ tổ chuyên môn duyệt
        </>
      );
    // case 8:
    //   return (
    //     <>
    //       <div className="dot color-done" />
    //       Đã duyệt
    //     </>
    //   );
    // case 9:
    //   return (
    //     <>
    //       <div className="dot color-wait" />
    //       Chờ tổ chuyên phê duyệt
    //     </>
    //   );
    // case 10:
    //   return (
    //     <>
    //       <div className="dot color-done" />
    //       Chờ xuất bản
    //     </>
    //   );
    case 11:
      return (
        <>
          <div className="dot color-refuse" />
          Tổ chuyên môn trả lại
        </>
      );
    case 12:
      return (
        <>
          <div className="dot color-wait" />
          Chờ xuất bản
        </>
      );
    case 13:
      return (
        <>
          <div className="dot color-done" />
          Đã xuất bản
        </>
      );
    default:
      return "";
  }
};

export const renderQuestionNameByType = (type: number) => {
  switch (type) {
    case QuestionTypeEnum.CHOOSE_TEXT:
      return "Câu hỏi nhiều lựa chọn văn bản";
    case QuestionTypeEnum.CHOOSE_IMAGE:
      return "Câu hỏi nhiều lựa chọn ảnh";
    case QuestionTypeEnum.CHOOSE_VIDEO:
      return "Câu hỏi nhiều lựa chọn video";
    case QuestionTypeEnum.TRUE_OR_FALSE:
      return "Câu hỏi dạng đúng sai";
    case QuestionTypeEnum.DROP_DOWN:
      return "Câu hỏi dạng danh sách";
    case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
      return "Câu hỏi dạng nhiều câu trả lời văn bản";
    case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
      return "Câu hỏi dạng nhiều câu trả lời ảnh";
    case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
      return "Câu hỏi dạng nhiều câu trả lời video";
    case QuestionTypeEnum.CONNECT:
      return "Câu hỏi dạng kết nối";
    case QuestionTypeEnum.SHORT_TEXT:
      return "Câu hỏi trả lời dạng văn bản ngắn";
    case QuestionTypeEnum.FILE_UPLOAD:
      return "Câu hỏi trả lời bằng file đính kèm";
    case QuestionTypeEnum.VIDEO:
      return "Câu hỏi trả lời bằng ghi hình";
    case QuestionTypeEnum.MP3:
      return "Câu hỏi trả lời bằng ghi âm";
    default:
      break;
  }
};

export const renderQuestionDescriptionByType = (type: number) => {
  switch (type) {
    case QuestionTypeEnum.CHOOSE_TEXT:
      return "Là loại câu hỏi có nhiều lựa chọn, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_IMAGE:
      return "Là loại câu hỏi có nhiều lựa chọn hình ảnh kèm mô tả, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_VIDEO:
      return "Là loại câu hỏi có nhiều lựa chọn video kèm mô tả, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.TRUE_OR_FALSE:
      return "Là loại câu hỏi có 02 lựa chọn và chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.DROP_DOWN:
      return "Là loại câu hỏi có nhiều lựa chọn trong danh sách, nhưng chỉ có 01 đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
      return "Là loại câu hỏi có nhiều lựa chọn, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
      return "Là loại câu hỏi có nhiều lựa chọn hình ảnh kèm mô tả, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
      return "Là loại câu hỏi có nhiều lựa chọn video kèm mô tả, và có nhiều đáp án là đúng";
    case QuestionTypeEnum.CONNECT:
      return "Là loại câu hỏi có nhiều cặp lựa chọn cần được kết nối với nhau";
    case QuestionTypeEnum.SHORT_TEXT:
      return "Là loại câu hỏi cho phép trả lời bằng cách nhập đoạn văn bản ngắn, có nhiều đáp án là đúng";
    case QuestionTypeEnum.FILE_UPLOAD:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file đính kèm. hình(video)";
    case QuestionTypeEnum.VIDEO:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file ghi hình(video)";
    case QuestionTypeEnum.MP3:
      return "Là loại câu hỏi cho phép trả lời bằng cách gửi lên file ghi âm";
    default:
      break;
  }
};
