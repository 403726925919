import { Col, Form, Input, Modal, Row, TreeSelect } from "antd";
import { DataType } from "../../../../../../types/survey-managment/listSurvey";
import {
  DetailTypeMoocCourseConstructionPlan,
  DataTypeMoocCourseConstructionPlan,
  FilterDataMoocCourseConstructionPlan,
} from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../../../../../stores/stores";
import { HelpService } from "../../../../../../service/helper.service";
import { get } from "lodash";
import {
  getApprover,
  sendApproval,
} from "../../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";

type Props = {
  open: boolean;
  handleCancel: () => void;
  browseData?: DataTypeMoocCourseConstructionPlan;
};

export default function ApproveCouresConstruction(props: Props) {
  const { open, handleCancel, browseData } = props;
  const userId = useAuthStore((states) => states.user?.id);
  const [listApprover, setListApprover] = useState<[]>([]);
  const [approvalLevelName, setApprovalLevelName] = useState<string | null>(
    null
  );
  const [isOrganization, setIsOrganization] = useState<boolean | null>(null);
  const [configurationId, setConfigurationId] = useState<number | null>(null);

  const [form] = Form.useForm();
  const helpService = new HelpService();
  const handleStatusLevel = (approvalLevelName: any) => {
    switch (approvalLevelName) {
      case "Quản trị khóa học":
        return 2;
      case "Tổ chuyên môn":
        return 5;
      case "Hội đồng thẩm định test":
        return 8;
      default:
        return 2;
    }
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          courseId: browseData?.id,
          submitterId: userId,
          configurationId: configurationId,
          constructionStatus: 1,
          organizationId: null,
          individualId: null,
        };
        if (isOrganization) {
          data.organizationId = values.individual_id;
        } else {
          data.individualId = values.individual_id;
        }
        sendApproval(data)
          .then((res) => {
            const { data, statusCode, message } = res.data;
            if (statusCode === 200) {
              helpService.successMessage(get(data, "message", message));
            }
            form.resetFields();
            handleCancel();
          })
          .catch((error) => {
            form.resetFields();
            handleCancel();
          });
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      getListApprover();
    }
  }, [open]);

  const getListApprover = async () => {
    try {
      const data = {
        courseId: browseData?.id,
        approvalType: 2,
      };
      const response = await getApprover(data).then((res) => {
        console.log(res);
        const { result, configurationId, approvalLevelName, isOrganization } =
          res.data.data;
        setListApprover(result);
        setApprovalLevelName(approvalLevelName);
        setIsOrganization(isOrganization);
        setConfigurationId(configurationId);
      });
    } catch (error) { }
  };
  const validations = {
    organization_member_id: {
      required: true,
      message: `Vui lòng chọn tên ${isOrganization ? "tổ chức" : "cán bộ"
        } duyệt khảo sát`,
    },
  };
  const filterTreeNode = (input: string, treeNode: any) => {
    const title = treeNode.title?.toString().toLowerCase() ?? "";
    return title.includes(input.trim().toLowerCase());
  };
  return (
    <>
      <Modal
        title={`Gửi duyệt ${browseData?.registrationName}`}
        open={open}
        onOk={onOk}
        onCancel={handleCancel}
        centered
        okText="Gửi yêu cầu"
        cancelText="Huỷ"
      >
        <Form
          layout="vertical"
          form={form}
          name="approveModal"
          initialValues={{
            individual_id: null,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="individual_id"
                label={`Đăng ký kế hoạch khoá học của bạn sẽ được gửi tới ${approvalLevelName}:`}
                rules={[validations.organization_member_id]}
                hasFeedback
              >
                <TreeSelect
                  showSearch
                  placeholder={`Nhập tên ${isOrganization ? "tổ chức" : "cán bộ"
                    } duyệt đăng ký`}
                  allowClear
                  treeData={listApprover}
                  filterTreeNode={filterTreeNode}
                  notFoundContent={
                    <span style={{ textAlign: "center" }}>
                      <p>
                        <img
                          className="image-empty-data"
                          src="/images/empty-img-gray.png"
                          alt="empty-img"
                        ></img>
                      </p>
                      <p className="nodata-text">Không tìm thấy</p>
                    </span>
                  }
                  treeNodeFilterProp="title"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
