import { Col, Form, Layout, Row, Spin, Tag } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./lecturer-management-detail.scss"
import { getMoocLecturerById } from "../../../../service/category-management/lecturer-management";

const { Content } = Layout;
const imgPath = "/images/";

const LecturerManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [lecturerDetail, setLecturerDetail] = useState<any>();

    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.lecturerManagement}`}>
            <span>Quản lý giảng viên</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
      ];

    // Get detail lecturer
    const getLecturerUpdate = async (lecturerId: number) => {
        setLoadingDetail(true);
        try {
            await getMoocLecturerById(lecturerId).then((res) => {
                if(res.status === 200) {
                    const { data } = res.data;
                    setLecturerDetail(data);
                    setTimeout(() => {
                        setLoadingDetail(false);
                    }, 333);
                }
            })
        } catch (err) {
            setLoadingDetail(false);
            console.error(err);
        }
    };

    // Chức vụ
    const regency = location.state.record?.authPositions.map((item: any) => {
        return(item.name);
    })

    // Trình độ học vấn
    const academicLevel = lecturerDetail?.moocTeacherProfiles.map((item: any) => {
        return(item.levelName);
    });

    // Chuyên ngành
    const specialized = lecturerDetail?.industryGroupInfo.children.map((item: any) => {
        return(item.name);
    });

    useEffect(() => {
        getLecturerUpdate(location.state.record.id);
    }, [location.state.record])

    
    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={isShowSubmitButton}
                />
                <div className="wrapper-settings">
                    <section className="setting-lecturer">
                        <CustomCard
                            className="lecturer-card"
                            title="Thông tin giảng viên">
                            {loadingDetail ? 
                                <div className="statistic-course-content statistic-course-loading">
                                    <Spin size="large" />
                                </div> 
                                : 
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="name" label="Tên giảng viên">
                                            <p className="lecturer-content">{lecturerDetail?.name}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            className="setting-item" name="university" label="Tên trường">
                                            <p className="lecturer-content">{lecturerDetail?.universityName}</p>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={12}>
                                        <Form.Item
                                            className="setting-item item-label" name="code" label="Mã giảng viên">
                                            <p className="lecturer-content">{lecturerDetail?.code}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            className="setting-item item-label" name="gender" label="Giới tính">
                                            <p className="lecturer-content">{lecturerDetail?.gender}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            className="setting-item item-label" name="department" label="Khoa">
                                            <p className="lecturer-content">{lecturerDetail?.industryGroupInfo.name}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            className="setting-item item-label" name="domicile" label="Nguyên quán">
                                            <p className="lecturer-content">{lecturerDetail?.provinceDto.name}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="specialized" label="Chuyên ngành">
                                            <div className="lecturer-content">
                                                {specialized?.map((item: any) => (
                                                    <Tag style={{marginBottom: 5}} key={item}>{item}</Tag>
                                                ))}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="academicLevel" label="Trình độ học vấn">
                                            <div className="lecturer-content">
                                                {academicLevel?.map((item: any) => (
                                                    <Tag style={{marginBottom: 5}} key={item}>{item}</Tag>
                                                ))}
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="regency" label="Chức vụ">
                                            <div className="lecturer-content">
                                                {regency?.map((item: any) => (
                                                    <Tag style={{marginBottom: 5}} key={item}>{item}</Tag>
                                                ))}
                                            </div>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="email" label="Email">
                                            <p className="lecturer-content">{lecturerDetail?.email}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="phone" label="Điện thoại liên hệ">
                                            <p className="lecturer-content">{lecturerDetail?.phoneNumber}</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </CustomCard>
                    </section>
                </div>
            </Content>
        </>
    )
}

export default LecturerManagementDetail;