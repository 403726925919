// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-time .time-register--form .ant-form-item .ant-form-item-control-input-content {
  gap: 16px;
  display: flex; }

.survey-managment-time .time-register--form .ant-form-item .ant-picker {
  width: 100%; }

.survey-managment-time .course-prerequisite--form .ant-form-item {
  margin-bottom: 0; }

.survey-managment-time .form-select--time {
  display: flex;
  gap: 16px; }
  .survey-managment-time .form-select--time .form-item--date {
    width: 60%; }
  .survey-managment-time .form-select--time .form-item--time {
    width: 40%; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/setting-survey/time-survey/index.scss"],"names":[],"mappings":"AAAA;EAIQ,SAAa;EACb,aAAa,EAAA;;AALrB;EASQ,WAAW,EAAA;;AATnB;EAgBM,gBAAgB,EAAA;;AAhBtB;EAqBI,aAAa;EACb,SAAa,EAAA;EAtBjB;IAyBM,UAAU,EAAA;EAzBhB;IA6BM,UAAU,EAAA","sourcesContent":[".survey-managment-time {\n  .time-register--form {\n    .ant-form-item {\n      .ant-form-item-control-input-content {\n        gap    : 16px;\n        display: flex;\n      }\n\n      .ant-picker {\n        width: 100%;\n      }\n    }\n  }\n\n  .course-prerequisite--form {\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n  }\n\n  .form-select--time {\n    display: flex;\n    gap    : 16px;\n\n    .form-item--date {\n      width: 60%;\n    }\n\n    .form-item--time {\n      width: 40%;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
