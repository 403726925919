import React, { FunctionComponent, useEffect, useState } from "react";
import { message } from "antd/lib";
import { get, isEmpty } from "lodash";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { UrlPattern, regexEmail } from "../../../../../../utils/regex";
import type { DatePickerProps } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { Input } from "antd";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import FormInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormRadio from "../../../../../../components/form-radio/FormRadio";
import grapesjs, { Block, Editor } from "grapesjs";
import GjsEditor, { useEditor, WithEditor } from "@grapesjs/react";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsTailWind from "grapesjs-tailwind";
import gjsNavbar from "grapesjs-navbar";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsStyleBg from "grapesjs-style-bg";
import gjsStyleFilter from "grapesjs-style-filter";
import gjsStyleGradient from "grapesjs-style-gradient";
import {
  getHirachyId,
  getHirachyById,
  getMoocApprovalConfigurationById,
  getLookupAuthGroup,
  getLookupMoocOrganizationGroup,
} from "../../../../../../service/course-plan-managment/mooc-approval-configuration/mooc-approval-configuration-api";

import { DetailTypeMoocApprovalConfiguration } from "../../../../../../types/course-plan-managment/mooc-approval-configuration";

const validations = {
  approvalLevelName: {
    required: true,
    message: "Vui lòng nhập tên cấp phê duyệt",
  },
  approvalType: {
    required: true,
    message: "Vui lòng chọn loại cấp phê duyệt",
  },
  isOrganization: {
    required: true,
    message: "Vui lòng chọn nhóm phê duyệt",
  },
  hierarchyId: {
    required: false,
    message: "Vui lòng chọn khoa Title of hierarchy_id áp dụng",
  },
};

type FormCreateMoocApprovalConfigurationProps = {
  onFinish?: any;
  dataOptions?: any;
  id?: any;
  detailData?: DetailTypeMoocApprovalConfiguration;
  isOpened?: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData?: React.Dispatch<
    React.SetStateAction<DetailTypeMoocApprovalConfiguration>
  >;
  setDataUpdate?: (data: any) => void;
  setDataSponsorUpdate?: (data: any) => void;
};

export const FormCreateMoocApprovalConfiguration: FunctionComponent<
  FormCreateMoocApprovalConfigurationProps
> = ({
  onFinish,
  dataOptions,
  id,
  detailData,
  isOpened,
  setIsOpened,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
    const dateFormat = "DD/MM/YYYY";
    const { TextArea } = Input;
    const onChange: DatePickerProps["onChange"] = (date, dateString) => { };
    const [disableInvalid, setDisableInvalid] = useState(false);
    const [disableOrgan, setDisableOrgan] = useState(false);
    const [form] = Form.useForm();
    const [error, setError] = useState<any>({});
    const [detailDataEdit, setDetailDataEdit] =
      useState<DetailTypeMoocApprovalConfiguration>(detailData!);

    useEffect(() => {
      form.resetFields();
      if (detailData) {
        if (detailData?.isOrganization === true) {
          setDisableInvalid(true);
          setDisableOrgan(false);
        } else {
          setDisableOrgan(true);
          setDisableInvalid(false);
        }
        if (detailData?.approvalType) {
          try {
            getHirachyId(detailData?.approvalType)
              .then((res) => {
                const { data } = res.data;
                if (data.length === 0) {
                  setLstHirachyId([]);
                } else {
                  const dataFormat = data.map((item: any) => {
                    return {
                      id: item.id,
                      name: item.approvalLevelName,
                    };
                  });
                  setLstHirachyId(dataFormat);
                }
              })
              .catch((err) => {
                console.log(err);
                message.error("Có lỗi chưa xác định");
              });
          } catch (error) {
            console.log(error);
            message.error("Có lỗi chưa xác định");
          }
        }
        form.setFieldsValue({
          id: detailData?.id || "",
          approvalLevelName: detailData?.approvalLevelName || "",
          approvalType: detailData?.approvalType || "",
          isOrganization: detailData?.isOrganization === true ? 1 : "0",
          hierarchyId: detailData?.hierarchyId || "",
          individualGroupId: detailData?.individualGroupId || "",
          organizationalGroupId: detailData?.organizationalGroupId || "",
          createdDate: dayjs(detailData?.createdDate) || "",
          updatedDate: dayjs(detailData?.updatedDate) || "",
          moocApprovalConfiguration: detailData?.moocApprovalConfiguration || "",
          otherMoocApprovalConfiguration:
            detailData?.otherMoocApprovalConfiguration || "",
        });
      }
    }, [detailData]);

    // useEffect(() => {
    //   form.resetFields();
    //   if (id && id != 0) getDetailMoocApprovalConfigurationData();
    // }, [id]);

    const navigate = useNavigate();
    const onStartInput = () => {
      if (false) {
        //setError({ ...error, [`lecturerName`]: true });
        return;
      } else {
        form.submit();
      }
    };

    const getDetailMoocApprovalConfigurationData = async () => {
      try {
        await getMoocApprovalConfigurationById(id)
          .then((res) => {
            const { detailData } = res.data;
            form.setFieldsValue({
              id: detailData?.id || "",
              approvalLevelName: detailData?.approvalLevelName || "",
              approvalType: detailData?.approvalType || "",
              isOrganization: detailData?.isOrganization || "",
              hierarchyId: detailData?.hierarchyId || "",
              individualGroupId: detailData?.individualGroupId || "",
              organizationalGroupId: detailData?.organizationalGroupId || "",
              createdDate: dayjs(detailData?.createdDate) || "",
              updatedDate: dayjs(detailData?.updatedDate) || "",
              moocApprovalConfiguration:
                detailData?.moocApprovalConfiguration || "",
              otherMoocApprovalConfiguration:
                detailData?.otherMoocApprovalConfiguration || "",
            });
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    };

    // forgein key function call apis
    const [lstIndividualGroupId, setLstIndividualGroupId] = useState<any[]>([]);
    const [lstApprovalLType, setLstApprovalType] = useState<any[]>([
      {
        id: 1,
        name: "Khảo sát",
      },
      {
        id: 2,
        name: "Đăng ký kế hoạch khóa học",
      },
      {
        id: 3,
        name: "Lập kế hoạch khóa học",
      },
    ]);
    const [lstOrganization, setLstOrganization] = useState<any[]>([
      {
        id: 0,
        name: "Cá nhân",
      },
      {
        id: 1,
        name: "Tổ chức",
      },
    ]);
    const [lstOrganizationalGroupId, setLstOrganizationalGroupId] = useState<
      any[]
    >([]);
    const [lstHirachyId, setLstHirachyId] = useState<any>([]);
    const getListLstIndividualGroupId = async () => {
      try {
        await getLookupAuthGroup()
          .then((res) => {
            const { data } = res.data;
            setLstIndividualGroupId(
              data.map((item: any) => {
                if (!("name" in item)) item["name"] = item["id"];
                return item;
              })
            );
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    };
    const handleOnChangeInvdividual = (e: any) => { };
    const handleOnChangeOrganizationl = (e: any) => { };
    const getListLstOrganizationalGroupId = async () => {
      try {
        await getLookupMoocOrganizationGroup()
          .then((res) => {
            const { data } = res.data;
            setLstOrganizationalGroupId(
              data.map((item: any) => {
                if (!("group_name" in item)) item["group_name"] = item["id"];
                return {
                  id: item["id"],
                  name: item["group_name"],
                };
              })
            );
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    };
    const handleOnChangApprovalType = async (e: any) => {
      if (e) {
        try {
          await getHirachyId(e)
            .then((res) => {
              const { data } = res.data;
              if (data.length === 0) {
                setLstHirachyId([]);
              } else {
                const dataFormat = data.map((item: any) => {
                  return {
                    id: item.id,
                    name: item.approvalLevelName,
                  };
                });
                setLstHirachyId(dataFormat);
              }
            })
            .catch((err) => {
              console.log(err);
              message.error("Có lỗi chưa xác định");
            });
        } catch (error) {
          console.log(error);
          message.error("Có lỗi chưa xác định");
        }
      }
    };
    const handleOnChangOrganization = (e: any) => {
      if (e == 0) {
        setDisableInvalid(false);
        setDisableOrgan(true);
        form.setFieldsValue({
          organizationalGroupId: null,
        });
      } else if (e == 1) {
        setDisableInvalid(true);
        setDisableOrgan(false);
        form.setFieldsValue({
          individualGroupId: null,
        });
      } else {
        setDisableInvalid(false);
        setDisableOrgan(false);
        form.setFieldsValue({
          individualGroupId: null,
        });
        form.setFieldsValue({
          organizationalGroupId: null,
        });
      }
    };
    useEffect(() => {
      getListLstIndividualGroupId();
      getListLstOrganizationalGroupId();
    }, []);

    return (
      <>
        <DrawerCustom
          title={
            id && id !== 0
              ? "Chỉnh sửa Cấu hình phê duyệt"
              : "Thêm mới Cấu hình phê duyệt"
          }
          open={isOpened}
          onClose={() => {
            setIsOpened(false);
            form.resetFields();
          }}
          extraNode={
            <Space>
              <Button
                onClick={() => {
                  setIsOpened(false);
                  //setDetailData({});
                  form.resetFields();
                }}
                className="btn btn-outlined"
              >
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={onStartInput}
              >
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={736}
        >
          <Form
            className="form-create-edit"
            layout={"vertical"}
            form={form}
            onFinish={onFinish}
            name="create-edit-mooc-approval-configuration-detail"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="approvalLevelName"
                  rules={[validations.approvalLevelName]}
                  label="Tên cấp phê duyệt"
                >
                  <FormInput placeholder="Tên cấp phê duyệt" />
                </Form.Item>
                <span className="label-down-text"></span>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="approvalType"
                  label="Loại cấp phê duyệt"
                  rules={[validations.approvalType]}
                >
                  <FormItemDropdown
                    onChange={(e) => handleOnChangApprovalType(e)}
                    options={lstApprovalLType}
                    allowClear={true}
                    placeholder="Loại cấp phê duyệt"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="isOrganization"
                  label="Thuộc nhóm"
                  rules={[validations.isOrganization]}
                >
                  <FormItemDropdown
                    onChange={(e) => handleOnChangOrganization(e)}
                    options={lstOrganization}
                    allowClear={true}
                    placeholder="Nhóm phê duyệt"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="hierarchyId" label="Cấp phê duyệt trước đó">
                  <FormItemDropdown
                    options={lstHirachyId}
                    allowClear={true}
                    placeholder="Chọn cấp phê duyệt trước đó"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="individualGroupId" label="Nhóm cán bộ phê duyệt">
                  <FormItemDropdown
                    onChange={(e) => handleOnChangeInvdividual(e)}
                    options={lstIndividualGroupId}
                    allowClear={true}
                    placeholder="Chọn nhóm cán bộ phê duyệt"
                    disabled={disableInvalid}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="organizationalGroupId"
                  label="Nhóm tổ chức phê duyệt"
                >
                  <FormItemDropdown
                    onChange={(e) => handleOnChangeOrganizationl(e)}
                    options={lstOrganizationalGroupId}
                    allowClear={true}
                    placeholder="Chọn nhóm tổ chức phê duyệt"
                    disabled={disableOrgan}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </DrawerCustom>
      </>
    );
  };
