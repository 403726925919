import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  Tooltip,
  message,
} from "antd";
import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTableCourseConstruction from "../../../components/course-construction/assign-course-construction/filter-table/FilterTable";
import {
  DataType,
  DetailCourseData,
} from "../../../types/assign-course-construction";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import {
  AntDesignOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  StopOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import FormFilter from "../../../components/course-construction/assign-course-construction/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import { MockDataAssignCourseConstruction } from "../../../components/course-construction/assign-course-construction/mock-data";

import "./index.scss";
import { isEmpty, set } from "lodash";
import dayjs from "dayjs";
import React from "react";
import CourseAssignmentModal from "../../../components/modal/course-assignment";
import {
  assignMultiCourse,
  getListAssign,
  revokeAssign,
} from "../../../service/assign-course-construction";
import { formatDate, formatDateToIsoString } from "../../../utils/converDate";
import { FilterData } from "../../../components/course-construction/assign-course-construction/filter-group/filter";
import moment from "moment";

type CourseConstruction = {
  title?: string;
};

const AssignCourseConstruction = (props: CourseConstruction) => {
  const [tab, setTab] = useState("1")

  const { title } = props
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [listPlan, setListPlan] = useState<DataType[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [countFalseRecords, setCountFalseRecords] = useState<number>(0)
  const [countTrueRecords, setCountTrueRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [clearForm, setClearForm] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [filterData, setFilterData] = useState<FilterData>({
    planCreationDate: null,
    planApprovalDate: null,
    assignmentDate: null,
    assignedInstructors: null,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
  });

  const [form] = Form.useForm();

  const handleOpenCreate = () => {
    if (isEmpty(selectedRowKeys)) {
      message.info("Vui lòng chọn khoá học phân công");
      return;
    }
    setIsOpened(true);
  };

  const getListData = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      createdDateFrom: !isEmpty(filterData.planCreationDate?.[0])
        ? new Date(filterData.planCreationDate?.[0]).toISOString()
        : "",
      createdDateTo: !isEmpty(filterData.planCreationDate?.[1])
        ? new Date(filterData.planCreationDate?.[1]).toISOString()
        : "",
      approvedDateFrom: !isEmpty(filterData.planApprovalDate?.[0])
        ? new Date(filterData.planApprovalDate?.[0]).toISOString()
        : "",
      approvedDateTo: !isEmpty(filterData.planApprovalDate?.[1])
        ? new Date(filterData.planApprovalDate?.[1]).toISOString()
        : "",
      assignedDateFrom: !isEmpty(filterData.assignmentDate?.[0])
        ? new Date(filterData.assignmentDate?.[0]).toISOString()
        : "",
      assignedDateTo: !isEmpty(filterData.assignmentDate?.[1])
        ? new Date(filterData.assignmentDate?.[1]).toISOString()
        : "",
      isAssigned: tab === "1" ? false : true,
      assigner: filterData.assignedInstructors,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    getListAssign(data)
      .then((res) => {
        const { data } = res.data;
        setListPlan(data.data);
        setTotalRecords(data.totalRecords);
        setCountFalseRecords(data.countFalseRecords);
        setCountTrueRecords(data.countTrueRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên kế hoạch",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 60,
    },
    {
      title: "Ngày phê duyệt",
      dataIndex: "approvedDate",
      key: "approvedDate",
      width: 30,
      render: (_: any, record) => {
        return <>{formatDate(record.approvedDate)}</>;
      },
    },
    {
      title: "Người phê duyệt",
      dataIndex: "approveName",
      key: "approveName",
      width: 40,
      render: (_: any, record) => {
        const firstCharacterName = record.approveName.slice(0, 1);
        return (
          <Avatar.Group
            maxCount={2}
            maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
          >
            <Avatar
              style={{ backgroundColor: "orange" }}
              src={record.approveUserImage}
            >
              {firstCharacterName}
            </Avatar>
            <p className="avatar-name">{record.approveName}</p>
          </Avatar.Group>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.detailAssignCourseConstruction}/${location.id}`,
                    {
                      state: record,
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: options1,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      navigate(
                        `${routesConfig.detailAssignedCourseConstruction}/${location.id}`,
                        {
                          state: {
                            ...record,
                            mode: "update",
                            tab: "1",
                            modeback: "backState",
                            backLink:
                              "/course-construction/assign-course-construction",
                          },
                        }
                      );
                    }
                    if (key === "2") {
                      navigate(`${routesConfig.historyCourseConstruction}`, {
                        state: record,
                      });
                    }
                  },
                  // else if (key === '2')handleCloseTask
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const dataColumnsAsigned: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên kế hoạch",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 60,
    },
    {
      title: "Ngày phân công",
      dataIndex: "assignedDate",
      key: "assignedDate",
      width: 30,
      render: (_: any, record) => {
        return <>{formatDate(record.assignedDate)}</>;
      },
    },
    {
      title: "Người được phân công",
      dataIndex: "approveName",
      className: "avatar-group",
      key: "approveName",
      width: 40,
      render: (_: any, record) => {
        const firstCharacterName = record.assigners;
        return firstCharacterName?.map((item: any, index: number) =>
          item.assigned.map((result: any, indexx: number) => {
            const firstName = result.assignUserName.slice(0, 1);
            return (
              <Avatar.Group
                maxCount={2}
                key={indexx}
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                <Tooltip title={result.assignUserName} placement="top">
                  <Avatar
                    src={result.image}
                    style={{ backgroundColor: "orange" }}
                  >
                    {firstName}
                  </Avatar>
                </Tooltip>
              </Avatar.Group>
            );
          })
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <Space>
            <a
              className="action-table"
              onClick={() =>
                navigate(
                  `${routesConfig.detailAssignCourseConstruction}/${location.id}`,
                  {
                    state: record,
                  }
                )
              }
            >
              <EyeOutlined />
            </a>
            <Dropdown
              menu={{
                items: options2,
                onClick: ({ key }) => {
                  if (key === "1") {
                    navigate(
                      `${routesConfig.detailAssignedCourseConstruction}/${location.id}`,
                      {
                        state: {
                          ...record,
                          mode: "update",
                          tab: "2",
                          modeback: "backState",
                          backLink:
                            "/course-construction/assign-course-construction",
                        },
                      }
                    );
                  }
                  if (key === "2") {
                    navigate(`${routesConfig.historyCourseConstruction}`, {
                      state: record,
                    });
                  }
                  if (key === "3") {
                    Modal.confirm({
                      title: "Thu hồi phân công",
                      content: `Bạn có chắc chắn muốn thu hồi giảng viên của khoá học ${record.name} không?`,
                      onOk: () => revocationAssignment(record),
                      okText: "Lưu",
                      cancelText: "Huỷ",
                      centered: true,
                      footer: (_, { OkBtn, CancelBtn }) => (
                        <>
                          <CancelBtn />
                          <OkBtn />
                        </>
                      ),
                    });
                  }
                },
                // else if (key === '2')handleCloseTask
              }}
              placement="bottomRight"
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const params = useParams();

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, statusOrder]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      planCreationDate: null,
      planApprovalDate: null,
      assignmentDate: null,
    });
  };

  const revocationAssignment = async (record: DataType) => {
    await revokeAssign(record.id).then((res) => {
      if (res.status === 200) {
        message.success("Lưu thông tin thành công");
        setTab("1");
      }
    });
  };

  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Phân công",
      icon: <UserAddOutlined />,
    },
    {
      key: "2",
      label: "Lịch sử hoạt động",
      icon: <HistoryOutlined />,
    },
  ];

  const options2: MenuProps["items"] = [
    {
      key: "1",
      label: "Phân công lại",
      icon: <RedoOutlined />,
    },
    {
      key: "2",
      label: "Lịch sử hoạt động",
      icon: <HistoryOutlined />,
    },
    {
      key: "3",
      label: "Thu hồi",
      icon: <StopOutlined />,
      className: "recall",
    },
  ];


  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const defaultCheckedList2 = dataColumnsAsigned.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkedList2, setCheckedList2] = useState(defaultCheckedList2);

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const newColumnsAsigned = useMemo(() => {
    return dataColumnsAsigned.map((item) => ({
      ...item,
      hidden: !checkedList2.includes(item.key as string),
    }));
  }, [checkedList2]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: `Chờ phân công ${countFalseRecords}`,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            hideSelection={true}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: `Đã phân công ${countTrueRecords}`,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumnsAsigned}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const saveMultiAssign = async (data: object) => {
    await assignMultiCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Phân công thành công khoá học");
        setIsOpened(!isOpened);
        getListData();
        setClearForm(true);
      }
    });
  };

  return (
    <Layout className="page-header-group assign-course-construction">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "create-course-plan-modal") {
            const listRowKey = selectedRowKeys.map((item: any) => {
              return item.id;
            });
            const data = {
              courseIds: listRowKey,
              assigners: values.lecturerName,
            };
            Modal.confirm({
              title: `Xác nhận phân công`,
              content: `Bạn có chắc chắn muốn phân công?`,
              onOk: () => {
                saveMultiAssign(data);
              },
              okText: "Xác nhận",
              cancelText: "Huỷ",
              centered: true,
              icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            });
          }
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTableCourseConstruction
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
        <CourseAssignmentModal
          clearForm={clearForm}
          title="Phân công kế hoạch"
          open={isOpened}
          handleOk={confirmModal}
          handleCancel={() => {
            setIsOpened(!isOpened);
          }}
          selectedRowKeys={selectedRowKeys}
        />
      </Form.Provider>
    </Layout>
  );
};

export default AssignCourseConstruction;
