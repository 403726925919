import {FunctionComponent, useState} from "react";
import "./registration-class.scss";
import {Layout, Tabs, TabsProps} from "antd";
import {routesConfig} from "../../../config/routes";
import SafeLink from "../../../components/link/SafeLink";
import PageHeader from "../../../components/page-header/PageHeader";
import {useLocation, useNavigate} from "react-router-dom";
import InfoTab from "./info/info";
import ListStudentTab from "./list-student/list-student";
import RouteTab from "./route/route";
import SettingsTab from "./settings/settings";
import {usePreventRouteChange} from "../../../hooks/usePreventRouteChange";

const {Content} = Layout;
const imgPath = "/images/";

export type RegistrationClassTabProps = {
  currentTab: string;
};

export type DetailRegistrationClassProps = {
  closedDate?: string;
  closedTime?: string;
  closingDate?: string;
  closingTime?: string;
  courseId?: number;
  createdDate?: string;
  enrolledDate?: string;
  enrolledTime?: string;
  expectedTime?: number;
  expectedTimeType?: number;
  form?: number;
  id?: number;
  instructorTeachers?: string;
  isDeleted?: string;
  name?: string;
  numberOfSession?: number;
  numberStudent?: number;
  openingDate?: string;
  openingTime?: string;
  status?: number;
};

const RegistrationClassPage: FunctionComponent = () => {
  const {confirmNavigation} = usePreventRouteChange();
  const location = useLocation();
  const navigate = useNavigate();

  const [tab, setTab] = useState<string>("1");

  const listBreadcrumb = [
    {
      title: (<span>Xây dựng khóa học</span>),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}`}>
          <span>Xây dựng Nội dung</span>
        </SafeLink>
      ),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}`}>
          <span>Lớp học ghi danh</span>
        </SafeLink>
      ),
    },
    {
      title: (<span>{location.state.name}</span>),
    },
  ];


  const handleChange = (value: string) => {
    confirmNavigation({link: ""}, false, () => {
      setTab(value);
    });
  };

  const tabItems: TabsProps["items"] = [
    {
      key: '1',
      label: 'Thông tin',
      children: (<InfoTab currentTab={tab}/>),
    },
    {
      key: '2',
      label: 'Lộ trình',
      children: (<RouteTab currentTab={tab}/>),
    },
    {
      key: '3',
      label: 'Danh sách học viên',
      children: (<ListStudentTab currentTab={tab}/>),
    },
    {
      key: '4',
      label: 'Cài đặt',
      children: (<SettingsTab currentTab={tab}/>),
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          title={location.state.name}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={false}
        />
        <div className="tabs-heading">
          <Tabs
            // defaultActiveKey="1"
            activeKey={tab}
            onChange={handleChange}
            items={tabItems}
            // tabBarExtraContent={rightContent()}
          />
        </div>
      </Content>
    </>
  );
};

export default RegistrationClassPage;
