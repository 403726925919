import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingExamSupervisionById: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-exam-supervision/get/${id}`);
};

export const getResultExamSupervision: (dataReq?: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/exam-supervision-result/search-by-block-id`,dataReq);
};

export const updateSettingExamSupervision: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-exam-supervision/update/${id}`, dataReq);
}; 