import { Card, Flex, Typography } from "antd";
import MinTimeVideo from "./ItemRating/MinTimeVideo";
import MinStudentJoin from "./ItemRating/MinStudentJoin";
import MaxTimeVideo from "./ItemRating/MaxTimeVideo";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSettingReportByCourse } from "../../service/setting-quality";

const { Title, Text } = Typography;

const ViewRatingVideo = () => {
  const params = useParams();
  const [maxTimeData, setMaxTimeData] = useState([]);
  const [minTimeData, setMinTimeData] = useState([]);
  const [minStudentData, setMinStudentData] = useState([]);
  const [count, setCount] = useState(0);

  const fetchData = async () => {
    try {
      const res = await getSettingReportByCourse(params?.id || "");
      const { data } = res.data;
      setMaxTimeData(data?.max_time);
      setMinTimeData(data?.min_time);
      setMinStudentData(data?.min_student);
      if (data?.max_time?.wrong === 0) {
        setCount((prev) => prev + 1);
      }
      if (data?.min_time?.wrong === 0) {
        setCount((prev) => prev + 1);
      }
      if (data?.min_student?.total >= data?.min_student?.setting) {
        setCount((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="px-6 mb-4">
      <Card className="w-full mt-6">
        <Flex align="center">
          <Title level={4} className="!mb-0 pr-4">
            Kết quả đánh giá chất lượng buổi học video
          </Title>
          <div className="bg-[#2F54EB] px-4 py-1 text-white rounded-md ">
            {count}/3 tiêu chí đạt
          </div>
        </Flex>
      </Card>
      <MinTimeVideo minTimeData={minTimeData} />
      <MaxTimeVideo maxTimeData={maxTimeData} />
      <MinStudentJoin minStudentData={minStudentData} />
    </div>
  );
};

export default ViewRatingVideo;
