import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

//Số lượng khoá học theo hình thức xuất bản
export const getCoursesByFormat: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-course-by-format`, payload);
};

export const exportCoursesByFormat: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-course-by-format`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng bài giảng theo trạng thái xuất bản
export const getSequenceByAvailableStatus: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-sequence-by-available-status`,
    payload
  );
};

export const exportSequenceByAvailableStatus: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-sequence-by-available-status`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng bài giảng theo khoa
export const getSequenceByDepartment: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-sequence-by-industry-group`,
    payload
  );
};
export const exportSequenceByDepartment: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-sequence-by-industry-group`,
    data,
    { responseType: "arraybuffer" }
  );
};
//Số lượng Scorm & xAPI
export const getNumberSCORMxAPI: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-scorm-and-xapi-unit`, payload);
};
export const exportNumberSCORMxAPI: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-scorm-and-xapi-unit`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng học liệu đa phương tiện theo loại
export const getMultimaterialsByType: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-multi-media-unit`, payload);
};

export const exportMultimaterialsByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-multi-media-unit`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng học liệu theo khoá học

export const getUnitByCourse: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/get-unit-by-course`, payload);
};

export const exportUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-by-course`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng bài kiểm tra theo loại

export const getTestByType: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/get-test-by-type`, payload);
};
export const exportTestByType: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-test-by-type`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Số lượng tài liệu tham khảo theo loại

export const getReferenceSourceByType: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-reference-source-by-type`,
    payload
  );
};

export const exportReferenceSourceByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-reference-source-by-type`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Tỷ lệ kết quả đánh giá khóa học
export const getRateUnitByCourse: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-unit-by-course`, payload);
};

export const exportRateUnitByCourse: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-course`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Tỷ lệ kết quả làm đánh giá
export const getRateUnitByModule: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-unit-by-module`, payload);
};

export const exportRateUnitByModule: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-module`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Tỷ lệ kết quả đánh giá theo bài giảng
export const getRateUnitBySequence: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-rate-unit-by-sequence`,
    payload
  );
};

export const exportRateUnitBySequence: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-sequence`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Thống kê số lượng tải về, xem, chia sẻ học liệu
export const getStaticUnitAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/by-time/get-unit-action`, payload);
};

export const exportStaticUnitAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-action-by-time`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Thống kê số lượng đánh giá, truy cập học liệu
export const getUnitReviewAndAccess: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-unit-review-and-access`,
    payload
  );
};

export const exportUnitReviewAndAccess: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-review-and-access`,
    data,
    { responseType: "arraybuffer" }
  );
};

//Thống kê số lượng phản hồi theo bài giảng
export const getRateSequenceAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-sequence-action`, payload);
};

export const exportRateSequenceAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-sequence-action`,
    data,
    { responseType: "arraybuffer" }
  );
};
//Thống kê số lượng phản hồi theo lớp
export const getRateClassAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-class-action`, payload);
};
export const exportRateClassAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-class-action`,
    data,
    { responseType: "arraybuffer" }
  );
};
//Số lượng khoá học theo phân công
export const getCourseAssignTeacher: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-course-assign-teacher`,
    payload
  );
};

export const exportCourseAssignTeacher: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-course-assign-teacher`,
    data,
    { responseType: "arraybuffer" }
  );
};
//Thống kê lượng tìm kiếm toàn hệ thống theo thời gian
export const getSearchHistory: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-search-history`,
    payload
  );
};
export const exportSearchHistory: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-search-history`,
    data,
    { responseType: "arraybuffer" }
  );
};
//Thống kê tìm kiếm theo từ khoá
export const getSearchKeywordCount: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-search-keyword-count`, payload);
};
export const exportSearchKeywordCount: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-search-keyword-count`,
    data,
    { responseType: "arraybuffer" }
  );
};
// Thống kê số lượng phản hồi theo thời gian
export const getUnitDiscuss: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/by-time/get-unit-discuss`, payload);
};
export const exportUnitDiscuss: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-discuss`,
    data,
    { responseType: "arraybuffer" }
  );
};

// Tỷ lệ kết quả đánh giá học liệu theo lớp
export const getRateUnitByClass: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-star-rating-exam`,
    payload
  );
};
export const exportRateUnitByClass: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-star-rating-exam`,
    data,
    { responseType: "arraybuffer" }
  );
};

// lấy danh sách khoa

export const getIndustryGroupFilter: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`chart-teacher/get-industry-group-filter`);
};

// lấy danh sách lớp

export const getClassList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`teacher-possession/filter-class-enroll`);
};

// export all
//tab tài nguyên
export const exportAllResource: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-resource`,
    data,
    { responseType: "arraybuffer" }
  );
};

//tab phản hồi và đánh giá
export const exportAllFeedbackAndEvaluation: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-feedback`,
    data,
    { responseType: "arraybuffer" }
  );
};


//tab hành vi người dùng
export const exportAllUserBehavior: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-behavior`,
    data,
    { responseType: "arraybuffer" }
  );
};
