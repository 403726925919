import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Form, message, Typography, Upload, UploadProps } from "antd";
import "./user-document.scss";
import { get, isEmpty } from "lodash";
import { useForm } from "antd/es/form/Form";
import { useLocation, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  getDistricts,
  getProvinces,
  getWards,
  updateInfoAccount,
} from "../../../../../../service/info-detail/infoDetailApi";
import { getRoles } from "../../../../../../stores/list-roles/rolesApi";
import {
  getAccountDetail,
  getOffices,
  getUnits,
} from "../../../../../../service/list-account-users/listAccountUsersApi";
import { convertArrayToNumber } from "../../../../../../utils/arrays";
import { FileType } from "../../../../../../utils/uploadImage";
import { VerifiedIcon } from "../../../../../../components/icons";
import CustomCard from "../../../../../../components/custom-card/CustomCard";
import {
  dataDocumentCommon,
  dataDocumentContact,
} from "../../../../../detail/constants/dataInfo";
import { EditOutlined } from "@ant-design/icons";
import CustomList from "../custom-list/CustomList";
import { Content } from "antd/es/layout/layout";
import { DetailContent } from "../../detail-content/DetailContent";
import PageHeader from "../../../../../../components/page-header/PageHeader";
import { DataType } from "../../../../../../types/listUsers";
import { useAppStore } from "../../../../../../stores/stores";
const imgPath = "/images/";

const UserDocumentSpecializedGroup: FunctionComponent = () => {
  const params = useParams();
  const id = params.userId || "";
  const location = useLocation();
  const detailData = location.state || {};

  const [loading, setLoading] = useState<boolean>(false);
  const [editingField, setEditingField] = useState<string>("");
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
    dataProvinces: [],
  });
  const [locations, setLocations] = useState({
    provinceValue: "",
    districtValue: "",
    wardValue: "",
  });

  const [userInfo, setUserInfo] = useState({});

  const [form] = useForm();
  const provinceCode = Form.useWatch("thanhPho", form);
  const districtCode = Form.useWatch("quan", form);
  const wardCode = Form.useWatch("phuong", form);
  const address = Form.useWatch("diaChiCuThe", form);
  const [dataUser, setDataUser] = useState<DataType>();
  const getDetailAccount = async () => {
    await getAccountDetail(id).then((res) => {
      const { data } = res.data;
      // setAccountDetail(data);
      setDataUser(data);
    });
  };
  useEffect(() => {
    getDetailAccount();
  }, []);
  useEffect(() => {
    console.log("vadatauser", dataUser);
    console.log("detailData ở tab chi tiết tv", detailData);
    if (provinceCode) {
      getProvinces(`?provinceCode=${provinceCode}`).then((res) => {
        const { data } = res.data;
        if (setLocations) {
          setLocations({
            ...locations,
            provinceValue: data[0].full_name,
          });
        }
      });
    }
    if (districtCode) {
      getDistricts(provinceCode, `&?districtCode=${districtCode}`).then(
        (res) => {
          const { data } = res.data;
          if (setLocations) {
            setLocations({
              ...locations,
              districtValue: data[0].nameEn,
            });
          }
        }
      );
    }
    if (wardCode) {
      getWards(districtCode, `&wardCode=${wardCode}`).then((res) => {
        const { data } = res.data;
        if (setLocations) {
          setLocations({
            ...locations,
            wardValue: data[0].name,
          });
        }
      });
    }
  }, [provinceCode, districtCode, wardCode]);

  useEffect(() => {
    if (dataUser) {
      const ngaySinh = get(dataUser, "ngaySinh", "");
      form.setFieldsValue({
        hoVaTen: get(dataUser, "hoVaTen", ""),
        ngaySinh: !isEmpty(ngaySinh) ? dayjs(ngaySinh) : undefined,
        gioiTinh: get(dataUser, "gioiTinh", ""),
        diaChi: get(dataUser, "diaChi", ""),
        dienThoai: get(dataUser, "dienThoai", ""),
        donViCongTac: get(dataUser, "donViCongTac", ""),
        chucVus: convertArrayToNumber(dataUser.chucVus),
        vaiTros: convertArrayToNumber(get(dataUser, "vaiTros", [])),
        chuyenNganh: get(dataUser, "chuyenNganh", ""),
        trinhDo: get(dataUser, "trinhDo", ""),
      });
    }
  }, [dataUser]);

  const fetchDataOptions = async () => {
    try {
      Promise.all([
        getRoles(),
        getUnits(),
        getOffices(),
        getProvinces(""),
      ]).then((res) => {
        const dataRole = res[0].data.data;
        const dataUnit = res[1].data.data;
        const dataOffices = res[2].data.data;
        const dataProvinces = res[3].data.data;
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnit,
          dataRoles: dataRole,
          dataProvinces: dataProvinces,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataOptions();
  }, []);

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleFinish = (valuesUpdate: any) => {
    setUserInfo({
      ...valuesUpdate,
      dienThoai: valuesUpdate.dienThoai,
      diaChi:
        locations.districtValue &&
        `${address}, ${locations.districtValue}, ${locations.wardValue}, ${locations.provinceValue}`,
      hoVaTen: valuesUpdate.hoVaTen,
      ngaySinh: valuesUpdate.ngaySinh,
      gioiTinh: valuesUpdate.gioiTinh,
      donViCongTacId: valuesUpdate.donViCongTac,
      chucVus: valuesUpdate.chucVus,
      vaiTros: valuesUpdate.vaiTros,
      chuyenNganh: valuesUpdate.chuyenNganh,
      trinhDo: valuesUpdate.trinhDo,
    });
  };
  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: (
        <a href={`/course-plan/specialized-group-list`}>Lập tổ chuyên môn</a>
      ),
    },
    {
      title: <a href={``}>{detailData?.organizationName || ""}</a>,
    },
    {
      title: "Xem lý lịch " + dataUser?.hoVaTen || "",
    },
  ];
  return (
    <div className="course-plan-managment-detail-user-speialized">
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={"Xem lý lịch " + dataUser?.hoVaTen}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <DetailContent>
          <div className="avatar-info">
            <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              disabled={true}
            >
              <img
                src={dataUser?.anhDaiDien ?? undefined}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            </Upload>
            <div className="avatar-title">
              <Typography.Title level={5}>{dataUser?.hoVaTen}</Typography.Title>
              <VerifiedIcon width={"25"} height={"24"} />
            </div>
          </div>
          <Form form={form} onFinish={handleFinish} layout="vertical">
            <CustomCard
              title={
                <div className="card-heading--info">
                  <Typography.Title level={5}>Thông tin chung</Typography.Title>
                </div>
              }
              className="card-info--wrapper"
            >
              {dataDocumentCommon.map(({ fieldName, title, fieldValue }) => {
                return (
                  <Fragment key={fieldName}>
                    <CustomList
                      title={title}
                      key={fieldName}
                      name={fieldName}
                      description={get(dataUser, fieldName, "")}
                      data={dataUser}
                      fieldValue={fieldValue}
                      options={dataOptions}
                      editFieldName={editingField}
                    />
                  </Fragment>
                );
              })}
            </CustomCard>
            <CustomCard
              title={
                <div className="card-heading--info">
                  <Typography.Title level={5}>
                    Thông tin liên hệ
                  </Typography.Title>
                </div>
              }
              className="card-info--wrapper"
            >
              {dataDocumentContact.map(({ fieldName, title, fieldValue }) => {
                return (
                  <Fragment key={fieldName}>
                    <CustomList
                      title={title}
                      key={fieldName}
                      name={fieldName}
                      description={get(dataUser, fieldName, "")}
                      options={dataOptions}
                      fieldValue={fieldValue}
                      form={form}
                      setLocation={setLocations}
                      editFieldName={editingField}
                    />
                  </Fragment>
                );
              })}
            </CustomCard>
          </Form>
        </DetailContent>
      </Content>
    </div>
  );
};

export default UserDocumentSpecializedGroup;
