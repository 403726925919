import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { FormInstance } from "antd/lib";
// import { dataStatus } from '../../../pages/list-user/constants/data';
import { get } from "lodash";

import type { DatePickerProps } from "antd";
import { Dayjs } from "dayjs";
import { FilterData } from "./filter";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";

type FormFilterProps = {
  handleCloseFilter: () => void;
  tab: string;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  filterData: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  filterData,
  setFilterData,
  tab,
}) => {
  const { RangePicker } = DatePicker;

  const listStatus = [
    {
      id: 0,
      name: 'Nháp'
    },
    {
      id: 1,
      name: 'Áp dụng'
    },
  ]

  const courseType = [
    { id: 1, name: "Tự triển khai" },
    { id: 2, name: "Hợp tác xây dựng" },
  ];

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Chọn ngày tạo</Typography.Title>
              <Form.Item name="approvedDate">
                <RangePicker
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="YYYY-MM-DD"
                  value={filterData.approvedDate}
                  onChange={(_value, dateString: any) =>
                    setFilterData(dateString, "approvedDate")
                  }
                />
              </Form.Item>
            </Col>

            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>
                Trạng thái
              </Typography.Title>
              <Form.Item name="status">
                <FormItemDropdown placeholder="Trạng thái" onChange={(option) => setFilterData(option, 'status')}
                  options={listStatus} />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 3 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
