import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HeartOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SettingOutlined,
  StopOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  Switch,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateV2 } from "../../../utils/converDate";
import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterSettingManagementData from "../../../components/management-data/filter-table/FilterTable";
import SettingManagementDataDrawer from "../../../components/drawer/setting-management-data";
import TableData from "../../../components/table-data/TableData";
import "./index.scss"
import { deleteSettingManagementData, getSettingManagementData, putSettingManagementData } from "../../../service/management-data";

type Props = {
  title?: string;
};

const SettingManagementDataPage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedSaveTemplate, setIsOpenedSaveTemplate] = useState<boolean>(false);
  const [listSettingManagementData, setListSettingManagementData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [mode, setMode] = useState<string>("");



  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const handleOpenCreate = () => {
    setIsOpened(true);
    setDataUpdate({})
  };

  const optionsContentForum: MenuProps["items"] = [
    {
      key: "1",
      label: "Xem chi tiết",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Lưu mẫu",
      icon: <SaveOutlined />,
    },
    {
      key: "3",
      label: "Tạo bản nháp",
      icon: <EditOutlined />,
    },
    {
      key: "4",
      label: "Sao chép/Dán",
      icon: <CopyOutlined />,
    },
    {
      key: "5",
      label: "Cài đặt hoạt động",
      icon: <SettingOutlined />,
    },
    {
      key: "4",
      label: "Xóa",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
    };

    await getSettingManagementData(data)
      .then((res) => {
        const { data } = res.data
        setListSettingManagementData(data.credentials)
        setTotalRecords(data.total);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, lastPreview]);

  const handleOpenAddSettingManagementData = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p>Nháp</p>
        break;
      case 1:
        return <p>Áp dụng</p>
        break;
      default:
        break;
    }
  }

  const deleteManagementData = async (record: any) => {
    await deleteSettingManagementData(record?.id).then((res) => {
      if (res.status === 200) {
        message.success("Xóa thông tin kết nối thành công")
        getListData()
      }
    })
  }

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Hệ thống",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Loại kết nối",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 20,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Thông tin",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 20,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.createdDate ? formatDateV2(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      width: 20,
      render: (_: any, record) => {
        const changeStatus = async (e: boolean) => {
          const data = {
            status: e
          }
          await putSettingManagementData(record?.id, data).then((res) => {
            if (res.status === 200) {
              getListData()
            }
          })
        }
        return (
          <Switch value={record?.status} onChange={e => changeStatus(e)} />
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpened(true);
                  setDataUpdate(record)
                }
                }
              >
                <EditOutlined />
              </a>

              <a
                className="action-table"
                onClick={() => {
                  Modal.confirm({
                    title: `Xác nhận xóa thiết lập kết nối`,
                    content: `Bạn có chắc chắn muốn xóa kết nối này?`,
                    onOk: () => {
                      deleteManagementData(record)
                    },
                    okText: "Xác nhận",
                    cancelText: "Huỷ",
                    centered: true,
                    icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
                }
                }
              >
                <DeleteOutlined />
              </a>
            </Space >
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  return (
    <Layout className="page-header-group setting-management-data">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData()
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterSettingManagementData
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              setlastPreview={setlastPreview}
              tab={tab}
            />
          </div>
        </div>

        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listSettingManagementData}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            // scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
            clearRowkey={clearRowkey}
          />
        </div>
      </Form.Provider>

      {/* <CommonModal
        open={isOpenedConfirm}
        title={'Xoá giao diện quản trị'}
        desc={`Chủ đề đã xoá sẽ không thể khôi phục và giao diện sẽ chuyển về mặc đinh. Bạn có chắc muốn xóa giao diện quản trị?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deletePortalAdmin}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      /> */}

      <SettingManagementDataDrawer
        open={isOpened}
        handleClose={() => setIsOpened(false)}
        getListData={getListData}
        mode={mode}
        dataUpdate={dataUpdate}
        setDataUpdate={setDataUpdate}
      />

    </Layout>
  );
};

export default SettingManagementDataPage;
