import React, { FunctionComponent, useState } from "react";
import { get } from "lodash";
import { Dayjs } from "dayjs";
import { FormInstance } from "antd/lib";
import type { DatePickerProps } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import "./filter-group.scss";
import { FilterDataMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  setFilterData: (value: any[], name: string) => void;
  clearFilter: () => void;
  onFinish?: () => void;
  dataOptions?: any[];
  tab: Number;
  filterData: any; //FilterDataMoocCourseConstructionPlan;
};

type RangeValue = any | null;

const FormFilterMoocCourseConstructionPlan: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <div className="gc-style">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 12, md: 12, lg: 10, sm: 10 }, 12]}>
            {dataOptions?.map((filter) => {
              if (filter.type == 'CreateDatePicker') {
                return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item name={filter.key}>
                    <RangePicker
                      placeholder={["Ngày tạo", "Ngày tạo"]}
                      format="DD-MM-YYYY"
                      value={filterData[filter.key]}
                      onChange={(_value, dateString: any) => {
                        const convertedDates = dateString.map(
                          (date: String) => {
                            const [day, month, year] = date.split("-");
                            return `${year}-${month}-${day}`;
                          }
                        );
                        setFilterData(convertedDates, filter.key);
                      }}
                    />
                  </Form.Item>
                </Col>);
              }
              if (filter.type == 'ApprovalDatePicker') {
                return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item name={filter.key}>
                    <RangePicker
                      placeholder={["Ngày duyệt", "Ngày duyệt"]}
                      format="DD-MM-YYYY"
                      value={filterData[filter.key]}
                      onChange={(_value, dateString: any) => {
                        const convertedDates = dateString.map(
                          (date: String) => {
                            const [day, month, year] = date.split("-");
                            return `${year}-${month}-${day}`;
                          }
                        );
                        setFilterData(convertedDates, filter.key);
                      }}
                    />
                  </Form.Item>
                </Col>);
              }
              if (filter.type == 'FormItemDropdownStatus') {
                return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item name={filter.key}>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Trạng thái"
                      options={filter.data}
                      onChange={(option) => {
                        setFilterData(option, filter.key);
                      }}
                    />
                  </Form.Item>
                </Col>);
              }
              if (filter.type == 'FormItemDropdownType') {
                return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Form.Item name={filter.key}>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Loại khóa học"
                      options={filter.data}
                      onChange={(option) => setFilterData(option, filter.key)}
                    />
                  </Form.Item>
                </Col>);
              }
              return (<></>);
            })}
            <Col
              xl={{ span: 1 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined className="itemDelete" />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilterMoocCourseConstructionPlan;
