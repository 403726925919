import {
  DeleteFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  MoreOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { DragSortTable, ProColumns } from "@ant-design/pro-components";
import { Button, Dropdown, message, Modal, Space, Typography } from "antd";
import { useState } from "react";
import { deleteCourseSection, publishSection } from "../../../../service/course-construct";
import AddChapter from "../../../drawer/add-chapter/AddChapter";
import ActiveSettingsChaptersDrawer from "../../active-settings-chapters-drawer/ActiveSettingsChaptersDrawer";
import "../course-constructor/course-construct.scss";
import LessonCard from "../lesson-card";
import { formatDateV2, formatTimeHm } from "../../../../utils/converDate";
import SaveTemplateSequenceModal from "../../../modal/save-template-sequence-modal";
import { useParams } from "react-router-dom";
import "./chapter-card.scss";

type IProp = {
  data?: any;
  handleGetDetail?: any;
  id: number;
  name: string;
  updateSequence: (idSection: number, idSequence: number, data: any) => void;
  updateUnit: (idSection: number, idSequence: number, data: any) => void;
};

const ChapterCard = (props: IProp) => {
  const { updateSequence, updateUnit } = props;
  const { confirm } = Modal;
  const { data } = props;
  const [dataSourceChildren, setDataSourceChildren] = useState(
    props.data.children
  );
  const [cardName, setCardName] = useState(props.data.name);
  const [oldName, setOldName] = useState("");
  const [active, setActive] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLesson, setIsLesson] = useState(false);
  const [isSection, setIsSection] = useState(false);
  const [isOpenSettingDraw, setIsOpenSettingDrawer] = useState(false);
  const [isOpenSaveTemplateModal, setIsOpenSaveTemplateModal] = useState(false);

  const params = useParams()

  const handleDeleteSection = async () => {
    try {
      const response = await deleteCourseSection(Number(props.data.id)).then(
        (result) => {
          props.handleGetDetail();
          message.success("Xóa chương học thành công");
        }
      );
    } catch (error) {
    }
  };


  const showConfirm = () => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{ color: "red", fontSize: "20px" }}>
            <DeleteFilled />
          </div>
          <span>Bạn muốn xóa chương học?</span>
        </div>
      ),
      icon: null,
      okText: "Xác nhận",
      cancelText: "Hủy",
      okType: "danger",
      onOk() {
        handleDeleteSection();
      },
      onCancel() {
      },
    });
  };

  // const handleChangeNameSection = async () => {
  //   const data = {
  //     name: cardName,
  //     courseId: props.id,
  //   };
  //   try {
  //     const response = await putCourseUpdateSection(props.data.id, data).then(
  //       (result) => {
  //         props.handleGetDetail();
  //       }
  //     );
  //   } catch (error) {}
  // };

  const handleDragSortEnd = (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: any
  ) => {
    updateSequence(props.id, props.data.id, newDataSource);
    setDataSourceChildren(newDataSource);
  };

  let locale = {
    emptyText: "Không có dữ liệu",
  };

  const handleAddLesson = () => {
    setIsEdit(false);
    setIsLesson(true);
    setIsOpenDrawer(true);
  };


  const columns: ProColumns[] = [
    {
      title: "",
      dataIndex: "sort",
      width: 1,
      className: "drag-visible",
    },
    {
      title: "",
      dataIndex: "name",
      className: "drag-visible",
      render: (_: any, record) => {
        return (
          <LessonCard
            data={record}
            itemDetailChapter={props.data}
            name={props.name}
            handleGetDetail={props.handleGetDetail}
            id={props.data.id}
            updateUnit={updateUnit}
          />
        );
      },
    },
  ];

  const handlePublishSection = async (sectionId: number) => {
    try {
      await publishSection(sectionId);
      message.success("Xuất bản chương thành công!")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className="card-heading--info">
        <div className="header--wrapper">
          <div className="header--ctn">
            <div className="header-course">
              <Typography.Title className="text-header-title" level={5}>{cardName}</Typography.Title>
              <span className="text-header-description">
                {`
                ${props.data.assignedName ? props.data.assignedName : ""} ${" "} ${props.data.assignedId ? `(${props.data.assignedId})` : ""}
                `}
              </span>
            </div>
            <Space>
              <a className="action-course" onClick={() =>
                Modal.confirm({
                  title: "Xuất bản chương",
                  content: "Bạn có muốn xuất bản chương này?",
                  okText: "Xác nhận",
                  cancelText: "Huỷ",
                  onOk: () => {
                    handlePublishSection(Number(props.data.id))
                  },
                  onCancel() { },
                })
              }>
                <UploadOutlined />
              </a>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Sửa chương",
                      icon: <EditOutlined />,
                    },
                    {
                      key: "2",
                      label: "Xóa chương",
                      icon: <DeleteOutlined />,
                      danger: true,
                    },
                    {
                      key: "3",
                      label: "Cài đặt hoạt động",
                      icon: <SettingOutlined />,
                    },
                    {
                      key: "4",
                      label: "Lưu mẫu",
                      icon: <DownloadOutlined />,
                    },
                  ],
                  onClick: ({ key }) => {
                    if (key === "1") {
                      setIsEdit(true);
                      setIsOpenDrawer(true);
                    }
                    if (key === "2") {
                      showConfirm();
                    }
                    if (key === "3") {
                      setIsOpenSettingDrawer(true);
                      setIsSection(true)
                      setIsLesson(false);
                    }
                    if (key === '4') {
                      setIsOpenSaveTemplateModal(true)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button className="action-course">
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
          <div className="text-header-description border-chapter">
            Lịch trình{" "}
            {data?.schuduleStartDate && formatDateV2(data?.schuduleStartDate)}
            {data?.schuduleEndDate && '-' + formatDateV2(data?.schuduleEndDate) && '-' + formatDateV2(data?.schuduleEndDate)}
            {data?.schuduleStartDate && ';' + formatTimeHm(data?.schuduleStartDate)}
            {data?.schuduleEndDate && '-' + formatTimeHm(data?.schuduleEndDate)}
          </div>
        </div>
      </div>
      <DragSortTable
        locale={locale}
        columns={columns}
        rowKey="key"
        search={false}
        pagination={false}
        dataSource={dataSourceChildren}
        dragSortKey="sort"
        onDragSortEnd={handleDragSortEnd}
      />
      <div onClick={handleAddLesson} className="add-container">
        <div>+</div>
        Thêm bài giảng
      </div>

      <AddChapter
        id={props.id}
        title="Basic Drawer"
        setIsOpenDrawer={setIsOpenDrawer}
        open={isOpenDrawer}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
        isLesson={isLesson}
        isEdit={isEdit}
      />
      <ActiveSettingsChaptersDrawer
        id={props.id}
        setIsOpened={setIsOpenSettingDrawer}
        open={isOpenSettingDraw}
        isSection={isSection}
        isLesson={isLesson}
        handleDetail={props.handleGetDetail}
        itemDetail={props?.data}
      />

      <SaveTemplateSequenceModal
        open={isOpenSaveTemplateModal}
        handleClose={() => setIsOpenSaveTemplateModal(false)}
        codeLesson={params?.id}
        sectionId={props?.data?.id}
      />
    </>
  );
};

export default ChapterCard;
