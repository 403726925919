import { AxiosResponse } from "axios";
import { axiosConfigV2OpenAPI } from "../../../config/api/configOpenApiV2";
import { axiosConfigV2 } from "../../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";

export const getMoocMinistryUserById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-ministerial/user/${id}`)
}

export const getOrganizationByName: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-university/get-organization-by-name`)
}

export const downloadMinistryUserTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-ministerial/user/download-template-excel`, {responseType: "arraybuffer",})
}

export const getPositionsMinistryUser: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/profile/get-positions`)
}

export const getAcademicLevelMinistryUser: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/profile/get-academic-level`)
}

export const getAddressProvinces: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2OpenAPI.get(`/address/provinces?code=4`)
}

export const getAllMinistryUserByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-ministerial/user/get-all-by-filter`, data)
}

export const exportExcelMoocMinistryUser: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-ministerial/user/export-excel`, data, {responseType: "arraybuffer",})
}

export const importExcelMoocMinistryUser: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/mooc-ministerial/user/import-excel`, data, {responseType: "arraybuffer",})
}

export const addMoocMinistryUser: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-ministerial/user`, data)
}

export const updateMoocMinistryUser: (userId: number, data: any) => Promise<AxiosResponse<any>> = (userId, data) => {
    return axiosConfigV2.put(`/mooc-ministerial/user/${userId}`, data)
}

export const changeStatusMinistryUser: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-ministerial/user/change-status`, data)
}

export const deleteMoocMinistryUser: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-ministerial/user/${id}`)
}