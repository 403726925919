import {
  DownOutlined,
  PlusOutlined,
  SettingOutlined,
  FilterOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Form,
  DatePicker,
  FormInstance,
} from "antd";
import React, {
  ChangeEvent,
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from "react";
import PageHeader from "../../../../../components/page-header/PageHeader";
import FormItemInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { FilterParticipant } from "../../../../../types/survey-managment/listSurvey";
import { FilterResultChart } from "../../../../../types/survey-managment/listSurvey";
import { getQuestionById } from "../../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { getLookupMoocSurveySection } from "../../../../../service/survey-managment/mooc-survey-question/mooc-survey-question-api";
import { getLookupMoocSurveyQuestion } from "../../../../../service/survey-managment/mooc-survey-question-answer/mooc-survey-question-answer-api";
import { DataQuestion } from "../../../../../types/survey-managment/listSurvey";

import htmlToImage from "html-to-image";
import { toPng, toJpeg } from "html-to-image";
import { useParams } from "react-router-dom";
import * as FileSaver from "file-saver";
import XLSX from "xlsx-js-style";
type PageHeaderType = {
  handleOpen: () => void;
  title?: string | null;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  filterData: FilterResultChart;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  handleSubmitSearch: () => void;
  clearFilter: () => void;
  form: FormInstance;
  refImage: any;
  dataExport: any;
};
const FilterChart: FunctionComponent<PageHeaderType> = ({
  handleOpen,
  title,
  searchValue,
  handleChangeInput,
  filterData,
  setFilterData,
  handleSubmitSearch,
  clearFilter,
  form,
  refImage,
  dataExport,
}) => {
  const { RangePicker } = DatePicker;
  const [showFilter, setShowFilter] = useState(true);
  const [dataQuestions, setDataQuestions] = useState<DataQuestion[]>([]);
  const params = useParams();
  console.log(dataExport);
  const [dataOptions, setDataOptions] = useState({
    dataStatus: [
      {
        id: 1,
        name: "Tự triển khai",
      },
      {
        id: 2,
        name: "Hợp tác xây dựng",
      },
    ],
    dataTypes: [
      {
        id: 1,
        name: "Nhiều lựa chọn văn bản",
      },
      {
        id: 2,
        name: "Nhiều câu lựa chọn ảnh",
      },
      {
        id: 3,
        name: "Lựa chọn đúng sai",
      },
      {
        id: 4,
        name: "Lựa chọn dropdown",
      },
      {
        id: 5,
        name: "Nhiều trả lời văn bản",
      },
      {
        id: 6,
        name: "Nhiều trả lời ảnh",
      },
      {
        id: 7,
        name: "Câu hỏi kết nối",
      },
      {
        id: 8,
        name: "Câu hỏi trả lời ngắn",
      },
    ],
  });
  const handleReturnType = (typeCode: number) => {
    switch (typeCode) {
      case 1:
        return "Nhiều lựa chọn văn bản";
      case 2:
        return "Nhiều lựa chọn ảnh ";
      case 3:
        return "Lựa chọn đúng, sai ";
      case 4:
        return "Lựa chọn dropdown";
      case 5:
        return "Nhiều trả lời văn bản";
      case 6:
        return "Nhiều trả lời ảnh";
      case 7:
        return "Câu hỏi kết nối";
      case 8:
        return "Câu trả lời ngắn";
      default:
        return "";
    }
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };

  const exportToCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    console.log(dataExport);
    let dataFormat: any[] = [];
    const dataCSV = dataExport.map((item: any) => {
      // Check if answerOther exists and has elements
      if (item.answerOther && item.answerOther.length > 0) {
        // Iterate over each element of answerOther
        item.answerOther.forEach((answer: string) => {
          // Add each answerOther element to questionData
          item.questionData.push([answer, 1]);
        });
      }
      return item;
    });
    dataCSV.forEach((item: any, key: any) => {
      let isFirstQuestionShown: boolean = false;
      if (item.questionData) {
        item.questionData.forEach((answer: any, index: any) => {
          if (index === 0 && !isFirstQuestionShown) {
            dataFormat.push({
              STT: key + 1,
              "Câu hỏi": item.question,
              "Loại câu hỏi": handleReturnType(item.type),
              "Tổng số câu trả lời": item.totalAnswers,
              "Ngày trả lời": item.dateReply ? formatDate(item.dateReply) : "",
              "Đáp án": answer[0],
              "Số câu trả lời": answer[1],
              "Tỉ lệ":
                ((Number(answer[1]) / item.totalAnswers) * 100).toFixed(2) +
                "%",
            });
            isFirstQuestionShown = true;
          } else {
            dataFormat.push({
              STT: "",
              "Câu hỏi": "",
              "Loại câu hỏi": "",
              "Tổng số câu trả lời": "",
              "Ngày trả lời": "",
              "Đáp án": answer[0],
              "Số câu trả lời": answer[1],
              "Tỉ lệ":
                ((Number(answer[1]) / item.totalAnswers) * 100).toFixed(2) +
                "%",
            });
          }
        });
      }
      if (item.answerText) {
        item.answerText.forEach((answer: any, index: any) => {
          if (index === 0 && !isFirstQuestionShown) {
            dataFormat.push({
              STT: key + 1,
              "Câu hỏi": item.question,
              "Loại câu hỏi": handleReturnType(item.type),
              "Tổng số câu trả lời": item.total,
              "Ngày trả lời": item.dateReply ? formatDate(item.dateReply) : "",
              "Đáp án": answer,
              "Số câu trả lời": "",
              "Tỉ lệ": "",
            });
            isFirstQuestionShown = true;
          } else {
            dataFormat.push({
              STT: "",
              "Câu hỏi": "",
              "Loại câu hỏi": "",
              "Tổng số câu trả lời": "",
              "Ngày trả lời": "",
              "Đáp án": answer,
              "Số câu trả lời": "",
              "Tỉ lệ": "",
            });
          }
        });
      }
    });
    console.log(dataFormat);

    const wscols = [
      { wch: 10 }, // Độ rộng cột cho "STT"
      { wch: 40 }, // Độ rộng cột cho "Câu hỏi"
      { wch: 30 }, // Độ rộng cột cho "Loại câu hỏi"
      { wch: 20 }, // Độ rộng cột cho "Tổng số câu trả lời"
      { wch: 30 }, // Độ rộng cột cho "Ngày trả lời"
      { wch: 40 }, //Độ rộng cột cho "Đáp án"
      { wch: 20 }, //Số câu trả lời
    ];

    const headerStyle = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "ADD8E6" }, // Màu nền màu vàng
      },
      font: {
        bold: true, // Chữ in đậm
      },
      alignment: {
        horizontal: "center", // Căn giữa
      },
      border: {
        // Định dạng border
        top: { style: "thin", color: { rgb: "000000" } }, // Border trên
        bottom: { style: "thin", color: { rgb: "000000" } }, // Border dưới
        left: { style: "thin", color: { rgb: "000000" } }, // Border trái
        right: { style: "thin", color: { rgb: "000000" } }, // Border phải
      },
    };

    const ws = XLSX.utils.json_to_sheet(dataFormat);
    // Định nghĩa phong cách căn giữa
    const centerAlignment = {
      horizontal: "center",
      vertical: "center",
    };

    // Áp dụng căn giữa cho cột thứ 5
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell_ref = XLSX.utils.encode_cell({ c: 4, r: R }); // Cột E là cột thứ 5, đánh số từ 0
        if (!ws[cell_ref]) continue;
        ws[cell_ref].s = ws[cell_ref].s || {};
        ws[cell_ref].s.alignment = centerAlignment;
      }
    }

    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]); // lấy phạm vi của bảng
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "1"; // tạo địa chỉ A1, B1, C1, v.v...
        if (!ws[address]) continue; // nếu ô không tồn tại, bỏ qua
        ws[address].s = headerStyle; // áp dụng định dạng
        console.log(ws[address].s);
      }
    }
    const borderStyle = {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    };

    // Áp dụng viền cho tất cả các ô
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
          if (!ws[cell_ref]) continue;
          ws[cell_ref].s = ws[cell_ref].s || {};
          ws[cell_ref].s.border = borderStyle;
        }
      }
    }

    ws["!cols"] = wscols;

    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "export" + fileExtension);
  };

  const onButtonClick = () => {
    console.log(refImage);
    if (refImage === null) {
      return;
    }
    toPng(refImage)
      .then((dataUrl) => {
        console.log(dataUrl);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "hinh-anh.png"; // Tên file khi lưu
        // Thêm liên kết vào body và kích hoạt việc tải xuống
        document.body.appendChild(link);
        link.click();
        // Sau khi tải xuống, loại bỏ liên kết khỏi body
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Không thể chuyển đổi HTML sang hình ảnh:", err);
      });
  };
  const openFilter = () => {
    setShowFilter(!showFilter);
  };
  const getQuestion = async (id: any) => {
    await getQuestionById(id).then((res) => {
      const { data } = res.data;
      const dataFormat = [...data].map((item: any) => {
        return {
          id: item.id,
          name: item.question_name,
        };
      });
      setDataQuestions(dataFormat);
    });
  };
  useEffect(() => {
    getQuestion(params.id);
  }, []);
  return (
    <Layout className="page-header-group">
      <div
        className={`page-header-group--layout ${
          showFilter ? "open-filter" : ""
        }`}
      >
        <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
          <Form form={form} name="filterForm">
            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="repDate" className="">
                  <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={["Ngày trả lời", "Ngày trả lời"]}
                    value={filterData.repDate}
                    onChange={(_value, dateString: any) => {
                      setFilterData(dateString, "repDate");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="typeQuestion">
                  <FormItemDropdown
                    placeholder="Loại câu hỏi"
                    modeDropdown="multiple"
                    value={filterData.typeQuestion}
                    options={dataOptions.dataTypes}
                    onChange={(option) => {
                      setFilterData(option, "typeQuestion");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="nameQuestion">
                  <FormItemDropdown
                    placeholder="Câu hỏi khảo sát"
                    modeDropdown="multiple"
                    value={filterData.nameQuestion}
                    options={dataQuestions}
                    onChange={(option) => {
                      setFilterData(option, "nameQuestion");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 8 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 24 }}
              >
                <div className="group-btn--filter" style={{ marginLeft: 30 }}>
                  <Button
                    className="btn btn-primary filter-btn"
                    // htmlType="submit"
                    onClick={handleSubmitSearch}
                    // onClick={() => form.submit()}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa tất cả</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={onButtonClick}
                    style={{ marginLeft: 10 }}
                  >
                    <FileImageOutlined />
                    <Typography.Text>Xuất ảnh</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={exportToCSV}
                    style={{ marginLeft: 10 }}
                  >
                    <DownloadOutlined />
                    <Typography.Text>Excel</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default FilterChart;
