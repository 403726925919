import { Card, Flex, Typography } from "antd";
import { isEmpty } from "lodash";
import { formatDateV2 } from "../../../../utils/converDate";

const { Title, Text } = Typography;

const DEFAULT_INFO_STUDENT = [
  { name: "code", value: "Mã người dùng" },
  { name: "auth_userprofile.date_of_birth", value: "Ngày sinh" },
  { name: "specialize", value: "Chuyên ngành" },
  { name: "auth_userprofile.level_of_education", value: "Trình độ" },
  { name: "school", value: "Trường học" },
];

const HeadFavoriteStudent = ({ infoFavoriteStudent }: any) => {
  return (
    <div className="px-6">
      <Card className="mt-4">
        <div className="pb-3 border-b">
          <Title level={4} className="!leading-3">
            Thông tin sinh viên
          </Title>
          <Text className="text-[#00000073]">
            Thay đổi thông tin chung hồ sơ cá nhân của người dùng
          </Text>
        </div>
        <div>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Tên người dùng</p>
            <p className="w-full sm:w-3/4">
              {infoFavoriteStudent?.first_name +
                " " +
                infoFavoriteStudent?.last_name}
            </p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Mã người dùng</p>
            <p className="w-full sm:w-3/4">{infoFavoriteStudent?.id}</p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Ngày sinh</p>
            <p className="w-full sm:w-3/4">
              {formatDateV2(infoFavoriteStudent?.auth_userprofile?.date_of_birth)}
            </p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Chuyên ngành</p>
            <p className="w-full sm:w-3/4"></p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Trình độ</p>
            <p className="w-full sm:w-3/4">
              {infoFavoriteStudent?.auth_userprofile?.level_of_education}
            </p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Trường học</p>
            <p className="w-full sm:w-3/4"> </p>
          </Flex>
          <Flex className="py-4 border-b" wrap="wrap">
            <p className="w-full sm:w-1/4 font-bold">Sở thích người dùng</p>
            <div className="w-full sm:w-3/4">
              {!isEmpty(infoFavoriteStudent?.mooc_student_favorite) && (
                <Flex align="center">
                  {infoFavoriteStudent?.mooc_student_favorite?.map(
                    (data: any, index: number) => {
                      if (index <= 2) {
                        return (
                          <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                            {data?.mooc_recommendation_tag?.name}
                          </div>
                        );
                      }
                    }
                  )}
                  {infoFavoriteStudent?.mooc_student_favorite?.length > 3 && (
                    <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                      +{infoFavoriteStudent?.mooc_student_favorite?.length - 3}
                    </div>
                  )}
                </Flex>
              )}
            </div>
          </Flex>
        </div>
      </Card>
    </div>
  );
};

export default HeadFavoriteStudent;
