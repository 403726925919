import { Flex } from "antd";
import { useState } from "react";
import CourseTag from "./StatiscalItems/CourseTag";
import InteractStudent from "./StatiscalItems/InteractStudent";

const StatisticalCourseTag = () => { 
  return (
    <div className="px-3 pt-6 mx-3">
      <Flex className="flex-wrap lg:!flex-nowrap" justify="space-between">
        <CourseTag />
        <InteractStudent />
      </Flex>
    </div>
  );
};

export default StatisticalCourseTag;
