import {Checkbox, Form, Radio, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import './index.scss'
import {getOptionNotification} from "../../../../../service/content-course/contenCourseApi";
import {FormInstance} from "antd/lib";

type NotificationSettingProps = {
  form: FormInstance
}
const validate = {
  notificationOptional: {
    required: true,
    message: "Vui lòng chọn loại thông báo",
  },
};
const NotificationSetting = ({form}: NotificationSettingProps) => {
  const [dataOptionsNoti, setDataOptionsNoti] = useState([])
  const [newDataOptions, setNewDataOptions] = useState<Array<{ label: string, value: number }>>([])
  const valueDefault = Form.useWatch('isDefault', form)
  const fetchOptionsNoti = async () => {
    try {
      const response = await getOptionNotification()
      const {data} = response.data
      setDataOptionsNoti(data)
    } catch (err) {
      console.error(err)
    }
  }


  useEffect(() => {
    fetchOptionsNoti()
  }, []);

  useEffect(() => {
    if (valueDefault) {
      form.setFieldValue('notificationOptional', [])
    }
  }, [valueDefault])


  useEffect(() => {
    if (dataOptionsNoti.length > 0) {
      const newData: Array<{ label: string, value: number }> = dataOptionsNoti.map((item: {
        id: number,
        name: string
      }) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      setNewDataOptions(newData)
    }
  }, [dataOptionsNoti]);

  return (
    <>
      <CustomCard title="Thông báo" className="wrapper-settings--card">
        <div className="section-type-noti">
          <div className="title">
            Nhận thông báo:
          </div>
          <div className="options-notification">
            <Form.Item name="receivedOverMail" valuePropName="checked">
              <Checkbox value={true}>
                Gửi thông báo qua mail
              </Checkbox>
            </Form.Item>
            <Form.Item name="receivedOverSystem" valuePropName="checked">
              <Checkbox value={true}>
                Gửi thông báo trên hệ thống
              </Checkbox>
            </Form.Item>
          </div>
        </div>
        <div className="type-noti-wrapper">
          <div className="title">
            Loại thông báo:
          </div>
          <div className="form-item--type_noti">
            <Form.Item name="isDefault">
              <Radio.Group className="options-notification--type">
                <div className="wrapper-radio">
                  <Radio value={true}>
                    Gửi theo thiết lập mặc định của trường.
                  </Radio>
                  <div className="message">
                    <Typography.Text className="sub-title">
                      Thông báo sẽ được gửi theo thiết lập chung của nhà trường
                    </Typography.Text>
                  </div>
                </div>
                <div className="wrapper-radio">
                  <Radio value={false}>
                    Tùy chỉnh
                  </Radio>
                  <div className="message">
                    <Typography.Text className="sub-title">Lựa chọn loại thông báo bạn muốn gửi cho học viện trong khóa
                      học
                      này</Typography.Text>
                  </div>
                </div>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="notificationOptional" className="option-types"
                       rules={!valueDefault ? [validate.notificationOptional] : [{required: false, message: ""}]}>
              <Checkbox.Group>
                {newDataOptions.map(option => (
                  <Checkbox key={option.value} value={option.value}>{option.label}</Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </div>
        </div>
      </CustomCard>
    </>
  );
};

export default NotificationSetting;
