import React, { useEffect, useState } from 'react'
import CustomCard from '../../../custom-card/CustomCard'
import { Button, Col, Divider, Flex, Form, Row, Tag, Tooltip, Typography, theme } from 'antd'
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown'
import "./assignmentInformation.scss"
import { isEmpty } from 'lodash'
import { message } from 'antd/lib'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { DetailCourseData, Enterprises } from '../../../../types/assign-course-construction'
import { getDetailCourse, getLecturersFromEnterprises } from '../../../../service/assign-course-construction'
import { useParams } from 'react-router-dom'

type Prop = {
  enterprises: Enterprises[]
  planInfo: any
  mode: string
  detailData: DetailCourseData
  setLoading: (value: boolean) => void
  setDataUpdate: (data: any) => void
  setDataSponsorUpdate: (data: any) => void
}

export default function AssignedInformation(props: Prop) {
  const { enterprises, planInfo, mode, detailData, setLoading, setDataUpdate, setDataSponsorUpdate } = props

  const [countSchool, setCountSchool] = useState<any>([])
  const [countSchoolSponsor, setCountSchoolSponsor] = useState<any>([])
  const [SponsorOptionLecturers, setSponsorOptionLecturers] = useState<Enterprises[]>([])
  const [dataLecturerCooperation, setDataLecturerCooperation] = useState<any>([])
  const [dataLecturerCooperationObj, setDataLecturerCooperationObj] = useState<any>({})
  const [dataOption, setDataOption] = useState<any>({})
  const [initValue, setInitValue] = useState<any>({});
  const [inputVisible, setInputVisible] = useState<any>({});
  const [error, setError] = useState<any>({})
  const [dataAssigners, setDataAssigners] = useState<any>([])
  const [dataNotAssigners, setDataNotAssigners] = useState<any>([])
  const [form] = Form.useForm()

  const validations = {
    hostSchool: {
      required: true,
      message: "Vui lòng chọn trường chủ trì",
    },
    lecturerName: {
      required: true,
      message: "Vui lòng chọn giảng viên",
    },
    cooperativeSchool: {
      required: true,
      message: "Vui lòng chọn trường hợp tác",
    },
    lecturerCooperativeName: {
      required: true,
      message: "Vui lòng chọn giảng viên",
    },
  }



  const getValueCooperative = (value: string, index: number) => {
    const itemResult = countSchool.filter((item: any) => item.enterpriseId === value)
    if (isEmpty(itemResult)) {
      let newArr = [...countSchool]
      newArr[index].assigned = []
      newArr[index].enterpriseId = value
      setCountSchool(newArr)
    }
    //api để lấy thông tin giáo viên theo trường hợp tác
    form.resetFields([`lecturerCooperativeName${index}`])
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value
    }
    getLecturersFromEnterprises(data).then(async res => {
      const { content } = res.data

      const dataChange = content?.map((item: any, index: number) => {
        return item.assignUserId
      })
      const result = {
        enterpriseUUID: value,
        assigner: dataChange,
        isSponsor: false
      }
      setDataOption({ ...dataOption, [`lecturer${index}`]: content })
    })
  }

  const getValue = async (value: any) => {
    let newArr = [...countSchoolSponsor]
    if (!isEmpty(countSchoolSponsor)) {
      newArr[0].assigned = []
      newArr[0].enterpriseId = value
      setCountSchoolSponsor(newArr)
    } else {
      newArr.push({
        assigned: !isEmpty(dataAssigners) ? dataAssigners[0].assigned : [],
        enterpriseId: value,
        enterpriseName: "",
        isSponsor: true
      })
      setCountSchoolSponsor(newArr)
    }
    //api để lấy thông tin giáo viên theo trường chủ trì
    form.resetFields(['lecturerName'])
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value
    }
    await getLecturersFromEnterprises(data).then(res => {
      const { content } = res.data
      setSponsorOptionLecturers(content)
    })
  }

  const getDefaultValue = async (value: any) => {
    let newArr = [...countSchoolSponsor]
    if (!isEmpty(newArr)) {
      newArr[0].enterpriseId = value
      setCountSchoolSponsor(newArr)
    }

    form.resetFields(['lecturerName'])
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value
    }
    await getLecturersFromEnterprises(data).then(res => {
      const { content } = res.data
      setSponsorOptionLecturers(content)
    })
  }

  const openAddLecturer = (index: number, id: string) => {
    const arrAssigner = countSchool?.[index].assigned.map((item: any) => { return item.assignUserId })
    form.setFieldsValue({
      [`lecturerCooperativeName${index}`]: arrAssigner
    })
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: true })
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: id
    }
    getLecturersFromEnterprises(data).then(async res => {
      const { content } = res.data

      const dataChange = content?.map((item: any, index: number) => {
        return item.assignUserId
      })

      setDataOption({ ...dataOption, [`lecturer${index}`]: content })
    })
  }

  const openAddLecturerSponsor = (id: string) => {
    const arrAssigner = !isEmpty(countSchoolSponsor) && countSchoolSponsor[0].assigned.map((item: any) => { return item.assignUserId })
    if (!isEmpty(countSchoolSponsor)) {
      form.setFieldsValue({
        [`lecturerName`]: arrAssigner
      })
    }
    setInputVisible({ ...inputVisible, [`inputVisible`]: true })
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: id
    }
    getLecturersFromEnterprises(data).then(async res => {
      const { content } = res.data
      setDataOption({ ...dataOption, [`lecturer`]: content })
    })
  }

  const updateSponsor = () => {
    const dataSelect = form.getFieldValue([`lecturerName`])
    const data = dataSelect.map((item: any) => {
      const itemResult = dataOption[`lecturer`].filter((result: any) => result.assignUserId === item)
      return { ...itemResult }
    })

    const finishData = data.map((item: any, index: number) => {
      return { ...item[0] }
    })

    let arr = [...countSchoolSponsor]
    arr[0].assigned = finishData

    setCountSchoolSponsor(arr)
    setInputVisible({ ...inputVisible, [`inputVisible`]: false })
    form.resetFields([`lecturerName`])
  }

  const updateInfoAccount = (index: number) => {
    const dataSelect = form.getFieldValue([`lecturerCooperativeName${index}`])
    const data = dataSelect.map((item: any) => {
      const itemResult = dataOption[`lecturer${index}`].filter((result: any) => result.assignUserId === item)
      return { ...itemResult }
    })

    const finishData = data.map((item: any, index: number) => {
      return { ...item[0] }
    })

    let arr = [...countSchool]
    arr[index].assigned = finishData
    setCountSchool(arr)
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: false })
    form.resetFields([`lecturerCooperativeName${index}`])
  }

  const cancelUpdate = (index: number) => {
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: false })
    form.resetFields([`lecturerCooperativeName${index}`])
  }

  const cancelUpdateSponsor = () => {
    setInputVisible({ ...inputVisible, [`inputVisible`]: false })
    form.resetFields([`lecturerName`])
  }

  const closeItem = (index: number, item: any, enterpriseId: string) => {
    const finishData = [...countSchool]
    const arr = finishData.filter((data: any) => data.enterpriseId === enterpriseId)
    if (!isEmpty(arr)) {
      const data = arr[0].assigned.filter((result: any) => result.assignUserId !== item.assignUserId)
      arr[0].assigned = data
      // setCountSchool(finishData)
    }
  }

  const closeItemSponsor = (item: any) => {
    const finishData = [...countSchoolSponsor]
    const arr = finishData.filter((data: any) => data.enterpriseId === item.enterpriseId)
    const data = finishData[0].assigned.filter((result: any) => result.assignUserId !== item.assignUserId)
    finishData[0].assigned = data
  }

  const renderListCooperationSchool = countSchool.map((school: any, index: any) => (
    <div key={index}>
      {school.display ?
        <>
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={22}>
                  <Form.Item name={`cooperativeSchool${index}`} label="Trường hợp tác">
                    <FormItemDropdown options={enterprises} allowClear={true} onChange={(value) => getValueCooperative(value, index)}
                      placeholder="Nhập từ khoá cần tìm"
                    />
                  </Form.Item>
                </Col>
                <Col span={2} className='text-right'><Tooltip placement="top" title={"Xoá"}><CloseOutlined className='cursor-pointer' onClick={() => removeSchool(index)} /></Tooltip></Col>
              </Row>
            </Col>
            <Col span={22}>
              {!inputVisible[`inputVisible${index}`] ? (
                <Flex gap="4px 8px" wrap="wrap">
                  {
                    school.assigned.map((item: any, indexx: any) => (
                      <Tag bordered={false} closable color="success" key={indexx} onClose={() => closeItem(index, item, school.enterpriseId)}>{item.assignUserName}</Tag>
                    ))
                  }
                  <Tag onClick={() => openAddLecturer(index, form.getFieldValue([`cooperativeSchool${index}`]))} >
                    <PlusOutlined /> Thêm giảng viên
                  </Tag>
                </Flex>
              )
                : null}
              <Row>
                <Col span={24}>
                  {inputVisible[`inputVisible${index}`] ? (
                    <div>
                      <Form.Item name={`lecturerCooperativeName${index}`} label="Tên giảng viên" hasFeedback>
                        <FormItemDropdown modeDropdown="multiple" options={dataOption[`lecturer${index}`]} allowClear={true}
                          placeholder="Chọn giảng viên" />
                      </Form.Item>
                      <Flex gap="0px 8px" wrap="wrap" className='-mt-2'>
                        <Button type="primary" onClick={() => updateInfoAccount(index)}>Cập nhật</Button>
                        <Button onClick={() => cancelUpdate(index)}>Huỷ</Button>
                      </Flex>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {
              countSchool.length - 1 !== index ?
                <Divider /> : null
            }
          </Row>
        </> : null
      }
    </div >
  ))

  const addSchool = () => {
    const obj = {
      assigned: [],
      enterpriseId: "",
      enterpriseName: "",
      display: true,
      isSponsor: false
    }
    if (countSchool.filter((item: any) => item.display).length === 5) {
      message.warning('Không thể tạo quá 5 trường hợp tác')
    } else {
      const arr = [...countSchool]
      arr.push(obj)
      setCountSchool(arr)
    }
  }

  const removeSchool = (id: string) => {
    form.resetFields([`cooperativeSchool${id}`])
    delete dataOption[`lecturer${id}`]
    let arr = [...countSchool]
    arr[Number(id)].display = false
    setCountSchool(arr)
  }

  const saveData = () => {
    if (isEmpty(countSchoolSponsor?.[0]?.assigned)) {
      setError({ ...error, [`lecturerName`]: true })
      return
    } else {
      form.submit()
      setError({ ...error, [`lecturerName`]: false })
      setDataUpdate(countSchool)
      setDataSponsorUpdate(countSchoolSponsor)
    }
    // setLoading(true)
  }

  const params = useParams()

  const getDetailCourseData = async () => {
    await getDetailCourse(params?.id).then(res => {
      const { data } = res.data
      const dataAssigners = data.assigners.filter((item: any) => item.isSponsor)

      const dataNotAssigners = data.assigners.filter((item: any) => !item.isSponsor)
      if (!isEmpty(dataNotAssigners)) {
        const arr = dataNotAssigners.map((item: any) => {
          return { ...item, [`display`]: true }
        })
        setCountSchool(arr)
      }

      if (!isEmpty(dataAssigners)) {
        setCountSchoolSponsor(dataAssigners)
        getDefaultValue(dataAssigners[0].enterpriseId)
      }
      setDataAssigners(dataAssigners)
      setDataNotAssigners(dataNotAssigners)
      form.setFieldValue("hostSchool", dataAssigners?.[0]?.enterpriseId)
      form.setFieldValue("cooperativeSchool0", !isEmpty(dataNotAssigners[0]) && dataNotAssigners[0].enterpriseId)
      form.setFieldValue("cooperativeSchool1", !isEmpty(dataNotAssigners[1]) && dataNotAssigners[1].enterpriseId)
      form.setFieldValue("cooperativeSchool2", !isEmpty(dataNotAssigners[2]) && dataNotAssigners[2].enterpriseId)
      form.setFieldValue("cooperativeSchool3", !isEmpty(dataNotAssigners[3]) && dataNotAssigners[3].enterpriseId)
      form.setFieldValue("cooperativeSchool4", !isEmpty(dataNotAssigners[4]) && dataNotAssigners[4].enterpriseId)
    })
  }

  useEffect(() => {
    setLoading(true)
    getDetailCourseData()
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])


  return (
    <div className="assignment-infomation--wrapper">
      <CustomCard title={
        <div className="card-heading--info">
          <Typography.Title level={5}>
            Thông tin phân công
          </Typography.Title>
          <Typography.Text className="sub-title">
            Chi tiết thông tin trường - giảng viên được phân công
          </Typography.Text>
        </div>
      } className="card-info--wrapper">
        <Form layout={"vertical"} form={form} name="create-course-plan" initialValues={{
          hostSchool: !isEmpty(dataAssigners) && dataAssigners[0].enterpriseId,
          cooperativeSchool0: !isEmpty(dataNotAssigners[0]) && dataNotAssigners[0].enterpriseId,
          cooperativeSchool1: !isEmpty(dataNotAssigners[1]) && dataNotAssigners[1].enterpriseId,
          cooperativeSchool2: !isEmpty(dataNotAssigners[2]) && dataNotAssigners[2].enterpriseId,
          cooperativeSchool3: !isEmpty(dataNotAssigners[3]) && dataNotAssigners[3].enterpriseId,
          cooperativeSchool4: !isEmpty(dataNotAssigners[4]) && dataNotAssigners[4].enterpriseId,
        }}>
          <Typography.Title level={5}>
            Trường chủ trì khoá học
          </Typography.Title>
          <Row gutter={16}>
            <Col span={22}>
              <Form.Item name="hostSchool" rules={[validations.hostSchool]} label="Trường chủ trì" >
                <FormItemDropdown options={enterprises} allowClear={true} onChange={getValue}
                  placeholder="Nhập từ khoá cần tìm"
                />
              </Form.Item>
            </Col>
            <Col span={22}>
              {!inputVisible[`inputVisible`] ? (
                <Flex gap="4px 8px" wrap="wrap" className='mb-1'>
                  {
                    !isEmpty(countSchoolSponsor) && countSchoolSponsor?.[0]?.assigned.map((item: any, indexx: any) => (
                      <Tag bordered={false} closable color="success" key={indexx} onClose={() => closeItemSponsor(item)}>{item.assignUserName}</Tag>
                    ))
                  }
                  <Tag
                    onClick={() => openAddLecturerSponsor(form.getFieldValue([`hostSchool`]))}
                  >
                    <PlusOutlined /> Thêm giảng viên
                  </Tag>
                </Flex>
              )
                : null}
              <Row>
                <Col span={24}>
                  {inputVisible[`inputVisible`] ? (
                    <div>
                      <Form.Item name={`lecturerName`} rules={[validations.lecturerName]} label="Tên giảng viên" hasFeedback>
                        <FormItemDropdown modeDropdown="multiple" options={dataOption[`lecturer`]} allowClear={true}
                          placeholder="Chọn giảng viên" />
                      </Form.Item>
                      <Flex gap="0px 8px" wrap="wrap" className='mt-2 mb-2'>
                        <Button type="primary" onClick={updateSponsor}>Cập nhật</Button>
                        <Button onClick={cancelUpdateSponsor}>Huỷ</Button>
                      </Flex>
                    </div>
                  ) : null}
                </Col>
                {
                  error.lecturerName ? <p style={{ color: "red" }}>Không được bỏ trống trường chủ trì</p> : null
                }
              </Row>
            </Col>
          </Row>
          {
            !isEmpty(countSchool) ?
              <>
                <Typography.Title level={5}>
                  Trường hợp tác xây dựng
                </Typography.Title>
                {renderListCooperationSchool}
              </>
              : null
          }

          {
            mode === 'update' ?
              <>
                <p className='cursor-pointer blue-text-1 mb-2 mt-3' onClick={addSchool}>Thêm Trường hợp tác +</p>
                <Button type="primary" onClick={saveData} className='w-full'>Lưu và chuyển bước kế tiếp</Button>
              </> : null
          }

        </Form>
      </CustomCard>
    </div>
  )
}
