import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
  Row,
  Col,
  Form,
  Typography,
} from "antd";
import '../detail.scss'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
  FilterOutlined,
  MoreOutlined,
} from "@ant-design/icons";



import { useForm } from "antd/es/form/Form";
import { searchQuestionInSurvey } from "../../../../../../../service/survey-managment/survey-content";
import {
  useSurveyDetail,
  useSurveyInfo,
} from "../../../../../../../stores/stores";
import EditorQuill from "../../editor-quill/EditorQuill";
import DetailQuestionFilter from "../DetailQuestionFilter";
import { ListQuestion, ListSurveySection } from "../../../../../../../types/survey-managment/survey";

interface DetailSectionProps {
  setUpdateDataSection: (data: ListSurveySection[]) => void;
}





const options1: MenuProps["items"] = [
  {
    key: "1",
    label: "Tạo bản sao",
    icon: <CopyOutlined />,
  },
  {
    key: "2",
    label: "Chuyển lên trên",
    icon: <ArrowUpOutlined />,
  },
  {
    key: "3",
    label: "Chuyển xuống dưới",
    icon: <ArrowDownOutlined />,
  },
  {
    key: "4",
    label: "Xóa",
    icon: <DeleteOutlined />,
    className: "dropdown-delete",
    danger: true,
  },
];

const SectionQuestionFilter: React.FC<DetailSectionProps> = ({

  setUpdateDataSection,
}) => {
  // const listSurveyFilterSection = useMemo(
  //   () => dataSection,
  //   [dataSection, setUpdateDataSection]
  // );

  const { listSurveyFilterSection, setListSurveyFilterSection } =
    useSurveyDetail((state) => {
      return {
        listSurveySections: state.listSurveySections,
        listSurveyFilterSection: state.listSurveyFilterSection,
        setListSurveyFilterSection: state.setListSurveyFilterSection
      };
    });

  const surveyInfo = useSurveyInfo((state: any) => state.surveyInfo);

  const moveItemUp = (index: number) => {
    if (index === 0) return; // Already at the top
    let newListSurveySections = [...listSurveyFilterSection];
    // Di chuyển phần tử lên trên
    [newListSurveySections[index - 1], newListSurveySections[index]] = [
      newListSurveySections[index],
      newListSurveySections[index - 1],
    ];
    // Cập nhật lại orderNumber
    newListSurveySections[index - 1].orderNumber = index; // Cập nhật orderNumber của phần tử mới
    newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển

    setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
  };

  const moveItemDown = (index: number) => {
    if (index === listSurveyFilterSection.length - 1) return; // Already at the bottom
    let newListSurveySections = [...listSurveyFilterSection];
    [newListSurveySections[index], newListSurveySections[index + 1]] = [
      newListSurveySections[index + 1],
      newListSurveySections[index],
    ];

    // Cập nhật lại orderNumber
    newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
    newListSurveySections[index + 1].orderNumber = index + 2; // Cập nhật orderNumber của phần tử mới

    setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
  };

  const [optionDropDown, setIsOption] = useState(options1);

  const handleOptionDropDown = (index: number) => {
    let newOptions = [...options1];

    if (listSurveyFilterSection.length < 2) {
      newOptions.splice(1, 2);
    }
    if (listSurveyFilterSection.length > 1) {
      if (index === 0) {
        newOptions.splice(1, 1);
      }
      if (index === listSurveyFilterSection.length - 1) {
        newOptions.splice(2, 1);
      }
    }
    setIsOption(newOptions);
  };

  const [isShowFilter, setIsShowFilter] = useState(false);
  const handleShowFilterQuestion = () => {
    setIsShowFilter(!isShowFilter);
  };
  const [form] = useForm();





  const handleCopySection = (sectionNumber: number) => {
    let newSection: ListSurveySection = {
      ...listSurveyFilterSection[sectionNumber],
      id: undefined,
    };
    let updatedListData = [...listSurveyFilterSection];
    updatedListData.splice(sectionNumber, 0, newSection);

    // Cập nhật lại orderNumber cho các phần tử sau vị trí được copy
    for (let i = sectionNumber; i < updatedListData.length; i++) {
      updatedListData[i].orderNumber = i + 1;
    }
    setUpdateDataSection(updatedListData);
  };

  const handleDeleteSection = (sectionNumber: number) => {
    let updatedListData: ListSurveySection[] = listSurveyFilterSection.map((item, index) => {
      if (index === sectionNumber) {
        return {
          ...item,
          isDeleted: true,
        };
      }
      return item;
    });

    // Cập nhật lại orderNumber cho các phần tử sau vị trí bị xóa
    for (let i = sectionNumber + 1; i < updatedListData.length; i++) {
      updatedListData[i].orderNumber = i;
    }
    setUpdateDataSection(updatedListData);
  };


  const [isShowDetailSection, setIsShowDetailSection] = useState(
    new Array(listSurveyFilterSection.length).fill(true)
  );

  const [isShowDescriptionList, setIsShowDescriptionList] = useState(
    new Array(listSurveyFilterSection.length).fill(false)
  );
  // console.log('list section')
  // console.log({ listSurveyFilterSection })
  return (

    <div className="card-not-found" style={{ marginTop: 15 }}>
      {listSurveyFilterSection.map((item, index) => {
        const {
          sectionName,
          sectionDescription,
          moocSurveyQuestion,
        }: ListSurveySection = item;

        const updateNewListSectionQuestions = (newList: ListQuestion[]) => {
          // Tạo một mảng mới để cập nhật lại listSurveySections
          const updatedListSurveySections = listSurveyFilterSection.map(
            (section, position) => {
              // Tìm section có id tương ứng với section đang xem xét
              if (position === index) {
                // Trả về một bản sao của section với moocSurveyQuestion được cập nhật
                return {
                  ...section,
                  moocSurveyQuestion: newList,
                };
              }
              // Trả về các section khác không cần cập nhật
              return section;
            }
          );

          // Cập nhật state hoặc dữ liệu cha với mảng mới đã được cập nhật
          setUpdateDataSection(updatedListSurveySections); //cập nhật lên page DetailSurvey
        };

        const shortSectionName = sectionName
          ? sectionName.length > 50
            ? sectionName?.slice(0, 50)
            : sectionName
          : "";

        const updateDescription = (newDescription: string) => {
          const updatedListData = listSurveyFilterSection.map((item, idx) => {
            if (idx === index) {
              return { ...item, sectionDescription: newDescription };
            }
            return item;
          });
          // console.log(newDescription)
          setUpdateDataSection(updatedListData); //cập nhật lên page DetailSurvey
        };

        const handleToggleDescription = (index: number) => {
          const newIsShowDescriptionList = [...isShowDescriptionList];
          newIsShowDescriptionList[index] =
            !newIsShowDescriptionList[index];
          setIsShowDescriptionList(newIsShowDescriptionList);
        };

        const handleToggleShowDetailSection = (index: number) => {
          const newIsShowDetailSection = [...isShowDetailSection];
          newIsShowDetailSection[index] = !newIsShowDetailSection[index];
          setIsShowDetailSection(newIsShowDetailSection);
        };

        return (
          <div key={index} id={`sectionElement-${index}`} >
            {/* Section detail */}
            <Card
              title={
                listSurveyFilterSection.length < 2
                  ? ``
                  : `Phần ${index + 1}/${listSurveyFilterSection.length
                  } : ${shortSectionName} . . .` //`Phần ${index + 1}/${listSurveySections.length}`
              }
              styles={{
                header: { backgroundColor: "#e6f7ff" },
                body: { display: isShowDetailSection ? "block" : "none" },
              }} // headStyle={{backgroundColor:'#e6f7ff'}}
              className="wrapper-settings--card form-setting--common"
              extra={
                <>
                  <Row>
                    <Space>
                      <Col>
                        <Button
                          onClick={() =>
                            handleToggleShowDetailSection(index)
                          }
                        >
                          {isShowDetailSection[index]
                            ? `Thu gọn`
                            : `Hiển thị chi tiết`}
                        </Button>
                      </Col>
                      <Col>
                        <Dropdown
                          onOpenChange={() => handleOptionDropDown(index)}
                          menu={{
                            items: optionDropDown,
                            onClick: ({ key }) => {
                              if (key === "1") {
                                Modal.confirm({
                                  title: `Bạn có muốn tạo bản sao của thành phần này trong khảo sát`,
                                  onOk: () => handleCopySection(index),
                                  okText: "Đồng ý",
                                  cancelText: "Huỷ",
                                  centered: true,
                                  icon: (
                                    <CopyOutlined
                                      style={{ color: "#1677FF" }}
                                    />
                                  ),
                                  footer: (_, { OkBtn, CancelBtn }) => (
                                    <>
                                      <CancelBtn />
                                      <OkBtn />
                                    </>
                                  ),
                                });
                              }

                              if (key === "2") {
                                moveItemUp(index);
                              }
                              if (key === "3") {
                                moveItemDown(index);
                              }
                              if (key === "4") {
                                Modal.confirm({
                                  title: `Bạn có chắc chắn muốn xóa thành phần này trong khảo sát`,
                                  onOk: () => handleDeleteSection(index),
                                  okText: "Đồng ý xóa",
                                  cancelText: "Huỷ",
                                  centered: true,
                                  icon: (
                                    <CopyOutlined
                                      style={{ color: "#1677FF" }}
                                    />
                                  ),
                                  footer: (_, { OkBtn, CancelBtn }) => (
                                    <>
                                      <CancelBtn />
                                      <OkBtn />
                                    </>
                                  ),
                                });
                              }
                            },
                          }}
                          placement="bottomRight"
                        >
                          <Button>
                            <MoreOutlined />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Space>
                  </Row>
                </>
              }
            >
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <Input
                  className="input-header-title"
                  placeholder="Nhập tiêu đề"
                  style={{ fontSize: "20px", color: "black" }}
                  defaultValue={sectionName}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const updatedListData = listSurveyFilterSection.map((item, idx) => {
                      if (idx === index) {
                        return { ...item, sectionName: newValue };
                      }
                      return item;
                    });
                    setUpdateDataSection(updatedListData);
                  }}
                />

                {sectionDescription === "" || !sectionDescription ? (
                  <>
                    {isShowDescriptionList[index] &&
                      isShowDetailSection[index] ? (
                      <EditorQuill
                        valueInitial={sectionDescription}
                        setUpdateValue={updateDescription}
                      />
                    ) : (
                      isShowDetailSection[index] && (
                        <Input
                          placeholder="Nhập mô tả"
                          onFocus={() => handleToggleDescription(index)}
                        />
                      )
                    )}
                  </>
                ) : (
                  <>
                    <EditorQuill
                      valueInitial={sectionDescription}
                      setUpdateValue={updateDescription}
                    />
                  </>
                )}
              </Space>
            </Card>

            <DetailQuestionFilter
              listSectionQuestions={moocSurveyQuestion}
              sectionId={Number(item.id)}
              updateListSectionQuestions={updateNewListSectionQuestions}
              showDetailSection={isShowDetailSection[index]}
            />
            {/* End of List Question */}
          </div>
        );
      })}
    </div>


  );
};

export default SectionQuestionFilter;
