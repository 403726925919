import {
  Avatar,
  Flex,
  Form,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
  message
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import { EyeOutlined } from "@ant-design/icons";

import PageHeader from "../../components/page-header/PageHeader";
import TableData from "../../components/table-data/TableData";
import { routesConfig } from "../../config/routes";
import { getListCourseVideo } from "../../service/setting-quality";
import FilterTable from "./filter-table/FilterTable";

type ControlQualityLessonVideoProps = {
  title?: string;
};

const ControlQualityLessonVideo = ({
  title,
}: ControlQualityLessonVideoProps) => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [listCourseVideo, setListCourseVideo] = useState<any[]>([]);
  const [dataFilter, setDataFilter] = useState({
    name: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getListCourseVideo(searchParam);
      const { data } = response.data;
      setListCourseVideo(data.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      name: searchValue,
      module: "video",
      sort: "asc",
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber]);

  const dataColumnsListCourse: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
              {/* <span>{record?.diaChi}</span> */}
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const onLink = () => {
          navigate(`/${routesConfig.controlQualityLesson}/${record?.id}`);
        };
        return (
          <Space>
            <span className="action-table cursor-pointer" onClick={onLink}>
              <EyeOutlined />
            </span>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsListCourse.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsListCourse.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        ...dataFilter,
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      ...dataFilter,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = () => {};

  return (
    <Layout className="page-header-group">
      {contextHolder}
      <Form.Provider>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterTable
              searchValue={searchValue}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
            />
          </div>
        </div>
        <TableData
          dataTable={listCourseVideo}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1200, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ControlQualityLessonVideo;
