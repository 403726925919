import "./detail.scss";
import {
  Button,
  Input,
  Checkbox,
  Row,
  Space,
  ConfigProvider,
  Radio,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListAnswer } from "../../../../../../../types/survey-managment/survey";
import TextArea from "antd/es/input/TextArea";

const QuestionType5 = (props: any) => {
  const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const onHandleDeleteOption = (index: number) => {
    /*
    const newOptions = options.map((option, i) => {
      if (i === index) {
        // Nếu là tùy chọn cần xóa, chỉ cần đặt trạng thái is_deleted thành true
        return {
          ...option,
          is_deleted: true
        };
      }
      return option;
    });
    */

    const newOptions = options.filter((_, i) => i !== index);

    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  const onHandleAddOption = () => {
    let newOptions = [...options];

    const addedOption = {
      answerText: `Chọn văn bản ${options.length + 1} `,
      answerOrderNumber: options.length + 1,
    };
    newOptions.push(addedOption);
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  const handleBlur = () => {
    setShowPlaceholder(true);
  };

  const handleChangeAnswerText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      answerText: event.target.value,
    };
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  useEffect(() => {
    setOptions(props.listOptions);
  }, [props.listOptions]);

  return (
    <div className="survey-managment-construction">
      <div>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "black",
                paddingInline: 0,
                paddingBlock: 0,
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
              },
            },
          }}
        >
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            {options.map((option, index) => {
              return (
                <div key={index}>
                  {!option.isOther && (
                    <Row
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={false}
                        style={{ width: "20px" }}
                      ></Checkbox>
                      <TextArea
                        style={{
                          flex: 1,
                          marginLeft: "10px",
                          marginRight: "10px",
                          padding: "10px 0",
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "1.5",
                        }}
                        autoSize
                        variant="borderless"
                        value={
                          option.answerText || option.answerText === ""
                            ? option.answerText
                            : `Lựa chọn văn bản ${options.length}`
                        }
                        onChange={(event) =>
                          handleChangeAnswerText(event, index)
                        }
                      />
                      <Button
                        style={{ width: 20, height: 25, marginLeft: "5px" }}
                        icon={<CloseOutlined />}
                        onClick={() => {
                          onHandleDeleteOption(index);
                        }}
                      />
                    </Row>
                  )}
                </div>
              );
            })}
            <Row
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                prefix={
                  <>
                    <Checkbox checked={false}></Checkbox>
                    <Button
                      type="link"
                      onClick={onHandleAddOption}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Thêm lựa chọn
                    </Button>
                  </>
                }
                variant="borderless"
                type="submit"
              />
            </Row>
            {props.isQuestionHasOther && (
              <Row
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  prefix={
                    <>
                      <Checkbox checked={false}></Checkbox>
                      <span style={{ minWidth: "200px" }}>
                        <i>
                          {" "}
                          Lựa chọn nhập <u>"Khác"</u>
                        </i>
                      </span>
                    </>
                  }
                  variant="borderless"
                />
              </Row>
            )}
          </Space>
        </ConfigProvider>
        <br />
      </div>
    </div>
  );
};

export default QuestionType5;
