import { Button, Collapse, Drawer, Form, Radio, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import "./index.scss"
import { isEmpty, size } from 'lodash'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailDepartmentalUserAccountDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")

  const getListDataDetail = async () => {
    // const data = {
    //   page: pageNumber,
    //   size: pageSize,
    //   sort: statusOrder,
    //   sequenceId: detailData?.id
    // }
    // await getTableDataDetailSequence(data).then((res) => {
    //   setListTableDataDetail(res.data?.data?.content)
    // })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Drawer
      title={"Xem chi tiết tài khoản người dùng cấp bộ"}
      open={open}
      onClose={onClose}
      className='detail-departmental-user-account-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-departmental-user-account-admin"
        layout={"vertical"}
        form={form}
        name="form-detail-departmental-user-account-admin"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel
              header="Thông tin người dùng"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Đơn vị</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.organizationName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người dùng</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.universityTypeName}</p></span>
              </div>
            </Panel>

            <Panel
              header="Thông tin tài khoản"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Email đăng nhập</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.districtName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Vai trò</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.wardName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Trạng thái</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.address}</p></span>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
