import React, { useEffect, useState } from 'react'
import CustomCard from '../../../custom-card/CustomCard'
import { Button, Col, Form, Row, Select, Table, TableColumnType, TableColumnsType, TreeDataNode, Typography, message } from 'antd'
import DirectoryTree, { DirectoryTreeProps } from 'antd/es/tree/DirectoryTree'
import Icon, { ArrowLeftOutlined, CaretDownOutlined, CaretUpOutlined, FileOutlined, InfoCircleOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import "./index.scss"
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown';
import { isEmpty } from 'lodash';
import { DetailCourseData } from '../../../../types/assign-course-construction';
import { getListLecturers, updateAssignCourse } from '../../../../service/assign-course-construction';

type Props = {
  setTab: (value: string) => void
  mode: string
  tab: string
  detailData: DetailCourseData
}

const locale = {
  emptyText: (
    <span>
      <p>
        <img className='image-empty-data' src="/images/empty-img-gray.png" alt="empty-img"></img>
      </p>
      <p className='nodata-text'>Không tìm thấy</p>
    </span>
  ),
  triggerDesc: 'Sắp xếp theo thứ tự Z-A',
  triggerAsc: 'Sắp xếp thứ tự A-Z',
  cancelSort: 'Huỷ sắp xếp'
};

export default function AssignedDetailedAssignment(props: Props) {
  const { setTab, mode, detailData, tab } = props
  const [expandAll, setExpandAll] = useState(false)
  const [listDataLecturers, setListDataLecturers] = useState([])
  const [expandedList, setExpandedList] = useState<any>([])
  const [form] = Form.useForm()

  interface DataType {
    key: React.ReactNode;
    name: string;
    assignedName: string;
    assignedId: string;
    children?: DataType[];
    dataIndex: string
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'assignedName',
      width: '40%',
      key: 'assignedName',
      render: (_: any, record) => {
        return (
          <Form.Item name={`lecturerCooperativeName${record.key}`} label="" dependencies={[`cooperativeSchool${record.key}`]} hasFeedback>
            <FormItemDropdown options={listDataLecturers} allowClear={true}
              placeholder="Chọn giảng viên"
            />
          </Form.Item>
        )
      },
    },
  ];

  const data: any = !isEmpty(detailData) && detailData?.sections.map((item: any, index: number) => {
    return {
      key: item.key,
      name: item.name,
      assignedName: item.assignedName,
      assignedId: item.assignedId,
      children: item.children
    }
  })

  const objInitValue: any = {}

  detailData?.sections?.map((item: any) => {
    objInitValue[`lecturerCooperativeName${item.id}`] = item.assignedId
  })

  const searchListLecturer = async () => {
    await getListLecturers(detailData.id).then(res => {
      const { data } = res.data
      setListDataLecturers(data)
    })
  }

  useEffect(() => {
    searchListLecturer()
  }, [tab])

  const expandAllFnc = () => {
    if (expandAll) {
      const arrId = detailData?.sections.map((item: any) => item.id.toString())
      setExpandedList(arrId)
    } else {
      setExpandedList([])
    }
  }

  useEffect(() => {
    if (detailData) {
      expandAllFnc()
    }
  }, [detailData, expandAll])

  return (
    <CustomCard title={
      <div className="card-heading--info">
        <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
          <Col xl={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>

            <Typography.Title level={5}>
              Cấu trúc của khoá học
            </Typography.Title>
            <Typography.Text className="sub-title">
              Chi tiết cấu trúc được duyệt của kế hoạch
            </Typography.Text>
          </Col>
          <Col xl={{ span: 4 }} md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }}></Col>
          <Col xl={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
            <Button onClick={() => setExpandAll(!expandAll)} className='float-right'>{expandAll ? "Thu gọn" : "Hiển thị chi tiết"}</Button>
          </Col>

        </Row>
      </div>
    } className="card-info--wrapper">
      <Form.Provider onFormFinish={async (name, { values, forms }) => {
        if (name === "create-detail-course-plan") {
          const props = Object.getOwnPropertyNames(values)
          const param = props.map((item: any) => {
            return item.replace("lecturerCooperativeName", "")
          })

          const dataApi = param.map((item) => {
            return {
              sectionId: Number(item),
              teacherId: values[`lecturerCooperativeName${item}`],
            }
          })
          const lastDataApi = dataApi.filter((item) => item.teacherId)
          await updateAssignCourse(detailData.id, { assigners: lastDataApi }).then(res => {
            message.success("Lưu thông tin thành công")
          })
        }
      }}>
        <Form layout={"vertical"} form={form} name="create-detail-course-plan" initialValues={objInitValue}>
          <div className="list-wrapper">
            <Table
              size="small"
              indentSize={0}
              columns={columns}
              dataSource={data}
              pagination={false}
              locale={locale}
              rowClassName={(record, index) => {
                if (!isEmpty(record.children)) {
                  return 'expand-record'
                }
                return "";
              }}
              expandable={{
                expandRowByClick: true,
                expandedRowKeys: expandedList,
                onExpand: (expanded, record) => {
                  // if (expanded) {
                  //   const arr = [...expandedList]
                  //   arr.push(record?.key?.toString())
                  //   setExpandedList(arr)
                  //   console.log("onExpand: ", record, expanded)
                  // }
                },
                onExpandedRowsChange(expandedKeys) {
                  setExpandedList(expandedKeys)

                },
              }}
            />
            {
              mode === 'update' ?
                <><Button onClick={() => form.submit()} type="primary" className='w-full mt-3'>Lưu</Button>
                  <p className='mt-2 blue-text-1 cursor-pointer' onClick={() => setTab("1")}><ArrowLeftOutlined className='mr-5' /> Quay lại bước trước
                  </p></> : null
            }
          </div>
        </Form>
      </Form.Provider>
    </CustomCard>
  )
}
