import {Button, Flex, Form, message, Typography, Upload} from 'antd';
import React, {MouseEvent, useEffect, useState} from 'react';
import {CloseOutlined, DeleteOutlined, LoadingOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import './index.scss'
import {useParams} from "react-router-dom";
import {
  createTeacherInstruct,
  getEnterpriseCourse,
  getTeacherCourse,
  updateImageTeacher
} from "../../../../../service/content-course/contenCourseApi";
import {ConstructCourseDetailType} from '../../../../../types/course-construction';
import {useAuthStore} from "../../../../../stores/stores";
import {FileType} from "../../../../../utils/uploadImage";
import CustomCard from "../../../../custom-card/CustomCard";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import FormItemInput from '../../../../form-input/FormInput';
import TextArea from "antd/es/input/TextArea";
import {BASE_URL_V2} from '../../../../../config/api/configApiv2';

type TabInstructor = {
  dataInstructorTeacher: Array<any>
  dataConfig?: ConstructCourseDetailType,
  isDirty: boolean,
  setDirty: (value: boolean) => void
}


const validation = {
  instructor: {
    required: true,
    message: "Vui lòng chọn người hướng dẫn"
  },
  enterprise: {
    required: true,
    message: "Vui lòng chọn trường"
  }
}


const TabInstructor = ({
                         dataInstructorTeacher, isDirty,
                         setDirty
                       }: TabInstructor) => {
  const [loading, setLoading] = useState(false);
  const {id} = useParams()
  const [dataAssigner, setDataAssigner] = useState([]);
  const [dataEnterprise, setDataEnterprise] = useState([]);
  const [form] = Form.useForm()
  const [listData, setListData] = useState({})

  const accessToken = useAuthStore((state) => state.accessToken);
  const [disabledFields, setDisabledFields] = useState<Array<boolean>>([]);
  const [isDelete, setIsDelete] = useState(false)

  const [enterpriseData, setEnterpriseData] = useState<string[]>([])
  const [enterpriseNewData, setEnterpriseNewData] = useState([])
  // const [newData, setNewData] = useState<any>([])
  const [dataInstructor, setDataInstructor] = useState<any[]>([])
  const [isEdit, setIsEdit] = useState(false)
  const [isCreateNewEl, setIsCreateNewEl] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState<any>({});
  const [checkValueInput, setCheckValueInput] = useState({
    enterpriseDisable: false,
    instructorDisable: false
  });
  const [dataImage, setDataImage] = useState<any>({});
  const [dataImageFile, setDataImageFile] = useState<any>({});
  const [imagesUpload, setImagesUpload] = useState({})
  const [initialTeachers, setInitialTeachers] = useState<any>([]);
  const [idDelete, setIdDelete] = useState([])
  const [selectValue, setSelectValue] = useState(0)

  const valueInstructor = Form.useWatch('instructors', form)


  const createConfigTeacher = async (dataReq: any, statusMes?: string) => {
    const response = await createTeacherInstruct(dataReq)
    if (statusMes === 'create') {
      message.success("Cài đặt thành công")
    } else if (statusMes === 'edit') {
      message.success("Cập nhật cài đặt thành công")
    } else if (statusMes === 'delete') {
      message.success("Xóa người hướng dẫn thành công")
    } else {
      message.success("Cài đặt thành công")
    }


  }


  const getEnterprise = async (id: number) => {
    try {
      const res = await getEnterpriseCourse(id)
      const {data} = res.data
      setDataEnterprise(data)
    } catch (err) {
      console.error(err)
    }
  }


  const getTeacher = async (id: string) => {
    try {
      const res = await getTeacherCourse(id)
      const {data} = res.data
      setDataAssigner(data)

    } catch (err) {
      console.error(err)
    }
  }


  const fillData = () => {
    form.setFieldsValue({
      instructors: dataInstructorTeacher.map((val: any) => ({
        teacherId: val.teacherId,
        uuid: val.uuid,
        email: val.email,
        teacherName: val.teacherName,
        imageTeacher: val.imageTeacher,
        biography: val.biography,
        id: val.id,
      }))
    })
    setEnterpriseData(dataInstructorTeacher.map((val: any, index: number) => (
      val.uuid
    )))
    setInitialTeachers(dataInstructorTeacher.map((teacher: any) => teacher.teacherId));
    const initialImages: any = {};
    dataInstructor.forEach((teacher: any, index: number) => {
      initialImages[index] = teacher.imageTeacher;
    });
    setDataImageFile(initialImages);

  }


  const updateImage = async (dataReq: any) => {
    try {
      const res = await updateImageTeacher(dataReq)
      const {status} = res.data
      if (status === 200) {
        message.success("Thiết lập ảnh thành công");
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleBeforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Bạn phải tải lên file JPG hoặc PNG !');
      setTimeout(() => {
        setLoadingUpload(false);
      }, 333);
    }
    if (file.size / 1024 / 1024 > 25) {
      message.error("File tải lên không vượt quá 25mb");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }
    return isJpgOrPng;
  };

  const handleChange = (info: any, name: any) => {
    if (info.file.status === 'uploading') {
      setLoadingUpload({...loadingUpload, [name]: true});
      return;
    }
    if (info.file.status === 'error') {
      message.error('Xảy ra lỗi khi tải ảnh lên !');
    }
    if (info.file.status === 'done') {
      message.success('Tải ảnh lên thành công !');
      const {response} = info.file;
      if (response && response.filePath) {
        setDataImage({
          ...dataImage,
          [name]: response.fileName
        });
        setDataImageFile({
          ...dataImageFile,
          [name]: response.filePath
        })
        form.setFieldsValue({
          instructors: form.getFieldValue('instructors').map((teacher: any, index: any) =>
            index === name ? {...teacher, imageTeacher: response.filePath} : teacher
          )
        });
        setLoadingUpload({...loadingUpload, [name]: false});

      }
    }
  };


  useEffect(() => {
    if (id) {
      getEnterprise(Number(id))
    }
  }, [id]);


  useEffect(() => {
    if (enterpriseData && enterpriseData.length > 0) {
      dataInstructor.map((_: unknown, index: number) => getTeacher(enterpriseData[index]))
    }
  }, [enterpriseData]);


  useEffect(() => {
    if (enterpriseNewData.some(val => val !== undefined)) {
      enterpriseNewData.map((val) => {
        if (val !== undefined) {
          return getTeacher(val)
        }
      })
    }
  }, [enterpriseNewData]);


  useEffect(() => {
    if (dataInstructorTeacher.length > 0) {
      setDataInstructor(dataInstructorTeacher)
      fillData()
    }

  }, [dataInstructorTeacher])

  console.log(dataInstructorTeacher)

  useEffect(() => {
    if (valueInstructor
      && valueInstructor.some((val: any) => val.id !== undefined)) {
      setIsEdit(true)
      setIsCreateNewEl(false)
    }
  }, [valueInstructor]);


  useEffect(() => {
    if (valueInstructor && valueInstructor.length > 0 && valueInstructor.some((val: any) => val.id === undefined)) {
      setIsEdit(false)
      setIsCreateNewEl(true)
    }
  }, [valueInstructor]);


  const newDataEnterprise = dataEnterprise && dataEnterprise.map((item: any) => {
    return {
      name: item.enterName,
      code: item.uuid
    }
  })

  const newData = dataAssigner && dataAssigner.map((item: any) => {
    return {
      name: item.nameUser,
      code: item.userId,
      disabled: false
    }
  })


  const onReset = () => {
    fillData()
  }


  const onFinish = (values: any) => {
    setDirty(false)
    const dataAssigner = values.instructors.filter((teacher: any) => teacher.imageTeacher);
    // @ts-ignore
    const newTeachers = values.instructors.filter((teacher: any) => !initialTeachers.includes(teacher.teacherId));
    // @ts-ignore
    const checkExistValueTeachers = values.instructors.some((teacher: any) => !initialTeachers.includes(teacher.teacherId))
    const convertValueForm = values.instructors.filter((teacher: any) => initialTeachers.includes(teacher.teacherId))
      .map((i: any, index: number) => ({
        id: i.id,
        teacherId: i.teacherId,
        uuid: i.uuid,
        imageTeacher: dataImage[index],
        biography: i.biography
      }))
    if (values.instructors &&
      Array.isArray(values.instructors) &&
      values.instructors.length > 0 && dataAssigner.length > 0) {
      const dataImageConvert = values.instructors.map((item: any, i: number) => ({
        teacherId: item.teacherId,
        imageTeacher: dataImage[i]
      }))
      const dataImageReq = {
        courseId: Number(id),
        courseTeacherUpdateRequest: dataImageConvert
      }
      updateImage(dataImageReq)

    }


    const newValue = newTeachers.map((teacher: any, index: number) => ({
      teacherId: teacher.teacherId,
      uuid: teacher.uuid,
      imageTeacher: Object.values(dataImage)[index],
      biography: teacher.biography
    }))
    const deleteData = {
      courseId: Number(id),
      courseTeacherAddNewRequest: [],
      courseTeacherUpdateRequest: [],
      teacherDelete: idDelete
    };

    if (dataInstructorTeacher.length > 0 && dataInstructorTeacher.some((val: {
      id: number;
    }) => val.id > 0) && values.instructors.some((val: any) => val.id !== undefined) && !isDelete && isEdit) {
      const checkData = values.instructors.some((teacher: any) => !initialTeachers.includes(teacher.teacherId))
      const dataUpdate = {
        courseId: Number(id),
        courseTeacherAddNewRequest: [],
        courseTeacherUpdateRequest: checkData ? values.instructors : convertValueForm,
        teacherDelete: []
      }
      createConfigTeacher(dataUpdate, 'edit')
    }
    if (dataInstructorTeacher.length < 1 && values.instructors.some((val: any) => val.id === undefined && !('id' in val)) && !isDelete && !isEdit) {
      const dataReq = {
        courseId: Number(id),
        courseTeacherAddNewRequest: newValue,
        courseTeacherUpdateRequest: [],
        teacherDelete: []
      }
      createConfigTeacher(dataReq, 'create')
    }

    if (dataInstructorTeacher.some((val: {
      id: number;
    }) => val.id > 0) && values.instructors.some((val: any) => val.id === undefined) && !isEdit && isCreateNewEl) {
      if (checkExistValueTeachers) {
        const data = {
          courseId: Number(id),
          courseTeacherAddNewRequest: newValue,
          courseTeacherUpdateRequest: convertValueForm,
          teacherDelete: []
        }
        createConfigTeacher(data)
      } else {
        message.error('Người hướng dẫn đã tồn tại.')
      }

    }

    if (idDelete.length > 0 && isDelete) {

      createConfigTeacher(deleteData, 'delete')
        .then(() => {
          setIsDelete(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }


  }
  const addNewTeacher = () => {
    const teachers = form.getFieldValue('instructors') || [];
    setDisabledFields([...disabledFields, false]);
    form.setFieldsValue({
      instructors: [...teachers, {teacherId: undefined, teacherName: '', email: '', imageTeacher: '', biography: ''}],
    });
    setIsEdit(false)
    setIsCreateNewEl(true)
    if (dataInstructorTeacher.some((val: {
      id: number;
    }) => val.id > 0) && valueInstructor.some((val: any) => val.id === undefined) && !isEdit && isCreateNewEl) {
      setCheckValueInput({
        ...checkValueInput,
        instructorDisable: true
      })
    }
  };


  const handleTeacherIdChange = (value: any, name: number) => {
    // @ts-ignore
    const selectedTeacher: any = dataAssigner.find((teacher: any) => teacher.userId === value);
    if (selectedTeacher) {
      // @ts-ignore
      form.setFieldsValue({
        instructors: form.getFieldValue('instructors').map((teacher: any, index: number) =>
          index === name ? {
            ...teacher,
            teacherName: selectedTeacher.nameUser,
            email: selectedTeacher.userEmail
          } : teacher
        )
      });
      setSelectValue(value)
    }
  };

  const handleValueChange = (changedValues: any, allValues: { instructors: any; }) => {
    const {instructors} = allValues;
    setDirty(true)
    if (instructors.some((val: any) => !val.teacherId)) {
      setEnterpriseNewData(instructors.map((val: any, index: any) => {
        if (!val.teacherId) {
          return val.uuid
        }
      }))

    } else {
      setEnterpriseNewData(changedValues.instructors.map((val: any, index: any) => {
        return val.uuid
      }))
    }
  };

  const handleRemove = (field: any, remove: any) => {
    if (dataInstructor[field.key] && dataInstructor[field.key].id !== undefined) {
      setIdDelete((prevIdDelete: any) => {
        if (!prevIdDelete.includes(dataInstructor[field.key].id)) {
          return [...prevIdDelete, dataInstructor[field.key].id];
        }
        return prevIdDelete;
      });
      setIsDelete(true)
      remove(field.name);
    } else {
      remove(field.name);
      setDisabledFields(disabledFields.filter((_, index) => index !== field.key));
      const updatedImages = {...dataImage};
      delete updatedImages[field.key];
      setDataImage(updatedImages);

    }
    if (dataInstructorTeacher.some((val: {
      id: number;
    }) => val.id > 0) && valueInstructor.some((val: any) => val.id !== undefined) && !isEdit && isCreateNewEl) {
      setCheckValueInput({
        ...checkValueInput,
        instructorDisable: false
      })
    }

  };


  const filterOptions = (selectedIds: string | any[], options: any[]) => {
    return options.map(option => ({
      ...option,
      disabled: selectedIds.includes(option.code),
    }));
  };

  const handleRemoveImage = (e: MouseEvent, name: any) => {
    e.preventDefault();
    setDataImageFile({
      ...dataImageFile,
      [name]: null
    })
    form.setFieldsValue({
      instructors: form.getFieldValue('instructors').map((teacher: any, index: any) =>
        index === name ? {...teacher, imageTeacher: null} : teacher
      )
    });
  };


  return (
    <>
      <div className="section-instructor">
        <Form form={form} onFinish={onFinish} onValuesChange={handleValueChange}
              initialValues={{
                instructors: dataInstructor
              }}
        >
          <Form.List name="instructors">
            {(fields, {add, remove}) => {
              const selectedTeacherIds = form.getFieldValue('instructors').map((instructor: {
                teacherId: any;
              }) => instructor.teacherId).filter(Boolean);

              return (
                <>
                  {fields.map((field, index) => {
                    const fieldData = form.getFieldValue('instructors')[field.key];
                    const valueFormImageEdit = fieldData ? fieldData.imageTeacher : undefined;
                    const filteredData = filterOptions(selectedTeacherIds, newData);
                    return (

                      <CustomCard title="Người hướng dẫn"
                                  key={field.key}
                                  extra={field.key >= 1 ?
                                    <CloseOutlined onClick={() => handleRemove(field, remove)}/> : null}
                                  className="wrapper-settings--card">
                        <div className='form-setting-instructor'>
                          <section className="instructor" key={index}>
                            <Form.Item label="Trường" rules={[validation.enterprise]}
                                       name={[field.name, 'uuid']}
                                       className="form-item--instructor item-university">
                              <FormItemDropdown
                                placeholder="Chọn trường"
                                disabledOptions={false}
                                options={newDataEnterprise}
                              />
                            </Form.Item>
                            <Form.Item name={[field.name, 'teacherId']} label="Người hướng dẫn"
                                       rules={[validation.instructor]}
                                       className="form-item--instructor">
                              <FormItemDropdown
                                onChange={(value) => handleTeacherIdChange(value, field.name)}
                                placeholder="Chọn người hướng dẫn"
                                options={filteredData}

                              />
                            </Form.Item>
                            <Flex className="attachment-avatar" justify='flex-end' gap={24}>
                              <div className="info-instructor">
                                <Form.Item label="Email" name={[field.name, 'email']} className="form-item--instructor">
                                  <FormItemInput
                                    disabled={true}

                                  />
                                </Form.Item>
                                <Form.Item name={[field.name, 'biography']} label="Tiểu sử"
                                           className="form-item--instructor">
                                  <TextArea
                                    placeholder="Nhập tiểu sử"

                                  />
                                </Form.Item>
                              </div>
                              <div className="info-avatar">
                                <Form.Item name={[field.name, 'imageTeacher']}>
                                  <div className="image-container">
                                    <Upload
                                      listType='picture-card'
                                      className='avatar-uploader'
                                      showUploadList={false}
                                      action={BASE_URL_V2 + '/mooc-course-block-quiz/upload-file'}
                                      headers={{Authorization: 'Bearer ' + accessToken}}
                                      beforeUpload={handleBeforeUpload}
                                      onChange={(info) => handleChange(info, field.name)}
                                    >
                                      {
                                        loadingUpload[field.name] ? (
                                          <div className='upload-content'><LoadingOutlined/></div>
                                        ) : (
                                          valueFormImageEdit || dataImageFile[field.name] ? (
                                            <img className='image-present'
                                                 src={dataImageFile[field.name] ? valueFormImageEdit : valueFormImageEdit}
                                                 style={{width: "100%", height: "100%"}} alt='image'/>
                                          ) : (
                                            <div className='upload-content'><PlusOutlined/><span>Tải lên</span></div>
                                          )
                                        )
                                      }
                                    </Upload>
                                    {(valueFormImageEdit || dataImageFile[field.name]) && <span className="remove-icon">
                                      <a className="upload-action" onClick={(e) => handleRemoveImage(e, field.name)}>
                                        <DeleteOutlined/>
                                      </a>
                                    </span>}
                                  </div>
                                </Form.Item>
                              </div>
                            </Flex>
                          </section>
                        </div>
                      </CustomCard>
                    )
                  })}


                  <div className="add-instructor--info">
                    <Button onClick={addNewTeacher}>
                      <PlusOutlined/>
                      <Typography.Text>
                        Thêm người hướng dẫn
                      </Typography.Text>
                    </Button>
                  </div>
                </>)
            }
            }
          </Form.List>
          <div className="save-instructor--info">
            <div className="group-button">
              <Button
                className="ant-btn-primary"
                htmlType="submit"
              >
                <SaveOutlined/>
                <Typography.Text>Lưu</Typography.Text>
              </Button>
              <Button className="cancel-btn" onClick={onReset}>
                <CloseOutlined/>
                <Typography.Text>Hủy</Typography.Text>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default TabInstructor;
