import { Form, Layout, Space, TableColumnsType, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FilterDataMoocActionHistory } from "../../../../../types/course-plan-managment/mooc-action-history";
import { routesConfig } from "../../../../../config/routes";
import { formatDateToIsoStringHHss } from "../../../../../utils/converDate";
import { TableProps } from "antd/lib";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import { getMoocCourseConstructionPlanHistory } from "../../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";
import {
  getMoocCoursePlanAssignmentHistory,
  getCoursePlanAssignmentHistory,
} from "../../../../../service/course-plan-managment/mooc-course-plan-assignment/mooc-course-plan-assignment-api";

const AssignmentHistory = () => {
  const imgPath = "/images/";
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [listMoocActionHistory, setListMoocActionHistory] = useState();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [filterData, setFilterData] = useState<FilterDataMoocActionHistory>({});
  const { id } = useParams();
  const location = useLocation();
  const state = location.state;

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: (
        <a
          href={`/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`}
        >
          Phân công lập kế hoạch
        </a>
      ),
    },
    {
      title: `Lịch sử thao tác phân công lập kế hoạch xây dựng khóa học`,
    },
  ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: "3%",
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2);
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Người phân công", //dropdown
      dataIndex: "nameAssigner",
      key: "nameAssigner",
      width: "20%",
      // render: (_: any, record, index: number) => {
      //   const userName = `${record.authUser.firstName} ${record.authUser.lastName}`;
      //   return <span className="table-order-header">{userName}</span>;
      // },
    },
    {
      title: "Người được phân công", //dropdown
      dataIndex: "nameAssignee",
      key: "nameAssigner",
      width: "20%",
      // render: (_: any, record, index: number) => {
      //   const userName = `${record.authUser.firstName} ${record.authUser.lastName}`;
      //   return <span className="table-order-header">{userName}</span>;
      // },
    },
    {
      title: "Thời gian", //clendar
      dataIndex: "createdDate",
      key: "createdDate",
      width: "15%",
      render: (_: any, record, index: number) => {
        const dateTimeConvert = record?.createdDate
          ? formatDateToIsoStringHHss(record?.createdDate.toString())
          : null;
        const parts = dateTimeConvert ? dateTimeConvert.split(" ") : [];
        const time = parts.length > 1 ? parts[0] : null;
        const date = parts.length > 1 ? parts[1] : null;
        return (
          <Space direction="vertical">
            <span>{time}</span>
            <span>{date}</span>
          </Space>
        );
      },
    },
    {
      title: "Nội dung phân công", //text
      dataIndex: "actionDetail",
      key: "actionDetail",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span>
            {record?.nameAssigner} đã phân công cho {record?.nameAssignee}{" "}
            {record?.itemId === null
              ? "chủ trì xây dựng kế hoạch"
              : "phụ trách tại mục " + record?.itemName}
          </span>
        );
      },
    },
  ];

  const getListData = (id: number) => {
    setLoading(true);

    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
      courseConstructionPlanId: Number(id),
    };

    getCoursePlanAssignmentHistory(data)
      .then((res) => {
        const { data } = res.data;

        setListMoocActionHistory(data.data);
        //setTotalRecords(res?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  useEffect(() => {
    getListData(Number(id));
  }, [id]);

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-action-history-detail") {
            console.log("valuesForm", values);
          }
        }}
      >
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader
                title={`Lịch sử thao tác phân công lập kế hoạch`}
                listBreadcrumb={listBreadcrumb}
                arrowLeft={imgPath + "arrowLeft.svg"}
                positionItem="column"
                breadcrumb={true}
              />
            </div>
          </div>
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listMoocActionHistory}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default AssignmentHistory;
