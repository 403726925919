import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingAuthority: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/setting-authority/get-all-roles`);
};

export const getSettingAuthorityById: (
    id?: string
  ) => Promise<
    AxiosResponse<any>
  > = (id) => {
    return axiosConfig.get(`/setting-authority/get/${id}`);
  };
  
  export const createSettingAuthority: (
    dataReq: any
  ) => Promise<AxiosResponse<any>> = (dataReq) => {
    return axiosConfig.post(`/setting-authority/create`, dataReq);
  };
  
  export const updateSettingAuthority: (
    id: string,
    dataReq?: any
  ) => Promise<AxiosResponse<any>> = (id, dataReq) => {
    return axiosConfig.put(`/setting-authority/update/${id}`, dataReq);
  };
  export const deleteSettingAuthority: (
    id: string
  ) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.delete(`/setting-authority/delete/${id}`);
  };
  