import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import {
  getClassList,
  getRateClassAction,
  getRateSequenceAction,
  getRateUnitByClass,
  getRateUnitByCourse,
  getRateUnitByModule,
  getRateUnitBySequence,
  getUnitDiscuss,
  getUnitReviewAndAccess,
} from "../../../../../service/statistical-report";
import { staticReportStore } from "../../../../../stores/stores";
import {
  BarStackedChartProps,
  ClassProps,
  DualChart,
} from "../../../../../types/static-report";
import FeedbackAndEvaluationChartTab from "../chart/feedback-and-evaluation-tab/FeedbackAndEvaluationChartTab";
import FeedbackAndEvaluationTableTab from "../table/report-list-tab/feedback-and-evaluationtable-tab/FeedbackAndEvaluationtableTab";
import { debounce } from "lodash";

type Props = {
};

const FeedbackAndEvaluationTab = (props: Props) => {
  const { staticReportType, filterValues, tab } = staticReportStore();
  const [classList, setClassList] = useState<ClassProps[]>([]);

  const [chartData, setChartData] = useState({
    rateUnitByCourse: [] as BarStackedChartProps[],
    rateUnitByModule: [] as BarStackedChartProps[],
    rateUnitBySequence: [] as BarStackedChartProps[],
    unitReviewAndAccess: [] as DualChart[],
    rateSequenceAction: [] as BarStackedChartProps[],
    rateClassAction: [] as BarStackedChartProps[],
    unitDiscuss: [] as BarStackedChartProps[],
    rateUnitByClass: [] as BarStackedChartProps[],
  });

  const [loading, setLoading] = useState({
    rateUnitByCourse: false,
    rateUnitByModule: false,
    rateUnitBySequence: false,
    unitReviewAndAccess: false,
    rateSequenceAction: false,
    rateClassAction: false,
    unitDiscuss: false,
    rateUnitByClass: false,
  });

  const payload = useMemo(
    () => ({
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
    }),
    [filterValues.dateFrom, filterValues.dateTo, filterValues?.courseLevelIds, filterValues?.industryGroupIds, filterValues?.courseIds]
  );

  const fetchData = async () => {
    try {
      const apiCalls = [
        {
          key: "rateUnitByCourse",
          api: getRateUnitByCourse,
          params: payload,
        },
        {
          key: "rateUnitByModule",
          api: getRateUnitByModule,
          params: payload,
        },
        {
          key: "rateUnitBySequence",
          api: getRateUnitBySequence,
          params: payload,
        },
        {
          key: "rateSequenceAction",
          api: getRateSequenceAction,
          params: payload,
        },
        {
          key: "rateClassAction",
          api: getRateClassAction,
          params: payload,
        },
        {
          key: "unitDiscuss",
          api: getUnitDiscuss,
          params: {
            ...payload,
            timeUnit: filterValues.timeUnit,
          },
        },
      ];

      await Promise.allSettled(
        apiCalls.map(async ({ key, api, params }) => {
          setLoading((prev) => ({ ...prev, [key]: true }));
          const response = await api(params);
          const data = response?.data?.data?.statistic || response?.data?.data;
          setChartData((prevState) => ({
            ...prevState,
            [key]: data,
          }));
          setLoading((prev) => ({ ...prev, [key]: false }));
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchRateUnitByClass = async () => {
    setLoading((prev) => ({ ...prev, rateUnitByClass: true }));
    try {
      const data = await getRateUnitByClass({
        ...payload,
        classId: filterValues?.classId,
      });
      setChartData((prevState) => ({
        ...prevState,
        rateUnitByClass: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching rateUnitByClass data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, rateUnitByClass: false }));
    }
  };

  const fetchUnitReviewAndAccess = async () => {
    setLoading((prev) => ({ ...prev, unitReviewAndAccess: true }));
    try {
      const data = await getUnitReviewAndAccess({
        ...payload,
        timeUnit: filterValues.timeUnit,
        moduleGroup: filterValues?.moduleGroup,
      });
      setChartData((prevState) => ({
        ...prevState,
        unitReviewAndAccess:
          data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching unitReviewAndAccess data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, unitReviewAndAccess: false }));
    }
  };

  const { classId, courseStructureType, moduleGroup, ...otherFilterValues } =
    filterValues;

  const otherFilterValuesString = JSON.stringify(otherFilterValues);


  const debouncedFetchData = useMemo(() => debounce(fetchData, 300), [filterValues]);
  const debouncedFetchRateUnitByClass = useMemo(() => debounce(fetchRateUnitByClass, 300), [filterValues, filterValues.classId]);
  const debouncedFetchUnitReviewAndAccess = useMemo(() => debounce(fetchUnitReviewAndAccess, 300), [filterValues]);

  useEffect(() => {
    if (tab === "2") {
      debouncedFetchData();
    }
  }, [otherFilterValuesString, tab]);

  useEffect(() => {
    if (tab === "2") {
      debouncedFetchRateUnitByClass();
    }
  }, [tab, filterValues.classId]);

  useEffect(() => {
    if (tab === "2") {
      debouncedFetchUnitReviewAndAccess();
    }
  }, [tab, filterValues]);

  useEffect(() => {
    const fetchClass = async () => {
      const res = await getClassList();
      setClassList(
        res?.data.data?.map((it: any) => ({
          label: it.name,
          value: it.id,
        }))
      );
    };
    if (tab === "2") {
      fetchClass();
    }
  }, [tab]);

  const memoizedRateUnitByCourse = useMemo(
    () => chartData.rateUnitByCourse,
    [chartData.rateUnitByCourse]
  );
  const memoizedRateUnitByModule = useMemo(
    () => chartData.rateUnitByModule,
    [chartData.rateUnitByModule]
  );
  const memoizedRateUnitBySequence = useMemo(
    () => chartData.rateUnitBySequence,
    [chartData.rateUnitBySequence]
  );
  const memoizedUnitReviewAndAccess = useMemo(
    () => chartData.unitReviewAndAccess,
    [chartData.unitReviewAndAccess]
  );
  const memoizedRateSequenceAction = useMemo(
    () => chartData.rateSequenceAction,
    [chartData.rateSequenceAction]
  );
  const memoizedRateClassAction = useMemo(
    () => chartData.rateClassAction,
    [chartData.rateClassAction]
  );
  const memoizedUnitDiscuss = useMemo(
    () => chartData.unitDiscuss,
    [chartData.unitDiscuss]
  );
  const memoizedRateUnitByClass = useMemo(
    () => chartData.rateUnitByClass,
    [chartData.rateUnitByClass]
  );

  return (
    <div>
      {staticReportType === "chart" ? (
        <FeedbackAndEvaluationChartTab
          loading={loading}
          rateUnitByCourse={memoizedRateUnitByCourse}
          rateUnitByModule={memoizedRateUnitByModule}
          rateUnitBySequence={memoizedRateUnitBySequence}
          unitReviewAndAccess={memoizedUnitReviewAndAccess}
          rateSequenceAction={memoizedRateSequenceAction}
          rateClassAction={memoizedRateClassAction}
          unitDiscuss={memoizedUnitDiscuss}
          rateUnitByClass={memoizedRateUnitByClass}
          classList={classList}
        />
      ) : (
        <FeedbackAndEvaluationTableTab
          loading={loading}
          rateUnitByCourse={memoizedRateUnitByCourse}
          rateUnitByModule={memoizedRateUnitByModule}
          rateUnitBySequence={memoizedRateUnitBySequence}
          unitReviewAndAccess={memoizedUnitReviewAndAccess}
          rateSequenceAction={memoizedRateSequenceAction}
          rateClassAction={memoizedRateClassAction}
          unitDiscuss={memoizedUnitDiscuss}
          rateUnitByClass={memoizedRateUnitByClass}
          classList={classList}
        />
      )}
    </div>
  );
};

export default FeedbackAndEvaluationTab;
