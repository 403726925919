import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Switch,
  TimePickerProps,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import TimePickerCustom from "../../components/timepicker";
import FormInputCommon from "../../components/form-input-common/FormInputCommon";
import { useParams } from "react-router-dom";
import {
  getSettingQuality,
  updateSettingQuality,
} from "../../service/setting-quality";
const { Title, Text } = Typography;

const ControlQualityLessonContent = () => {
  const params = useParams();

  const [messageApi, contextHolder] = message.useMessage();
  const [isEditMinTime, setIsEditMinTime] = useState(false);
  const [isEditMaxTime, setIsEditMaxTime] = useState(false);
  const [isEditNumberStudent, setIsEditNumberStudent] = useState(false);

  const [paramLessonVideo, setParamLessonVideo] = useState<any>({
    is_active: false,
    minTime: "00:00:00",
    id: null,
    maxTime: "00:00:00",
    student: 0,
  });
  const fetchData = async () => {
    try {
      const response = await getSettingQuality(params?.id || "");
      const { data } = response.data;
      setParamLessonVideo({
        id: data?.id,
        is_active: data?.is_active,
        minTime: data?.min_time || "00:00:00",
        maxTime: data?.max_time || "00:00:00",
        student: data?.min_student || 0,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const onChangeActive = async (checked: boolean) => {
    try {
      await updateSettingQuality(paramLessonVideo?.id || "", {
        // max_time: paramLessonVideo?.maxTime,
        is_active: checked,
        // min_time: paramLessonVideo?.minTime,
        // min_student: paramLessonVideo?.student,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
      setParamLessonVideo({
        ...paramLessonVideo,
        is_active: checked,
      });
    } catch (error: any) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };
  const handleChangeStudentNumber = (value: string | number) => {
    if (!/^\d*$/.test(value as string)) {
      return;
    }
    if (Number(value) >= 0) {
      setParamLessonVideo({
        ...paramLessonVideo,
        student: value,
      });
    }
  };
  const handleChangeMinTime: TimePickerProps["onChange"] = (
    time,
    timeString
  ) => {
    setParamLessonVideo({
      ...paramLessonVideo,
      minTime: timeString,
    });
  };
  const handleChangeMaxTime: TimePickerProps["onChange"] = (
    time,
    timeString
  ) => {
    setParamLessonVideo({
      ...paramLessonVideo,
      maxTime: timeString,
    });
  };
  const handleSubmit = async (name: string) => {
    const paramExamSupervision = {
      is_active: true,
      minTime: paramLessonVideo?.minTime,
      maxTime: paramLessonVideo?.maxTime,
      student: paramLessonVideo?.student,
    };

    if (name === "minTime") {
      setIsEditMinTime(false);
    } else if (name === "maxTime") {
      setIsEditMaxTime(false);
    } else {
      setIsEditNumberStudent(false);
    }
    try {
      await updateSettingQuality(paramLessonVideo?.id || "", {
        max_time: paramLessonVideo?.maxTime,
        min_time: paramLessonVideo?.minTime,
        min_student: Number(paramLessonVideo?.student),
      });
      messageApi.open({
        type: "success",
        content: "Lưu thông tin thành công",
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {contextHolder}
      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Cài đặt tiêu chí đánh giá chất lượng
            </Title>
            <Text className="text-[#00000073]">
              Thiết lập tiêu chí đánh giá chất lượng buổi học video
            </Text>
          </div>
        </Flex>
        <Flex className="py-4">
          <span className="!font-semibold pr-8">Trạng thái</span>
          <div className="px-4">
            <Switch
              defaultChecked
              onChange={(checked) => onChangeActive(checked)}
              checked={paramLessonVideo?.is_active}
            />
            <Text className="px-4">
              {paramLessonVideo?.is_active
                ? "Đang hoạt động"
                : "Không hoạt động"}
            </Text>
          </div>
        </Flex>
      </Card>
      {paramLessonVideo?.is_active && (
        <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
          <Flex
            justify="space-between"
            align="center"
            className="border-b py-3"
          >
            <Flex align="center">
              <Title level={5} className="!m-0 pr-3">
                Tổng thời gian tối thiểu của 01 video buổi học
              </Title>
              {isEditMinTime ? (
                <TimePickerCustom
                  onChange={handleChangeMinTime}
                  value={
                    paramLessonVideo.minTime === "" || !paramLessonVideo.minTime
                      ? "00:00:00"
                      : paramLessonVideo.minTime
                  }
                  format="HH:mm:ss"
                  disabled={!isEditMinTime}
                />
              ) : (
                <Text className="leading-[9px]">
                  {paramLessonVideo?.minTime === ""
                    ? "00:00:00"
                    : paramLessonVideo?.minTime}
                </Text>
              )}
            </Flex>
            {isEditMinTime ? (
              <Flex>
                <Button
                  type="primary"
                  onClick={() => handleSubmit("minTime")}
                  className="ml-2"
                >
                  Lưu
                </Button>
                <Button
                  onClick={() => setIsEditMinTime(false)}
                  className="ml-2"
                >
                  Hủy
                </Button>
              </Flex>
            ) : (
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => setIsEditMinTime(true)}
              >
                <EditOutlined />
              </Button>
            )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            className="border-b py-3"
          >
            <Flex align="center">
              <Title level={5} className="!m-0 pr-3">
                Tổng thời gian tối đa của 01 video buổi học
              </Title>
              {isEditMaxTime ? (
                <TimePickerCustom
                  onChange={handleChangeMaxTime}
                  value={
                    paramLessonVideo.maxTime === "" || !paramLessonVideo.maxTime
                      ? "00:00:00"
                      : paramLessonVideo.maxTime
                  }
                  format="HH:mm:ss"
                  disabled={!isEditMaxTime}
                />
              ) : (
                <Text className="leading-[9px]">
                  {paramLessonVideo?.maxTime === ""
                    ? "00:00:00"
                    : paramLessonVideo?.maxTime}
                </Text>
              )}
            </Flex>
            {isEditMaxTime ? (
              <Flex>
                <Button
                  type="primary"
                  onClick={() => handleSubmit("maxTime")}
                  className="ml-2"
                >
                  Lưu
                </Button>
                <Button
                  onClick={() => setIsEditMaxTime(false)}
                  className="ml-2"
                >
                  Hủy
                </Button>
              </Flex>
            ) : (
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => setIsEditMaxTime(true)}
              >
                <EditOutlined />
              </Button>
            )}
          </Flex>
          <Flex
            justify="space-between"
            align="center"
            className="border-b py-3"
          >
            <Flex align="center">
              <Title level={5} className="!m-0 pr-3">
                Số sinh viên tối thiểu của 01 video buổi học
              </Title>
              {isEditNumberStudent ? (
                <FormInputCommon
                  type="number"
                  min={0}
                  max={1000}
                  placeholder="Nhập thời điểm nhắc nhở"
                  value={paramLessonVideo?.student}
                  onChange={(e) => handleChangeStudentNumber(e?.target?.value)}
                />
              ) : (
                <Text className="leading-[9px]">
                  {paramLessonVideo?.student === ""
                    ? 0
                    : paramLessonVideo?.student}
                </Text>
              )}
            </Flex>
            {isEditNumberStudent ? (
              <Flex>
                <Button
                  type="primary"
                  onClick={() => handleSubmit("numberStudent")}
                  className="ml-2"
                >
                  Lưu
                </Button>
                <Button
                  onClick={() => setIsEditNumberStudent(false)}
                  className="ml-2"
                >
                  Hủy
                </Button>
              </Flex>
            ) : (
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => setIsEditNumberStudent(true)}
              >
                <EditOutlined />
              </Button>
            )}
          </Flex>
        </Card>
      )}
    </div>
  );
};

export default ControlQualityLessonContent;
