// OverviewChartTab
export const comboDualAxesChartData = [
  {
    criteria: "10/8",
    columnValue: 2800,
    lineValue: 500,
  },
  {
    criteria: "11/8",
    columnValue: 1200,
    lineValue: 2500,
  },
  {
    criteria: "12/8",
    columnValue: 2100,
    lineValue: 3000,
  },
  {
    criteria: "13/8",
    columnValue: 3700,
    lineValue: 2900,
  },
  {
    criteria: "14/8",
    columnValue: 2050,
    lineValue: 2800,
  },
  {
    criteria: "15/8",
    columnValue: 3500,
    lineValue: 4500,
  },
];

export const barChartData = [
  {
    type: 1,
    criteria: "Vãng lai",
    values: [
      {
        count: 40,
        percentage: 40,
        criteria: `Số lượt (567)`,
      },
      {
        count: 36,
        percentage: 36,
        criteria: `Số người (567)`,
      },
    ],
  },
  {
    type: 2,
    criteria: "Người dùng tự do",
    values: [
      {
        count: 34,
        percentage: 34,
        criteria: `Số lượt (567)`,
      },
      {
        count: 33,
        percentage: 33,
        criteria: `Số người (567)`,
      },
    ],
  },
  {
    type: 3,
    criteria: "Người dùng đăng ký",
    values: [
      {
        count: 31,
        percentage: 31,
        criteria: `Số lượt (567)`,
      },
      {
        count: 28,
        percentage: 28,
        criteria: `Số người (567)`,
      },
    ],
  },
];

export const barChartCountData = [
  {
    type: 1,
    criteria: "Hà Nội",
    count: 40,
    percentage: 30,
  },
  {
    type: 2,
    criteria: "Tp. HCM",
    count: 36,
    percentage: 50,
  },
  {
    type: 3,
    criteria: "Thái Nguyên",
    count: 34,
    percentage: 70,
  },
  {
    type: 4,
    criteria: "Hà Nam",
    count: 33,
    percentage: 70,
  },
  {
    type: 5,
    criteria: "Hà Tĩnh",
    count: 31,
    percentage: 70,
  },
  {
    type: 6,
    criteria: "HHH",
    count: 28,
    percentage: 70,
  },
];

export const barStackedChartLecturerData = [
  {
    type: 1,
    criteria: "Đại học Bách Khoa",
    values: [
      {
        count: 15,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 15,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 15,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
  {
    type: 2,
    criteria: "Đại học KTQD",
    values: [
      {
        count: 15,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 8,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 15,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
  {
    type: 3,
    criteria: "Đại học HHH",
    values: [
      {
        count: 14,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 12,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 8,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
  {
    type: 4,
    criteria: "Đại học Luật",
    values: [
      {
        count: 11,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 11,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 10,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
  {
    type: 5,
    criteria: "Đại học Thương Mại",
    values: [
      {
        count: 14,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 7,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 7,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
  {
    type: 6,
    criteria: "Đại học Ngoại Thương",
    values: [
      {
        count: 17,
        percentage: 40,
        criteria: `Trên đại học`,
      },
      {
        count: 4,
        percentage: 36,
        criteria: `Đại học`,
      },
      {
        count: 4,
        percentage: 36,
        criteria: `Cao đẳng`,
      },
    ],
  },
];

export const barStackedChartStudentData = [
  {
    type: 1,
    criteria: "Đại học Bách Khoa",
    values: [
      {
        count: 24,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 21,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
  {
    type: 2,
    criteria: "Đại học KTQD",
    values: [
      {
        count: 12,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 26,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
  {
    type: 3,
    criteria: "Đại học HHH",
    values: [
      {
        count: 17,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 17,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
  {
    type: 4,
    criteria: "Đại học Luật",
    values: [
      {
        count: 17,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 15,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
  {
    type: 5,
    criteria: "Đại học Thương Mại",
    values: [
      {
        count: 14,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 14,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
  {
    type: 6,
    criteria: "Đại học Ngoại Thương",
    values: [
      {
        count: 12,
        percentage: 36,
        criteria: `Học viên đăng ký mới`,
      },
      {
        count: 13,
        percentage: 36,
        criteria: `Học viên cũ`,
      },
    ],
  },
];

export const barStackedChartUniversityData = [
  {
    type: 1,
    criteria: "Đại học Bách Khoa",
    values: [
      {
        count: 24,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 21,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
  {
    type: 2,
    criteria: "Đại học KTQD",
    values: [
      {
        count: 12,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 26,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
  {
    type: 3,
    criteria: "Đại học HHH",
    values: [
      {
        count: 17,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 17,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
  {
    type: 4,
    criteria: "Đại học Luật",
    values: [
      {
        count: 17,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 15,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
  {
    type: 5,
    criteria: "Đại học Thương Mại",
    values: [
      {
        count: 14,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 14,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
  {
    type: 6,
    criteria: "Đại học Ngoại Thương",
    values: [
      {
        count: 12,
        percentage: 36,
        criteria: `Phối hợp`,
      },
      {
        count: 13,
        percentage: 36,
        criteria: `Chủ trì`,
      },
    ],
  },
];

export const donutChartData = [
  {
    type: 1,
    criteria: "Đa phương tiện",
    count: 40,
    percentage: 40,
  },
  {
    type: 2,
    criteria: "Bài kiểm tra",
    count: 30,
    percentage: 30,
  },
  {
    type: 3,
    criteria: "Scorm & xAPI",
    count: 10,
    percentage: 10,
  },
  {
    type: 4,
    criteria: "Tài liệu tham khảo",
    count: 20,
    percentage: 20,
  },
];

// ResourceChartTab
export const barStackedChartData = [
    {
      type: 1,
      criteria: "Đại học Bách Khoa",
      values: [
        {
          count: 5,
          percentage: 5,
          criteria: 'Thẻ ghi danh'
        },
        {
          count: 10,
          percentage: 10,
          criteria: 'Thẻ ghi danh'
        },
        {
          count: 15,
          percentage: 15,
          criteria: 'Thẻ ghi danh'
        }
      ]
    ,
    },
    {
      type: 2,
      criteria: "Đại học kinh tế quốc dân",
      values: [
        {
          count: 10,
          percentage: 10,
          criteria: 'Tự do'
        },
        {
          count: 20,
          percentage: 20,
          criteria: 'Tự do'
        },
        {
          count: 10,
          percentage: 10,
          criteria: 'Tự do'
        }
      ]
    },
    {
      type: 3,
      criteria: "Đại học Luật",
      values: [
        {
          count: 15,
          percentage: 20,
          criteria: 'Riêng tư'
        },
        {
          count: 20,
          percentage: 25,
          criteria: 'Riêng tư'
        },
        {
          count: 10,
          percentage: 30,
          criteria: 'Riêng tư'
        }
      ]
    },
  ];

  export const barChartNumberData = [
    {
      type: 1,
      criteria: "Khoa H1",
      count: 40,
      percentage: 20,
    },
    {
      type: 2,
      criteria: "Khoa H2",
      count: 36,
      percentage: 20,
    },
    {
      type: 3,
      criteria: "Khoa H3",
      count: 34,
      percentage: 20,
    },
    {
      type: 4,
      criteria: "Khoa H4",
      count: 33,
      percentage: 20,
    },
    {
      type: 5,
      criteria: "Khoa H5",
      count: 31,
      percentage: 20,
    },
    {
      type: 6,
      criteria: "Khoa H6",
      count: 28,
      percentage: 20,
    }
  ]

  export const donutChartTestData = [
    {
      type: 1,
      criteria: "Bài tập",
      count: 40,
      percentage: 40,
    },
    {
      type: 2,
      criteria: "Bài kiểm tra",
      count: 40,
      percentage: 40,
    },
    {
      type: 3,
      criteria: "Bài thi",
      count: 20,
      percentage: 20,
    },
  ]

  export const donutChartReferencesData = [
    {
      type: 1,
      criteria: "PDF",
      count: 40,
      percentage: 40,
    },
    {
      type: 2,
      criteria: "Video",
      count: 40,
      percentage: 40,
    },
    {
      type: 3,
      criteria: "MP3",
      count: 20,
      percentage: 20,
    },
  ]

  export const donutChartScormData = [
    {
      type: 1,
      criteria: "xAPI",
      count: 40,
      percentage: 40,
    },
    {
      type: 3,
      criteria: "Scorm",
      count: 60,
      percentage: 60,
    },
  ]

  export const donutChartMultimediaData = [
    {
      type: 1,
      criteria: "Công khai",
      count: 30,
      percentage: 30,
    },
    {
      type: 2,
      criteria: "Bản nháp",
      count: 40,
      percentage: 40,
    },
    {
      type: 3,
      criteria: "Riêng tư",
      count: 30,
      percentage: 30,
    },
  ]
