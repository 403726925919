import { Modal } from 'antd'
import React, { ReactNode } from 'react'
import "./common.scss"
import { isEmpty } from 'lodash'

type Props = {
  open: boolean
  closeIcon: boolean
  title: string
  className?: string
  width?: string
  desc: any
  okText: string
  cancelText: string
  closeModal: (value: boolean) => void
  confirm: () => void
  icon: ReactNode
}

export default function CommonModal(props: Props) {
  const { open, title, closeIcon, okText, cancelText, closeModal, icon, confirm, desc, width, className } = props

  const onOk = () => {
    confirm()
  }

  return (
    <Modal
      className={!isEmpty(className) ? className : 'modal-common'}
      title={<>{icon} <span>{title}</span></>}
      style={{ width: `${width}` }}
      closeIcon={closeIcon}
      centered
      open={open}
      okText={okText}
      cancelText={cancelText}
      onOk={onOk}
      onCancel={() => closeModal(false)}
    >
      <div className='description'>
        {desc}
      </div>
    </Modal>
  )
}
