import {
  Breadcrumb,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeftOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/lib";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import { getCourseBehaviorAfter } from "../../../../../service/prediction-model";
import {
  getAllTagSystemSuggestCourse
} from "../../../../../service/system-suggest-course";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";

const StudentAfterJoin = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [documentSystem, setDocumentSystem] = useState<any[]>([]);
  const [dataFilter, setDataFilter] = useState({
    name: "",
    tags: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionSelectTag, setOptionSelectTag] = useState([]);

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getCourseBehaviorAfter(searchParam);
      const { data } = response.data;
      setDocumentSystem(data?.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListTag = async () => {
    try {
      const response = await getAllTagSystemSuggestCourse();
      const { data } = response.data;
      setOptionSelectTag(
        data?.data?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      name: searchValue,
      // tags: [],
      report_id: Number(params?.id),
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
    fetchListTag();
  }, [pageSize, pageNumber]);

  const dataColumnsStudent: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      width: 15,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    {
      title: "Hoàn thành khoá học",
      dataIndex: "join",
      key: "join",
      render: (_: any, record: any) => {
        return <div>200</div>;
      },
      width: 10,
    },
    {
      title: "Được cấp chứng chỉ",
      dataIndex: "pass",
      key: "pass",
      render: (_: any, record: any) => {
        return <div>122</div>;
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumnsStudent.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsStudent.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        ...dataFilter,
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      ...dataFilter,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const clearFilter = async (formInstance: FormInstance) => {
    setLoading(true);
    setDataFilter({
      name: "",
      tags: [],
    });
    const searchParam = {
      tags: [],
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      name: searchValue,
    };
    fetchData(searchParam);
    formInstance.resetFields();
  };

  const handleFinish = (values: any) => {
    setLoading(true);
    console.log({ values });

    const searchParam = {
      ...values,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      name: searchValue,
    };
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <Breadcrumb
            className="w-full"
            items={[
              {
                title: "Hệ thống gợi ý khoá học",
              },
              {
                title: "Cài đặt Dự đoán, phân tích xu hướng",
              },
              {
                title: "Phân tích nội dung đầu vào sinh viên 2024 - 2025",
              },
              {
                title: "Thông tin hồ sơ sinh viên",
              },
            ]}
          />
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <ArrowLeftOutlined
                className="arrowleft-icon mr-2"
                onClick={() => navigate(-1)}
              />
              <PageHeader
                title={
                  "Số lượng sinh viên thực hiện hành vi sau khi tham gia khoá học"
                }
              />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsStudent}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
            optionSelectTag={optionSelectTag}
            setDataFilter={setDataFilter}
          />
        </div>
        <TableData
          dataTable={documentSystem}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default StudentAfterJoin;
