// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-lesson .wrapper {
  padding: 0px 0px;
  height: auto; }

.add-lesson .setting-lesson-course .setting-lesson {
  padding: 20px 20px; }

.add-lesson .setting-lesson-course .ant-card {
  width: 100%; }

.add-lesson-modal {
  width: 50% !important; }
  .add-lesson-modal .ant-modal-content {
    width: 100%; }
    .add-lesson-modal .ant-modal-content .ant-modal-header {
      border-bottom: 1px solid #F0F0F0;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .add-lesson-modal .ant-modal-content .ant-modal-footer {
      border-top: 1px solid #F0F0F0;
      padding-top: 15px; }

.multiple-quizes {
  font-size: 14px; }

.modal-warning--submit_lesson .ant-modal-confirm-btns {
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/content-construction/add-survy/add-survy.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB;EAChB,YAAY,EAAA;;AAHhB;EAQM,kBAAkB,EAAA;;AARxB;EAYM,WAAW,EAAA;;AAKjB;EACE,qBAAqB,EAAA;EADvB;IAII,WAAW,EAAA;IAJf;MAOM,gCAAgC;MAChC,oBAAoB;MACpB,mBAAmB,EAAA;IATzB;MAiBM,6BAA6B;MAC7B,iBAAiB,EAAA;;AAKvB;EACE,eAAe,EAAA;;AAGjB;EAEI,uBAAuB,EAAA","sourcesContent":[".add-lesson {\n  .wrapper {\n    padding: 0px 0px;\n    height: auto;\n  }\n\n  .setting-lesson-course {\n    .setting-lesson {\n      padding: 20px 20px;\n    }\n\n    .ant-card {\n      width: 100%;\n    }\n  }\n}\n\n.add-lesson-modal {\n  width: 50% !important;\n\n  .ant-modal-content {\n    width: 100%;\n\n    .ant-modal-header {\n      border-bottom: 1px solid #F0F0F0;\n      padding-bottom: 15px;\n      margin-bottom: 15px;\n    }\n\n    // .ant-modal-body {\n\n    // }\n\n    .ant-modal-footer {\n      border-top: 1px solid #F0F0F0;\n      padding-top: 15px;\n    }\n  }\n}\n\n.multiple-quizes {\n  font-size: 14px;\n}\n\n.modal-warning--submit_lesson {\n  .ant-modal-confirm-btns {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
