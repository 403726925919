import {
  Checkbox,
  Flex,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import CommonModal from "../../../../../../../components/modal/common";
import SelectUiCard from "../../../../../../../components/select-ui";
import { DEFAULT_GROUP } from "../../../../../../../constants/setting-identity";
import {
  getListUserAttendance,
  shareReportDocumentAttendance,
} from "../../../../../../../service/setting-attendance";
import { getSettingAuthority } from "../../../../../../../service/setting-authority";
const { Title, Text } = Typography;

interface IProps {
  isOpenConfirmShare: boolean;
  onCloseModal: () => void;
  handleCloseModalShare: () => void;
  handleNotice: (mess: string) => void;
  idReport: string;
  usersShare: any;
  setUsersShare: any;
  title: string;
}
const ReportCheckInShare = ({
  onCloseModal,
  isOpenConfirmShare,
  handleCloseModalShare,
  handleNotice,
  idReport,
  usersShare,
  title,
  setUsersShare,
}: IProps) => {
  const [valueShare, setValueShare] = useState<string>("email");

  const [permissionShare, setPermissionShare] = useState({
    is_view: true,
    is_edit: false,
    is_remove: false,
  });

  const [optionSelectDoc, setOptionSelectDoc] = useState([]);
  const [optionSelectUser, setOptionSelectUser] = useState([]);

  const onChangeShare = (e: RadioChangeEvent) => {
    setValueShare(e.target.value);
  };
  const handleConfirmShare = async () => {
    const paramShare = {
      is_view: permissionShare?.is_view,
      is_edit: permissionShare?.is_edit,
      is_remove: permissionShare?.is_remove,
      notification: valueShare,
      report_id: idReport,
      permission_ids: usersShare?.permission_ids,
      user_ids: usersShare?.user_ids,
    };
    try {
      await shareReportDocumentAttendance(paramShare);
      handleNotice("Chia sẻ báo cáo thành công");
      onCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeShareReport = (val: string, name: string) => {
    setUsersShare({
      ...usersShare,
      [name]: val,
    });
  };
  const handleChangeRole = (e: any) => {
    setPermissionShare({
      ...permissionShare,
      [e.target.name]: e.target.checked,
    });
  };
  const fetchDataUser = async () => {
    try {
      const res = await getListUserAttendance({
        skip: 0,
        take: 10,
      });
      const { data } = res.data;
      setOptionSelectUser(
        data?.data?.map((option: any) => ({
          label: option?.auth_userprofile?.name,
          value: option.id,
          desc: option?.email
        }))
      );
    } catch (error) {}
  };

  const fetchSettingAuthority = async () => {
    try {
      const response = await getSettingAuthority();
      const { data } = response.data;
      setOptionSelectDoc(
        data?.map((option: any) => ({
          label: DEFAULT_GROUP[option?.codename],
          value: option.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
    fetchDataUser();
  }, []);

  return (
    <div>
      <Title level={5} className="w-1/4 !mb-0">
        Nhóm người dùng <span className="text-[#FF3141]">*</span>
      </Title>
      <SelectUiCard
        className="font-medium"
        name="permission_ids"
        filterOption={(input: any, option: any) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        label=""
        options={optionSelectDoc}
        mode="multiple"
        required
        allowClear
        placeholder="Nhập tên nhóm người dùng"
        onChange={(val) => onChangeShareReport(val, "permission_ids")}
      />

      <Title level={5} className="w-1/4 !mb-0">
        Người dùng <span className="text-[#FF3141]">*</span>
      </Title>
      <SelectUiCard
        className="font-medium"
        name="user_ids"
        label=""
        filterOption={(input: any, option: any) =>
          (option?.label.toLowerCase() + option?.desc.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        options={optionSelectUser}
        mode="multiple"
        required
        placeholder="Nhập tên hoặc email người dùng"
        allowClear
        onChange={(val) => onChangeShareReport(val, "user_ids")}
        optionRender={(option: any) => (
          <Space>
            <span role="img" aria-label={option.data.label}>
              {option.data.label}
            </span>
            {option.data.desc}
          </Space>
        )}
      />

      <Flex className="pt-6">
        <Title level={5} className="w-1/4">
          Quyền chia sẻ:
        </Title>
        <Flex>
          <Checkbox
            name="is_view"
            disabled
            checked
            className="flex items-center pr-2"
          >
            Xem
          </Checkbox>
          <Checkbox
            name="is_edit"
            onChange={(e) => handleChangeRole(e)}
            className="flex items-center pr-2"
          >
            Sửa
          </Checkbox>
          <Checkbox
            name="is_remove"
            onChange={(e) => handleChangeRole(e)}
            className="flex items-center"
          >
            Xóa
          </Checkbox>
        </Flex>
      </Flex>
      <Flex>
        <Title level={5} className="w-1/4">
          Chọn gửi báo cáo qua:
        </Title>
        <Radio.Group onChange={onChangeShare} value={valueShare}>
          <Radio value={"email"}>Email</Radio>
          <Radio value={"system"}>Hệ thống Mooc</Radio>
        </Radio.Group>
      </Flex>
      <CommonModal
        open={isOpenConfirmShare}
        title={`Chia sẻ báo cáo ${title}`}
        desc={
          <div>
            Đảm bảo rằng tất cả các{" "}
            <span className="text-[#FAAD14]">
              thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi Xác nhận chia sẻ báo cáo này
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmShare}
        closeModal={handleCloseModalShare}
      />
    </div>
  );
};

export default ReportCheckInShare;
