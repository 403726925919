import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Checkbox, Form, Radio, Select, Typography } from "antd";
import { DetailContent } from "../detail-content/DetailContent";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import {
  CheckSquareFilled,
  MinusCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import "./DetailInfoTab.scss";
import { useAppStore } from "../../../../../stores/stores";
import { getAccountDetail } from "../../../../../service/list-account-users/listAccountUsersApi";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../../config/routes";
import { DataType } from "../../../../../types/listUsers";
type DetailInfoTabProps = {
  detailData: any;
  memberList: any;
};
const DetailInfoTab: FunctionComponent<DetailInfoTabProps> = ({
  detailData,
  memberList,
}) => {
  const navigate = useNavigate();
  const [listHtmlData, setListHtmlData] = useState<any[]>();
  useEffect(() => {
    const htmlData = memberList.map((item: any, index: any) => (
      <div
        className="course-plan-managment-detail-info-speialized"
        style={{ marginBottom: 20, padding: 10, backgroundColor: "#FAFAFA" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ fontSize: "15px", fontWeight: 5 }}>Thành viên</div>
          <div>
            <Button
              style={{ width: 80, height: 25 }}
              onClick={() => {
                navigate(
                  `/${routesConfig.coursePlan}/${routesConfig.specializedGroupList}/${routesConfig.detailInfoMemberSpecializedGroup}/${item.user_id}`,
                  {
                    state: detailData,
                  }
                );
              }}
            >
              Xem chi tiết
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
          }}
        >
          <Form.Item style={{ width: "40%" }}>
            <Select
              showSearch
              optionFilterProp="children"
              defaultValue={item.name}
              options={memberList.map(
                (member: { user_id: any; name: any }) => ({
                  value: member.user_id,
                  label: member.name,
                })
              )}
              placeholder="Chọn thành viên"
              disabled
            />
          </Form.Item>
          <div style={{ marginLeft: 30 }}>
            <Form.Item label="">
              <Checkbox.Group
                defaultValue={[item.isLeader]}
                disabled
                style={{ width: "100%" }}
              >
                <Checkbox value={1}>Tổ trưởng</Checkbox>
                <Checkbox value={0} style={{ marginLeft: 10 }}>
                  Thành viên
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </div>
        </div>
      </div>
    ));
    setListHtmlData(htmlData);
  }, [memberList]);
  return (
    <DetailContent>
      <CustomCard
        className="card-info--wrapper"
        title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              {detailData.organizationCode} - {detailData.organizationName}
            </Typography.Title>
            <Typography.Text
              className="sub-title"
              style={{ width: "90%", wordWrap: "break-word" }}
            >
              {detailData.organizationRemark}
            </Typography.Text>
          </div>
        }
      ></CustomCard>

      <CustomCard
        className="card-info--wrapper"
        title={
          <div className="card-heading--info">
            <Typography.Title level={4} style={{ fontSize: "17px" }}>
              Thành viên tổ chuyên môn
            </Typography.Title>
          </div>
        }
      >
        <Typography.Text style={{ fontSize: "17px" }}></Typography.Text>
        {listHtmlData}
      </CustomCard>
    </DetailContent>
  );
};

export default DetailInfoTab;
