import { Col, Form, Modal, Row, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { get } from "lodash";
import { getApprover, sendApproval } from "../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  handleCancel: () => void;
  browseData: any;
  isNavigation?: boolean | false;
};

export default function ApproveSurvey(props: Props) {
  const { open, handleCancel, browseData, isNavigation } = props;
  const navigation = useNavigate();
  const userId = useAuthStore((states) => states.user?.id);
  const [listApprover, setListApprover] = useState<[]>([]);
  const [approvalLevelName, setApprovalLevelName] = useState<string>("");
  const [isOrganization, setIsOrganization] = useState<boolean | null>(null);
  const [configurationId, setConfigurationId] = useState<number | null>(null);

  const [form] = Form.useForm();
  const helpService = new HelpService();

  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const data = {
          surveyId: browseData?.id,
          submitterId: userId,
          configurationId: configurationId,
          surveyStatus: 1,
          organizationId: null,
          individualId: null
        };
        if (isOrganization) {
          data.organizationId = values.individual_id;
        } else {
          data.individualId = values.individual_id;
        }
        sendApproval(data)
          .then((res) => {
            const { data, statusCode, message } = res.data;
            if (statusCode === 200) {
              helpService.successMessage(get(data, "message", message));
              if(isNavigation){
                navigation(`/survey-training/list-survey`)
              }
            }
            form.resetFields();
            handleCancel();
          })
          .catch((error) => {
            form.resetFields();
          });
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      getListApprover();
    }
  }, [open]);

  const getListApprover = async () => {
    try {
      const data = {
        surveyId: browseData?.id,
        approvalType: 1,
      };
      const response = await getApprover(data).then((res) => {
        const { result, configurationId, approvalLevelName, isOrganization } =
          res.data.data;
        setListApprover(result);
        setApprovalLevelName(approvalLevelName);
        setIsOrganization(isOrganization);
        setConfigurationId(configurationId);
      });
    } catch (error) { }
  };
  const validations = {
    organization_member_id: {
      required: true,
      message: `Vui lòng chọn tên ${isOrganization ? "tổ chức" : "cán bộ"
        } duyệt khảo sát`,
    },
  };

  return (
    <>
      <Modal
        title={`Phê duyệt khảo sát ${browseData?.surveyName}`}
        open={open}
        onOk={onOk}
        onCancel={handleCancel}
        centered
        okText="Gửi yêu cầu"
        cancelText="Huỷ"
      >
        <Form
          layout="vertical"
          form={form}
          name="approveModal"
          initialValues={{
            individual_id: null,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="individual_id"
                label={`Khảo sát của bạn sẽ được gửi tới ${approvalLevelName}:`}
                rules={[validations.organization_member_id]}
                hasFeedback
              >
                <TreeSelect
                  showSearch
                  placeholder={`Nhập tên ${isOrganization ? "tổ chức" : "cán bộ"
                    } duyệt khảo sát`}
                  allowClear
                  treeData={listApprover}
                  notFoundContent={
                    <span style={{ textAlign: "center" }}>
                      <p>
                        <img
                          className="image-empty-data"
                          src="/images/empty-img-gray.png"
                          alt="empty-img"
                        ></img>
                      </p>
                      <p className="nodata-text">Không tìm thấy</p>
                    </span>
                  }
                  treeNodeFilterProp="title"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
