import {
  ApartmentOutlined,
  ContactsOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Layout, Result, Tabs, TabsProps, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import SettingFeatured from "./setting-featured";
import SettingNotice from "./setting-notice";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import ViewResultSupervision from "./view-result";
import { getSettingExamSupervisionById } from "../../service/exam-supervision";
import { useEffect, useState } from "react";
const imgPath = "/images/";

const { Content } = Layout;

const SettingSupervisionDetail = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isNotFound, setIsNotFound] = useState(false);
  const params = useParams();

  const [paramFeature, setParamFeature] = useState<any>({
    id: "",
    face: true,
    is_active: true,
    is_follow: false,
    is_detect: false,
    is_notification: false,
    timeFollow: "00:00:00",
    timeDetect: "00:00:00",
    completion_time: "",
    notification_ids: [],
  });

  const fetchDataExamSupervision = async () => {
    try {
      const res = await getSettingExamSupervisionById(params?.id || "");
      const { data } = res.data;
      setParamFeature({
        ...paramFeature,
        id: data?.id,
        is_active: data?.is_active,
        is_notification: data?.is_notification,
        is_follow: data?.is_concentration,
        is_detect: data?.is_location,
        timeFollow: data?.concentration ? data?.concentration : "00:00:00",
        timeDetect: data?.location ? data?.location : "00:00:00",
        completion_time: data?.completion_time ? data?.completion_time : "0",
        notification_ids:
          data?.mooc_exam_supervision_permission.map(
            (user: any) => user?.auth_permission?.id
          ) || [],
      });
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 404) {
        setIsNotFound(true);
      }
      messageApi.error({
        type: "error",
        content: error?.response?.data?.message,
      });
    }
  };

  useEffect(() => {
    fetchDataExamSupervision();
  }, []);
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ContactsOutlined />,
      label: "Cài đặt tính chất",
      children: isNotFound ? (
        <Result
          status="404"
          title="404"
          subTitle="Không có dữ liệu."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Trang chủ
            </Button>
          }
        />
      ) : (
        <SettingFeatured
          setParamFeature={setParamFeature}
          paramFeature={paramFeature}
        />
      ),
    },
    {
      key: "2",
      icon: <ApartmentOutlined />,
      label: "Cài đặt thông báo",
      children: isNotFound ? (
        <Result
          status="404"
          title="404"
          subTitle="Không có dữ liệu."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Trang chủ
            </Button>
          }
        />
      ) : (
        <SettingNotice
          setParamFeature={setParamFeature}
          paramFeature={paramFeature}
        />
      ),
    },
    {
      key: "3",
      icon: <FileTextOutlined />,
      label: "Xem kết quả chế độ giám sát",
      children: isNotFound ? (
        <Result
          status="404"
          title="404"
          subTitle="Không có dữ liệu."
          extra={
            <Button type="primary" onClick={() => navigate("/")}>
              Trang chủ
            </Button>
          }
        />
      ) : (
        <ViewResultSupervision courseId={params?.id || ""} />
      ),
    },
  ];

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        title="Cài đặt giám sát thi cử"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </Content>
  );
};

export default SettingSupervisionDetail;
