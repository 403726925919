import {
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps
} from "antd";
import { isEmpty } from "lodash";
import {
  useEffect,
  useMemo,
  useState
} from "react";
import TableData from "../../../components/table-data/TableData";
import { getSystemSuggestCourseAll } from "../../../service/system-suggest-course";
  
  type TableListTagSystemProps = {
    setHeadValue: any;
  };
  interface SearchProps {
    take: number,
    skip: number,
  }
  const TableListTagSystem = ({
    setHeadValue,
  }: TableListTagSystemProps) => {
    const [synthesisReport, setSynthesisReport] = useState<
      any[]
    >([]);
  
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(0);
  
    const fetchData = async (searchParam: SearchProps) => {
      try {
        const response = await getSystemSuggestCourseAll(searchParam);
        const { data } = response.data;
        setSynthesisReport(data?.data);
        setTotalPage(data?.count?._count?.id);
  
        setLoading(false);
        
        setHeadValue([
          {
            label: "Số lượng khoá học trên hệ thống",
            value: data?.data?.name,
          },
          {
            label: "Trạng thái hệ thông",
            value:
              data?.data?.name,
          },
          {
            label: "Thời gian cập nhật",
            value:
              data?.data?.name,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      fetchData({
        take: pageSize,
        skip: (pageNumber - 1) * 10,
      });
    }, [pageSize, pageNumber]);
  
    const dataColumnsQualityEvaluation: TableColumnsType<any> = [
      {
        title: "STT",
        dataIndex: "numericalOrder",
        key: "numericalOrder",
        width: 5,
        render: (_: any, record, index: number) => {
          return <div>{record?.numericalOrder}</div>;
        },
      },
      {
        title: "Tên khoá học",
        dataIndex: "name",
        key: "name",
        width: 20,
        render: (_: any, record) => {
          return (
            <Flex className="table-order-course-name">
              <img
                className=""
                loading="eager"
                alt=""
                src={record?.image || "/images/ic_school_placeholder.svg"}
              />
              <div className="pl-2">
                <span className="course-title">{record?.name}</span>
              </div>
            </Flex>
          );
        },
        sorter: {
          compare: (a, b) => a.name.length - b.name.length,
        },
      },
      {
        title: "Thẻ tag",
        dataIndex: "tag",
        key: "tag",
        width: 30,
        render: (_, data: any) => {
          return (
            <div>
              {!isEmpty(data?.mooc_recommendation) ? (
                <Flex align="center">
                  {data?.mooc_recommendation?.map((data: any, index: number) => {
                    if (index <= 2) {
                      return (
                        <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                          {data?.mooc_recommendation_tag?.name}
                        </div>
                      );
                    }
                  })}
                  {data?.mooc_recommendation?.length > 3 && (
                    <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                      +{data?.mooc_recommendation?.length - 3}
                    </div>
                  )}
                </Flex>
              ) : (
                <div>Chưa có dữ liệu</div>
              )}
            </div>
          );
        },
      },
      // {
      //   title: "Trạng thái",
      //   dataIndex: "trangThai",
      //   key: "trangThai",
      //   render: (_: any, record: any) => {
      //     return (
      //       <div>
      //         {record?.status ? (
      //           <Badge color="green" text="Đã gắn thẻ" />
      //         ) : (
      //           <Badge status="default" text="Chưa gắn thẻ" />
      //         )}
      //       </div>
      //     );
      //   },
      //   width: 10,
      // },
    ];
  
    const defaultCheckedList = dataColumnsQualityEvaluation.map(
      (item) => item.key as string
    );
  
    const [checkedList, setCheckedList] = useState(defaultCheckedList);
  
    const columnChecked = useMemo(() => {
      return dataColumnsQualityEvaluation.map((item) => ({
        ...item,
      }));
    }, [checkedList]);
  
    const handleTableChange: TableProps["onChange"] = (
      pagination,
      filters,
      sorter
    ) => {};
  
    return (
      <Layout className="page-header-group">
        <Form.Provider>
          <TableData
            dataTable={synthesisReport}
            dataColumns={columnChecked}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalPage}
            handleTableChangeProps={handleTableChange}
          />
        </Form.Provider>
      </Layout>
    );
  };
  
  export default TableListTagSystem;
  