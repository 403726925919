import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingAttendance: (
  search: any
) => Promise<AxiosResponse<any>> = (search: any) => {
  return axiosConfig.get(`/setting-attendance/get-course`, search);
};

export const getSettingAttendanceById: (
  id?: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-attendance/get-by-course/${id}`);
};

export const getSettingAttendanceReport: (
  id: string
) => Promise<AxiosResponse<any>> = (id: string) => {
  return axiosConfig.get(`/setting-attendance/get-course-by-id/${id}`);
};

export const getSettingAttendanceReportByID: (
  documentId: string
) => Promise<AxiosResponse<any>> = (documentId: string) => {
  return axiosConfig.get(`/attendance-report/get-by-id/${documentId}`);
};

export const getSettingAttendanceReportByDocumentID: (
  documentId: string
) => Promise<AxiosResponse<any>> = (documentId: string) => {
  return axiosConfig.get(`/attendance-report/get-by-block/${documentId}`);
};

export const getReportAttendance: (
  id: number,
  is_face: number
) => Promise<AxiosResponse<any>> = (id: number, is_face: number) => {
  return axiosConfig.get(
    `/attendance-report/attendance/${id}?is_face=${is_face}`
  );
};

export const getListCourseAttendancePage: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-attendance/get-course-by-page`, dataReq);
};

export const createSettingAttendance: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-attendance/create`, dataReq);
};

export const getListCourseAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-attendance/search_course`, dataReq);
};

export const getListReportAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/attendance-report/search`, dataReq);
};

export const getListReportDocumentAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/attendance-report/search-by-document`, dataReq);
};

export const getListUserAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-attendance/search_user`, dataReq);
};

export const createReportDocumentAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/attendance-report/create`, dataReq);
};

export const createReportByStudent: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/attendance-report/create-by-student`, dataReq);
};

export const shareReportDocumentAttendance: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/report-share/create`, dataReq);
};

export const updateSettingAttendance: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-attendance/update/${id}`, dataReq);
};
export const updateStatusSettingAttendance: (
  id: string | number,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(
    `/setting-attendance/update_active_attendance/${id}`,
    dataReq
  );
};
export const updateReportSettingAttendance: (
  id?: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/attendance-report/update/${id}`, dataReq);
};

export const updateRatingSettingAttendance: (
  id?: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/attendance-report/update-rating/${id}`, dataReq);
};

export const deleteSettingAttendance: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/setting-attendance/delete/${id}`);
};

export const deleteReportAttendance: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/attendance-report/delete/${id}`);
};
