import {
  CaretDownOutlined,
  CloseOutlined,
  EditOutlined,
  FileWordOutlined,
  InfoCircleOutlined,
  RobotOutlined,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Tooltip,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonModal from "../../components/modal/common";
import { getSettingStructureCourseById } from "../../service/setting-course-structure";
import "./setting-structure.scss";
import { isEmpty } from "lodash";

const { Title, Text } = Typography;
const { TextArea } = Input;
interface IProps {
  handleNotice: (type: any, message: string) => void;
  dataParam: any;
  isView: boolean;
}

const URL_SUGGEST_COURSE_CONTENT = process.env
  .REACT_APP_SUGGEST_COURSE_CONTENT as string;

const SettingStructureContent = ({
  handleNotice,
  dataParam,
  isView,
}: IProps) => {
  const params = useParams();
  const [dataCourse, setDataCourse] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [indexSequence, setIndexSequence] = useState(NaN);

  const fetchData = async () => {
    try {
      const response = await getSettingStructureCourseById(params?.id || "");
      const { data } = response;
      console.log(data?.data[0]);

      setDataCourse(data?.data[0] ? data?.data[0] : []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpen = (id: number) => {
    setIsOpen(true);
  };
  const handleSaveSequence = () => {
    setIndexSequence(NaN);
    handleNotice("success", "Lưu thông tin thành công");
  };
  const CommonSession = () => {
    const treeData: TreeDataNode[] = dataCourse?.mooc_course_section?.map(
      (value: any) => ({
        title: (
          <Flex justify="space-between" align="center">
            <div>
              <Title level={5}>{value?.name}</Title>
              <p className="text-[#00000073]">
                Mô tả: {value?.description ? value?.description : "Không có"}
              </p>
            </div>
            {!isView && (
              <Tooltip title="Cài đặt cấu trúc bài giảng mặc định">
                <Button
                  className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                  onClick={() => handleOpen(value?.id)}
                >
                  <SettingOutlined />
                </Button>
              </Tooltip>
            )}
          </Flex>
        ),
        key: value?.id,
        children:
          value?.mooc_course_sequence
            .map((sequenceData: any, index: number) => ({
              title: (
                <Flex
                  justify="space-between"
                  align="center"
                  className="pl-2 py-2"
                >
                  <div className="py-2">
                    <Typography.Text className="pl-2">
                      {sequenceData.name}
                    </Typography.Text>
                    <p className="text-[#00000073]">
                      Mô tả:{" "}
                      {sequenceData?.description
                        ? sequenceData?.description
                        : "Không có"}
                    </p>
                  </div>
                  {!isView && (
                    <Flex className="w-full sm:w-fit">
                      {indexSequence === index ? (
                        <Flex>
                          <Button
                            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                            onClick={() => setIndexSequence(NaN)}
                          >
                            <CloseOutlined />
                            Hủy
                          </Button>
                          <Button
                            type="primary"
                            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                            onClick={handleSaveSequence}
                          >
                            <SaveOutlined />
                            Lưu
                          </Button>
                        </Flex>
                      ) : (
                        <Tooltip title="Chỉnh sửa nội dung">
                          <Button
                            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                            onClick={() => setIndexSequence(index)}
                          >
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                      )}
                    </Flex>
                  )}
                </Flex>
              ),
              key: sequenceData?.id,
              icon: <FileWordOutlined />,
              children: sequenceData?.mooc_course_unit?.map(
                (unitData: any) => ({
                  title: (
                    <Flex
                      justify="space-between"
                      align="center"
                      wrap="wrap"
                      className="pl-2 py-2"
                    >
                      <div className="py-2 border-l">
                        <Flex>
                          <Typography.Text className="pl-2">
                            {unitData.name}
                          </Typography.Text>
                        </Flex>
                      </div>
                      {!isView && (
                        <Button className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer">
                          <RobotOutlined />
                        </Button>
                      )}
                    </Flex>
                  ),
                  key: unitData?.id,
                })
              ),
            }))
            .filter((item: any) => item) || [],
      })
    );
    return (
      <Card>
        <Tree
          showIcon
          className="tree-course setting-structure-tree"
          defaultExpandAll
          switcherIcon={<CaretDownOutlined />}
          treeData={treeData}
        />
      </Card>
    );
  };

  const handleConfirmChoose = () => {
    setIsOpen(false);
  };

  return (
    <div className="p-6">
      <Card className="w-full">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Flex align="center">
              <Title level={4} className="!m-0 pr-4">
                Cài đặt cấu trúc bài giảng
              </Title>
              {dataParam?.is_active ? (
                <Badge status="success" text="Đang bật" />
              ) : (
                <Badge status="default" text="Đang tắt" />
              )}
            </Flex>
            <Text className="text-[#00000073]">
              Hệ thống gợi ý cấu trúc bài giảng hỗ trợ người dùng phát triển và
              quản lý nội dung khoá học
            </Text>
          </div>
        </Flex>
        <Flex className="py-4 border-b">
          <span className="!font-semibold pr-8 w-1/6">Tên khoá học</span>
          <div className="px-4">{dataCourse?.name}</div>
        </Flex>
        <Flex className="py-4 border-b">
          <span className="!font-semibold pr-8 w-1/6">Khoa</span>
          <div className="px-4">
            {!isEmpty(dataCourse?.mooc_course_industry) &&
              dataCourse?.mooc_course_industry[0]?.mooc_industry?.name}
          </div>
        </Flex>
        <Flex className="py-4">
          <span className="!font-semibold pr-8 w-1/6">Chuyên ngành</span>
          <div className="px-4">
            {!isEmpty(dataCourse?.mooc_course_industry_group) &&
              dataCourse?.mooc_course_industry_group[0]?.mooc_industry_group
                ?.name}
          </div>
        </Flex>
      </Card>
      {/* {dataParam?.is_active && ( */}
      <Card className="w-full mt-6">
        <div className="border-b pb-4">
          <Title level={4} className="!m-0 pr-4">
            Thông tin cấu trúc bài giảng
          </Title>
          <Text className="text-[#00000073]">
            Nội dung cấu trúc bài giảng theo chương
          </Text>
        </div>
        <div className="border rounded-md mt-4">
          <CommonSession />
        </div>
      </Card>
      {/* )} */}
      <CommonModal
        open={isOpen}
        title={`Cài đặt cấu trúc bài giảng mặc định`}
        desc={
          <div>
            <p>Chọn loại học liệu để hệ thống đưa gợi ý cấu trúc bài giảng</p>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                // checked={paramFeature?.is_follow}
                // onChange={onChangeSelect}
                name="study"
              />
              <span className="pl-4">Học liệu kiến thức</span>
            </Flex>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                // checked={paramFeature?.is_follow}
                // onChange={onChangeSelect}
                name="rate"
              />
              <span className="pl-4">Học liệu đánh giá</span>
            </Flex>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                // checked={paramFeature?.is_follow}
                // onChange={onChangeSelect}
                name="format"
              />
              <span className="pl-4">Học liệu tham khảo</span>
            </Flex>
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmChoose}
        closeModal={() => setIsOpen(false)}
        className={""}
      />
    </div>
  );
};

export default SettingStructureContent;
