import { Col, Form, Layout, Row, Spin } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./university-management-detail.scss"
import { getMoocUniversityById } from "../../../../service/category-management/university-management";

const { Content } = Layout;
const imgPath = "/images/";

const UniversityManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);
    const [universityDetail, setUniversityDetail] = useState<any>();
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.universityManagement}`}>
            <span>Quản lý trường đại học</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
    ];

    // Get detail university
    const getUniversityUpdate = async (universityId: number) => {
        setLoadingDetail(true);
        try {
            await getMoocUniversityById(universityId).then((res) => {
                if(res.status === 200) {
                    const { data } = res.data;
                    setUniversityDetail(data);
                    setTimeout(() => {
                        setLoadingDetail(false);
                    }, 333);
                }
            })
        } catch (err) {
            setLoadingDetail(false);
            console.error(err);
        }
    };

    useEffect(() => {
        getUniversityUpdate(location.state.universityId);
    }, [location.state.universityId])

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={isShowSubmitButton}
                />
                <div className="wrapper-settings">
                    <section className="setting-university">
                        <CustomCard className="university-card" title="Thông tin cơ sở đào tạo">
                            {loadingDetail ? 
                                <div className="statistic-course-content statistic-course-loading">
                                    <Spin size="large" />
                                </div> 
                                : 
                                <Row gutter={[16,16]}>
                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="universityName" label="Tên cơ sở đào tạo">
                                            <p className="university-content">{universityDetail?.name}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="englishName" label="Tên tiếng Anh">
                                            <p className="university-content">{universityDetail?.nameEn}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="modalType" label="Loại hình">
                                            <p className="university-content">{universityDetail?.typeSchool}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="universityCode" label="Mã trường">
                                            <p className="university-content">{universityDetail?.code}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="trainingType" label="Loại trường">
                                            <p className="university-content">{universityDetail?.type}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="province" label="Tỉnh/TP">
                                            <p className="university-content">{universityDetail?.provinceName}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="managementAgency" label="Cơ quan quản lý trực tiếp">
                                            <p className="university-content">{universityDetail?.organizationName}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            className="setting-item item-label" name="district" label="Quận/Huyện">
                                            <p className="university-content">{universityDetail?.districtName}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="principal" label="Hiệu trưởng">
                                            <p className="university-content">{universityDetail?.principal}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item className="setting-item item-label" name="ward" label="Phường/Xã/Thị trấn">
                                            <p className="university-content">{universityDetail?.wardName}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="address" label="Địa chỉ">
                                            <p className="university-content">{universityDetail?.address}</p>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item className="setting-item" name="website" label="Website">
                                            <p className="university-content">{universityDetail?.addressWebsite}</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </CustomCard>
                    </section>
                </div>
            </Content>
        </>
    )
}

export default UniversityManagementDetail;