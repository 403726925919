import {
  Card,
  Flex,
  Form,
  Layout,
  Select,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import TableData from "../../../../../../components/table-data/TableData";
import { routesConfig } from "../../../../../../config/routes";
import { getSettingAttendanceReportByID } from "../../../../../../service/setting-attendance";
import { DataType } from "../../../../../../types/listUsers";
import { formatDate } from "../../../../../../utils/converDate";
import { useStudyManagementStore } from "../../../../../../stores/stores";

type TableReportCheckInDetailProps = {
  title?: string;
  idDocument?: string;
  setReport: any;
  paramStatus: any;
  setParamStatus: any;
  setIdCourse: any;
  isViewHistory: boolean;
  setIsAction: (action: boolean) => void
};
interface SearchType {
  search: string;
  course_document_id?: number | string;
  skip: number;
  take: number;
}

const mockData = [
  {
    time: "15:00 13/6/2023",
    id: 1,
  },
  {
    time: "15:00 13/6/2023",
    id: 2,
  },
  {
    time: "15:00 13/6/2023",
    id: 13,
  },
  {
    time: "15:00 13/6/2023",
    id: 11,
  },
  {
    time: "15:00 13/6/2023",
    id: 12,
  },
];
const TableReportCheckInDetail = ({
  title,
  idDocument,
  setReport,
  paramStatus,
  setParamStatus,
  setIdCourse,
  isViewHistory,
  setIsAction,
}: TableReportCheckInDetailProps) => {
  const location = useLocation();

  const [accountReportCheckInDetail, setAccountReportCheckInDetail] = useState<
    DataType[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [headValue, setHeadValue] = useState<any>([]);
  const { setTitleAttendance } = useStudyManagementStore((state) => state);
  const [dataListReport, setDataListReport] = useState<any>(mockData);
  const [idStudent, setIdStudent] = useState(1);

  const pathName = location.pathname;

  const fetchData = async (idDocument: any) => {
    try {
      const response = await getSettingAttendanceReportByID(idDocument);
      const { data } = response.data;
      setAccountReportCheckInDetail(data?.data?.mooc_attendance || []);
      setTotalPage(data?.data?.total || 0);

      setParamStatus(
        data?.data?.mooc_attendance?.map((item: any) => ({
          id: item?.id,
          result: item?.result,
        }))
      );
      setLoading(false);
      setReport({
        title: data?.data?.name || "",
        idReport: data?.data?.id,
      });
      setIdCourse(data?.data.mooc_course.id);
      setTitleAttendance(data?.data.mooc_course.name);
      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.data.mooc_course.name,
        },
        {
          label: "Chương",
          value:
            data?.data?.mooc_course_block?.mooc_course_unit
              ?.mooc_course_sequence.mooc_course_section.name,
        },
        {
          label: "Bài giảng",
          value:
            data?.data?.mooc_course_block?.mooc_course_unit
              ?.mooc_course_sequence.name,
        },
        {
          label: "Học liệu",
          value: data?.data?.mooc_course_block?.title,
        },
        {
          label: "Ngày học",
          value: data?.data?.mooc_course_block?.mooc_course_unit?.published_date
            ? formatDate(
                data?.data?.mooc_course_block?.mooc_course_unit?.published_date
              )
            : "Không có ngày học",
        },
        {
          label: "Thời gian học",
          value: data?.data?.mooc_course_block?.mooc_course_unit
            ?.schedule_start_date
            ? formatDate(
                data?.data?.mooc_course_block?.mooc_course_unit
                  ?.schedule_start_date
              )
            : "Không có thời gian học",
        },
        {
          label: "Sĩ số",
          value: `${data?.data?.attendance}/${data.data.total}`,
        },
        {
          label: "Tính chất điểm danh",
          value: `${data?.data?.lesson ? "Tiết học" : ""}${
            data?.data?.discussion ? ", Thảo luận" : ""
          }${data?.data?.seminar ? ", Khảo sát" : ""}`,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData(idDocument);
  }, [pageSize, pageNumber]);

  const handleChangeStatus = (value: any, id: number | string) => {
    setIsAction(true)
    setParamStatus((prevData: any[]) =>
      prevData.map((item: { id: string | number; result: any }) =>
        item.id === id ? { ...item, result: value } : item
      )
    );
  };

  const dataColumnsReportCheckInDetail: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.first_name + " " +
              record?.auth_user_mooc_attendance_student_idToauth_user
                ?.last_name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.auth_userprofile?.date_of_birth &&
              formatDate(
                record?.auth_user_mooc_attendance_student_idToauth_user
                  ?.auth_userprofile?.date_of_birth
              )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.auth_userprofile?.phone_number &&
              formatDate(
                record?.auth_user_mooc_attendance_student_idToauth_user
                  ?.auth_userprofile?.phone_number
              )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user?.email}
          </Typography.Text>
        );
      },
    },
    {
      title: "Điểm danh",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {pathName.includes(routesConfig.reportCheckInDetail) ||
            isViewHistory ? (
              <div
                className={`${
                  record?.result ? "table-checkin-1" : "table-checkin-2"
                }`}
              >
                {record?.result ? "Có mặt" : "Vắng mặt"}
              </div>
            ) : (
              <Select
                defaultValue={record?.result ? "Có mặt" : "Vắng mặt"}
                style={{ width: 120 }}
                onChange={(value) => handleChangeStatus(value, record?.id)}
                options={[
                  { value: true, label: "Có mặt" },
                  { value: false, label: "Vắng mặt" },
                ]}
              />
            )}
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckInDetail.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    if (isViewHistory) {
      dataColumnsReportCheckInDetail.pop();
      dataColumnsReportCheckInDetail.push({
        title: "Điểm danh",
        dataIndex: "courseType",
        key: "courseType",
        width: 20,
        render: (_: any, record) => {
          return (
            <div className="table-course-type">
              <div
                className={`${
                  record?.result ? "table-checkin-1" : "table-checkin-2"
                }`}
              >
                {record?.result ? "Có mặt" : "Vắng mặt"}
              </div>
            </div>
          );
        },
      });
    }
    return dataColumnsReportCheckInDetail.map((item) => ({
      ...item,
    }));
  }, [checkedList, isViewHistory]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="px-6 pt-6 w-full">
          <Card>
            <Flex className="w-full 2xl:justify-between" wrap="wrap">
              {headValue?.map((dataReport: any, index: number) => (
                <div
                  className={`${
                    index + 1 === headValue.length ? "" : "sm:border-r"
                  } px-4 w-full sm:w-1/2 md:w-1/4 mb-2 xl:mb-0 xl:w-fit`}
                  key={index}
                >
                  <p className="w-full">{dataReport.label}</p>
                  <p className="font-medium text-lg">{dataReport?.value}</p>
                </div>
              ))}
            </Flex>
          </Card>
        </div>
        <Flex className="w-full">
          <div className={`${isViewHistory ? "w-4/5" : "w-full"}`}>
            <TableData
              dataTable={accountReportCheckInDetail}
              dataColumns={columnChecked}
              setPageNumber={setPageNumber}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              pageSize={pageSize}
              pagination={true}
              loadingTable={loading}
              scroll={{ x: 1500, y: 600 }}
              totalRecords={totalPage}
              handleTableChangeProps={handleTableChange}
            />
          </div>
          {isViewHistory && (
            <div className="w-1/5 my-[1.5rem] mr-[1.5rem] bg-white">
              <div className="h-full">
                <Typography.Title
                  level={5}
                  className="text-center border-b p-4 !mb-0"
                >
                  Phiên bản chỉnh sửa
                </Typography.Title>
                <div>
                  {dataListReport?.map((listReport: any, index: number) => (
                    <Flex
                      key={index}
                      className={`hover:bg-[#E6F7FF] p-4 ${
                        listReport?.id === idStudent
                          ? "bg-[#E6F7FF] border-r-4 border-r-[#1890FF]"
                          : ""
                      }`}
                      onClick={() => setIdStudent(listReport?.id)}
                    >
                      <Typography.Text className="pr-3">
                        {Number(index + 1)}
                      </Typography.Text>
                      <Typography.Text>
                        Chỉnh sửa lúc {listReport?.time}
                      </Typography.Text>
                    </Flex>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Flex>
      </Form.Provider>
    </Layout>
  );
};

export default TableReportCheckInDetail;
