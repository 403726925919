import { Card, Flex, Layout, Typography, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import HeadSynthesisReportSystem from "./HeadSynthesisReportSystem";
import TableListTagSystem from "./TableListTagSystem";
import { useState } from "react";
import { routesConfig } from "../../../config/routes";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import StatisticalCourseTag from "./StatisticalCourseTag";
import { UpOutlined } from "@ant-design/icons";
import TableSynthesisReportSystem from "./TableSynthesisReportSystem";
const { Content } = Layout;
const imgPath = "/images/";

const SynthesisReportSystem = () => {
  const [headValue, setHeadValue] = useState<any>([]);
  const [isShow, setIsShow] = useState<any>([]);

  return (
    <Content>
      <div className="p-6 bg-white">
        <PageHeaderCommon title="Báo cáo tổng hợp" />
      </div>
      <HeadSynthesisReportSystem headValue={headValue} />
      <StatisticalCourseTag />
      <div className="px-3 pt-6">
        <Card className="mx-3 table-list-score">
          <Flex
            justify="space-between"
            align="center"
            className="p-6 cursor-pointer"
            onClick={() => setIsShow(!isShow)}
          >
            <Typography.Title level={5} className="!mb-0">
              Danh sách thẻ tag theo sở thích
            </Typography.Title>
            <UpOutlined
              className={`${
                isShow ? "rotate-180" : ""
              } ease-in-out duration-500`}
            />
          </Flex>
          <div
            className={`${
              isShow ? "h-fit" : "h-0"
            } overflow-hidden transition duration-500 ease-in-out`}
          >
            <TableListTagSystem setHeadValue={setHeadValue} />
          </div>
        </Card>
      </div>
      <div className="px-6 pt-6">
        <TableSynthesisReportSystem />
      </div>
    </Content>
  );
};

export default SynthesisReportSystem;
