export default [
  
  [{ size: [ 'small', false, 'large', 'huge' ]}],
  ["bold", "italic", "underline", "formula", { list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }] ,
  [{ 'color': [] }, { 'background': [] }], 
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  ["link", "image", "video"],
  // ["link", "image", "video"],
  // [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
];
