import { FunctionComponent, ReactNode } from "react";
import "./page-header.scss";
import { Badge, Breadcrumb, Button, Modal, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  DeleteOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";

type PageHeaderCommonType = {
  arrowLeft?: string;
  subTitle?: string;
  title?: string | null;
  headerGroupButton?: boolean;
  breadcrumb?: boolean;
  positionItem?: string;
  listBreadcrumb?: ItemType[];
  childrenGroupButton?: ReactNode;
  handleBack?: any;
};

const PageHeaderCommon: FunctionComponent<PageHeaderCommonType> = ({
  arrowLeft,
  title,
  breadcrumb,
  positionItem,
  listBreadcrumb,
  childrenGroupButton,
  headerGroupButton,
  subTitle,
  handleBack,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className={`page-header ${positionItem ? "item-vertical" : ""} ${
        !breadcrumb ? "only-title" : ""
      }`}
      id="page-header"
    >
      {breadcrumb && <Breadcrumb items={listBreadcrumb} />}
      <div className="page-heading">
        <div className="heading-left">
          {arrowLeft && (
            <ArrowLeftOutlined
              className="arrowleft-icon"
              onClick={handleBack}
            />
          )}
          <h3 className="title54">{`${subTitle ?? ""} ${title}`}</h3>
        </div>
        {headerGroupButton && (
          <div className="heading-right">{childrenGroupButton}</div>
        )}
      </div>
    </div>
  );
};

export default PageHeaderCommon;
