import { Col, Row, Select } from "antd";
import React from "react";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import LineChart from "../../../../../../components/types-of-charts/LineChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import {
  AreaStackedChartData,
  barChartData,
  lineChartData,
} from "./DataFakeChart";
import StatisticalReportCard from "../../StatisticalReportCard";

type Props = {};
const UserBehaviorChartTab = (props: Props) => {
  return (
    <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượng tìm kiếm theo từ khoá"
          content={
            <BarChart
              data={barChartData}
              colors={["#69C0FF"]}
              xAxisTitle="Từ khoá"
            />
          }
        />
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tìm kiếm tài nguyên"
          content={
            <AreaStackedChart
              data={AreaStackedChartData}
              colors={["#8FD0FF", " #85E4DE", "#FFD08F", "#FF9A98", "#C69FF0"]}
              yAxisTitle="Số lượng phản hồi"
            />
          }
        />
      </Col>

      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tải về tài nguyên"
          content={
            <LineChart
              yAxisTitle="Số lượng"
              data={lineChartData}
              lineColor="#FF7875"
              lineName="Lượt tải về"
            />
          }
        />
      </Col>
      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tải về tài nguyên"
          content={
            <LineChart
              yAxisTitle="Số lượng"
              data={lineChartData}
              lineColor="#69C0FF"
              lineName="Lượt xem"
            />
          }
        />
      </Col>
      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tải về tài nguyên"
          content={
            <LineChart
              yAxisTitle="Số lượng"
              data={lineChartData}
              lineColor="#5CDBD3"
              lineName="Lượt chia sẻ"
            />
          }
        />
      </Col>
    </Row>
  );
};

export default UserBehaviorChartTab;
