import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { axiosConfigToUpload } from "../../../config/api/configApiUpload";
import { axiosConfigV2OpenAPI } from "../../../config/api/configOpenApiV2";
import { axiosConfigV2 } from "../../../config/api/configApiv2";

export const getMeId: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/authentications/me`);
};

export const getAccountInfo: (param: number) => Promise<AxiosResponse<any>> = (
  param: number
) => {
  return axiosConfig.get(`/accounts/get-by-id/${param}`);
};

export const getInfoLoginUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/account/get-teacher-profile`);
};

export const putChangePassword: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.put(`/pesonals/change-password-personal`, data);
};

export const editAccountInfo: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(`/account/update-profile`, payload);
};

export const getpositions: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(`/profile/get-positions`);
};
export const getAllIndustry: (params: any) => Promise<AxiosResponse<any>> = (
  params
) => {
  return axiosConfigV2OpenAPI.post(`/course/get-all-industry`, params);
};
export const getACademicLevel: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(`/profile/get-academic-level`);
};
export const getAllEnterprise: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(
    `/enterprise/get-all-enterprise?page=1&size=1000`
  );
};

export const getTeacherOrganization: (
  paload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2OpenAPI.post(
    `/profile/get-all-teacher-organization`,
    payload
  );
};

export const getCountry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(`/address/countries`);
};

export const getProvinces: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2OpenAPI.get(`/address/provinces?code=4`);
};

export const getDistricts: (
  provinceId: string
) => Promise<AxiosResponse<any>> = (provinceId) => {
  return axiosConfigV2OpenAPI.get(`/address/districts?code=${provinceId}`);
};

export const getWards: (districtId: string) => Promise<AxiosResponse<any>> = (
  districtId
) => {
  return axiosConfigV2OpenAPI.get(`/address/wards?code=${districtId}`);
};

export const sendOtpDeleteAccount: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.post(`/account/delete-account/send-otp`, {});
};

export const deleteAccountByPassword: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    "/account/delete-account/confirm-by-password",
    payload
  );
};

export const deleteAccountByOtp: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post("/account/delete-account/confirm-by-otp", payload);
};
