import { FunctionComponent, useEffect, useState } from "react";
import "./index.scss";
import { Button, Col, Layout, Row, Modal, message } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DetailContent } from "./component/detail-content/DetailContent";
import {
  useAppStore,
  useAuthStore,
  useSurveyDetail,
  useSurveyRespond,
} from "../../../stores/stores";
import DetailSection from "./component/detail-section/DetailSection";
import { listSurveySection2 } from "./component/detail-section/dataExample";
import {
  getListAnswerStatusNotIn,
  getQuestionSurvey,
  getQuestionSurveyDraft,
  submitDraftAnswer,
  submitSurveyAnswer,
  getQuestionSurveyByUserId,
} from "../../../service/survey-managment/mooc-survey-respond/mooc-survey-respond-api";
import { FileDoneOutlined } from "@ant-design/icons";
import themeSurvey from "../common/constant/theme-survey.const";
import CustomCard from "../../../components/custom-card/CustomCard";
import SurveyDescription from "./component/survey-description/DetailSection";
import { downloadFile } from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
const { Content } = Layout;
const imgPath = "../images/";

const SurveyRespond: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id: surveyId } = useParams();
  const { type } = useParams();
  const { idPar } = useParams();

  const { theme } = useParams();
  const { checkEnd } = location.state || {};
  const [themeValue] = useState<string>(theme ?? "1");
  const userInfo = useAuthStore((state) => state.user);
  const [userId, setUserId] = useState<any>(idPar ? idPar : userInfo?.id);
  const [nameSurvey, setNameSurvey] = useState<string>("");
  const [descriptionSurvey, setDescriptionSurvey] = useState<string>("");
  const setSurveyData = useSurveyRespond((state) => state.setSurveyData);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const surveyData = useSurveyRespond((state) => state.surveyData);

  useEffect(() => {
    if (isInitialRender) {
      setSurveyData([]);
      setIsInitialRender(false);
    }
    handelChangeTheme(themeValue);
  }, []);
  const imagePattern = /\.(jpg|jpeg|png)(\?.*)?$/i;
  const videoPattern = /\.(mp4|mov|wmv|flv|avi|mkv|webm)(\?.*)?$/i;
  const isImage = (url: any) => {
    return imagePattern.test(url);
  };

  const isVideo = (url: any) => {
    return videoPattern.test(url);
  };
  const getAllSrcValues = (html: string) => {
    const srcPattern = /src="([^"]*)"/g;
    const filePattern = /bucket\/(.*?)\?X-Amz-Algorithm/;
    const matches = [];
    let match;
    while ((match = srcPattern.exec(html)) !== null) {
      const srcValue = match[1];
      const fileMatch = filePattern.exec(srcValue);
      if (fileMatch) {
        matches.push({
          file: fileMatch[1],
        });
      }
    }
    return matches;
  };
  const fetchUrl = async (url: string) => {
    const res = await downloadFile({ filePath: url });
    return res;
  };
  const descripttionFinal = (surveyInfo: any) => {
    let arr: any = [];
    if (surveyInfo && surveyInfo?.descriptionSurvey) {
      arr = getAllSrcValues(surveyInfo.descriptionSurvey);
      console.log({ arr });
      const fetchAllUrls = async () => {
        try {
          const promises = arr.map((item: any) => fetchUrl(item.file));
          const results = await Promise.all(promises);
          console.log({ results });
          let parser: any = new DOMParser();
          let doc: any = parser.parseFromString(
            surveyInfo?.descriptionSurvey,
            "text/html"
          );
          let elements: any = doc.querySelectorAll("iframe, img");
          console.log({ elements });
          results.forEach((newDesc, index) => {
            let element = elements[index];
            if (element) {
              if (
                element.tagName.toLowerCase() === "img" &&
                isImage(newDesc.data)
              ) {
                element.src = newDesc.data;
              } else if (
                element.tagName.toLowerCase() === "iframe" &&
                isVideo(newDesc.data)
              ) {
                console.log("Hello");
                element.src = newDesc.data;
              }
            }
          });
          let updatedDescription = doc.body.innerHTML;
          console.log({ updatedDescription });
          setDescriptionSurvey(updatedDescription);
          // setListUrl(arr);
        } catch (error) {
          console.error("Error: ", error);
        }
      };
      fetchAllUrls();
    }
  };
  useEffect(() => {
    // if (surveyData.length === 0) {
    //   if (type === "0") {
    //     const initializeUser = (userId: number, surveyId: number) => {
    //       const dataKey = `survey_${userId}_${surveyId}`;
    //       const storedData = localStorage.getItem(dataKey);
    //       if (storedData) {
    //         const parseData = JSON.parse(storedData);
    //         const { nameSurvey, surveyData, descriptionSurvey } = parseData;
    //         if (surveyData.length > 0) {
    //           setSurveyData(surveyData);
    //           setNameSurvey(nameSurvey);
    //           setDescriptionSurvey(descriptionSurvey);
    //           return;
    //         }
    //       }
    //       fetchSurveyData();
    //     };
    //     initializeUser(Number(userId), Number(surveyId));
    //   } else if (type === "1") {
    //     const fetchSurveyDataDraf = async () => {
    //       try {
    //         const res = await getQuestionSurveyDraft(userId, surveyId);
    //         const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
    //         setNameSurvey(nameSurvey);
    //         setSurveyData(surveyData);
    //         setDescriptionSurvey(descriptionSurvey);
    //       } catch (error) {
    //         console.error("Failed to fetch survey data", error);
    //       }
    //     };
    //     fetchSurveyDataDraf();
    //   } else if (type === "2") {
    //     const fetchSurveyDataDone = async () => {
    //       try {
    //         console.log("Hello", userId);
    //         const res = await getQuestionSurvey(userId, surveyId);
    //         console.log(res);
    //         const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
    //         setNameSurvey(nameSurvey);
    //         setSurveyData(surveyData);
    //         setDescriptionSurvey(descriptionSurvey);
    //       } catch (error) {
    //         console.error("Failed to fetch survey data", error);
    //       }
    //     };
    //     fetchSurveyDataDone();
    //   }
    // }
    // if (
    //   !isInitialRender &&
    //   surveyData &&
    //   userId &&
    //   surveyId &&
    //   surveyData.length > 0 &&
    //   nameSurvey !== ""
    // ) {
    //   const dataKey = `survey_${userId}_${surveyId}`;
    //   localStorage.setItem(
    //     dataKey,
    //     JSON.stringify({
    //       nameSurvey,
    //       surveyData,
    //       descriptionSurvey,
    //     })
    //   );
    // }
    if (type === "0") {
      console.log("Hello");
      fetchSurveyData();
    } else if (type === "1") {
      const fetchSurveyDataDraf = async () => {
        try {
          const res = await getQuestionSurveyDraft(userId, surveyId);
          const { nameSurvey, surveyData } = res.data.data;
          setNameSurvey(nameSurvey);
          setSurveyData(surveyData);
          descripttionFinal(res.data.data);
        } catch (error) {
          console.error("Failed to fetch survey data", error);
        }
      };
      fetchSurveyDataDraf();
    } else if (type === "2") {
      const fetchSurveyDataDone = async () => {
        try {
          const res = await getQuestionSurvey(userId, surveyId);
          const { nameSurvey, surveyData } = res.data.data;
          setNameSurvey(nameSurvey);
          setSurveyData(surveyData);
          descripttionFinal(res.data.data);
        } catch (error) {
          console.error("Failed to fetch survey data", error);
        }
      };
      fetchSurveyDataDone();
    }
  }, []);

  const fetchSurveyData = async () => {
    try {
      const res = await getListAnswerStatusNotIn(userId, surveyId);
      const { nameSurvey, surveyData, descriptionSurvey } = res.data.data;
      setNameSurvey(nameSurvey);
      setSurveyData(surveyData);
      descripttionFinal(res.data.data);
    } catch (error) {
      console.error("Failed to fetch survey data", error);
    }
  };

  const handleSubmitDraftAnswer = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận trả lời bản nháp",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        content: (
          <div>
            Bản nháp sẽ được tạo và lưu vào mục đã tham gia, bạn hãy quay trở
            lại làm khảo sát trong thời gian sớm nhất.
          </div>
        ),
        onOk: async () => {
          try {
            await submitDraftAnswer(userId, surveyId, surveyData);
            message.success("Lưu bản nháp thành công");
            navigate("/survey-student/training-need");
          } catch (error) {
            message.error("Lưu bản nháp thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Lưu bản nháp thất bại");
    }
  };

  const handleSubmitSurveyAnswer = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận trả lời đợt khảo sát",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        content: (
          <div>
            Đảm bảo rằng{" "}
            <span style={{ color: "orange" }}>
              tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi Xác nhận gửi kết quả khảo sát.
          </div>
        ),
        onOk: async () => {
          try {
            for (let section of surveyData) {
              for (let question of section.moocSurveyQuestion ?? []) {
                if (
                  question.isRequired &&
                  (question.questionAnswer === null ||
                    question.questionAnswer === undefined ||
                    question.questionAnswer === "")
                ) {
                  message.error(
                    `"${question.questionName}" cần được trả lời trước khi gửi đi.`
                  );
                  return;
                }
              }
            }
            await submitSurveyAnswer(userId, surveyId, surveyData);
            message.success("Gửi kết quả khảo sát thành công");
            navigate("/survey-student/training-need");
          } catch (error) {
            message.error("Xác nhận gửi kết quả khảo sát thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Gửi khảo sát thất bại");
    }
  };

  // theme
  const handelChangeTheme = (value: string) => {
    let themeData = themeSurvey.filter((item) => {
      return item.value == themeValue.toString();
    })[0];
    let elementParent = document.getElementById("themeElement");
    while (elementParent?.className != "wrapper" && elementParent) {
      elementParent = elementParent?.parentElement ?? null;
    }
    elementParent != null
      ? (elementParent.style.backgroundColor =
          themeData.WapperBackgroundColor.toString())
      : null;
    const element: HTMLCollectionOf<Element> =
      document.getElementsByClassName("ant-card-head");
    for (let i = 0; i < element.length; i++) {
      element[i].className = `ant-card-head ${themeData.themeName}`;
    }
  };

  const listBreadcrumb = [
    {
      title: "Khảo sát",
    },
    {
      title: (
        <a href={`/survey-student/training-need`}>Khảo sát nhu cầu đào tạo</a>
      ),
    },
    {
      title: `${nameSurvey}`,
    },
  ];
  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={nameSurvey}
          arrowLeft={"img"}
          positionItem="column"
          breadcrumb={true}
        />
        <DetailContent>
          <div id="themeElement">{}</div>
          {surveyData.length > 0 ? (
            <>
              <SurveyDescription
                surveyName={nameSurvey}
                surveyDescription={descriptionSurvey}
              />
              <DetailSection
                dataSection={surveyData}
                typeSurvey={type}
                theme={theme}
              />
            </>
          ) : (
            <CustomCard
              title="Câu hỏi khảo sát"
              className="wrapper-settings--card form-setting--common card-setting"
            >
              <span>Khảo sát này chưa có câu hỏi nào</span>
            </CustomCard>
          )}
          {type !== "2" && surveyData.length > 0 && (
            <Row
              justify="start"
              align="middle"
              style={{ marginTop: 20, width: "100%" }}
              gutter={8}
            >
              <Col span={2}>
                <Button
                  onClick={() => navigate("/survey-student/training-need")}
                  disabled={location.state.mode === "view"}
                >
                  Hủy
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => handleSubmitDraftAnswer()}
                  disabled={location.state.mode === "view"}
                >
                  Lưu nháp
                </Button>
              </Col>
              <Col span={3}>
                <Button
                  type="primary"
                  onClick={() => handleSubmitSurveyAnswer()}
                  disabled={location.state.mode === "view"}
                >
                  Gửi trả lời
                </Button>
              </Col>
            </Row>
          )}
        </DetailContent>
      </Content>
    </>
  );
};

export default SurveyRespond;
