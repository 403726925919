import { Card, Col, Row, Statistic } from "antd";
import React from "react";
import StatisticalReportCard from "../../StatisticalReportCard";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import { barChartNumberData, barStackedChartData, donutChartMultimediaData, donutChartReferencesData, donutChartScormData, donutChartTestData } from "../../constants/fakeData";
import { get } from "lodash";
import { CaretUpOutlined } from "@ant-design/icons";
import "./resource-chart-tab.scss";

type Props = {
  resourceDataStatistical: any,
};

const ResourceChartTab = (props: Props) => {
  const {resourceDataStatistical} = props;

  const renderStatistical = () => {
    return (
      <>
        <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Khóa học"
                value={get(resourceDataStatistical,'dataCourses.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataCourses.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Bài giảng"
                value={get(resourceDataStatistical,'dataLesson.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataLesson.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Bài kiểm tra"
                value={get(resourceDataStatistical,'dataTest.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataTest.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="SCORM/x API"
                value={get(resourceDataStatistical,'dataScormxAPi.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataScormxAPi.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Đa phương tiện"
                value={get(resourceDataStatistical,'dataMultimedia.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataMultimedia.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
          <Col sm={{ span: 4 }} xs={{ span: 24 }}>
            <Card bordered={false}>
              <Statistic
                className="card-statistical"
                title="Tài liệu tham khảo"
                value={get(resourceDataStatistical,'dataReferences.value', '')}
                precision={2}
                valueStyle={{ fontWeight: 500, fontSize: 30 }}
                formatter={(value) => (
                  <>
                    {value}<span className="percentage-difference">{get(resourceDataStatistical,'dataReferences.percent', '')}%<CaretUpOutlined style={{color: "#52C41A"}}/></span>
                  </>
                )}
              />
            </Card>
          </Col>
      </>
    )
  }
  return (
    <>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        {renderStatistical()}
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng khóa học theo hình thức xuất bản"  
            content={
              <BarStackedChart 
                data={barStackedChartData}
                showDataLabels={false}
                colors={["#FFC069", "#5CDBD3", "#69C0FF"]}
              />
            }
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng khóa học theo khoa"
            content={
              <BarChart 
                data={barChartNumberData}
                colors={["#69C0FF", "#FFC069"]}
                showLegend={false}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard 
            chartName="Số lượng bài giảng theo khoa"
            content={
              <BarChart 
                data={barChartNumberData}
                colors={["#69C0FF", "#FFC069"]}
                showLegend={false}
                hasTotal={true}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard 
            chartName="Số lượng bài kiểm tra"  
            content={
              <DonutChart 
                data={donutChartTestData}
                title=""
                height={300}
                innerSize="70%"
                size="80%"
                colors= {["#5CDBD3", "#FFC069", "#FF7875"]}
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard 
            chartName="Số lượng tài liệu tham khảo"  
            content={
              <DonutChart 
                data={donutChartReferencesData}
                title=""
                height={300}
                innerSize="70%"
                size="80%"
                colors= {["#5CDBD3", "#FFC069", "#FF7875"]}
                totalName="Tổng số"
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard 
            chartName="Số lượng SCORM & xAPI"  
            content={
              <DonutChart 
                data={donutChartScormData}
                title=""
                height={300}
                innerSize="70%"
                size="80%"
                colors= {["#5CDBD3", "#FFC069", "#FF7875"]}
                totalName="Tổng số"
              />
            }
          />
        </Col>
        <Col sm={{ span: 6 }} xs={{ span: 24 }} className="col-donut-chart">
          <StatisticalReportCard 
            chartName="Số lượng học liệu đa phương tiện"  
            content={
              <DonutChart 
                data={donutChartMultimediaData}
                title=""
                height={300}
                innerSize="70%"
                size="80%"
                colors= {["#5CDBD3", "#FFC069", "#FF7875"]}
                totalName="Tổng số"
              />
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ResourceChartTab;
