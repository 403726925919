import {Form, InputNumber, message, Modal, Radio, RadioChangeEvent, Typography} from 'antd'
import React, {useEffect, useState} from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import './index.scss'
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import {useForm} from "antd/es/form/Form";
import {FormInstance} from "antd/lib";
import dayjs from "dayjs";
import {get} from "lodash";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/vi';


const options = [
  {label: "Ngày", id: 1},
  {label: "Tuần", id: 2},
  {label: "Tháng", id: 3},
]

type SettingScheduleProps = {
  formInstanceWrapper?: FormInstance,
  dataConfig?: any,
}

const SettingSchedule = ({formInstanceWrapper, dataConfig}: SettingScheduleProps) => {
  const [form] = useForm()
  const [value, setValue] = useState({
    isScheduleByTeacher: false
  })
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenModalSetting, setIsOpenModalSetting] = useState(false)
  const [addValues, setAddValues] = useState({})
  const [selectStartDate, setSelectStartDate] = useState(get(dataConfig, 'schedule.openingDate', ''))
  const [selectEndDate, setSelectEndDate] = useState()
  dayjs.extend(customParseFormat);

  const handleChangeOptions = (e: RadioChangeEvent) => {
    setValue({
      ...value,
      isScheduleByTeacher: e.target.value
    })
  }


  const handleClose = () => {
    setIsOpen(false)
  }

  const handleCloseModalSetting = () => {
    setIsOpenModalSetting(false)
  }

  const onAddValue = (values: any) => {
    setAddValues({
      ...addValues,
      limit: parseInt(values.limit),
      typeLimit: values.typeLimit
    })
    handleClose()
    handleCloseModalSetting()
  }


  const updateFormWrapper = (value: { isScheduleByTeacher: boolean }, addValues: any) => {
    if (!value.isScheduleByTeacher && formInstanceWrapper) {
      formInstanceWrapper?.setFieldsValue({
        scheduleCourse: {
          limit: addValues.limit,
          typeLimit: addValues.typeLimit
        }
      })
    }

  }

  const handleSubmitForm = async () => {
    try {
      const values = !value.isScheduleByTeacher ?
        await form.getFieldsValue(['limit', 'typeLimit']) :
        get(dataConfig, 'isScheduleByTeacher', false) ? await form.getFieldsValue(['limit', 'typeLimit']) :
          await form.getFieldsValue(['limit', 'typeLimit'])
      onAddValue(values)


      if (!value.isScheduleByTeacher) {
        handleClose()
        handleCloseModalSetting()
      }

    } catch (err) {
      message.error('Nếu chọn theo giảng viên phải chọn ngày khai giảng')
      console.error(err)
    }


  }


  useEffect(() => {
    if (formInstanceWrapper && Object.keys(addValues).length > 0) {
      updateFormWrapper(value, addValues)
    }
  }, [value, formInstanceWrapper, addValues]);


  useEffect(() => {
    if (dataConfig && !dataConfig.isScheduleByTeacher) {
      form.setFieldsValue({
        'limit': get(dataConfig, 'limit', null),
        'typeLimit': get(dataConfig, 'typeLimit', 1),
      })
      formInstanceWrapper?.setFieldsValue({
        scheduleCourse: {
          limit: get(dataConfig, 'limit', null),
          typeLimit: get(dataConfig, 'typeLimit', 1)
        }
      })
    }
  }, [dataConfig]);


  return (
    <>
      <CustomCard title="Nhịp độ" className="wrapper-settings--card">
        <Form.Item name="scheduleCourse">
          <Form.Item className="form-item-setting" name="isScheduleByTeacher">
            <Radio.Group onChange={handleChangeOptions}>
              <div className="wrapper-radio">
                <Radio value={true}>
                  Theo giảng viên
                </Radio>
                <div className="message">
                  <Typography.Text className="sub-title">
                    Tiến độ khóa học sẽ được thiết lập bởi giảng viên. Lịch trình sẽ phải được thiết lập trước khi xuất
                    bản khóa
                    học.
                  </Typography.Text>
                </div>
              </div>
              <div className="wrapper-radio">
                <Radio value={false}>
                  Theo ngày đăng ký. <a className='link-setting' onClick={(e) => {
                  e.preventDefault()
                  if (!value.isScheduleByTeacher) {
                    setIsOpenModalSetting(true)
                  } else {
                    setIsOpenModalSetting(false)
                  }
                }}>
                  Thiết lập Nỗ lực mong chờ
                </a>
                </Radio>
                <div className="message">
                  <Typography.Text className="sub-title">
                    Tiến độ khóa học sẽ theo lịch trình đề xuất và phụ thuộc vào ngày người dùng bắt đầu học.
                  </Typography.Text>
                </div>
              </div>
            </Radio.Group>
          </Form.Item>
        </Form.Item>

      </CustomCard>
      <Modal forceRender title="Thiết lập nỗ lực mong chờ" okText="Lưu"
             cancelText="Hủy" open={isOpenModalSetting} onOk={handleSubmitForm} onCancel={handleCloseModalSetting}
             className="modal-setting--schedule">
        <Form form={form} className="form-setting--limit" initialValues={{limit: null, typeLimit: 1}}>
          <Form.Item name="limit" label="Giới hạn" className="form-item--limited">
            <InputNumber min={"0"}
                         className="form-input"
                         controls={false}/>
          </Form.Item>
          <Form.Item name="typeLimit" className="form-item--type">
            <FormItemDropdown options={options}/>
          </Form.Item>
        </Form>
      </Modal>

    </>
  );
};

export default SettingSchedule;
