import { Col, Form, Row, Spin, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent, useEffect, useState } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get } from "lodash";
import { getAddressDistrictsByProvinceId, getAddressWardsByDistrictId } from "../../../../../service/category-management/university-management";
import { useWatch } from "antd/es/form/Form";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên cơ sở đào tạo không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên cơ sở đào tạo"))
			}
			return Promise.resolve()
		}
	},
	code: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã cơ sở đào tạo không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã cơ sở đào tạo"))
			}
			return Promise.resolve()
		}
	},
	modalType: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn loại hình"))
			}
			return Promise.resolve()
		}
	},
	trainingType: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn loại cơ sở đào tạo"))
			}
			return Promise.resolve()
		}
	},
	province: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn Tỉnh/TP"))
			}
			return Promise.resolve()
		}
	},
	district: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn Quận/huyện"))
			}
			return Promise.resolve()
		}
	},
	ward: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn Phường/xã/thị trấn"))
			}
			return Promise.resolve()
		}
	},
	managementAgency: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn cơ quan quản lý trực tiếp"))
			}
			return Promise.resolve()
		}
	},
}

type FormCreateUniversityProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const FormCreateUniversity: FunctionComponent<FormCreateUniversityProps> = ({ form, onFinish, dataOptions,loadingForm }) => {
	const [dataDistricts, setDataDistricts] = useState([]);
	const [dataWards, setDataWards] = useState([]);
	const proviceData = useWatch("province", form);
	const districtData = useWatch("district", form);

	const handleChangeProvince = (e: any) => {
		// getDistricts(e);
		setDataWards([]);
		form.setFieldsValue({
			district: null,
			ward: null,
		})
	}

	const handleChangeDistrict = (e: any) => {
		form.setFieldsValue({
			ward: null,
		})
		// getWards(e);
	}

	// Get districts API
	const getDistricts = async (provinceId: string) => {
		try {
			await getAddressDistrictsByProvinceId(provinceId).then((res) => {
				setDataDistricts(res.data.data);
			})
		} catch (err) {
			console.error(err)
		}
	}

	// Get wards API
	const getWards = async (provinceId: string) => {
		try {
			await getAddressWardsByDistrictId(provinceId).then((res) => {
				setDataWards(res.data.data);
			})
		} catch (err) {
			console.error(err)
		}
	}

	// get districts by provice
	useEffect(() => {
		if(proviceData) {
			getDistricts(proviceData);
		} else {
			setDataDistricts([]);
		}
	}, [proviceData])

	// get wards by district
	useEffect(() => {
		if(districtData) {
			getWards(districtData);
		} else {
			setDataWards([]);
		}
	}, [districtData])
	
    return (
        <>
            <Form layout={"vertical"} form={form} onFinish={onFinish} name="createUniversity" initialValues={{ status : true}}>
				{loadingForm ? 
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					: 
					<Row gutter={[16,16]}>
						<Col span={24}>
							<Form.Item name="name" rules={[validations.name]} label="Tên cơ sở đào tạo">
								<FormInput placeholder="Nhập tên cơ sở đào tạo" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="englishName" label="Tên tiếng Anh">
								<FormInput placeholder="Nhập tên tiếng anh của cơ sở đào tạo" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="modalType" rules={[validations.modalType]} label="Loại hình">
								<FormItemDropdown options={get(dataOptions, 'dataTrainingType', '')} allowClear={true} placeholder="Chọn loại hình" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="code" rules={[validations.code]} label="Mã cơ sở đào tạo">
								<FormInput placeholder="Nhập mã cơ sở đào tạo" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="trainingType" rules={[validations.trainingType]} label="Loại cơ sở đào tạo">
								<FormItemDropdown options={get(dataOptions, 'dataModalType', '')} allowClear={true} placeholder="Chọn loại cơ sở đào tạo" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="province" rules={[validations.province]} label="Tỉnh/TP">
								<FormItemDropdown options={get(dataOptions, 'dataProvince', '')}
												onChange={(e) => handleChangeProvince(e)} placeholder="Chọn Tỉnh/TP" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="managementAgency" rules={[validations.managementAgency]} label="Cơ quan quản lý trực tiếp">
								<FormItemDropdown options={get(dataOptions, 'dataManagementAgency', '')} allowClear={true} placeholder="Chọn cơ quan quản lý trực tiếp" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="district" rules={[validations.district]} label="Quận/huyện">
								<FormItemDropdown options={dataDistricts}
												onChange={(e) => handleChangeDistrict(e)} placeholder="Chọn Quận/huyện" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="principal" label="Hiệu trưởng">
								<FormInput placeholder="Nhập tên hiệu trưởng" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="ward" rules={[validations.ward]} label="Phường/xã/thị trấn">
								<FormItemDropdown options={dataWards} placeholder="Chọn Phường/xã/thị trấn" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="address" label="Địa chỉ">
								<FormInput placeholder="Nhập địa chỉ" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="website" label="Website">
								<FormInput placeholder="Nhập website" />
							</Form.Item>
						</Col>
						<Col span={24} style={{display:"flex", justifyContent:"space-between"}}>
							<span>Trạng thái</span>
							<Form.Item name="status">
								<Switch defaultChecked />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
        </>
    )
}

export default FormCreateUniversity;