import {
  Card,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableData from "../../../../components/table-data/TableData";
import {
  getListStudentExam
} from "../../../../service/auto-score";
import { formatDate } from "../../../../utils/converDate";

type TableDetailScoreProps = {
  title?: string;
  idBlock?: string;
  setScore: any;
  paramStatus: any;
  setParamStatus: any;
  setIdCourse: any;
};
interface SearchType {
  search: string;
  skip: number;
  take: number;
  sort: string;
}

const TableDetailScore = ({
  title,
  idBlock,
  setScore,
  paramStatus,
  setParamStatus,
  setIdCourse,
}: TableDetailScoreProps) => {
  const params = useParams();
  const [accountDetailScore, setAccountDetailScore] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [headValue, setHeadValue] = useState<any>([]);

  const fetchData = async (filterParam: SearchType) => {
    try {
      const response = await getListStudentExam(
        filterParam,
        Number(params?.id)
      );
      const { data } = response.data;
      setAccountDetailScore(data?.mooc_score || []);
      setTotalPage(data?.mooc_score?.length || 0);

      setLoading(false);
      setScore({
        courseName: data?.mooc_course?.name,
        examName:
          data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence?.name,
      });
      setIdCourse(data?.id);
      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.mooc_course?.name,
        },
        {
          label: "Tên học liệu",
          value: data?.mooc_course_block?.mooc_course_unit?.name,
        },
        {
          label: "Tên bảng điểm",
          value: data?.name,
        },
        {
          label: "Số lượng sinh viên làm bài",
          value:  data?.mooc_score?.length,
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData({
      search: "",
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      sort: "desc",
    });
  }, [pageSize, pageNumber]);

  const dataColumnsDetailScore: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.date_of_birth &&
              formatDate(record?.auth_user?.auth_userprofile?.date_of_birth)}
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.auth_user?.email}</Typography.Text>;
      },
    },
    {
      title: "Điểm số",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div>
            {record?.point || 0}
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsDetailScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsDetailScore.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="px-6 pt-6 w-full">
          <Card>
            <Flex className="w-full" wrap="wrap">
              {headValue?.map((dataScore: any, index: number) => (
                <div
                  className={`${
                    index + 1 === headValue.length ? "" : "sm:border-r"
                  } px-4 w-full sm:w-1/2 md:w-1/4 mb-2 lg:!mb-0 xl:w-fit`}
                  key={index}
                >
                  <p className="w-full">{dataScore.label}</p>
                  <p className="font-medium text-lg">{dataScore?.value}</p>
                </div>
              ))}
            </Flex>
          </Card>
        </div>
        <TableData
          dataTable={accountDetailScore}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableDetailScore;
