import { Layout, message } from "antd";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableDetailScore from "./TableDetailScore";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";

const { Content } = Layout;
const imgPath = "/images/";

const DetailScore = () => {
  const navigate = useNavigate();
  const [score, setScore] = useState({
    courseName: "",
    examName: ""
  });
  const [paramStatus, setParamStatus] = useState<any>([]);
  const [idCourse, setIdCourse] = useState("");

  const params = useParams();

  const listBreadcrumb = [
    {
      title: "Danh sách khoá học",
    },
    {
      title: score.courseName,
    },
    {
      title: score.examName,
    },
    {
      title: "Quản lý chấm bài thi tự động",
    },
    {
      title: "Xem bảng điểm",
    },
  ];

  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={"Xem bảng điểm"}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <TableDetailScore
        idBlock={params?.id}
        setScore={setScore}
        paramStatus={paramStatus}
        setParamStatus={setParamStatus}
        setIdCourse={setIdCourse}
      />
    </Content>
  );
};

export default DetailScore;
