import { useCallback, useEffect, useState } from "react";
import StartScreen from "./components/StartScreen";
import ControlPanel from "./components/ControlPanel";
import QuizLayout from "./quiz";
import PauseScreen from "./components/PauseScreen";
import FinishScreen from "./components/FinishScreen";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResultQuizLayout from "./view-results";
import useKeyword from "../../../../../hooks/useKeyword";
import useModalConfirm from "../../../../../hooks/useModalConfirm";
import "./style.scss";
import { globalStore } from "../../../../../stores/stores";
import {
  continueExam,
  examMark,
  getExamDetails,
  getUnitDetails,
  pauseExam,
  submitExam,
} from "../../../../../service/learning-course";
import { isEmpty, toNumber } from "lodash";
import {
  ExamConfigs,
  FinishResult,
  GroupsExam,
  QuizProps,
  QuizRequest,
  ResultExam,
  UnitInfoProps,
} from "../../../../../types/course";
import {
  notifySuccess,
  notifyWarning,
} from "../../../../../utils/notification";
import {
  AutoResultOptions,
  ExamType,
  LayoutMode,
  TimeExpiredOption,
} from "../../../../../constants/course";
import { getResultsQuiz } from "../../../../../service/courses";
import { useDirty } from "../../../../../contexts/DirtyProvider";
import { useViewport } from "../../../../../hooks/useViewport";

interface QuizScreenProps {
  groupExams: GroupsExam[];
  onChangeMenuMode?: (v?: any) => void;
}

function QuizScreen({
  onChangeMenuMode = () => {},
  groupExams,
}: QuizScreenProps) {
  const { setDirty } = useDirty();
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const showConfirm = useModalConfirm();
  const { setIsFullscreenQuiz } = globalStore();

  // data of exam
  const blockId = toNumber(useKeyword("blockId"));
  const courseId = toNumber(useParams()?.id);
  const [blockInfo, setBlockInfo] = useState<UnitInfoProps>();
  const [configs, setConfigs] = useState<ExamConfigs>();
  const executionTimeExpiredOption = toNumber(
    configs?.actionConfig?.executionTimeExpiredOption
  );
  const showResultsCompletionOption = blockInfo?.quizConfigDTO
    ? JSON.parse(
        blockInfo?.quizConfigDTO?.evaluateConfig?.showResultsCompletionOption
      )
    : [];

  const [currentQuestion, setCurrentQuestion] = useState<QuizProps>();
  const [currentGroup, setCurrentGroup] = useState<GroupsExam>();
  const [groups, setGroups] = useState<GroupsExam[]>([]);
  const [quizzesFlat, setQuizzesFlat] = useState<QuizProps[]>([]);
  const [quizzesCompleted, setQuizzesCompleted] = useState<QuizRequest[]>([]);
  const [results, setResults] = useState<ResultExam>();

  // control
  const [isFinalFinish, setIsFinalFinish] = useState<boolean>(false);
  const [isExpandingTime, setIsExpandingTime] = useState<boolean>(false);
  const [layoutMode, setLayoutMode] = useState<number>(LayoutMode.SINGLE);
  const currentScreen = useKeyword("screen") || "train";
  const [finishResult, setFinishResult] = useState<FinishResult>();
  const [sessionId, setSessionId] = useState<string>("");
  const [allTimeToCompleted, setAllTimeToCompleted] = useState<number>(0);
  const [timeDeadline, setTimeDeadline] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [typeOfFinish, setTypeOfFinish] = useState<
    "userFinish" | "timeFinish" | ""
  >("");
  const [mode, setMode] = useState<
    "start" | "learn" | "end" | "pause" | "finish" | "result"
  >("start");
  const [quizzesDisabled, setQuizzesDisabled] = useState<number[]>([]); // Có tác dụng trong trường hợp đếm thời gian từng câu + làm bài tuần tự

  //pause
  const [isPausing, setIsPausing] = useState<boolean>(true);
  const [dateTimePause, setDateTimePause] = useState<number>(0);

  // Xử lý cho câu hỏi có thời gian riêng
  const isSequentiallyQuiz = configs?.generalConfig?.sequentiallyQuiz; // Bài quiz làm tuần tự tính & thời gian từng câu

  const [isExpand, setIsExpand] = useState(true);
  const [openFaceRecord, setOpenFaceRecord] = useState(true);

  const convertQuizToTrain = (quiz: any) => {
    return {
      ...quiz,
      questions: quiz?.answers ? JSON.parse(quiz?.answers) : null,
      settingGeneral: quiz?.settingGeneral
        ? JSON.parse(quiz?.settingGeneral)
        : null,
      settingHint: quiz?.settingHint ? JSON.parse(quiz?.settingHint) : null,
      settingPoint: quiz?.settingPoint ? JSON.parse(quiz?.settingPoint) : null,
      settingResponse: quiz?.settingResponse
        ? JSON.parse(quiz?.settingResponse)
        : null,
    };
  };

  const convertGroup = (group: GroupsExam) => {
    return {
      ...group,
      quiz:
        group?.quizzes?.length > 0
          ? group?.quizzes.map((item: any) => convertQuizToTrain(item))
          : [],
    };
  };

  const handleStart = async (type?: "start" | "retrain") => {
    if (blockId) {
      navigate(`${location.pathname}?blockId=${blockId}&screen=train`);
      setIsFinalFinish(false);
      if (type === "retrain") {
        setQuizzesCompleted([]);
      }
      setMode("learn")
      try {
        setLoading(true);
        // const res = await getExamDetails({
        //   courseId: courseId,
        //   blockId: blockId,
        // });
        // setLayoutMode(
        //   toNumber(
        //     res.data?.data?.config?.generalConfig?.formatQuiz || LayoutMode.FLAT
        //   )
        // );
        // const config: ExamConfigs = res.data?.data?.config;
        // setConfigs(config);
        const groupsExam: GroupsExam[] = groupExams.map((item: GroupsExam) =>
          convertGroup(item)
        );

        if (groupsExam) {
          setGroups(groupsExam);
          initialQuiz(groupsExam);

          setCurrentGroup(groupsExam[0]);
          setAllTimeToCompleted(600);
          if (groupsExam?.length)
            for (let i = 0; i < groupsExam?.length; i++) {
              setQuizzesFlat((prevState: QuizProps[]) => [
                ...prevState,
                ...groupsExam[i]?.quiz,
              ]);
            }

          setIsPausing(false);
          setMode("learn");
        }

        // bật mode full screen đối với các dạng bài kiểm tra & bài thi
        if (blockInfo?.examType && blockInfo?.examType !== ExamType.EXERCISE) {
          setIsFullscreenQuiz(true);
          onChangeMenuMode(false);
          document.documentElement.requestFullscreen();
        }
      } catch (err: any) {
        notifyWarning(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const initialQuiz = (groups: GroupsExam[]) => {
    if (groups && groups?.length > 0) {
      for (let i = 0; i < groups?.length; i++) {
        if (groups[i]?.quiz && groups[i]?.quiz?.length > 0) {
          setCurrentQuestion(groups[i]?.quiz[0]);
          break;
        }
      }
    }
  };
  const handleMinimizeRecord = useCallback(() => {
    setIsExpand(false);
  }, [isExpand]);
  const handlePause = () => {
    showConfirm({
      title: "Xác nhận tạm dừng làm bài",
      description: "Bạn có chắc chắn muốn tạm dừng làm bài?",
      okText: "Tạm dừng",
      cancelText: "Huỷ",
      type: "confirm",
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/pause-primary-icon.svg`}
        />
      ),
      onOk: () => pause(),
    });
  };

  const handleFinish = () => {
    showConfirm({
      title: "Xác nhận nộp bài",
      description: "Bạn có chắc chắn muốn nộp bài không?",
      okText: "Nộp bài",
      cancelText: "Huỷ",
      type: "confirm",
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/pause-primary-icon.svg`}
        />
      ),
      onOk: async () => {
        await finishExam("userFinish");
      },
    });
  };

  const finishExam = async (
    type: "userFinish" | "timeFinish",
    isCancelled?: boolean
  ) => {
    const payload = {
      blockId: blockId,
      quizRequests: quizzesCompleted,
      isCancelled: isCancelled,
    };

    try {
      let res;
      setLoading(true);
      // setFinishResult(res.data.data);
      setMode("finish");
      setIsPausing(true);
      setTypeOfFinish(type);
      setCurrentQuestion(undefined);
      setQuizzesCompleted([]);
      setQuizzesFlat([]);
      setGroups([]);
      setQuizzesDisabled([]);
      setIsExpandingTime(false);
      setDirty(false);
      if (type === "userFinish") {
        notifySuccess("Nộp bài thành công");
      }
    } catch (err: any) {
     
    } finally {
      setLoading(false);
    }
  };

  const handleMarkExam = async () => {
    await examMark({
      blockId: blockId,
      sessionId: sessionId,
    });
  };

  const pause = async () => {
    const payload = {
      blockId: blockId,
      currentQuizId: toNumber(currentQuestion?.id),
      quizRequests: quizzesCompleted,
    };

    try {
      const res = await pauseExam(payload);
      setFinishResult(res.data.data);
      setMode("pause");
      setIsPausing(true);
      setDateTimePause(Date.now());
    } catch (err) {}
  };

  const handleViewResult = async (ssId: string) => {
    try {
      setLoading(true);
      await handleMarkExam();
      navigate(`${location.pathname}?blockId=${blockId}&screen=result`);
      setMode("result");
      const res = await getResultsQuiz(ssId);
      const groupsExam: GroupsExam[] = res.data?.data?.groups;
      const results = res.data?.data?.result;

      if (results) {
        setLayoutMode(
          toNumber(
            res.data?.data?.config?.generalConfig?.formatQuiz || LayoutMode.FLAT
          )
        );
        const config: ExamConfigs = res.data?.data?.config;
        setConfigs(config);
        setGroups(groupsExam);
        initialQuiz(groupsExam);
        setSessionId(res.data?.data?.sessionId);
        setResults(res.data?.data?.result);
        for (let i = 0; i < groupsExam?.length; i++) {
          setQuizzesFlat((prevState: QuizProps[]) => [
            ...prevState,
            ...groupsExam[i]?.quiz,
          ]);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleContinue = async () => {
    if (sessionId && blockId) {
      setIsPausing(false);
      setMode("learn");
      const res = await continueExam({
        sessionId: sessionId,
        blockId: blockId,
      });
      setAllTimeToCompleted(res?.data?.data?.timeToCompleted);
      const continueGroup = res.data?.data?.groups;

      let currentQuiz: QuizProps | undefined = undefined;
      if (continueGroup && continueGroup?.length > 0) {
        for (let i = 0; i < continueGroup?.length; i++) {
          const currQuizzes = continueGroup[i]?.quiz;
          if (currQuizzes && currQuizzes?.length > 0) {
            for (let j = 0; j < currQuizzes?.length; j++) {
              if (currentQuestion?.id === currQuizzes[j]?.id) {
                currentQuiz = currQuizzes[j];
                break;
              }
            }
          }
        }
      }
      if (currentQuiz) {
        setCurrentQuestion(currentQuiz);
      }
    }
  };

  const handleFinishSingleQuiz = () => {
    handleEnableNextQuiz();
    handleNextQuiz();
    if (currentQuestion?.id === quizzesFlat[quizzesFlat?.length - 1]?.id) {
      setQuizzesDisabled(quizzesFlat.map((item: QuizProps) => item.id));
      finishExam("timeFinish");
    }
  };

  const handleEnableNextQuiz = () => {
    if (
      isSequentiallyQuiz &&
      quizzesCompleted?.some(
        (quiz: QuizRequest) => quiz?.quizId === currentQuestion?.id
      )
    ) {
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );

      if (currentIndexQuiz !== -1) {
        const temp = [...quizzesDisabled];
        temp[currentIndexQuiz + 1] = 0;

        setQuizzesDisabled(temp);
      }
    }
  };

  const handleNextQuiz = () => {
    switch (layoutMode) {
      case LayoutMode.SINGLE:
        if (quizzesFlat && currentQuestion) {
          // next quiz
          const currentIndexQuiz = quizzesFlat?.findIndex(
            (quiz: QuizProps) => quiz.id === currentQuestion?.id
          );
          if (currentIndexQuiz !== -1) {
            const nextQuiz = quizzesFlat[currentIndexQuiz + 1];
            if (nextQuiz) {
              setCurrentQuestion(nextQuiz);
              // xử lý cho dạng tuần tự
              if (isSequentiallyQuiz) {
                const newQuizzesDisabled = quizzesFlat?.map((quiz: QuizProps) =>
                  quiz?.id === nextQuiz?.id ? 0 : quiz.id
                );
                setQuizzesDisabled(newQuizzesDisabled);
              }
            }
          }
        }
        break;
      case LayoutMode.GROUP:
        const currentIndexGroup = groups?.findIndex(
          (group: GroupsExam) => group.id === currentGroup?.id
        );
        if (currentIndexGroup !== -1) {
          const nextGroup = groups[currentIndexGroup + 1];
          if (nextGroup) setCurrentGroup(nextGroup);
        }

        break;
      default:
        break;
    }
  };

  const handlePreviousQuiz = () => {
    switch (layoutMode) {
      case LayoutMode.SINGLE:
        if (quizzesFlat && currentQuestion) {
          // prev quiz
          const currentIndexQuiz = quizzesFlat?.findIndex(
            (quiz: QuizProps) => quiz.id === currentQuestion?.id
          );

          if (currentIndexQuiz !== -1) {
            const prevQuiz = quizzesFlat[currentIndexQuiz - 1];
            if (prevQuiz) setCurrentQuestion(prevQuiz);
          }
        }
        break;
      case LayoutMode.GROUP:
        const currentIndexGroup = groups?.findIndex(
          (group: GroupsExam) => group.id === currentGroup?.id
        );

        if (currentIndexGroup !== -1) {
          const prevGroup = groups[currentIndexGroup - 1];
          if (prevGroup) setCurrentGroup(prevGroup);
        }

        break;
      default:
        break;
    }
  };

  const disableNextBtn = () => {
    if (layoutMode === LayoutMode.SINGLE) {
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );

      const nextQuiz = quizzesFlat[currentIndexQuiz + 1];

      return isSequentiallyQuiz
        ? quizzesDisabled?.includes(nextQuiz?.id)
        : currentQuestion?.id === quizzesFlat[quizzesFlat?.length - 1]?.id;
    }

    return currentGroup?.id === groups[groups?.length - 1]?.id;
  };

  const disablePrevBtn = () => {
    if (layoutMode === LayoutMode.SINGLE)
      return isSequentiallyQuiz
        ? true
        : currentQuestion?.id === quizzesFlat[0]?.id;
    return currentGroup?.id === groups[0]?.id;
  };

  const handleChangeFieldValues = (values: QuizRequest[]) => {
    setQuizzesCompleted(values);
    setDirty(true);
  };

  const onExpandExamTime = () => {
    const expandTime = toNumber(
      configs?.actionConfig?.executionTimeExpiredValue
    );
    if (expandTime > 0) {
      setIsPausing(false);
      setMode("learn");
      setAllTimeToCompleted(expandTime * 60);
    }
  };

  const handleChangeQuizPanel = (quiz: QuizProps) => {
    setCurrentQuestion(quiz);

    const targetElement = document.getElementById(`quest-${quiz.id}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" }); // scroll tới vị trí câu hiện tại
    }

    if (isSequentiallyQuiz && quiz?.id !== currentQuestion?.id) {
      setQuizzesDisabled(
        quizzesFlat?.map((q: QuizProps) => (q?.id === quiz?.id ? 0 : q?.id))
      );
    }
  };

  const onTimeEnd = () => {
    if (isExpandingTime) {
      // Trường hợp hết thời gian nới
      setIsFinalFinish(true);
      finishExam("timeFinish");
    } else {
      switch (executionTimeExpiredOption) {
        // Hết thời gian chính => tự động nộp bài
        case TimeExpiredOption.AUTO:
          finishExam("timeFinish");
          break;
        // Hết thời gian chính => nới
        case TimeExpiredOption.CONTINUE:
          setMode("finish");
          setIsPausing(true);
          setTypeOfFinish("timeFinish");
          setIsExpandingTime(true);
          break;
        // Hết thời gian chính => huỷ bài làm
        case TimeExpiredOption.CANCEL:
          finishExam("timeFinish", true);
          setMode("finish");
          setIsPausing(true);
          setTypeOfFinish("timeFinish");
          break;
        default:
          break;
      }
    }
  };

  const renderTrainLayout = () => {
    switch (mode) {
     
      case "learn":
        return (
          <QuizLayout
            data={currentQuestion}
            quizzesDisabled={quizzesDisabled}
            loading={loading}
            layout={layoutMode}
            configs={configs}
            groups={groups}
            currentGroup={currentGroup as GroupsExam}
            isPausing={isPausing}
            quizzesCompleted={quizzesCompleted}
            quizzes={quizzesFlat}
            disableNextBtn={disableNextBtn()}
            disablePrevBtn={disablePrevBtn()}
            onNext={handleNextQuiz}
            onPrevious={handlePreviousQuiz}
            onPause={handlePause}
            onFinish={handleFinish}
            onChange={handleChangeFieldValues}
            onFinishSingleQuiz={handleFinishSingleQuiz}
          />
        );
      case "result":
        return (
          <ResultQuizLayout
            groups={groups}
            currentGroup={currentGroup as GroupsExam}
            layout={layoutMode}
            configs={configs}
            results={results}
            loading={loading}
            data={currentQuestion}
            quizzes={quizzesFlat}
            sessionId={sessionId}
            onBack={() => {
              setMode("finish");
              navigate(`${location.pathname}?blockId=${blockId}&screen=train`);
            }}
            onChangeHistory={(ssId: string) => handleViewResult(ssId)}
          />
        );
      case "pause":
        return (
          <PauseScreen
            timeDeadline={timeDeadline}
            dateTimePause={dateTimePause}
            onContinue={handleContinue}
          />
        );
      case "finish":
        return (
          <FinishScreen
            isFinalFinish={isFinalFinish}
            type={typeOfFinish}
            loading={loading}
            config={configs}
            remainingRetryNumber={
              finishResult?.remainingRetryNumber ||
              blockInfo?.remainingRetryNumber
            }
            waitingTimeForNextExecution={
              finishResult?.waitingTimeForNextExecution ||
              blockInfo?.waitingTimeForNextExecution
            }
            timeTraining={
              finishResult?.totalTrainTime || blockInfo?.totalTimeTrain
            }
            isAbleToRetry={
              finishResult?.isAbleToRetry || blockInfo?.isAbleToRetry
            }
            isShowResult={
              showResultsCompletionOption?.includes(
                AutoResultOptions.SHOW_VIEW_RESULT_BTN
              ) || blockInfo?.isShowResult
            }
            answerSendTime={
              finishResult?.answerSendTime || blockInfo?.answerSendTime
            }
            onRetrain={() => handleStart("retrain")}
            onViewResult={() => handleViewResult(sessionId)}
            onContinue={onExpandExamTime}
          />
        );
      default:
        break;
    }
  };

  const renderContainer = () => {
    switch (currentScreen) {
      case "train":
        return (
          <div className="structure-content flex">
            <div className="train-layout flex-1">{renderTrainLayout()}</div>

            {(mode === "learn" || mode === "result" || mode === "pause") && (
              <>
                <ControlPanel
                  showTime={allTimeToCompleted > 0}
                  quizzesDisabled={quizzesDisabled}
                  quizzesCompleted={quizzesCompleted}
                  totalQuiz={quizzesFlat?.length}
                  timeToCompleted={allTimeToCompleted}
                  currentQuestion={currentQuestion}
                  groups={groups}
                  pause={isPausing}
                  onTimeEnd={onTimeEnd}
                  onChange={handleChangeQuizPanel}
                />
              </>
            )}
          </div>
        );
      case "result":
        return (
          <div className="structure-content flex">
            <div className="train-layout flex-1">{renderTrainLayout()}</div>
            <ControlPanel
              mode="result"
              showTime={false}
              showCountCompleted={showResultsCompletionOption?.includes(
                AutoResultOptions.SHOW_CORRECT_BY_GROUP
              )}
              showComplete={!isEmpty(results)}
              totalQuiz={quizzesFlat?.length}
              currentQuestion={currentQuestion}
              groups={groups}
              results={results}
              onChange={handleChangeQuizPanel}
            />
          </div>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullscreenQuiz(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      setOpenFaceRecord(false);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    if (isSequentiallyQuiz) {
      setQuizzesDisabled(
        quizzesFlat.map(
          (item: QuizProps) => (item.id === currentQuestion?.id ? 0 : item.id) // disable toàn bộ quiz ngoại trừ câu đầu
        )
      );
    }
  }, [isSequentiallyQuiz]);

  useEffect(() => {
    // enable câu tiếp theo và disable các câu đằng trước
    handleEnableNextQuiz();
  }, [isSequentiallyQuiz, quizzesCompleted]);

  useEffect(()=>{
    handleStart("start");
  },[])

  useEffect(() => {
    // Get unit info trước khi start
    const getUnitInfo = async () => {
      setLoading(true);
      setDirty(false);
      if (blockId && isEmpty(blockInfo)) {
        try {
          const res = await getUnitDetails(blockId);
          const blockIf = res.data.data;
          setBlockInfo(blockIf);
          setSessionId(blockIf?.sessionId);
          // if (blockIf?.complete) {
          //   setMode("finish");
          //   setTypeOfFinish(
          //     blockIf?.submitTime === 0 ? "userFinish" : "timeFinish"
          //   );
          // }

          //  nếu là bài tập mở và không giới hạn thời gian => view
          if (
            blockIf?.examType === ExamType.EXERCISE && // là loại bài tập
            blockIf?.quizConfigDTO?.actionConfig?.numberActionEvaluate ===
              null && // không giới hạn số lần làm bài
            blockIf?.quizConfigDTO?.evaluateConfig?.recordResults === null // không bật ghi nhận kết quả vào thành tích
          ) {
            handleStart("start");
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else setLoading(false);
    };
    getUnitInfo();

    return () => setDirty(false);
  }, [blockId]);

  useEffect(() => {
    if (width < 1200) {
      onChangeMenuMode(false);
    }
  }, [width]);

  return <div className={`course-structure mb-5`}>{renderContainer()}</div>;
}

export default QuizScreen;
