import { Button, Card, Flex, Layout, message } from "antd";
import PageHeaderCommon from "../../../../../../components/page-header-common/PageHeaderCommon";
import TableReportCheckInDetail from "./TableReportCheckInDetail";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../../../../config/routes";
import {
  getListReportDocumentAttendance,
  updateReportSettingAttendance,
} from "../../../../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../../../../stores/stores";

const { Content } = Layout;
const imgPath = "/images/";

const ReportCheckInDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [report, setReport] = useState({
    title: "",
    idReport: "",
  });
  const [paramStatus, setParamStatus] = useState<any>([]);
  const [idCourse, setIdCourse] = useState("");
  const [isViewHistory, setIsViewHistory] = useState(false);
  const [isAction, setIsAction] = useState(false);

  const pathName = location.pathname;
  const isView = pathName.includes(routesConfig.reportCheckInDetail);
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const { setTabAttendance } = useStudyManagementStore((state) => state);

  const listBreadcrumb = [
    {
      title: "Quản lý khoá học",
    },
    {
      title: "Danh sách báo cáo",
    },
    {
      title: report?.title,
    },
  ];

  const GroupButton: any = () => {
    const handleSaveReportCheckIn = async () => {
      try {
        await updateReportSettingAttendance(report?.idReport, {
          attendance: paramStatus,
          // name: 'Bao cao 3'
        });
        messageApi.open({
          type: "success",
          content: `Lưu thành công ${report?.title}`,
        });
      } catch (error) {
        messageApi.open({
          type: "error",
          content: `Lưu thất bại ${report?.title}`,
        });
      }
    };
    return (
      <Flex
        gap="small"
        justify="flex-end"
        className="w-full lg:w-2/3 xl:w-1/2 m-auto my-4"
      >
        {isViewHistory ? (
          <Button onClick={() => setIsViewHistory(false)}>Thoát chế độ</Button>
        ) : (
          <Button onClick={() => setIsViewHistory(true)}>
            Xem lại lịch sử
          </Button>
        )}
        {!isViewHistory && isAction && (
          <Button type="primary" onClick={handleSaveReportCheckIn}>
            Lưu
          </Button>
        )}
      </Flex>
    );
  };

  const handleBack = () => {
    if (idCourse) {
      setTabAttendance("3");
      navigate(
        `/management-study/${routesConfig.settingRemindCheckInDetail}/${idCourse}`
      );
    } else {
      navigate(-1);
    }
  };
  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={report?.title}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        headerGroupButton={true}
        childrenGroupButton={isView ? "" : <GroupButton />}
        breadcrumb={true}
        handleBack={handleBack}
      />
      <TableReportCheckInDetail
        idDocument={params?.id}
        setReport={setReport}
        paramStatus={paramStatus}
        setParamStatus={setParamStatus}
        setIdCourse={setIdCourse}
        isViewHistory={isViewHistory}
        setIsAction={setIsAction}
      />
    </Content>
  );
};

export default ReportCheckInDetail;
