import { FunctionComponent, useEffect, useState, useRef } from "react";
import "./index.scss";
import DetailInfo from "../../../detail/components/detail-info/DetailInfo";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import { HistoryOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
// import { DetailContent } from "../components/detail-content/DetailContent";
import UserDocument from "../../../detail/components/user-document/UserDocument";
import { useAppStore } from "../../../../stores/stores";
import { get } from "lodash";
import { getAccountDetail } from "../../../../service/list-account-users/listAccountUsersApi";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import PlanInformation from "../../../../components/course-construction/assign-course-construction/plan-information";
import DetailedAssignment from "../../../../components/course-construction/assign-course-construction/detailed-assignment";
import BrowsingResults from "../../../../components/course-construction/assign-course-construction/browsing-results";
import { getDetailCourse } from "../../../../service/assign-course-construction";
import { DetailCourseData } from "../../../../types/assign-course-construction";
import ListParticipants from "../components/list-participants/index";
import ResultShot from "../components/result-shot";
import {
  getSurveyById,
  getResult,
} from "../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { DataResult } from "../../../../types/survey-managment/listSurvey";
import HighCharts from "../components/high-chart/Highcharts";
import htmlToImage from "html-to-image";
import { toPng, toJpeg } from "html-to-image";
import ResultChart from "../components/result-chart/index";
import ResultSynthesis from "../components/result-synthesis/index";

const { Content } = Layout;
const imgPath = "/images/";

const SurveyDetail: FunctionComponent = () => {
  const location = useLocation();

  const [detailData, setDetailData] = useState<any>({
    appraisedDate: "",
    appraisers: [],
    approveName: "",
    approveUserId: 0,
    approvedDate: "",
    assigners: [],
    description: "",
    id: 0,
    name: "",
    isApproved: false,
    isAssigned: false,
    sections: [],
  });
  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });
  const params = useParams();
  const [dataResult, setDataResult] = useState<DataResult[]>([]);
  const getDetailCourseData = async (id: any) => {
    await getSurveyById(id).then((res) => {
      const data = res.data.data;
      setDetailData(data);
    });
  };
  // const getResultSurvey = async () => {
  //   if (params && params.id) {
  //     await getResult(params.id).then((res) => {
  //       setDataResult(res.data);
  //       console.log(res.data);
  //     });
  //   } else {
  //     // Xử lý khi params hoặc params.id không tồn tại
  //   }
  // };
  useEffect(() => {
    getDetailCourseData(params.id);
    //getResultSurvey();
  }, []);
  const ref = useRef<HTMLDivElement>(null); // Sử dụng useRef với kiểu HTMLDivElement

  const onButtonClick = () => {
    if (ref.current === null) {
      return;
    }
    console.log(ref.current);
    toPng(ref.current)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "hinh-anh.png"; // Tên file khi lưu
        // Thêm liên kết vào body và kích hoạt việc tải xuống
        document.body.appendChild(link);
        link.click();
        // Sau khi tải xuống, loại bỏ liên kết khỏi body
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.error("Không thể chuyển đổi HTML sang hình ảnh:", err);
      });
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Danh sách tham gia",
      children: (
        <div className="wrapper">
          <section className="detail-account detail-course">
            <div className="form-detail">
              <ListParticipants />
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "2",
      label: "Biểu đồ kết quả",
      children: (
        <>
          <div className="wrapper detail-course-construction">
            <section id="form-detail-chart" className="detail-account">
              <div className="form-detail-chart">
                <ResultChart />
              </div>
            </section>
          </div>
        </>
      ),
    },
    {
      key: "3",
      label: "Thống kê kết quả",
      children: (
        <div className="wrapper browsing-results">
          <section className="detail-account">
            <div className="form-detail">
              <ResultSynthesis refChild={ref} />
              {/* <ExportCSV /> */}

              {/* <ResultChartRender id={2} /> */}
            </div>
          </section>
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Khảo sát nhu cầu đạo tạo",
    },
    {
      title: (
        <a href={`/survey-training/${routesConfig.surverList}`}>
          Tổng hợp kết quả khảo sát
        </a>
      ),
    },
    {
      title: detailData.surveyName,
    },
  ];

  return (
    <div className="survey-managment-synthesis">
      <Content>
        <PageHeader
          title={detailData.surveyName}
          subTitle="Tổng hợp kết quả "
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          {location.state.tab == 1 ? (
            <Tabs defaultActiveKey="1" items={items} />
          ) : (
            <Tabs defaultActiveKey="2" items={items} />
          )}
        </div>
      </Content>
    </div>
  );
};

export default SurveyDetail;
