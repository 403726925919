import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex, Form, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import SelectUiCard from "../../../../../../../components/select-ui";
import "./filter-group.scss";

type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
  optionSelectTag: any;
  setDataFilter: any;
  dataFilter: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  showFilter,
  clearFilter,
  onFinish,
  optionSelectTag,
  setDataFilter,
  dataFilter,
}) => {
  const [form] = useForm();
  const onChangeShareReport = (val: string) => {
    setDataFilter(val);
  };

  const onChangeDate = (value: any, name: string) => {
    console.log(value);

    setDataFilter({ ...dataFilter, [name]: value });
  };
  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""} !pl-0`}>
        <Form
          name="formFilterMinistries"
          onFinish={onFinish}
          form={form}
          className="flex-wrap flex"
        >
          <Flex justify="end" className="w-full lg:w-4/5 pr-3">
            <Form.Item name="rangeDate">
              <DatePicker.RangePicker
                className="font-medium w-full lg:w-1/3"
                placeholder={["Bắt đầu", "Kết thúc"]}
                onChange={(date, dateString: any) =>
                  onChangeDate(dateString, "date")
                }
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <SelectUiCard
              className="font-medium w-full lg:w-1/3 px-2"
              name="school"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectTag}
              mode="multiple"
              placeholder="Trường"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/3 pr-2"
              name="address"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectTag}
              mode="multiple"
              placeholder="Địa chỉ"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/3 pr-2"
              name="level"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectTag}
              mode="multiple"
              placeholder="Trình độ"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/3"
              name="favors"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectTag}
              mode="multiple"
              placeholder="Sở thích"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
          </Flex>

          <Flex className="w-full lg:w-1/5">
            <Button
              className="btn btn-primary filter-btn mr-2 lg:mr-0"
              htmlType="submit"
            >
              <FilterOutlined />
              <Typography.Text>Lọc</Typography.Text>
            </Button>
            <Button
              className="btn btn-outlined clear-filter-btn"
              onClick={() => clearFilter(form)}
            >
              <DeleteOutlined />
              <Typography.Text>Xóa bộ lọc</Typography.Text>
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
