import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import PageHeader from "../../../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import "./index.scss";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Popconfirm,
  Space,
  Switch,
  TableColumnsType,
  TableProps,
  Typography,
  Modal,
} from "antd";
import FilterTable from "../filter-table/FilterTable";
import DrawerCustom from "../../../../../components/drawer/Drawer";
import {
  DataType,
  DataSchool,
} from "../../../../../types/survey-managment/listSurvey";
import {
  CloseOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Link, useSearchParams } from "react-router-dom";
import TableData from "../../../../../components/table-data/TableData";
import { HelpService } from "../../../../../service/helper.service";
import { routesConfig } from "../../../../../config/routes";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  getListSurvey,
  getListParticipant,
} from "../../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { FilterParticipant } from "../../../../../types/survey-managment/listSurvey";
import { getUniversity } from "../../../../../service/common";
import TableParticipant from "../table-data/TableData";
type ListUserProps = {
  title?: string;
};

const ListParticipants: FunctionComponent<ListUserProps> = ({ title }) => {
  const helpService = new HelpService();

  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [accountUsers, setAccountUsers] = useState<DataType[]>([]);
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
    dataStatus: [
      {
        id: 0,
        label: "Bản nháp",
      },
      {
        id: 1,
        label: "Chờ duyệt",
      },
      {
        id: 2,
        label: "Đã xuất bản",
      },
      {
        id: 3,
        label: "Kết thúc",
      },
    ],
    dataType: [
      {
        id: 0,
        label: "Tự triển khai",
      },
      {
        id: 1,
        label: "Hợp tác xây dựng",
      },
    ],
  });
  const [dataFilter, setDataFilter] = useState<FilterParticipant>({
    repDate: null,
    school: null,
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [dataSchool, setDataSchool] = useState<DataSchool[]>([]);
  const [form] = Form.useForm();
  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa khóa học",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa khóa học",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];
  const status = [
    {
      id: Number(0),
      value: "Bản nháp",
    },
    {
      id: 1,
      value: "Chờ duyệt",
    },
    {
      id: 2,
      value: "Đã xuất bản",
    },
    {
      id: 3,
      value: "Kết thúc",
    },
  ];
  const type = [
    {
      id: 0,
      value: "Tự triển khai",
    },
    {
      id: 1,
      value: "Hợp tác xây dựng",
    },
  ];
  const paramsId = useParams();
  const formatDate = (dateString: any) => {
    const dateObj = dateString.split("T")[0];
    const arrDate = dateObj.split("-");

    // Lấy ra ngày, tháng và năm
    const ngay = arrDate[2];
    const thang = arrDate[1]; // Lưu ý: getMonth() trả về index của tháng (từ 0 đến 11)
    const nam = arrDate[0];

    // Định dạng lại chuỗi ngày tháng năm
    const ngayThangNam = ngay + "/" + thang + "/" + nam;

    return ngayThangNam;
  };
  const fetchData = async () => {
    const data = {
      id: Number(paramsId.id),
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      repDateFrom: !isEmpty(dataFilter.repDate?.[0])
        ? new Date(dataFilter.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(dataFilter.repDate?.[1])
        ? new Date(dataFilter.repDate?.[1]).toISOString()
        : "",
      school: !isEmpty(dataFilter.school) ? dataFilter.school : null,
    };
    await getListParticipant(data).then((res) => {
      const { data, totalRecords, pageSize, pageNumber } = res.data.data;
      console.log(res.data.data);
      setPageNumber(pageSize);
      setPageSize(pageNumber);
      setTotalRecords(totalRecords);
      const dataUser = data.map((item: any) => {
        return {
          id: item.id,
          surveyId: item.surveyId,
          email: item.email,
          participantId: item.participantId,
          name: item.name,
          university: item.nameSchool,
          participationEnd: formatDate(
            item.participationEnd ? item.participationEnd.split("T")[0] : ""
          ),
          themeType: item.themeType,
        };
      });

      setAccountUsers(dataUser);
      setLoading(false);
    });
  };
  const getUniversityList = async () => {
    await getUniversity().then((res: any) => {
      const universityFormat = res.data.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setDataSchool(res.data.data);
    });
  };
  useEffect(() => {
    fetchData();
    getUniversityList();
  }, []);

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên người trả lời khảo sát",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 20,
    },

    {
      title: "Trường đại học",
      dataIndex: "university",
      key: "university",
      width: 20,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
    },
    {
      title: "Ngày trả lời",
      dataIndex: "participationEnd",
      key: "participationEnd",
      width: 20,
    },

    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        console.log(record);
        const location = {
          id: `${record.participantId}`,
          idTheme: `${record.themeType}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(`2/${location.idTheme}/${location.id}`, {
                    state: { record, mode: "view" },
                  })
                }
              >
                <EyeOutlined />
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleClose = () => {
    setIsOpened(false);
    form.resetFields();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
    }
  };

  const handleSubmitSearch = () => {
    // if (searchValue === "") {
    //   setPageNumber(1);
    // }
    fetchData();
  };

  const clearFilter = () => {
    form.resetFields();
    setDataFilter({
      repDate: null,
      school: null,
    });
  };
  const handleChangeFilter = (value: any, name: string) => {
    setDataFilter({ ...dataFilter, [name]: value });
  };
  const handleFinish = (values: any) => {
    setLoading(true);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  return (
    <Layout className="page-header-group survey-managment-synthesis">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUser") {
            const { createUser } = forms;

            //onFinishCreate(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            {/* <div className="table-header--left">
              <PageHeader listBreadcrumb={listBreadcrumb} />
            </div> */}
            <FilterTable
              handleOpen={handleOpenCreate}
              title={""}
              dataExport={accountUsers}
              searchValue={searchValue}
              filterData={dataFilter}
              setFilterData={handleChangeFilter}
              handleChangeInput={handleChangeInput}
              handleSubmitSearch={handleSubmitSearch}
              clearFilter={clearFilter}
              form={form}
            />
          </div>
        </div>
        <div className="list-participant">
          <TableParticipant
            dataTable={accountUsers}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            // scroll={{ x: 200, y: 100 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default ListParticipants;
