import { ArrowLeftOutlined, MenuFoldOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import SurveyListCore from "./survey-list-core/survey-list-core";
import { useAddLesson } from "../../../../../stores/stores";
import SurvyListQuestion from "./survy-list-question/survy-list-question";
import { QuestionProgressModeClick, TypeCreateQuestion } from "../../../../../constants";
import { addUnitBank } from "../../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz";
import { useLocation } from "react-router-dom";

var indexDB = require("idbcache").default;

type SurvyListProps = {
  activeHandleSave: () => void;
};

const SurvyList = (props: SurvyListProps) => {
  const { flagCourseEditing, toggleFlagCourseEditing } = useAddLesson((state) => state);
  const reviewList = useAddLesson((state) => state.reviewList);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);
  const modeClick = useAddLesson((state) => state.modeClick);
  const location = useLocation();
  const [currentCourse, setCurrentCourse] = useState<any>();
  const [dataLessonId, setDataLessonId] = useState<number>(0);
  const [tab, setTab] = useState("2");
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
  const [bankCodeValue, setBankCodeValue] = useState<string>('');

  useEffect(() => {
    indexDB.get("current-course").then((val: any) => {
      setCurrentCourse(JSON.parse(val));
    });
  }, []);

  useEffect(() => {
    setCurrentCourse({ id: reviewList.id, name: reviewList.name });
  }, [reviewList]);

  const addToBank = async (dataReq: any) => {
    try {
      const response = await addUnitBank(dataReq)
      const { codeStatus } = response.data
      if (codeStatus === 200) {
        message.success("Lưu vào ngân hàng đề thành công")
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handlePrevTab = () => {
    if (modeClick === QuestionProgressModeClick.INPROGRESS) {
      confirmChageTabMessage();
    } else {
      setTab("1");
    }
  };

  const handleChangeText = (e: any) => {
    setBankCodeValue(e.target.value);
  };


  const handleSaveQuizToBankOk = async () => {
    if (bankCodeValue) {
      const dataReq = {
        unitId: location.state?.id,
        code: bankCodeValue
      }
      addToBank(dataReq);
      setBankCodeValue("")
      setIsSaveModalOpen(false);

    } else {
      message.error("Vui lòng nhập mã câu hỏi!");
      return Promise.reject();
    }
  };

  const renderContentByTab = () => {
    switch (tab) {
      case "1":
        return (
          <div className="tab1">
            <div className="block-header text-center pr-10">
              <Typography.Title level={5}>Danh sách đánh giá</Typography.Title>
            </div>
            <div className="form-detail">
              <SurveyListCore tab={tab} setTab={setTab} setDataLessonId={setDataLessonId}
                setIsShowModal={setIsSaveModalOpen} />
            </div>
          </div>
        );
      case "2":
        return (
          <div className="tab2">
            <div className="block-header pr-10">
              {typeCreateQuestion === TypeCreateQuestion.COURSEWARE ? (<ArrowLeftOutlined className="arrowleft-icon ml-4 mr-4 cursor-pointer" onClick={handlePrevTab} />) : ('')}
              <Typography.Title level={5}>{currentCourse ? currentCourse.name : 'Chưa có tên'}</Typography.Title>
            </div>
            <div className="form-detail">
              <SurvyListQuestion tab={tab} dataLessonId={dataLessonId} />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const confirmChageTabMessage = () => {
    Modal.confirm({
      title: 'Chưa lưu thay đổi',
      icon: <SaveOutlined />,
      content: 'Học liệu có những thay đổi chưa được lưu. Bạn có muốn lưu lại trước khi chuyển sang học liệu khác ?',
      className: "modal-custom success",
      okButtonProps: {
        className: "btn",
      },
      cancelButtonProps: {
        className: "btn btn-outlined",
      },
      okText: 'Lưu thay đổi',
      cancelText: "Đóng",
      onOk: () => {
        props.activeHandleSave();
        setTab("1");
        Modal.destroyAll();
      },
      onCancel: () => {
        Modal.destroyAll();
      },
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button onClick={() => {
            setTab("1");
            Modal.destroyAll();
          }}>
            Tiếp tục
          </Button>
          <OkBtn />
        </>
      ),
    });
  };


  return (
    <section>
      <div className={`tabs-heading editor-course-editing ${flagCourseEditing ? "" : "tab-closed w-50px"}`}>
        <div style={{ visibility: flagCourseEditing ? "visible" : "hidden" }}>
          {renderContentByTab()}
        </div>
        <MenuFoldOutlined className="editor-course-editing-move-btn" rotate={flagCourseEditing ? 0 : 180} onClick={toggleFlagCourseEditing} />
      </div>
      <Modal
        className="save-modal"
        title="Lưu vào ngân hàng đề"
        open={isSaveModalOpen}
        onOk={handleSaveQuizToBankOk}
        onCancel={() => {
          setIsSaveModalOpen(false);
        }}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div className="save-modal-input">
          <label>Mã đánh giá: </label>
          <Input
            onChange={handleChangeText}
            value={bankCodeValue}
            placeholder="Mã đánh giá"
          />
        </div>
      </Modal>
    </section>
  )
}
export default SurvyList;
