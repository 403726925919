import { Col, Empty, Row, Select, Spin } from "antd";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ColumnStackedPercentChart from "../../../../../../components/types-of-charts/ColumnStackedPercentChart";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import { staticReportStore } from "../../../../../../stores/stores";
import {
  BarStackedChartProps,
  ClassProps,
  DualChart,
} from "../../../../../../types/static-report";
import { chartContentTypes } from "../../../../../../utils/staticReport";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";

type Props = {
  loading: Record<string, boolean>;
  rateUnitByCourse: BarStackedChartProps[];
  rateUnitByModule: BarStackedChartProps[];
  rateUnitBySequence: BarStackedChartProps[];
  unitReviewAndAccess: DualChart[];
  rateSequenceAction: BarStackedChartProps[];
  rateClassAction: BarStackedChartProps[];
  unitDiscuss: BarStackedChartProps[];
  rateUnitByClass: BarStackedChartProps[];
  classList: ClassProps[];
};

const FeedbackAndEvaluationChartTab = React.memo((props: Props) => {
  const {
    loading,
    rateUnitByCourse,
    rateUnitByModule,
    rateUnitBySequence,
    unitReviewAndAccess,
    rateSequenceAction,
    rateClassAction,
    unitDiscuss,
    rateUnitByClass,
    classList,
  } = props;
  const { tab
    , setHandleExportAllImagesAsZip } = staticReportStore();
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } = useChartExport();
  useEffect(() => {
    setHandleExportAllImagesAsZip(handleExportAllImagesAsZip);
  }, [tab]);
  const { setFilterValues } = staticReportStore();

  const handleContentTypesChange = (value: string) => {
    let courseStructureType = "";
    let moduleGroup = null;
    switch (value) {
      case "5":
        courseStructureType = "sequence";
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = "course";
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = "unit";
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      courseStructureType: courseStructureType,
      moduleGroup: moduleGroup,
    });
  };

  return (
    <div>
      <div className="chart-title">Thống kê số lượng phản hồi</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading.unitDiscuss}>
                {unitDiscuss.length > 0 ? (
                  <AreaStackedChart
                    data={unitDiscuss}
                    colors={[
                      "#69C0FF",
                      "#5CDBD3",
                      "#FFC069",
                      "#FF7875",
                      "#B37FEB",
                    ]}
                    yAxisTitle="Số lượng phản hồi"
                    ref={createChartRef("unitDiscuss")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () => handleExportImage("unitDiscuss", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("unitDiscuss", "application/pdf"),
              },
            ]}
          />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.rateSequenceAction}>
                {rateSequenceAction.length > 0 ? (
                  <BarStackedChart
                    data={rateSequenceAction}
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    ref={createChartRef("rateSequenceAction")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage("rateSequenceAction", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateSequenceAction", "application/pdf"),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo lớp"
            content={
              <Spin spinning={loading.rateClassAction}>
                {rateClassAction.length > 0 ? (
                  <BarStackedChart
                    data={rateClassAction}
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    ref={createChartRef("rateClassAction")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () => handleExportImage("rateClassAction", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateClassAction", "application/pdf"),
              },
            ]}
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng đánh giá</div>
      <Col span={24}>
        <StatisticalReportCard
          chartName="Số lượng đánh giá theo thời gian"
          content={
            <Spin spinning={loading.unitReviewAndAccess}>
              {unitReviewAndAccess.length > 0 ? (
                <ComboDualAxesChart
                  data={unitReviewAndAccess}
                  columnName="Lượng làm đánh giá"
                  columnColor="#69C0FF"
                  lineName="Lượt truy cập (hoàn thành học liệu)"
                  lineColor="#FF7875"
                  ref={createChartRef("unitReviewAndAccess")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          moreOption={
            <div>
              <Select
                defaultValue="3"
                style={{ width: 170 }}
                onSelect={(value) => {
                  handleContentTypesChange(value);
                }}
                options={chartContentTypes.filter(
                  (item) => item.value !== "5" && item.value !== "6"
                )}
                allowClear
              />
            </div>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage("unitReviewAndAccess", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage("unitReviewAndAccess", "application/pdf"),
            },
          ]}
        />
      </Col>

      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả làm đánh giá"
            content={
              <Spin spinning={loading.rateUnitByModule}>
                {rateUnitByModule.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByModule}
                    ref={createChartRef("rateUnitByModule")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage("rateUnitByModule", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateUnitByModule", "application/pdf"),
              },
            ]}
          />
        </Col>

        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá khoá học"
            content={
              <Spin spinning={loading.rateUnitByCourse}>
                {rateUnitByCourse.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByCourse}
                    ref={createChartRef("rateUnitByCourse")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage("rateUnitByCourse", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateUnitByCourse", "application/pdf"),
              },
            ]}
          />
        </Col>
      </Row>

      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá theo bài giảng"
            content={
              <Spin spinning={loading.rateUnitBySequence}>
                {rateUnitBySequence.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitBySequence}
                    ref={createChartRef("rateUnitBySequence")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage("rateUnitBySequence", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateUnitBySequence", "application/pdf"),
              },
            ]}
          />
        </Col>

        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá học liệu theo lớp"
            content={
              <Spin spinning={loading.rateUnitByClass}>
                {rateUnitByClass.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByClass}
                    ref={createChartRef("rateUnitByClass")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  showSearch
                  placeholder="Chọn lớp"
                  style={{ width: 170 }}
                  onSelect={(value) => {
                    setFilterValues({ classId: value });
                  }}
                  options={classList}
                  allowClear
                />
              </div>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () => handleExportImage("rateUnitByClass", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage("rateUnitByClass", "application/pdf"),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
});

export default FeedbackAndEvaluationChartTab;
