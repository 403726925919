const lp = "./img/";
const plp = "https://via.placeholder.com/350x250/";
export const imagesAssests = [
  lp + "team1.jpg",
  lp + "team2.jpg",
  lp + "team3.jpg",
  lp + "work-desk.jpg",
  lp + "phone-app.png",
  lp + "bg-gr-v.png",
  plp + "78c5d6/fff",
  plp + "459ba8/fff",
  plp + "79c267/fff",
  plp + "c5d647/fff",
  plp + "f28c33/fff",
  plp + "e868a2/fff",
  plp + "cc4360/fff",
];

export const commonMedia = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M16.725 19.5q-.3-.125-.563-.263t-.537-.337l-1.075.325q-.175.05-.325-.013t-.25-.212l-.6-1q-.1-.15-.05-.325t.175-.3l.825-.725q-.05-.3-.05-.65t.05-.65l-.825-.725q-.125-.125-.175-.3t.05-.325l.6-1q.1-.15.25-.212t.325-.013l1.075.325q.275-.2.537-.337t.563-.263l.225-1.1q.05-.175.163-.288t.312-.112h1.2q.2 0 .313.113t.162.287l.225 1.1q.3.125.563.263t.537.337l1.075-.325q.175-.05.325.013t.25.212l.6 1q.1.15.05.325t-.175.3l-.825.725q.05.3.05.65t-.05.65l.825.725q.125.125.175.3t-.05.325l-.6 1q-.1.15-.25.213t-.325.012l-1.075-.325q-.275.2-.537.338t-.563.262l-.225 1.1q-.05.175-.163.288t-.312.112h-1.2q-.2 0-.313-.113t-.162-.287l-.225-1.1Zm1.3-1.5q.825 0 1.413-.588T20.025 16q0-.825-.587-1.413T18.025 14q-.825 0-1.412.588T16.024 16q0 .825.588 1.413t1.412.587ZM5 20q-.825 0-1.412-.588T3 18V4q0-.825.588-1.413T5 2h14q.825 0 1.413.588T21 4v5.65q-.475-.225-.975-.363T19 9.075V4H5v9h3.55q.3 0 .525.138t.35.362q.35.575.775.9t.925.475q-.225 1.35.063 2.675T12.275 20H5Z"/></svg>';

export const styleManagerConfig = {
  sectors: [
    {
      name: "Cài đặt hệ thống nâng cao",
      id: "advanced-setting",
      visible: false,
      properties: [
        {
          type: "advanced-setting-info",
          key: "advanced-setting-info",
          name: "Thông tin",
          property: "info",
          options: [
            {
              id: "advanced-setting-id",
              name: "Id",
              value: ""
            },
            {
              id: "advanced-setting-title",
              name: "Tiêu đề",
              value: ""
            },
          ],
        },
        {
          type: "advanced-setting-display-subject",
          key: "advanced-setting-display-subject",
          name: "Đối tượng hiển thị",
          property: "display-for",
          options: [
            {
              id: "all",
              name: "Tất cả",
            },
            {
              id: "registered_only",
              name: "Chỉ hiển thị với người dùng đã ghi danh",
            },
            {
              id: "hidden",
              name: "Không hiển thị",
            },
          ],
        },
        {
          type: "advanced-setting-identification-card",
          key: "advanced-setting-identification-card",
          name: "Thẻ định danh",
          property: "identification-card",
          options: [
            {
              id: "advanced-setting-identification-card",
              name: "Tên",
            },
          ],
        },
        {
          type: "advanced-setting-replace",
          key: "advanced-setting-replace",
          name: "Thay thế bởi",
          property: "replace",
        },
      ]
    },
    {
      name: "General",
      properties: [
        {
          extend: "float",
          type: "radio",
          default: "none",
          options: [
            { id: "none", value: "none", className: "fa fa-times" },
            { id: "left", value: "left", className: "fa fa-align-left" },
            { id: "right", value: "right", className: "fa fa-align-right" },
          ],
        },
        "display",
        { extend: "position", type: "select" },
        "top",
        "right",
        "left",
        "bottom",
      ],
    },
    {
      name: "Dimension",
      open: false,
      properties: [
        "width",
        {
          id: "flex-width",
          type: "integer",
          name: "Width",
          units: ["px", "%"],
          property: "flex-basis",
          toRequire: true,
        },
        "height",
        "max-width",
        "min-height",
        "margin",
        "padding",
      ],
    },
    {
      name: "Typography",
      open: false,
      properties: [
        {
          name: 'Font Family',
          property: 'font-family',
          defaults: 'Arial, Helvetica, sans-serif',
          type: "select",
          list: [
            { id: "Arial, Helvetica, sans-serif", value: "Arial, Helvetica, sans-serif", name: "Arial" },
            { id: "Courier New, Courier, monospace", value: "Courier New, Courier, monospace", name: "Courier New" },
            { id: "Georgia, serif", value: "Georgia, serif", name: "Georgia" },
            { id: "Helvetica, sans-serif", value: "Helvetica, sans-serif", name: "Helvetica" },
            { id: "Lucida Sans Unicode, Lucida Grande, sans-serif", value: "Lucida Sans Unicode, Lucida Grande, sans-serif", name: "Lucida Sans Unicode" },
            { id: "Tahoma, Geneva, sans-serif", value: "Tahoma, Geneva, sans-serif", name: "Tahoma" },
            { id: "Times New Roman, Times, serif", value: "Times New Roman, Times, serif", name: "Times New Roman" },
            { id: "Trebuchet MS, Helvetica, sans-serif", value: "Trebuchet MS, Helvetica, sans-serif", name: "Trebuchet MS" },
            { id: "Verdana, Geneva, sans-serif", value: "Verdana, Geneva, sans-serif", name: "Verdana" },
          ],
        },
        "font-size",
        {
          name: 'Font Weight',
          property: 'font-weight',
          defaults: '400',
          type: "select",
          list: [
            { id: "400", value: "400", name: "Nomal" },
            { id: "700", value: "700", name: "Bold" },
            { id: "900", value: "900", name: "Ultra-Bold" },
          ],
        },
        "letter-spacing",
        "color",
        "line-height",
        {
          extend: "text-align",
          options: [
            {
              id: "left",
              label: "Left",
              className: "fa fa-align-left",
            },
            {
              id: "center",
              label: "Center",
              className: "fa fa-align-center",
            },
            {
              id: "right",
              label: "Right",
              className: "fa fa-align-right",
            },
            {
              id: "justify",
              label: "Justify",
              className: "fa fa-align-justify",
            },
          ],
        },

        {
          property: "text-decoration",
          type: "radio",
          default: "none",
          options: [
            { id: "none", label: "None", className: "fa fa-times" },
            {
              id: "underline",
              label: "underline",
              className: "fa fa-underline",
            },
            {
              id: "line-through",
              label: "Line-through",
              className: "fa fa-strikethrough",
            },
          ],
        },
        "text-shadow",
      ],
    },
    {
      name: "Decorations",
      open: false,
      properties: [
        "opacity",
        "border-radius",
        "border",
        "box-shadow",
        "background", // { id: 'background-bg', property: 'background', type: 'bg' }
      ],
    },
    {
      name: "Extra",
      open: false,
      buildProps: ["transition", "perspective", "transform"],
    },
    {
      name: "Flex",
      open: false,
      properties: [
        {
          name: "Flex Container",
          property: "display",
          type: "select",
          defaults: "block",
          list: [
            { id: "block", value: "block", name: "Disable" },
            { id: "flex", value: "flex", name: "Enable" },
          ],
        },
        {
          name: "Flex Parent",
          property: "label-parent-flex",
          type: "integer",
        },
        {
          name: "Direction",
          property: "flex-direction",
          type: "radio",
          defaults: "row",
          list: [
            {
              id: "row",
              value: "row",
              name: "Row",
              className: "icons-flex icon-dir-row",
              title: "Row",
            },
            {
              id: "row-reverse",
              value: "row-reverse",
              name: "Row reverse",
              className: "icons-flex icon-dir-row-rev",
              title: "Row reverse",
            },
            {
              id: "column",
              value: "column",
              name: "Column",
              title: "Column",
              className: "icons-flex icon-dir-col",
            },
            {
              id: "column-reverse",
              value: "column-reverse",
              name: "Column reverse",
              title: "Column reverse",
              className: "icons-flex icon-dir-col-rev",
            },
          ],
        },
        {
          name: "Justify",
          property: "justify-content",
          type: "radio",
          defaults: "flex-start",
          list: [
            {
              id: "flex-start",
              value: "flex-start",
              className: "icons-flex icon-just-start",
              title: "Start",
            },
            {
              id: "flex-end",
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-just-end",
            },
            {
              id: "space-between",
              value: "space-between",
              title: "Space between",
              className: "icons-flex icon-just-sp-bet",
            },
            {
              id: "space-around",
              value: "space-around",
              title: "Space around",
              className: "icons-flex icon-just-sp-ar",
            },
            {
              id: "center",
              value: "center",
              title: "Center",
              className: "icons-flex icon-just-sp-cent",
            },
          ],
        },
        {
          name: "Align",
          property: "align-items",
          type: "radio",
          defaults: "center",
          list: [
            {
              id: "flex-start",
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start",
            },
            {
              id: "flex-end",
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end",
            },
            {
              id: "stretch",
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str",
            },
            {
              id: "center",
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center",
            },
          ],
        },
        {
          name: "Flex Children",
          property: "label-parent-flex",
          type: "integer",
        },
        {
          name: "Order",
          property: "order",
          type: "integer",
          defaults: "0",
          min: 0,
        },
        {
          name: "Flex",
          property: "flex",
          type: "composite",
          properties: [
            {
              name: "Grow",
              property: "flex-grow",
              type: "integer",
              defaults: "0",
              min: 0,
            },
            {
              name: "Shrink",
              property: "flex-shrink",
              type: "integer",
              defaults: "0",
              min: 0,
            },
            {
              name: "Basis",
              property: "flex-basis",
              type: "integer",
              units: ["px", "%", ""],
              unit: "",
              defaults: "auto",
            },
          ],
        },
        {
          name: "Align",
          property: "align-self",
          type: "radio",
          defaults: "auto",
          list: [
            {
              id: "auto",
              value: "auto",
              name: "Auto",
            },
            {
              id: "flex-start",
              value: "flex-start",
              title: "Start",
              className: "icons-flex icon-al-start",
            },
            {
              id: "flex-end",
              value: "flex-end",
              title: "End",
              className: "icons-flex icon-al-end",
            },
            {
              id: "stretch",
              value: "stretch",
              title: "Stretch",
              className: "icons-flex icon-al-str",
            },
            {
              id: "center",
              value: "center",
              title: "Center",
              className: "icons-flex icon-al-center",
            },
          ],
        },
      ],
    },
  ],
};

export const styleManagerCertificate = {
  sectors: [
    {
      name: "General",
      properties: [
        {
          extend: "float",
          type: "radio",
          default: "none",
          options: [
            { id: "none", value: "none", className: "fa fa-times" },
            { id: "left", value: "left", className: "fa fa-align-left" },
            { id: "right", value: "right", className: "fa fa-align-right" },
          ],
        },
        "display",
        { extend: "position", type: "select" },
        "top",
        "right",
        "left",
        "bottom",
      ],
    },
    {
      name: "Dimension",
      open: false,
      properties: [
        "width",
        {
          id: "flex-width",
          type: "integer",
          name: "Width",
          units: ["px", "%"],
          property: "flex-basis",
          toRequire: true,
        },
        "height",
        "max-width",
        "min-height",
        "margin",
        "padding",
      ],
    },
    {
      name: "Typography",
      open: true,
      properties: [
        "font-family",
        "font-size",
        "font-weight",
        "letter-spacing",
        "color",
        "line-height",
        {
          extend: "text-align",
          options: [
            {
              id: "left",
              label: "Left",
              className: "fa fa-align-left",
            },
            {
              id: "center",
              label: "Center",
              className: "fa fa-align-center",
            },
            {
              id: "right",
              label: "Right",
              className: "fa fa-align-right",
            },
            {
              id: "justify",
              label: "Justify",
              className: "fa fa-align-justify",
            },
          ],
        },
        {
          property: "text-decoration",
          type: "radio",
          default: "none",
          options: [
            { id: "none", label: "None", className: "fa fa-times" },
            {
              id: "underline",
              label: "underline",
              className: "fa fa-underline",
            },
            {
              id: "line-through",
              label: "Line-through",
              className: "fa fa-strikethrough",
            },
          ],
        },
        "text-shadow",
      ],
    },
  ],
};