import React, {FunctionComponent} from 'react';
import {Badge, Form, Tabs} from "antd";
import InfoRoleForm from "./InfoRoleForm";
import AccountApplicative from "./AccountApplicative";
import './create-role.scss'
import {FormInstance} from "antd/lib";
import {DataType} from "../../../../types/listUsers";
import InfoRoleGeneralForm from './InfoRoleGeneral';


type CreateRoleProps = {
  form: FormInstance,
  checkEdit?: boolean,
  checkIsFormEdit?: boolean,
  resetPermissions?: any,
  dataEdit?: DataType | any,
  count?: number,
  isOpened?: boolean,
  setCount?: (value: number) => void,
  activeKey?: string,
  setActiveKey?: React.Dispatch<React.SetStateAction<string>>,
}


const CreateRole: FunctionComponent<CreateRoleProps> = ({
                                                          form,
                                                          checkEdit,
                                                          checkIsFormEdit,
                                                          dataEdit,
                                                          count,
                                                          setCount,
                                                          isOpened,
                                                          resetPermissions,
                                                          activeKey,
                                                          setActiveKey
                                                        }) => {


  const items = [
    {
      key: '1',
      label: 'Thông tin vai trò',
      children: <InfoRoleGeneralForm form={form} checkEdit={checkEdit} checkIsFormEdit={checkIsFormEdit}
                              resetPermissions={resetPermissions}
                              dataEdit={dataEdit} isOpened={isOpened}/>
    },
    {
      key: '2',
      label: 'Gán quyền',
      children: <InfoRoleForm form={form} checkEdit={checkEdit} checkIsFormEdit={checkIsFormEdit}
                              resetPermissions={resetPermissions}
                              dataEdit={dataEdit} isOpened={isOpened}/>
    },
    {
      key: '3',
      label: <Badge offset={[20, 8]}
                    dot={true}
                    text={<span className="count-account">{count}</span>}>Gán tài
        khoản</Badge>,
      children: <AccountApplicative formCreate={form} dataEdit={dataEdit} setCount={setCount}
                                    checkEdit={checkEdit} checkIsFormEdit={checkIsFormEdit}/>
    }
  ]
  return (
    <div>
      <Form form={form} name="formRole" initialValues={{trangThai: true}}>
        <Tabs activeKey={activeKey} onChange={setActiveKey} items={items}/>
      </Form>

    </div>
  );
};

export default CreateRole;
