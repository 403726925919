import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Flex, Form, Typography } from "antd";
import { FormInstance } from "antd/lib";
import moment from "moment";
import { useState } from "react";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const CourseTag = () => {
  const [filterData, setFilterData] = useState<any>([]);

  const [form] = Form.useForm();

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && current > moment().endOf('day');
  };
  
  const handleFilterTag = () => {
    console.log("filler");
  };

  const clearFilterTag = (formInstance: FormInstance) => {
    formInstance.resetFields();
  };
  const handleChangeTime = (dateString: any, name: string) => {
    console.log({ dateString });
  };
  return (
    <Card className="w-full lg:w-1/2 lg:mr-2 mb-3 lg:mb-0">
    <Title level={5}>Thống kê khoá học gắn thẻ tag của QTHT</Title>
    <Form
      name="formFilterTag"
      onFinish={handleFilterTag}
      form={form}
      className="flex-wrap flex"
    >
      <Form.Item name="date">
        <Flex>
          <RangePicker
            placeholder={["Từ ngày", "Đến ngày"]}
            format="YYYY-MM-DD"
            onChange={(_value, dateString: any) =>
              handleChangeTime(dateString, "planCreationDate")
            }
            disabledDate={disabledDate}
          />
          <Flex className="ml-3">
            <Button
              className="btn btn-primary filter-btn mr-2"
              htmlType="submit"
              // onClick={handleFilter}
            >
              <FilterOutlined />
              <Typography.Text>Lọc</Typography.Text>
            </Button>
            <Button
              className="btn btn-outlined clear-filter-btn"
              onClick={() => clearFilterTag(form)}
            >
              <DeleteOutlined />
              <Typography.Text>Xóa bộ lọc</Typography.Text>
            </Button>
          </Flex>
        </Flex>
      </Form.Item>
    </Form>
    <Flex
      className="border-b p-4 bg-[#FAFAFA] mt-4"
      justify="space-between"
    >
      <Text className="w-4/5 border-r">Thông tin</Text>
      <Text className="w-1/5 text-center">Số lượng</Text>
    </Flex>
    <Flex className="border-b p-4 mt-4" justify="space-between">
      <Text className="w-4/5">Đã được gắn thẻ tag</Text>
      <Text className="w-1/5 text-center">1000</Text>
    </Flex>
    <Flex className="px-4 mt-4" justify="space-between">
      <Text className="w-4/5">Chưa được gắn thẻ tag</Text>
      <Text className="w-1/5 text-center">1000</Text>
    </Flex>
  </Card>
  )
}

export default CourseTag