import React from "react";
import { DetailTypeMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import CustomCard from "../../../../../../components/custom-card/CustomCard";
import { Badge, Typography } from "antd";
import { ApprovalStatusEnum, StatusEum } from "../../../../mooc-course-construction-plan/list-course-plan/constants/data";

interface DetailInfoProps {
  detailData: any;
  ltsIndustry: any;
  ltsIndustryGroup: any;
  ltsCollabUniversity: any;
  hostUniversity: any;
}

const DetailInfo: React.FC<DetailInfoProps> = ({
  detailData,
  ltsIndustry,
  ltsIndustryGroup,
  ltsCollabUniversity,
  hostUniversity,
}) => {
  const handleString = (e: any[]) => {
    let title: string = "";
    if (e.length > 0) {
      e.forEach((m) => {
        title = title + m.name + ", ";
      });
      title = title.slice(0, -2);
    }

    return title;
  };
  // console.log("ngvietanh",{ detailData })

  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự thực hiện";
      case 2:
        return "Hợp tác xây dựng";
      case 3:
        return "Dùng chung"
      default:
        return "";
    }
  };

  // const handleReturnStatus = (statusCode: number) => {
  //   switch (statusCode) {
  //     case 1:
  //       return "Bản nháp";
  //     case 2:
  //       return "Riêng tư";
  //     case 3:
  //       return "Chờ duyệt";
  //     case 4:
  //       return "Đã duyệt";
  //     default:
  //       return "";
  //   }
  // };
  const handleReturnStatus = (record: any) => {
    switch (record.construction_plan_status) {
      case StatusEum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case StatusEum.WAIT_APPROVE:
        if (record.approval_status && record.approval_level_name) {
          switch (record.approval_status) {
            case ApprovalStatusEnum.WAIT_APPROVE:
              return `Chờ ${record.approval_level_name} duyệt`;
            case ApprovalStatusEnum.APPROVED:
              return `${record.approval_level_name} đã duyệt`;
            case ApprovalStatusEnum.REFUSE:
              return `${record.approval_level_name} từ chối`;
            default:
              return "Chờ duyệt";
          }
        }
        return "Chờ duyệt";
      case StatusEum.APPROVED:
        return "Hội đồng thẩm định đã duyệt";
      case StatusEum.REFUSE:
        return "QTKH từ chối";

      default:
        return "";
    }
  };

  return (
    <div>
      <CustomCard
        title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              Thông tin kế hoạch khóa học
            </Typography.Title>
            <Typography.Text className="sub-title">
              Thông tin chung về kế hoạch xây dựng khóa học
            </Typography.Text>
          </div>
        }
        className="card-info--wrapper"
      >
        <div className="list-wrapper">
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Tên kế hoạch
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                {detailData.registration_name}
              </Typography.Text>
            </div>
          </div>
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Mã kế hoạch
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                {detailData.registration_code}
              </Typography.Text>
            </div>
          </div>
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Trạng thái
            </Typography.Title>

            <div className="list-item--description">
              <Typography.Text className="content">
                <Badge
                  key={detailData.construction_plan_status}
                  color={
                    parseInt(detailData.construction_plan_status) === 1
                      ? "#D9D9D9"
                      : parseInt(detailData.construction_plan_status) === 2
                        ? "#FAAD14"
                        : parseInt(detailData.construction_plan_status) === 3
                          ? "#52C41A"
                          : parseInt(detailData.construction_plan_status) === 4
                            ? "#FF4D4F"
                            : "white"
                  }
                  style={{ marginRight: 5 }}
                />
                {handleReturnStatus(
                  detailData
                )}
              </Typography.Text>
            </div>
          </div>
          {ltsIndustry !== undefined &&
            ltsIndustry.length > 0 ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Khoa
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {handleString(ltsIndustry)}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {ltsIndustryGroup !== undefined && ltsIndustryGroup.length > 0 ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Chuyên ngành
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {handleString(ltsIndustryGroup)}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {hostUniversity !== undefined ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Trường chủ trì
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {hostUniversity.name}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {ltsCollabUniversity !== undefined && ltsCollabUniversity.length > 0 ? (
            <div className="list-item">
              <Typography.Title level={5} className="list-item--title">
                Trường hợp tác
              </Typography.Title>

              <div className="list-item--description">
                <Typography.Text className="content">
                  {handleString(ltsCollabUniversity)}
                </Typography.Text>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="list-item">
            <Typography.Title level={5} className="list-item--title">
              Loại khóa học
            </Typography.Title>

            <div className="list-item--description">
              <div className="table-course-type">
                {parseInt(detailData.course_type) === 2 ? (
                  <div className="table-course-type-2">
                    {handleReturnCourseType(parseInt(detailData.course_type))}
                  </div>
                ) : parseInt(detailData.course_type) === 1 ? (
                  <div className="table-course-type-1">
                    {handleReturnCourseType(parseInt(detailData.course_type))}
                  </div>
                ) : parseInt(detailData.course_type) === 3 ? (
                  <div className="table-course-type-3">
                    {handleReturnCourseType(parseInt(detailData.course_type))}
                  </div>
                ) :
                  (
                    ""
                  )}
              </div>
            </div>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default DetailInfo;
