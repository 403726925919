import { DownloadOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { FunctionComponent } from "react";

type FilterTableProps = {
    isRendering: boolean,
    handleOpenFilter: () => void,
  }

const FilterTable: FunctionComponent<FilterTableProps> = ({
    isRendering,
    handleOpenFilter,
}) => {
    return (
        <>
      <div className="filter-table-charts">
        <Row gutter={[8, 8]}>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-resigter">
              <Button className="btn btn-primary">
                <Typography.Text>{isRendering ? <LoadingOutlined /> : <DownloadOutlined />} Xuất dữ liệu</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
    );
}

export default FilterTable;