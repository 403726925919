import React, { useEffect, useMemo, useState } from "react";
import "./detail.scss";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { ListQuestion } from "../../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "./constant/data";
import { useSurveyDetail } from "../../../../../../stores/stores";
import QuestionCard from "./QuestionCard";

interface DetailQuestionProps {
  listSectionQuestions: ListQuestion[];
  updateListSectionQuestions: (data: ListQuestion[]) => void;
  showDetailSection: boolean;
  checkIndexQuestionPosition: (index: number) => void;
}

const DetailQuestion: React.FC<DetailQuestionProps> = ({
  listSectionQuestions,
  updateListSectionQuestions,
  showDetailSection,
  checkIndexQuestionPosition,
}) => {
  const [typeQuestionId, setTypeQuestionId] = useState(1);
  const updateSectionQuestion = useSurveyDetail(
    (state) => state.updateSectionListQuestion
  );

  const listData = useMemo(() => listSectionQuestions, [listSectionQuestions]);
  // console.log('list data after add one more detail question')
  // console.log({ listData })
  // Di chuyển câu hỏi lên trên
  const moveQuestionUp = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index <= 0 || index >= list.length) return list;

    const newList = [...list];
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];

    // Cập nhật lại orderNumber
    newList[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
    newList[index - 1].orderNumber = index; // Cập nhật orderNumber của phần tử mới

    return newList;
  };

  // Di chuyển câu hỏi xuống dưới
  const moveQuestionDown = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length - 1) return list;

    const newList = [...list];
    [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];

    // Cập nhật lại orderNumber
    newList[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
    newList[index + 1].orderNumber = index + 2; // Cập nhật orderNumber của phần tử mới

    return newList;
  };

  // Sao chép một câu hỏi
  const copyQuestion = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length) return list;
    const newList = [...list];
    const copiedQuestion = { ...newList[index], id: undefined }; // Xóa id để tạo bản sao mới
    newList.splice(index + 1, 0, copiedQuestion); // Chèn bản sao sau câu hỏi gốc

    // Đánh số lại orderNumber
    for (let i = index + 1; i < newList.length; i++) {
      newList[i].orderNumber = i + 1; // Gán lại orderNumber mới
    }

    return newList;
  };

  // Xóa câu hỏi
  const deleteQuestion = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length) return list;

    // Tạo một bản sao của mảng để tránh sửa đổi trực tiếp vào đối tượng ban đầu
    const newList = list.map((question, i) => {
      if (i === index) {
        // Nếu là câu hỏi cần xóa, chỉ cần đặt trạng thái isDeleted thành true
        return {
          ...question,
          isDeleted: true,
        };
      }
      return question;
    });

    return newList;
  };

  const handleDeleteQuestion = (index: number, orderNumber?: number) => {
    const updatedList = deleteQuestion(listSectionQuestions, index);
    updateListSectionQuestions(updatedList);
  };

  const handleCopyQuestion = (index: number) => {
    console.log(`listSectionQuestions::`, listSectionQuestions);
    const updatedList = copyQuestion(listSectionQuestions, index);
    console.log(`updatedList::`, updatedList);

    updateListSectionQuestions(updatedList);
  };

  const moveItemUp = (index: number) => {
    const updatedList = moveQuestionUp(listSectionQuestions, index);

    // console.log(`updatedList::`, updatedList);
    updateListSectionQuestions(updatedList);
  };

  const moveItemDown = (index: number) => {
    const updatedList = moveQuestionDown(listSectionQuestions, index);
    // console.log(`updatedList::`, updatedList);
    updateListSectionQuestions(updatedList);
  };

  const handleChangeTypeQuestion = (value: string) => {
    const objectType = optionsDropDownQuestionType.find(
      (option) => option.value === value
    );
    if (objectType && objectType.type) {
      setTypeQuestionId(objectType.type);
    }
  };

  const checkQuestionActive = () => {
    const position = 0;
    listData.forEach((item, index) => {
      const element = document.getElementById(`questionElement-${index}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top;
        if (elementTop <= 500 && elementTop >= 0) {
          console.log(index);
          checkIndexQuestionPosition(index);
          // return index
        }
      }
    });
    // return position
  };

  return (
    <div className="survey-managment-construction">
      <div>
        {listData.map((sectionQuestion: ListQuestion, index: number) => {
          // console.log(`detailQuestion - listQuestion:: `, sectionQuestion)
          const updateSectionQuestionData = (data: ListQuestion) => {
            // console.log(`data::`, data)
            const updatedList = [...listData];

            // Find the index of the element you want to update (assuming you have an identifier like id)
            const indexToUpdate = updatedList.findIndex(
              (item) => item.orderNumber === data.orderNumber
            );

            // If the element is found, update it with the new data
            if (indexToUpdate !== -1) {
              updatedList[indexToUpdate] = data;
              // Update the state with the modified array
              updateListSectionQuestions(updatedList);
            }
          };

          return (
            <div key={index} id={`questionElement-${index}`}>
              <QuestionCard
                index={index}
                sectionQuestion={sectionQuestion}
                updateNewSectionQuestion={updateSectionQuestionData}
                showDetailSection={showDetailSection}
                onChangeTypeQuestion={handleChangeTypeQuestion}
                onDeleteQuestion={handleDeleteQuestion}
                onCopyQuestion={handleCopyQuestion}
                onMoveUp={moveItemUp}
                onMoveDown={moveItemDown}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailQuestion;
