import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
// import { DetailContent } from "../components/detail-content/DetailContent";
import "../styles.scss"
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import CertificateTemplate from "../certificate-template";
import CertificateSetting from "../certificate-setting";
import { getDetailCertificate } from "../../../service/certificate";

const { Content } = Layout;
const imgPath = "/images/";

const DetailCertificate: FunctionComponent = () => {
  const location = useLocation();
  const [tab, setTab] = useState("1");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathname = url.pathname;
  const segments = pathname.split("/");
  const lastSegment = segments[segments.length - 1];
  const [nameCertificate, setNameCertificate] = useState('')

  const getDetailData = async () => {
    await getDetailCertificate(lastSegment).then((res) => {
      setNameCertificate(res.data.data.name);
    });
  };

  useEffect(() => {
    getDetailData();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Giới thiệu chung",
      children: (
        <div className="">
          <section className="detail-account detail-course">
            <div className="form-detail">
              <CertificateTemplate tab={lastSegment}/>
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "2",
      label: "Cài đặt hoạt động",
      children: (
        <div className="">
          <section className="detail-account detail-course">
            <div className="form-detail-setting">
             <CertificateSetting setNameCertificate={setNameCertificate}/>
            </div>
          </section>
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: (
        <a href={`${routesConfig.certificate}`}>
          Thiết lập mẫu chứng nhận/chứng chỉ
        </a>
      ),
    },
    {
      title: nameCertificate,
    },
  ];

  return (
    <>
      <Content className="detail-portal-administration">
        <PageHeader
          title={nameCertificate}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={tab === "4"}
        />
        <div className="tabs-heading">
          <Tabs defaultActiveKey="1" items={items} onChange={setTab} />
        </div>
      </Content>
    </>
  );
};

export default DetailCertificate;
