
import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Modal, message } from 'antd';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import type { TabsProps } from 'antd';
import InformationPlan from './InformationPlan';
import ListDocumentPlan from './ListDocumentPlan';
import ConstructionPlan2 from './ConstructionPlan2';
import { downloadFile, getListMoocCourseDescriptionDocumentById } from '../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi';
import { TypeCourseDescriptionDocument } from '../../../../types/course-plan-managment/mooc-course-construction-plan';
import ModalProgressBar from './ModalProgressBar';
import axios from 'axios';

const { TabPane } = Tabs;
const ModalDowloadAllPlan = (props: any) => {
  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);
  const [isOpenModalProgressBar, setIsOpenModalProgressBar] = useState<boolean>(false)
  const [linkDownload, setLinkDownload] = useState<HTMLAnchorElement>()
  const [keyChoosen, setKeyChoosen] = useState('1')
  // const [isCourseConstructTabRendered, setIsCourseConstructTabRendered] = useState(false);

  const { isOpen,
    setIsOpen,
    isShowModalDetailDownload,
    setIsShowModalDetailDownload,
    detailCourseConstruct,
    defaultActive,
    setDefaultActive,
    listCourseConstruct,
    // expandedKeys,
    // setExpandedKeys,
    autoExpandParent,
    setAutoExpandParent,
    treeData,
    getAllKeys,
    courseConstructPlanId,
    expandAll,
    setExpandAll,
    expandedKeys,
    setExpandedKeys,

  } = props

  const refDetailInforPlan1 = useRef(null)
  const refCourseConstructPlan1 = useRef(null);
  const refDetailInforPlan2 = useRef(null)
  const refCourseConstructPlan2 = useRef(null);
  const refDetailInforPlan3 = useRef(null)
  const refCourseConstructPlan3 = useRef(null);

  const [isTab2ContentRendered, setIsTab2ContentRendered] = useState<boolean>(false);
  const [isTab3ContentRendered, setIsTab3ContentRendered] = useState<boolean>(false);


  const handleTabChange = (key: any) => {
    if (key === '2' && !isTab2ContentRendered) {
      setIsTab2ContentRendered(true);
    } else if (key === '3' && !isTab3ContentRendered) {
      setIsTab3ContentRendered(true);
    }
    console.log({ key })
    setKeyChoosen(key)
  };

  const dataFileSample: TypeCourseDescriptionDocument[] = [
    {
      "id": 96,
      "fileName": "test/1714723416188_moocinit.docx",
      "fileType": "docx",
      "filePath": "https://s3.moooc.xyz/bucket/test/1714967880325_MOOC-init.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ihWotP7nBIXENZiD%2F20240506%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240506T035800Z&X-Amz-Expires=900&X-Amz-Signature=970773169ed62628f8c553947753eeb984bf5e9c2d20587915ce3d1215a88421&X-Amz-SignedHeaders=host",

      "isDeleted": false,
      "courseConstructionPlanId": 36
    },
    {
      "id": 97,
      "fileName": "test/1714723416188_maudon.pdf",
      "fileType": "pdf",
      "filePath": "https://s3.moooc.xyz/bucket/test/1714967941290_Mau-don-xin-nghi-phep-06.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ihWotP7nBIXENZiD%2F20240506%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240506T035901Z&X-Amz-Expires=900&X-Amz-Signature=bf541cd9017067c320f0b7bb7dd9911260164c2e2b73f527a4770e0e8e0fc599&X-Amz-SignedHeaders=host",

      "isDeleted": false,
      "courseConstructionPlanId": 36
    }
  ]
  const getListMoocCourseDescriptionDocumentByIData = async () => {
    try {
      const response = await getListMoocCourseDescriptionDocumentById(courseConstructPlanId);
      const data = response.data.data;
      const newData: TypeCourseDescriptionDocument[] = [...data];

      const updatedData = await Promise.all(
        newData.map(async (item) => {
          try {
            const downloadResult = await downloadFile({ filePath: item?.filePath });
            return { ...item, filePath: downloadResult.data, fileName: `TaiLieuMoTa/${item.fileName}.${item.fileType}` };
          } catch (error) {
            console.error(error);
            return item;
          }
        })
      );

      console.log(`res-DescriptionDocument:: `, updatedData);
      setDataDescriptionDocument(updatedData);
    } catch (error) {
      console.error(error);
      message.error("Có lỗi chưa xác định");
    }
  }
  useEffect(() => {
    getListMoocCourseDescriptionDocumentByIData()
  }, [courseConstructPlanId])


  const handleCancel = () => {
    setIsShowModalDetailDownload(false)
  }

  const handleOk = async () => {
    setIsOpenModalProgressBar(true);
    setExpandAll(true)
    try {
      let detailInforDiv;
      let courseConstructDiv
      if (keyChoosen === '1') {
        detailInforDiv = refDetailInforPlan1.current;
        courseConstructDiv = refCourseConstructPlan1.current;
      } else if (keyChoosen === '2') {
        detailInforDiv = refDetailInforPlan2.current;
        courseConstructDiv = refCourseConstructPlan2.current;
      }
      else if (keyChoosen === '3') {
        detailInforDiv = refDetailInforPlan3.current;
        courseConstructDiv = refCourseConstructPlan3.current;
      }

      if (!detailInforDiv || !courseConstructDiv) {
        console.log('khong co ref')
      }
      // console.log({ detailInforDiv })
      // console.log({ courseConstructDiv })
      const folderName = "Documents";
      const zip = new JSZip();
      const folder = zip.folder(folderName);

      console.log({ dataDescriptionDocument })
      if (dataDescriptionDocument && dataDescriptionDocument.length > 0) {
        for (const document of dataDescriptionDocument) {
          if (document.filePath && document.filePath !== "") {
            try {
              const response = await axios.get(document.filePath, {
                responseType: "arraybuffer",
              });
              zip.file(`${folderName}/${document.fileName}`, response.data);
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log('khong co file');
          }
        }
      }

      const processDiv = async (div: any, fileName: any) => {
        // console.log({ div })
        await new Promise(resolve => setTimeout(resolve, 500));

        const canvas = await html2canvas(div, { logging: true, useCORS: true, removeContainer: true, scale: 10 });
        // const imageData = canvas.toDataURL("image/jpeg");

        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'a4'
        });

        // const width = 200;
        // const height = canvas.height * width / canvas.width;

        const a4Width = 210;
        const a4Height = 297;
        const imgWidth = a4Width - 4;
        const imgHeight = (canvas.height * imgWidth / canvas.width) - 6.5;

        const data = canvas.toDataURL("image/jpeg");
        // pdf.addImage(imageData, "JPEG", 0, 0, width, height);
        let heightLeft = imgHeight;
        let position = 0;
        const bottomPadding = 0;
        pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= (a4Height - bottomPadding);

        while (heightLeft > 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= (a4Height - bottomPadding);
        }
        if (folder) {
          folder.file(fileName, pdf.output('blob'));
        }
      };

      await Promise.all([
        processDiv(detailInforDiv, 'chi-tiet-cau-truc.pdf'),
        processDiv(courseConstructDiv, 'cau-truc-khoa-hoc.pdf')
      ]);



      const zipBlob = await zip.generateAsync({ type: 'blob' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = 'ThongTinDangKyKeHoach.zip';
      link.click();
      setLinkDownload(link)

    } catch (error) {
      console.error(error);
    } finally {
      setIsOpenModalProgressBar(false);
    }
  };

  return (
    <>
      <Modal open={isOpen}
        width={800}
        title="Thông tin đăng ký kế hoạch"

        centered={true}

        onOk={handleOk}
        okText='Tải xuống'
        onCancel={handleCancel}
        cancelText="Huỷ"
      >
        <div>
          {/* <Tabs defaultActiveKey="1" items={items} onChange={onChange} /> */}

          <Tabs onChange={handleTabChange}>
            <TabPane tab="Thông tin chung" key="1" forceRender={true}>
              <div ref={refDetailInforPlan1} className='infor-detail-1'>
                <InformationPlan detailCourseConstruct={detailCourseConstruct} />
              </div>
              <div ref={refCourseConstructPlan1} className='course-construct-none-1'>
                <ConstructionPlan2
                  // defaultActive={defaultActive}
                  // setDefaultActive={setDefaultActive}
                  // listCourseConstruct={listCourseConstruct}
                  // expandedKeys={expandedKeys}
                  // setExpandedKeys={setExpandedKeys}
                  // autoExpandParent={true}
                  // setAutoExpandParent={setAutoExpandParent}
                  // treeData={treeData}
                  // getAllKeys={getAllKeys}
                  dataCourseStructure={listCourseConstruct}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}

                />
                {/* <ConstructionPlan2 dataCourseStructure={listCourseConstruct} /> */}

              </div>
            </TabPane>
            <TabPane tab="Cấu trúc khoá học" key="2" forceRender={true}>
              {isTab2ContentRendered && (
                <>
                  <div ref={refDetailInforPlan2} className='infor-detail-none-2'>
                    <InformationPlan detailCourseConstruct={detailCourseConstruct} />
                  </div>
                  <div ref={refCourseConstructPlan2} className='course-construct-2'>
                    <ConstructionPlan2
                      // defaultActive={defaultActive}
                      // setDefaultActive={setDefaultActive}
                      // listCourseConstruct={listCourseConstruct}
                      // expandedKeys={expandedKeys}
                      // setExpandedKeys={setExpandedKeys}
                      // autoExpandParent={true}
                      // setAutoExpandParent={setAutoExpandParent}
                      // treeData={treeData}
                      // getAllKeys={getAllKeys}
                      dataCourseStructure={listCourseConstruct}
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                      expandedKeys={expandedKeys}
                      setExpandedKeys={setExpandedKeys}

                    />
                    {/* <ConstructionPlan2 dataCourseStructure={listCourseConstruct} /> */}

                  </div>
                </>
              )}
            </TabPane>
            <TabPane tab="Tài liệu mô tả" key="3">
              {isTab3ContentRendered && (
                <>
                  <div ref={refDetailInforPlan3} className='infor-detail-none-2'>
                    <InformationPlan detailCourseConstruct={detailCourseConstruct} />
                  </div>
                  <div ref={refCourseConstructPlan3} className='course-construct-none-1'>
                    <ConstructionPlan2
                      // defaultActive={defaultActive}
                      // setDefaultActive={setDefaultActive}
                      // listCourseConstruct={listCourseConstruct}
                      // expandedKeys={expandedKeys}
                      // setExpandedKeys={setExpandedKeys}
                      // autoExpandParent={true}
                      // setAutoExpandParent={setAutoExpandParent}
                      // treeData={treeData}
                      // getAllKeys={getAllKeys}
                      dataCourseStructure={listCourseConstruct}
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                      expandedKeys={expandedKeys}
                      setExpandedKeys={setExpandedKeys}

                    />
                    {/* <ConstructionPlan2 dataCourseStructure={listCourseConstruct} /> */}

                  </div>
                  <ListDocumentPlan dataListDocument={dataDescriptionDocument} />
                </>
              )}
            </TabPane>
          </Tabs>
        </div>
      </Modal>
      {isOpenModalProgressBar && (
        <ModalProgressBar
          isOpen={isOpenModalProgressBar}
          setIsOpen={setIsOpenModalProgressBar}
          isOpenModalFather={isOpen}
          setIsOpenModalFather={setIsOpen}
          // refFilePdf={refFilePdf}
          link={linkDownload}
        />
      )}

    </>
  )
}

export default ModalDowloadAllPlan