import { ReactNode } from "react"

export interface DataType {
    id: number,
    name: string,
    code: string,
    type: string,
    status: boolean,
    createdDate: string,
    numericalOrder: ReactNode,
}

export const dataType = [
    {
      value: "Công lập",
      label: "Công lập"
    },
    {
      value: "Dân lập",
      label: "Dân lập"
    },
    {
      value: "Nước ngoài",
      label: "Nước ngoài"
    },
    {
      value: "Tư thục",
      label: "Tư thục"
    },
]
  
export const dataStatus = [
    {
      value: true,
      label: "Hoạt động"
    },
    {
      value: false,
      label: "Không hoạt động"
    }
]
  
export const validateFieldCreate = ["name", "englishName","modalType","code","trainingType","province","managementAgency","district","principal", "ward", "address", "website", "status"]

export const validateFieldFilter = []
