import { useEffect, useState } from "react";
import { Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { get } from "lodash";
import FormSwitch from "../../../../../../components/form-switch/FormSwitch";
import { QuestionTypeEnum, initialQuestionSettingValue, DEFAULT_MAX_LENGTH_COURSE_TEXTAREA, DEFAULT_TEXAREA_ROW } from "../../../../../../constants";
import { useAddLesson } from "../../../../../../stores/stores";
import { QuestionAnswerInfo, SettingQuestionProps } from "../../../../../../types/course-construction";
import { convertObjectToArray } from "../../../../../../utils/arrays";
import { UpdateSettingsProps } from "../setting-question-course";

function FeedbackSetting(props: UpdateSettingsProps) {
  const { handelUpdateSettings } = props;

  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const question = useAddLesson((state) => state.question);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const answers = convertObjectToArray(currentQuestionInfo, "questionName") || [];
  const [isDisplayCriteria, setisDisplayCriteria] = useState<boolean>(false);

  const handleChangeFeed = (e: string, index: number) => {
    const newAnswers = answers;
    newAnswers[index] = { ...newAnswers[index], response: e };
    setCurrentQuestionInfo({
      ...newAnswers,
      questionName: currentQuestionInfo.questionName,
    });
  };
  
  useEffect(() => {
    if (question.type === QuestionTypeEnum.SHORT_TEXT || question.type === QuestionTypeEnum.VIDEO || question.type === QuestionTypeEnum.MP3 || question.type === QuestionTypeEnum.FILE_UPLOAD) {
      setisDisplayCriteria(true);
    } else {
      setisDisplayCriteria(false);
    }
  }, [currentQuestionInfo, settingQuestion.isFeedback4EachAns]);

  const renderSettingDetailAnswers = () => {
    if (settingQuestion.isFeedback4EachAns) {
      if (answers.length) {
        return (
          <section>
            {answers.map((item: QuestionAnswerInfo, index: number) => (
              <div
                className="setting-detail-score"
                style={{ marginBottom: "16px" }}
                key={index + 1}
              >
                <span>{isDisplayCriteria ? "Tiêu chí" : "Lựa chọn"} {item.id}</span>
                <Form.Item name={`feed${index + 1}`}>
                  <TextArea
                    showCount
                    autoSize={{ minRows: 1, maxRows: 10 }}
                    maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                    rows={DEFAULT_TEXAREA_ROW}
                    value={item.response}
                    onChange={(e) => {
                      handleChangeFeed(e.target.value, index);
                    }}
                    placeholder="Nhập nội dung phản hồi"
                  />
                </Form.Item>
              </div>
            ))}
          </section>
        );
      } else {
        return <div className="center cm-em">Câu hỏi này chưa có {isDisplayCriteria ? "tiêu chí" : " đáp án"}.</div>;
      }
    } else {
      return;
    }
  };

  const renderSettingRightAndWrong = () => {
    if (!settingQuestion.isFeedback4EachAns) {
      return (
        <section>
          <div className="setting-question-row row-area">
            <span className="row-label">Trả lời đúng</span>
            <Form.Item name="correctAns">
              <TextArea
                placeholder="Nhập nội dụng phản hồi"
                maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                rows={DEFAULT_TEXAREA_ROW}
                showCount
                autoSize={{ minRows: 1, maxRows: 10 }}
                defaultValue={settingQuestion.incorrectAns}
                onChange={(e) => { handelUpdateSettings(e.target.value, 'correctAns') }}
              />
            </Form.Item>
          </div>
          <div className="setting-question-row row-area">
            <span className="row-label">Trả lời sai</span>
            <Form.Item name="incorrectAns">
              <TextArea
                placeholder="Nhập nội dụng phản hồi"
                maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                rows={DEFAULT_TEXAREA_ROW}
                showCount
                autoSize={{ minRows: 1, maxRows: 10 }}
                defaultValue={settingQuestion.incorrectAns}
                onChange={(e) => { handelUpdateSettings(e.target.value, 'incorrectAns') }}
              />
            </Form.Item>
          </div>
          <div className="setting-question-row row-area">
            <span className="row-label">Chưa trả lời</span>
            <Form.Item name="notAnswer">
              <TextArea
                placeholder="Nhập nội dụng phản hồi"
                maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                rows={DEFAULT_TEXAREA_ROW}
                showCount
                autoSize={{ minRows: 1, maxRows: 10 }}
                defaultValue={settingQuestion.incorrectAns}
                onChange={(e) => { handelUpdateSettings(e.target.value, 'notAnswer') }}
              />
            </Form.Item>
          </div>
        </section>
      );
    }
  };

  return (
    <>
      <FormSwitch
        name="isFeedback4EachAns"
        label="Hiển thị phản hồi cho từng câu trả lời"
        onChange={handelUpdateSettings} defaultValue={settingQuestion.isFeedback4EachAns}
      />
      {renderSettingDetailAnswers()}
      {renderSettingRightAndWrong()}
    </>
  );
}

export default FeedbackSetting;
