import {
  ContactsOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Flex, Input, Layout, Tabs, TabsProps, message } from "antd";
import { useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import InfoAction from "./info-action";
import InfoInput from "./info-input";
import { useState } from "react";
import { SearchProps } from "antd/es/input";
import { isEmpty } from "lodash";
import CommonModal from "../../../components/modal/common";
import ModalGroupInput from "./info-input/ModalGroupInput";
import ModalTimeMatch from "./ModalTimeMatch";
import ModalGroupAction from "./info-action/ModalGroupAction";
import {
  deleteLearningTrend,
  publishLearningTrends,
  updateLearningTrendsDescription,
} from "../../../service/prediction-model";

const { Content } = Layout;
const { Search } = Input;

const imgPath = "/images/";

const FormHead = ({
  onSearch,
  selectedRowKeys,
  statusTag,
  setStatusTag,
  setIsDelete,
  setModalCreate,
  setTimeMatch,
  handleNotice,
  handleSave,
}: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  const handleEdit = () => {
    if (statusTag?.key === "1") {
      setStatusTag({
        key: statusTag.key,
        isEditInput: true,
        isEditAction: false,
      });
    } else {
      setStatusTag({
        key: statusTag.key,
        isEditInput: false,
        isEditAction: true,
      });
    }
  };

  const handlePublic = async () => {
    try {
      await publishLearningTrends(statusTag?.key);
      handleNotice("success", "Phát hành thông tin thành công");
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenModalCreate = () => {
    if (statusTag?.key === "1") {
      setModalCreate({
        isOpenInput: true,
        isOpenAction: false,
      });
    } else {
      setModalCreate({
        isOpenInput: false,
        isOpenAction: true,
      });
    }
  };
  return (
    <div className="heading-search">
      {(statusTag?.isEditInput && statusTag.key === "1") ||
      (statusTag?.isEditAction && statusTag.key === "2") ? (
        <Button type="primary" onClick={handleSave}>
          <SaveOutlined /> Lưu thay đổi
        </Button>
      ) : isEmpty(selectedRowKeys) ? (
        <Flex>
          <Button type="primary" className="mr-2" onClick={handlePublic}>
            Phát hành
          </Button>
          <Search
            placeholder="Nhập tên nhóm nội dung"
            onSearch={onSearch}
            className="mr-2"
            value={searchText}
            onChange={handleChangeSearch}
          />
          <Button className="mr-2" onClick={handleOpenModalCreate}>
            <PlusOutlined />
            Tạo mới nhóm
          </Button>
          <Button className="mr-2" onClick={handleEdit}>
            <EditOutlined />
          </Button>
          <Button onClick={() => setTimeMatch(true)}>
            <SettingOutlined />
          </Button>
        </Flex>
      ) : (
        <Button
          className="bg-[#FF4D4F] text-white"
          onClick={() => setIsDelete(true)}
        >
          <DeleteOutlined /> Xoá nhóm nội dung
        </Button>
      )}
    </div>
  );
};

const ManagementGroupContent = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedRowKeysInfo, setSelectedRowKeysInfo] = useState<React.Key[]>(
    []
  );
  const [selectedRowKeysAction, setSelectedRowKeysAction] = useState<
    React.Key[]
  >([]);
  const [statusTag, setStatusTag] = useState({
    key: "1",
    isEditInput: false,
    isEditAction: false,
  });
  const [isDeleteInfo, setIsDeleteInfo] = useState(false);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const [modalCreate, setModalCreate] = useState({
    isOpenInput: false,
    isOpenAction: false,
  });
  const [isOpenTimeInput, setIsOpenTimeInput] = useState(false);
  const [isOpenTimeAction, setIsOpenTimeAction] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [infoDesc, setInfoDesc] = useState<any[]>([]);

  const [messageApi, contextHolder] = message.useMessage();
  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };
  const handleRefresh = () => setIsRefresh(!isRefresh);

  const listBreadcrumb = [
    {
      title: "Hệ thống gợi ý khoá học",
    },
    {
      title: "Quản lý nhóm nội dung",
    },
  ];
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ContactsOutlined />,
      label: "Thông tin đầu vào",
      children: (
        <InfoInput
          searchValue={searchValue}
          setSelectedRowKeysInfo={setSelectedRowKeysInfo}
          statusTag={statusTag}
          isRefresh={isRefresh}
          setInfoDesc={setInfoDesc}
          handleNotice={handleNotice}
          handleRefresh={handleRefresh}
        />
      ),
    },
    {
      key: "2",
      icon: <ContactsOutlined />,
      label: "Thông tin hành vi",
      children: (
        <InfoAction
          searchValue={searchValue}
          setSelectedRowKeysAction={setSelectedRowKeysAction}
          statusTag={statusTag}
          isRefresh={isRefresh}
          setInfoDesc={setInfoDesc}
          handleNotice={handleNotice}
          handleRefresh={handleRefresh}
        />
      ),
    },
  ];
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };

  const clearModal = () => {
    setIsDeleteInfo(false);
    setIsDeleteAction(false);
    setModalCreate({
      isOpenInput: false,
      isOpenAction: false,
    });
    setIsOpenTimeInput(false);
    setIsOpenTimeAction(false);
  };
  const onChange = (key: string) => {
    setStatusTag({
      ...statusTag,
      key: key,
    });
  };

  const handleSave = async () => {
    try {
      await updateLearningTrendsDescription({ data: infoDesc });
      handleNotice("success", "Lưu thông tin thành công");
      handleRefresh();
      setStatusTag({
        key: statusTag.key,
        isEditInput: false,
        isEditAction: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmDelete = async () => {
    let param = null;
    if (isDeleteInfo) {
      param = {
        field_ids: selectedRowKeysInfo?.map((value: any) => value?.id),
      };
    } else {
      param = {
        field_ids: selectedRowKeysAction?.map((value: any) => value?.id),
      };
    }
    try {
      await deleteLearningTrend(param);
      handleRefresh();
      handleNotice("success", "Xoá thông tin thành công");
      setSelectedRowKeysAction([]);
      setSelectedRowKeysInfo([]);

      clearModal();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Quản lý nhóm nội dung thông tin sinh viên"
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate("/")}
        headerGroupButton
        childrenGroupButton={
          statusTag.key === "1" ? (
            <FormHead
              onSearch={onSearch}
              selectedRowKeys={selectedRowKeysInfo}
              statusTag={statusTag}
              setStatusTag={setStatusTag}
              setIsDelete={setIsDeleteInfo}
              setModalCreate={setModalCreate}
              setTimeMatch={setIsOpenTimeInput}
              handleNotice={handleNotice}
              handleSave={handleSave}
            />
          ) : (
            <FormHead
              onSearch={onSearch}
              selectedRowKeys={selectedRowKeysAction}
              statusTag={statusTag}
              setStatusTag={setStatusTag}
              setIsDelete={setIsDeleteAction}
              setModalCreate={setModalCreate}
              setTimeMatch={setIsOpenTimeAction}
              handleNotice={handleNotice}
              handleSave={handleSave}
            />
          )
        }
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      <CommonModal
        open={isDeleteInfo}
        title={"Xoá nhóm nội dung thông tin đầu vào của sinh viên"}
        desc={
          <div>
            Nhóm nội dung thông tin đầu vào đã xoá sẽ không thể khôi phục.{" "}
            <br />
            Bạn có chắc chắn muốn xoá nội dung này?
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={() => setIsDeleteInfo(false)}
      />
      <CommonModal
        open={isDeleteAction}
        title={"Xoá nhóm thông tin hành vi của sinh viên"}
        desc={
          <div>
            Nhóm nội dung thông tin đã xoá sẽ không thể khôi phục. Bạn có chắc
            chắn muốn xoá nội dung này?
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={() => setIsDeleteAction(false)}
      />
      <ModalGroupInput
        isOpenCreate={modalCreate.isOpenInput}
        clearModal={clearModal}
        handleNotice={handleNotice}
        handleRefresh={handleRefresh}
        isRefresh={isRefresh}
      />
      <ModalGroupAction
        isOpenCreate={modalCreate.isOpenAction}
        clearModal={clearModal}
        handleNotice={handleNotice}
        handleRefresh={handleRefresh}
        isRefresh={isRefresh}
      />
      <ModalTimeMatch
        id={"1"}
        isOpen={isOpenTimeInput}
        clearModal={clearModal}
        handleNotice={handleNotice}
      />
      <ModalTimeMatch
        id={"2"}
        isOpen={isOpenTimeAction}
        clearModal={clearModal}
        handleNotice={handleNotice}
      />
    </Content>
  );
};

export default ManagementGroupContent;
