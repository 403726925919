export const dataStatus = [
  {
    value: true,
    label: "Hoạt động",
  },
  {
    value: false,
    label: "Không hoạt động",
  },
];
export const courseType = [
  {
    id: 1,
    name: "Tự thực hiện",
  },
  {
    id: 2,
    name: "Hợp tác xây dựng",
  },
  {
    id: 3,
    name: "Dùng chung",
  },
];

export const validateFieldCreate = [
  "fullName",
  "email",
  "donViCongTacId",
  "vaiTroIds",
  "trangThai",
  "chucVuIds",
];

export const validateFieldCreateSurvey = [
  "surveyName",
  "courseType",
  "universityId",
  "collaboratorId",
  "industryId",
  "industryGroupId",
];

export const validateFieldFilter = ["donVis", "vaiTros", "trangThai"];

export enum SurveyStatusEnum {
  SAVE_DRAFT = 1,
  WAIT_APPROVE = 2,
  APPROVED = 3,
  REFUSE = 4,
  PUBLISHED = 5,
  END = 6,
  REVOKE = 7
}

export enum ApprovalStatusEnum {
  WAIT_APPROVE = 1,
  APPROVED = 2,
  REFUSE = 3,
  REVOKE = 4,
}
