import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../../config/api/configApi";
import { configApiWithoutNotify } from "../../../../config/api/configApiWithoutNotify";

export const postLogin: (body: any) => Promise<AxiosResponse<any>> = (
  body: any
) => {
  return configApiWithoutNotify.post(`/auth/login`, body);
};

export const getMailOtp: (param: string) => Promise<AxiosResponse<any>> = (
  param: string
) => {
  return axiosConfig.post(`/accounts/get-otp-forgot-password`, {
    email: param,
  });
};

export const postChangePassword: (body: any) => Promise<AxiosResponse<any>> = (
  body
) => {
  return configApiWithoutNotify.post(`/auth/change-password`, body);
};
