import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Space,
  Switch,
  Button,
  Dropdown,
  Typography,
  Select,
  Tag,
  Modal,
  Row,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  FundViewOutlined,
  HighlightOutlined,
  MoreOutlined,
  StarOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import toolbarOptions from "./toolbarOptions";
import {
  ListAnswer,
  ListQuestion,
} from "../../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "./constant/data";
import QuestionView from "./question-view/QuestionView";
import { MenuProps } from "antd/lib";
import EditorQuill from "../editor-quill/EditorQuill";
import ListUser from "../../../../../list-user/ListUser";

const modules = {
  toolbar: toolbarOptions,
};

interface QuestionCardProps {
  index: number;
  sectionQuestion: ListQuestion;
  showDetailSection: boolean;
  onChangeTypeQuestion: (value: string) => void;
  onDeleteQuestion: (index: number) => void;
  onCopyQuestion: (index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  updateNewSectionQuestion: (data: ListQuestion) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
  index,
  sectionQuestion,
  showDetailSection,
  onChangeTypeQuestion,
  onDeleteQuestion,
  onCopyQuestion,
  onMoveUp,
  onMoveDown,
  updateNewSectionQuestion,
}) => {
  const {
    questionName,
    questionDescription,
    questionType,
    orderNumber,
    isRequired,
    moocSurveyQuestionAnswer,
  } = sectionQuestion;

  const listAnswer = useMemo(
    () => sectionQuestion.moocSurveyQuestionAnswer,
    [sectionQuestion]
  );
  const isQuestionHasOther = useMemo(() => {
    return sectionQuestion.moocSurveyQuestionAnswer.some(
      (answer) => answer.isOther && !answer.isDeleted
    );
  }, [sectionQuestion]);
  useEffect(() => {}, [sectionQuestion]);

  const [isRequiredQuestion, setIsRequiredQuestion] = useState<boolean>(
    isRequired || false
  );
  const handleSwitchChange = (checked: boolean) => {
    setIsRequiredQuestion(checked);
    updateNewSectionQuestion({ ...sectionQuestion, isRequired: checked });
  };
  const [valueQuillContainer, setValueQuillContainer] =
    useState(questionDescription);
  const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);

  const handleOpenAddDescription = () => {
    setIsOpenAddDescription(!isOpenAddDescription);
  };

  const optionsMoreQuestion: MenuProps["items"] = [
    {
      key: "1",
      label:
        valueQuillContainer === ""
          ? isOpenAddDescription
            ? "Ẩn mô tả"
            : "Thêm mô tả"
          : isOpenAddDescription
          ? "Ẩn mô tả"
          : "Hiển thị mô tả",
      icon: <FundViewOutlined onClick={handleOpenAddDescription} />,
    },
    {
      key: "2",
      label: "Di chuyển lên trên",
      icon: <ArrowUpOutlined />,
    },
    {
      key: "3",
      label: "Di chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
    {
      key: "4",
      label: isQuestionHasOther ? (
        <>
          Xóa lựa chọn <Tag>Khác</Tag>
        </>
      ) : (
        <>
          Thêm lựa chọn <Tag>Khác</Tag>
        </>
      ),
      icon: <HighlightOutlined />,
    },
  ];

  const [optionDropDownMore, setIsOption] = useState(optionsMoreQuestion);

  const handleOptionDropDownMore = (index: number) => {
    let newOptions = [...optionsMoreQuestion];
    if (index === 0) {
      newOptions.splice(1, 1);
    }
    if (index === index - 1) {
      newOptions.splice(2, 1);
    }
    setIsOption(newOptions);
  };

  const handleUpdateAnswerData = (newAnswerData: ListAnswer[]) => {
    // console.log(newAnswerData);
    updateNewSectionQuestion({
      ...sectionQuestion,
      moocSurveyQuestionAnswer: newAnswerData,
    });
  };

  const handleUpdateQuestionType = (typeNumber: number) => {
    updateNewSectionQuestion({ ...sectionQuestion, questionType: typeNumber });
  };

  // console.log(`newAnswerData::`, newAnswerData);

  const handleUpdateQuestionDescription = (newDescription: string) => {
    updateNewSectionQuestion({
      ...sectionQuestion,
      questionDescription: newDescription,
    });
  };

  const handleUpdateQuestionName = (newName: string) => {
    updateNewSectionQuestion({ ...sectionQuestion, questionName: newName });
  };

  /*
  const handleChangeIsHasOtherOption = () => {

    if (isQuestionHasOther) {
      //xóa answer trong sectionQuestion.moocSurveyQuestionAnswer mà có answer.isOther=true và chuyển isDeleted=true
      sectionQuestion.moocSurveyQuestionAnswer.forEach((answer) => {
        if (answer.isOther) {
          answer.isDeleted = true;
        }
      });
    }

    if (!isQuestionHasOther) {
      //kiểm tra trong sectionQuestion.moocSurveyQuestionAnswer có câu nào có answer nào có isOther=true không, nếu có thì chuyển isDeleted của câu đó là false, nếu không có answer nào có isOther=true, thì tạo mới một answer trong sectionQuestion.moocSurveyQuestionAnswer mà có answer.isOther=true

      let hasOtherOption = false;
      // Kiểm tra trong sectionQuestion.moocSurveyQuestionAnswer có câu nào có answer nào có isOther=true không
      sectionQuestion.moocSurveyQuestionAnswer.forEach((answer) => {
        if (answer.isOther) {
          // Nếu có, chuyển isDeleted của câu đó là false
          answer.isDeleted = false;
          hasOtherOption = true;
        }
      });

      // Nếu không có answer nào có isOther=true
      if (!hasOtherOption) {
        // Tạo mới một answer trong sectionQuestion.moocSurveyQuestionAnswer mà có answer.isOther=true
        const newAnswer = {
          // Thông tin của answer mới
          // ...
          isOther: true,
          isDeleted: false, // Đảm bảo isDeleted là false
          // ...
        };
        sectionQuestion.moocSurveyQuestionAnswer.push(newAnswer); // Thêm answer mới vào mảng
      }
    }
  };*/

  const handleChangeIsHasOtherOption = () => {
    if (isQuestionHasOther) {
      // Xóa answer trong sectionQuestion.moocSurveyQuestionAnswer mà có answer.isOther=true và chuyển isDeleted=true
      const updatedAnswers = sectionQuestion.moocSurveyQuestionAnswer.map(
        (answer) => (answer.isOther ? { ...answer, isDeleted: true } : answer)
      );
      const updatedSectionQuestion = {
        ...sectionQuestion,
        moocSurveyQuestionAnswer: updatedAnswers,
      };
      updateNewSectionQuestion(updatedSectionQuestion);
    } else {
      // Kiểm tra trong sectionQuestion.moocSurveyQuestionAnswer có câu nào có answer nào có isOther=true không
      let hasOtherOption = false;
      const updatedAnswers = sectionQuestion.moocSurveyQuestionAnswer.map(
        (answer) => {
          if (answer.isOther) {
            // Nếu có, chuyển isDeleted của câu đó là false
            hasOtherOption = true;
            return { ...answer, isDeleted: false };
          }
          return answer;
        }
      );

      // Nếu không có answer nào có isOther=true
      if (!hasOtherOption) {
        // Tạo mới một answer trong sectionQuestion.moocSurveyQuestionAnswer mà có answer.isOther=true
        const newAnswer = {
          // Thông tin của answer mới
          // ...
          isOther: true,
          isDeleted: false, // Đảm bảo isDeleted là false
          answerOrderNumber:
            sectionQuestion.moocSurveyQuestionAnswer.length + 1,
          // ...
        };
        updatedAnswers.push(newAnswer); // Thêm answer mới vào mảng
      }

      const updatedSectionQuestion = {
        ...sectionQuestion,
        moocSurveyQuestionAnswer: updatedAnswers,
      };
      updateNewSectionQuestion(updatedSectionQuestion);
    }
  };

  return (
    <Card
      key={orderNumber}
      title={
        !showDetailSection ? (
          <Typography>{`Câu hỏi ${index + 1}`}</Typography>
        ) : (
          <Row>
            {isRequiredQuestion && (
              <StarOutlined style={{ marginRight: "10px", color: "red" }} />
            )}
            <TextArea
              value={questionName ? questionName : undefined}
              onChange={(e) => {
                handleUpdateQuestionName(e.target.value); // Pass the new value to the function
              }}
              placeholder={!questionName ? `Tên câu hỏi` : undefined}
              style={{
                height: "auto",
                width: 450,
                marginTop: 15,
                fontSize: 20,
                fontWeight: "lighter",
                color: "black",
                wordWrap: "break-word",
                resize: "none",
              }}
            />
          </Row>
        )
      }
      styles={{
        header: { border: "none" },
        body: { display: showDetailSection ? "block" : "none" },
        extra: { display: showDetailSection ? "block" : "none" },
      }}
      className="wrapper-settings--card form-setting--common"
      extra={
        <>
          <Select
            value={`${
              optionsDropDownQuestionType.find(
                (opt) => opt.type === questionType
              )?.value
            }`}
            onChange={(value: string) => {
              onChangeTypeQuestion(value);
              const foundType = optionsDropDownQuestionType.find(
                (item) => item.value === value
              );
              handleUpdateQuestionType(foundType?.type || 1);
            }}
            options={optionsDropDownQuestionType}
          />
        </>
      }
    >
      <Space direction="vertical" size="small" style={{ display: "flex" }}>
        {isOpenAddDescription && (
          <EditorQuill
            valueInitial={valueQuillContainer}
            handleOnChange={setValueQuillContainer}
            handleOnBlur={handleUpdateQuestionDescription}
          />
        )}
      </Space>

      {/* Hiển thị nội dung câu hỏi */}
      <QuestionView
        typeQuestionId={Number(questionType)}
        listAnswer={listAnswer}
        handleUpdateAnswerData={handleUpdateAnswerData}
        isQuestionHasOther={isQuestionHasOther}
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space size="large">
          <Space size="small" style={{ minWidth: "100px" }}>
            <div style={{ minWidth: "60px" }}>Bắt Buộc </div>
            <Switch
              checked={isRequiredQuestion}
              size="small"
              onChange={handleSwitchChange}
            />
          </Space>
          <Space size="small">
            <Button
              icon={<CopyOutlined />}
              onClick={async () => {
                await Modal.confirm({
                  title: `Tạo bản sao câu hỏi ${index + 1}`,
                  content: (
                    <>
                      Bạn có chắc chắn muốn tạo bản sao của câu hỏi này trong
                      khảo sát?
                    </>
                  ),
                  okText: "Xác nhận",
                  cancelText: "Huỷ bỏ",
                  centered: true,
                  onOk: () => onCopyQuestion(index),
                });
              }}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={async () => {
                await Modal.confirm({
                  title: `Xóa câu hỏi ${index + 1}`,
                  content: (
                    <>
                      Câu hỏi khảo sát sẽ được xóa khỏi đợt khảo sát. <br /> Bạn
                      có chắc chắn muốn xóa câu hỏi
                    </>
                  ),
                  okText: `Xác nhận`,
                  cancelText: `Hủy bỏ`,
                  centered: true,
                  onOk: () => {
                    onDeleteQuestion(index);
                  },
                });
              }}
            />
            <Dropdown
              onOpenChange={() => handleOptionDropDownMore(index)}
              menu={{
                items: optionDropDownMore,
                style: { width: "max-content" },
                onClick: ({ key }) => {
                  if (key === "1") {
                    handleOpenAddDescription();
                  }
                  if (key === "2") {
                    onMoveUp(index);
                  }
                  if (key === "3") {
                    onMoveDown(index);
                  }
                  if (key === "4") {
                    handleChangeIsHasOtherOption();
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Space>
        </Space>
      </div>
    </Card>
  );
};

export default QuestionCard;
