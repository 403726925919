import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import {
  CloseOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SendOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";
import { get, isEmpty, set } from "lodash";
import DrawerCustom from "../../../components/drawer/Drawer";
import { formatDateV2 } from "../../../utils/converDate";
import TableData from "../../../components/table-data/TableData";
import "../../../styles/_variables.scss";

import { DetailTypeMoocCourseConstructionPlan } from "../../../types/course-plan-managment/mooc-course-construction-plan";
import { DetailTypeMoocSurvey } from "../../../types/survey-managment/mooc-survey";
import { DetailTypeAuthUser } from "../../../types/mooc-share-all";
import { DetailTypeMoocOrganization } from "../../../types/course-plan-managment/mooc-organization";
import {
  DetailTypeMoocApproval,
  DataTypeMoocApproval,
  FilterDataMoocApproval,
  CreareCoursePlanArroval,
} from "../../../types/course-plan-managment/mooc-approval";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { FormCreateMoocApproval } from "./components/form-create";
import {
  postMoocApprovalSearch,
  deleteMoocApproval,
  getMoocApprovalById,
  postCreateMoocApproval,
  putUpdateMoocApproval,
} from "../../../service/course-plan-managment/mooc-approval/mooc-approval-api";
import { Tabs, TabsProps } from "antd/lib";
import {
  postMoocApprovalCourseSearch,
  postMoocApprovedCourseSearch,
} from "../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import CourseConstructionPlan from "../mooc-course-construction-plan1/CourseConstructionPlan";
import { approvalStatusEum } from "../mooc-approval-plan/constants/data";
import { approveCoursePlan } from "../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { HelpService } from "../../../service/helper.service";
import ManyApprovalsSurvey from "./components/many-approvals/ManyApprovals";
import InfoCourseApprovals from "./components/info-course-approvals/InfoCourseApprovals";

type ListMoocApprovalProps = {
  title?: string;
};

const ListMoocApprovalCourseRegister = (props: ListMoocApprovalProps) => {
  // data option, columns is changing
  const idField = "id";
  const nameField = "id";
  const helpService = new HelpService();
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState<string>(
    location?.state?.keyTab ?? "1"
  );
  const [isOpenManyApprovalsSurvey, setIsOpenManyApprovalsSurvey] =
    useState<boolean>(false);
  const [isOpenConfim, setIsOpenConfim] = useState<boolean>(false);
  const [listMoocApproval, setListMoocApproval] = useState<
    DataTypeMoocApproval[]
  >([]);
  const [listMoocApproved, setListMoocApproved] = useState<
    DataTypeMoocApproval[]
  >([]);
  const [browseData, setBrowseData] = useState<DataTypeMoocApproval>();
  const [detailData, setDetailData] = useState<DetailTypeMoocApproval>();
  const [filterData, setFilterData] = useState<FilterDataMoocApproval>({
    createdDate: null,
    approvalDate: null,
  });
  const [isLoading, setIsLoading] = useState(true);
  // forgein key function call apis
  const [lstCourseConstructionPlanId, setLstCourseConstructionPlanId] =
    useState<DetailTypeMoocCourseConstructionPlan[]>([]);
  const [lstSurveyId, setLstSurveyId] = useState<DetailTypeMoocSurvey[]>([]);
  const [lstIndividualId, setLstIndividualId] = useState<DetailTypeAuthUser[]>(
    []
  );
  const [lstOrganzationId, setLstOrganzationId] = useState<
    DetailTypeMoocOrganization[]
  >([]);
  const [isOpenModalDownload, setIsOpenModalDownload] =
    useState<boolean>(false);
  const [courseConstructPlanId, setCourseConstructPlanId] = useState<number>();
  const [recordAproved, setRecordAproved] = useState<DataTypeMoocApproval>();

  const [dataOptions, setDataOptions] = useState<any>([
    {
      title: "Ngày gửi duyệt",
      type: "RangePicker",
      key: "createdDate",
      size: 4,
      data: [],
    },
  ]);
  const [dataSearch, setDataSearch] = useState({});

  const [dataFilter, setDataFilter] = useState({});
  // const optionsDropdownMenu:  MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: "Phê duyệt đăng ký",
  //     icon: <FileDoneOutlined />,
  //   },
  //   {
  //     key: "2",
  //     label: "Phê duyệt nhanh đăng ký",
  //     icon: <ThunderboltOutlined />,
  //   },
  //   {
  //     key: "3",
  //     label: "Lịch sử phê duyệt",
  //     icon: <HistoryOutlined />,
  //   },
  //   {
  //     key: "4",
  //     label: "Tải nội dung đăng ký",
  //     icon: <DownloadOutlined />,
  //   },
  // ];
  const optionsDropdownMenu = (record: any): MenuProps["items"] => {
    let options: MenuProps["items"] = [
      {
        key: "1",
        label: "Phê duyệt đăng ký",
        icon: <FileDoneOutlined />,
      },
      {
        key: "2",
        label: "Phê duyệt nhanh đăng ký",
        icon: <ThunderboltOutlined />,
      },
      {
        key: "3",
        label: "Lịch sử phê duyệt",
        icon: <HistoryOutlined />,
      },
      {
        key: "5",
        label: "Lịch sử hoạt động",
        icon: <HistoryOutlined />,
      },
      {
        key: "4",
        label: "Tải nội dung đăng ký",
        icon: <DownloadOutlined />,
      },
    ];
    if (activeTabKey === "2") {
      options = options.filter(
        (options) => options?.key !== "1" && options?.key !== "2"
      );
    }
    return options;
  };
  const handleApproveSurvay = (record: any) => {
    const dataSend: CreareCoursePlanArroval = {
      courseId: Number(record.courseConstructionPlanId),
      individualId: Number(record.individualId),
      courseStatus: approvalStatusEum.APPROVED,
      submitterId: record.s,
    };
    approveCoursePlan(dataSend).then((res) => {
      const { data, statusCode, message } = res.data;
      if (statusCode === 200) {
        getListData();
        helpService.successMessage(get(data, "message", message));
      }
    });
  };
  useEffect(() => {
    if (isOpenManyApprovalsSurvey == false) {
      clearFilter();
      setSearchValue("");
      getListData();
    }
  }, [isOpenManyApprovalsSurvey]);
  const dataColumns: TableColumnsType<DataTypeMoocApproval> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã đăng ký",
      dataIndex: "course_construction_plan_id",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.courseConstructionPlanCode}
          </span>
        );
      },
    },
    {
      title: "Tên đăng ký", //dropdown
      dataIndex: "cours_construction_plan_name",
      key: "courseConstructionPlanName",
      width: 14,
      sorter: (a, b) =>
        a.courseConstructionPlanName.localeCompare(
          b.courseConstructionPlanName
        ),
      render: (_: any, record, index: number) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">
                {record.courseConstructionPlanName}
              </span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi phê duyệt", //clendar
      dataIndex: "created_date",
      key: "createdDate",
      width: 10,
      // sorter: {
      //   compare: (a, b) => 0, // compare: (a, b) => a.createdDate.length - b.createdDate.length
      // },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.createdDate
              ? formatDateV2(record?.createdDate.toString())
              : null}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 2.2,
      render: (_, record) => {
        const location = {
          id: `${record[idField]}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.moocApprovalDetail}/${location.id}`,
                    {
                      state: {
                        record,
                        mode: "view",
                        keyTab: activeTabKey,
                        modeback: "backState",
                        backLink: `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsDropdownMenu(record),
                  onClick: ({ key }) => {
                    if (key === "1") {
                      navigate(
                        `${routesConfig.approvalCourseRegister}/${record.id}`,
                        {
                          state: { record, mode: "view" },
                        }
                      );
                    }
                    if (key === "2") {
                      setIsOpenConfim(true);
                      setRecordAproved(record);
                    }
                    if (key === "3") {
                      navigate(
                        `${routesConfig.moocApprovalDetail}/${location.id}?tab=4`,
                        {
                          state: { record, mode: "view" },
                        }
                      );
                    }
                    if (key === "4") {
                      setIsOpenModalDownload(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                    }
                    if (key === "5") {
                      navigate(
                        `/course-plan/register-course-plan/mooc-course-construction-plan-list/mooc-course-construction-plan-history/${record.courseConstructionPlanId}`,
                        {
                          state: {
                            ...record,
                            type: "approval",
                          },
                        }
                      );
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];

  const dataColumnsApproved: TableColumnsType<DataTypeMoocApproval> = [
    {
      title: "STT",
      width: 1,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "",
      width: 1,
      render: (_: any, record, index: number) => {
        return <Checkbox />;
      },
    },
    {
      title: "Mã đăng ký",
      dataIndex: "course_construction_plan_id",
      width: 2,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.courseConstructionPlanCode}
          </span>
        );
      },
    },
    {
      title: "Tên đăng ký", //dropdown
      dataIndex: "course_construction_plan_name",
      key: "courseConstructionPlanName",
      width: 4,
      sorter: (a, b) =>
        a.courseConstructionPlanName.localeCompare(
          b.courseConstructionPlanName
        ),
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.courseConstructionPlanName}
          </span>
        );
      },
    },
    {
      title: "Ngày gửi phê duyệt", //clendar
      dataIndex: "created_date",
      key: "createdDate",
      width: 2,
      // sorter: {
      //   compare: (a, b) => 0, // compare: (a, b) => a.createdDate.length - b.createdDate.length
      // },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.createdDate
              ? formatDateV2(record?.createdDate.toString())
              : null}
          </span>
        );
      },
    },
    {
      title: "Ngày phê duyệt", //clendar
      dataIndex: "approval_date",
      key: "approvalDate",
      width: 2,
      // sorter: {
      //   compare: (a, b) => 0, // compare: (a, b) => a.createdDate.length - b.createdDate.length
      // },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.approvalDate
              ? formatDateV2(record?.approvalDate.toString())
              : null}
          </span>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 1,
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record[idField]}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(`${routesConfig.moocApprovalDetail}/${record.id}`, {
                    state: {
                      record,
                      mode: "view",
                      keyTab: activeTabKey,
                      modeback: "backState",
                      backLink: `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`,
                    },
                  })
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsDropdownMenu(record),
                  onClick: ({ key }) => {
                    if (key === "3") {
                      navigate(
                        `${routesConfig.moocApprovalDetail}/${location.id}?tab=4`,
                        {
                          state: { record, mode: "view" },
                        }
                      );
                    }
                    if (key === "4") {
                      setIsOpenModalDownload(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const defaultCheckedListApproved = dataColumnsApproved.map(
    (item) => item.key as string
  );
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkedListApproved, setCheckedListApproved] = useState(
    defaultCheckedListApproved
  );

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: "danh sách phê duyệt đăng ký kế hoạch",
    },
  ];

  // function call apis, process data
  const getListData = async () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: activeTabKey === "1" ? pageNumber : pageNumberApproed,
      pageSize: activeTabKey === "1" ? pageSize : pageSizeApproed,
      id: !isEmpty(filterData.id) ? filterData.id : null,
      courseConstructionPlanId: !isEmpty(filterData.courseConstructionPlanId)
        ? filterData.courseConstructionPlanId
        : null,
      approvalDateFrom: !isEmpty(filterData.approvalDate?.[0])
        ? filterData.approvalDate?.[0].toString()
        : null,
      approvalDateTo: !isEmpty(filterData.approvalDate?.[1])
        ? filterData.approvalDate?.[1].toString()
        : null,
      approvalRemark: !isEmpty(filterData.approvalRemark)
        ? filterData.approvalRemark
        : null,
      individualId: !isEmpty(filterData.individualId)
        ? filterData.individualId
        : null,
      createdDateFrom: !isEmpty(filterData.createdDate?.[0])
        ? filterData.createdDate?.[0].toString()
        : null,
      createdDateTo: !isEmpty(filterData.createdDate?.[1])
        ? filterData.createdDate?.[1].toString()
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    setDataSearch(data);

    if (activeTabKey === "1") {
      await postMoocApprovalCourseSearch(data)
        .then((res) => {
          const { data } = res.data;

          setListMoocApproval(data.data);
          setTotalRecords(data.totalRecords);
          postMoocApprovedCourseSearch(data)
            .then((res) => {
              const { data } = res.data;
              setTotalRecordsApproved(data.totalRecords);
            })
            .catch((err) => {
              setLoading(false);
              console.log(err);
              message.error("Có lỗi chưa xác định");
            });
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });

      setIsLoading(false);
    }
    if (activeTabKey === "2") {
      await postMoocApprovedCourseSearch(data)
        .then((res) => {
          const { data } = res.data;
          setListMoocApproved(data.data);
          setTotalRecordsApproved(data.totalRecords);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });

      setIsLoading(false);
    }
  };

  const handleCreate = async (value: any) => {
    try {
      setLoading(true);
      const data = value;

      const response = await postCreateMoocApproval(data)
        .then((result) => {
          setLoading(false);
          message.success("Cấu hình phê duyệt đã được thêm thành công");
          navigate(
            `${routesConfig.moocApprovalDetail}/${result.data.data.id}`,
            {
              state: { mode: "edit" },
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleEdit = async (value: any) => {
    try {
      const data = value;
      const response = await putUpdateMoocApproval(idDetail, data)
        .then((result) => {
          // navigate success
          message.success(
            `Sửa Cấu hình phê duyệt ${data[nameField]} thành công`
          );
          navigate(`${routesConfig.moocApprovalDetail}/${idDetail}`, {
            state: { mode: "edit" },
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setFilterData({
      createdDate: null,
      approvalDate: null,
    });

    setDataFilter({ ...dataFilter });
    getListData();
  };

  //tab
  const onTabChange = (key: any) => {
    setActiveTabKey(key);
    if (key === "1") {
      setDataOptions([
        {
          title: "Ngày gửi duyệt",
          type: "RangePicker",
          key: "createdDate",
          size: 4,
          data: [],
        },
      ]);
    }

    if (key === "2") {
      setDataOptions([
        {
          title: "Ngày gửi duyệt",
          type: "RangePicker",
          key: "createdDate",
          size: 4,
          data: [],
        },
        {
          title: "Ngày duyệt",
          type: "RangePicker",
          key: "approvalDate",
          size: 4,
          data: [],
        },
      ]);
    }
  };

  // common state variables, no change
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showFilterFilerAppred, setShowFilterFilerAppred] =
    useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumberApproed, setPageNumberApproed] = useState<number>(1);
  const [pageSizeApproed, setPageSizeApproed] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalRecordsApproved, setTotalRecordsApproved] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenBrowseModal, setIsOpenBrowseModal] = useState<boolean>(false);
  const [isOpenSyncModal, setIsOpenSyncModal] = useState<boolean>(false);
  const [syncCheckbox, setSyncCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);

  // common functions
  useEffect(() => {
    // setLoading(true);
    getListData();
  }, [
    activeTabKey,
    pageNumber,
    pageNumberApproed,
    pageSizeApproed,
    pageSize,
    statusOrder,
    dataFilter,
  ]);

  const getNumericalNumber = (index: number, page: number, size: number) => {
    return (page - 1) * size + index + 1;
  };

  const randomCode = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).replace("-", "");
      }
    );
  };

  const handleOpenFilter = async () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setPageNumberApproed(1);
    setPageSize(10);
    setPageSizeApproed(10);
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setPageSize(10);
      setPageNumberApproed(1);
      setPageSizeApproed(10);
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEdit(value);
    } else {
      handleCreate(value);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <span>Chờ phê duyệt </span>
          <span className="border-total-record">{totalRecords}</span>
        </div>
      ),
      children: (
        // <ListMoocAwaitingApproval title='Chờ phê duyệt'></ListMoocAwaitingApproval>
        <Layout className="page-header-group course-construction">
          <Form.Provider
            onFormFinish={(formName, { values, forms }) => {
              if (formName === "create-edit-mooc-approval-detail") {
                handleSave(values);
              }
            }}
          >
            <div className="tabs-heading">
              <TableData
                dataTable={listMoocApproval}
                dataColumns={dataColumns}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                pageSize={pageSize}
                loadingTable={loading}
                scroll={{ x: 1500, y: 600 }}
                totalRecords={totalRecords}
                setSelectedRowKeysProps={setSelectedRowKeys}
                handleTableChangeProps={handleTableChange}
                pagination={true}
                hideSelection={false}
              />
            </div>
            <ManyApprovalsSurvey
              data={dataSearch}
              open={isOpenManyApprovalsSurvey}
              handleCancel={() => {
                handleCloseManyApprovalsSurvey();
              }}
            />
            <InfoCourseApprovals
              data={recordAproved}
              open={isOpenConfim}
              handleCancel={() => {
                setIsOpenConfim(false);
              }}
              handleComfim={() => {
                setIsOpenConfim(false),
                  Modal.confirm({
                    icon: <FileDoneOutlined />,
                    title: `Xác nhận phê duyệt ${recordAproved?.courseConstructionPlanName}?`,
                    content: (
                      <>
                        <p>
                          Đảm bảo rằng tất cả các
                          <Typography.Text type="warning">
                            {" "}
                            thông tin liên quan đã được kiểm tra và cặp nhật đầy
                            đủ{" "}
                          </Typography.Text>
                          trước khi Xác nhận kết quả phê duyệt khảo sát
                        </p>
                      </>
                    ),
                    onOk: () => handleApproveSurvay(recordAproved),
                    centered: true,
                    okText: "Xác nhận",
                    cancelText: "Đóng",
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
              }}
            />
          </Form.Provider>

          {isOpenModalDownload && (
            <CourseConstructionPlan
              isOpen={isOpenModalDownload}
              setIsOpen={setIsOpenModalDownload}
              courseConstructPlanId={courseConstructPlanId}
            />
          )}
        </Layout>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <span>Đã phê duyệt</span>
          <span className="border-total-record">{totalRecordsApproved}</span>
        </div>
      ),
      children: (
        // <ListMoocApprovedCourse title='Đã phê duyệt'></ListMoocApprovedCourse>
        <Layout className="page-header-group course-construction">
          <Form.Provider
            onFormFinish={(formName, { values, forms }) => {
              if (formName === "create-edit-mooc-approval-detail") {
                handleSave(values);
              }
            }}
          >
            <div className="tabs-heading">
              <TableData
                dataTable={listMoocApproved}
                dataColumns={dataColumns}
                setPageNumber={setPageNumberApproed}
                setPageSize={setPageSizeApproed}
                pageNumber={pageNumberApproed}
                pageSize={pageSizeApproed}
                loadingTable={loading}
                scroll={{ x: 1500, y: 600 }}
                totalRecords={totalRecordsApproved}
                setSelectedRowKeysProps={setSelectedRowKeys}
                handleTableChangeProps={handleTableChange}
                pagination={true}
              />
            </div>
            {/* <FormCreateMoocApproval
                      // truyen danh sach cac bien state list 
                      dataOptions={dataOptions}
                      id={idDetail}
                      detailData={detailData}
                      isOpened={isOpened}
                      setIsOpened={setIsOpened}
                  /> */}
          </Form.Provider>

          {isOpenModalDownload && (
            <CourseConstructionPlan
              isOpen={isOpenModalDownload}
              setIsOpen={setIsOpenModalDownload}
              courseConstructPlanId={courseConstructPlanId}
            />
          )}
        </Layout>
      ),
    },
  ];
  const OpenManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(true);
  };
  const handleCloseManyApprovalsSurvey = () => {
    clearFilter();
    setIsOpenManyApprovalsSurvey(false);
    getListData();
  };
  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-approval-detail") {
            handleSave(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader
                title={title}
                // listBreadcrumb={listBreadcrumb}
                positionItem="column"
                breadcrumb={true}
              />
            </div>

            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={
                activeTabKey === "1" ? dataColumns : dataColumnsApproved
              }
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={Number(activeTabKey)}
              showFilter={dataOptions != null && dataOptions.length > 0}
              handleOpenManyApprovalsSurvey={OpenManyApprovalsSurvey}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            onFinish={handleSubmitSearch}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            form={form}
            showFilter={showFilter}
            tab={Number(activeTabKey)}
          />
          {!isLoading && (
            <div className="tabs-heading">
              <Tabs
                activeKey={activeTabKey}
                onChange={onTabChange}
                items={items}
              />
            </div>
          )}
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default ListMoocApprovalCourseRegister;
