import {IDataCreateUser} from "../../types/listUsers";
import {axiosConfig} from "../../config/api/configApi";
import {AxiosResponse} from "axios";

export const createAccountMinistry: (data: IDataCreateUser) => Promise<AxiosResponse<any>> = (data: IDataCreateUser) => {
	return axiosConfig.post(`/accounts/cap-bo`, data)
}

export const getDataMinistry: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
	return axiosConfig.post(`/accounts/cap-bo/search`, search);
}

