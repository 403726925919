import { CloseOutlined, DeleteOutlined, InfoCircleOutlined, SaveOutlined, UploadOutlined, } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { multimediaIconList } from "../../../constants/multimedia-constants/multimedia-constants";
import {
  createCourseByAudio,
  createCourseByPDF,
  createCourseByScrom,
  createCourseByText,
  createCourseByVideo,
  createCourseByXAPI,
  getListMp3Option,
  getListPdfOption,
  getListScormOption,
  getListVideoOption,
} from "../../../service/course-construct";
import { unitDetail } from "../../../types/course-review";
import { convertSecondsToMinutesAndSeconds } from "../../../utils/converDate";
import FormInput from "../../form-input/FormInput";
import "./addLearningMaterials.scss";
import ReactQuill from "react-quill";
import { isEmpty, size } from "lodash";
import { FORMAT_REACT_QUILL } from "../../../constants";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import FileUploader from "../../file-uploader/file-uploader.component";
import CommonModal from "../../modal/common";

type Props = {
  open?: boolean;
  isLesson?: boolean;
  title?: string;
  setIsOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  sequenceId?: string;
  handleDetail?: any;
  itemDetail?: ItemDetail;
  isEdit?: boolean;
  multimediaType?: any;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
};

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    ['blockquote', 'code-block'],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['formula']
  ],
};
const optionTime = [
  {
    id: 10,
    name: "Phút",
  },
  {
    id: 20,
    name: "Giờ",
  }
];

const { Option } = Select;
const AddLearningMaterials = (props: Props) => {
  const {
    open,
    setIsOpenDrawer,
    id,
    multimediaType,
    itemDetail,
    isEdit,
    sequenceId,
    handleDetail,
    setIsEdit,
  } = props;
  const [form] = Form.useForm();
  const blockId = itemDetail?.block?.[0]?.id;
  const type = itemDetail?.block?.[0]?.module;

  const [step, setStep] = useState(1);
  const [chooseFile, setChooseFile] = useState(1);
  const [loading, setLoading] = useState(false);
  const [unitDetail, setUnitDetail] = useState<unitDetail>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [fileList, setFileList] = useState<any[]>([]);
  const [fileDelete, setFileDelete] = useState<any[]>();
  const [videoAttachment, setVideoAttachment] = useState<any>();
  const [fileUpload, setFileUpload] = useState<any>();
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const [fileListOption, setFileListOption] = useState<any>([]);
  const [fileOption, setFileOption] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const handleChangeFileAttachment = (info: { file: any; fileList: any[] }) => {
    const deleteElm = unitDetail?.attachments.filter(
      (item1) => !info.fileList.some((item2) => item1.id === item2.id)
    );
    setFileList(info.fileList);
    setFileDelete(deleteElm);
  };

  const onUploadSuccess = (fileKeys: string[], info: { file: any; fileList: any[] }) => {
  };

  const onCloseDraw = () => {
    setStep(1);
    form.resetFields();
    setFileListOption([])
    setFileOption("")
    setVideoAttachment(undefined)
    setFileUpload(undefined)
    setChooseFile(1)
    setFileList([])
    setIsOpenDrawer(false);
    if (isEdit && setIsEdit) {
      setIsEdit(false);
    }
  };
  const handleRadioChange = (e: RadioChangeEvent) => {
    setChooseFile(e.target.value);
  };
  const renderNameDraw = () => {
    return isEdit
      ? "Sửa học liệu " + unitDetail?.title
      : "Thêm học liệu " + renderTitle();
  };

  const handleChange = (value: string) => {
  };

  const returnAccept = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "VIDEO":
        return ".mp4, .mpg, .avi, .webm, .m4v";
        break;
      case "SCROM":
        return ".zip, .rar";
        break;
      case "PDF":
        return ".pdf";
        break;
      case "AUDIO":
        return ".mp3";
        break;
      case "MP3":
        return ".mp3";
        break;
      case "TEXT":
        return ".txt";
        break;
      case "XAPI":
        return ".zip";
        break;
      default:
        break;
    }
  };

  const renderTitle = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "VIDEO":
        return "Video";
        break;
      case "SCROM":
        return "Scorm";
        break;
      case "SCORM":
        return "Scorm";
        break;
      case "PDF":
        return "PDF";
        break;
      case "XAPI":
        return "xAPI";
        break;
      case "AUDIO":
        return "Audio";
        break;
      case "MP3":
        return "Audio";
        break;
      case "TEXT":
        return "Văn bản";
        break;
      case "REFERENCE":
        return "Tài liệu tham khảo";
        break;
      default:
        break;
    }
  };

  const getS3Prefix = () => {
    switch (multimediaType ? multimediaType?.toUpperCase() : type?.toUpperCase()) {
      case "SCROM":
      case "XAPI":
        return "scorm/" + sequenceId;
      default:
        return "sequences/" + sequenceId;
    }
  };

  const handlePreviewFile = async (file: any) => {
    return await URL.createObjectURL(file);
  };
  const saveScrom = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }
    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }
    await createCourseByScrom(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu scorm thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const saveVideo = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }
    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }
    await createCourseByVideo(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu video thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => setLoading(false));
  };

  const saveXAPI = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }
    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }
    await createCourseByXAPI(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu xAPI thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const savePDF = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }
    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }

    await createCourseByPDF(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu PDF thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const saveAudio = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = "";
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }
    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }

    await createCourseByAudio(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu mp3 thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const saveText = async (values: any) => {
    values.attachFile?.fileList?.map((item: any) => {
      return item.originFileObj;
    });
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;

    let formData: any = {};
    formData["sequenceId"] = sequenceId ?? "";
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    formData["iconType"] = values.icon ?? 1;
    formData["text"] = values.text;
    formData["referenceUrl"] = "";
    formData["expectedTime"] = values.expectedTime;
    formData["expectedTimeType"] = values.expectedTimeType;

    if (values.videoAttach?.file) {
      formData["videoAttachment"] = values.videoAttach?.file.response.fileKey || values.videoAttach?.file?.fileKey;
    }

    if (fileList && Array.isArray(fileList)) {
      formData["filesAttachment"] = fileList.map(i => i.response.fileKey || i.fileKey);
    }
    await createCourseByText(formData).then((res) => {
      if (res.status === 200) {
        message.success("Tạo học liệu văn bản thành công");
        setOpenCorfirmModal(false);
        setIsOpenDrawer(false);
        form.resetFields();
        handleDetail();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const editUnit = async (values: any) => {
    const sumMinute = values.minutes ? values.minutes * 60 : 0;
    const sumSecond = values.seconds ? values.seconds : 0;
    const formData: any = {};

    formData["blockId"] = String(unitDetail?.courseBlockId);
    formData["title"] = values.title;
    if (!isEmpty(values.description)) {
      formData["description"] = !isEmpty(values.description) ? values.description : "";
    }
    formData["timeCompletedCondition"] = sumMinute + sumSecond;
    if (!isEmpty(values.text)) {
      formData["text"] = values.text;
    }
    formData["unitId"] = String(unitDetail?.courseUnitId);
    formData["fileMain"] = values.video;
    formData["fileUploadFrom"] = chooseFile === 1 ? 0 : 1;

    if (!isEmpty(fileOption) && chooseFile === 2) {
      formData["fileMain"] = fileOption ?? null;
    }

    if (!isEmpty(values.video?.fileList) && chooseFile === 1) {
      formData["fileMain"] = values.video?.file.response.fileKey || (values.video?.fileList[0].fileKey ?? null);
    }

    const elementsWithoutId = fileList.filter(
      (item: any) => item.id === undefined || item.id === null
    );
    if (elementsWithoutId && Array.isArray(elementsWithoutId)) {
      formData["filesAttachment"] = elementsWithoutId.map(i => i.fileKey || i.response.fileKey);
    }
    if (fileDelete) {
      formData["filesAttachmentDelete"] = String(fileDelete?.map((it: any) => it.id).join(",")) ?? '';
    }

    formData["iconType"] = values.icon ?? null;

    if (values.videoAttach?.file?.originFileObj) {
      formData["videoAttachment"] = values.videoAttach?.file?.originFileObj ?? null;
    }

    formData["expectedTime"] = values.expectedTime || null;
    formData["expectedTimeType"] = values.expectedTimeType || null;

    const apiUrl = multimediaIconList?.find((it) => it.type.toLocaleLowerCase() === type.toLocaleLowerCase())?.apiEdit;

    if (apiUrl) {
      await apiUrl(formData).then((res: any) => {
        if (res.status === 200) {
          message.success("Sửa học liệu thành công");
          setOpenCorfirmModal(false);
          setIsOpenDrawer(false);
          form.resetFields();
          handleDetail();
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    form.validateFields(["video"]);
  }, [form]);

  useEffect(() => {
    const getDetailCourseData = async () => {
      const apiUrl = multimediaIconList?.find(
        (it) => it.type.toLowerCase() === type.toLowerCase()
      )?.apiDetail;

      try {
        if (apiUrl) {
          const response = await apiUrl(blockId).then((res: any) => {
            const { data } = res.data;
            setUnitDetail(data);
            const fileList = data.attachments
              ?.filter((it: any) => it.attachmentType !== "video")
              .map((file: any) => ({
                id: file.id,
                name: file.fileName,
                status: "done",
                url: file.attachmentKey,
                attachmentType: file.attachmentType,
              }));
            setFileList(fileList);
            form.setFieldsValue({
              ...data,
              icon: data?.iconType || 1,
              minutes: convertSecondsToMinutesAndSeconds(
                data?.timeCompletedCondition
              )?.minutes,
              seconds: convertSecondsToMinutesAndSeconds(
                data?.timeCompletedCondition
              )?.seconds,
              videoAttach: fileList?.filter(
                (it: any) => it.attachmentType === "video"
              ),
              video: data.fileUploadFrom === 0 ? (data?.mainHtmlKey || data?.mainVideo || data?.mainKey) : "",
              selectVideo: data.fileUploadFrom === 1 ? (data?.mainHtmlKey || data?.mainVideo || data?.mainKey) : '',
            });
            setChooseFile(data.fileUploadFrom === 1 ? 2 : 1)
          });
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    if (blockId && isEdit) {
      getDetailCourseData();
    }
  }, [blockId, isEdit]);

  const getTemplateOption = async () => {
    let response: any = {}
    const data = {
      page: 1,
      size: 1000
    }
    if (multimediaType?.toUpperCase() === 'VIDEO' || type?.toUpperCase() === 'VIDEO') {
      response = await getListVideoOption(data)
    }
    if (multimediaType?.toUpperCase() === 'SCROM' || type?.toUpperCase() === 'SCORM') {
      response = await getListScormOption(data)
    }
    if (multimediaType?.toUpperCase() === 'XAPI' || type?.toUpperCase() === 'XAPI') {
      response = await getListScormOption(data)
    }
    if (multimediaType?.toUpperCase() === 'PDF' || type?.toUpperCase() === 'PDF') {
      response = await getListPdfOption(data)
    }
    if (multimediaType?.toUpperCase() === 'AUDIO' || type?.toUpperCase() === 'MP3') {
      response = await getListMp3Option(data)
    }
    setFileListOption(response.data?.data?.data)
  }

  useEffect(() => {
    if (open) {
      getTemplateOption()
    }
  }, [open])

  useEffect(() => {
    if (unitDetail?.id) {
      setFileUpload(unitDetail?.fileUploadFrom === 0 ? (unitDetail?.mainVideo || unitDetail?.mainHtmlKey || unitDetail?.mainKeyUrl) : '');
      setFileOption(unitDetail?.fileUploadFrom === 1 ? (unitDetail?.mainVideo || unitDetail?.mainHtmlKey || unitDetail?.mainKey) : '');
      setVideoAttachment(
        unitDetail?.attachments?.find((it) => it.attachmentType === "video")
          ?.attachmentKey
      );
      setTitle(unitDetail?.title)
    }
  }, [unitDetail?.id, open]);

  const handleChangeVideoAttach = (info: { file: any; fileList: any[] }) => {
    if (info?.fileList.length > 0) {
      //const videoObjectUrl = URL.createObjectURL(info.file);
      //setVideoAttachment(videoObjectUrl);
    } else {
      setVideoAttachment(null);
    }
  };

  const handleDeleteVideoAttach = () => {
    const idDelete = unitDetail?.attachments.find(
      (it) => it.attachmentType === "video"
    );
    setFileDelete((oldState) => {
      if (Array.isArray(oldState)) {
        return [...oldState, idDelete];
      } else {
        return [idDelete];
      }
    });
    setVideoAttachment(null);
  };

  const handleChangeFileUpload = (info: any) => {
    if (!isEmpty(info.fileList) && info.fileList[0].response) {
      if (multimediaType !== 'scrom' && multimediaType !== 'xAPI' && type !== 'scorm' && type !== 'xapi') {
        const videoObjectUrl = URL.createObjectURL(info.fileList[0]?.originFileObj);
        setFileUpload(videoObjectUrl);
      } else {
        setFileUpload(info.fileList[0]?.originFileObj);
      }
    } else {
      setFileUpload('');
    }
  };

  const beforeUpload = () => {
    return false
  }

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        setLoading(true);
        if (!isEdit) {
          if (multimediaType === "scrom") {
            saveScrom(values);
          }
          if (multimediaType === "video") {
            saveVideo(values);
          }
          if (multimediaType === "xAPI") {
            saveXAPI(values)
          }
          if (multimediaType === "PDF") {
            savePDF(values)
          }
          if (multimediaType === "audio") {
            saveAudio(values)
          }
          if (multimediaType === "text") {
            saveText(values)
          }
        } else {
          editUnit(values);
        }
      }}
    >
      <Drawer
        title={renderNameDraw()}
        onClose={onCloseDraw}
        open={open}
        width={800}
        extra={
          <Space>
            <Button onClick={onCloseDraw} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              // htmlType="submit"
              onClick={() => {
                setOpenCorfirmModal(true)
              }}
              loading={loading}
              disabled={multimediaType !== 'text' && type !== 'text' && (((chooseFile === 1 && isEmpty(fileUpload)) || isEmpty(title) || (chooseFile === 2 && isEmpty(fileOption))))}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
      >
        <Form
          className="add-learning-materials"
          layout={"vertical"}
          form={form}
          name="add-chapter"
        >
          <>
            <Row>
              <Col span={6} className="setup-time-title">
                Thông tin chung
              </Col>
              <Col span={18} className="learning-materials-content">
                <Form.Item
                  label={"Tiêu đề"}
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Thông tin này bắt buộc",
                    },
                  ]}
                >
                  <FormInput placeholder="Nhập tiêu đề" onChange={(e) => setTitle(e.target.value)}/>
                </Form.Item>
                <Form.Item label={"Mô tả"} name="description">
                  <FormInput placeholder="Nhập mô tả" />
                </Form.Item>
                <Form.Item label={"Icon"} name="icon">
                  <Select defaultValue={1} style={{ width: 120 }}>
                    {multimediaIconList.map((it) => (
                      <Option key={it.value} value={it.value}>
                        <img
                          src={it.iconUrl}
                          alt={it.type}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {
              multimediaType !== 'text' && type !== 'text' ?
                <Row>
                  <Col span={6} className="setup-time-title">
                    Chọn file học liệu
                  </Col>
                  <Col span={18} className="learning-materials-content">
                    <Radio.Group value={chooseFile}>
                      <div className="radio-item">
                        <Radio value={1} onChange={handleRadioChange}>
                          <Typography.Title className="add-chapter-title" level={5}>
                            {renderTitle()} từ thiết bị
                          </Typography.Title>
                        </Radio>
                        <div className="add-chapter-description">
                          <h2>
                            Chọn một {renderTitle()} từ thiết bị cá nhân của bạn
                          </h2>
                          <Form.Item
                            label={renderTitle()}
                            rules={[
                              {
                                required: chooseFile === 1,
                                message: "Thông tin này bắt buộc",
                              },
                            ]}
                            name="video"
                            className="mb-0"
                          >
                            <FileUploader
                              beforeUpload={beforeUpload}
                              listType="picture"
                              accept={returnAccept()}
                              maxCount={1}
                              onChange={handleChangeFileUpload}
                              onRemove={() => setFileUpload(null)}
                              prefix={getS3Prefix()}
                            >
                              <Button icon={<UploadOutlined />}>
                                Tải {renderTitle()}
                              </Button>
                            </FileUploader>
                          </Form.Item>
                          {multimediaType === 'xAPI' ? <p>Vui lòng tải tệp xAPI, Dung lượng tối đã 100MB</p> : null}
                          {multimediaType === 'PDF' ? <p>Vui lòng tải tệp PDF, Dung lượng tối đã 100MB</p> : null}

                          {fileUpload ? (
                            type === "video" ? (
                              <ReactPlayer
                                url={fileUpload}
                                controls
                                width="100%"
                                height="100%"
                                style={{ marginTop: "20px" }}
                              />
                            ) : (
                              <iframe
                                allow="fullscreen"
                                scrolling="yes"
                                width="100%"
                                height="100%"
                                src={fileUpload}
                                style={{ marginTop: "20px" }} />
                            )
                          ) : null}
                        </div>
                      </div>
                      <div className="radio-item">
                        <Radio value={2} onChange={handleRadioChange}>
                          <Typography.Title className="add-chapter-title" level={5}>
                            {renderTitle()} từ thư viện
                          </Typography.Title>
                        </Radio>

                        {chooseFile === 2 && (
                          <div className="add-chapter-description">
                            <Form.Item
                              label={""}
                              name="selectVideo"
                              rules={[
                                {
                                  required: chooseFile === 2,
                                  message: "Thông tin này bắt buộc",
                                },
                              ]}
                            >
                              <FormItemDropdown placeholder="Chọn file từ thư viện--" onChange={setFileOption}
                                options={fileListOption} />
                            </Form.Item>
                          </div>
                        )}

                        <div className="describe-text">
                          Chọn một {renderTitle()} từ thiết bị cá nhân của bạn
                        </div>
                      </div>
                    </Radio.Group>
                  </Col>
                </Row> :
                <Row className="mb-5 mt-4">
                  <Col span={6} className="setup-time-title">
                    Văn bản
                  </Col>
                  <Col span={18} className="learning-materials-content">
                    <Form.Item
                      label={""}
                      name="text"
                      rules={[
                        {
                          required: multimediaType === 'text',
                          message: "Thông tin này bắt buộc",
                        },
                      ]}
                    >
                      <ReactQuill
                        modules={modules}
                        theme="snow"
                        formats={FORMAT_REACT_QUILL}
                        // onChange={setValue}
                        placeholder="Nhập text"
                      />
                    </Form.Item>
                  </Col>
                </Row>
            }
            <Row>
              <Col span={6}>
                <div className="setup-time-title">Chọn tệp đính kèm</div>
                {
                  multimediaType !== 'xAPI' && multimediaType !== 'PDF' && type !== "xapi" && type !== "pdf" ?
                    <div className="describe-text">
                      Bạn có thể cung cấp cho sinh viên nội dung đính kèm cùng với
                      học liệu.
                    </div> : null
                }
              </Col>
              <Col span={18} className="learning-materials-content">
                <>
                  <div className="add-chapter-description">
                    <Form.Item name="attachFile">
                      <FileUploader
                        listType="picture"
                        multiple
                        beforeUpload={beforeUpload}
                        fileList={fileList}
                        prefix={getS3Prefix()}
                        onUploadSuccess={onUploadSuccess}
                        onChange={handleChangeFileAttachment}>
                        <Button icon={<UploadOutlined />}>
                          Tải tệp đính kèm
                        </Button>
                      </FileUploader>
                    </Form.Item>

                    {
                      !isEmpty(multimediaType) && !['xAPI', 'audio', 'PDF', 'text'].includes(multimediaType) ?
                        <Form.Item name="videoAttach" className="mb-0">
                          <FileUploader
                            // {...videoAttach}
                            beforeUpload={beforeUpload}
                            name="video-attachment"
                            action=""
                            accept=".mp4, .mpg, .avi, .webm, .m4v"
                            maxCount={1}
                            prefix={getS3Prefix()}
                            onChange={handleChangeVideoAttach}
                          >
                            <Button icon={<UploadOutlined />}>
                              Tải Video đính kèm
                            </Button>
                          </FileUploader>
                        </Form.Item>
                        : null
                    }
                    {videoAttachment ? (
                      <div className="video-attachment">
                        <ReactPlayer
                          url={videoAttachment}
                          controls
                          width="100%"
                          height="100%"
                        />
                        <DeleteOutlined
                          className="icon-delete"
                          onClick={handleDeleteVideoAttach}
                        />
                      </div>
                    ) : null}
                  </div>
                </>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={6}>
                <div className="setup-time-title">Quy tắc hoàn thành</div>
                <div className="describe-text">
                  Đặt ra các điều kiện mà người học phải đáp ứng để hoạt động
                  học tập này được coi là 'hoàn thành'.
                </div>
              </Col>
              <Col span={18} className="learning-materials-content">
                <div className="setup-time">
                  <div className="setup-time-title">
                    Hoạt động học này sẽ được coi là hoàn thành khi người học:
                  </div>
                  <div className="setup-time-time">
                    <div>
                      <span className="setup-time-title pe">Phút</span>
                      <Form.Item name="minutes">
                        <InputNumber min={0} max={60} changeOnWheel />
                      </Form.Item>
                    </div>
                    <div>
                      <span className="setup-time-title pe">Giây</span>
                      <Form.Item name="seconds">
                        <InputNumber min={0} max={59} changeOnWheel />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col span={6}>
                <div className="expected-time-title">Nỗ lực mong chờ</div>

              </Col>
              <Col span={12} className="learning-materials-content">
                <Row gutter={16}>
                  <Col span={10}>
                    <Form.Item name="expectedTime">
                      <InputNumber style={{ width: "100%" }} min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="expectedTimeType">
                      <FormItemDropdown style={{ width: "100%" }} options={optionTime} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

          </>

          <CommonModal
            open={openCorfirmModal}
            title={"Thông báo xác nhận lưu học liệu"}
            desc={
              <><p>Xin vui lòng xác nhận việc lưu học liệu trong khoá học</p><div>
                Đảm bảo rằng tất cả các{" "}
                <span className="text-browse">
                  thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                </span>
                trước khi lưu khoá học
              </div></>
            }
            closeIcon={true}
            okText={"Xác nhận"}
            cancelText={"Huỷ"}
            icon={
              <InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }}
              />
            }
            confirm={form.submit}
            closeModal={() => setOpenCorfirmModal(false)}
          />
        </Form>
      </Drawer>
    </Form.Provider>
  );
};

export default AddLearningMaterials;

interface ItemDetail {
  name: string;
  describe: string;
  id: number;
  video: any;
  block: any;
}
