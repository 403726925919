interface ChartData {
    type: number | null;
    criteria: string;
    values: Array<{
      count: number;
      percentage: number | null;
      criteria: string;
    }>;
  }
export const AreaStackedChartData:ChartData[] = [
    {
        "type": null,
        "criteria": "20/08",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/09",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/10",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/11",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/01",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/02",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/03",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/04",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/05",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/06",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "20/07",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "01/08",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "02/08",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "03/08",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    },
    {
        "type": null,
        "criteria": "05/08",
        "values": [
            {
                "count": 1,
                "percentage": null,
                "criteria": "Đa Phương Tiện"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Bài Tập/Bài Kiểm Tra/Bài Thi"
            },
            {
                "count": 0,
                "percentage": null,
                "criteria": "Tài Liệu Tham Khảo"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Bài giảng"
            },
            {
                "count": 1,
                "percentage": null,
                "criteria": "Khoá học"
            }
        ]
    }
]
export const lineChartData = [
    { criteria: "24/7", value: 1000 },
    { criteria: "25/7", value: 2100 },
    { criteria: "26/7", value: 3200 },
    { criteria: "27/7", value: 4300 },
    { criteria: "28/7", value: 5400 },
    { criteria: "29/7", value: 6500 },
    { criteria: "30/7", value: 7600 },
  ];
  
export const barChartData = [
    
    {
      type: 1,
      criteria:"abc",
      count: 7516,
      percentage:10
    },
    {
      type: 2,
      criteria:"qrs",
      count: 4915,
      percentage:10
    },
    {
      type: 3,
      criteria:"nop",
      count: 5321,
      percentage:10
    },
    {
      type: 4,
      criteria:"klm",
      count: 5861,
      percentage:10
    },
    {
      type: 5,
      criteria:"ghi",
      count: 6934,
      percentage:10
    },
    {
      type: 6,
      criteria:"def",
      count: 7488,
      percentage:50
    },
    {
      type: 7,
      criteria:"tuvwxy",
      count: 3549,
      percentage:10
    },
    
  ]