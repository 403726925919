import React, {useEffect} from 'react';

type HtmlToTextType = {
  HtmlContent: string
}

const HtmlToText = ({HtmlContent}: HtmlToTextType) => {
  const containerRef = React.useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = HtmlContent;
    }
  }, [HtmlContent])

  const getTextContent = () => {
    if (containerRef.current) {
      return containerRef.current.textContent || '';
    }
    return '';
  };

  return (
    <div>
      <p ref={containerRef}>{getTextContent()}</p>
    </div>
  );
};

export default HtmlToText;
