import { useEffect, useState, useMemo } from "react";
import { debounce } from "lodash";
import {
  DOWNLOAD_UNIT,
  SHARE_UNIT,
  VIEW_UNIT,
} from "../../../../../constants/static-report";
import {
  getSearchHistory,
  getSearchKeywordCount,
  getStaticUnitAction,
} from "../../../../../service/statistical-report";
import { staticReportStore } from "../../../../../stores/stores";
import {
  BarStackedChartProps,
  LineChartProps,
  PieChart,
} from "../../../../../types/static-report";
import UserBehaviorChartTab from "../chart/user-behavior-tab/UserBehaviorChartTab";
import UserBehaviorTableTab from "../table/report-list-tab/user-behavior-Table-tab/UserBehaviorTableTab";
import dayjs from "dayjs";

type Props = {
};

const UserBehaviorTab = (props: Props) => {
  const { staticReportType, filterValues, tab } = staticReportStore();

  const [chartData, setChartData] = useState({
    staticDownloadUnit: [] as LineChartProps[],
    staticViewUnit: [] as LineChartProps[],
    staticShareUnit: [] as LineChartProps[],
    searchHistory: [] as BarStackedChartProps[],
    searchKeywordCount: [] as PieChart[],
  });

  const [loading, setLoading] = useState({
    staticDownloadUnit: false,
    staticViewUnit: false,
    staticShareUnit: false,
    searchHistory: false,
    searchKeywordCount: false,
  });

  const payload = useMemo(
    () => ({
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      timeUnit: filterValues?.timeUnit,
    }),
    [
      filterValues.dateFrom,
      filterValues.dateTo,
      filterValues.courseLevelIds,
      filterValues.industryGroupIds,
      filterValues.courseIds,
      filterValues.timeUnit,
    ]
  );

  const fetchDownloadUnit = async () => {
    setLoading((prev) => ({ ...prev, staticDownloadUnit: true }));
    try {
      const data = await getStaticUnitAction({
        ...payload,
        unitActionType: DOWNLOAD_UNIT,
        courseStructureType: filterValues?.downloadCourseStructureType,
        moduleGroup: filterValues?.downloadModuleGroup,
      });
      setChartData((prevState) => ({
        ...prevState,
        staticDownloadUnit: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching staticDownloadUnit data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, staticDownloadUnit: false }));
    }
  };

  const fetchViewUnit = async () => {
    setLoading((prev) => ({ ...prev, staticViewUnit: true }));
    try {
      const data = await getStaticUnitAction({
        ...payload,
        unitActionType: VIEW_UNIT,
        courseStructureType: filterValues?.viewCourseStructureType,
        moduleGroup: filterValues?.viewModuleGroup,
      });
      setChartData((prevState) => ({
        ...prevState,
        staticViewUnit: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching staticViewUnit data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, staticViewUnit: false }));
    }
  };

  const fetchShareUnit = async () => {
    setLoading((prev) => ({ ...prev, staticShareUnit: true }));
    try {
      const data = await getStaticUnitAction({
        ...payload,
        unitActionType: SHARE_UNIT,
        courseStructureType: filterValues?.shareCourseStructureType,
        moduleGroup: filterValues?.shareModuleGroup,
      });
      setChartData((prevState) => ({
        ...prevState,
        staticShareUnit: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching staticShareUnit data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, staticShareUnit: false }));
    }
  };


  const fetchSearchKeywordCount = async () => {
    setLoading((prev) => ({ ...prev, searchKeywordCount: true }));
    try {
      const data = await getSearchKeywordCount({
        ...payload,
        courseStructureType: filterValues?.searchKeywordCourseStructureType,
        moduleGroup: filterValues?.searchKeywordModuleGroup,
      });
      setChartData((prevState) => ({
        ...prevState,
        searchKeywordCount: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching searchKeywordCount data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, searchKeywordCount: false }));
    }
  };

  const fetchSearchHistory = async () => {
    setLoading((prev) => ({ ...prev, searchHistory: true }));
    try {
      const data = await getSearchHistory({
        ...payload,
      });
      setChartData((prevState) => ({
        ...prevState,
        searchHistory: data?.data?.data?.statistic || data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching searchHistory data:", error);
    } finally {
      setLoading((prev) => ({ ...prev, searchHistory: false }));
    }
  };
  const debouncedFetchDownloadUnit = useMemo(
    () => debounce(fetchDownloadUnit, 500),
    [payload, filterValues?.downloadCourseStructureType, filterValues?.downloadModuleGroup]
  );
  const debouncedFetchViewUnit = useMemo(
    () => debounce(fetchViewUnit, 500),
    [payload, filterValues?.viewCourseStructureType, filterValues?.viewModuleGroup]
  );
  const debouncedFetchShareUnit = useMemo(
    () => debounce(fetchShareUnit, 500),
    [payload, filterValues?.shareCourseStructureType, filterValues?.shareModuleGroup]
  );
  const debouncedFetchSearchKeywordCount = useMemo(
    () => debounce(fetchSearchKeywordCount, 500),
    [payload, filterValues?.searchKeywordCourseStructureType, filterValues?.searchKeywordModuleGroup]
  );
  const debouncedFetchSearchHistory = useMemo(
    () => debounce(fetchSearchHistory, 500),
    [payload]
  );
  useEffect(() => {
    if (tab === "3") {
      debouncedFetchSearchKeywordCount();
    }
  }, [tab, debouncedFetchSearchKeywordCount]);

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchSearchHistory();
    }
  }, [tab, debouncedFetchSearchHistory]);

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchDownloadUnit();
    }
  }, [tab, debouncedFetchDownloadUnit]);

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchViewUnit();
    }
  }, [tab, debouncedFetchViewUnit]);

  useEffect(() => {
    if (tab === "3") {
      debouncedFetchShareUnit();
    }
  }, [tab, debouncedFetchShareUnit]);



  const memoizedStaticDownloadUnit = useMemo(
    () => chartData.staticDownloadUnit,
    [chartData.staticDownloadUnit]
  );
  const memoizedStaticViewUnit = useMemo(
    () => chartData.staticViewUnit,
    [chartData.staticViewUnit]
  );
  const memoizedStaticShareUnit = useMemo(
    () => chartData.staticShareUnit,
    [chartData.staticShareUnit]
  );
  const memoizedSearchHistory = useMemo(
    () => chartData.searchHistory,
    [chartData.searchHistory]
  );
  const memoizedSearchKeywordCount = useMemo(
    () => chartData.searchKeywordCount,
    [chartData.searchKeywordCount]
  );

  return (
    <div>
      {staticReportType === "chart" ? (
        <UserBehaviorChartTab
          loading={loading}
          staticDownloadUnit={memoizedStaticDownloadUnit}
          staticViewUnit={memoizedStaticViewUnit}
          staticShareUnit={memoizedStaticShareUnit}
          searchHistory={memoizedSearchHistory}
          searchKeywordCount={memoizedSearchKeywordCount}
        />
      ) : (
        <UserBehaviorTableTab
          loading={loading}
          staticDownloadUnit={memoizedStaticDownloadUnit}
          staticViewUnit={memoizedStaticViewUnit}
          staticShareUnit={memoizedStaticShareUnit}
          searchHistory={memoizedSearchHistory}
          searchKeywordCount={memoizedSearchKeywordCount}
        />
      )}
    </div>
  );
};

export default UserBehaviorTab;
