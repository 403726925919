import { Layout, Tabs, TabsProps } from "antd";
import { Editor } from "grapesjs";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseContentPage from "../../../../../components/course-content";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../../config/routes";
import { getTemplateByCourseId } from "../../../../../service/page-builder/page-builder";
import CourseSettingPage from "./course-setting";
import "./index.scss";
import IntroductionPage from "./introduction-page";
import BrowsingResults from "../../../../../components/course-construction/assign-course-construction/browsing-results";
import { getAllStatusReviewCourse } from "../../../../../service/content-course/contenCourseApi";
import ActionCourseHistory from "../../action-course-history";

const imgPath = "/images/";
const { Content } = Layout;

export default function DetailCourseApproval() {
  const location = useLocation();
  const [activeSvg, setActiveSvg] = useState("pc");
  const [template, setTemplate] = useState<any>();
  const [tab, setTab] = useState<string>("1");
  const [detailData, setDetailData] = useState<any>()
  const navigate = useNavigate()

  const listBreadcrumb = [
    {
      title: "Xây dựng khoá học",
    },
    {
      title: (
        <a
          href={`/course-construction/${routesConfig.courseBrowse}/${routesConfig.courseApproval}`}
        >
          Phê duyệt khoá học
        </a>
      ),
    },
    {
      title: location.state.name,
    },
  ];

  const params = useParams();


  const reloadAllTemplate = (editor: Editor, objTemplate: string) => {
    const parseCourseSavedTempalte = JSON.parse(objTemplate);
    editor.setComponents(parseCourseSavedTempalte.html);
    editor.setStyle(parseCourseSavedTempalte.css);
  };

  const getDetailTemplatesByCoueseId = (cb?: any) => {
    getTemplateByCourseId(params?.id).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  const onEditor = (editor: Editor) => {
    getDetailTemplatesByCoueseId((res: any) => {
      if (!isEmpty(res)) {
        reloadAllTemplate(editor, res.template);
      }
    });
  };

  const getDetailCourseData = async () => {
    await getAllStatusReviewCourse(params?.id).then(res => {
      setDetailData(res.data.data)
    })
  }

  useEffect(() => {
    getDetailCourseData()
  }, [])

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab?.toString())
    }
  }, [navigate])

  const items: TabsProps["items"] = [
    {
      key: "1",
      // icon: <UserOutlined />,
      label: "Trang giới thiệu",
      children: (
        <div className="wrapper-approve-page">
          <section className="introduction-page disabled-overflow">
            <div className="form-detail">
              <IntroductionPage mode={location.state?.mode} setTab={setTab} tab={tab} />
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "2",
      // icon: <HistoryOutlined />,
      label: "Nội dung khoá học",
      children: (
        <div className="wrapper-approve-page disabled-overflow">
          <section className="">
            <div className="form-detail">
              <CourseContentPage setTab={setTab} tab={tab} detailDataProps={detailData}/>
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "3",
      // icon: <LockOutlined />,
      label: "Kết quả duyệt",
      children: (
        <div className="">
          <section className="course-browse">
            <div className="form-detail">
              <BrowsingResults planInfo={location.state} detailData={detailData} />
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "4",
      // icon: <LockOutlined />,
      label: "Lịch sử thao tác",
      children: (
        <div className="wrapper-approve-page disabled-overflow">
          <section className="">
            <div className="form-detail">
              <ActionCourseHistory />
            </div>
          </section>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Content className="course-browse-detail">
        <PageHeader
          title={`Phê duyệt khoá học ${location.state.nameCourse}`}
          // title={location.state.nameCourse}
          // subTitle="Phê duyệt khoá học "
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            defaultActiveKey="1"
            items={items}
            activeKey={tab}
            onChange={setTab}
          />
        </div>
      </Content>
    </Layout>
  );
}
