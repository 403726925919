import React, {useState} from 'react';
import {Col, Row, TableColumnsType, TableProps} from "antd";
import TableReportCard from "../../chart/table-report-card";
import TableData from "../../../../../../components/table-data/TableData";

const BehaviorTable = () => {
  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")

  const tableQuatitySeachColumn: TableColumnsType<any> = [
    {
      title: "Từ khoá",
      dataIndex: "keyword",
      key: "keyword"
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "total"
    }
  ]
  const tableQuatityResourceColumn: TableColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time"
    },
    {
      title: "Khóa học",
      dataIndex: "course",
      key: "course"
    },
    {
      title: "Bài giảng",
      dataIndex: "section",
      key: "section"
    },
    {
      title: "Bài kiểm tra",
      dataIndex: "test",
      key: "test"
    },
    {
      title: "Tài liệu tham khảo",
      dataIndex: "document",
      key: "document"
    },
    {
      title: "Đa phương tiện",
      dataIndex: "multiMode",
      key: "multiMode"
    },
    {
      title: "Scorm & xAPI",
      dataIndex: "scorm&xAPI",
      key: "scorm&xAPI"
    }
  ]

  const tableColumnResource: TableColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time"
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity"
    },
  ]


  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng tìm kiếm
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <TableReportCard tableName="Số lươt tìm kiếm theo từ khoá"
                           content={<>
                             <TableData dataColumns={tableQuatitySeachColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
        <Col sm={{span: 16}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượt tìm kiếm tài nguyên theo thời gian"
                           content={<>
                             <TableData dataColumns={tableQuatityResourceColumn}
                                        handleTableChangeProps={handleTableChange}/>
                           </>}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượt xem, tải về, chia sẻ
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượt tải về tài nguyên" content={<>
            <TableData dataColumns={tableColumnResource} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượt xem tài nguyên" content={<>
            <TableData dataColumns={tableColumnResource} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <TableReportCard tableName="Số lượt chia sẻ tài nguyên" content={<>
            <TableData dataColumns={tableColumnResource} handleTableChangeProps={handleTableChange}/>
          </>}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default BehaviorTable;
