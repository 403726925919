import {
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Drawer,
  Form,
  message,
  Radio,
  RadioChangeEvent,
  Space,
  TimePicker,
  Typography,
} from "antd";
import dayjs, {Dayjs} from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, {useEffect, useState} from "react";
import {
  getSectionDetail,
  getSquenceDetail,
  getUnitDetailSetting,
  lessonSettingInstructions,
  lessonUnitInstructions,
  sectionsettingInstructions,
} from "../../../service/course-construct";
import {viLocale} from "../../../utils/pickerType";
import "./activeSettingsChaptersDrawer.scss";
import {createDisabledTime, formatDateTime} from "../../../utils/converDate";
// import { DisabledTimes } from "rc-picker/lib/interface";
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
type Props = {
  open?: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  isSection?: boolean;
  isLesson?: boolean;
  isUnit?: boolean;
  id?: number;
  handleDetail?: any;
  itemDetail?: any;
  itemDetailChapter?: any;
  lessonDetailData?: any;

};
dayjs.extend(utc);
dayjs.extend(timezone);


const ActiveSettingsChaptersDrawer = (props: Props) => {
  const {
    open,
    setIsOpened,
    isSection,
    isLesson,
    id,
    lessonDetailData,
    itemDetail,
    itemDetailChapter,
    handleDetail,
    isUnit
  } = props;

  const [form] = Form.useForm();
  const {Panel} = Collapse;
  const [availableStatus, setAvailableStatus] = useState<any>(null);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setSelectEndDate] = useState<Dayjs>(dayjs());
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);
  const [sectionDetail, setSectionDetail] = useState<any>(null);
  const [isSendLearner, setIsSendLearner] = useState<any>(false);
  const [isSendEntireSchool, setIsSendEntireSchool] = useState<any>(false);
  const [isHideSection, setIsHideSection] = useState<any>(false);
  const [scheduleType, setScheduleType] = useState<any>(false);
  const [detailChapter, setDetailChapter] = useState(null)
  const [detailLesson, setDetailLesson] = useState(null)

  const handleRadioChange = (e: RadioChangeEvent) => {
    setAvailableStatus(e.target.value);
  };
  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
    setStartTime(date);
  };
  const handleChangeEndDate = (date: any) => {
    setSelectEndDate(date);
    setEndTime(date);
  };
  const handleChangeStartTime = (time: any | null) => {
    setStartTime(time);
  };

  const handleChangeEndTime = (time: any | null) => {
    setEndTime(time);
  };

  const getSectionDetailApi = async () => {
    const apiParams = isUnit ? getUnitDetailSetting : (isLesson ? getSquenceDetail : getSectionDetail);
    await apiParams(Number(itemDetail?.id)).then((res) => {
      setSectionDetail(res.data);
    });
  };

  useEffect(() => {
    setAvailableStatus(sectionDetail?.availableStatus);
    setStartDate(
      sectionDetail?.scheduleStartDate
        ? dayjs.utc(sectionDetail?.scheduleStartDate)
        : null
    );
    setSelectEndDate(
      sectionDetail?.scheduleEndDate
        ? dayjs.utc(sectionDetail?.scheduleEndDate)
        : dayjs()
    );
    setStartTime(
      sectionDetail?.scheduleStartDate
        ? dayjs.utc(sectionDetail?.scheduleStartDate)
        : null
    );
    setEndTime(
      sectionDetail?.scheduleEndDate
        ? dayjs.utc(sectionDetail?.scheduleEndDate)
        : null
    );

    setIsSendLearner(sectionDetail?.emailNotificationLearner);
    setIsSendEntireSchool(sectionDetail?.emailNotificationAll);
    setIsHideSection(sectionDetail?.isHide);
    setScheduleType(sectionDetail?.scheduleType === 1)

  }, [sectionDetail]);


  useEffect(() => {
    if (isLesson) {
      setDetailChapter(itemDetailChapter)
    }
  }, [isLesson]);

  useEffect(() => {
    if (isUnit) {
      setDetailLesson(lessonDetailData)
    }
  }, [isUnit]);


  useEffect(() => {
    if (open) {
      getSectionDetailApi();
    }
  }, [open]);

  const radioItemList = [
    {
      value: 0,
      title: "Bản nháp",
      describe: "Mặc định khi khởi tạo. Người học sẽ không thể tìm kiếm hoặc nhìn thấy nội dung này ở trên tầng.",
    },
    {
      value: 1,
      title: "Riêng tư",
      describe: "Nội dung chỉ hiển thị với những Người học được Giảng viên đăng ký hoặc Người học đã trả phí.",
    },
    {
      value: 2,
      title: "Công khai",
      describe: "Nội dung được hiển thị công khai trên danh sách nội dung học và Người học có thể tìm kiếm, học nội dung.",
    },
  ];

  const onSaveSetting = () => {
    const formData = new FormData();
    const valueType: any = scheduleType ? 1 : 0
    formData.append("id", itemDetail?.id ?? null)
    formData.append("availableStatus", availableStatus ?? 0)
    formData.append("scheduleType", valueType ?? false)
    formData.append("scheduleStartDate", formatDateTime(startDate, startTime))
    formData.append("scheduleEndDate", formatDateTime(endDate, endTime))
    formData.append("emailNotificationAll", isSendEntireSchool ?? false)
    formData.append("emailNotificationLearner", isSendLearner ?? false)
    formData.append("isHide", isHideSection ?? false)

    const submitApiUrl = isUnit ? lessonUnitInstructions(formData) : (isLesson
      ? lessonSettingInstructions(formData)
      : sectionsettingInstructions(formData));

    try {
      const response = submitApiUrl.then((result: any) => {
        handleDetail();
        message.success(
          "Cài đặt hoạt động " + `${itemDetail?.name}` + " thành công"
        );
      });
    } catch (error) {
    }
  };
  const onClose = () => {
    setIsOpened(false);
    form.resetFields();
  };
  const disabledTime: (
    date: Dayjs,
    range?: "start" | "end" | undefined
  ) => any = (date) => createDisabledTime(startTime || endTime || null);

  const disabledDate = (current: any) => {
    // @ts-ignore
    return (current < dayjs(detailChapter?.schuduleStartDate) || current > dayjs(detailChapter?.schuduleEndDate))

  }

  const disabledDateUnit = (current: any) => {
    // @ts-ignore
    return (current < dayjs(detailLesson?.scheduleStartDate) || current > dayjs(detailLesson?.scheduleEndDate))

  }


  return (
    <Drawer
      title={"Cài đặt hoạt động " + `${itemDetail?.name}`}
      open={open}
      onClose={onClose}
      width={736}
      bodyStyle={{padding: "0px"}}
    >
      <Form
        className="active-settings-chapter"
        layout={"vertical"}
        form={form}
        name="active-settings-chapter"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="Truy cập" key="1" className="setting-chapter-title">
              <Radio.Group value={availableStatus}>
                {radioItemList.map((item, index) => (
                  <div key={index} className="radio-item wrapper-radio">
                    <Radio value={item.value} onChange={handleRadioChange}>
                      <Typography.Title
                        className="setting-chapter-title"
                        level={5}
                      >
                        {item.title}
                      </Typography.Title>
                    </Radio>
                    <div className="setting-chapter-description">
                      {item.describe}
                    </div>
                  </div>
                ))}
              </Radio.Group>
            </Panel>
            <Panel header="Lịch trình" key="2">
              {isLesson && (
                <div className="radio-item">
                  <Checkbox
                    checked={scheduleType}
                    onChange={(e) => setScheduleType(e.target.checked)}
                  />
                  <span className="setting-chapter-description">
                    Theo khoá học
                  </span>
                </div>
              )}
              {isUnit && (
                <div className="radio-item">
                  <Checkbox
                    checked={scheduleType}
                    onChange={(e) => setScheduleType(e.target.checked)}
                  />
                  <span className="setting-chapter-description">
                    Theo bài giảng
                  </span>
                </div>
              )}
              {!scheduleType ? (
                <>
                  <div className="time-picker">
                    <div className="time-picker-content">
                      <span>Ngày bắt đầu</span>
                      <DatePicker
                        onChange={handleChangeStartDate}
                        locale={viLocale}
                        format={"DD/MM/YYYY"}
                        value={startDate}
                        disabledDate={isLesson ? disabledDate : isUnit ? disabledDateUnit : undefined}
                        maxDate={endDate ? endDate : undefined}
                        minDate={startDate ? startDate : undefined}
                      />
                    </div>
                    <div className="time-picker-content">
                      <span>Giờ</span>
                      <TimePicker
                        locale={viLocale}
                        value={startTime}
                        onChange={handleChangeStartTime}
                        format={"HH:mm"}
                      />
                    </div>
                  </div>
                  <div className="time-picker">
                    <div className="time-picker-content">
                      <span>Ngày kết thúc</span>
                      <DatePicker
                        onChange={handleChangeEndDate}
                        locale={viLocale}
                        format={"DD/MM/YYYY"}
                        value={endDate}
                        disabledDate={isLesson ? disabledDate : isUnit ? disabledDateUnit : undefined}
                        minDate={startDate ? startDate : undefined}
                      />
                    </div>
                    <div className="time-picker-content">
                      <span>Giờ</span>
                      <TimePicker
                        locale={viLocale}
                        value={endTime}
                        onChange={handleChangeEndTime}
                        format={"HH:mm"}
                        // disabledTime={disabledTime}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </Panel>
            <Panel header="Email thông báo" key="3">
              <div className="radio-item">
                Xác định đối tượng nhận thông báo ngay khi chương mới được cập
                nhập
              </div>
              <div className="radio-item">
                <Checkbox
                  checked={isSendEntireSchool}
                  onChange={(e) => setIsSendEntireSchool(e.target.checked)}
                />
                <span className="setting-chapter-description">
                  Gửi email thông báo tới toàn trường
                </span>
              </div>
              <div className="radio-item">
                <Checkbox
                  checked={isSendLearner}
                  onChange={(e) => setIsSendLearner(e.target.checked)}
                />
                <span className="setting-chapter-description">
                  Gửi email thông báo tới người học
                </span>
              </div>
            </Panel>
          </Collapse>
          <div className="radio-item">
            <Checkbox
              checked={isHideSection}
              onChange={(e) => setIsHideSection(e.target.checked)}
            />
            <span className="setting-chapter-description">
              {isLesson ? "Ẩn bài giảng" : (isUnit ? "Ẩn học liệu" : "Ẩn chương")}
            </span>
            <div className="radio-item">
              Học viên không thể nhìn thấy nội dung nào trong {isUnit ? "học liệu" : "chương"} này
            </div>
          </div>
        </div>
        <Space className="btn-save-setting">
          <Button className="btn btn-primary" onClick={onSaveSetting}>
            <Typography.Text>Lưu</Typography.Text>
          </Button>
          <Button onClick={onClose} className="btn btn-outlined">
            <Typography.Text>Đóng</Typography.Text>
          </Button>
        </Space>
      </Form>
    </Drawer>
  );
};

export default ActiveSettingsChaptersDrawer;

