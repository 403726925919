import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"
import { axiosConfigToUpload } from "../../../config/api/configApiUpload"

export const getMoocPortfolioById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-portfolio/${id}`)
}

export const getAllPortfolioByName: (name: string) => Promise<AxiosResponse<any>> = (name) => {
    return axiosConfigV2.get(`/mooc-portfolio/find-by-name?name=${name}`)
}

export const downloadPortfolioTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-portfolio/download-template-excel`, {responseType: "arraybuffer",})
}

export const getAllByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio/get-all-by-filter`, data)
}

export const addMoocPortfolio: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio`, data)
}

export const exportExcelMoocPortfolio: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio/export-excel`, data, {responseType: "arraybuffer",})
}

export const importExcelMoocPortfolio: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/mooc-portfolio/import-excel`, data, {responseType: "arraybuffer",})
}

export const updateMoocPortfolio: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-portfolio`, data)
}

export const changeStatusPortfolio: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-portfolio/change-status`, data)
}

export const deleteMoocPortfolio: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-portfolio/${id}`)
}