import type { FC, ReactElement } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../stores/stores";
import { routesConfig } from "../../config/routes";

interface AuthGuardProps {
  children: ReactElement<any, any>;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const accessToken = useAuthStore((state) => state.accessToken);
  const expiresIn = useAuthStore((state) => state.expiresIn);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // Check first
    if (!accessToken) {
      navigate(routesConfig.login, {
        state: { returnUrl: location.pathname },
      });
    } else {
      setChecked(true);
    }
  }, [accessToken, location]);

  useEffect(() => {
    if (expiresIn) {
      const currentDate = new Date();
      const expireDate = new Date(expiresIn);
      if (currentDate > expireDate) {
        // check with BE later
        // const logout = useAuthStore.getState().logout;
        // logout();
      }
    }
  }, [location, expiresIn]);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return children;
};
