import React, {ChangeEvent, KeyboardEvent, useEffect, useMemo, useState,} from "react";
import {Button, Dropdown, Form, Layout, message, Popconfirm, Space, Switch, TableColumnsType, TableProps,} from "antd";
import {useNavigate, useSearchParams} from "react-router-dom";
import {DataType} from "../../types/listUsers";
import TableData from "../../components/table-data/TableData";
import PageHeader from "../../components/page-header/PageHeader";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, KeyOutlined, MoreOutlined} from "@ant-design/icons";
import {validateFormCreate} from "./constants/data";
import {get, isEmpty} from "lodash";
import {createAccountTeacher, getDataTeachers,} from "../../service/list-account-teachers/listAccountTeachersApi";
import FormCreateTeacher from "./components/form-create-teacher/FormCreateTeacher";
import {
  changeStatusAccount,
  getOffices,
  getRoles,
  getUnits,
} from "../../service/list-account-users/listAccountUsersApi";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import {HelpService} from "../../service/helper.service";
import {getCurrentPageFromLink} from "../../utils/getPageFromLink";
import {combineArray} from "../../utils/arrays";
import {formatOriginTime} from "../../utils/converDate";
import DetailLecturerAccountAdminDrawer from "../../components/drawer/lecturer-account";
import ChangePassLecturerDrawer from "../../components/drawer/lecturer-account/change-password";
import CommonModal from "../../components/modal/common";

type ListTeachersProps = {
  title?: string;
};

const ListTeacher = ({title}: ListTeachersProps) => {
  const helpService = new HelpService();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [isOpenedCreateTeacher, setIsOpenedCreateTeacher] =
    useState<boolean>(false);
  const [dataTeachers, setDataTeachers] = useState<DataType[]>([]);
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
  });
  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    status: null,
    approvedDate: null,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [form] = Form.useForm();
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const [isOpenLecturerAccountAdmin, setIsOpenLecturerAccountAdmin] = useState<boolean>(false)
  const [isOpenedConfirmDelete, setIsOpenedConfirmDelete] = useState<boolean>(false)

  const [isOpenChangePass, setIsOpenChangePass] = useState<boolean>(false)
  const [detailData, setDetailData] = useState<any>()

  const options = [
    {
      key: "1",
      label: "Đổi mật khẩu",
      icon: <KeyOutlined/>,
    },
    {
      key: "2",
      label: "Xoá tài khoản",
      icon: <DeleteOutlined/>,
      className: "dropdown-delete",
      danger: true,
    },
  ]

  const fetchData = () =>
    getDataTeachers({
      keySearch: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize
    }).then((res) => {
      const {data, totalRecords} = res.data.data;
      setDataTeachers(data);
      setTotalPage(totalRecords);
      setLoading(false);
    });

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber]);

  const dataColumnsTeachers: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'fullName',
      key: 'fullName',
      sorter: true,
      width: 30
    },
    {
      title: 'Họ và tên người dùng',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 30
    },
    {
      title: 'Trường đại học',
      dataIndex: 'phone',
      key: 'phone',
      width: 20
    },
    {
      title: 'Vai trò',
      dataIndex: 'vaiTros',
      key: 'vaiTros',
      render: (_: any, record: { vaiTros: string[] }) => {
        const length = record.vaiTros?.length
        return (
          record.vaiTros.map((item: string, index: number) => (
            <p key={index} className={`border rounded-md w-fit p-1 bg-gray-100 ${index !== 0 ? 'mt-1' : ''}`}>{item}</p>
          ))
        )
      },
      width: 30
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>{record?.createdDate ? formatOriginTime(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      render: (_: any, record: { trangThai: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          // record.trangThai = checked;
        }

        const confirm = (id: number) => {
          const data = {
            usedId: record?.id,
            isActive: !record?.trangThai
          }
          changeStatusAccount(data).then((res) => {
            const {statusCode} = res.data

            if (statusCode === 200) {
              helpService.successMessage(`${record?.trangThai ? 'Khóa' : 'Mở khóa'} tài khoản thành công`)
              // fetchData()
              record.trangThai = !record?.trangThai;

              // setPageNumber(1)
            }

          }).catch(err => {
          })
        }

        const cancel = () => {
        }

        return (
          <Popconfirm
            description={`Bạn có chắc chắn muốn ${record.trangThai ? 'khóa' : 'mở khóa'} tài khoản?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.trangThai}
                    checked={record.trangThai}
                    onChange={handleChange}
            />
          </Popconfirm>
        )
      },
      width: 20
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record) => {
        const location = {
          id: `${record.id}`
        }

        return (
          (
            <Space className="flex justify-center">
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenLecturerAccountAdmin(true)
                  setDetailData(record)
                }}
              >
                <EyeOutlined/>
              </a>
              <Dropdown
                menu={{
                  items: options,
                  onClick: ({key}) => {
                    if (key === '1') {
                      setIsOpenChangePass(true)
                      setDetailData(record)
                    }
                    if (key === '2') {
                      setIsOpenedConfirmDelete(true)
                      setDetailData(record)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined/>
                </Button>
              </Dropdown>
            </Space>
          )
        )
      },
      fixed: 'right',
      width: 15,
    }
  ];

  const defaultCheckedList = dataColumnsTeachers.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search);
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([
          getRoles(),
          getUnits(),
          getOffices(),
        ]);
        const dataRoles = response[0].data.data;
        const dataUnits = response[1].data.data;
        const dataOffices = response[2].data.data;
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnits,
          dataRoles: dataRoles,
        });
      };
      fetchDataOptions();
    }
  }, []);

  const columnsChecked = useMemo(() => {
    return dataColumnsTeachers.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const openFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleClose = () => {
    setIsOpened(false);
    form.resetFields();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    setParams({[name]: value});
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getDataTeachers({
        keyword: searchValue,
      })
        .then((users) => {
          const {data, totalRecords} = users.data.data;
          setDataTeachers(data);
          setTotalPage(totalRecords);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleSubmitSearch = () => {
    getDataTeachers({
      pageNumber: pageNumber,
      pageSize: pageSize,
      keyword: searchValue,
    })
      .then((users) => {
        const {data, totalRecords} = users.data.data;
        setDataTeachers(data);
        setTotalPage(totalRecords);
      })
      .catch((err) => console.error(err));

    if (searchValue === "") {
      getDataTeachers({})
        .then((users) => {
          const {data} = users.data.data;
          setDataTeachers(data);
        })
        .catch((err) => console.error(err));
    }
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      universityId: null,
      status: null,
      approvedDate: null,
    });
  }

  const onFinishCreate = (values: any) => {
    createAccountTeacher(values)
      .then((res) => {
        const {data, statusCode} = res.data;
        if (statusCode === 200) {
          helpService.successMessage(
            get(data, "message", "Tạo tài khoản thành công")
          );
          form.resetFields();
          fetchData();
          handleClose();
        }
      })
      .catch((err) => {
      });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({...filterData, [name]: value});
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleFinish = (values: any) => {
    setLoading(true);
    getDataTeachers({
      vaiTros: get(values, "vaiTros", []),
      donVis: combineArray(dataOptions.dataUnits, get(values, "donVis", [])),
      trangThai: get(values, "trangThai", []),
    }).then((res) => {
      const {data, totalRecords} = res.data.data;
      setLoading(false);
      setTotalPage(totalRecords);
      setDataTeachers(data);
    });
  };

  const deleteAccount = () => {
    setIsOpenedConfirmDelete(false)
    message.success("Xóa tài khoản thành công")
  }

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, {values, forms}) => {
          if (name === "createTeacher") {
            const {createTeacher} = forms;
            const data = createTeacher.getFieldsValue(validateFormCreate);
            onFinishCreate(data);
          }
          if (name === "filterForm") {
            fetchData()
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title}/>
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumnsTeachers}
              handleOpenFilter={openFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <TableData
          dataTable={dataTeachers}
          dataColumns={columnsChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{x: 1500, y: 600}}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />

        <FormCreateTeacher open={isOpened} handleClose={handleClose} fetchData={fetchData}/>
        <DetailLecturerAccountAdminDrawer
          open={isOpenLecturerAccountAdmin}
          onClose={() => setIsOpenLecturerAccountAdmin(false)}
          detailData={detailData}
        />

        <ChangePassLecturerDrawer
          open={isOpenChangePass}
          onClose={() => setIsOpenChangePass(false)}
          detailData={detailData}
          fetchData={fetchData}
        />

        <CommonModal
          open={isOpenedConfirmDelete}
          title={'Xác nhận xóa tài khoản'}
          desc={`Vui lòng xác nhận xóa tài khoản Giảng viên`}
          closeIcon={true}
          okText={'Xóa tài khoản'}
          cancelText={"Huỷ"}
          icon={<ExclamationCircleOutlined style={{color: "orange"}}/>}
          confirm={deleteAccount}
          closeModal={() => setIsOpenedConfirmDelete(false)}
          className="modal-delete-common"
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListTeacher;
