import { Col, Row, Spin, Empty, Select } from "antd";
import TableWithTotals from "../../../../../../../components/table-with-totals/TableWithTotals";
import StatisticalReportCard from "../../../StatisticalReportCard";
import {
  BarStackedChartProps,
  LineChartProps,
  PieChart,
} from "../../../../../../../types/static-report";
import { createTableData } from "../../tableConvertData";
import dayjs from "dayjs";
import { staticReportStore } from "../../../../../../../stores/stores";
import {
  exportSearchHistory,
  exportSearchKeywordCount,
  exportStaticUnitAction,
} from "../../../../../../../service/statistical-report";
import { chartContentTypes } from "../../../../../../../utils/staticReport";
import {
  DOWNLOAD_UNIT,
  SHARE_UNIT,
  VIEW_UNIT,
} from "../../../../../../../constants/static-report";

type Props = {
  loading: Record<string, boolean>;
  staticDownloadUnit: LineChartProps[];
  staticViewUnit: LineChartProps[];
  staticShareUnit: LineChartProps[];
  searchHistory: BarStackedChartProps[];
  searchKeywordCount: PieChart[];
};

const UserBehaviorTableTab = (props: Props) => {
  const {
    loading,
    staticDownloadUnit,
    staticViewUnit,
    staticShareUnit,
    searchHistory,
    searchKeywordCount,
  } = props;
  const { filterValues, setFilterValues } = staticReportStore();

  const columns = [
    { title: "Thời gian", dataIndex: "criteria", key: "criteria" },
    { title: "Số lượng", dataIndex: "total", key: "total" },
  ];
  const handleExport = async (
    apiEndpoint: (data: any) => Promise<any>,
    fileName: string,
    payloadOverride?: any
  ) => {
    const defaultPayload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      timeUnit: filterValues?.timeUnit,
    };

    const payloads = { ...defaultPayload, ...payloadOverride };

    try {
      const res = await apiEndpoint(payloads);

      const fileData = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(fileData);
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleParamsChange = (
    value: string,
    setFilterValues: Function,
    courseTypeKey: string,
    moduleGroupKey: string
  ) => {
    let courseStructureType = "";
    let moduleGroup = null;

    switch (value) {
      case "5":
        courseStructureType = "sequence";
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = "course";
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = "unit";
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      [courseTypeKey]: courseStructureType,
      [moduleGroupKey]: moduleGroup,
    });
  };
  return (
    <div>
      <>
        <div className="chart-title">Thống kê số lượng tìm kiếm</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tìm kiếm theo từ khoá"
              content={
                <Spin spinning={loading.searchKeywordCount}>
                  {searchKeywordCount?.length > 0 ? (
                    <TableWithTotals
                      data={searchKeywordCount?.map((it) => ({
                        ...it,
                        total: it.count,
                      }))}
                      columns={[
                        {
                          title: "Từ khoá",
                          dataIndex: "criteria",
                          key: "criteria",
                        },
                        { title: "Số lượng", dataIndex: "count", key: "count" },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "searchKeywordCourseStructureType",
                        "searchKeywordModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportSearchKeywordCount,
                      "Số lượng tìm kiếm theo từ khoá.xlsx",
                      {
                        courseStructureType:
                          filterValues?.searchKeywordCourseStructureType,
                        moduleGroup: filterValues?.searchKeywordModuleGroup,
                      }
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 16 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian"
              content={
                <Spin spinning={loading.searchHistory}>
                  {searchHistory?.length > 0 ? (
                    <TableWithTotals
                      data={createTableData(searchHistory).dataSource}
                      columns={[
                        {
                          title: "Thời gian tạo",
                          dataIndex: "name",
                          key: "name",
                        },
                        ...createTableData(searchHistory).columns,
                        {
                          title: "Tổng",
                          dataIndex: "Tổng",
                          key: "Tổng",
                        },
                      ]}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportSearchHistory,
                      "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian.xlsx"
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>

      <>
        <div className="chart-title">Thống kê số lượng tải về</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng tải về theo thời gian"
              content={
                <Spin spinning={loading.staticDownloadUnit}>
                  {staticDownloadUnit?.length > 0 ? (
                    <TableWithTotals
                      data={staticDownloadUnit?.map((it) => ({
                        ...it,
                        total: it.value,
                      }))}
                      columns={columns}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "downloadCourseStructureType",
                        "downloadModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportStaticUnitAction,
                      "Thống kê lượng tải về theo thời gian.xlsx",
                      {
                        unitActionType: DOWNLOAD_UNIT,
                        courseStructureType:
                          filterValues?.downloadCourseStructureType,
                        moduleGroup: filterValues?.downloadModuleGroup,
                      }
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng xem theo thời gian"
              content={
                <Spin spinning={loading.staticViewUnit}>
                  {staticViewUnit?.length > 0 ? (
                    <TableWithTotals
                      data={staticViewUnit?.map((it) => ({
                        ...it,
                        total: it.value,
                      }))}
                      columns={columns}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "viewCourseStructureType",
                        "viewModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportStaticUnitAction,
                      "Thống kê lượng xem theo thời gian.xlsx",
                      {
                        unitActionType: VIEW_UNIT,
                        courseStructureType:
                          filterValues?.viewCourseStructureType,
                        moduleGroup: filterValues?.viewModuleGroup,
                      }
                    ),
                },
              ]}
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng chia sẻ theo thời gian"
              content={
                <Spin spinning={loading.staticShareUnit}>
                  {staticShareUnit?.length > 0 ? (
                    <TableWithTotals
                      data={staticShareUnit?.map((it) => ({
                        ...it,
                        total: it.value,
                      }))}
                      columns={columns}
                      keyColumn="criteria"
                      height={275}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    defaultValue="6"
                    style={{ width: 170 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        setFilterValues,
                        "shareCourseStructureType",
                        "shareModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                    allowClear
                  />
                </div>
              }
              exportOptions={[
                {
                  label: "",
                  action: () =>
                    handleExport(
                      exportStaticUnitAction,
                      "Thống kê lượng chia sẻ theo thời gian.xlsx",
                      {
                        unitActionType: SHARE_UNIT,
                        courseStructureType:
                          filterValues?.shareCourseStructureType,
                        moduleGroup: filterValues?.shareModuleGroup,
                      }
                    ),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    </div>
  );
};

export default UserBehaviorTableTab;
