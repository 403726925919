import create from "zustand";
import { StateCreator } from "zustand";

export interface globalState {
  isFooterDefault: boolean;
  schoolId: string;
  reload: boolean;
  isFullscreenQuiz: boolean;
  openLoading: any;
  percentageProcessApi: number;
  setFooterDefault: () => void;
  setSchoolId: (id: string) => void;
  setReload: (data: boolean) => void;
  setIsFullscreenQuiz: (data: boolean) => void;
  setOpenLoading: (data: any) => void;
  setPercentageProcessApi: (data: any) => void;
}

export const globalSlice: StateCreator<globalState> = (set) => ({
  isFooterDefault: true,
  schoolId: "",
  reload: true,
  isFullscreenQuiz: false,
  openLoading: {},
  percentageProcessApi: 0,

  setFooterDefault: () => set((state) => ({ isFooterDefault: false })),
  setSchoolId: (state: string) => { set(() => ({ schoolId: state, })) },
  setReload: (value: boolean) => { set({ reload: value }) },
  setIsFullscreenQuiz: (value: boolean) => { set({ isFullscreenQuiz: value }) },
  setOpenLoading: (value: any) => { set({ openLoading: value }) },
  setPercentageProcessApi: (value: any) => { set({ percentageProcessApi: value }) },
});
