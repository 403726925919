import { Layout, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseContentPage from "../../../../../components/course-content";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../../config/routes";
import { getApproveInformationDetail } from "../../../../../service/course-construct";
import CourseSettingPage from "./course-setting";
import "./index.scss";
import IntroductionPage from "./introduction-page";
import { isEmpty } from "lodash";
import BrowsingResults from "../../../../../components/course-construction/assign-course-construction/browsing-results";
import { getDetailCourse } from "../../../../../service/assign-course-construction";
import ActionCourseHistory from "../../action-course-history";
import { getAllStatusReviewCourse } from "../../../../../service/content-course/contenCourseApi";

const imgPath = "/images/";
const { Content } = Layout;

type Props = {
  isCourseApprove?: string;
};
export default function DetailCourseBrowse(props: Props) {
  const location = useLocation();
  const navigate = useNavigate()
  const [tab, setTab] = useState<string>("1");

  const params = useParams()

  const [detailData, setDetailData] = useState<any>()

  const getDetailCourseData = async () => {
    await getAllStatusReviewCourse(params?.id).then(res => {
      setDetailData(res.data.data)
    })
  }

  useEffect(() => {
    getDetailCourseData()
  }, [])

  const listBreadcrumb = [
    {
      title: "Xây dựng khoá học",
    },
    {
      title: (
        <a
          href={`/course-construction/${routesConfig.courseBrowse}/${routesConfig.contentBrowse}`}
        >
          Duyệt khoá học
        </a>
      ),
    },
    {
      title: location.state.name,
    },
  ];

  useEffect(() => {
    if(location.state?.isSlide) {
      setTab("1")
    } else {
      setTab("2")
    }
    if (!isEmpty(location.search)) {
      setTab("2")
    } else {
      setTab("1")
    }
    if(location.state?.tab) {
      setTab("3")
    }
  }, [navigate])

  const items: TabsProps["items"] = [
    {
      key: "1",
      // icon: <UserOutlined />,
      label: "Trang giới thiệu",
      children: (
        <div className="wrapper-approve-page">
          <section className="introduction-page disabled-overflow">
            <div className="form-detail">
              <IntroductionPage mode={location.state?.mode} setTab={setTab} tab={tab} />
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "2",
      // icon: <HistoryOutlined />,
      label: "Nội dung khoá học",
      children: (
        <div className="wrapper-approve-page disabled-overflow">
          <section className="">
            <div className="form-detail">
              <CourseContentPage setTab={setTab} tab={tab} detailDataProps={detailData}/>
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "3",
      // icon: <LockOutlined />,
      label: "Kết quả duyệt",
      children: (
        <div className="">
          <section className="course-browse">
            <div className="form-detail">
              <BrowsingResults planInfo={location.state} detailData={detailData} />
            </div>
          </section>
        </div>
      ),
    },
    {
      key: "4",
      // icon: <LockOutlined />,
      label: "Lịch sử thao tác",
      children: (
        <div className="wrapper-approve-page disabled-overflow">
          <section className="">
            <div className="form-detail">
              <ActionCourseHistory />
            </div>
          </section>
        </div>
      ),
    },
  ];

  const renderSubtitle = (status: number) => {
    switch (status) {
      case 3:
      case 4:
        return "Duyệt";
      case 5:
      case 7:
        return "Thẩm định";
      case 6:
        return "Thẩm định lại";
      case 9:
      case 10:
        return "Phê duyệt";
      case 11:
        return "Phê duyệt lại";
      case 12:
        return "Xuất bản";
      case 13:
        return "Đã xuất bản";
      default:
        break;
    }
  };

  return (
    <Layout>
      <Content className="course-browse-detail">
        <PageHeader
          title={`${renderSubtitle(location.state.status)} ${location.state.name}`}
          // subTitle={renderSubtitle(location.state.status)}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            defaultActiveKey="1"
            items={items}
            activeKey={tab}
            onChange={setTab}
          />
        </div>
      </Content>
    </Layout>
  );
}
