import {
  ContactsOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";
import { useStudyManagementStore } from "../../../../stores/stores";
import RemindCheckInDetailForm from "./RemindCheckInDetailForm";
import ReportCheckIn from "./report-checkin";
const imgPath = "/images/";

const { Content } = Layout;

const RemindCheckInDetail = () => {
  const navigate = useNavigate();

  const params = useParams();
  // const [title, setTitle] = useState("");
  const { numberTab, title } = useStudyManagementStore((state) => state);
  const listBreadcrumb = [
    {
      title: <Link to={`/management-study/${routesConfig.settingRemindCheckIn}`}>Quản lý điểm danh</Link>,
    },
    {
      title: title,
    },
  ];
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ContactsOutlined />,
      label: "Cài đặt nhắc nhở",
      children: (
        <RemindCheckInDetailForm id={params.id as string} />
      ),
    },
    {
      key: "3",
      icon: <FileTextOutlined />,
      label: "Báo cáo",
      children: <ReportCheckIn title="" />,
    },
  ];

  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={title}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(`/management-study/${routesConfig.settingRemindCheckIn}`)}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey={numberTab} items={items} />
      </div>
    </Content>
  );
};

export default RemindCheckInDetail;
