import { useRef } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const useChartExport = () => {
  const chartRefs = useRef<{ [key: string]: any }>({});

  const createChartRef = (chartKey: string) => (ref: any) => {
    if (ref && ref.getChart) {
      chartRefs.current[chartKey] = ref.getChart();
    }
  };

  const handleExportImage = (chartKey: string, format: string) => {
    const chart = chartRefs.current[chartKey];
    if (chart) {
      chart.exportChart({
        type: format,
        filename: chartKey,
      });
    } else {
      console.warn(`No chart found for key: ${chartKey}`);
    }
  };

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleExportAllImagesAsZip = async () => {
    const zip = new JSZip();
    const exportUrl = "https://export.highcharts.com/";

    const promises = Object.keys(chartRefs.current).map(
      async (chartKey, index) => {
        const chart = chartRefs.current[chartKey];
        if (chart) {
          const svgData = chart.getSVG();

          const formData = new FormData();
          formData.append("filename", chartKey);
          formData.append("type", "image/png");
          formData.append("svg", svgData);

          try {
            await delay(1000 * index);

            const response = await fetch(exportUrl, {
              method: "POST",
              body: formData,
            });

            if (!response.ok) {
              throw new Error(`Error: ${response.statusText}`);
            }

            const blob = await response.blob();
            zip.file(`${chartKey}.png`, blob);
          } catch (error) {
            console.error(`Failed to export chart: ${chartKey}`, error);
          }
        } else {
          console.warn(`No chart found for key: ${chartKey}`);
        }
      }
    );

    await Promise.all(promises);

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "charts.zip");
    });
  };
  return { createChartRef, handleExportImage, handleExportAllImagesAsZip };
};

export default useChartExport;
