import {
  ForwardedRef,
  FunctionComponent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Input,
  Layout,
  Modal,
  Radio,
  Select,
  Tabs,
  TabsProps,
  TreeSelect,
} from "antd";
import { useLocation, useParams } from "react-router-dom";
import { message } from "antd/lib";
import dayjs from "dayjs";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Badge,
  Typography,
} from "antd";
import { getMoocCourseConstructionPlanById } from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { useAppStore } from "../../../../../stores/stores";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { DetailContent } from "../detail-content/DetailContent";
import { getSpecializedGroupDetail } from "../../../../../service/course-plan-managment/specialized-group/specializedGroupApi";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import {
  deleteMoocOrganizationMemberMany,
  postCreateManyMoocOrganizationMember,
  postCreateMoocOrganizationMember,
  updateMoocOrganizationMemberMany,
} from "../../../../../service/course-plan-managment/mooc-organization-member/mooc-organization-member-api";
import { faIRIntl } from "@ant-design/pro-components";
import { getDataTeachers } from "../../../../../service/list-account-teachers/listAccountTeachersApi";
import { getTeacherSpecial } from "../../../../../service/course-plan-managment/specialized-group/specializedGroupApi";
import { DataType } from "../../../../../types/listUsers";
import { random } from "lodash";
const { Content } = Layout;
const imgPath = "/images/";
type FormSpecializedGroupMemberProps = {
  detailData: any;
  memberList: any;
  isDisable: any;
};

const FormSpecializedGroupMemberProp: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  FormSpecializedGroupMemberProps
> = (
  { detailData, memberList, isDisable },
  ref: ForwardedRef<HTMLButtonElement>
) => {
  type DataGroupMemeber = {
    id: number;
    organizationId: number;
    organizationMemberId: number;
    isLeader: any;
    name?: string;
    image_path?: Boolean;
    user_id?: Number;
  };
  type DataGiangVienDto = {
    id: number;
    fullName: string;
    phone: string;
    email: string;
    image?: string;
    anhDaiDien: string;
  };
  const { id } = useParams();
  const myRef = useRef(null);
  let listDataMemberUpdate: DataGroupMemeber[];

  const [listDataMember, setListDataMember] = useState<DataGroupMemeber[]>([]);
  const [listDataMemberCreate, setListDataMemberCreate] = useState<
    DataGroupMemeber[]
  >([]);
  const [listDataMemberDelete, setListDataMemberDelete] = useState<
    DataGroupMemeber[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [position, setPosition] = useState(1);
  const [detailListData, setDetailListData] = useState<any>({});
  const [isChecked, setIsChecked] = useState(false);
  const [dataTeachers, setDataTeachers] = useState<any[]>([]);
  const [listValue, setListValue] = useState<any[]>([]);
  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });

  const params = useParams();
  const location = useLocation();

  const getListDataMemberById = async (id: any) => {
    setLoading(true);
    // console.log(id);
    try {
      await getTeacherSpecial().then((res) => {
        const { data } = res;
        setDataTeachers(
          data.map((member: any) => ({
            value: member.id,
            label: member.fullName,
          }))
        );
        setLoading(false);
      });
      await getSpecializedGroupDetail(id).then((res) => {
        const data = res.data;
        console.log(data);
        setDetailListData(data);
        setListDataMember(data.member);
        if (data.member.length === 0) {
          for (let index = 0; index < 5; index++) {
            setListDataMember((previousData) => [
              {
                id: Math.floor(Math.random() * 1000),
                organizationId: parseInt(`${id}`),
                organizationMemberId: 0,
                isLeader: 0,
              },
              ...previousData,
            ]);
          }
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    getListDataMemberById(id);

    // console.log(2);
  }, [id]);
  // useEffect(() => {
  //   setListValue(listDataMember.concat(listDataMemberCreate))
  // },[listDataMember,listDataMemberCreate]);
  useEffect(() => {
    if (listDataMember) {
      // console.log(listDataMemberCreate)
      const update = dataTeachers.map((e) => {
        let data: any = { value: e.value, label: e.label };
        for (let index = 0; index < listDataMember.length; index++) {
          const element = listDataMember[index];
          if (e.value.toString() === element.organizationMemberId.toString()) {
            data = {
              value: e.value,
              label: e.label,
              disabled: true,
            };
            break;
          }
        }
        return data;
      });
      // console.log(update);
      // console.log(1);
      setDataTeachers(update);
    }
    // if()
  }, [listDataMember]);
  const filterOption = (input: any, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const [listHtmlData, setListHtmlData] = useState<any[]>();
  const changePosition = (e: any) => {
    // console.log(e.target.value);
    setPosition(e.target.value);
  };
  useEffect(() => {
    listDataMemberUpdate = [...listDataMember];
    // setListHtmlData(htmlData);
  }, [detailListData, listDataMember, dataTeachers]);
  const handleCheck = (a: Number) => {
    setIsChecked(!isChecked);
  };
  const handleSave = async () => {
    await updateMoocOrganizationMemberMany(listDataMember)
      .then((res) => {
        message.success("Lưu thay đổi thành viên tổ chuyên môn thành công");
      })
      .catch((e) => {
        message.error("Có lỗi xảy ra");
      });
  };
  const deleteDataMember = (element: any) => {
    setListDataMember((previousData) =>
      previousData.filter((member) => member.id !== element.id)
    );
  };
  const handleLeaderChange = (index: number, value: number) => {
    const updatedMembers = listDataMember.map((member, idx) => {
      return {
        ...member,
        isLeader: idx === index ? value : 0, // Chỉ đặt "Chủ tịch" cho thành viên đã chọn
      };
    });

    setListDataMember(updatedMembers);
  };
  const [form] = Form.useForm();

  return (
    <DetailContent>
      <CustomCard
        className="card-info--wrapper"
        title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              {detailData.organizationCode +
                " - " +
                detailData.organizationName}
            </Typography.Title>
            <Typography.Text
              className="sub-title"
              style={{ width: "90%", wordWrap: "break-word" }}
            >
              {detailData.organizationRemark}
            </Typography.Text>
          </div>
        }
      ></CustomCard>
      <CustomCard
        className="card-info--wrapper"
        title={
          <div className="card-heading--info">
            <Typography.Title style={{ fontSize: "17px" }}>
              Thành viên chuyên môn
            </Typography.Title>
          </div>
        }
      >
        <Typography.Title style={{ fontSize: "17px" }}></Typography.Title>

        <Form name="dynamic_form_nest_item" form={form} autoComplete="off">
          <div>
            {listDataMember.map((item: any, index: any) => (
              <div
                style={{
                  marginBottom: 20,
                  padding: 10,
                  backgroundColor: "#FAFAFA",
                }}
                key={item.id}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontSize: "15px", fontWeight: 5 }}>
                    Thành viên
                  </div>
                  <div>
                    <Button
                      disabled={isDisable}
                      style={{ width: 40, height: 30 }}
                      onClick={() => {
                        Modal.confirm({
                          title: `Xóa thành viên ${item.name}`,
                          content: `Bạn có chắc chắn muốn xóa thành viên?`,
                          onOk: () => {
                            const newList = item;
                            deleteDataMember(item);
                            setListDataMemberDelete((e) => [newList, ...e]);
                          },
                          okText: "Lưu",
                          cancelText: "Huỷ",
                          centered: true,
                          icon: (
                            <ExclamationCircleOutlined
                              style={{ color: "#1677FF" }}
                            />
                          ),
                          footer: (_, { OkBtn, CancelBtn }) => (
                            <>
                              <CancelBtn />
                              <OkBtn />
                            </>
                          ),
                        });
                      }}
                      danger
                    >
                      Xóa
                    </Button>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <Form.Item style={{ width: "40%" }}>
                    <Select
                      disabled={isDisable}
                      showSearch
                      optionFilterProp="children"
                      defaultValue={listDataMember[index].name}
                      options={dataTeachers}
                      filterOption={filterOption}
                      allowClear={true}
                      placeholder="Chọn thành viên"
                      onChange={(e) => {
                        listDataMemberUpdate[index].organizationMemberId =
                          parseInt(e);
                        setListDataMember(listDataMemberUpdate);
                      }}
                    />
                  </Form.Item>
                  <div style={{ marginLeft: 30 }}>
                    <Form.Item label="">
                      <Radio.Group
                        disabled={isDisable}
                        onChange={(e) =>
                          handleLeaderChange(index, e.target.value)
                        }
                        value={listDataMember[index].isLeader}
                      >
                        <Radio value={1}>Tổ trưởng</Radio>
                        <Radio value={0} style={{ marginLeft: 10 }}>
                          Thành viên
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {listDataMember.length !== dataTeachers.length ? (
            <Form.Item style={{ width: "150px" }}>
              <Button
                disabled={isDisable}
                type="dashed"
                onClick={() =>
                  setListDataMember((previousData) => [
                    ...previousData,
                    {
                      id: Math.floor(Math.random() * 1000),
                      organizationId: detailListData.id,
                      organizationMemberId: 0,
                      isLeader: 0,
                    },
                  ])
                }
                block
                icon={<UserAddOutlined />}
              >
                Thêm thành viên
              </Button>
            </Form.Item>
          ) : (
            <span></span>
          )}
        </Form>
        <Form name="dynamic_form_nest_item" form={form} autoComplete="off">
          <Form.Item>
            <Button
              ref={ref}
              style={{ display: "none" }}
              type="primary"
              onClick={handleSave}
            >
              Lưu
            </Button>
          </Form.Item>
        </Form>
      </CustomCard>
    </DetailContent>
  );
};
const FormSpecializedGroupMember = forwardRef<
  HTMLButtonElement,
  FormSpecializedGroupMemberProps
>(FormSpecializedGroupMemberProp);

export default FormSpecializedGroupMember;
