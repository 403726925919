import { Key, ReactNode, useEffect, useState } from "react";
import "./sidebar.scss";
import Sider from "antd/es/layout/Sider";
import { Menu, theme } from "antd";
import { MenuItem } from "../../types/sidebar";
import {
  ApartmentOutlined,
  BellOutlined,
  BookOutlined,
  ContainerOutlined,
  FormOutlined,
  GlobalOutlined,
  SettingFilled,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { isEmpty } from "lodash";
import { useAddLesson } from "../../stores/stores";
import { QuestionData } from "../../types/course-construction";
import SafeLink from "../../components/link/SafeLink";
import { getPermission } from "../../service/common";
import { listItems } from "../../constants";

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: string
): MenuItem {
  return {
    key,
    label,
    icon,
    children,
    type,
  } as MenuItem;
}

interface SidebarProps {
  selectedMenu: MenuItem[];
  collapsedContent: boolean;
  isHideSider: boolean;
}

getItem(
  <SafeLink to={routesConfig.roleManagement}>Quản lý vai trò</SafeLink>,
  routesConfig.roleManagement,
  <ApartmentOutlined />
),
  getItem("Khảo sát", "survey-student", <TeamOutlined />, [
    getItem(
      <SafeLink to="/survey-student/training-need">
        Khảo sát nhu cầu đào tạo
      </SafeLink>,
      "training-need"
    ),
  ]),
  getItem("Khảo sát nhu cầu đào tạo", "survey-training", <BookOutlined />, [
    getItem(
      <SafeLink to={`/survey-training/list-survey`}>
        Quản lý đợt khảo sát
      </SafeLink>,
      `/survey-training/list-survey`
    ),
    getItem(
      <SafeLink to={`/survey-training/browse-survey`}>Duyệt khảo sát</SafeLink>,
      `/survey-training/browse-survey`
    ),
    getItem(
      <SafeLink to={`/survey-training/${routesConfig.surverList}`}>
        Tổng hợp kết quả khảo sát
      </SafeLink>,
      `/survey-training/${routesConfig.surverList}`
    ),
  ]),
  getItem("Kế hoạch khóa học", "course-plan", <BookOutlined />, [
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.registerCoursePlan}/${routesConfig.moocCourseConstructionPlan}`}
      >
        Đăng ký kế hoạch khóa học
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.registerCoursePlan}/${routesConfig.moocCourseConstructionPlan}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`}
      >
        Duyệt đăng ký kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`}
      >
        Phân công lập kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.moocCourseConstructionPlan}`}
      >
        Lập kế hoạch khóa học
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.moocCourseConstructionPlan}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.planningApproval}`}
      >
        Duyệt lập kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.planningApproval}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.specializedGroupList}`}
      >
        Lập tổ chuyên môn
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.specializedGroupList}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.organizeAppraisalBoard}`}
      >
        Lập hội đồng thẩm định
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.organizeAppraisalBoard}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.submitApprovalPlan}`}
      >
        Gửi phê duyệt kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.submitApprovalPlan}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.coursePlan}/${routesConfig.submitAppraisalPlan}`}
      >
        Gửi thẩm định kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.submitAppraisalPlan}`
    ),
    getItem(
      <SafeLink to={`/${routesConfig.coursePlan}/${routesConfig.approval}`}>
        Phê duyệt kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.approval}`
    ),
    getItem(
      <SafeLink to={`/${routesConfig.coursePlan}/${routesConfig.appraisal}`}>
        Thẩm định kế hoạch
      </SafeLink>,
      `/${routesConfig.coursePlan}/${routesConfig.appraisal}`
    ),
  ]),
  getItem("Xây dựng khoá học", "course-construction", <BookOutlined />, [
    //   getItem(<Link to={`/course-construction/${routesConfig.courseConstructionManagement}`}>
    //   Quản lý xây dựng khoá học
    // </Link>, `/course-construction/${routesConfig.courseConstructionManagement}`),
    getItem(
      <Link
        to={`/course-construction/${routesConfig.assignCourseConstruction}`}
      >
        Phân công xây dựng khoá học
      </Link>,
      `/course-construction/${routesConfig.assignCourseConstruction}`
    ),
    getItem(
      <Link to={`/course-construction/${routesConfig.contentConstruction}`}>
        Xây dựng nội dung
      </Link>,
      `/course-construction/${routesConfig.contentConstruction}`
    ),
    getItem("Kiểm định nội dung", "course-browse", null, [
      getItem(
        <Link
          to={`/course-construction/course-browse/${routesConfig.contentBrowse}`}
        >
          QTKH duyệt
        </Link>,
        `/course-construction/course-browse/${routesConfig.contentBrowse}`
      ),
      getItem(
        <Link
          to={`/course-construction/course-browse/${routesConfig.courseEvaluation}`}
        >
          Hội đồng thẩm định
        </Link>,
        `/course-construction/course-browse/${routesConfig.courseEvaluation}`
      ),
      getItem(
        <Link
          to={`/course-construction/course-browse/${routesConfig.courseApproval}`}
        >
          Tổ chuyên môn duyệt
        </Link>,
        `/course-construction/${routesConfig.courseApproval}`
      ),
      getItem(
        <Link
          to={`/course-construction/course-browse/${routesConfig.publishCourse}`}
        >
          Xuất bản khoá học
        </Link>,
        `/course-construction/${routesConfig.publishCourse}`
      ),
    ]),
    getItem(
      <Link to={`/course-construction/${routesConfig.surveyPlan}`}>
        Kế hoạch khảo sát
      </Link>,
      `/course-construction/${routesConfig.surveyPlan}`
    ),
  ]),
  getItem("Quản lý danh mục", "category-management", <SettingOutlined />, [
    getItem(
      <SafeLink
        to={`/category-management/${routesConfig.sharedCategoriesManagement}`}
      >
        Quản lý danh mục dùng chung
      </SafeLink>,
      `/category-management/${routesConfig.sharedCategoriesManagement}`
    ),
    getItem(
      <SafeLink
        to={`/category-management/${routesConfig.portfolioValueManagement}`}
      >
        Quản lý giá trị danh mục
      </SafeLink>,
      `/category-management/${routesConfig.portfolioValueManagement}`
    ),
    getItem(
      <SafeLink
        to={`/category-management/${routesConfig.universityManagement}`}
      >
        Quản lý Trường đại học
      </SafeLink>,
      `/category-management/${routesConfig.universityManagement}`
    ),
    getItem(
      <SafeLink to={`/category-management/${routesConfig.lecturerManagement}`}>
        Quản lý giảng viên
      </SafeLink>,
      `/category-management/${routesConfig.lecturerManagement}`
    ),
    getItem(
      <SafeLink to={`/category-management/${routesConfig.studentManagement}`}>
        Quản lý sinh viên
      </SafeLink>,
      `/category-management/${routesConfig.studentManagement}`
    ),
  ]),
  getItem(
    <SafeLink to={`/${routesConfig.portalAdministration}`}>
      Quản trị cổng thông tin
    </SafeLink>,
    routesConfig.portalAdministration,
    <GlobalOutlined />
  ),
  getItem("Quản trị blog & diễn đàn", "blog-and-forum", <FormOutlined />, [
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.blogContent}`}
      >
        Quản trị nội dung blog
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.blogContent}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.designThemeBlogContent}`}
      >
        Thiết lập giao diện
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.designThemeBlogContent}`
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.manageContentForum}`}
      >
        Quản lý chủ đề diễn đàn
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.manageContentForum}`
    ),
  ]),
  getItem("Quản lý khóa học", "management-study", <SettingFilled />, [
    getItem(
      <Link to={`/management-study/${routesConfig.settingRemindCheckIn}`}>
        Cài đặt nhắc nhở điểm danh
      </Link>,
      `/management-study/${routesConfig.settingRemindCheckIn}`
    ),
    getItem(
      <Link to={`/management-study/${routesConfig.course}`}>
        Danh sách khoá học
      </Link>,
      `/management-study/${routesConfig.course}`
    ),
  ]),
  getItem(
    "Hệ thống gợi ý khoá học",
    "system-suggest-course",
    <BookOutlined />,
    [
      getItem(
        <Link
          to={`/system-suggest-course/${routesConfig.learningDocumentationSystem}`}
        >
          Hệ thống học liệu
        </Link>,
        `/system-suggest-course/${routesConfig.learningDocumentationSystem}`
      ),
      getItem(
        <Link
          to={`/system-suggest-course/${routesConfig.synthesisReportSystem}`}
        >
          Báo cáo tổng hợp
        </Link>,
        `/system-suggest-course/${routesConfig.synthesisReportSystem}`
      ),
      getItem(
        <Link to={`/system-suggest-course/${routesConfig.listStudentSystem}`}>
          Danh sách sinh viên
        </Link>,
        `/system-suggest-course/${routesConfig.listStudentSystem}`
      ),
    ]
  ),
  getItem("Mô hình dự đoán xu hướng", "prediction-model", <BookOutlined />, [
    getItem(
      <Link to={`/prediction-model/${routesConfig.managementGroupContent}`}>
        Quản lý nhóm nội dung
      </Link>,
      `/prediction-model/${routesConfig.managementGroupContent}`
    ),
    getItem(
      <Link to={`/prediction-model/${routesConfig.settingPrediction}`}>
        Cài đặt phân tích
      </Link>,
      `/prediction-model/${routesConfig.settingPrediction}`
    ),
  ]),
  getItem(
    <SafeLink to={`${routesConfig.scoringListCourse}`}>
      Quản lý chấm điểm
    </SafeLink>,
    routesConfig.scoringListCourse,
    <ContainerOutlined />
  ),
  getItem(
    <SafeLink to={`${routesConfig.notifications}`}>Quản lý thông báo</SafeLink>,
    routesConfig.notifications,
    <BellOutlined />
  ),
  getItem("Cài đặt nâng cao", "setting-high-level", <SettingOutlined />, [
    getItem(
      <Link to={`/setting-high-level/${routesConfig.settingIdentify}`}>
        Cài đặt định danh
      </Link>,
      `/setting-high-level/${routesConfig.settingIdentify}`
    ),
    getItem(
      <Link to={`/setting-high-level/${routesConfig.managementAutoScoring}`}>
        Quản lý chấm điểm tự động
      </Link>,
      `/setting-high-level/${routesConfig.managementAutoScoring}`
    ),
    getItem(
      <Link to={`/setting-high-level/${routesConfig.controlQualityCourse}`}>
        Kiểm soát chất lượng khoá học
      </Link>,
      `/setting-high-level/${routesConfig.controlQualityCourse}`
    ),
  ]);

const SiderLayout: React.FC<SidebarProps> = ({ selectedMenu, collapsedContent, isHideSider }) => {
  const location = useLocation();
  const { token: { colorBgContainer }, } = theme.useToken();
  const defaultOpenKeys = location.pathname.split("/").filter((x) => { return x !== '' });
  const defaultSelectedKeys = [`/` + `${defaultOpenKeys[0]}/` + `${!isEmpty(defaultOpenKeys[1]) ? defaultOpenKeys[1] : ''}`];
  const listOpenKeys = [`${defaultOpenKeys[0]}`, `${defaultOpenKeys[1]}`];
  const setQuestion = useAddLesson((state) => state.setQuestion);
  const setModeQuestion = useAddLesson((state) => state.setModeQuestion);
  const setTypeQuestionChoose = useAddLesson((state) => state.setTypeQuestionChoose);
  const setModeClick = useAddLesson((state) => state.setModeClick);
  const [tab, setTab] = useState("1");
  const [loading, setLoading] = useState<boolean>(false);
  const [color, setColor] = useState<any>({});
  const backgroundColor = location.state?.color?.menuColor;
  const pathName = location.pathname;

  useEffect(() => {
    setQuestion({} as QuestionData);
    setModeClick("");
    setModeQuestion("");
    setTypeQuestionChoose("");
  }, [tab]);

  const getColor = async () => {
    setLoading(true);
    await getPermission().then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setColor(res.data?.data?.color);
      }
    });
  };

  useEffect(() => {
    getColor();
  }, []);

  return (
    <>
      {!isHideSider ? (
        <Sider
          breakpoint="lg"
          width={'100%'}
          style={{
            height: '100%',
            boxShadow: 'unset',
            background: `${pathName.includes(routesConfig.detailAdminInterface)
              ? backgroundColor
              : !isEmpty(color) && !loading
                ? color?.menuColor
                : "#FFFFFF"
              }`,
          }}
          className="sider"
          collapsible={!isHideSider}
          collapsed={collapsedContent}
          trigger={null}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={defaultSelectedKeys}
            defaultOpenKeys={listOpenKeys}
            items={selectedMenu}
            className="menu-wrapper"
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingBottom: '48px',
              height: '100%',
              background: `${pathName.includes(routesConfig.detailAdminInterface)
                ? backgroundColor
                : !isEmpty(color) && !loading
                  ? color?.menuColor
                  : "#FFFFFF"
                }`,
            }}
          />
        </Sider>
      ) : null}
    </>
  );
};

export default SiderLayout;
