import { DeleteOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";

interface UploadItemProps {
  file: any;
  hasDelete?: boolean;
  onDelete?: (v: any) => void;
}

function UploadItem({
  file,
  onDelete = () => {},
  hasDelete = false,
}: UploadItemProps) {
  const type = file?.type;

  const handleDelete = () => {
    onDelete(file?.name);
  };

  const renderFile = () => {
    switch (type) {
      case "image":
      case "image/jpeg":
      case "image/png":
        return (
          <div key={file.name} className="upload-item-preview">
            <img src={file.preview} alt="preview" />
            <Tooltip title={file.name}>
              <p className="file-preview-name">{file.name}</p>
            </Tooltip>
            {hasDelete && (
              <DeleteOutlined
                className="pointer pl-1 pr-1"
                onClick={handleDelete}
              />
            )}
          </div>
        );
      case "application/pdf":
      case "pdf":
        return (
          <div key={file.name} className="upload-item-preview">
            <img
              src={`${process.env.PUBLIC_URL}/images/pdf-icon.svg`}
              alt="preview"
            />
            <Tooltip title={file.name}>
              <p className="file-preview-name">{file.name}</p>
            </Tooltip>
            {hasDelete && (
              <DeleteOutlined
                className="pointer pl-1 pr-1"
                onClick={handleDelete}
              />
            )}
          </div>
        );
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      case "excel":
      case "sheet":
      case "xlxs":
        return (
          <div key={file.name} className="upload-item-preview">
            <img
              src={`${process.env.PUBLIC_URL}/images/xlxs-icon.svg`}
              alt="preview"
            />
            <Tooltip title={file.name}>
              <p className="file-preview-name">{file.name}</p>
            </Tooltip>
            {hasDelete && (
              <DeleteOutlined
                className="pointer pl-1 pr-1"
                onClick={handleDelete}
              />
            )}
          </div>
        );
      case "word":
      case "docx":
      case "doc":
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return (
          <div key={file.name} className="upload-item-preview">
            <img
              src={`${process.env.PUBLIC_URL}/images/word-icon.svg`}
              alt="preview"
            />
            <Tooltip title={file.name}>
              <p className="file-preview-name">{file.name}</p>
            </Tooltip>
            {hasDelete && (
              <DeleteOutlined
                className="pointer pl-1 pr-1"
                onClick={handleDelete}
              />
            )}
          </div>
        );
      case "powerpoint":
      case "pptx":
      case "application/vnd.ms-powerpoint":
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return (
          <div key={file.name} className="upload-item-preview">
            <img
              src={`${process.env.PUBLIC_URL}/images/pptx-icon.svg`}
              alt="preview"
            />
            <Tooltip title={file.name}>
              <p className="file-preview-name">{file.name}</p>
            </Tooltip>
            {hasDelete && (
              <DeleteOutlined
                className="pointer pl-1 pr-1"
                onClick={handleDelete}
              />
            )}
          </div>
        );
      default:
        return "";
    }
  };
  return <>{renderFile()}</>;
}

export default UploadItem;
