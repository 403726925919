import React, {ChangeEvent, useEffect, useState} from 'react';
import TableData from "../../../../components/table-data/TableData";
import {DataType} from "../../../../types/listUsers";
import {Button, Form, TableColumnsType, TableProps} from "antd";
import {ClearOutlined, FilterOutlined, SearchOutlined} from "@ant-design/icons";
import FormInput from "../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import {getOffices, getRoles, getUnits} from "../../../../service/list-account-users/listAccountUsersApi";
import {getListUsersExist} from "../../../../service/role-management/roleManagementApi";
import {FormInstance} from "antd/lib";
import {get} from "lodash";
import {combineArray} from "../../../../utils/arrays";

const dataColumns: TableColumnsType<DataType> = [
  {
    title: 'Họ và tên',
    dataIndex: 'fullName',
    key: 'fullName',
    width: 40
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: 25
  },
  {
    title: 'Đơn vị công tác',
    dataIndex: 'unit',
    key: 'unit',
    width: 35
  }
]

type AccountApplicativeProps = {
  checkEdit?: boolean,
  checkIsFormEdit?: boolean,
  dataEdit?: any,
  setCount?: (value: number) => void,
  formCreate?: FormInstance,
}

const AccountApplicative = ({checkEdit, checkIsFormEdit, setCount, formCreate, dataEdit}: AccountApplicativeProps) => {


  const [form] = Form.useForm()
  const [searchValue, setSearchValue] = useState<string>("")
  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [totalRecords, setTotalRecords] = useState(10)
  const [loading, setLoading] = useState(true)
  const [dataTable, setDataTable] = useState<DataType[]>([])
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: []
  })
  const [dataFilter, setDataFilter] = useState({
    donVis: [],
    vaiTros: [],
  })
  const [statusExists, setStatusExists] = useState(false)


  const fetchDataPermissions: (dataSearch: any) => Promise<any> = (dataSearch: any) => getListUsersExist(dataSearch).then(res => {
    const {data, totalRecords, pageSize, pageNumber} = res.data.data
    setTotalRecords(totalRecords)
    setPageNumber(pageNumber)
    setPageSize(pageSize)
    setCount && setCount(totalRecords)
    setLoading(false)
    setDataTable(data)
  })

  useEffect(() => {
    if (!checkIsFormEdit && !dataEdit) {
      fetchDataPermissions({
        ...dataFilter,
        keywords: searchValue,
        pageNumber: pageNumber,
        pageSize: pageSize
      })
    }

  }, [pageSize, pageNumber, checkIsFormEdit]);


  useEffect(() => {
    if (dataEdit && Object.keys(dataEdit).length > 0) {
      setLoading(true)
      setStatusExists(true)
      getListUsersExist({id: get(dataEdit, 'id', ''), isExist: statusExists}).then(res => {
        const {data, totalRecords} = res.data.data
        setTotalRecords(totalRecords)
        setLoading(false)
        setCount && setCount(totalRecords)
        setDataTable(data)
      })
    }
  }, [statusExists, dataEdit]);


  useEffect(() => {

    const fetchDataOptions = async () => {
      try {
        const response = await Promise.all([getRoles(), getUnits(), getOffices()])
        const dataRoles = response[0].data.data
        const dataUnits = response[1].data.data
        const dataOffices = response[2].data.data
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnits,
          dataRoles: dataRoles
        })
      } catch (err) {
        console.error(err)
      }
    }
    fetchDataOptions()

  }, []);


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }
  const handleSearch = () => {
    setLoading(true)
    fetchDataPermissions({keyword: searchValue}).then(res => {
      setLoading(false)
    })
  }

  const handleFilterData = (values: any) => {
    setLoading(true)
    setDataFilter({
      ...dataFilter,
      vaiTros: values.vaiTros,
      donVis: combineArray(dataOptions.dataUnits, values.donVis)
    })
    fetchDataPermissions({
      keyword: searchValue,
      vaiTros: values.vaiTros,
      donVis: combineArray(dataOptions.dataUnits, values.donVis)
    }).then(res => {
      setLoading(false)
    })
  }

  const clearFilter = () => {
    setLoading(true)
    setDataFilter({
      ...dataFilter,
      vaiTros: [],
      donVis: []
    })
    form.resetFields()
    fetchDataPermissions({}).then(res => {
      setLoading(false)
    })
  }
  const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {
  };
  return (
    <div className="table-role--applied">
      <div className="search-table">
        <div className="search-input">
          <FormInput placeholder="Nhập tên, email để tìm kiếm"
                     disabled={checkEdit}
                     onKeyPress={(e) => {
                       if (e.key === 'Enter') {
                         handleSearch()
                       }
                     }}
                     onChange={handleChange}
                     afterPrefixIcon={<SearchOutlined onClick={handleSearch}/>}/>
        </div>
      </div>
      <Form form={form} onFinish={handleFilterData} name="filterForm">
        <div className="filter-data--table">
          <div className="filter-group">
            <Form.Item className="filter-select--item" name="donVis">
              <FormItemDropdown placeholder="Chọn đơn vị công tác" modeDropdown="multiple"
                                options={dataOptions.dataUnits}
                                disabled={checkEdit}/>
            </Form.Item>
            <Form.Item className="filter-select--item" name="vaiTros">
              <FormItemDropdown placeholder="Chọn vai trò" modeDropdown="multiple" options={dataOptions.dataRoles}
                                disabled={checkEdit}/>
            </Form.Item>
            <div className="filter-group--btn">
              <Button disabled={checkEdit} onClick={() => form.submit()}>
                <FilterOutlined/>
              </Button>
              <Button onClick={clearFilter} disabled={checkEdit}>
                <ClearOutlined/>
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <Form.Item name="nguoiDungs">

        <TableData dataColumns={dataColumns} loadingTable={loading} pageNumber={pageNumber} pageSize={pageSize}
                   checkEdit={checkEdit} dataTable={dataTable} totalRecords={totalRecords}
                   setPageNumber={setPageNumber}
                   form={formCreate}
                   pagination={true}
                   hideSelection={true}
                   setPageSize={setPageSize}
                   handleTableChangeProps={handleTableChange}/>
      </Form.Item>
    </div>
  );
};

export default AccountApplicative;
