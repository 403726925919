// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-respond .title-card {
  font-size: 50px; }

.survey-managment-respond .block-img {
  margin: 10px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/survey-respond/index.scss"],"names":[],"mappings":"AAAA;EAEQ,eAAe,EAAA;;AAFvB;EAMQ,YAAY,EAAA","sourcesContent":[".survey-managment-respond {\n    .title-card {\n        font-size: 50px;\n    }\n\n    .block-img {\n        margin: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
