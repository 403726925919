import {IDataCreateUser} from "../../types/listUsers";
import {axiosConfig} from "../../config/api/configApi";
import {AxiosResponse} from "axios";

export const createAccountTeacher: (user: IDataCreateUser) => Promise<AxiosResponse<any>> = (user) => {
    return axiosConfig.post(`/accounts/giang-vien`, user)
}

export const getDataTeachers: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
    return axiosConfig.post(`/accounts/giang-vien/search`, search);
}

export const getAllTeachers: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfig.get(`/course-planning-assignment/giang-vien/get-all`);
}